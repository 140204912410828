import { useEffect } from 'react';

import { GET_PROGRAM } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetProgram = (programId = '') => {
  const {
    loading = false,
    data = {},
    refetch = () => {},
  } = programId
    ? useAuthQuery(GET_PROGRAM, {
        variables: { programId },
      })
    : {};
  const { program = {} } = { ...data };

  useEffect(() => {
    refetch();
  }, []);

  return {
    isLoading: loading,
    program,
  };
};

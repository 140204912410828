import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { loggedIn } from 'libs/authenticationService';
import { getUserRole } from 'utils/isClinic';
import { ROLES, ROUTES } from 'utils/constants';

import TrialBanner from 'components/TrialBanner';
import { ConversationsProvider } from 'components/conversations/ConversationsProvider';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userRole = getUserRole();
  const isSuperClinic = userRole === ROLES.SUPER_CLINIC;

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn() ? (
          <>
            {props.location.pathname.indexOf(ROUTES.BILLING) === -1 && !isSuperClinic && <TrialBanner />}
            <ConversationsProvider>
              <Component {...props} />
            </ConversationsProvider>
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
              search: props.location.search,
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.defaultProps = {
  location: undefined,
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default PrivateRoute;

import React from 'react';
import { useTranslation } from 'react-i18next';

import maleAvatar from 'assets/images/patient-male.svg';
import femaleAvatar from 'assets/images/patient-female.svg';

import SolidButton from 'components/SolidButton';

const EmployeeList = ({ employees, selectedEmployees, handleSelectEmployee }) => {
  const { t } = useTranslation();

  return (
    <div className="patients-list-modal__list super-clinic-employees">
      {(employees || []).map((employee) => {
        const { name = '', gender = '', cardId = '', email = '', id = '' } = { ...employee };
        const isSelected = [...selectedEmployees].indexOf(id) !== -1;
        return (
          <div key={id} className="patient">
            <div className="patient__info">
              <div className="patient__info__avatar">
                <img src={gender === 'Hombre' ? maleAvatar : femaleAvatar} alt="patient avatar" />
              </div>
              <div className="patient__info__name">
                <p>{name}</p>
                <p>{cardId}</p>
                <p>{email}</p>
              </div>
            </div>
            <div>
              <SolidButton
                onClick={() => handleSelectEmployee(id)}
                className={`${isSelected ? 'remove' : 'add'} min-width-100 slim`}
              >
                {isSelected ? t('button.delete') : t('button.add')}
              </SolidButton>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EmployeeList;

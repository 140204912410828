import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaChevronRight } from 'react-icons/fa';
import { CgTrash } from 'react-icons/cg';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiDuplicate } from 'react-icons/bi';
import { v4 as uuid } from 'uuid';
import { Draggable } from 'react-beautiful-dnd';

import { useToggle } from 'utils/hooks/useToggle';
import { useEvieProgramContext } from 'components/eviePrograms/EvieProgramProvider';
import { ACTIONS } from 'components/eviePrograms/service';

import EvieWarning from '../EvieWarning/EvieWarning';
import EvieLevelInfo from '../EvieLevelInfo/EvieLevelInfo';

const EvieLevel = ({ index, level }) => {
  const { t } = useTranslation();
  const { dispatch, state } = useEvieProgramContext();
  const { levels } = state;
  const { id, name = '', goal = '' } = level;

  const [levelWarning, setLevelWarning] = useToggle();
  const [levelInfo, setLevelInfo] = useToggle();

  const handleSelectLevel = () => {
    dispatch({ type: ACTIONS.SELECT_LEVEL, payload: { ...level, number: index + 1 } });
  };

  const handleRemoveLevel = () => {
    dispatch({ type: ACTIONS.REMOVE_LEVEL, payload: id });
  };

  const handleAddLevelInfo = (values) => {
    dispatch({ type: ACTIONS.ADD_LEVEL_INFO, payload: { id, values } });
    setLevelInfo();
  };

  const handleCloneLevel = () => {
    const newLevels = [...levels];
    newLevels.splice(index + 1, 0, {
      ...level,
      isNew: true,
      id: uuid(),
      programSessions: level.programSessions.map((programSession) => ({
        ...programSession,
        isNew: true,
        sessionExercises: programSession.sessionExercises.map((sessionEx) => ({ ...sessionEx, isNew: true })),
      })),
    });
    dispatch({ type: ACTIONS.CLONE_LEVEL, payload: { newLevels } });
  };

  const waringData = {
    open: levelWarning,
    onCancelAction: setLevelWarning,
    onConfirmAction: handleRemoveLevel,
    title: t('eviePrograms.warning.levelTitle'),
  };

  const levelInfoData = {
    levelData: { name, goal },
    open: levelInfo,
    onCancelAction: setLevelInfo,
    onSubmitLevelInfo: handleAddLevelInfo,
  };

  return (
    <>
      <Draggable draggableId={id} index={index}>
        {(provided) => (
          <li
            className="evie-program__levels-list_level"
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <span className="evie-program__levels-list_label-level">
              {t('eviePrograms.levels.labelLevel')} {index + 1}
            </span>
            <div className="evie-program__handlers">
              <CgTrash onClick={setLevelWarning} />
              <AiOutlineEdit onClick={setLevelInfo} />
              <BiDuplicate onClick={handleCloneLevel} />
              <button type="button" className="evie-program__handlers_selector" onClick={handleSelectLevel}>
                <FaChevronRight />
              </button>
            </div>
          </li>
        )}
      </Draggable>
      {levelWarning && <EvieWarning {...waringData} />}
      {levelInfo && <EvieLevelInfo {...levelInfoData} />}
    </>
  );
};

EvieLevel.propTypes = {
  index: PropTypes.number.isRequired,
  level: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    goal: PropTypes.string,
    programSessions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default EvieLevel;

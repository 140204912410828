import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';

import { ROUTES } from 'utils/constants';

const AllAudios = ({ audios }) => {
  const { push } = useHistory();
  return (
    <>
      {(audios || []).map((audio) => {
        const { id, title, content } = audio;
        return (
          <tr key={id}>
            <td style={{ width: '30%' }}>{title}</td>
            <td style={{ width: '60%' }}>
              <p className="content-column-table">{content}</p>
            </td>
            <td className="actions-col" style={{ width: '10%' }}>
              <button type="button" onClick={() => push(`${ROUTES.AUDIO}?id=${id}`)} className="form-detail">
                <IoIosArrowDown />
              </button>
            </td>
          </tr>
        );
      })}
    </>
  );
};

AllAudios.propTypes = {
  audios: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
    }),
  ).isRequired,
};

export default AllAudios;

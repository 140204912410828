import { useState } from 'react';

export const useFilterSegments = () => {
  const [filterParts, setFilterParts] = useState([]);
  const [filterEquipments, setFilterEquipments] = useState([]);
  const [filterLevels, setFilterLevels] = useState([]);

  const handleSelectSegments = ({ selectedSegment, category }) => {
    if (category === 'bodyParts') {
      setFilterParts((prevParts) => {
        const isSelected = prevParts.includes(selectedSegment);
        if (isSelected) {
          return prevParts.filter((segment) => segment !== selectedSegment);
        }
        return [...prevParts, selectedSegment];
      });
    }

    if (category === 'equipments') {
      setFilterEquipments((prevEquipments) => {
        const isSelected = prevEquipments.includes(selectedSegment);
        if (isSelected) {
          return prevEquipments.filter((segment) => segment !== selectedSegment);
        }
        return [...prevEquipments, selectedSegment];
      });
    }

    if (category === 'levels') {
      setFilterLevels((prevLevels) => {
        const isSelected = prevLevels.includes(selectedSegment);
        if (isSelected) {
          return prevLevels.filter((segment) => segment !== selectedSegment);
        }
        return [...prevLevels, selectedSegment];
      });
    }
  };

  return {
    handleSelectSegments,
    filterParts,
    filterEquipments,
    filterLevels,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';

import { evieLevelInfo } from 'utils/validationSchema';

import { TextInput } from 'components/FormComponents';
import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: 500,
  },
};

const EvieLevelInfo = ({ levelData, open, onCancelAction, onSubmitLevelInfo }) => {
  const { t } = useTranslation();

  return (
    <Modal modalProps={modalProps} modalIsOpen={open} closeModal={onCancelAction} closeable={false}>
      <div className="evie-warning">
        <h3 className="evie-warning__title">{t('eviePrograms.level.infoTitle')}</h3>
        <Formik
          initialValues={{ ...levelData }}
          onSubmit={onSubmitLevelInfo}
          validationSchema={evieLevelInfo}
          enableReinitialize
        >
          {({ isSubmitting, isValid }) => {
            const isDisabledButton = [!isValid, isSubmitting].includes(true);
            return (
              <Form className="evie-warning__form">
                <Field type="text" label={t('formLabel.name')} name="name" component={TextInput} />
                <Field type="text" label={t('formLabel.goal')} name="goal" component={TextInput} />
                <div className="evie-warning__handlers level-info-btns">
                  <SolidButton className="evie-secondary mr-2" onClick={onCancelAction}>
                    {t('button.back')}
                  </SolidButton>
                  <SolidButton
                    type="submit"
                    className={`evie-primary ${isDisabledButton ? 'disabled' : ''}`}
                    disabled={isDisabledButton}
                  >
                    {t('button.update')}
                  </SolidButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

EvieLevelInfo.propTypes = {
  levelData: PropTypes.shape({
    name: PropTypes.string,
    goal: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onCancelAction: PropTypes.func.isRequired,
  onSubmitLevelInfo: PropTypes.func.isRequired,
};

export default EvieLevelInfo;

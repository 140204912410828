import React from 'react';
import PropTypes from 'prop-types';

export const ImagePreview = ({ block }) => {
  const { url } = block;
  return <img src={url} alt="display content" className="readingPreview__info_img" />;
};

ImagePreview.propTypes = {
  block: PropTypes.shape({ url: PropTypes.string }).isRequired,
};

import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import { useCreateProgramContext } from 'components/createProgram/CreateProgramProvider';
import { useGetPlans } from 'utils/hooks/useGetPlans';
import { ACTIONS, CREATE_PROGRAM_STEPS } from 'components/createProgram/service/programConstants';
import { programDetail } from 'utils/validationSchema';

import { TextInput, NumberInput, MultiSelect } from 'components/FormComponents';
import SolidButton from 'components/SolidButton';
import UserAvatar from 'components/UserAvatar';
import Icon from 'components/Icon';

import noDoctor from 'assets/images/login-employers.png';

const ProgramDetail = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const { formatedOptions } = useGetPlans();

  const { createProgramState, dispatch } = useCreateProgramContext();
  const { backgroundImage, doctorImage, description, title, doctorName, duration, planId } = {
    ...createProgramState,
  };
  const initialValues = { backgroundImage, doctorImage, title, doctorName, duration, planId };

  const [backImage, setBackImage] = useState(backgroundImage);

  const fileBackground = useRef();
  const readerBackground = new FileReader();

  useEffect(() => {
    if (typeof backgroundImage === 'object') {
      setBackImage(URL.createObjectURL(backgroundImage));
    }
  }, [backgroundImage]);

  useEffect(() => {
    if (backgroundImage && typeof backgroundImage !== 'object') {
      setBackImage(backgroundImage);
    }
  }, [backgroundImage]);

  readerBackground.onloadend = () => {
    setBackImage(readerBackground.result);
  };

  const handleFileBackground = () => {
    if (fileBackground) {
      fileBackground.current.click();
    }
  };

  const [docImage, setDocImage] = useState(doctorImage);
  const fileDoctor = useRef();
  const readerDoctor = new FileReader();

  useEffect(() => {
    if (typeof doctorImage === 'object') {
      setDocImage(URL.createObjectURL(doctorImage));
    }
  }, [doctorImage]);

  useEffect(() => {
    if (doctorImage && typeof doctorImage !== 'object') {
      setDocImage(doctorImage);
    }
  }, [doctorImage]);

  readerDoctor.onloadend = () => {
    setDocImage(readerDoctor.result);
  };

  const handleFileDoctor = () => {
    if (fileDoctor) {
      fileDoctor.current.click();
    }
  };

  const blocksFromHtml = htmlToDraft(description);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));

  useEffect(() => {
    if (description) {
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [description]);

  const onEditorStateChange = (editState) => {
    setEditorState(editState);
  };

  const handleSubmit = (values) => {
    dispatch({
      type: ACTIONS.ADD_DETAILS,
      payload: { ...values, description: draftToHtml(convertToRaw(editorState.getCurrentContent())) },
    });
    dispatch({ type: ACTIONS.SET_STEP, payload: CREATE_PROGRAM_STEPS.READING_TEMPLATES });
  };

  return (
    <section>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={programDetail} enableReinitialize>
        {({ setFieldValue, isSubmitting, isValid }) => {
          const isDisabledButton = [!isValid, isSubmitting].includes(true);
          return (
            <Form>
              <div className="program__detail">
                <div className="program__detail__avatar">
                  <div className="program__detail__avatar__container">
                    <span>{t('formLabel.backgroundImage')}</span>
                    <UserAvatar size="huge" avatarUrl={backImage || noDoctor}>
                      <div className="template-detail__camera" aria-hidden="true" onClick={handleFileBackground}>
                        <Icon name="camera" />
                      </div>
                      <input
                        type="file"
                        className="file-upload"
                        ref={fileBackground}
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          const upload = event.currentTarget.files[0];
                          setFieldValue('backgroundImage', upload);
                          if (upload) {
                            readerBackground.readAsDataURL(upload);
                          }
                        }}
                      />
                    </UserAvatar>
                  </div>
                  <div className="program__detail__avatar__container">
                    <span>{t('formLabel.doctorImage')}</span>
                    <UserAvatar size="huge" avatarUrl={docImage || noDoctor}>
                      <div className="template-detail__camera" aria-hidden="true" onClick={handleFileDoctor}>
                        <Icon name="camera" />
                      </div>
                      <input
                        type="file"
                        className="file-upload"
                        ref={fileDoctor}
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          const upload = event.currentTarget.files[0];
                          setFieldValue('doctorImage', upload);
                          if (upload) {
                            readerDoctor.readAsDataURL(upload);
                          }
                        }}
                      />
                    </UserAvatar>
                  </div>
                </div>
                <div className="program__detail__fields">
                  <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
                  <Field type="text" label={t('formLabel.doctorName')} name="doctorName" component={TextInput} />
                  <Field type="text" label={t('formLabel.duration')} name="duration" component={NumberInput} />
                  <Field
                    type="text"
                    label={t('formLabel.plan')}
                    name="planId"
                    component={MultiSelect}
                    options={formatedOptions}
                    placeholder={t('placeholder.plan')}
                  />
                </div>
              </div>
              <div className="reading__editor">
                <Editor
                  editorClassName="rich-text-editor"
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  placeholder="Write something!"
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'fontFamily',
                      'list',
                      'textAlign',
                      'colorPicker',
                      'link',
                      'emoji',
                      'history',
                    ],
                    fontFamily: {
                      options: ['SF Pro Text'],
                    },
                  }}
                />
              </div>
              <div className="program__detail__buttons">
                <SolidButton className="secondary mr-2" onClick={goBack}>
                  {t('button.back')}
                </SolidButton>
                <SolidButton
                  className={`mr-4 ${isDisabledButton ? 'disabled' : ''}`}
                  disabled={isDisabledButton}
                  type="submit"
                >
                  {t('button.next')}
                </SolidButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default ProgramDetail;

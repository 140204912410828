import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaRegImage } from 'react-icons/fa';
import { FiVideo } from 'react-icons/fi';

import { useToggle } from 'utils/hooks/useToggle';
import showNotification from 'utils/showNotification';

import ExerciseModal from 'components/CreatePatient/ExerciseModal';

const ItemCard = ({ item, addContent, selectedContent, isExercises }) => {
  const { t } = useTranslation();
  const { id, title, image, thumbnailImage = {}, backgroundImage = {} } = item;
  const { thumb } = thumbnailImage;
  const { thumb: thumbBg } = backgroundImage;
  const isSelected = selectedContent.some(({ id: itemId }) => itemId === id);

  const [isExercise, setIsExercise] = useToggle();

  const handleDisplayExercise = (event) => {
    event.stopPropagation();
    setIsExercise();
  };

  const handleSelectItem = () => {
    if (isSelected && isExercises) {
      showNotification({ type: 'warning', messages: [t('notification.warning.selectedItem')] });
    } else {
      addContent(item);
    }
  };

  return (
    <>
      <li>
        <button
          type="button"
          className={`evie-program__item-list_item ${isSelected ? 'selected-item' : ''}`}
          onClick={handleSelectItem}
        >
          <div
            className="image-input__bg evie-program__item-list_item-img"
            style={{ backgroundImage: `url(${thumb || thumbBg || image})` }}
          >
            {!thumb && !thumbBg && !image && <FaRegImage className="image-input__bg_icon" />}
          </div>
          <h3 className="evie-program__item-list_item-title">{title}</h3>
          {isExercises && <FiVideo className="evie-program__item-list_item-icon" onClick={handleDisplayExercise} />}
        </button>
      </li>
      {isExercise && (
        <ExerciseModal open={isExercise} onCloseModal={setIsExercise} exercise={item} onAdd={handleSelectItem} />
      )}
    </>
  );
};

ItemCard.defaultProps = {
  isExercises: false,
};

ItemCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
    thumbnailImage: PropTypes.shape({
      thumb: PropTypes.string,
    }),
    backgroundImage: PropTypes.shape({
      thumbBg: PropTypes.string,
    }),
  }).isRequired,
  addContent: PropTypes.func.isRequired,
  selectedContent: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  isExercises: PropTypes.bool,
};

export { ItemCard };

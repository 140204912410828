import React from 'react';
import { isEmpty } from 'ramda';

import { useConversationsContext } from 'components/conversations/ConversationsProvider';
import { getFullDate, getNameAndInitials } from '../utils';

const ConversationHeader = () => {
  const { selectedConversation, lng } = useConversationsContext();
  const isNotSelectedConversation = isEmpty(selectedConversation);

  const dateUptaded = isNotSelectedConversation
    ? null
    : getFullDate({
        date: selectedConversation.dateUpdated,
        lng,
        options: { month: 'short', day: 'numeric', year: 'numeric' },
      });

  const { name, initials } = getNameAndInitials(selectedConversation);

  return (
    <>
      <div className="initials">
        <span>{initials}</span>
      </div>
      <div className="credentials">
        <h4>{name}</h4>
        <p>
          <span>{dateUptaded}</span>
        </p>
      </div>
    </>
  );
};

export default ConversationHeader;

import { ACTIONS } from './createActivePauseConstants';

export function createActivePauseReducer(state, action) {
  switch (action.type) {
    case ACTIONS.ADD_EXERCISE:
      return { ...state, exerciseIds: [...state.exerciseIds, action.payload] };
    case ACTIONS.REMOVE_EXERCISE:
      return { ...state, exerciseIds: state.exerciseIds.filter(({ id }) => id !== action.payload.id) };
    case ACTIONS.SET_ACTIVE_PAUSE:
      return { ...state, ...action.payload };
    default:
      throw new Error(`Unhandled action ${action.type}`);
  }
}

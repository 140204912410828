import React from 'react';
import { isEmpty } from 'ramda';

import SolidButton from 'components/SolidButton';

const ActionButtons = (props) => {
  const {
    onCloseModal = () => {},
    selectedSurvey = {},
    handleSendSurvey = () => {},
    handleShowSurvey = () => {},
    showSurvey = false,
    selectedQuestions = [],
  } = { ...props };
  const isSurveyEmpty = isEmpty(selectedSurvey);

  return (
    <div className="followup-buttons">
      <SolidButton className="secondary mr-3" onClick={onCloseModal}>
        Cancelar
      </SolidButton>
      {!showSurvey && (
        <SolidButton
          disabled={isSurveyEmpty}
          className={`${isSurveyEmpty ? 'disabled' : ''} mr-3`}
          onClick={handleShowSurvey}
        >
          Seleccionar preguntas
        </SolidButton>
      )}
      <SolidButton
        disabled={isSurveyEmpty || isEmpty(selectedQuestions)}
        className={`${isSurveyEmpty || isEmpty(selectedQuestions) ? 'disabled' : ''}`}
        onClick={handleSendSurvey}
      >
        Enviar
      </SolidButton>
    </div>
  );
};

export { ActionButtons };

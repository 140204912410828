import React, { useEffect, useState } from 'react';
import { propOr, isEmpty, pathOr } from 'ramda';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { useAuthQuery } from '../../utils/hooks';
import { useRedirectByRole } from '../../utils/hooks/useRedirectByRole';
import Loader from 'react-loader-spinner';
import { GoogleLogin } from 'react-google-login';
import LeftMenu from '../../components/LeftMenu';
import Header from '../../components/Header';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { Formik, Field, Form } from 'formik';
import { TextAreaInput, TextInput, DateTimeInput, CovenantSelect } from '../../components/FormComponents';
import showNotification from '../../utils/showNotification';
import { ROLES } from 'utils/constants';
import { GET_EVENTS, VALIDATE_USER } from '../../apollo/queries';
import { CREATE_EVENT, UPDATE_EVENT, SEND_OAUTH_TOKEN } from '../../apollo/mutations';
import noPatients from '../../assets/images/no-patients.svg';
import { eventForm } from '../../utils/validationSchema';
import './events.scss';

const expirationDuration = 1000 * 60 * 60 * 12;
const rowsPerPage = 10;
const modalProps = {
  content: {
    background: '#FCFCFC',
    width: '50vw',
    maxWidth: '640px',
    outline: 'none',
    padding: '0px !important',
  },
};

moment.locale = 'es';
export const eventPermissions = ['fisioterapiamodofisio@gmail.com'];

const Events = (props) => {
  useRedirectByRole([ROLES.CLINIC]);
  const { loading, data, refetch } = useAuthQuery(GET_EVENTS);
  const { data: clinicData } = useAuthQuery(VALIDATE_USER);
  const { getClinicInfo: clinic = {} } = { ...clinicData };
  const { email = '', enableEvents = false } = { ...clinic };
  const allEvents = propOr([], 'events')(data);
  const [events, setEvents] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const prevAccepted = localStorage.getItem('accepted');
  const currentTime = new Date().getTime();
  const notAccepted = prevAccepted === undefined;
  const prevAcceptedExpired = prevAccepted !== undefined && currentTime - prevAccepted > expirationDuration;
  const storedToken = localStorage.getItem('googleToken');
  let isToken = false;
  if (notAccepted || prevAcceptedExpired || !storedToken) {
    localStorage.removeItem('accepted');
    localStorage.removeItem('googleToken');
  } else {
    isToken = true;
  }

  const [token, setToken] = useState(isToken);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState();
  const [updateEvent] = useMutation(UPDATE_EVENT);
  const [createEvent] = useMutation(CREATE_EVENT);
  const [sendOAuthToken] = useMutation(SEND_OAUTH_TOKEN);

  const newEvent = { title: '', description: '', startAt: '', covenantId: undefined };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let temporal = allEvents;
    const totalPages = temporal.length > 0 ? Math.ceil(temporal.length / rowsPerPage) : 0;
    if (page !== 1) setPage(1);
    if (pages !== totalPages) setPages(totalPages);
    if (isNewResults(temporal)) setFiltered(temporal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEvents]);

  useEffect(() => {
    const init = page * rowsPerPage - rowsPerPage;
    const end = init + rowsPerPage;
    setEvents(filtered.slice(init, end));
  }, [filtered, page]);

  const onCloseModal = () => {
    setOpenModal(false);
    setSelected(undefined);
  };

  const onOpenModal = (item) => {
    const { covenant = {}, startAt = '' } = { ...item };
    const { id: covenantId = '' } = { ...covenant };
    setOpenModal(true);
    setSelected({ ...item, covenantId, startAt: !!startAt ? moment(startAt).format('DD/MM/YYYY - hh:mm A') : '' });
  };

  const saveEventForm = async (values) => {
    try {
      const { id: eventId } = { ...selected };
      const data = {
        ...selected,
        ...values,
      };
      const mutation = !!eventId ? updateEvent : createEvent;
      const response = await mutation({
        variables: { ...data },
      });

      const errors = pathOr([], ['data', !!eventId ? 'updateEvent' : 'createEvent', 'errors'])(response);
      const emptyErrors = isEmpty(errors);
      showNotification({
        type: emptyErrors ? 'success' : 'error',
        messages: emptyErrors ? [`Se ha ${!!eventId ? 'actualizado' : 'creado'} el evento exitosamente.`] : errors,
      });

      if (emptyErrors) {
        onCloseModal();
        refetch();
      } else {
        localStorage.removeItem('googleToken');
        localStorage.removeItem('accepted');
      }
    } catch (err) {
      showNotification({
        type: 'error',
        messages: ['Error inesperado creado el evento'],
      });
      localStorage.removeItem('googleToken');
      localStorage.removeItem('accepted');
      setToken(false);
    }
  };

  const sendAuthToken = async (googleResponse) => {
    const { accessToken: googleToken = '' } = { ...googleResponse };
    const response = await sendOAuthToken({
      variables: { googleToken },
    });

    const errors = pathOr([], ['data', 'sendToken', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    if (emptyErrors) {
      setToken(true);
      localStorage.setItem('googleToken', googleToken);
      localStorage.setItem('accepted', new Date().getTime());
      refetch();
    } else {
      showNotification({
        type: emptyErrors ? 'success' : 'error',
        messages: errors,
      });
    }
  };

  const isNewResults = (results) => {
    const rIds = (results || [])
      .map(({ id, title, startAt, covenantId }) => `${id}${title}${startAt}${covenantId}`)
      .join(',');
    const fIds = (filtered || [])
      .map(({ id, title, startAt, covenantId }) => `${id}${title}${startAt}${covenantId}`)
      .join(',');
    return rIds !== fIds;
  };

  if (!(process.env.REACT_APP_ENV !== 'production' || eventPermissions.indexOf(email) !== -1 || enableEvents))
    return null;

  return (
    <div className="d-flex">
      <LeftMenu history={props.history} />
      <div className="dashboard__main">
        <Header title="Eventos" /*setGoogleToken={setToken}*/ />
        <div className="dashboard__content--patients">
          {loading && (
            <div className={'loading-container'}>
              <Loader type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
            </div>
          )}
          {token && !loading && isEmpty(allEvents) && (
            <div className="patients__empty-state">
              <div className={'content'}>
                <p className={'title'}>¡Aún no tienes eventos programados!</p>
                <p className={'subtitle'}>Inicia programando un evento para tu consultorio o clínica.</p>
                <div className="mx-2 text-center" onClick={() => onOpenModal({ ...newEvent })}>
                  <Button category="rounded">Crear Evento</Button>
                </div>
              </div>
              <img src={noPatients} alt={'no patients'} className={'image'} />
            </div>
          )}
          {!token && !loading && (
            <div className="patients__empty-state">
              <div className={'content'}>
                <p className={'title'}>Integrar Google Calendar</p>
                <p className={'subtitle'}>
                  HealthPhy se integra con los servicios de Google para la programación de eventos, ingresa con tu
                  cuenta para habilitar la funcionalidad.
                </p>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_OAUTH}
                  buttonText="Iniciar sesión con Google"
                  onSuccess={sendAuthToken}
                  onFailure={(responseGoogle) => {
                    console.log(responseGoogle);
                    setToken(false);
                  }}
                  responseType={'code,token'}
                  cookiePolicy={'single_host_origin'}
                  scope={'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events'}
                  redirectUri={'/events'}
                />
              </div>
              <img src={noPatients} alt={'no patients'} className={'image'} />
            </div>
          )}
          {token && !loading && !isEmpty(allEvents) && (
            <div className="patients__container">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '10px',
                  marginTop: '-20px',
                  justifyContent: 'flex-end',
                }}
              >
                <div className="mx-2 text-center" onClick={() => onOpenModal({ ...newEvent })}>
                  <Button category="rounded">Crear Evento</Button>
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '5%' }}></th>
                    <th style={{ width: '25%' }}>Titulo</th>
                    <th style={{ width: '20%' }}>Convenio</th>
                    <th style={{ width: '20%' }}>Fecha/Hora</th>
                    <th style={{ width: '15%' }}>Enlace</th>
                    <th style={{ width: '15%' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((event) => {
                    const { id, title, startAt, covenant, conferenceLink } = { ...event };
                    const { label, name } = { ...covenant };
                    return (
                      <tr key={id}>
                        <td style={{ width: '5%' }}>
                          <div
                            style={{
                              width: '24px',
                              height: '24px',
                              borderRadius: '48px',
                              margin: '10px',
                              marginLeft: '30px',
                              backgroundColor: label,
                            }}
                          />
                        </td>
                        <td style={{ width: '25%' }}>{title}</td>
                        <td style={{ width: '20%' }}>{name}</td>
                        <td style={{ width: '20%' }}>{moment(startAt).format('DD/MM/YYYY - hh:mm A')}</td>
                        <td style={{ width: '15%' }}>
                          {!!conferenceLink && (
                            <a
                              className={'button button--secondary'}
                              style={{ borderRadius: '10px', padding: '6px 12px' }}
                              href={conferenceLink}
                              target={'_blank'}
                            >
                              Unirse al evento
                            </a>
                          )}
                        </td>
                        <td className={'actions-col'} style={{ width: '15%' }}>
                          <Button category="primary" onClick={() => onOpenModal(event)}>
                            Ver detalle
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                  {isEmpty(events) && (
                    <tr className={'empty-state'}>
                      <td>
                        <div className={'patients__no-results'}>
                          <div className={'content'}>
                            <p className={'title'}>No se han encontrado resultados</p>
                            <p className={'subtitle'}>
                              Asegúrate de que el criterio esté escrito correctamente o intenta ajustar el filtro para
                              encontrar lo que estas buscando.
                            </p>
                          </div>
                          <img src={noPatients} alt={'no results'} className={'image'} />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {pages > 1 && (
                <div className={'pagination'}>
                  <div className={'page-item arrow'} onClick={page > 1 ? () => setPage(page - 1) : undefined}>
                    <Icon name={'previous'} className={'arrow-icon'} />
                  </div>
                  {[...new Array(pages)].map((item, index) => {
                    const pg = index + 1;
                    return (
                      <div key={index} className={`page-item ${pg === page && 'active'}`} onClick={() => setPage(pg)}>
                        {pg}
                      </div>
                    );
                  })}
                  <div className={'page-item arrow'} onClick={page < pages ? () => setPage(page + 1) : undefined}>
                    <Icon name={'next'} className={'arrow-icon'} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal modalIsOpen={openModal} closeModal={onCloseModal} modalProps={modalProps} closeable={false}>
        <div>
          <h3 className="modal__title">{selected && selected.id ? '' : 'Crear'} Evento</h3>
          <Formik initialValues={{ ...selected }} validationSchema={eventForm} onSubmit={saveEventForm}>
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} className={'w-100'}>
                <Field
                  type="text"
                  label="Título"
                  name="title"
                  component={TextInput}
                  disabled={selected && selected.id}
                />
                <Field label="Fecha/Hora" name="startAt" component={DateTimeInput} disabled={selected && selected.id} />
                <Field
                  type="text"
                  label="Convenio"
                  name="covenantId"
                  component={CovenantSelect}
                  placeholder="Seleccione un convenio"
                  disabled={selected && selected.id}
                />
                <span className={'span-covenant-description'}>
                  <Field
                    type="text"
                    label="Descripción"
                    name="description"
                    minRows={20}
                    component={TextAreaInput}
                    className={'observations-field'}
                    disabled={selected && selected.id}
                  />
                </span>
                <div className="d-flex justify-content-center mt-4 modal_buttons">
                  <Button category="secondary-rounded" className="mr-3" onClick={onCloseModal}>
                    Cancelar
                  </Button>
                  {selected && !selected.id && (
                    <Button category="rounded" onClick={() => {}}>
                      Guardar
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default Events;

/* eslint-disable import/named */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';
import { LEFT_MENU } from 'utils/leftMenu';

import {
  CreateAvailabilityProvider,
  useCreateAvailabilityContext,
} from 'components/availability/CreateAvailabilityProvider';
import Header from 'components/Header';
import Timezone from 'components/availability/Timezone';
import AvailabilityList from 'components/availability/AvailabilityList';
import SolidButton from 'components/SolidButton';
import AddIntervalsModal from 'components/availability/AddIntervalsModal';

import './availability.scss';

const AvailabilityWrapper = () => (
  <CreateAvailabilityProvider>
    <Availability />
  </CreateAvailabilityProvider>
);

const Availability = () => {
  useRedirectByRole([ROLES.CLINIC, ROLES.SUPER_CLINIC, ROLES.SPECIALIST]);
  const { userRole } = getUserRole();
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { isOpenAddIntervalsModal, onCreateAvailability, isButtonDisabled } = useCreateAvailabilityContext();

  return (
    <>
      <section className="d-flex">
        {LEFT_MENU[userRole]}
        <div className="dashboard__main">
          <Header title={t('leftMenuHeader.availability')} shadow />
          <div className="availability">
            <h2 className="availability_title">{t('availability.title')}</h2>
            <p className="availability_description">{t('availability.description')}</p>
            <section className="availability__content">
              <Timezone />
              <div className="availability__divider" />
              <AvailabilityList />
            </section>
            <section className="availability__buttons">
              <SolidButton className="secondary mr-2" onClick={() => goBack()}>
                {t('button.back')}
              </SolidButton>
              <SolidButton
                disabled={isButtonDisabled}
                className={isButtonDisabled ? 'secondary' : ''}
                onClick={onCreateAvailability}
              >
                {t('button.save')}
              </SolidButton>
            </section>
          </div>
        </div>
      </section>
      {isOpenAddIntervalsModal && <AddIntervalsModal />}
    </>
  );
};

export default AvailabilityWrapper;

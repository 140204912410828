import { useEffect } from 'react';

import { GET_COMPANY } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetCompany = (companyId = '') => {
  const {
    loading = false,
    data = {},
    refetch = () => {},
  } = companyId
    ? useAuthQuery(GET_COMPANY, {
        variables: { companyId },
      })
    : {};
  const { company = {} } = { ...data };

  useEffect(() => {
    if (companyId) {
      refetch();
    }
  }, [companyId]);

  return { loading, companyData: company };
};

import { useEffect } from 'react';
import { GET_SHORT_VIDEOS } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetShortVideos = () => {
  const { loading, data, refetch } = useAuthQuery(GET_SHORT_VIDEOS);
  const { shortVideos: allShortVideos = [] } = { ...data };

  useEffect(() => {
    refetch();
  }, []);
  return { allShortVideos, loading };
};

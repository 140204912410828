export const rowsPerPage = 2;

export const typeOfPlans = [
  { label: 'Rehabilitación', value: 'rehabilitation' },
  { label: 'Pausas Activas', value: 'active_pause' },
  { label: 'Completados', value: 'completed' },
  { label: 'Inactivos', value: 'unactive' },
];

export const handleFilterPlans = (
  state,
  { completedPlans, unactivePlans, rehabilitationPlans, activePausePlans, plans, filterList },
) => {
  const [rehabilitation, activePause, completed, unactive] = filterList.map(({ value }) => value);
  switch (state.value) {
    case rehabilitation:
      return rehabilitationPlans;
    case activePause:
      return activePausePlans;
    case completed:
      return completedPlans;
    case unactive:
      return unactivePlans;
    default:
      return plans;
  }
};

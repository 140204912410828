import React from 'react';
import { object, string } from 'yup';
import { Formik, Field, Form } from 'formik';
import { TextInput, TextAreaInput } from 'components/FormComponents';
import { requiredMessage, urlMessage } from 'utils/validationSchema';

const validationSchema = object().shape({
  label: string().trim().required(requiredMessage),
  gif: string().trim().url(urlMessage),
});

const TextForm = (props) => {
  const { onUpdateField, data, footer } = { ...props };
  const { type: typeObj = {} } = { ...data };
  const { type = '' } = { ...typeObj };
  const isBlockType = type === 'block';

  return (
    <Formik initialValues={data} onSubmit={onUpdateField} validationSchema={validationSchema}>
      {({ setFieldValue }) => (
        <Form className="form">
          <Field
            className={`${isBlockType ? 'block-type' : ''}`}
            type="text"
            label="Enunciado de la pregunta"
            name="label"
            minRows={10}
            component={isBlockType ? TextAreaInput : TextInput}
          />
          <Field type="text" label="Gif url" name="gif" component={TextInput} />
          {!['block'].includes(type) && (
            <>
              <Field
                name="required"
                render={({ field }) => (
                  <div className="form__input-container assessment__checkbox__input">
                    <input
                      {...field}
                      type="checkbox"
                      checked={field.value}
                      onChange={() => {
                        setFieldValue('required', !field.value);
                      }}
                    />
                    <label className="form__label">Obligatorio</label>
                  </div>
                )}
              />
              <Field
                name="multi"
                render={({ field }) => (
                  <div className="form__input-container assessment__checkbox__input">
                    <input
                      {...field}
                      type="checkbox"
                      checked={field.value}
                      onChange={() => {
                        setFieldValue('multi', !field.value);
                      }}
                    />
                    <label className="form__label">Varias líneas</label>
                  </div>
                )}
              />
            </>
          )}
          {footer}
        </Form>
      )}
    </Formik>
  );
};

export default TextForm;

import React, { useState, useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

import { useFormInput } from 'utils/hooks';
import { useConversationsContext } from 'components/conversations/ConversationsProvider';
import { sortConversations } from '../utils';

import ConversationInfo from '../ConversationInfo';

const ConversationsList = () => {
  const { conversations } = useConversationsContext();
  const sortConversationsByDate = conversations.sort(sortConversations);
  const searchConversations = useFormInput('');
  const { value = '' } = { ...searchConversations };

  const [filteredConversations, setFilteredConversations] = useState([]);

  useEffect(() => {
    let temporal = sortConversationsByDate;
    if (value) {
      temporal = sortConversationsByDate.filter((conversation) =>
        `${conversation.friendlyName}`.toLowerCase().includes(value.toLowerCase()),
      );
    }
    setFilteredConversations(temporal);
  }, [sortConversationsByDate, value]);

  return (
    <section className="conversations__list">
      <div className="conversations__list_search">
        <AiOutlineSearch />
        <input {...searchConversations} placeholder="Search a Conversation..." />
      </div>
      <ul className="conversations__list_items">
        {(filteredConversations || []).map((conversation) => (
          <ConversationInfo key={conversation.sid} conversation={conversation} />
        ))}
      </ul>
    </section>
  );
};

export default ConversationsList;

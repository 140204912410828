import { ROLES } from 'utils/constants';

const PARTNER_PRICING = {
  [ROLES.CLINIC]: {
    USD: 5,
    COP: 10000,
  },
  [ROLES.COMPANY]: {
    USD: 10,
    COP: 10000,
    EUR: 10,
  },
};

const COMPANY_PLANS = {
  SMALL: 1,
  MEDIUM: 0.85,
  LARGE: 0.75,
};

export const getPricing = ({ userRole, currency, defaultPrice = 5 }) =>
  PARTNER_PRICING[userRole][currency] || defaultPrice;

export const getCompanyDiscount = (patients) => {
  let percentage = COMPANY_PLANS.SMALL;
  if (patients <= 50) {
    percentage = COMPANY_PLANS.SMALL;
  } else if (patients > 50 && patients <= 250) {
    percentage = COMPANY_PLANS.MEDIUM;
  } else {
    percentage = COMPANY_PLANS.LARGE;
  }
  return percentage;
};

export const getValues = ({ patients, partnerPrice, period, discount, isClinic }) => {
  let totalPrice;
  let savings;

  if (isClinic) {
    totalPrice = patients * partnerPrice * period * (1 - (discount ? discount / 100 : 0));
    savings = patients * partnerPrice * period - totalPrice;
  } else {
    totalPrice = patients * partnerPrice * period * getCompanyDiscount(patients);
    savings = patients * partnerPrice * period * (1 - getCompanyDiscount(patients));
  }

  return { totalPrice, savings };
};

export const getPercentage = (patients) => Math.floor((1 - getCompanyDiscount(patients)) * 100);

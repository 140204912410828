import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { propOr, isEmpty, pathOr } from 'ramda';
import Loader from 'react-loader-spinner';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useMutation } from '@apollo/react-hooks';

import { CREATE_SUBSCRIPTION } from 'apollo/mutations';
import { useAuthQuery } from 'utils/hooks';
import { getUserRole } from 'utils/isClinic';
import showNotification from 'utils/showNotification';
import { FETCH_BY_ROLE, GET_PARTNER_BY_ROLE, ROLES, ROUTES } from 'utils/constants';
import { useToggle } from 'utils/hooks/useToggle';
import { BILLING_STEPS } from 'views/Billing/billingSteps';

import { PaymentPlan, PaymentMethod, PaymentData, PaymentSummary, PaymentSuccess } from 'components/Billing';

import './billing.scss';

const Billing = () => {
  const userRole = getUserRole();
  const isCompany = userRole === ROLES.COMPANY;
  const { replace } = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [step, setStep] = useState(BILLING_STEPS.PLAN_SELECTION);
  const [plan, setPlan] = useState();
  const [loading, setLoading] = useToggle();
  const [billingData, setBillingData] = useState({});
  const [patients, setPatients] = useState(10);
  const [method, setMethod] = useState(0);

  const { data, refetch } = useAuthQuery(FETCH_BY_ROLE[userRole]);
  const partner = propOr({}, GET_PARTNER_BY_ROLE[userRole])(data);
  const { profile = {} } = { ...partner };
  const { currency = 'usd' } = { ...profile };
  const [createSubscription] = useMutation(CREATE_SUBSCRIPTION);

  const onSelectPlan = (selectedPlan) => {
    setPlan(selectedPlan);
    setStep(BILLING_STEPS.PAYMENT_METHOD);
  };
  const onDashboard = () => replace(ROUTES.DASHBOARD);
  const doPayment = async () => {
    try {
      setLoading();
      const {
        address,
        country = '',
        phone = '',
        email = '',
        firstName = '',
        lastName = '',
        paymentMethod,
      } = {
        ...billingData,
      };
      const { id: paymentMethodId = '' } = { ...paymentMethod };
      const { period: intervalCount = 1, couponId } = { ...plan };
      const formatCountry = country.slice(country.indexOf('+'));
      let variables = {
        customerInput: { email, address, name: `${firstName} ${lastName}`, phone: `${formatCountry} ${phone}` },
        patientNumber: patients,
        paymentMethodId,
        intervalCount,
        ...(isCompany && {
          userType: userRole,
        }),
      };
      if (couponId) {
        variables = { ...variables, coupon: couponId };
      }
      const response = await createSubscription({ variables });
      const messages = pathOr([], ['data', 'createClinicSubscription', 'errors'])(response);
      const emptyErrors = isEmpty(messages);
      setLoading();
      if (emptyErrors) {
        setStep(BILLING_STEPS.SUCCESS);
      } else {
        showNotification({ type: 'error', messages });
      }
    } catch (error) {
      setLoading();
      showNotification({ type: 'error', messages: ['Ha ocurrido un error procesando el pago, intenta nuevamente'] });
    }
  };
  const onBillingData = async (dataToSend) => {
    const { address: line1, country = '', phone = '', email = '', firstName = '', lastName = '' } = dataToSend;
    const formatCountry = country.slice(country.indexOf('+'));
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        address: {
          line1,
        },
        email,
        name: `${firstName} ${lastName}`,
        phone: `${formatCountry} ${phone}`,
      },
    });
    if (!error) {
      setStep(BILLING_STEPS.CHECKOUT);
      setBillingData({ ...dataToSend, paymentMethod });
    } else {
      console.error(error);
      const { message = 'Error validando el medio de pago, porfavor verificar' } = { ...error };
      showNotification({ type: 'error', messages: [message] });
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  const onAddCoupon = (couponDiscount, couponId) => {
    setPlan((prevState) => ({ ...prevState, couponDiscount, couponId }));
  };

  return (
    <div className="d-flex hvh-100 billing">
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={loading} />
      {step === BILLING_STEPS.PLAN_SELECTION && (
        <PaymentPlan
          userRole={userRole}
          currency={currency.toUpperCase()}
          patients={patients}
          setPatients={setPatients}
          onSelectPlan={onSelectPlan}
        />
      )}
      {step === BILLING_STEPS.PAYMENT_METHOD && (
        <PaymentMethod
          plan={{ ...plan, patients }}
          onBack={() => setStep(BILLING_STEPS.PLAN_SELECTION)}
          method={method}
          setMethod={setMethod}
          onNext={() => setStep(BILLING_STEPS.BILLING_DATA)}
        />
      )}
      {step === BILLING_STEPS.BILLING_DATA && (
        <PaymentData
          plan={{ ...plan, patients }}
          onBack={() => setStep(BILLING_STEPS.PAYMENT_METHOD)}
          method={method}
          billingData={billingData}
          onNext={onBillingData}
          handleAddCoupon={onAddCoupon}
        />
      )}
      {step === BILLING_STEPS.CHECKOUT && (
        <PaymentSummary
          plan={{ ...plan, patients }}
          billingData={billingData}
          method={method}
          stripe={stripe}
          onNext={doPayment}
          onPlan={() => setStep(BILLING_STEPS.PLAN_SELECTION)}
          onMethod={() => setStep(BILLING_STEPS.PAYMENT_METHOD)}
          onBack={() => setStep(BILLING_STEPS.BILLING_DATA)}
        />
      )}
      {step === BILLING_STEPS.SUCCESS && <PaymentSuccess onNext={onDashboard} />}
    </div>
  );
};

export default Billing;

import { useState, useEffect, useMemo } from 'react';
import { Country } from 'country-state-city';

export const useGetCountries = () => {
  const [countries, setCountries] = useState([]);

  const getCountries = async () => {
    try {
      const result = await Country.getAllCountries();
      if (Array.isArray(result) && result.length) {
        setCountries(result);
      }
    } catch (error) {
      console.error('ERROR: ', error);
      setCountries([]);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const getDialingCode = useMemo(() => {
    const dialingCodeList = countries.map(({ name, phonecode, flag }) => {
      const formatPhoneCode = phonecode.startsWith('+') ? phonecode : `+${phonecode}`;
      return { label: `${formatPhoneCode} ${flag}`, value: `${name} ${formatPhoneCode}` };
    });
    return dialingCodeList;
  }, [countries]);
  return { countries, dialingCodeList: getDialingCode };
};

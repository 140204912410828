import { isEmpty } from 'ramda';

export const formatDate = (value) => {
  if (!value) return '---';
  const d = new Date(value);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return [year, month, day].join('-');
};

export const mapValue = (value, type) => {
  switch (type) {
    case 'verification':
      return value ? 'Verdadero' : 'Falso';
    case 'date':
      return formatDate(value);
    case 'pointed-multiple-selection':
      return isEmpty(value) ? '---' : `${value.label} (${value.value} puntos)`;
    default:
      return value || '---';
  }
};

export const buildCustomData = ({ painLevel, pillsLevel, urinaryDisfunction, functionality, sleepQuality }) => [
  {
    value: functionality,
    type: 'multiple-selection',
    label: 'Funcionalidad: ¿Cuáles de estas actividades no es capaz de realizar?',
  },
  { value: pillsLevel, label: 'Uso de medicamentos para disminuir el dolor en la última semana' },
  { value: painLevel, label: '¿De uno a 10 cómo califica hoy el dolor?' },
  { value: sleepQuality, label: '¿Cómo es la calidad subjetiva del sueño en la última semana?' },
  { value: urinaryDisfunction, label: 'Nivel del dolor' },
];

export const noGroupedContent = (content = []) =>
  content.map((field) => {
    const { id = '', label = '', value = '', type = 'text' } = { ...field };
    return (
      <div className="item" key={id}>
        {!['block'].includes(type) && <p className="label">{label}</p>}
        {type === 'multiple-selection' ? (
          <div className="tags">
            {`${value}`.split(', ').map((item) => (
              <span className="tag" key={item}>
                {item || '---'}
              </span>
            ))}
          </div>
        ) : (
          <p className="value">{mapValue(value, type)}</p>
        )}
      </div>
    );
  });

export const groupedContent = ({ content = [], groupPoints = [] }) =>
  content.map((group) => {
    const { id = '', groupTitle = '', questions = [] } = { ...group };
    const getPoints = groupPoints.find(({ id: pointedGroupId }) => pointedGroupId === id);
    return (
      <div key={id} className="answered-group">
        <div className="group-title">
          {groupTitle !== 'single' && <p className="group-title_title">{groupTitle}</p>}
          {!!getPoints && <p className="group-title_points">(Puntos: {getPoints.points})</p>}
        </div>
        {questions.map((question) => {
          const { id: questionId = '', label = '', value = '', type = 'text' } = { ...question };
          return (
            <div className="item" key={questionId}>
              {!['block'].includes(type) && <p className="label">{label}</p>}
              {type === 'multiple-selection' ? (
                <div className="tags">
                  {`${value}`.split(', ').map((item) => (
                    <span className="tag" key={item}>
                      {item || '---'}
                    </span>
                  ))}
                </div>
              ) : (
                <p className="value">{mapValue(value, type)}</p>
              )}
            </div>
          );
        })}
      </div>
    );
  });

export const modalProps = {
  content: {
    width: '100%',
    maxWidth: '768px',
    maxHeight: '95vh',
    outline: 'none',
    padding: '0px !important',
  },
};

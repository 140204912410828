import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Field, Form } from 'formik';
import { isEmpty, pathOr } from 'ramda';
import { FaRegEdit } from 'react-icons/fa';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { updatePatientPersonalInfo } from 'utils/validationSchema';
import { capitalize } from 'utils/string';
import { useTeamMembersList } from 'utils/hooks';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';
import { getUserRole } from 'utils/getUserRole';
import { UPDATE_PATIENT_PROFILE } from 'apollo/mutations';
import { useConversationsContext } from 'components/conversations/ConversationsProvider';

import { TextInput, NumberInput, DateInput, CovenantSelect, MultiSelect } from 'components/FormComponents';
import SolidButton from 'components/SolidButton';
import ChangeStatusAlert from 'components/Patients/Patient/ChangeStatusAlert';
import CreateConversationModal from 'components/Patients/Patient/CreateConversationModal';
import CreateBookingRequest from 'components/Patients/Patient/CreateBookingRequest';

import maleAvatar from 'assets/images/patient-male.svg';
import femaleAvatar from 'assets/images/patient-female.svg';

const PersonalInformation = ({ patient, onUpdateStatus, selectedId, refetch }) => {
  const { t } = useTranslation();
  const {
    assessment,
    cardId,
    email,
    gender,
    birthday,
    status,
    name,
    covenant,
    phone,
    teamMember,
    id: patientId,
  } = { ...patient };
  const { id: covenantId = null, label: covenantColor = '#ffffff', name: covenantName = 'Ninguno' } = { ...covenant };
  const { id: teamMemberId = null, name: teamMemberName = '' } = { ...teamMember };
  const { diagnostic } = { ...assessment };
  const initialValues = { name, cardId, birthday, email, phone, covenantId, teamMemberId, gender };
  const { userRole, isSuperClinic } = getUserRole();

  const { teamMembersOptions = [] } = useTeamMembersList(userRole);

  const [updatePatientProfile] = useMutation(UPDATE_PATIENT_PROFILE);

  const [isAlert, setIsAlert] = useToggle();
  const [showSpinner, setSpinner] = useState(false);
  const [isEditPersInfo, setIsEditPersInfo] = useToggle();
  const [isStartChat, setIsStartChat] = useToggle();
  const [isBookingRequest, setIsBookingRequest] = useToggle();
  const { conversations } = useConversationsContext();
  const findEmployeeConversation = conversations.find(({ attributes }) => attributes.employee_id === patientId);

  useEffect(() => {
    if (isEditPersInfo) {
      setIsEditPersInfo();
    }
  }, [selectedId]);

  const onUpdatePatientProfile = async (values) => {
    const { covenantId } = { ...values };
    const emptyCovenant = isEmpty(covenantId);
    setSpinner(true);
    const response = await updatePatientProfile({
      variables: {
        patientId,
        patientProfile: emptyCovenant ? { ...values, covenantId: null } : values,
      },
    });

    const errors = pathOr([], ['data', 'updatePatientProfile', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? ['Se ha actualizado el perfil del paciente exitosamente.'] : errors,
    });

    if (emptyErrors) {
      setIsEditPersInfo();
    }
  };

  const initialGender = gender === 'Hombre' ? 'M' : gender === 'Mujer' ? 'F' : '---';

  return (
    <>
      {!isEditPersInfo && (
        <div className="patient-container__personal">
          <div className="title-avatar">
            <img src={gender === 'Hombre' ? maleAvatar : femaleAvatar} alt="patient avatar" className="avatar" />
            <div>
              <div className="title-avatar__name">
                <h3 className="name-line">{name}</h3>
                <SolidButton className={`${status} slim min-width-100`} onClick={setIsAlert}>
                  {status === 'active' ? 'Activo' : 'Inactivo'}
                </SolidButton>
                {!findEmployeeConversation && isSuperClinic && (
                  <SolidButton className="slim min-width-100" onClick={setIsStartChat}>
                    Crear Chat
                  </SolidButton>
                )}
                <SolidButton className="slim min-width-100" onClick={setIsBookingRequest}>
                  {t('patient.personalInfo.btns.book')}
                </SolidButton>
              </div>
              <p className="diagnostic-title">
                Diagnóstico <span>{diagnostic ? capitalize(diagnostic) : '---'}</span>
              </p>
            </div>
            {!!name && <FaRegEdit onClick={setIsEditPersInfo} className="edit-pers-info" />}
          </div>
          <div className="info">
            <div className="info-item">
              <p className="info-label">Documento de Identidad</p>
              <p className="info-value dni">{cardId || '---'}</p>
            </div>
            <div className="info-item">
              <p className="info-label">Correo electrónico</p>
              <p className="info-value">{email}</p>
            </div>
            {!!phone && (
              <div className="info-item">
                <p className="info-label">Teléfono</p>
                <p className="info-value">{phone}</p>
              </div>
            )}
            <div className="info-item">
              <p className="info-label">Genéro</p>
              <p className="info-value gender">{initialGender}</p>
            </div>
            <div className="info-item">
              <p className="info-label">Convenio</p>
              <div className="info-value info-color-value">
                <div
                  className="info-color"
                  style={{
                    backgroundColor: covenantColor,
                    borderColor: covenantColor === '#ffffff' ? '#818181' : '#fff',
                  }}
                />
                {covenantName}
              </div>
            </div>
            <div className="info-item">
              <p className="info-label">Fecha de nacimiento</p>
              <p className="info-value">{birthday ? moment(birthday).format('YYYY / MM / DD') : '---'}</p>
            </div>
            <div className="info-item">
              <p className="info-label">Profesional</p>
              <p className="info-value">{teamMemberName || '---'}</p>
            </div>
          </div>
        </div>
      )}
      {isEditPersInfo && (
        <div className="patient-container__edit">
          <div className="patient-container__edit__title">
            <p>Editar información personal del paciente</p>
            <AiOutlineCloseCircle onClick={setIsEditPersInfo} className="edit-pers-info-close-icon" />
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={onUpdatePatientProfile}
            validationSchema={updatePatientPersonalInfo}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="form">
                <Field type="text" label="Nombre" name="name" component={TextInput} />
                <Field label="Documento de Identidad" name="cardId" component={TextInput} />
                <Field label="Fecha de nacimiento" name="birthday" component={DateInput} />
                <Field type="email" label="Correo Electrónico" name="email" component={TextInput} />
                <Field label="Teléfono" name="phone" component={NumberInput} />
                <Field
                  type="text"
                  label="Convenio"
                  name="covenantId"
                  placeholder="Seleccione un convenio (si aplica)"
                  component={CovenantSelect}
                />
                {!isEmpty(teamMembersOptions) && (
                  <Field
                    type="text"
                    label="Profesional"
                    name="teamMemberId"
                    component={MultiSelect}
                    options={teamMembersOptions}
                    placeholder="Seleccione el Profesional para el acompañamiento"
                  />
                )}
                <div style={{ marginBottom: '20px' }}>
                  <span className="form__label d-block mb-2">Género</span>
                  <div className="form-row">
                    <div className="col-auto">
                      <div className="form-check form-check-inline align-items-baseline">
                        <label htmlFor="male" className="form__radio">
                          <Field id="male" name="gender" type="radio" value="Hombre" /> Hombre
                        </label>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="form-check form-check-inline align-items-baseline">
                        <label htmlFor="female" className="form__radio">
                          <Field id="female" name="gender" type="radio" value="Mujer" /> Mujer
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="edit-pers-info-buttons">
                  <SolidButton onClick={setIsEditPersInfo} type="button" className="secondary min-width-100 mr-3">
                    Cancelar
                  </SolidButton>
                  <SolidButton type="submit" className="min-width-100">
                    Actualizar
                  </SolidButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      <ChangeStatusAlert
        isAlert={isAlert}
        name={name}
        updateStatus={() => onUpdateStatus(setIsAlert)}
        toggleAlert={setIsAlert}
      />
      <CreateConversationModal
        isStartChat={isStartChat}
        name={name}
        patientId={patientId}
        toggleStartChat={setIsStartChat}
      />
      {isBookingRequest && (
        <CreateBookingRequest
          open={isBookingRequest}
          toggleModal={setIsBookingRequest}
          patientId={patientId}
          refetch={refetch}
        />
      )}
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={showSpinner} />
    </>
  );
};

export default PersonalInformation;

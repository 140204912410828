import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import { useFormInput } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROUTES, ROLES } from 'utils/constants';
import { useGetReadings } from 'components/createTemplate/service';
import { BLOCK_CONTENT } from 'components/readings/service/constants';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SearchFilter from 'components/SearchFilter';
import SolidButton from 'components/SolidButton';
import ReadingRow from 'components/readings/ReadingRow';
import NoReadingsFound from 'components/Forms/NoFormsFound';
import { NoMembers } from 'components/EmptyState';

import noTemplates from 'assets/images/no-data.svg';

const Readings = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const { push } = useHistory();
  const { loading, allReadings } = useGetReadings();

  const [readings, setReadings] = useState([]);
  const searchReadings = useFormInput('');
  const { value = '' } = searchReadings;

  useEffect(() => {
    let temporal = allReadings;
    if (value) {
      temporal = allReadings.filter((reading) => {
        const { title } = reading;
        return `${title}`.toLowerCase().includes(value.toLowerCase());
      });
    }
    setReadings(temporal);
  }, [allReadings, value]);

  const handleChangeRoute = () => {
    push(`${ROUTES.READING}?block_content=${BLOCK_CONTENT.TRUE}`);
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenu />
        <div className="dashboard__main">
          <Header title={t('leftMenuHeader.readings')} shadow />
          <div className="templates__content dashboard__content--patients">
            {!loading && isEmpty(allReadings) && (
              <NoMembers
                title="¡Aún no tienes lecturas configuradas!"
                subtitle="Inicia configurando una lectura para la valoración de tus pacientes."
                button="Crear Lectura"
                handleClick={handleChangeRoute}
                image={noTemplates}
              />
            )}
            {!loading && !isEmpty(allReadings) && (
              <div className="forms readings">
                <div className="forms__search">
                  <SearchFilter searchItems={searchReadings} placeholder="Buscar una Lectura" />
                  <SolidButton onClick={handleChangeRoute} className="slim">
                    Crear Lectura
                  </SolidButton>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '30%' }}>Imagen</th>
                      <th style={{ width: '50%' }}>Título</th>
                      <th style={{ width: '20%' }}> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(readings || []).map((reading) => {
                      const { id } = { ...reading };
                      return <ReadingRow key={id} reading={reading} />;
                    })}
                    {isEmpty(readings) && <NoReadingsFound />}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </section>
      <Loader className="spinner" type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
    </>
  );
};

export default Readings;

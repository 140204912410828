import { useState } from 'react';

export const useShowComponent = (initialState = false) => {

  const [componentIsVisible, setComponentIsVisible] = useState(initialState);

  const toggleComponent = () => {
    setComponentIsVisible(prevState => !prevState);
  };

  return {
    componentIsVisible,
    toggleComponent,
  }
};

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { pathOr, isEmpty } from 'ramda';
import Loader from 'react-loader-spinner';

import { NewUserForm, NewUserIntro, NewUserCompleted, NEW_USER_STEPS } from 'components/NewUser';
import NoUrlFound from 'components/NoUrlFound';

import { PARTNER_PATIENT_SIGNUP } from 'apollo/mutations';
import { VALIDATE_TOKEN } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';
import { useToggle } from 'utils/hooks/useToggle';
import showNotification from 'utils/showNotification';

import logoHealthphy from 'assets/images/logo-blue.svg';

import './new-user.scss';

const NewUser = () => {
  const { userToken } = useParams();
  const { loading, data = {} } = useAuthQuery(VALIDATE_TOKEN, {
    variables: { token: userToken },
  });

  const { validateToken = {} } = { ...data };
  const { program_description: description = '', valid = false } = { ...validateToken };

  const [step, setStep] = useState(NEW_USER_STEPS.INTRO);
  const [spinner, setSpinner] = useToggle();
  const [partnerPatientSignup, { data: userCredentialsResponse }] = useMutation(PARTNER_PATIENT_SIGNUP);
  const { partnerPatientSignup: userCredentials = {} } = { ...userCredentialsResponse };

  const handleStep = (currentStep) => {
    switch (currentStep) {
      case 'form':
        setStep(NEW_USER_STEPS.FORM);
        break;
      case 'completed':
        setStep(NEW_USER_STEPS.COMPLETED);
        break;
      default:
        setStep(NEW_USER_STEPS.INTRO);
        break;
    }
  };

  const handleSubmitNewUser = async ({ name, email }) => {
    setSpinner(true);
    const response = await partnerPatientSignup({
      variables: {
        name,
        email,
        token: userToken,
      },
    });

    const errors = pathOr([], ['data', 'partnerPatientSignup', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);
    if (!emptyErrors) showNotification({ type: 'error', messages: errors });

    if (emptyErrors) handleStep('completed');
  };

  if (loading) return null;

  if (!valid) return <NoUrlFound message="Token inválido" />;

  return (
    <>
      <section className="new-user">
        <div className="new-user__content">
          <header className="new-user__content__logo">
            <img src={logoHealthphy} alt="HealthPhy Logo" />
          </header>
          {step === NEW_USER_STEPS.INTRO && <NewUserIntro description={description} handleStep={handleStep} />}
          {step === NEW_USER_STEPS.FORM && <NewUserForm handleSubmitNewUser={handleSubmitNewUser} />}
          {step === NEW_USER_STEPS.COMPLETED && <NewUserCompleted credentials={userCredentials} />}
        </div>
        <footer className="new-user__footer">©{new Date().getFullYear()} - HealthPhy</footer>
      </section>
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || spinner}
      />
    </>
  );
};

export default NewUser;

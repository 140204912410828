import React from 'react';
import { Bar } from 'react-chartjs-2';

import './satisfactionLevel.scss';


const SatisfactionLevel = ({satisfactionLevel}) => {

	const { last7DaysSatisfactionLevel = {} } = { ...satisfactionLevel }
	
	const satisfactionLevels = (hash) => {
		let levelsArray = [];
		Object.entries(hash).forEach(([key, value]) => {
		  levelsArray.push(value);
		});
		return levelsArray;
	}

	const data = {
		labels: Object.keys(last7DaysSatisfactionLevel),
		datasets: [
			{
				label: 'Nivel de satisfacción',
				data: satisfactionLevels(last7DaysSatisfactionLevel),
				backgroundColor: '#46C2AD',
				barThickness: 40,
			},
		]
	}

	const options = {
		legend: {
			display: false,
		},
		scales: {
			yAxes: [
				{
					ticks: {
						min: 0,
						max: 5,
						setpSize: 1,
					},
				},
			],
			xAxes: [
				{
					gridLines: {
						drawOnChartArea: false
					}
				}
			] 
		},
	}

  return (
    <div className={'satisfaction-level'}>
    	<p>Nivel de satisfacción por día</p>
  		<Bar data={data} options={options}/>
    </div>
  )
}

export default SatisfactionLevel;
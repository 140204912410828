import React from 'react';
import { useTranslation } from 'react-i18next';

import { currencyFormat } from 'utils/string';
import { getUserRole } from 'utils/isClinic';
import { ROLES } from 'utils/constants';
import { getPercentage } from 'components/Billing/PaymentPlan/payment-plan-utils';
import { getGranTotal } from 'components/Billing/utils';

import Icon from 'components/Icon';
import Button from 'components/Button';

import stripe from 'assets/images/stripe.svg';

const Summary = (props) => {
  const { plan = {}, useWompi = true, onNext = () => {} } = { ...props };
  const {
    name = '',
    patients = 0,
    partnerPrice = 0,
    discount = 0,
    period = 0,
    totalPrice = 0,
    currency = 'USD',
    couponDiscount,
  } = { ...plan };

  const { t } = useTranslation();
  const userRole = getUserRole();
  const isClinic = userRole === ROLES.CLINIC;

  const subtotal = partnerPrice * patients * period;
  const { couponDiscountValue, totalPriceWithCoupon } = getGranTotal(totalPrice, couponDiscount);

  return (
    <div className="billing__content__summary">
      {useWompi && (
        <div className="billing__content__panel wompi__info">
          <img src={stripe} alt="stripe logo" />
        </div>
      )}
      <div className="billing__content__panel payment__details">
        <div className="payment__details__title">
          <Icon name="receipt" className="icon" />
          <p>{t('billing.summary.title')}</p>
        </div>
        <div className="payment__details__body">
          <div className="summary__row">
            <p className="label">{t('billing.summary.selectedPlan')}</p>
            <p className="value">
              {name} - {patients} {isClinic ? t('billing.users.patients') : t('billing.users.employees')}
            </p>
          </div>
          <div className="summary__row">
            <p className="label">{t('billing.summary.subtotal')}</p>
            <p className="value">
              {currencyFormat(subtotal)} {currency}
            </p>
          </div>
          <div className="summary__row">
            <p className="label">
              {t('billing.summary.discount')} ({isClinic ? discount : getPercentage(patients)}%)
            </p>
            <p className="value discount">
              - {currencyFormat(subtotal - totalPrice)} {currency}
            </p>
          </div>
          {couponDiscount && (
            <div className="summary__row">
              <p className="label">
                {t('billing.summary.coupon')} ({couponDiscount}%)
              </p>
              <p className="value discount">
                - {currencyFormat(couponDiscountValue)} {currency}
              </p>
            </div>
          )}
          <div className="summary__row">
            <p className="label">{t('billing.summary.total')}</p>
            <p className="value total__price">
              <span>{currencyFormat(totalPriceWithCoupon)}</span> {currency}
            </p>
          </div>
          <div className="button__container">
            <Button type="submit" category="rounded" className="button__next" onClick={onNext}>
              {t('button.next')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;

import React from 'react';
import { usePagination, DOTS } from 'utils/hooks/usePagination';
import Icon from 'components/Icon';
import './pagination.scss';

const Pagination = (props) => {
  const { onPageChange, totalPages, siblingCount = 1, currentPage } = { ...props };

  const paginationRange = usePagination({
    currentPage,
    totalPages,
    siblingCount,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  if (currentPage === 0 || paginationRange.length < 2) return null;

  return (
    <ul className="pagination-container">
      <li className={`pagination-container--item arrow ${currentPage === 1 ? 'disabled' : ''}`} onClick={onPrevious}>
        <Icon name="previous" />
      </li>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <li className="pagination-container--item">&#8230;</li>;
        }
        return (
          <li
            className={`pagination-container--item ${pageNumber === currentPage ? 'active' : ''}`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li className={`pagination-container--item arrow ${currentPage === lastPage ? 'disabled' : ''}`} onClick={onNext}>
        <Icon name="next" />
      </li>
    </ul>
  );
};

export default Pagination;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft } from 'react-icons/fa';
import { VscPreview } from 'react-icons/vsc';
import { isEmpty } from 'ramda';

import { useToggle } from 'utils/hooks/useToggle';
import { useCreateReadingContext } from 'components/readings/CreateReadingProvider';
import { formatDataToSend, formatBackgroundImage } from 'components/readings/service/utils';

import SolidButton from 'components/SolidButton';
import EditBuilderTitle from '../EditBuilderTitle';
import ReadingPreview from '../ReadingPreview';

const BuilderTitle = () => {
  const { t } = useTranslation();
  const { goBack, createUpdateReadingBuilder, readingId, builderState } = useCreateReadingContext();
  const { backgroundImage, title, content } = { ...builderState };
  const previewData = {
    title,
    backgroundImage: formatBackgroundImage(backgroundImage),
    content: formatDataToSend(content),
  };

  const [isEditTitle, setIsEditTitle] = useToggle(!readingId);
  const [isReadingPreview, setIsReadingPreview] = useToggle();

  const isDisableBtn = !title || isEmpty(content);

  return (
    <>
      <header className="builder__title">
        <FaChevronLeft className="builder__title_back" onClick={goBack} />
        <button type="button" onClick={setIsEditTitle}>
          <h2 className="builder__title_label">{title}</h2>
        </button>
        <VscPreview className="builder__title_prevBtn" onClick={setIsReadingPreview} />
        <SolidButton
          className={`ml-auto ${isDisableBtn ? 'disabled' : ''}`}
          disabled={isDisableBtn}
          onClick={createUpdateReadingBuilder}
        >
          {t('button.save')}
        </SolidButton>
      </header>
      {isEditTitle && <EditBuilderTitle modalIsOpen={isEditTitle} toggleModal={setIsEditTitle} />}
      {isReadingPreview && (
        <ReadingPreview modalIsOpen={isReadingPreview} toggleModal={setIsReadingPreview} previewData={previewData} />
      )}
    </>
  );
};

export default BuilderTitle;

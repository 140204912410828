import { useState, useCallback } from 'react';

export const useToggle = (initialValue = false) => {
  const [toggleValue, setToggleValue] = useState(initialValue);

  const toggle = useCallback(() => {
    setToggleValue((value) => !value);
  }, []);

  return [toggleValue, toggle];
};

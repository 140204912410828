import React from 'react';
import { toast } from 'react-toastify';
import Notification from '../components/Notification';

const showNotification = ({ type, messages }) => {
  toast(<Notification type={type} messages={messages} />, {
    type,
    className: `notifications ${type}`,
    closeButton: false,
  });
};

export default showNotification;

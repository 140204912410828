import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

const VerificationInput = (props) => {
  const { label, gif, name, ...rest } = props;

  return (
    <div className="question">
      <div className="question__verification">
        <div className="question__validation question__verification_label">
          <label htmlFor={name}>{label}</label>
        </div>
        <Field id={name} name={name} {...rest}>
          {({ field, form }) => (
            <label className={`radio radio--${field.value ? 'checked' : ''} verification`}>
              <input
                id={name}
                type="checkbox"
                checked={field.value}
                onChange={() => {
                  form.setFieldValue(name, !field.value);
                }}
              />
              <span className="checkmark-box verification" />
            </label>
          )}
        </Field>
      </div>
      {!!gif && (
        <div className="question__gif">
          <img src={gif} alt="this is a Gif" />
        </div>
      )}
    </div>
  );
};

VerificationInput.propTypes = {
  label: PropTypes.string.isRequired,
  gif: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default VerificationInput;

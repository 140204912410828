import React, { useState, useEffect, useRef } from 'react';
import { Formik, Field, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { FaFileAudio } from 'react-icons/fa';

import { useCreateUpdateAudio, useGetAudio } from 'components/audio/service';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import { queryParams } from 'utils/string';
import { createAudio } from 'utils/validationSchema';
import { formatBackgroundImage } from 'components/readings/service/utils';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SolidButton from 'components/SolidButton';
import { TextInput, TextAreaInput, ImageInput } from 'components/FormComponents';

import './create-audio.scss';

const CreateAudio = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const {
    goBack,
    location: { search },
  } = useHistory();
  const { id: audioId = '' } = queryParams(search);

  const { createUpdateAudio, spinner } = useCreateUpdateAudio(audioId);
  const { audio, loading } = useGetAudio(audioId);
  const { title = '', content = '', file = '', thumbnailImage = {}, fileUs } = audio;
  const { thumb } = thumbnailImage;
  const initialValues = { title, content, file, thumbnailImage: thumb };
  const formatedThumbImage = formatBackgroundImage(thumb);

  const [audioUrl, setAudioUrl] = useState('');
  const [audioUsUrl, setAudioUsUrl] = useState('');
  useEffect(() => {
    if (file) {
      setAudioUrl(file);
    }
  }, [file]);

  useEffect(() => {
    if (fileUs) {
      setAudioUsUrl(fileUs);
    }
  }, [fileUs]);

  const fileInput = useRef();
  const fileUsInput = useRef();
  const reader = new FileReader();
  const readerUs = new FileReader();

  reader.onloadend = () => {
    setAudioUrl(reader.result);
  };

  readerUs.onloadend = () => {
    setAudioUsUrl(readerUs.result);
  };

  const handleFileInputClick = () => {
    if (fileInput) {
      fileInput.current.click();
    }
  };

  const handleFileUsInputClick = () => {
    if (fileUsInput) {
      fileUsInput.current.click();
    }
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenu />
        <div className="dashboard__main">
          <Header title={audioId ? t('leftMenuHeader.audioDetail') : t('leftMenuHeader.createAudio')} shadow />
          <Formik
            initialValues={initialValues}
            onSubmit={createUpdateAudio}
            validationSchema={createAudio}
            enableReinitialize
          >
            {({ setFieldValue, isSubmitting, isValid }) => {
              const isDisabledButton = [!isValid, isSubmitting, !audioUrl, !audioUsUrl].includes(true);
              return (
                <Form>
                  <section className="create-audio">
                    <div className="create-audio__fields">
                      <ImageInput
                        setFieldValue={setFieldValue}
                        formatedImage={formatedThumbImage}
                        name="thumbnailImage"
                        label={t('formLabel.thumbnailImage')}
                      />
                      <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
                      <Field
                        type="text"
                        label={t('formLabel.description')}
                        name="content"
                        minRows={4}
                        component={TextAreaInput}
                      />
                    </div>
                    <div className="create-audio__player">
                      <SolidButton type="button" className="slim" onClick={handleFileInputClick}>
                        <FaFileAudio />
                        {t('button.upload')}
                      </SolidButton>
                      <input
                        type="file"
                        accept="audio/*"
                        className="file-upload"
                        ref={fileInput}
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          const upload = event.currentTarget.files[0];
                          setFieldValue('file', upload);
                          if (upload) {
                            reader.readAsDataURL(upload);
                          }
                        }}
                      />
                      <audio className="aud" controls src={audioUrl}>
                        <track kind="captions" />
                        Your browser does not support the
                        <code>audio</code> element.
                      </audio>
                    </div>
                    <div className="create-audio__player">
                      <SolidButton type="button" className="slim" onClick={handleFileUsInputClick}>
                        <FaFileAudio />
                        {t('button.upload')} US
                      </SolidButton>
                      <input
                        type="file"
                        accept="audio/*"
                        className="file-upload"
                        ref={fileUsInput}
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          const upload = event.currentTarget.files[0];
                          setFieldValue('fileUs', upload);
                          if (upload) {
                            readerUs.readAsDataURL(upload);
                          }
                        }}
                      />
                      <audio className="aud" controls src={audioUsUrl}>
                        <track kind="captions" />
                        Your browser does not support the
                        <code>audio</code> element.
                      </audio>
                    </div>
                  </section>
                  <div className="create-audio__buttons">
                    <SolidButton className="secondary" onClick={goBack}>
                      {t('button.back')}
                    </SolidButton>
                    <SolidButton
                      className={`ml-2 ${isDisabledButton ? 'disabled' : ''}`}
                      disabled={isDisabledButton}
                      type="submit"
                    >
                      {audioId ? t('button.update') : t('button.create')}
                    </SolidButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || spinner}
      />
    </>
  );
};

export default CreateAudio;

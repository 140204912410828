import React from 'react';
import { Formik, Field, Form } from 'formik';
import { isEmpty } from 'ramda';
import Modal from 'components/Modal';
import Button from 'components/SolidButton';
import { TextInput, MultiSelect } from 'components/FormComponents';

import { titleTypeForm } from 'utils/validationSchema';
import { typeFormOptions, typeSurveyOptions, initialFormTypeValues } from './constants';

const NameModal = (props) => {
  const { open, onCloseModal, editMode, handleNameTypeChange, form } = { ...props };
  const { title, typeForm, surveyType } = { ...form };
  return (
    <Modal
      modalIsOpen={open}
      closeModal={onCloseModal}
      closeable={false}
      modalProps={{ shouldCloseOnOverlayClick: editMode, shouldCloseOnEsc: editMode }}
    >
      <Formik
        initialValues={isEmpty(form) ? initialFormTypeValues : { title, typeForm, surveyType }}
        onSubmit={handleNameTypeChange}
        validationSchema={titleTypeForm}
      >
        {({ handleSubmit, values: { typeForm: typeFormSelected } }) => (
          <Form className="form">
            <div>
              <h3 className="modal__title title_left">Nombre y tipo del formulario</h3>
              <p className="modal__subtitle subtitle_left">
                Con el nombre y el tipo podras identificar el formulario cuando vayas a utilizarlo:
              </p>
              <Field type="text" label="Nombre del formulario" name="title" component={TextInput} />
              {surveyType !== 'grouped' && (
                <Field
                  type="text"
                  label="Tipo del formulario"
                  name="typeForm"
                  component={MultiSelect}
                  options={typeFormOptions}
                  placeholder="Seleccione el tipo de formulario"
                />
              )}
              {typeFormSelected === 'survey' && isEmpty(form) && (
                <Field
                  type="text"
                  label="Tipo de encuesta"
                  name="surveyType"
                  component={MultiSelect}
                  options={typeSurveyOptions}
                  placeholder="Seleccione el tipo de encuesta"
                />
              )}
              <div className="d-flex  mt-4 modal__buttons right_buttons">
                <Button className="secondary" onClick={onCloseModal}>
                  Cancelar
                </Button>
                <div style={{ marginRight: '10px' }} />
                <Button onClick={() => handleSubmit()}>Guardar</Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default NameModal;

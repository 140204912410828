import React from 'react';
import Loader from 'react-loader-spinner';

import { BLOCK_TYPE } from '../service/constants';
import { useCreateReadingContext } from '../CreateReadingProvider';

import BuilderTitle from '../BuilderTitle';
import BlockModifier from '../BlockModifier';
import BuilderBlocks from '../BuilderBlocks';
import BuilderMask from '../BuilderMask';
import BlockList from '../BlockList';

const ReadingBuilder = () => {
  const { loading, builderState } = useCreateReadingContext();
  const { blockType } = { ...builderState };
  const isIdle = blockType === BLOCK_TYPE.IDLE;
  return (
    <>
      <div className="builder">
        <section className="builder__blocks">
          <BuilderTitle />
          <BuilderBlocks />
          {!isIdle && <BuilderMask />}
        </section>
        <section className="builder__blockOptions">{isIdle ? <BlockList /> : <BlockModifier />}</section>
      </div>
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={loading} />
    </>
  );
};

export default ReadingBuilder;

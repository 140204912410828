import React from 'react';
import PropTypes from 'prop-types';

import SolidButton from 'components/SolidButton';

import doctors from 'assets/images/doctors.svg';

const NewUserIntro = ({ description, handleStep }) => (
  <div className="new-user__content__intro">
    <div className="new-user__content__intro__image">
      <img src={doctors} alt="doctors" />
    </div>
    <h2>Programa de aliados HealthPhy</h2>
    <p>
      En HealthPhy, hacemos fisioterapia digital al alcance de todos, haciéndola más accesible para ti. A continuación
      podrás crear una cuenta con nosotros para empezar el plan de salud asignado con tu aliado.
    </p>
    <p>{description}</p>
    <SolidButton onClick={() => handleStep('form')}>Crear usuario</SolidButton>
  </div>
);

NewUserIntro.defaultProps = {
  handleStep: () => {},
};

NewUserIntro.propTypes = {
  handleStep: PropTypes.func,
  description: PropTypes.string.isRequired,
};

export default NewUserIntro;

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { useCreateProgramContext } from 'components/createProgram/CreateProgramProvider';
import { customStyles } from 'utils/styles';

const SelectedReadingList = ({ templates, readDays, templateId, addReadingDays }) => {
  const { t } = useTranslation();
  const selectedTemplate = templates.find(({ id }) => id === templateId);
  const {
    createProgramState: { duration },
  } = useCreateProgramContext();

  const [keySelect, setKeySelect] = useState(0);

  useEffect(() => {
    setKeySelect((prev) => prev + 1);
  }, [readDays]);

  const getDayOptions = [...Array(Number(duration))].map((_, index) => ({ value: index + 1, label: index + 1 }));

  const getDefaultDay = (readingId) =>
    readDays.filter(({ ids }) => ids.includes(readingId)).map(({ day }) => ({ value: day, label: day }));

  return (
    <div className="forms program__templates__select">
      <table>
        <thead>
          <tr>
            <th style={{ width: '75%' }}>{t('tableHeader.title')}</th>
            <th style={{ width: '25%' }}> </th>
          </tr>
        </thead>
        <tbody className="program__templates__select--tbody">
          {(selectedTemplate ? selectedTemplate.readings : []).map(({ id, title }) => (
            <tr key={id}>
              <td style={{ width: '75%' }}>{title}</td>
              <td style={{ width: '25%' }}>
                <Select
                  key={keySelect}
                  defaultValue={getDefaultDay(id)}
                  placeholder={t('placeholder.day')}
                  options={getDayOptions}
                  styles={customStyles}
                  onChange={({ value }) => addReadingDays(id, value.toString())}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SelectedReadingList;

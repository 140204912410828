import React from 'react';
import PropTypes from 'prop-types';

import TextInput from './FormComponents/TextInput';
import TextArea from './FormComponents/TextArea';
import RadioButtons from './FormComponents/RadioButtons';
import CheckboxGroup from './FormComponents/CheckboxGroup';
import RangeInput from './FormComponents/RangeInput';
import DateInput from './FormComponents/DateInput';
import FileInputSurveys from './FormComponents/FileInputSurveys';
import InfoScreen from './FormComponents/InfoScreen';
import VerificationInput from './FormComponents/VerificationInput';

const FormControlSurveys = ({ isMulti, ...rest }) => {
  const { control = '' } = { ...rest };

  switch (control) {
    case 'text':
    case 'block':
    case 'numeric':
      return isMulti ? <TextArea {...rest} /> : <TextInput {...rest} />;
    case 'multiple-selection':
    case 'pointed-multiple-selection':
      return isMulti ? <CheckboxGroup {...rest} /> : <RadioButtons {...rest} />;
    case 'numeric-scale':
      return <RangeInput {...rest} />;
    case 'date':
      return <DateInput {...rest} />;
    case 'verification':
      return <VerificationInput {...rest} />;
    case 'file':
      return <FileInputSurveys {...rest} />;
    case 'text-image':
      return <InfoScreen {...rest} />;
    default:
      return null;
  }
};

FormControlSurveys.propTypes = {
  isMulti: PropTypes.bool.isRequired,
};

export default FormControlSurveys;

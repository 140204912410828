export const mapWeeksToUpdate = (weeks, plan) => {
  const { weeks: planWeeks = [] } = { ...plan };
  let planNumber = 0;
  return weeks.map((week) => {
    const { weekId, title, days: newDays = [] } = { ...week };
    const [planWeek] = planWeeks.filter(({ id }) => weekId === id);
    const allDays = newDays
      .map((day) => {
        const { weekNumber, exerciseId, exercisePosition, rules } = { ...day };
        const { days: planDays = [] } = { ...planWeek };
        const [planDay] = planDays.filter(({ weekNumber: wNumber }) => wNumber === weekNumber);
        const { id: dayId } = { ...planDay };
        return {
          dayId,
          weekNumber,
          exerciseId,
          exercisePosition,
          rules,
        };
      })
      .reduce((rv, x) => {
        (rv[x.weekNumber] = rv[x.weekNumber] || []).push(x);
        return rv;
      }, {});
    const days = Object.keys(allDays).map((ad) => {
      const dayItems = allDays[ad];
      const [first] = [...dayItems];
      const { dayId, weekNumber } = { ...first };
      return {
        dayId,
        weekNumber,
        exerciseIds: dayItems.map(({ exerciseId, exercisePosition, rules }) => ({
          exerciseId,
          exercisePosition,
          rules,
        })),
      };
    });
    return {
      weekId,
      title,
      days: days.map((dy) => {
        planNumber++;
        return { ...dy, planNumber };
      }),
    };
  });
};

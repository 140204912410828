import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { Formik, Field, Form } from 'formik';

import { customPlanForm as templateDetail } from 'utils/validationSchema';

import UserAvatar from 'components/UserAvatar';
import SolidButton from 'components/SolidButton';
import Icon from 'components/Icon';
import { TextInput, TextAreaInput } from 'components/FormComponents';
import { useCreateTemplateContext } from 'components/createTemplate/CreateTemplateProvider';

import noTemplates from 'assets/images/login-employers.png';

const TemplateDetail = () => {
  const {
    searchId,
    template: { title = '', description = '', backgroundImage = {}, readings = [] },
    handleCreateEditTemplate,
  } = useCreateTemplateContext();
  const { thumb = '' } = { ...backgroundImage };
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const [imageUrl, setImageUrl] = useState('');
  const fileInput = useRef();
  const reader = new FileReader();

  reader.onloadend = () => {
    setImageUrl(reader.result);
  };

  const handleFileInputClick = () => {
    if (fileInput) {
      fileInput.current.click();
    }
  };

  const initialValues = {
    title,
    description,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleCreateEditTemplate}
      validationSchema={templateDetail}
      enableReinitialize
    >
      {({ setFieldValue, isSubmitting, isValid }) => {
        const isDisabledButton = [!isValid, isSubmitting, isEmpty(readings)].includes(true);
        return (
          <Form className="template-detail">
            <div className="template-detail__avatar">
              <UserAvatar size="huge" avatarUrl={imageUrl || thumb || noTemplates}>
                <div className="template-detail__camera" aria-hidden="true" onClick={handleFileInputClick}>
                  <Icon name="camera" />
                </div>
                <input
                  type="file"
                  className="file-upload"
                  ref={fileInput}
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    const upload = event.currentTarget.files[0];
                    setFieldValue('backgroundImage', upload);
                    if (upload) {
                      reader.readAsDataURL(upload);
                    }
                  }}
                />
              </UserAvatar>
            </div>
            <div className="template-detail__description">
              <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
              <Field
                type="text"
                label={t('formLabel.description')}
                name="description"
                minRows={4}
                component={TextAreaInput}
              />
            </div>
            <div className="template-detail__button">
              <SolidButton className="secondary mr-2" onClick={goBack}>
                {t('button.back')}
              </SolidButton>
              <SolidButton className={isDisabledButton ? 'disabled' : ''} disabled={isDisabledButton} type="submit">
                {searchId ? t('button.update') : t('button.create')}
              </SolidButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TemplateDetail;

import React from 'react';
import moment from 'moment';
import { capitalize } from '../../utils/string';
import EventCard from './EventCard';

moment.locale = 'es';
const WeekView = (props) => {
  const { current = moment(), events = [], changeRoute } = { ...props };
  const currentLabel = capitalize(current.format('MMMM YYYY'));

  const generateWeek = () => {
    const newDays = [];
    const start = moment(current).startOf('week');
    const end = moment(current).endOf('week');
    const startMT = moment(current).startOf('month');
    const endMT = moment(current).endOf('month');

    if (startMT.isAfter(start)) {
      for (let i = startMT.diff(start, 'days'); i > 0; i--) {
        newDays.push({
          value: `${moment(startMT).subtract(i, 'day').date()}`,
          disabled: true,
          date: moment(startMT).subtract(i, 'day'),
        });
      }
    }
    if (endMT.isSameOrAfter(end)) {
      for (let j = start.date() > end.date() ? 1 : start.date(); j <= end.date(); j++) {
        newDays.push({
          value: `${j}`,
          date: moment(`${moment(current).format('YYYYMM')}${j}`, 'YYYYMMD'),
        });
      }
    } else {
      for (let j = start.date(); j <= endMT.date(); j++) {
        newDays.push({
          value: `${j}`,
          date: moment(`${moment(current).format('YYYYMM')}${j}`, 'YYYYMMD'),
        });
      }
    }
    if (endMT.isBefore(end)) {
      const diffEnd = end.diff(endMT, 'days');
      for (let i = diffEnd; i > 0; i--) {
        newDays.push({
          value: `${moment(endMT)
            .add(diffEnd - i + 1, 'day')
            .date()}`,
          disabled: true,
          date: moment(endMT).add(diffEnd - i + 1, 'day'),
        });
      }
    }
    return newDays;
  };

  const generateHours = () => {
    const items = [];
    new Array(24).fill().forEach((acc, index) => {
      items.push(moment({ hour: index }).format('h:mm A'));
    });
    return items;
  };

  const isToday = ({ value: day }) => {
    return `${moment(current).format('YYYYMM')}${day}` === moment().format('YYYYMMDD');
  };

  const dayLabels = ['LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB', 'DOM'];
  const week = generateWeek();
  const hours = generateHours();
  return (
    <div style={{ display: 'flex', minHeight: '85%' }}>
      <div className={'week-view'}>
        <div className={'week-view-title'}>
          <span>
            <b>{currentLabel}</b>
          </span>
        </div>
        <div className={'week-view-body'}>
          <span className={'week-view-item title'}></span>
          {week.map((item, index) => (
            <span key={index} className={'week-view-item title'}>
              <span className={`day-label ${isToday(item) && 'today'}`}>{dayLabels[index]}</span>
              <span className={`day-number ${isToday(item) && 'today'}`}>{item.value || '--'}</span>
            </span>
          ))}
          <>
            {hours.map((hour, index) => {
              return (
                <React.Fragment key={`${index}-${hour}`}>
                  <span className={'week-view-item hour'}>{hour}</span>
                  {week.map((item, index) => (
                    <span key={index} className={`week-view-item ${item.disabled && 'disabled'}`}>
                      {events
                        .filter(({ startAt, endAt, type }) => {
                          const eventI = moment(`${moment(item.date).format('DD/MM/YYYY')} ${hour}`, 'DD/MM/YYYY h:mm A');
                          const isEnd =
                            type === 'event'
                              ? eventI.isBefore(moment(endAt || startAt).startOf('hour'))
                              : eventI.isSameOrBefore(moment(endAt || startAt).startOf('hour'));
                          return (
                            moment(startAt).format('YYYYMMDD') === moment(item.date).format('YYYYMMDD') &&
                            eventI.isSameOrAfter(moment(startAt).startOf('hour')) &&
                            isEnd
                          );
                        })
                        .map((event, index) => (
                          <EventCard key={index} event={event} changeRoute={changeRoute} />
                        ))}
                    </span>
                  ))}
                </React.Fragment>
              );
            })}
          </>
        </div>
      </div>
    </div>
  );
};
export default WeekView;

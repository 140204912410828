import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FaRegEdit, FaRegTrashAlt, FaRegClone } from 'react-icons/fa';
import { inputTypes } from './constants';

const Fieldard = (props) => {
  const {
    index,
    groupId,
    question,
    onRemoveInput = () => {},
    handleInput = () => {},
    handleClone = () => {},
  } = { ...props };
  const { id, type: typeObj = {}, label, title: qTitle } = { ...question };
  const inputType = inputTypes.find((el = {}) => el.type === typeObj.type);
  const { title, icon: Icon } = { ...inputType };
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <li
          className="field_question"
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          {!!Icon && (
            <div className="field_icon">
              <Icon className="input_icon" />
            </div>
          )}
          <div className="field_content">
            <p className="field_title">{label || qTitle}</p>
            <p className="field_description">Tipo: {title}</p>
          </div>
          <div className="field_buttons">
            <FaRegTrashAlt onClick={() => onRemoveInput({ groupId, questionId: id })} className="upd-del-icons" />
            <FaRegEdit onClick={() => handleInput({ ...question, groupId })} className="upd-del-icons" />
            <FaRegClone
              onClick={() => handleClone({ clonedQuestion: { ...question, groupId }, index })}
              className="upd-del-icons"
            />
          </div>
        </li>
      )}
    </Draggable>
  );
};

export default Fieldard;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';

import { evieExerciseInfo, evieSqueezeInfo } from 'utils/validationSchema';

import { TextInput, MultiSelect } from 'components/FormComponents';
import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: 500,
  },
};
const lapOptions = [...Array(20)].map((_, index) => ({ value: index + 1, label: index + 1 }));
const restOptions = [...Array(6)].map((_, index) => ({ value: (index + 1) * 10, label: (index + 1) * 10 }));

const EvieBlockInfo = ({ blockData, open, onCancelAction, onSubmitBlockInfo, squeeze }) => {
  const { t } = useTranslation();

  return (
    <Modal modalProps={modalProps} modalIsOpen={open} closeModal={onCancelAction} closeable={false}>
      <div className="evie-warning">
        <h3 className="evie-warning__title">{t('eviePrograms.level.blockTitle')}</h3>
        <Formik
          initialValues={{ ...blockData }}
          onSubmit={onSubmitBlockInfo}
          validationSchema={squeeze ? evieSqueezeInfo : evieExerciseInfo}
          enableReinitialize
        >
          {({ isSubmitting, isValid }) => {
            const isDisabledButton = [!isValid, isSubmitting].includes(true);
            return (
              <Form className="evie-warning__form">
                <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
                {!squeeze ? (
                  <div className="evie-warning__form_select">
                    <div className="evie-warning__form_laps">
                      <Field
                        type="text"
                        label={t('formLabel.laps')}
                        name="laps"
                        component={MultiSelect}
                        options={lapOptions}
                      />
                    </div>
                    <div className="evie-warning__form_laps">
                      <Field
                        type="text"
                        label={t('formLabel.rest')}
                        name="rest"
                        component={MultiSelect}
                        options={restOptions}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="evie-warning__handlers level-info-btns">
                  <SolidButton className="evie-secondary mr-2" onClick={onCancelAction}>
                    {t('button.back')}
                  </SolidButton>
                  <SolidButton
                    type="submit"
                    className={`evie-primary ${isDisabledButton ? 'disabled' : ''}`}
                    disabled={isDisabledButton}
                  >
                    {t('button.update')}
                  </SolidButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

EvieBlockInfo.propTypes = {
  blockData: PropTypes.shape({
    title: PropTypes.string,
    laps: PropTypes.number,
    rest: PropTypes.number,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onCancelAction: PropTypes.func.isRequired,
  onSubmitBlockInfo: PropTypes.func.isRequired,
  squeeze: PropTypes.bool.isRequired,
};

export default EvieBlockInfo;

import React, { useContext, createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { v4 as uuid } from 'uuid';

import { useEvieProgramServiceContext } from 'components/eviePrograms/EvieProgramServiceProvider';
import {
  ACTIONS,
  EVIE_PROGRAM_STEPS,
  evieProgramReducer,
  useCreateUpdateProgramBuilder,
} from 'components/eviePrograms/service';

const initialState = {
  step: EVIE_PROGRAM_STEPS.DESCRIPTION,
  detail: {
    backgroundImage: '',
    title: '',
    description: '',
  },
  levels: [],
  selectedLevel: {},
  selectedExerciseSession: {},
};

const EvieProgramContext = createContext();

const EvieProgramProvider = ({ children }) => {
  const { programId, program } = useEvieProgramServiceContext();
  const { title = '', description = '', backgroundImage = {}, programLevels = [] } = program;
  const formatLevels = programLevels.map((level) => {
    const { id, number, name, goal, programSessions = [], readings = [], audios = [], shortVideos = [] } = level;
    const formatExercises = programSessions.map((programSession) => ({
      ...programSession,
      sessionExercises: programSession.sessionExercises.map((sessionExercise) => ({
        ...sessionExercise,
        exercises: sessionExercise.exercises.map((exercise) => ({
          id: exercise?.exercise?.id,
          temporalId: uuid(),
          title: exercise?.exercise?.title,
          image: exercise?.exercise?.image,
          position: exercise.position,
        })),
      })),
      sessionSqueezes: programSession.sessionSqueezes.map((sessionSqueeze) => ({
        ...sessionSqueeze,
        squeezes: sessionSqueeze.squeezes.map((squeeze) => ({
          id: squeeze?.squeeze?.id,
          temporalId: uuid(),
          title: squeeze?.squeeze?.title,
          position: squeeze.position,
        })),
      })),
    }));
    const formatAdditionalContent = (items) => items.map((item) => ({ ...item, temporalId: uuid() }));
    return {
      id,
      number,
      name,
      goal,
      programSessions: formatExercises,
      additionalContent: [
        ...formatAdditionalContent(readings),
        ...formatAdditionalContent(audios),
        ...formatAdditionalContent(shortVideos),
      ],
    };
  });
  const { thumb = '' } = backgroundImage;
  const programInitState = { detail: { title, description, backgroundImage: thumb }, levels: formatLevels };

  const [state, dispatch] = useReducer(evieProgramReducer, initialState);

  useEffect(() => {
    if (!isEmpty(program)) {
      dispatch({ type: ACTIONS.SET_PROGRAM, payload: programInitState });
    }
  }, [program]);

  const { createUpdProgramBuilder, spinner } = useCreateUpdateProgramBuilder(state, programId);

  return (
    <EvieProgramContext.Provider value={{ isSendingProgram: spinner, state, dispatch, createUpdProgramBuilder }}>
      {children}
    </EvieProgramContext.Provider>
  );
};

EvieProgramProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useEvieProgramContext() {
  const context = useContext(EvieProgramContext);
  if (!context) {
    throw new Error('Expected an EvieProgramContext somewhere in the react tree to set context value');
  }
  return context;
}

export { useEvieProgramContext, EvieProgramProvider };

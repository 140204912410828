import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { v4 as uuid } from 'uuid';

import { useToggle } from 'utils/hooks/useToggle';
import { useEvieProgramContext } from 'components/eviePrograms/EvieProgramProvider';
import { ACTIONS } from 'components/eviePrograms/service';

import EvieAddContent from '../EvieAddContent/EvieAddContent';
import SelectedItemList from '../SelectedItemList';

const EvieLevelInAdditionalContent = ({ index, level }) => {
  const { t } = useTranslation();
  const { dispatch } = useEvieProgramContext();
  const { id: levelId, additionalContent } = level;

  const [isAddingContent, setIsAddingContent] = useToggle();

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }
    if (type === 'list') {
      const currentDraggable = additionalContent.find((item) => item.temporalId === draggableId);
      const newOrderedItems = [...additionalContent];
      newOrderedItems.splice(source.index, 1);
      newOrderedItems.splice(destination.index, 0, currentDraggable);
      dispatch({
        type: ACTIONS.DRAG_AND_DROP_ADDITIONAL_CONTENT,
        payload: { levelId, newAdditionalContent: newOrderedItems },
      });
    }
  };

  const handleAddContent = (selectedItem) => {
    dispatch({
      type: ACTIONS.ADD_ADDITIONAL_CONTENT,
      payload: { levelId, selectedItem: { ...selectedItem, temporalId: uuid() } },
    });
  };

  const handleRemoveItem = (itemId) => {
    dispatch({ type: ACTIONS.REMOVE_ADDITIONAL_CONTENT, payload: { levelId, itemId } });
  };

  return (
    <>
      <li className="evie-program__levels-list_level">
        <span className="evie-program__levels-list_label-level">
          {t('eviePrograms.levels.labelLevel')} {index + 1}
        </span>
        <div className="evie-program__handlers">
          <button type="button" className="evie-program__handlers_selector" onClick={setIsAddingContent}>
            {isAddingContent ? <FaChevronDown /> : <FaChevronRight />}
          </button>
        </div>
      </li>
      {isAddingContent && (
        <EvieAddContent
          index={index}
          addContent={handleAddContent}
          additionalContent={additionalContent}
          selectedItems={
            <SelectedItemList selectedContent={additionalContent} removeItem={handleRemoveItem} dragEnd={onDragEnd} />
          }
        />
      )}
    </>
  );
};

EvieLevelInAdditionalContent.propTypes = {
  index: PropTypes.number.isRequired,
  level: PropTypes.shape({
    id: PropTypes.string,
    additionalContent: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default EvieLevelInAdditionalContent;

export const ACTIONS = {
  SET_PROGRAM: 'setProgram',
  SET_STEP: 'setStep',
  ADD_DETAIL: 'addDetail',
  ADD_LEVEL: 'addLevel',
  SELECT_LEVEL: 'selectLevel',
  REMOVE_LEVEL: 'removeLevel',
  ADD_LEVEL_INFO: 'addLevelInfo',
  ADD_ADDITIONAL_CONTENT: 'addAdditionalContent',
  REMOVE_ADDITIONAL_CONTENT: 'removeAdditionalContent',
  DRAG_AND_DROP_ADDITIONAL_CONTENT: 'dragAndDropAdditionalContent',
  ADD_SESSION: 'addSession',
  REMOVE_SESSION: 'removeSession',
  ADD_EXERCISE_SESSION: 'addExerciseSession',
  REMOVE_EXERCISE_SESSION: 'removeExerciseSession',
  ADD_EXERCISE_SESSION_INFO: 'addExerciseSessionInfo',
  ADD_PROGRAM_SESSIONS: 'addProgramSessions',
  SELECT_EXERCISE_SESSION: 'selectExerciseSession',
  ADD_ADD_ITEM: 'addItem',
  REMOVE_ITEM: 'removeItem',
  DRAG_AND_DROP_ITEM: 'dragAndDropItem',
  CLONE_LEVEL: 'cloneLevel',
  CLONE_SESSION: 'cloneSession',
  CLONE_EXERCISE_SESSION: 'cloneExerciseSession',
  DRAG_AND_DROP_EXERCISE_SESSION: 'dragAndDropExerciseSession',
  DRAG_AND_DROP_SESSION: 'dragAndDropSession',
  DRAG_AND_DROP_LEVEL: 'dragAndDropLevel',
  ADD_SQUEEZE_SESSION: 'addSqueezeSession',
  DRAG_AND_DROP_SQUEEZE_SESSION: 'dragAndDropSqueezeSession',
  REMOVE_SQUEEZE_SESSION: 'removeSqueezeSession',
  ADD_SQUEEZE_SESSION_INFO: 'addSqueezeSessionInfo',
  CLONE_SQUEEZE_SESSION: 'cloneSqueezeSession',
};

export const EVIE_PROGRAM_STEPS = {
  DESCRIPTION: 'description',
  LEVELS: 'levels',
  ADDITIONAL_CONTENT: 'additional_content',
  COMPLETED: 'completed',
};

export const handleFilterTabs = (state, { activeItems, unactiveItems, allItems, filterItems }) => {
  const [active, unactive, all] = filterItems;
  switch (state) {
    case active:
      return activeItems;
    case unactive:
      return unactiveItems;
    case all:
    default:
      return allItems;
  }
};

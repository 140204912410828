import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import SolidButton from 'components/SolidButton';

import noPatients from 'assets/images/no-patients.svg';

const NoSurveys = ({ onCloseModal }) => {
  const { push } = useHistory();

  return (
    <div className="forms-empty-state">
      <div className="forms-empty-state__content">
        <p className="title">¡Aún no tienes formularios configurados!</p>
        <p className="subtitle">Inicia configurando un formulario para el segumiento de tus pacientes.</p>
        <img src={noPatients} alt="no patients" className="forms-empty-state__content-image" />
      </div>
      <div className="followup-buttons">
        <SolidButton type="button" onClick={onCloseModal} className="secondary mr-3">
          Cancelar
        </SolidButton>
        <SolidButton type="button" onClick={() => push('/crear-formulario')}>
          Continuar
        </SolidButton>
      </div>
    </div>
  );
};

NoSurveys.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};

export { NoSurveys };

import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { isEmpty, pathOr } from 'ramda';
import { Formik, Field, Form } from 'formik';
import Loader from 'react-loader-spinner';

import { rejectForm as createConversation } from 'utils/validationSchema';
import { useToggle } from 'utils/hooks/useToggle';
import showNotification from 'utils/showNotification';
import { CREATE_CONVERSATION } from 'apollo/mutations';
import { useConversationsContext } from 'components/conversations/ConversationsProvider';

import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';
import { TextAreaInput } from 'components/FormComponents';

const initialValues = { message: '' };

const CreateConversationModal = ({ isStartChat, name, patientId, toggleStartChat }) => {
  const { handleModal: onOpenChatModal, handlePatientId } = useConversationsContext();
  const [createTwilioConversation] = useMutation(CREATE_CONVERSATION);
  const [spinner, setSpinner] = useToggle();
  const handleCreateConversation = async ({ message }) => {
    setSpinner();
    const response = await createTwilioConversation({
      variables: {
        employeeId: patientId,
        message,
      },
    });
    const errors = pathOr([], ['data', 'createTwilioConversation', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? ['Conversación creada exitosamente'] : errors,
    });
    if (emptyErrors) {
      handlePatientId(patientId);
      toggleStartChat();
      onOpenChatModal();
    }
  };
  return (
    <Modal modalIsOpen={isStartChat} closeModal={toggleStartChat} closeable={false}>
      <div>
        <p className="modal__subtitle">
          Inicia un chat con <strong>{name}</strong> y ofrece un acompañamiento personalizado
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={handleCreateConversation}
          validationSchema={createConversation}
          enableReinitialize
        >
          {({ isSubmitting, isValid }) => {
            const isDisabledButton = [!isValid, isSubmitting].includes(true);
            return (
              <Form>
                <Field type="text" label="mensaje" name="message" minRows={4} component={TextAreaInput} />
                <div className="d-flex justify-content-center mt-4 modal_buttons">
                  <SolidButton className="secondary mr-3" onClick={toggleStartChat}>
                    Cancelar
                  </SolidButton>
                  <SolidButton type="submit" className={isDisabledButton ? 'disabled' : ''} disabled={isDisabledButton}>
                    Continuar
                  </SolidButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={spinner} />
    </Modal>
  );
};

CreateConversationModal.propTypes = {
  isStartChat: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  toggleStartChat: PropTypes.func.isRequired,
};

export default CreateConversationModal;

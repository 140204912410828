import React from 'react';
import PropTypes from 'prop-types';
import doctorAvatar from '../../assets/images/doctor-avatar.svg';

import './user-avatar.scss';

const UserAvatar = ({ avatarUrl, size, className, children }) => (
  <div
    className={`user-avatar user-avatar--${size} ${className}`}
    style={{
      backgroundImage: `url(${avatarUrl || doctorAvatar})`,
      backgroundSize: 'cover',
    }}>
    {children}
  </div>
);

UserAvatar.defaultProps = {
  size: 'medium',
  className: '',
  avatarUrl: '',
};

UserAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default UserAvatar;

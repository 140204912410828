import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckCircle, BsLink45Deg } from 'react-icons/bs';

import { GET_COMPANY_PROFILE } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';

import SolidButton from 'components/SolidButton';

const CopyLink = () => {
  const { t } = useTranslation();
  const { data } = useAuthQuery(GET_COMPANY_PROFILE);
  const { getCompanyInfo = {} } = { ...data };
  const { invitationLink = '' } = { ...getCompanyInfo };
  const [isCopied, setIsCopied] = useState(false);
  const inputRef = useRef();

  const handleCopyToClipboard = (event) => {
    if (!!inputRef && !!inputRef.current) {
      inputRef.current.select();
    }
    document.execCommand('copy');
    event.target.focus();
    setIsCopied(true);
  };

  return (
    <section className="copy-link">
      <p>{t('employees.invite.description')}</p>
      <div className="copy-link__button">
        <div className="copy-link__button__input">
          {isCopied ? <BsCheckCircle color="#46C2AD" size={24} /> : <BsLink45Deg color="#8093FC" size={24} />}
          <input readOnly ref={inputRef} type="text" value={invitationLink} />
        </div>
        {document.queryCommandSupported('copy') && (
          <SolidButton onClick={handleCopyToClipboard} className="min-width-100">
            {t('employees.invite.button')}
          </SolidButton>
        )}
      </div>
    </section>
  );
};

export default CopyLink;

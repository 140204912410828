import React from 'react';
import { propOr } from 'ramda';
import { getIn } from 'formik';
import Select from 'react-select';

export const customStyles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#fff',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    color: state.isSelected ? '#8093FC' : provided.color,
    ':hover': {
      backgroundColor: '#8093FC',
      color: '#fff',
      cursor: 'pointer',
      marginLeft: '-2px',
    },
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: '#8093FC',
    color: '#fff',
    borderRadius: '6px',
    paddingRight: '7px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#818181',
    fontWeight: '300',
  }),
};

const MultiSelect = (props) => {
  const {
    field,
    form,
    label,
    options,
    isRequired,
    labelClassName,
    className,
    isMulti,
    placeholder,
    onChange: onChangeValue,
  } = {
    ...props,
  };
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error;
  const invalidClass = hasError ? 'is-invalid' : '';
  const mapOptions = options.map((item) => {
    if (item.value) {
      return item;
    }
    return { value: item, label: item };
  });

  const onChange = (option) => {
    if (onChangeValue) {
      onChangeValue({
        target: {
          name,
          value: isMulti ? (option || []).map((item) => item.value) : option.value,
        },
      });
    }
    form.setFieldValue(field.name, isMulti ? (option || []).map((item) => item.value) : option.value);
  };

  const getValue = () => {
    if (mapOptions) {
      return isMulti
        ? mapOptions
            .filter((option) => `${field.value}`.indexOf(option.value) >= 0)
            .sort((a, b) => {
              const ida = field.value.indexOf(a.value);
              const idb = field.value.indexOf(b.value);
              return ida === idb ? 0 : ida > idb ? 1 : -1;
            })
        : mapOptions.find((option) => option.value === field.value);
    }
    return isMulti ? [] : '';
  };

  return (
    <div className="form__input-container form__input-container-select">
      {label && (
        <label htmlFor={name} className={`form__label ${labelClassName}`}>
          {label} {isRequired && <span className="text-pink">*</span>}
        </label>
      )}
      <Select
        id={name}
        name={name}
        isMulti={isMulti}
        className={`form__input-select ${className} ${invalidClass}`}
        options={mapOptions}
        value={getValue()}
        onChange={onChange}
        styles={customStyles}
        placeholder={placeholder}
      />
      {hasError && <span className="form__error form__error--select">{error}</span>}
    </div>
  );
};

export default MultiSelect;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';

import { readingDetail } from 'utils/validationSchema';
import { useCreateReadingContext } from 'components/readings/CreateReadingProvider';
import { ACTIONS } from 'components/readings/service/constants';
import { formatBackgroundImage } from 'components/readings/service/utils';

import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';
import { TextInput, ImageInput } from 'components/FormComponents';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: '514px',
    padding: 24,
  },
};

const EditBuilderTitle = ({ modalIsOpen, toggleModal }) => {
  const { t } = useTranslation();
  const { goBack, dispatch, readingId, builderState } = useCreateReadingContext();
  const { title, backgroundImage, thumbnailImage } = { ...builderState };
  const initialValues = { title, backgroundImage, thumbnailImage };
  const formatedBgImage = formatBackgroundImage(backgroundImage);
  const formatedThumbImage = formatBackgroundImage(thumbnailImage);

  const handleGoBack = () => {
    if (readingId) {
      toggleModal();
    } else {
      goBack();
    }
  };

  const handleSubmitReadingTitle = (values) => {
    dispatch({ type: ACTIONS.SET_TITLE, payload: values });
    toggleModal();
  };

  return (
    <Modal
      modalProps={modalProps}
      modalIsOpen={modalIsOpen}
      closeModal={toggleModal}
      closeable={false}
      closeOnOverlayClick={!!title}
      closeOnEsc={!!title}
    >
      <h3 className="builder__title_modal-label">{t('readings.editTitle.label')}</h3>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitReadingTitle}
        validationSchema={readingDetail}
        enableReinitialize
      >
        {({ setFieldValue, isSubmitting, isValid }) => {
          const isDisabledButton = [!isValid, isSubmitting].includes(true);
          return (
            <Form>
              <ImageInput
                setFieldValue={setFieldValue}
                formatedImage={formatedBgImage}
                name="backgroundImage"
                label={t('formLabel.backgroundImage')}
              />
              <ImageInput
                setFieldValue={setFieldValue}
                formatedImage={formatedThumbImage}
                name="thumbnailImage"
                label={t('formLabel.thumbnailImage')}
              />
              <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
              <div className="builder__title_btns">
                <SolidButton type="button" className="secondary mr-2" onClick={handleGoBack}>
                  {t('button.cancel')}
                </SolidButton>
                <SolidButton disabled={isDisabledButton} className={isDisabledButton ? 'disabled' : ''} type="submit">
                  {t('button.save')}
                </SolidButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

EditBuilderTitle.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default EditBuilderTitle;

import { GET_PLANS_WITH_TITLE } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';

export const useGetPlans = () => {
  const { data } = useAuthQuery(GET_PLANS_WITH_TITLE);
  const { planTemplates = [] } = { ...data };
  const formatPlanOptions = planTemplates.map(({ title: label, id: value }) => ({ label, value }));

  return { userPlans: planTemplates, formatedOptions: formatPlanOptions };
};

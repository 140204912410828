import { useMutation } from '@apollo/react-hooks';
import { isEmpty, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { FULFILL_ORDER } from 'apollo/mutations';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';

export const useFullfillOrder = ({
  orderId = '',
  refetchOrders = () => {},
  setOpenOrder = () => {},
  setOrderKey = () => {},
}) => {
  const { t } = useTranslation();
  const [spinner, setSpinner] = useToggle();
  const [fullfillOrder] = useMutation(FULFILL_ORDER);

  const handleFulfillOrder = async () => {
    setSpinner();
    const response = await fullfillOrder({
      variables: {
        orderId,
      },
    });

    const errors = pathOr([], ['data', 'fullfillOrder', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();
    showNotification({
      type: isEmpty(errors) ? 'success' : 'error',
      messages: isEmpty(errors) ? [t('notification.order')] : errors,
    });
    if (emptyErrors) {
      setOpenOrder();
      refetchOrders();
      setOrderKey((prevState) => prevState + 1);
    }
  };

  return { handleFulfillOrder, spinner };
};

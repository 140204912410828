import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { propOr } from 'ramda';
import { getIn } from 'formik';

import './form.scss';

const PasswordInput = ({ field, form, label, labelClassName, className, containerClass, isFSblocked, isRequired, ...props }) => {
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error;
  const invalidClass = hasError ? 'is-invalid' : '';
  const fsClass = isFSblocked ? 'fs-block' : '';
  const [show, setShow] = useState(false);
  return (
    <div className={`form__input-container ${containerClass}`}>
      {label && (
        <label htmlFor={name} className={`form__label ${labelClassName}`}>
          {label} {isRequired && <span className='text-pink'>*</span>}
        </label>
      )}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <input
          className={`form__input ${className} ${fsClass} ${invalidClass}`}
          id={name}
          {...field}
          {...props}
          type={show ? 'text' : 'password'}
        />
        <div className={'password-button'} onClick={() => setShow(!show)}>
          <Icon name='eye' />
        </div>
      </div>
      {hasError && <div className='form__error'>{error}</div>}
    </div>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isFSblocked: PropTypes.bool,
  isRequired: PropTypes.bool,
};

PasswordInput.defaultProps = {
  label: '',
  className: '',
  labelClassName: '',
  isFSblocked: false,
  isRequired: false,
};

export default PasswordInput;

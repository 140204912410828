import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import CreateReadingOld from 'components/readings/CreateReadingOld';
import ReadingBuilder from 'components/readings/ReadingBuilder';
import { CreateReadingProvider, useCreateReadingContext } from 'components/readings/CreateReadingProvider';
import { BLOCK_CONTENT } from 'components/readings/service/constants';

import './create-reading.scss';

function CreateReadingWrapper() {
  return (
    <CreateReadingProvider>
      <CreateReading />
    </CreateReadingProvider>
  );
}
const CreateReading = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();

  const { readingId, blockContent } = useCreateReadingContext();

  return (
    <>
      <section className="d-flex">
        <LeftMenu />
        <div className="dashboard__main">
          <Header title={readingId ? t('leftMenuHeader.editReading') : t('leftMenuHeader.reading')} shadow />
          {blockContent === BLOCK_CONTENT.TRUE ? <ReadingBuilder /> : <CreateReadingOld />}
        </div>
      </section>
    </>
  );
};

export default CreateReadingWrapper;

import React, { useState, useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import { useMutation } from '@apollo/react-hooks';
import { isEmpty, pathOr } from 'ramda';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { FaSearch } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { ADD_TEAM_MEMBER, UPDATE_TEAM_MEMBER, DELETE_TEAM_MEMBER } from 'apollo/mutations';
import showNotification from 'utils/showNotification';
import noPatients from 'assets/images/no-patients.svg';

import AddTeamMemberModal from 'components/Settings/AddTeamMemberModal';
import NoTeamMembers from 'components/Settings/NoTeamMembers';
import TeamMembersList from 'components/Settings/TeamMembersList';
import SolidButton from 'components/SolidButton';
import Modal from 'components/Modal';

const rowsPerPage = 4;

const TeamMembers = ({ partnerInfo, refetch, loading }) => {
  const { t } = useTranslation();
  const { teamMembers = [] } = partnerInfo;
  const [addTeamMember] = useMutation(ADD_TEAM_MEMBER);
  const [updateTeamMember] = useMutation(UPDATE_TEAM_MEMBER);
  const [deleteTeamMember] = useMutation(DELETE_TEAM_MEMBER);

  const [isOpenAddTeamMemberModal, setIsOpenAddTeamMemberModal] = useState(false);
  const [isOpendeleteMemberModal, setIsOpenDeleteMemberModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [teamMember, setTeamMember] = useState({});
  const [teamMembersList, setTeamMembersList] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  const { id: memberId = '', name: memberName = '' } = teamMember;

  const memoSortedMembers = useMemo(() => {
    const sortedMembers = teamMembers.sort((a, b) => {
      const toLowerA = a.name.toLowerCase();
      const toLowerB = b.name.toLowerCase();
      return toLowerA > toLowerB ? 1 : toLowerA < toLowerB ? -1 : 0;
    });
    return sortedMembers;
  }, [teamMembers]);

  const isNewResults = (results) => {
    const rIds = (results || []).map(({ id, name, email }) => `${id}${name}${email}`).join(',');
    const fIds = (filtered || []).map(({ id, name, email }) => `${id}${name}${email}`).join(',');
    return rIds !== fIds;
  };

  useEffect(() => {
    let temporal = memoSortedMembers;
    if (search) {
      temporal = memoSortedMembers.filter((member) => {
        const { name = '', email = '' } = { ...member };
        const memberFound = `${name}-${email}`.toLowerCase();
        return memberFound.indexOf(`${search}`.toLowerCase()) !== -1;
      });
    }
    const totalPages = temporal.length > 0 ? Math.ceil(temporal.length / rowsPerPage) : 0;
    if (page !== 1) setPage(1);
    if (pages !== totalPages) setPages(totalPages);
    if (isNewResults(temporal)) setFiltered(temporal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoSortedMembers, search]);

  const toggleDeleteMemberModal = () => {
    if (isOpendeleteMemberModal) {
      setIsOpenDeleteMemberModal(false);
      setTeamMember({});
    } else {
      setIsOpenDeleteMemberModal(true);
    }
  };

  const toggleAddUpdateMemberModal = () => {
    if (isOpenAddTeamMemberModal) {
      setIsOpenAddTeamMemberModal(false);
      setTeamMember({});
    } else {
      setIsOpenAddTeamMemberModal(true);
    }
  };

  useEffect(() => {
    const init = page * rowsPerPage - rowsPerPage;
    const end = init + rowsPerPage;
    setTeamMembersList(filtered.slice(init, end));
  }, [filtered, page]);

  const onAddTeamMember = async (values) => {
    setSpinner(true);
    const response = await addTeamMember({
      variables: {
        memberInput: { ...values },
      },
    });
    const errors = pathOr([], ['data', 'addTeamMember', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha creado el Profesional exitosamente.`] : errors,
    });

    if (emptyErrors) {
      refetch();
      toggleAddUpdateMemberModal();
    }
  };

  const onUpdateTeamMember = async (values) => {
    setSpinner(true);
    const response = await updateTeamMember({
      variables: {
        memberId,
        memberInput: { ...values },
      },
    });
    const errors = pathOr([], ['data', 'updateTeamMember', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha actualizado el Profesional exitosamente.`] : errors,
    });

    if (emptyErrors) {
      refetch();
      toggleAddUpdateMemberModal();
    }
  };

  const onDeleteTeamMember = async (id) => {
    setSpinner(true);
    const response = await deleteTeamMember({
      variables: {
        memberId: id,
      },
    });
    const errors = pathOr([], ['data', 'deleteTeamMember', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha eliminado el Profesional exitosamente.`] : errors,
    });

    if (emptyErrors) {
      refetch();
      toggleDeleteMemberModal();
    }
  };

  const getTeamMember = (objMember, action) => {
    setTeamMember(objMember);
    if (action === 'update') toggleAddUpdateMemberModal();
    if (action === 'delete') toggleDeleteMemberModal();
  };

  return (
    <section className="settings">
      <div className="settings__left">
        <p className="settings__left_profile">{t('settings.team.title')}</p>
        <p className="settings__left_description">{t('settings.team.description')}</p>
      </div>
      <div className="settings__right">
        {isEmpty(memoSortedMembers) && !loading && <NoTeamMembers openModal={toggleAddUpdateMemberModal} />}
        {!isEmpty(memoSortedMembers) && (
          <>
            {memoSortedMembers.length > 4 && (
              <div className="search-box">
                <FaSearch />
                <input
                  onChange={(ev) => setSearch(ev.target.value)}
                  value={search}
                  placeholder="Buscar un miembro del equipo"
                />
              </div>
            )}
            {isEmpty(teamMembersList) && !loading && (
              <section className="no-team-members-container">
                <div className="no-team-members-container__content">
                  <p className="title">{t('nofound.title')}</p>
                  <p className="subtitle">{t('nofound.description')}</p>
                </div>
                <div className="no-team-members-container__image">
                  <img src={noPatients} alt="no members found" />
                </div>
              </section>
            )}
            {!isEmpty(teamMembersList) && (
              <TeamMembersList
                memoSortedMembers={memoSortedMembers}
                teamMembers={teamMembersList}
                getTeamMember={getTeamMember}
                onDeleteTeamMember={onDeleteTeamMember}
              />
            )}
            <div className="button-pagination">
              <SolidButton onClick={toggleAddUpdateMemberModal}>{t('settings.team.addBtn')}</SolidButton>
              {pages > 1 && (
                <div className="button-pagination__arrows">
                  <MdKeyboardArrowLeft
                    onClick={page > 1 ? () => setPage(page - 1) : undefined}
                    className={`button-pagination__arrows__arrow button-pagination__arrows__arrow--left ${
                      page <= 1 && 'left-disabled'
                    }`}
                  />
                  <MdKeyboardArrowRight
                    onClick={page < pages ? () => setPage(page + 1) : undefined}
                    className={`button-pagination__arrows__arrow ${page >= pages && 'right-disabled'}`}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <AddTeamMemberModal
        addUpdateMember={isEmpty(teamMember) ? onAddTeamMember : onUpdateTeamMember}
        teamMember={teamMember}
        modalIsOpen={isOpenAddTeamMemberModal}
        onCloseModal={toggleAddUpdateMemberModal}
      />
      <Modal modalIsOpen={isOpendeleteMemberModal} closeModal={toggleDeleteMemberModal} closeable={false}>
        <div className="delete-member-modal">
          <p>
            Estás seguro que deseas eliminar a <strong>{memberName}</strong> de tu equipo?
          </p>
          <div className="delete-member-modal__buttons">
            <SolidButton onClick={toggleDeleteMemberModal} className="secondary mr-2">
              Cancelar
            </SolidButton>
            <SolidButton onClick={() => onDeleteTeamMember(memberId)}>Eliminar</SolidButton>
          </div>
        </div>
      </Modal>
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={spinner} />
    </section>
  );
};

export default TeamMembers;

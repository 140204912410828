import { useMemo } from 'react';

export const activeEmployee = 'active';

export const useFilterByProperty = ({ data, property, value }) => {
  const memoDataByProperty = useMemo(() => {
    switch (property) {
      case 'status':
        return data.filter(({ status }) => status === value);
      case 'completed':
        return data.filter(({ completed }) => completed === value);
      case 'planType':
        return data.filter(
          ({ plan: { planType }, status, completed }) => planType === value && status === activeEmployee && !completed,
        );
      default:
        return data;
    }
  }, [data, property, value]);

  return memoDataByProperty;
};

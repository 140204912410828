import React from 'react';
import moment from 'moment';

moment.locale = 'es';
const EventCard = (props) => {
  const { event = {}, changeRoute = () => {} } = { ...props };
  const { title, type, startAt, endAt, patientId, conferenceLink } = { ...event };
  const onEvent = () => {
    if (type === 'event') {
      window.open(conferenceLink);
    } else {
      changeRoute(`/pacientes/${patientId}?tab=4`);
    }
  };
  return (
    <div className={`event-card ${type}`} onClick={onEvent}>
      <p className={`title ${type}`}>{title}</p>
      <p className={`info ${type}`}>
        {moment(startAt).format('h:mm A')}
        {type === 'event' ? ` - ${moment(endAt).format('h:mm A')}` : ''}
      </p>
    </div>
  );
};

export default EventCard;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { CREATE_PROGRAM_STEPS } from 'components/createProgram/service/programConstants';
import { useCreateProgramContext } from 'components/createProgram/CreateProgramProvider';

import Icon from 'components/Icon';

import './create-program-step.scss';

const CreateProgramStep = () => {
  const { t } = useTranslation();

  const {
    createProgramState: { step },
  } = useCreateProgramContext();

  const detail = CREATE_PROGRAM_STEPS.PROGRAM_DETAIL;
  const template = CREATE_PROGRAM_STEPS.READING_TEMPLATES;
  const audio = CREATE_PROGRAM_STEPS.AUDIO;
  const completed = CREATE_PROGRAM_STEPS.COMPLETED;
  const checkedTemplate = [audio, completed].includes(step);

  return (
    <div className="program-step-container">
      <div className="program-step-container__step">
        <div className={`program-step-container__icon ${step === detail ? 'current' : 'checked'}`}>
          {step !== detail && <Icon name="checked" className="checked-icon" />}
        </div>
        <p className={`program-step-container__step--name ${step === detail ? 'current-text' : 'checked-text'}`}>
          {t('createProgram.step.detail')}
        </p>
      </div>
      <span className={`program-step-container--line ${step !== detail && 'checked-line'}`} />
      <div className="program-step-container__step">
        <div
          className={`program-step-container__icon ${step === template ? 'current' : ''} ${
            checkedTemplate ? 'checked' : ''
          }`}
        >
          {checkedTemplate && <Icon name="checked" className="checked-icon" />}
        </div>
        <p
          className={`program-step-container__step--name ${step === template ? 'current-text' : ''} ${
            checkedTemplate ? 'checked-text' : ''
          }`}
        >
          {t('createProgram.step.readingTemplate')}
        </p>
      </div>
      <span className={`program-step-container--line ${checkedTemplate && 'checked-line'}`} />
      <div className="program-step-container__step">
        <div
          className={`program-step-container__icon ${step === audio ? 'current' : ''} ${
            step === completed ? 'checked' : ''
          }`}
        >
          {step === completed && <Icon name="checked" className="checked-icon" />}
        </div>
        <p
          className={`program-step-container__step--name ${step === audio ? 'current-text' : ''} ${
            step === completed ? 'checked-text' : ''
          }`}
        >
          {t('createProgram.step.audio')}
        </p>
      </div>
    </div>
  );
};

export default CreateProgramStep;

import { gql } from 'apollo-boost';

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout
  }
`;

export const ACCEPT_TERMS = gql`
  mutation AcceptTermsAndConditions {
    acceptTermsAndConditions {
      errors
    }
  }
`;

export const CREATE_CLINIC = gql`
  mutation createClinic($authUser: AuthUserInput!, $role: String!, $name: String) {
    createClinic(authUser: $authUser, role: $role, name: $name) {
      clinic {
        id
        name
        email
      }
      errors
      token
    }
  }
`;

export const SIGNIN_USER = gql`
  mutation signinUser($authUser: AuthUserInput!, $role: String) {
    signinUser(authUser: $authUser, role: $role) {
      clinic {
        id
        name
        email
        userType
      }
      errors
      token
    }
  }
`;

export const CREATE_PATIENT_PROFILE = gql`
  mutation createPatientProfile(
    $patientProfile: PatientInput!
    $patientAssessment: AssessmentInput!
    $patientId: String
    $files: [AnswerFileInput!]
  ) {
    createPatientProfile(
      patientProfile: $patientProfile
      patientAssessment: $patientAssessment
      patientId: $patientId
      files: $files
    ) {
      patient {
        id
        email
        name
        gender
        birthday
        cardId
        assessment {
          id
          diagnostic
          content
          answerFiles {
            id
            label
            file {
              original
            }
          }
        }
      }
      errors
    }
  }
`;

export const UPDATE_PATIENT_PROFILE = gql`
  mutation updatePatientProfile($patientProfile: PatientInput!, $patientId: String!) {
    updatePatientProfile(patientProfile: $patientProfile, patientId: $patientId) {
      patient {
        id
        email
        name
        gender
        birthday
        cardId
        teamMember {
          id
          name
        }
        covenant {
          id
          name
        }
      }
      errors
    }
  }
`;

export const CREATE_PATIENT_PLAN = gql`
  mutation createPatientPlan($patientId: String!, $planId: String!) {
    createPatientPlan(patientId: $patientId, planId: $planId) {
      patientPlan {
        plan {
          id
          description
        }
        patient {
          id
          email
          name
          status
        }
        status
      }
    }
  }
`;

export const CREATE_PATIENT_FOLLOWUP = gql`
  mutation createPatientFollowup(
    $patientId: String!
    $patientFollowup: PatientFollowupInput!
    $files: [AnswerFileInput!]
  ) {
    createPatientFollowup(patientId: $patientId, patientFollowup: $patientFollowup, files: $files) {
      patient {
        id
        email
        name
        status
        patientFollowups {
          id
          content
          answerFiles {
            id
            label
            file {
              original
            }
          }
        }
      }
      errors
    }
  }
`;

export const CREATE_CUSTOM_PLAN = gql`
  mutation createCustomPlanLex($patientId: String, $plan: PlanInput!, $weeks: [WeekInput!]!) {
    createCustomPlanLex(patientId: $patientId, plan: $plan, weeks: $weeks) {
      plan {
        id
      }
      errors
    }
  }
`;

export const UPDATE_CUSTOM_PLAN = gql`
  mutation updatePlan($planId: String!, $plan: PlanInput!, $weeks: [WeekInput!]!) {
    updatePlan(planId: $planId, plan: $plan, weeks: $weeks) {
      patientPlan {
        id
      }
      errors
    }
  }
`;

export const UPDATE_PATIENT_STATUS = gql`
  mutation updatePatientStatus($patientId: String!) {
    updatePatientStatus(patientId: $patientId) {
      patient {
        id
        status
      }
      errors
    }
  }
`;

export const CREATE_UPDATE_CLINIC_PROFILE = gql`
  mutation createUpdateClinicProfile($profile: ProfileInput!, $avatar: Upload) {
    createUpdateClinicProfile(profile: $profile, avatar: $avatar) {
      clinic {
        id
        profile {
          currency
        }
      }
      errors
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updateUserPassword($password: String!) {
    updateUserPassword(password: $password) {
      status
      errors
    }
  }
`;

export const UPDATE_BOOKING = gql`
  mutation updateBookingRequest(
    $bookingId: String!
    $status: String!
    $startAt: String
    $message: String
    $ownerId: String
    $ownerType: String
  ) {
    updateBookingRequest(
      bookingId: $bookingId
      status: $status
      startAt: $startAt
      message: $message
      ownerId: $ownerId
      ownerType: $ownerType
    ) {
      errors
      booking {
        id
      }
    }
  }
`;

export const BOOKING_TOKEN = gql`
  mutation generateBookingToken($bookingId: String!) {
    generateBookingToken(bookingId: $bookingId) {
      errors
      ready
      token
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation createClinicSubscription(
    $customerInput: CustomerInput!
    $patientNumber: Int!
    $paymentMethodId: String!
    $intervalCount: Int!
    $coupon: String
  ) {
    createClinicSubscription(
      customerInput: $customerInput
      patientNumber: $patientNumber
      paymentMethodId: $paymentMethodId
      intervalCount: $intervalCount
      coupon: $coupon
    ) {
      errors
    }
  }
`;

export const CREATE_COVENANT = gql`
  mutation createClinicCovenant($name: String!, $description: String, $label: String!, $active: Boolean!) {
    createClinicCovenant(name: $name, description: $description, label: $label, active: $active) {
      errors
    }
  }
`;

export const UPDATE_COVENANT = gql`
  mutation updateClinicCovenant(
    $name: String!
    $description: String
    $label: String!
    $active: Boolean!
    $covenantId: String!
  ) {
    updateClinicCovenant(
      name: $name
      description: $description
      label: $label
      active: $active
      covenantId: $covenantId
    ) {
      errors
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation createEvent($title: String!, $startAt: String!, $description: String!, $covenantId: String) {
    createEvent(title: $title, description: $description, startAt: $startAt, covenantId: $covenantId) {
      errors
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent($title: String!, $startAt: String!, $description: String!, $covenantId: String) {
    updateEvent(title: $title, description: $description, startAt: $startAt, covenantId: $covenantId) {
      errors
    }
  }
`;

export const SEND_OAUTH_TOKEN = gql`
  mutation updateClinicToken($googleToken: String!) {
    updateClinicToken(googleToken: $googleToken) {
      status
      errors
    }
  }
`;

export const CREATE_UPDATE_FORM = gql`
  mutation createUpdateClinicForm(
    $formId: String
    $title: String!
    $content: JSON!
    $typeForm: String!
    $surveyType: String
  ) {
    createUpdateClinicForm(
      formId: $formId
      title: $title
      content: $content
      typeForm: $typeForm
      surveyType: $surveyType
    ) {
      errors
      form {
        id
      }
    }
  }
`;

export const BULK_CREATE_PATIENTS = gql`
  mutation bulkCreatePatients($emails: [String!]!) {
    bulkCreatePatients(emails: $emails) {
      status
      errors
    }
  }
`;

export const UPDATE_PATIENT_SURVEY = gql`
  mutation updatePatientSurvey($surveyId: String!, $content: JSON!, $files: [AnswerFileInput!]) {
    updatePatientSurvey(surveyId: $surveyId, content: $content, files: $files) {
      errors
      survey {
        id
        typeSurvey
        answerFiles {
          id
          groupId
          label
          file {
            original
          }
        }
      }
    }
  }
`;

export const CREATE_SURVEY_INVITE = gql`
  mutation createSurveyInvite($patientId: String!, $formId: String!, $questions: [String!]) {
    createSurveyInvite(patientId: $patientId, formId: $formId, questions: $questions) {
      errors
      survey {
        id
        url
      }
    }
  }
`;

export const CREATE_AVAILABILITY_SLOTS = gql`
  mutation createAvailability($intervals: JSON!, $timezoneOffset: Int, $timezoneValue: String) {
    createAvailability(intervals: $intervals, timezoneOffset: $timezoneOffset, timezoneValue: $timezoneValue) {
      errors
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!, $role: String!) {
    resetPassword(email: $email, role: $role) {
      success
      errors
    }
  }
`;
export const SET_NEW_PASSWORD = gql`
  mutation setNewPassword($email: String!, $role: String!, $password: String!) {
    setNewPassword(email: $email, role: $role, password: $password) {
      success
      errors
    }
  }
`;

export const ADD_TEAM_MEMBER = gql`
  mutation addTeamMember($memberInput: TeamMemberInput!) {
    addTeamMember(memberInput: $memberInput) {
      errors
      member {
        id
        name
        email
      }
    }
  }
`;

export const UPDATE_TEAM_MEMBER = gql`
  mutation updateTeamMember($memberId: String!, $memberInput: TeamMemberInput!) {
    updateTeamMember(memberId: $memberId, memberInput: $memberInput) {
      errors
      member {
        id
        name
        email
      }
    }
  }
`;

export const DELETE_TEAM_MEMBER = gql`
  mutation deleteTeamMember($memberId: String!) {
    deleteTeamMember(memberId: $memberId) {
      errors
      status
    }
  }
`;

export const UPDATE_CLINIC_WHITE_LABEL = gql`
  mutation updateClinicWhiteLabel($colors: JSON!, $avatar: Upload) {
    updateClinicWhiteLabel(colors: $colors, avatar: $avatar) {
      errors
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation markAsRead($notificationId: String!) {
    markAsRead(notificationId: $notificationId) {
      errors
    }
  }
`;

export const BULK_CREATE_SURVEY_INVITATION = gql`
  mutation bulkCreateSurveyInvitation($patientIds: [String!]!, $formId: String!) {
    bulkCreateSurveyInvitation(patientIds: $patientIds, formId: $formId) {
      errors
      status
    }
  }
`;

export const EMPLOYEE_SIGNUP = gql`
  mutation employeeSignup($email: String!, $name: String!, $companyToken: String!, $phone: String, $language: String) {
    employeeSignup(email: $email, name: $name, companyToken: $companyToken, phone: $phone, language: $language) {
      errors
      employee {
        id
        name
        status
      }
    }
  }
`;

export const CREATE_UPDATE_COMPANY_PRODUCT = gql`
  mutation createUpdateCompanyProduct($productInput: ProductInput!, $productId: String, $image: Upload) {
    createUpdateCompanyProduct(productInput: $productInput, productId: $productId, image: $image) {
      errors
    }
  }
`;

export const SEND_EMAIL_TO_PATIENTS = gql`
  mutation sendEmailToPatients($patientIds: [String!]!, $message: String!, $subject: String!, $file: Upload) {
    sendEmailToPatients(patientIds: $patientIds, message: $message, subject: $subject, file: $file) {
      errors
      success
    }
  }
`;

export const PARTNER_PATIENT_SIGNUP = gql`
  mutation partnerPatientSignup($email: String!, $name: String!, $token: String!) {
    partnerPatientSignup(email: $email, name: $name, token: $token) {
      email
      password
      errors
    }
  }
`;

export const UPDATE_USER_LANGUAGE = gql`
  mutation updateUserLanguage($language: String!) {
    updateUserLanguage(language: $language) {
      errors
      profile {
        language
      }
    }
  }
`;

export const DELETE_CLINIC_FORM = gql`
  mutation deleteClinicForm($formId: String!) {
    deleteClinicForm(formId: $formId) {
      errors
      status
    }
  }
`;

export const CREATE_UPDATE_READING = gql`
  mutation createUpdateReading(
    $readingId: String
    $title: String!
    $blockContent: Boolean
    $content: String
    $contentJson: JSON
    $backgroundImage: Upload
    $thumbnailImage: Upload
  ) {
    createUpdateReading(
      readingId: $readingId
      title: $title
      blockContent: $blockContent
      content: $content
      contentJson: $contentJson
      backgroundImage: $backgroundImage
      thumbnailImage: $thumbnailImage
    ) {
      errors
      reading {
        id
        title
      }
    }
  }
`;

export const FULFILL_ORDER = gql`
  mutation fullfillOrder($orderId: String) {
    fullfillOrder(orderId: $orderId) {
      order {
        id
        name
      }
      errors
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder($productId: String!, $quantity: Int!) {
    createOrder(productId: $productId, quantity: $quantity) {
      errors
      order {
        id
      }
    }
  }
`;

export const CREATE_UPDATE_READING_TEMPLATE = gql`
  mutation createReadingTemplate(
    $templateId: String
    $title: String!
    $description: String
    $backgroundImage: Upload
    $readingIds: [String!]!
  ) {
    createReadingTemplate(
      templateId: $templateId
      title: $title
      description: $description
      backgroundImage: $backgroundImage
      readingIds: $readingIds
    ) {
      errors
      readingTemplate {
        id
        title
      }
    }
  }
`;

export const CREATE_UPDATE_PROGRAM = gql`
  mutation createUpdateProgram(
    $programId: String
    $backgroundImage: Upload
    $doctorImage: Upload
    $programInput: ProgramInput!
  ) {
    createUpdateProgram(
      programId: $programId
      backgroundImage: $backgroundImage
      doctorImage: $doctorImage
      programInput: $programInput
    ) {
      errors
      program {
        id
        title
      }
    }
  }
`;

export const CREATE_UPDATE_COMPANY = gql`
  mutation createUpdateCompany(
    $companyId: String
    $name: String!
    $email: String!
    $planId: String
    $surveyId: String!
  ) {
    createUpdateCompany(companyId: $companyId, name: $name, email: $email, planId: $planId, surveyId: $surveyId) {
      errors
      company {
        id
        name
        configValues
      }
    }
  }
`;

export const CREATE_PUSH_NOTIFICATION = gql`
  mutation createPushNotification($title: String!, $message: String!, $employeeIds: [String!]!) {
    createPushNotification(title: $title, message: $message, employeeIds: $employeeIds) {
      errors
      success
    }
  }
`;

export const UNACTIVE_PATIENT_PLAN = gql`
  mutation unactivePatientPlan($patientPlanId: String!) {
    unactivePatientPlan(patientPlanId: $patientPlanId) {
      errors
      success
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  mutation createTwilioConversation($employeeId: String!, $message: String!) {
    createTwilioConversation(employeeId: $employeeId, message: $message) {
      errors
      success
    }
  }
`;

export const DELETE_CLINIC_PLAN = gql`
  mutation deletePlanTemplate($planId: String!) {
    deletePlanTemplate(planId: $planId) {
      errors
      status
    }
  }
`;

export const CREATE_UPDATE_ACTIVE_BREAK = gql`
  mutation createUpdateActiveBreak(
    $activeBreakId: String
    $title: String!
    $description: String!
    $tagColor: String!
    $bodyParts: [String!]!
    $exerciseIds: [ExerciseObjectInput!]!
    $backgroundImage: Upload
  ) {
    createUpdateActiveBreak(
      activeBreakId: $activeBreakId
      title: $title
      description: $description
      tagColor: $tagColor
      bodyParts: $bodyParts
      exerciseIds: $exerciseIds
      backgroundImage: $backgroundImage
    ) {
      errors
      activeBreak {
        id
        title
      }
    }
  }
`;

export const CREATE_UPDATE_AUDIO = gql`
  mutation createUpdateAudio(
    $audioId: String
    $title: String!
    $content: String!
    $file: Upload
    $fileUs: Upload
    $thumbnailImage: Upload
  ) {
    createUpdateAudio(
      audioId: $audioId
      title: $title
      content: $content
      file: $file
      fileUs: $fileUs
      thumbnailImage: $thumbnailImage
    ) {
      errors
      audio {
        id
        title
      }
    }
  }
`;

export const UPDATE_SPEND_LIMIT = gql`
  mutation createCompanySpendLimit($allowanceAmount: Int!, $cadence: String!) {
    createCompanySpendLimit(allowanceAmount: $allowanceAmount, cadence: $cadence) {
      errors
      companySpendLimit {
        id
      }
    }
  }
`;

export const UPDATE_PAYMENT_MODE = gql`
  mutation updatePaymentMode($paymentMode: String) {
    updatePaymentMode(paymentMode: $paymentMode) {
      errors
      profile {
        id
      }
    }
  }
`;

export const UPDATE_PAYMENT_METHOD = gql`
  mutation setPaymentMethod($paymentMethodId: String!) {
    setPaymentMethod(paymentMethodId: $paymentMethodId) {
      errors
      success
    }
  }
`;

export const CREATE_EVIE_PAYMENT_METHOD = gql`
  mutation createEvieExternalPayment($name: String!, $email: String!, $phone: String!, $installments: String!, $currency: String!, $paymentMethodId: String!, $productId: String!, $source: String!, $language: String!, $coupon: String) {
    createEvieExternalPayment(name: $name, email: $email, phone: $phone, installments: $installments, currency: $currency, paymentMethodId: $paymentMethodId, productId: $productId, source: $source, language: $language, coupon: $coupon) {
      errors
      success
    }
  }
`;

export const CREATE_EVIE_PROGRAM_PAYMENT = gql`
  mutation createEvieProgramPayment($name: String!, $email: String!, $phone: String!, $installments: String!, $currency: String!, $paymentMethodId: String!, $productId: String!, $source: String!, $language: String!, $plan: String!, $coupon: String, $campaign: String) {
    createEvieProgramPayment(name: $name, email: $email, phone: $phone, installments: $installments, currency: $currency, paymentMethodId: $paymentMethodId, productId: $productId, source: $source, language: $language, plan: $plan, coupon: $coupon, campaign: $campaign) {
      errors
      success
    }
  }
`;

export const CREATE_UPDATE_SHORT_VIDEO = gql`
  mutation createUpdateShortVideo(
    $shortVideoId: String
    $title: String!
    $description: String!
    $file: Upload
    $fileUs: Upload
    $thumbnailImage: Upload
    $posterImage: Upload
  ) {
    createUpdateShortVideo(
      shortVideoId: $shortVideoId
      title: $title
      description: $description
      file: $file
      fileUs: $fileUs
      thumbnailImage: $thumbnailImage
      posterImage: $posterImage
    ) {
      errors
      shortVideo {
        id
        title
      }
    }
  }
`;

export const CREATE_UPDATE_PROGRAM_BUILDER = gql`
  mutation createUpdateProgramBuilder(
    $programId: String
    $title: String!
    $description: String!
    $backgroundImage: Upload
    $programLevels: [ProgramLevelInput!]!
  ) {
    createUpdateProgramBuilder(
      programId: $programId
      title: $title
      description: $description
      backgroundImage: $backgroundImage
      programLevels: $programLevels
    ) {
      errors
      success
    }
  }
`;

export const CREATE_BOOKING_REQUEST = gql`
  mutation createBookingRequest($patientId: String!, $startAt: String!, $message: String) {
    createBookingRequest(patientId: $patientId, startAt: $startAt, message: $message) {
      errors
      booking {
        id
        startAt
      }
    }
  }
`;

export const CREATE_UPDATE_SQUEEZE = gql`
  mutation createUpdateSqueeze(
    $squeezeId: String
    $title: String!
    $content: JSON
    $file: Upload
    $audio: Upload
    $audioUs: Upload
  ) {
    createUpdateSqueeze(
      squeezeId: $squeezeId
      title: $title
      content: $content
      file: $file
      audio: $audio
      audioUs: $audioUs
    ) {
      errors
      squeeze {
        id
        title
      }
    }
  }
`;

import { useState, useEffect } from 'react';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import { GET_CLINIC_READING } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';
import { BLOCK_CONTENT } from './constants';

export const useGetReading = (readingId = '', blockContent) => {
  const isOldReading = BLOCK_CONTENT.FALSE === blockContent;
  const {
    loading = false,
    data = {},
    refetch = () => {},
  } = readingId && isOldReading
    ? useAuthQuery(GET_CLINIC_READING, {
        variables: { readingId },
      })
    : {};
  const { getClinicReading: readingData = {} } = { ...data };
  const { content = '' } = { ...readingData };

  const blocksFromHtml = htmlToDraft(content);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));

  useEffect(() => {
    if (!loading) {
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [loading]);

  return {
    loading,
    refetch,
    setEditorState,
    readingData,
    editorState,
  };
};

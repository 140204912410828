import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/SolidButton';

const FormFooter = ({ onCancel, group, survey }) => (
  <div className="d-flex mt-4 modal__buttons right_buttons">
    <Button className="secondary" onClick={onCancel}>
      Cancelar
    </Button>
    <div style={{ marginRight: '10px' }} />
    <Button type="submit" className={!group && survey === 'grouped' ? 'disabled' : ''}>
      Aceptar
    </Button>
  </div>
);

FormFooter.defaultProps = {
  onCancel: () => {},
  group: '',
  survey: 'simple',
};

FormFooter.propTypes = {
  onCancel: PropTypes.func,
  group: PropTypes.string,
  survey: PropTypes.string,
};

export default FormFooter;

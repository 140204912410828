import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty, pathOr, path } from 'ramda';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import { SIGNIN_USER, RESET_PASSWORD } from 'apollo/mutations';
import { setAuthInfo } from 'libs/authenticationService';
import { useToggle } from 'utils/hooks/useToggle';
import { useRedirectByStatus } from 'utils/hooks/useRedirectByStatus';
import showNotification from 'utils/showNotification';
import { getUserRole } from 'utils/isClinic';
import { getChat } from 'utils/isChat';
import { REDIRECT_BY_ROLE, USER_ROLES } from 'utils/constants';

import Authentication from 'components/Authentication';
import LoginForm from 'components/Login/LoginForm';
import RequestPasswordForm from 'components/Login/RequestPasswordForm';

import './login.scss';

const Login = (props) => {
  const { t } = useTranslation();
  useRedirectByStatus();
  const changeRoute = path(['history', 'replace'])(props);

  const [signinUser] = useMutation(SIGNIN_USER);
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const [spinner, setSpinner] = useToggle();
  const [forgotPassword, setForgotPassword] = useToggle();
  const {
    location: { pathname },
  } = useHistory();

  const onSubmit = async ({ email, password }) => {
    setSpinner();
    const response = await signinUser({
      variables: {
        authUser: {
          email,
          password,
        },
        role: USER_ROLES[pathname],
      },
    });

    const user = await pathOr({}, ['data', 'signinUser', 'clinic'])(response);
    const token = await pathOr('', ['data', 'signinUser', 'token'])(response);

    if (token) {
      setAuthInfo({ token, user });
    }

    const errors = pathOr([], ['data', 'signinUser', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();
    showNotification({
      type: isEmpty(errors) ? 'success' : 'error',
      messages: isEmpty(errors) ? ['Bienvenido.'] : errors,
    });
    if (emptyErrors) {
      getChat(getUserRole());
      changeRoute(REDIRECT_BY_ROLE[getUserRole()]);
    }
  };

  const onSendEmail = async ({ email }) => {
    setSpinner();
    const response = await resetPassword({
      variables: {
        email,
        role: USER_ROLES[pathname],
      },
    });

    const errors = pathOr([], ['data', 'resetPassword', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [t('notification.passwordRecovery')] : errors,
    });

    if (emptyErrors) {
      setForgotPassword();
    }
  };

  return (
    <Authentication title={forgotPassword ? t('login.recover') : t('login.welcome')} role={USER_ROLES[pathname]}>
      {forgotPassword ? (
        <RequestPasswordForm onSendEmail={onSendEmail} toggleForgotPassword={setForgotPassword} />
      ) : (
        <LoginForm onLogin={onSubmit} toggleForgotPassword={setForgotPassword} role={USER_ROLES[pathname]} />
      )}

      <Loader type="ThreeDots" color="#495FD7" height={100} width={100} className="spinner" visible={spinner} />
    </Authentication>
  );
};

export default Login;

import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { propOr, isEmpty, isNil } from 'ramda';

import { EXERCISES } from 'apollo/queries';
import { CREATE_CUSTOM_PLAN, EDIT_PLAN } from 'utils/steps';
import { useAuthQuery, useFormInput } from 'utils/hooks';
import { useShowComponent } from 'utils/hooks/useShowComponent';
import { useGetCategories } from 'utils/hooks/useGetCategories';
import { useFilterSegments } from 'utils/hooks/useFilterSegments';

import SearchFilter from 'components/SearchFilter';
import CategoriesFilter from 'components/CategoriesFilter';
import CategoriesButton from 'components/CategoriesButton';
import Icon from 'components/Icon';
import SolidButton from 'components/SolidButton';
import ExerciseModal from '../ExerciseModal';
import { StoreConsumer } from 'components/StoreProvider';
import { NoExercisesFound } from 'components/EmptyState';

import './create-week.scss';

const dayOptions = Array.from({length: 7}, (_, i) => i + 1 );
const setsOptions = Array.from({length: 20}, (_, i) => i + 1 );
const repsOptions = Array.from({length: 20}, (_, i) => i + 1 );
const weightOptions = Array.from({length: 100}, (_, i) => i + 1 ).filter(value => !(value % 5));

const asWeek = (list) => {
  const week = [[], [], [], [], [], [], [], []];
  list.forEach((item) => {
    const { weekNumber = 0 } = { ...item };
    week[weekNumber].push(item);
  });
  return week;
};

const asList = (week = []) => week.reduce((a, b) => [...a, ...b], []);
const asOrderList = (week = []) =>
  week
    .map((items) => {
      return items.map((item, index) => ({ ...item, order: index + 1 }));
    })
    .reduce((a, b) => [...a, ...b], []);

const CreateWeek = ({ onSetStep, patientId, week: selectedWeek }) => {

  const { index: selectedWeekIndex, week: selected = {} } = { ...selectedWeek };
  const { title: selectedTitle, days = [], id: selectedId } = { ...selected };

  const { loading, data } = useAuthQuery(EXERCISES);
  const exercises = propOr([], 'exercises')(data);

  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [weekIsOk, setIsWeekOk] = useState(false);
  const [isRules, setIsRules] = useState(false);
  const [exercisesArray, setExercisesArray] = useState([]);
  const [selectedExercises, setSelectedExercises] = useState(asWeek(days));
  const [showedExercise, setShowedExercise] = useState({});

  const categories = useGetCategories({ exercises });
  const filterStates = useFilterSegments();
  const { filterParts, filterEquipments, filterLevels, } = { ...filterStates };
  const { componentIsVisible: categoriesIsOpen, toggleComponent: toggleCategories } = useShowComponent();
  const searchExercises = useFormInput('');
  const { value = '' } = { ...searchExercises };

  useEffect(() => {
    if (isEmpty(exercisesArray) && !isEmpty(exercises)) {
      setExercisesArray(exercises);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercises]);

  useEffect(() => {
    let temporal = exercises;
    const lowerCaseValue = value.toLowerCase();
    if (value) {
      temporal = exercises.filter(({ title, bodyParts }) => {
        const bodyPartsString = bodyParts.join(' - ').toLowerCase();
        return `${title}`.toLowerCase().includes(lowerCaseValue) || bodyPartsString.includes(lowerCaseValue);
      }); 
    }

    if (!isEmpty(filterParts)) {
      temporal = temporal.filter((ex) => {
        const { bodyParts: exbpts = [] } = { ...ex };
        return exbpts.map((e) => filterParts.indexOf(e) !== -1).reduce((a, b) => a || b, false);
      });

    }
    if (!isEmpty(filterEquipments)) {
      temporal = temporal.filter((ex) => {
        const { equipments: equip = [] } = { ...ex };
        return equip.map((e) => filterEquipments.indexOf(e) !== -1).reduce((a, b) => a || b, false);
      });
    }
    if (!isEmpty(filterLevels)) {
      temporal = temporal.filter((ex) => {
        const { levels: levs = [] } = { ...ex };
        return levs.map((e) => filterLevels.indexOf(e) !== -1).reduce((a, b) => a || b, false);
      });
    }
    setExercisesArray(temporal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, filterParts, filterEquipments, filterLevels]);

  const onToggleModal = () => setIsModalOpen(!modalIsOpen);

  const showExercise = (exercise) => () => {
    onToggleModal();
    setShowedExercise(exercise);
  };

  const onRemoveSelected = (exercise, exerciseIndex) => {
    const exercises = [...selectedExercises];
    const { id: exerciseId, weekNumber } = { ...exercise };
    exercises[weekNumber] = exercises[weekNumber].filter(({ id }, index) => !(id === exerciseId && index === exerciseIndex));
    const list = asList(exercises);
    setIsWeekOk(
      isNil(list.find((item) => item.weekNumber === 0 || isNil(item.weekNumber) || isEmpty(item.weekNumber))) && !isEmpty(list)
    );
    setSelectedExercises(exercises);
  };

  const onSelect =
    (exerciseId, weekNumber = 0, selectedIndex) =>
    ({ target: { value } }) => {
      const exercises = [...selectedExercises];
      const exercise = exercises[weekNumber].find((el, index) => el.id === exerciseId && index === selectedIndex);
      if (exercise) {
        exercises[weekNumber] = exercises[weekNumber].filter((el, index) => !(el.id === exerciseId && index === selectedIndex));
        exercises[parseInt(value, 10)] = [
          {
            ...exercise,
            weekNumber: value ? parseInt(value, 10) : 0,
            order: 0,
          },
          ...exercises[parseInt(value, 10)],
        ];
      }
      const list = asList(exercises);
      setIsWeekOk(
        isNil(list.find((item) => item.weekNumber === 0 || isNil(item.weekNumber) || isEmpty(item.weekNumber))) && !isEmpty(list)
      );
      setSelectedExercises(exercises);
    };

  const onInput =
    (exerciseId, weekNumber = 0, selectedIndex, field) =>
    ({ target: { value } }) => {
      const exercises = [...selectedExercises];
      const exercise = exercises[weekNumber].find((el, index) => el.id === exerciseId && index === selectedIndex);
      if (exercise) {
        const rules = { ...exercise.rules, [field]: value };
        exercises[weekNumber][selectedIndex] = { ...exercises[weekNumber][selectedIndex], rules };
      }
      const list = asList(exercises);
      setIsWeekOk(
        isNil(list.find((item) => item.weekNumber === 0 || isNil(item.weekNumber) || isEmpty(item.weekNumber))) && !isEmpty(list)
      );
      setSelectedExercises(exercises);
    };

  const onSaveWeek = (setStoreData, weeks, customPlan) => () => {
    const { planId } = { ...customPlan };
    const exercises = asOrderList(selectedExercises);
    if (selectedWeek) {
      const newWeeks = [...weeks];
      newWeeks[selectedWeekIndex] = { title: selectedTitle, days: exercises, id: selectedId };
      setStoreData('weeks', newWeeks);
    } else {
      setStoreData('weeks', [...weeks, { title: `Semana ${weeks.length + 1}`, days: exercises }]);
    }
    setStoreData('week', undefined);
    onSetStep(planId ? EDIT_PLAN : CREATE_CUSTOM_PLAN);
  };

  const onGoBack = (setStoreData, customPlan) => () => {
    const { planId } = { ...customPlan };
    setStoreData('week', undefined);
    onSetStep(planId ? EDIT_PLAN : CREATE_CUSTOM_PLAN);
  };

  const onAddExercise = (exercise, excercisesToShow) => () => {
    const exercises = [...selectedExercises];
    exercises[0].push({ ...exercise, weekNumber: 0 });
    setSelectedExercises(exercises);
    setExercisesArray(excercisesToShow);
    setIsWeekOk(false);
  };

  const getTotalDays = () => {
    return [...new Set(selectedExercises.filter(({ weekNumber }) => !!weekNumber).map(({ weekNumber }) => weekNumber))].length;
  };

  const isButtonRules = selectedExercises.find((item) => !isEmpty(item));

  const SortableItem = sortableElement((item) => {
    const { value = {} } = { ...item };
    const { item: exercise = {}, index = 0 } = { ...value };
    const { id, title, image, weekNumber = 0, rules = {} } = { ...exercise };
    const { reps = '', sets = '', weight = '' } = { ...rules };
    return (
      <div className={'card-exercise-drag'}>
        <img src={image} className={'image-drag'} alt={'exercise'} />
        <div className={'info-drag'}>
          <div className='close-icon-container-drag'>
            <Icon name='close' className={'close-icon-drag'} onClick={() => onRemoveSelected(exercise, index)} />
          </div>
          <p className={'title-drag'}>{title}</p>
          <div style={{ display: 'flex', marginTop: '0.5rem' }}>
            <select
              id='day-select'
              className='form__input create-week__select'
              onChange={onSelect(id, weekNumber, index)}
              value={weekNumber}>
              <option hidden value={''}>Dia #</option>
              {dayOptions.map((option) => (
                <option value={option} key={option}>{`Dia ${option}`}</option>
              ))}
            </select>
          </div>
          {isRules && (
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5rem' }}>
              <div>
                <label htmlFor={'day-sets'} className={'label-select-reps'}>Sets</label>
                <select
                  id={'day-sets'}
                  className={'form__input create-week__select'}
                  onChange={onInput(id, weekNumber, index, 'sets')}
                  value={sets}>
                  <option hidden value={''}>Sets</option>
                  {setsOptions.map((option) => (
                    <option value={option} key={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor={'day-reps'} className={'label-select-reps'}>Reps</label>
                <select
                  id={'day-reps'}
                  className={'form__input create-week__select'}
                  onChange={onInput(id, weekNumber, index, 'reps')}
                  value={reps}>
                  <option hidden value={''}>Reps</option>
                  {repsOptions.map((option) => (
                    <option value={option} key={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor={'day-weight'} className={'label-select-reps'}>Carga</label>
                <select
                  id={'day-weight'}
                  className={'form__input create-week__select'}
                  onChange={onInput(id, weekNumber, index, 'weight')}
                  value={weight}>
                  <option hidden value={''}>Carga</option>
                  {weightOptions.map((option) => (
                    <option value={option} key={option}>{option}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return <div className={'day-data'}>{children}</div>;
  });

  const onSortEnd = ({ oldIndex, newIndex, collection }) => {
    const newSelected = [...selectedExercises];
    newSelected[collection] = arrayMove(newSelected[collection], oldIndex, newIndex);
    setSelectedExercises(newSelected);
    const list = asList(newSelected);
    setIsWeekOk(
      isNil(list.find((item) => item.weekNumber === 0 || isNil(item.weekNumber) || isEmpty(item.weekNumber))) && !isEmpty(list)
    );
  };
  return (
    <StoreConsumer>
      {({ setStoreData, weeks, customPlan }) => (
        <div className='create-week__container'>
          <div className='row h-100 w-100'>
            <div className='col-8 create-week__search-panel'>
              <div className='create-week__left h-100'>
                <div className='search-bar'>
                  <div className='search-bar__filters'>
                    <SearchFilter 
                      searchItems={searchExercises} 
                      placeholder={'Buscar un ejercicio'}
                    />
                    <CategoriesButton showCategories={categoriesIsOpen} toggleCategories={toggleCategories}/>
                  </div>
                  <Icon name='close' className={'search-bar__icon'} onClick={onGoBack(setStoreData, customPlan)} />
                </div>
                <CategoriesFilter
                  className={'create-plan-view'}
                  showCategories={categoriesIsOpen}
                  categories={categories}
                  filterStates={filterStates}
                />
                <div className='create-week__selected'>
                  {!loading && isEmpty(exercisesArray) && (
                    <NoExercisesFound/>
                  )}
                  <div className='row'>
                    {(exercisesArray || []).map((exercise) => (
                      <div className='col-4' key={exercise.id}>
                        <div className='create-week__selected-item mb-3'>
                          <div className='create-week__img-container' onClick={onAddExercise(exercise, exercisesArray)}>
                            <img src={exercise.image} alt={exercise.title} className='create-week__img w-100' />
                            <div className='create-week__img-btn text-center'>
                              <div className={'add-button'}>
                                <Icon name={'add'} className={'icon'} />
                                Añadir
                              </div>
                            </div>
                          </div>
                          <div className='info-container d-flex justify-content-between align-items-start'>
                            <p className={'title'}>{exercise.title}</p>
                            <div className='detail-button' onClick={showExercise(exercise)}>
                              <Icon name={'chevron-right'} className={'icon'} />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-4 create-week__week-panel'>
              <div className={'card'}>
                <div className={'card-title-container'}>
                  <p className={'card-title'}>{selectedWeek ? selectedTitle : `Semana ${weeks.length + 1}`}</p>
                  <div className={'buttons'}>
                    {!!isButtonRules && (
                      <SolidButton onClick={() => setIsRules(prevState =>!prevState)} className={'small-font slim min-width-100 mr-1'}>
                        {isRules ? 'Dejar de editar' : 'Editar repeticiones'}
                      </SolidButton>
                    )}
                    <SolidButton
                      className={`${!weekIsOk && 'disabled'} small-font slim min-width-100`}
                      onClick={weekIsOk ? onSaveWeek(setStoreData, weeks, customPlan) : undefined}>
                      {selectedWeek ? 'Actualizar' : 'Crear'} Semana
                    </SolidButton>
                  </div>
                </div>
                <div className={'card-summary-container'}>
                  <p className={'card-summary-item'}>
                    <span>{selectedExercises.length}</span>Ejercicios
                  </p>
                  <p className={'card-summary-item'}>
                    <span>{getTotalDays()}</span>Días
                  </p>
                </div>
                <div className='card-days-container'>
                  <div className={'card-day'}>
                    <SortableContainer onSortEnd={onSortEnd} distance={50}>
                      {selectedExercises.map((items, index) =>
                        !isEmpty(items) ? (
                          <React.Fragment key={index}>
                            <p className={'day-title'}>
                              <strong>{index === 0 ? 'Ejercicios' : `Día ${index}`}</strong>
                            </p>
                            <ul>
                              {items.map((item, i) => (
                                <SortableItem
                                  key={`${item.id}-${index}-${i}`}
                                  value={{ item, index: i }}
                                  index={i}
                                  collection={index}
                                  disabled={index === 0}
                                />
                              ))}
                            </ul>
                          </React.Fragment>
                        ) : null
                      )}
                    </SortableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader
            type='ThreeDots'
            color='#495fd7'
            height={100}
            width={100}
            className='spinner'
            visible={loading}
          />
          <ExerciseModal
            open={modalIsOpen}
            onCloseModal={onToggleModal}
            exercise={showedExercise}
            onAdd={onAddExercise(showedExercise, exercisesArray)}
          />
        </div>
      )}
    </StoreConsumer>
  );
};

export default CreateWeek;

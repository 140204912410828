import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { isEmpty } from 'ramda';
import { v4 as uuid } from 'uuid';

import { ACTIONS, EVIE_PROGRAM_STEPS } from 'components/eviePrograms/service';
import { useEvieProgramContext } from 'components/eviePrograms/EvieProgramProvider';

import SolidButton from 'components/SolidButton';
import EvieLevelsList from '../EvieLevelsList/EvieLevelsList';
import EvieSelectedLevel from '../EvieSelectedLevel/EvieSelectedLevel';

const EvieLevels = () => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { levels, selectedLevel },
  } = useEvieProgramContext();

  const handleBack = () => {
    dispatch({ type: ACTIONS.SET_STEP, payload: EVIE_PROGRAM_STEPS.DESCRIPTION });
  };
  const handleNext = () => {
    dispatch({ type: ACTIONS.SET_STEP, payload: EVIE_PROGRAM_STEPS.ADDITIONAL_CONTENT });
  };
  const handleAddLevel = () => {
    dispatch({
      type: ACTIONS.ADD_LEVEL,
      payload: { id: uuid(), isNew: true, programSessions: [], additionalContent: [] },
    });
  };

  const isEmptyLevel = isEmpty(selectedLevel);

  return (
    <div className="evie-program__levels">
      {isEmptyLevel && (
        <section className="evie-program__add-level">
          <h3 className="evie-program__add-level_title">{t('eviePrograms.levels.title')}</h3>
          <SolidButton className="evie-add" onClick={handleAddLevel}>
            {t('eviePrograms.levels.addLevelBtn')}
            <AiOutlinePlusSquare />
          </SolidButton>
        </section>
      )}
      {isEmptyLevel ? <EvieLevelsList /> : <EvieSelectedLevel />}
      {isEmptyLevel && (
        <section className="evie-program__btns">
          <SolidButton className="evie-secondary mr-4" onClick={handleBack}>
            {t('button.back')}
          </SolidButton>
          <SolidButton
            className={`evie-primary ${isEmpty(levels) ? 'disabled' : ''}`}
            disabled={isEmpty(levels)}
            onClick={handleNext}
          >
            {t('button.next')}
          </SolidButton>
        </section>
      )}
    </div>
  );
};

export default EvieLevels;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { isEmpty } from 'ramda';
import Select from 'react-select';

import { BookingStatus } from 'utils/steps';
import { useTeamMembersList } from 'utils/hooks';
import { customStyles } from 'components/FormComponents/MultiSelect';
import { getBookingCount } from '../utils';

import Icon from 'components/Icon';
import { StoreConsumer } from 'components/StoreProvider';
import SolidButton from 'components/SolidButton';
import Button from 'components/Button';
import { NoPatientData } from 'components/EmptyState';
import Modal from 'components/Modal';
import RejectModal from '../RejectModal';

import alert from 'assets/images/alert.png';

const statusList = {
  [BookingStatus.AWAITING]: 'Agendada',
  [BookingStatus.CONFIRMED]: 'Confirmada',
  [BookingStatus.REJECTED]: 'Rechazada',
  [BookingStatus.EXPIRED]: 'Vencida',
  [BookingStatus.CANCELED]: 'Cancelada',
  [BookingStatus.COMPLETED]: 'Finalizada',
};

const modalProps = {
  content: {
    height: '414px',
  },
};

const customMemberStyles = {
  option: (provided) => ({
    ...provided,
    fontWeight: '300',
    fontSize: '16px',
    color: '#606060',
    backgroundColor: '#fff',
    ':hover': {
      color: '#fff',
      backgroundColor: '#8093FC',
      cursor: 'pointer',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
};

const Calls = ({ patient = {}, onUpdateBooking = () => {}, onJoinCall = () => {} }) => {
  const { bookings: baseBookings = [], currentBooking = {} } = { ...patient };
  const { id: currentId = '', status: currentStatus, startAt: scheduleDate } = { ...currentBooking };
  const { teamMembersOptions = [] } = useTeamMembersList();
  const [booking, setBooking] = useState();
  const [value, setValue] = useState();
  const [target, setTarget] = useState();
  const [updating, setUpdating] = useState(false);
  const [selectedMember, setSelectedMember] = useState('');

  const bookings = baseBookings.map((data) => {
    const { versions = [], startAt, status } = { ...data };
    const logs = (versions || []).map((version) => {
      const { logs: versionLog = [] } = { ...version };
      const { status = [], message = [], updated_at = [], start_at = [], session_id = [] } = { ...versionLog };
      return {
        status: (status || [])[1],
        message: (message || [])[1],
        updatedAt: (updated_at || [])[1],
        startAt: (start_at || [])[1],
        sessionId: (session_id || [])[1],
      };
    });
    const [first = {}] = [...logs];
    const sessionIdLog = status === 'confirmed' ? logs[logs.length - 1] : logs.find(({ status }) => !status);
    const { sessionId = '' } = { ...sessionIdLog };
    return {
      ...data,
      logs,
      updatedAt: first.updatedAt || startAt,
      sessionId,
    };
  });

  const { sessionId } = { ...bookings.find(({ id }) => id === currentId) };

  const selectOptions = bookings
    .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
    .map(({ id: value, updatedAt }) => {
      const formatDate = moment(updatedAt).format('DD / MM / YYYY');
      return {
        value,
        label: value === currentId ? `Activa (${formatDate})` : formatDate,
      };
    });

  const onSelectBooking = (item) => {
    const { value: bookingId } = { ...item };
    const selected = bookings.find(({ id }) => id === bookingId);
    setBooking(selected);
    setValue(item);
    setUpdating(false);
  };

  const { id: bookId, logs: allLogs = [] } = { ...booking };

  useEffect(() => {
    if (!isEmpty(bookings) && !bookId && !currentId) {
      const item = bookings[bookings.length - 1];
      const { id: value, updatedAt } = { ...item };
      onSelectBooking({ value, label: moment(updatedAt).format('DD / MM / YYYY') });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings]);

  useEffect(() => {
    if (currentId) {
      const item = bookings.find(({ id }) => id === currentId);
      const { id: value, updatedAt } = { ...item };
      onSelectBooking({ value, label: `Activa (${moment(updatedAt).format('DD / MM / YYYY')})` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId, currentStatus]);

  const logs = (allLogs || []).filter(({ status }) => !!status);
  const isCall = bookId === currentId && currentStatus === BookingStatus.CONFIRMED && !!sessionId;
  const onCloseModal = () => setTarget(undefined);
  const onRejectModal = () => setTarget(BookingStatus.REJECTED);
  const onConfirmModal = () => setTarget(BookingStatus.CONFIRMED);
  const onCompleteModal = () => setTarget(BookingStatus.COMPLETED);
  const onSubmit = (payload) => {
    setUpdating(true);
    onUpdateBooking(
      {
        ...payload,
        bookingId: currentId,
        status: target,
        ...(!!selectedMember && {
          ownerId: selectedMember,
          ownerType: 'TeamMember',
        }),
      },
      onCloseModal,
    );
  };

  const joinCall = (setStoreData) => async () => {
    onJoinCall(currentId, sessionId, { startAt: moment(scheduleDate).format('DD/MM/YYYY - hh:mma') }, setStoreData);
  };

  const bookingsCount = getBookingCount(baseBookings);

  return (
    <StoreConsumer>
      {({ openCall, setStoreData }) => {
        const isOpenCall = openCall.isOpen;
        return (
          <div className="w-100">
            <div className="patient__title">
              <div>
                <Icon name="bell" className="icon" />
                <p className="title">Llamadas</p>
              </div>
            </div>
            <div className="patient__tab_content follow-up">
              {isEmpty(bookings) ? (
                <NoPatientData tab="calls" calls />
              ) : (
                <>
                  <div className="form__input-container form__input-container-select patient__booking-select">
                    <p className="patient__booking-select_length">
                      <strong>Número de teleconsultas:</strong> {bookingsCount}
                    </p>
                    <label>Solicitud:</label>
                    <Select
                      id="booking"
                      name="booking"
                      className="form__input-select"
                      options={selectOptions}
                      value={value}
                      onChange={onSelectBooking}
                      styles={customStyles}
                      placeholder="Solicitud activa"
                    />
                  </div>
                  <div className="patient__call-logs">
                    {logs.map((log, index) => {
                      const { updatedAt, id, status, message } = { ...log };
                      return (
                        <div className="item" key={id}>
                          <div className="info">
                            <p className="title">{`Registro ${index + 1}`}</p>
                            <p className="date">{moment(updatedAt).format('DD/MM/YYYY - hh:mma')}</p>
                          </div>
                          <div className="content">
                            <p className="message">
                              {message}
                              {!!message && <br />}
                              {status === BookingStatus.CONFIRMED && (
                                <span>
                                  {index === logs.length - 1
                                    ? 'Solicitud confirmada para el '
                                    : 'Solicitud confirmada por el especialista'}
                                  {index === logs.length - 1 && (
                                    <strong>{moment(scheduleDate).format('DD/MM/YYYY - hh:mma')}</strong>
                                  )}
                                </span>
                              )}
                              {status === BookingStatus.AWAITING && (
                                <span>
                                  {'Solicitud agendada para el '}
                                  <strong>{moment(scheduleDate).format('DD/MM/YYYY - hh:mma')}</strong>
                                </span>
                              )}
                              {status === BookingStatus.CANCELED && (
                                <span>
                                  {'Solicitud '}
                                  <strong>cancelada</strong>
                                  {' por el paciente'}
                                </span>
                              )}
                              {status === BookingStatus.COMPLETED && (
                                <span>
                                  {'Solicitud '}
                                  <strong>finalizada</strong>
                                  {' por el especialista'}
                                </span>
                              )}
                              {status === BookingStatus.EXPIRED && (
                                <span>
                                  {'La solicitud '}
                                  <strong>expiró</strong>
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="actions">
                            {status !== BookingStatus.AWAITING && (
                              <p className={`status ${status}`}>{statusList[status]}</p>
                            )}
                            {status === BookingStatus.AWAITING &&
                              (index === logs.length - 1 && !updating ? (
                                <>
                                  <Button category="rounded" onClick={onConfirmModal}>
                                    Aceptar
                                  </Button>
                                  <Button category="secondary-rounded" onClick={onRejectModal}>
                                    Rechazar
                                  </Button>
                                </>
                              ) : (
                                <p className={`status ${status}`}>{statusList[status]}</p>
                              ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {isCall && !updating && !isOpenCall && (
                    <div className="mx-2 button-container">
                      <Button category="rounded" onClick={joinCall(setStoreData)}>
                        Unirse a la llamada
                      </Button>
                      <Button category="secondary-rounded" onClick={onCompleteModal} className="button-call-complete">
                        Finalizar llamada
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
            <RejectModal
              modalIsOpen={target === BookingStatus.REJECTED}
              onCloseModal={onCloseModal}
              onSubmit={onSubmit}
            />
            <Modal
              modalIsOpen={target === BookingStatus.CONFIRMED || target === BookingStatus.COMPLETED}
              closeModal={onCloseModal}
              closeable={false}
              modalProps={modalProps}
            >
              <div>
                <div className="alert-container">
                  <img src={alert} className="alert-icon" alt="alert-icon" />
                </div>
                <h3 className="modal__title">Gestionar Solicitud</h3>
                {target === BookingStatus.CONFIRMED && (
                  <p className="modal__subtitle">
                    ¿Realmente deseas confirmar la llamada para el{' '}
                    <strong>{moment(scheduleDate).format('DD/MM/YYYY - hh:mma')}</strong> ?
                  </p>
                )}
                {target === BookingStatus.COMPLETED && (
                  <>
                    <p className="modal__subtitle">
                      ¿Realmente deseas marcar la llamada como <strong>finalizada</strong> ?
                    </p>
                    <Select
                      placeholder="Selecciona el profesional que realizó la llamada"
                      options={teamMembersOptions}
                      styles={customMemberStyles}
                      maxMenuHeight={120}
                      onChange={({ value }) => setSelectedMember(value)}
                    />
                  </>
                )}
                <div className="handle-call-buttons">
                  <SolidButton className="secondary mr-2" onClick={onCloseModal}>
                    Cancelar
                  </SolidButton>
                  <SolidButton onClick={() => onSubmit()}>Aceptar</SolidButton>
                </div>
              </div>
            </Modal>
          </div>
        );
      }}
    </StoreConsumer>
  );
};

export default Calls;

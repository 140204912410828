import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { useFormatBlockTypes } from '../service/useFormatBlockTypes';
import { useCreateReadingContext } from '../CreateReadingProvider';
import { ACTIONS, BLOCK_TYPE } from '../service/constants';

const BuilderBlockCard = ({ builderBlock, index }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { type, id } = builderBlock;
  const { dispatch, builderState } = useCreateReadingContext();
  const { content } = builderState;
  const { blockTypes } = useFormatBlockTypes();

  const getBlockLabel = blockTypes.find((bType) => bType.type === type);
  const { title, icon: Icon } = getBlockLabel;

  const handleSelectBlock = () => {
    dispatch({ type: ACTIONS.SET_BLOCK_TYPE, payload: type });
    dispatch({ type: ACTIONS.HANDLE_SELECT_BLOCK, payload: builderBlock });
  };

  const handleRemoveBlock = () => {
    const filterBlocks = content.filter((block) => block.id !== id);
    dispatch({ type: ACTIONS.REMOVE_BLOCK, payload: filterBlocks });
  };

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <li
          className="builder-block-card"
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          {Icon && <Icon className="builder-block-card-icon" />}
          <div className="builder-block-card-content">
            {builderBlock[language] && type !== BLOCK_TYPE.BULLET_LIST && (
              <p className="builder-block-card-label">{builderBlock[language]}</p>
            )}
            <span className="builder-block-card-type">
              <strong>{t('formLabel.type')}: </strong>
              {title}
            </span>
          </div>
          <div className="builder-block-card-btns">
            {type !== BLOCK_TYPE.DIVIDER && (
              <FaRegEdit onClick={handleSelectBlock} className="builder-block-card-icons" />
            )}
            <FaRegTrashAlt onClick={handleRemoveBlock} className="builder-block-card-icons" />
          </div>
        </li>
      )}
    </Draggable>
  );
};

BuilderBlockCard.propTypes = {
  builderBlock: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default BuilderBlockCard;

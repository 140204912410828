import React from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'react-loader-spinner';
import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import TemplateDetail from 'components/createTemplate/TemplateDetail';
import ReadingsList from 'components/createTemplate/ReadingsList';

import { CreateTemplateProvider, useCreateTemplateContext } from 'components/createTemplate/CreateTemplateProvider';

import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';

import './create-template.scss';

const CreateTemplateWrapper = () => (
  <CreateTemplateProvider>
    <CreateTemplate />
  </CreateTemplateProvider>
);

const CreateTemplate = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const { searchId, isLoading } = useCreateTemplateContext();

  return (
    <section className="d-flex">
      <LeftMenu />
      <div className="dashboard__main">
        <Header title={searchId ? t('leftMenuHeader.editTemplate') : t('leftMenuHeader.template')} shadow />
        <div className="templates__content">
          {!isLoading && (
            <>
              <TemplateDetail />
              <div className="readings-list-container">
                <ReadingsList title={t('createTemplate.readings.title.all')} />
                <ReadingsList title={t('createTemplate.readings.title.own')} own />
              </div>
            </>
          )}
        </div>
      </div>
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={isLoading} />
    </section>
  );
};

export default CreateTemplateWrapper;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import Loader from 'react-loader-spinner';
import { pathOr, isEmpty } from 'ramda';
import Button from '../../SolidButton';
import Icon from '../../Icon';
import showNotification from '../../../utils/showNotification';
import { PERSONAL_INFORMATION } from '../../../utils/steps';
import { BULK_CREATE_PATIENTS } from '../../../apollo/mutations';
import noEmails from '../../../assets/images/no-plans.svg';

import './bulkInvite.scss';

const initialState = {
  emails: [],
  value: '',
  error: null,
};

const BulkInvite = ({ changeRoute, onSetStep }) => {
  const [bulkCreatePatients] = useMutation(BULK_CREATE_PATIENTS);
  const [bulkInvite, setBulkInvite] = useState(initialState);
  const [showLoader, setShowLoader] = useState(false);

  const { goBack = () => {} } = useHistory();

  const onSendInvitation = async () => {
    setShowLoader(true);
    const response = await bulkCreatePatients({
      variables: {
        emails: bulkInvite.emails,
      },
    });

    const errors = pathOr([], ['data', 'bulkCreatePatients', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setShowLoader(false);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? ['Se ha enviado la invitación exitosamente.'] : errors,
    });

    if (emptyErrors) {
      onSetStep(PERSONAL_INFORMATION);
      changeRoute('/pacientes');
    }
  };

  const handleKeyDown = (ev) => {
    if (['Enter', 'Tab', ','].includes(ev.key)) {
      ev.preventDefault();
      let email = bulkInvite.value.trim();

      if (email && isValid(email)) {
        setBulkInvite({
          ...bulkInvite,
          emails: [...bulkInvite.emails, email],
          value: '',
        });
      }
    }
  };

  const handleChange = (ev) => {
    setBulkInvite({
      ...bulkInvite,
      value: ev.target.value,
      error: null,
    });
  };

  const handleDelete = (email) => {
    setBulkInvite({
      ...bulkInvite,
      emails: bulkInvite.emails.filter(em => em !== email)
    });
  };

  const handlePaste = (ev) => {
    ev.preventDefault();
    let paste = ev.clipboardData.getData('text');
    // eslint-disable-next-line
    let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      let toBeAdded = emails.filter(email => !isInList(email));
      setBulkInvite({
        ...bulkInvite,
        emails: [...bulkInvite.emails, ...toBeAdded],
      });
    }
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} ya ha sido añadido.`;
    }
    if (!isEmail(email)) {
      error = `${email} no es una dirección de correo válida.`;
    }
    if (error) {
      setBulkInvite({
        ...bulkInvite,
        error,
      });
      return false;
    }
    return true;
  }

  const isInList = email => bulkInvite.emails.includes(email);
  // eslint-disable-next-line
  const isEmail = email => (/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/).test(email);

  return (
    <section className={'form-invite-container'}>
      <h3>1. Añade los correos que a los que enviarás invitación</h3>
      <label htmlFor={'emails-list'}>
        Escribe o pega los correos (separados por coma) a los que quieres hacerles llegar la invitación para integrarlos a la plataforma de HealthPhy. Ej: diego@hotmail.com, tatiana@gmail.com, juan@gmail.com
      </label>
      <div className={'form-invite-container__emails'}>
        <div className={'field'}>
          <p>Para:</p>
          <input
            id={'emails-list'}
            type={'text'}
            value={bulkInvite.value}
            placeholder={'ejemplo@mail.com'}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onPaste={handlePaste}
          />  
        </div>
        <div className={'emails'}>
          {bulkInvite.emails.map(email => (
            <div className={'added-email'} key={email}>
              <p>{email}</p>
              <Icon name={'close'} className={'close-invite'} onClick={() => handleDelete(email)} />
            </div>
          ))}  
        </div>
        {isEmpty(bulkInvite.emails) && (
          <div className={'empty-emails'}>
            <img src={noEmails} alt={'no emails added'}/>
            <p>Aún no has añadido ningún correo</p>
          </div>
        )}
        {bulkInvite.error && (
          <p className={'error'}>{bulkInvite.error}</p>
        )}
      </div>
      <div className={'buttons-container'}>
        <Button type={'button'} className={'secondary'} onClick={goBack}>
          Cancelar
        </Button>
        <Button 
          onClick={isEmpty(bulkInvite.emails) ? null : onSendInvitation}
          type={'button'}
          className={`${isEmpty(bulkInvite.emails) && 'disabled'}`}
        >
          Enviar
        </Button>
      </div>
      <Loader
        type={'ThreeDots'}
        color={'#495fd7'}
        height={100}
        width={100}
        className={'spinner'}
        visible={showLoader}
      />
    </section>
  );
};

export default BulkInvite;
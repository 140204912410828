import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { formatCurrency } from 'utils/formatCurrency';
import { useGetCompanyPaymentInfo } from 'components/invoices/service';

import PaymentMethod from 'components/PaymentMethod';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const OndemandHeader = ({ invoice, updatingPayment }) => {
  const { amountDue, status, period = '', invoiceItems = [] } = { ...invoice };
  const { paymentInfo, refetch } = useGetCompanyPaymentInfo();
  const { cardLast4, cardBrand, paymentMethodType, profile = {} } = { ...paymentInfo };
  const { currency } = { ...profile };
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
  const month = `0${today.getMonth() + 1}`.slice(-2);
  const year = today.getFullYear();
  const isEnglish = language === 'en';
  const dueDate = isEnglish ? `${month}/${lastDayOfMonth}/${year}` : `${lastDayOfMonth}/${month}/${year}`;
  const isDueDate = period.slice(0, 2) === month && status === 'draft';

  return (
    <section className="invoices__header">
      <div className="invoices__header_info">
        <section className="amount">
          <div className="amount__label">
            <span className="amount__label_title">{t('invoices.header.amount')}</span>
            <strong className="amount__label_value">{formatCurrency({ language, currency, amount: amountDue })}</strong>
          </div>
          {isDueDate && (
            <div className="amount__label">
              <span className="amount__label_title">{t('invoices.header.dueDate')}</span>
              <strong className={`amount__label_value ${'due-date'}`}>{dueDate}</strong>
            </div>
          )}
        </section>
        <section className="status-count">
          <div className="status-count__label">
            <span className="amount__label_title">{t('invoices.header.items')}</span>
            <strong className="status-count__label_count">
              {invoiceItems.length} {t('invoices.header.invoices')}
            </strong>
          </div>
          {status && (
            <div className="status-count__label">
              <span className="amount__label_title">{t('invoices.header.status')}</span>
              <strong className={`status-count__label_status ${status || ''}`}>{status}</strong>
            </div>
          )}
        </section>
      </div>
      <div className="invoices__header_payment">
        {!!currency && (
          <Elements stripe={stripePromise} options={{ paymentMethodCreation: 'manual', mode: 'setup', currency }}>
            <PaymentMethod
              cardLast4={cardLast4}
              cardBrand={cardBrand}
              type={paymentMethodType}
              refetch={refetch}
              setLoading={updatingPayment}
            />
          </Elements>
        )}
      </div>
    </section>
  );
};

OndemandHeader.propTypes = {
  invoice: PropTypes.shape({
    amountDue: PropTypes.number,
    status: PropTypes.string,
    period: PropTypes.string,
    invoiceItems: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  updatingPayment: PropTypes.func.isRequired,
};

export default OndemandHeader;

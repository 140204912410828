import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { propOr, isEmpty, pathOr } from 'ramda';
import Loader from 'react-loader-spinner';
import { useMutation } from '@apollo/react-hooks';

import { CREATE_UPDATE_CLINIC_PROFILE, UPDATE_PASSWORD } from 'apollo/mutations';
import { useAuthQuery } from 'utils/hooks';
import { useToggle } from 'utils/hooks/useToggle';
import { ROUTES, FETCH_BY_ROLE, GET_PARTNER_BY_ROLE } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';
import showNotification from 'utils/showNotification';
import { LEFT_MENU } from 'utils/leftMenu';

import PasswordModal from 'components/Profile/PasswordModal';
import Header from 'components/Header';
import ClinicProfile from 'components/SignUp/ClinicProfile';
import CompanyProfile from 'components/SignUp/CompanyProfile';
import SuperClinicProfile from 'components/SignUp/SuperClinicProfile';

const EditProfile = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { userRole, isClinic, isCompany, isSuperClinic, isSpecialist } = getUserRole();
  const { loading, data, refetch } = useAuthQuery(FETCH_BY_ROLE[userRole]);
  const partner = propOr({}, GET_PARTNER_BY_ROLE[userRole])(data);

  const [updateClinicProfile] = useMutation(CREATE_UPDATE_CLINIC_PROFILE);
  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  const [passwordModal, setPasswordModal] = useToggle();
  const [spinner, setSpinner] = useToggle();
  const [passwordModalKey, setPasswordModalKey] = useState(0);

  useEffect(() => {
    refetch();
  }, []);

  const onSubmit = async (values) => {
    setSpinner();
    const { file: avatar, countryName: country, doctorsNumber, ...rest } = { ...values };
    const response = await updateClinicProfile({
      variables: {
        profile: {
          ...rest,
          name: undefined,
          email: undefined,
          file: undefined,
          country,
          ...(doctorsNumber && {
            doctorsNumber: Number(doctorsNumber),
          }),
        },
        avatar,
      },
    });
    const errors = pathOr([], ['data', 'createUpdateClinicProfile', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [t('notification.updateProfile')] : errors,
    });

    if (emptyErrors) {
      refetch();
    }
  };

  const onChangePassword = async (values, callback) => {
    const { password } = { ...values };
    setSpinner();
    const response = await updatePassword({
      variables: {
        password,
      },
    });
    const errors = pathOr([], ['data', 'updateUserPassword', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [t('notification.updatePassword')] : errors,
    });

    if (emptyErrors) {
      refetch();
      callback();
      setPasswordModalKey(passwordModalKey + 1);
    }
  };

  const toggleOpenModal = () => {
    setPasswordModal();
    setPasswordModalKey(passwordModalKey + 1);
  };

  return (
    <div className="d-flex hvh-100">
      {LEFT_MENU[userRole]}
      <div className="dashboard__main flex-fill">
        <Header shadow title={t('leftMenuHeader.profile')} />
        {!loading && (
          <>
            {isClinic && (
              <ClinicProfile
                editProfile
                partner={partner}
                onContinue={onSubmit}
                toggleOpenModal={toggleOpenModal}
                onBack={() => push(ROUTES.CONFIGURATION)}
              />
            )}
            {isCompany && (
              <CompanyProfile
                editProfile
                partner={partner}
                onContinue={onSubmit}
                toggleOpenModal={toggleOpenModal}
                onBack={() => push(ROUTES.CONFIGURATION)}
              />
            )}
            {(isSuperClinic || isSpecialist) && (
              <SuperClinicProfile
                editProfile
                partner={partner}
                onContinue={onSubmit}
                toggleOpenModal={toggleOpenModal}
                onBack={() => push(ROUTES.CONFIGURATION)}
              />
            )}
          </>
        )}
      </div>
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || spinner}
      />
      <PasswordModal
        key={passwordModalKey}
        open={passwordModal}
        onCloseModal={toggleOpenModal}
        onSave={onChangePassword}
      />
    </div>
  );
};

export default EditProfile;

import React from 'react';
import { isEmpty } from 'ramda';
import SolidButton from '../SolidButton';
import { useAppState } from '../StoreProvider';
import {
  FaCalendarAlt,
  FaUserPlus,
  FaClipboardList,
  FaColumns,
} from 'react-icons/fa';
import { AiOutlineCheckCircle } from 'react-icons/ai';

import './createClinicData.scss';

const getIcon = (step) => {
  switch (step) {
    case 'calendar': {
      return <FaCalendarAlt/>;
    }
    case 'patients': {
      return <FaUserPlus/>;
    }
    case 'plans': {
      return <FaClipboardList/>; 
    }
    case 'forms':
      return <FaColumns/>;
    default: {
      return null;
    }
  }  
};

const getButtonText = (step) => {
  switch (step) {
    case 'calendar': {
      return 'Calendario';
    }
    case 'patients': {
      return 'Nuevo Paciente';
    }
    case 'plans': {
      return 'Planes'; 
    }
    case 'forms':
      return 'Formularios';
    default: {
      return null;
    }
  }  
};

const getContent = (step) => {
  switch (step) {
    case 'calendar': {
      return (
        <div className={'incomplete__content'}>
          <p className={'incomplete__content_title'}>Configurar disponibilidad</p>
          <p className={'incomplete__content_description'}>
            Click en <strong>"Calendario"</strong> y configura los bloques de horario por día disponibles para tus pacientes.
          </p>  
        </div>
      );
    }
    case 'patients': {
      return (
        <div className={'incomplete__content'}>
          <p className={'incomplete__content_title'}>Crear un paciente</p>
          <p className={'incomplete__content_description'}>
            Click en <strong>"Nuevo Paciente"</strong> y selecciona una de las dos opciones: empezar evaluación o invitar.
          </p>  
        </div> 
      );
    }
    case 'plans': {
      return (
        <div className={'incomplete__content'}>
          <p className={'incomplete__content_title'}>Crear un plan</p>
          <p className={'incomplete__content_description'}>
            Click en <strong>"Planes"</strong> y selecciona crear plan para tener tus propias plantillas de ejercicios.
          </p>  
        </div>  
      );
    }
    case 'forms':
      return (
        <div className={'incomplete__content'}>
          <p className={'incomplete__content_title'}>Crear tu primer formulario</p>
          <p className={'incomplete__content_description'}>
            Click en <strong>"Formularios"</strong> para crear un formato de evaluación, encuesta o seguimiento personalizado.
          </p>  
        </div>  
      );
    default: {
      return null;
    }
  }  
};

const CreateClinicData = ({ step, changeRoute, data }) => {
  const { toggleInviteCreateMember = () => {} } = useAppState();

	const handleCreateData = (step) => {
	  switch (step) {
	    case 'calendar': {
	      return changeRoute({
	      	pathname: '/calendario',
	      	state: true,
	      });
	    }
	    case 'patients': {
	      return toggleInviteCreateMember();
	    }
	    case 'plans': {
	      return changeRoute('/crear-plan'); 
	    }
	    case 'forms':
	      return changeRoute('/crear-formulario');
	    default: {
	      return null;
	    }
	  }  
	};

  return (
    <section className={`incomplete ${!isEmpty(data) && 'completed'}`}>
    	<div className={`incomplete__icon ${!isEmpty(data) && 'completed'}`}>
    		{isEmpty(data) ? getIcon(step) : <AiOutlineCheckCircle/>}
    	</div>
    	{getContent(step)}
    	<div className={'incomplete__button'}>
    		<SolidButton 
    			className={`min-width-140 slim ${!isEmpty(data) && 'disabled'}`}
    			onClick={() => isEmpty(data) ? handleCreateData(step) : null}
    		>
    			{isEmpty(data) ? getButtonText(step) : 'Completo'}
    		</SolidButton>
    	</div>
    </section>
  );
}

export default CreateClinicData;
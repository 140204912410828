import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';

import { useFormInput } from 'utils/hooks';
import { useToggle } from 'utils/hooks/useToggle';
import { useGetCategories } from 'utils/hooks/useGetCategories';
import { useFilterSegments } from 'utils/hooks/useFilterSegments';

import SearchFilter from 'components/SearchFilter';
import CategoriesButton from 'components/CategoriesButton';
import CategoriesFilter from 'components/CategoriesFilter';
import { ItemCard } from './ItemCard';

const ItemList = ({ items, addContent, selectedContent, isExercises }) => {
  const { t } = useTranslation();
  const searchExercises = useFormInput('');
  const { value = '' } = { ...searchExercises };
  const categories = useGetCategories({ exercises: items });
  const filterStates = useFilterSegments();
  const { filterParts, filterEquipments, filterLevels } = filterStates;

  const [allExercises, setAllExercises] = useState([]);
  const [isCategoriesOpen, setIsCategoriesOpen] = useToggle();

  useEffect(() => {
    if (isEmpty(allExercises) && !isEmpty(items)) {
      setAllExercises(items);
    }
  }, [items]);

  const handleFilterElements = (allEx, category) =>
    allEx.filter((ex) => {
      const { bodyParts: exbpts = [] } = ex;
      return exbpts.map((e) => category.indexOf(e) !== -1).reduce((a, b) => a || b, false);
    });

  useEffect(() => {
    let temporal = items;
    const lowerCaseValue = value.toLowerCase();
    if (value) {
      temporal = items.filter(({ title, bodyParts = [] }) => {
        const bodyPartsString = bodyParts.join(' - ').toLowerCase();
        return `${title}`.toLowerCase().includes(lowerCaseValue) || bodyPartsString.includes(lowerCaseValue);
      });
    }
    if (!isEmpty(filterParts)) {
      temporal = handleFilterElements(temporal, filterParts);
    }
    if (!isEmpty(filterEquipments)) {
      temporal = handleFilterElements(temporal, filterEquipments);
    }
    if (!isEmpty(filterLevels)) {
      temporal = handleFilterElements(temporal, filterLevels);
    }
    setAllExercises(temporal);
  }, [value, filterParts, filterEquipments, filterLevels]);

  return (
    <section className="evie-program__item-list-container">
      <div className="active-pause__exercises_filters">
        <SearchFilter searchItems={searchExercises} placeholder={t('placeholder.searchExercise')} />
        {isExercises && <CategoriesButton showCategories={isCategoriesOpen} toggleCategories={setIsCategoriesOpen} />}
      </div>
      <CategoriesFilter
        className="create-active-break-view"
        showCategories={isCategoriesOpen}
        categories={categories}
        filterStates={filterStates}
      />
      <ul className={`evie-program__item-list ${isExercises ? 'exercises' : ''}`}>
        {allExercises.map((item) => {
          const { id } = item;
          return (
            <ItemCard
              key={id}
              item={item}
              addContent={addContent}
              selectedContent={selectedContent}
              isExercises={isExercises}
            />
          );
        })}
      </ul>
    </section>
  );
};

ItemList.defaultProps = {
  isExercises: false,
};

ItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  addContent: PropTypes.func.isRequired,
  selectedContent: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isExercises: PropTypes.bool,
};

export default ItemList;

import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import Loader from 'react-loader-spinner';

import { readingDetail } from 'utils/validationSchema';
import { useGetReading } from 'components/readings/service/useGetReading';
import { useCreateUpdateReading } from 'components/readings/service/useCreateUpdateReading';

import UserAvatar from 'components/UserAvatar';
import Icon from 'components/Icon';
import SolidButton from 'components/SolidButton';
import { TextInput } from 'components/FormComponents';
import { useCreateReadingContext } from '../CreateReadingProvider';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const CreateReadingOld = () => {
  const { t } = useTranslation();
  const { goBack, readingId, blockContent } = useCreateReadingContext();
  const { loading, setEditorState, readingData, editorState } = useGetReading(readingId, blockContent);
  const { title = '', backgroundImage = {} } = { ...readingData };
  const { thumb } = { ...backgroundImage };
  const { spinner, onCreateUpdateReading } = useCreateUpdateReading({ editorState, readingId });

  const [imageUrl, setImageUrl] = useState('');
  const fileInput = useRef();
  const reader = new FileReader();

  reader.onloadend = () => {
    setImageUrl(reader.result);
  };

  const handleFileInputClick = () => {
    if (fileInput) {
      fileInput.current.click();
    }
  };

  const onEditorStateChange = (editState) => {
    setEditorState(editState);
  };
  return (
    <section className="reading">
      <Formik
        initialValues={{ title }}
        onSubmit={onCreateUpdateReading}
        validationSchema={readingDetail}
        enableReinitialize
      >
        {({ setFieldValue, isSubmitting, isValid }) => {
          const isDisabledButton = [!isValid, isSubmitting].includes(true);
          return (
            <Form className="template-detail">
              <div className="template-detail__avatar">
                <UserAvatar size="huge" avatarUrl={imageUrl || thumb}>
                  <div className="template-detail__camera" aria-hidden="true" onClick={handleFileInputClick}>
                    <Icon name="camera" />
                  </div>
                  <input
                    type="file"
                    className="file-upload"
                    ref={fileInput}
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      const upload = event.currentTarget.files[0];
                      setFieldValue('backgroundImage', upload);
                      if (upload) {
                        reader.readAsDataURL(upload);
                      }
                    }}
                  />
                </UserAvatar>
              </div>
              <div className="template-detail__description reading__description">
                <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
                <div className="reading__button">
                  <SolidButton className="secondary" onClick={goBack}>
                    {t('button.back')}
                  </SolidButton>
                  <SolidButton
                    className={`ml-2 ${isDisabledButton ? 'disabled' : ''}`}
                    disabled={isDisabledButton}
                    type="submit"
                  >
                    {readingId ? t('button.update') : t('button.create')}
                  </SolidButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="reading__editor">
        {!loading && (
          <Editor
            editorClassName="rich-text-editor"
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            placeholder="Write something!"
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'fontFamily',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'emoji',
                'history',
                'image',
              ],
              fontFamily: {
                options: ['SF Pro Text'],
              },
            }}
          />
        )}
      </div>
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={(loading && readingId) || spinner}
      />
    </section>
  );
};

export default CreateReadingOld;

import React from 'react';
import { useTranslation } from 'react-i18next';

import SolidButton from 'components/SolidButton';

import noData from 'assets/images/no-data.svg';

import './NoTeamMembers.scss';

const NoTeamMembers = ({ openModal }) => {
  const { t } = useTranslation();
  return (
    <section className="no-team-members-container">
      <div className="no-team-members-container__content">
        <p className="title">{t('settings.team.emptyState.title')}</p>
        <SolidButton onClick={openModal}>{t('settings.team.addBtn')}</SolidButton>
      </div>
      <div className="no-team-members-container__image">
        <img src={noData} alt="no team members" />
      </div>
    </section>
  );
};

export default NoTeamMembers;

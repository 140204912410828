import React from 'react';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Button from '../../Button';
import VideoPlayer from '../../VideoPlayer';
import './exercise-modal.scss';

const modalProps = {
  content: {
    width: '60vw',
    maxWidth: '840px',
    outline: 'none',
    padding: '0px !important',
  },
};

const ExerciseModal = props => {
  const { open, onCloseModal, exercise, onAdd } = { ...props };
  const { tips, video, reps, rest, sets, instructions } = exercise;
  const onAddExercise = () => {
    onAdd && onAdd();
    onCloseModal();
  };
  return (
    <Modal modalIsOpen={open} closeModal={onCloseModal} modalProps={modalProps} closeable={false}>
      <div className={'exercise__modal'}>
        <div className={'close-icon-container'}>
          <Icon name='close' className={'close-icon'} onClick={onCloseModal} />
        </div>
        <p className={'exercise__modal__title'}>Detalles del ejercicio</p>
        <div className={'exercise__modal__info'}>
          <div className={'exercise__modal__row'}>
            <VideoPlayer source={video} />
            <div className={'exercise__modal__data'}>
              <div className={'info-row'}>
                <p className={'label'}>Repeticiones</p>
                <p className={'value'}>{reps}</p>
              </div>
              <div className={'info-row'}>
                <p className={'label'}>Series</p>
                <p className={'value'}>{sets}</p>
              </div>
              <div className={'info-row'}>
                <p className={'label'}>Descanso</p>
                <p className={'value'}>{rest}</p>
              </div>
              {onAdd && (
                <div className={'button-container'}>
                  <Button type='button' category='table-primary' className={`ml-3 add-exercise`} onClick={onAddExercise}>
                    <Icon name={'add'} className={'icon'} />
                    Añadir
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className={'exercise__modal__row'}>
            <div className={'info-col'}>
              <p className={'label'}>Instrucciones</p>
              <ol>
                {`${instructions}`
                  .split('- ')
                  .filter(el => !!el)
                  .map((item, index) => (
                    <li className={'value'} key={index}>
                      {item}
                    </li>
                  ))}
              </ol>
            </div>
            {tips && (
              <div className={'info-col'}>
                <p className={'label'}>Consejos</p>
                <ol>
                  {`${tips}`
                    .split('- ')
                    .filter(el => !!el)
                    .map((item, index) => (
                      <li className={'value'} key={index}>
                        {item}
                      </li>
                    ))}
                </ol>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExerciseModal;

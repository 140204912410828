import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { BsDownload } from 'react-icons/bs';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { isEmpty, pathOr } from 'ramda';

import { useToggle } from 'utils/hooks/useToggle';
import { useDownloadPdf } from 'utils/hooks';
import showNotification from 'utils/showNotification';
import { CREATE_PATIENT_FOLLOWUP } from 'apollo/mutations';

import Icon from 'components/Icon';
import { NoPatientData } from 'components/EmptyState';
import SolidButton from 'components/SolidButton';
import FollowUpModal from '../FollowUpModal';
import FollowUpSurveyViewModal from '../FollowUpSurveyViewModal';
import FollowupSurveyToPrint from '../FollowupSurveyToPrint';
import FullHistoryModal from '../FullHistoryModal';

const FollowUp = ({ patient = {}, setSpinner = () => {}, refetch = () => {} }) => {
  const { patientFollowups: followUps = [], id: patientId = '' } = { ...patient };
  const [modalIsOpen, setIsModalOpen] = useToggle(false);
  const [followUpModalIsOpen, setIsFollowUpModalOpen] = useToggle(false);
  const [isFullHistory, setIsFullHistory] = useToggle(false);
  const [followUp, setFollowUp] = useState({});
  const [createPatientFollowup] = useMutation(CREATE_PATIENT_FOLLOWUP);

  const onCloseModal = () => setIsModalOpen(false);
  const onOpenModal = () => setIsModalOpen(true);
  const onCloseFollowUpModal = () => setIsFollowUpModalOpen(false);
  const onOpenViewFollowUpModal = (data) => () => {
    setFollowUp(data);
    setIsFollowUpModalOpen(true);
  };

  const onSubmit = async (values, followupForm, formId) => {
    const { ownerId = '' } = { ...values };
    setSpinner(true);
    const formValues = followupForm
      .filter(({ type }) => type !== 'file')
      .map((formField) => {
        const { id } = { ...formField };
        return { ...formField, value: (typeof values[id] === 'object' ? values[id].join(', ') : values[id]) || '' };
      });
    const files = followupForm
      .filter(({ type, id }) => type === 'file' && !isEmpty(values[id]))
      .map((formField) => {
        const { id = '', label = '' } = { ...formField };
        return { label, file: values[id][0] };
      });
    const response = await createPatientFollowup({
      variables: {
        patientId,
        patientFollowup: {
          content: formValues,
          formId,
          ...(!!ownerId && {
            ownerId,
            ownerType: 'TeamMember',
          }),
        },
        ...(!isEmpty(files) && {
          files,
        }),
      },
    });
    const errors = pathOr([], ['data', 'createPatientFollowup', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? ['Se ha agregado exitosamente el seguimiento.'] : errors,
    });

    if (emptyErrors) {
      refetch();
      setIsModalOpen(false);
    }
  };

  const onPrint = (callback, data) => () => {
    setFollowUp(data);
    callback();
  };

  const { componentRef, handlePrint } = useDownloadPdf();

  return (
    <div className="w-100">
      <div className="patient__title">
        <div>
          <Icon name="followup" className="icon" />
          <p className="title">Datos de seguimiento</p>
        </div>
        {!isEmpty(followUps) ? (
          <SolidButton className="slim secondary" onClick={setIsFullHistory}>
            Full history
          </SolidButton>
        ) : null}
      </div>
      <div className="patient__tab_content follow-up">
        {isEmpty(followUps) ? (
          <NoPatientData tab="followup" onCreateData={onOpenModal} />
        ) : (
          <>
            <div className="patient__logs">
              {followUps.map(({ createdAt, ...data }, index) => {
                const { form = {} } = { ...data };
                const { title: formTitle = '' } = { ...form };
                return (
                  <div className="item" key={createdAt}>
                    <div className="info">
                      <p className="title">{formTitle || `Seguimiento #${index + 1}`}</p>
                      <p className="date">{moment(createdAt).format('DD/MM/YYYY - hh:mma')}</p>
                    </div>
                    <div className="events">
                      <div className="event-detail">
                        <IoIosArrowDown onClick={onOpenViewFollowUpModal(data)} />
                      </div>
                      <div className="event-detail">
                        <BsDownload onClick={onPrint(handlePrint, data)} />
                      </div>
                    </div>
                    <div style={{ display: 'none' }}>
                      <FollowupSurveyToPrint data={followUp} ref={componentRef} />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="add-followup-button">
              <SolidButton onClick={() => setIsModalOpen(true)}>Agregar</SolidButton>
            </div>
          </>
        )}
      </div>
      {modalIsOpen && <FollowUpModal modalIsOpen={modalIsOpen} onCloseModal={onCloseModal} onSubmit={onSubmit} />}
      <FollowUpSurveyViewModal modalIsOpen={followUpModalIsOpen} onCloseModal={onCloseFollowUpModal} data={followUp} />
      {isFullHistory && (
        <FullHistoryModal open={isFullHistory} onCancelAction={setIsFullHistory} followUps={followUps} />
      )}
    </div>
  );
};

export default FollowUp;

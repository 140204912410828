import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GET_CUSTOMER_PORTAL } from 'apollo/queries';
import { logout } from 'libs/authenticationService';
import { useToggle } from 'utils/hooks/useToggle';
import { useAuthQuery } from 'utils/hooks';
import { ROUTES } from 'utils/constants';
import { destroyChat } from 'utils/isChat';
import { getUserRole } from 'utils/getUserRole';

import UserAvatar from 'components/UserAvatar';
import Icon from 'components/Icon';
import { SignUpPartnerModal } from 'components/SignUp';

import './navigation.scss';

const Navigation = ({ thumb, id, termsConditions, isSubscription, loading, refetch }) => {
  const { isClinic, isCompany } = getUserRole();
  const history = useHistory();

  const { data: dataPortal, refetch: refetchPortal } = isCompany || isClinic ? useAuthQuery(GET_CUSTOMER_PORTAL) : {};
  const customerPortal = propOr({}, 'getCustomerPortal')(dataPortal);
  const { url: portalUrl } = { ...customerPortal };

  const [signUpModal, setSignUpModal] = useToggle();
  const { t } = useTranslation();

  useEffect(() => {
    if ((!id || !termsConditions) && (isCompany || isClinic) && !loading) {
      setSignUpModal();
    }
  }, [id, termsConditions, loading]);

  const onCloseModal = () => {
    refetch();
    setSignUpModal();
  };

  const onProfile = () => {
    history.push(ROUTES.CONFIGURATION);
  };

  const onSubscriptions = async () => {
    await refetchPortal();
    window.open(portalUrl);
  };

  const onLogout = () => {
    if (isClinic) {
      destroyChat();
    }
    logout();
    history.replace(ROUTES.HOME);
    localStorage.clear();
  };

  const isSubscriptions = !!portalUrl && (isClinic || isCompany) && isSubscription;

  return (
    <>
      <div className="navigation__dropdown">
        <UserAvatar size="small" avatarUrl={thumb} className="navigation__avatar mr-1" />
        <span className="navigation__name">
          <Icon name="chevron-down" className="icon mr-2" />
        </span>
        <div className="navigation__dropdown-menu">
          <button type="button" className="navigation__link" onClick={onProfile}>
            <Icon name="profile" className="icon" />
            <span>{t('navigation.profile')}</span>
          </button>
          {isSubscriptions && (
            <button type="button" className="navigation__link" onClick={onSubscriptions}>
              <Icon name="billing" className="icon" />
              <span>{t('navigation.subscriptions')}</span>
            </button>
          )}
          {isClinic && (
            <>
              <button type="button" className="navigation__link" onClick={() => window.open(ROUTES.TERMS)}>
                <Icon name="subscriptions" className="icon" />
                <span>{t('navigation.terms')}</span>
              </button>
              <button type="button" className="navigation__link" onClick={() => window.open(ROUTES.PRIVACY)}>
                <Icon name="lock" className="icon-poly" />
                <span>{t('navigation.policy')}</span>
              </button>
            </>
          )}
          <button type="button" className="navigation__link red" onClick={onLogout}>
            <Icon name="logout" className="icon" />
            <span>{t('navigation.logout')}</span>
          </button>
        </div>
      </div>
      {signUpModal && !loading && <SignUpPartnerModal open={signUpModal && !loading} onCloseModal={onCloseModal} />}
    </>
  );
};

Navigation.propTypes = {
  thumb: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  termsConditions: PropTypes.bool.isRequired,
  isSubscription: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default Navigation;

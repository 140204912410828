import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { path, pathOr, isEmpty } from 'ramda';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import { useAuthQuery } from 'utils/hooks';
import { passwordRecovery } from 'utils/validationSchema';
import { queryParams } from 'utils/string';
import showNotification from 'utils/showNotification';
import { ROUTES } from 'utils/constants';
import { VALID_USER } from 'apollo/queries';
import { SET_NEW_PASSWORD } from 'apollo/mutations';

import Authentication from 'components/Authentication';
import SolidButton from 'components/SolidButton';
import NoUrlFound from 'components/NoUrlFound';
import { PasswordInput } from 'components/FormComponents';

const PasswordRecovery = (props) => {
  const { t } = useTranslation();
  const changeRoute = path(['history', 'replace'])(props);
  const search = path(['history', 'location', 'search'])(props);
  const params = { ...queryParams(search) };
  const { email, token, role } = { ...params };

  const { loading, data = {} } = useAuthQuery(VALID_USER, {
    variables: {
      email,
      token,
      role,
    },
  });

  const { validUser } = { ...data };

  const [showSpinner, setSpinner] = useState(false);
  const [setNewPassword] = useMutation(SET_NEW_PASSWORD);

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const onPasswordRecovery = async ({ password }) => {
    setSpinner(true);
    const response = await setNewPassword({
      variables: {
        email,
        role,
        password,
      },
    });

    const errors = pathOr([], ['data', 'setNewPassword', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);
    showNotification({
      type: isEmpty(errors) ? 'success' : 'error',
      messages: isEmpty(errors) ? [t('notification.passwordRecovered')] : errors,
    });

    if (emptyErrors) changeRoute(ROUTES.HOME);
  };

  return (
    <>
      {!!validUser && !loading && (
        <Authentication title={t('login.access')}>
          <Formik
            initialValues={initialValues}
            onSubmit={onPasswordRecovery}
            validationSchema={passwordRecovery}
            enableReinitialize
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div style={{ marginBottom: 65 }}>
                  <Field
                    label={t('formLabel.password')}
                    name="password"
                    component={PasswordInput}
                    labelClassName="login-label"
                  />
                </div>
                <Field
                  label={t('formLabel.confirmPassword')}
                  name="confirmPassword"
                  component={PasswordInput}
                  labelClassName="login-label"
                />
                <div className="password-recovery-button">
                  <SolidButton type="submit">{t('button.retrieve')}</SolidButton>
                </div>
              </Form>
            )}
          </Formik>
        </Authentication>
      )}
      {!validUser && !loading && <NoUrlFound message={t('login.empty')} />}
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || showSpinner}
      />
    </>
  );
};

export default PasswordRecovery;

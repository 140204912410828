import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';

import { ROUTES } from 'utils/constants';

import Thumb from 'components/Thumb';

const ProgramsList = ({ programs }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <>
      {(programs || []).map((program) => {
        const {
          id,
          title,
          doctorName,
          duration,
          backgroundImage: { thumb },
        } = { ...program };
        return (
          <tr key={id} className="">
            <td style={{ width: '20%' }}>
              <Thumb source={thumb} alt={title} />
            </td>
            <td style={{ width: '30%' }}>{title}</td>
            <td style={{ width: '30%' }}>{doctorName}</td>
            <td style={{ width: '10%' }}>
              {duration} {t('programs.duration')}
            </td>
            <td className="actions-col" style={{ width: '10%' }}>
              <button type="button" onClick={() => push(`${ROUTES.PROGRAM}?id=${id}`)} className="form-detail">
                <IoIosArrowDown />
              </button>
            </td>
          </tr>
        );
      })}
    </>
  );
};

ProgramsList.propTypes = {
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      doctorName: PropTypes.string,
      duration: PropTypes.string,
      backgroundImage: PropTypes.shape({
        thumb: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default ProgramsList;

import React from 'react';

import SolidButton from 'components/SolidButton';

const SelectedSurvey = (props) => {
  const { selectedSurvey = {}, handleSelectQuestion = () => {}, selectedQuestions = [] } = { ...props };
  const { content = [] } = { ...selectedSurvey };
  return (
    <div className="group">
      {content.map(({ groupTitle, questions, id: groupId }) => (
        <div key={groupId} className="group__content">
          {groupTitle !== 'single' && <h3 className="send-survey">{groupTitle}</h3>}
          {(questions || []).map(({ id: questionId, label }) => {
            const isSelected = [...selectedQuestions].indexOf(questionId) !== -1;
            return (
              <div key={questionId} className="group__question">
                <p className="group__question_label">{label}</p>
                <SolidButton
                  onClick={() => handleSelectQuestion(questionId)}
                  className={`${isSelected ? 'remove' : 'add'} min-width-100 slim`}
                >
                  {isSelected ? 'Eliminar' : 'Añadir'}
                </SolidButton>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export { SelectedSurvey };

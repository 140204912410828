import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'utils/constants';
import { useRedirectByStatus } from 'utils/hooks/useRedirectByStatus';

import SolidButton from 'components/SolidButton';

import logoHealthphy from 'assets/images/logo-blue.svg';
import './new-home.scss';

const NewHome = () => {
  const { t } = useTranslation();
  useRedirectByStatus();
  const { push } = useHistory();

  return (
    <div className="new-home-container">
      <section className="new-home-container__content">
        <div className="new-home-container__content__logo">
          <img src={logoHealthphy} alt="HealthPhy Logo" />
        </div>
        <h2>{t('login.welcome')}</h2>
        <div className="new-home-container__content__buttons">
          <SolidButton onClick={() => push(ROUTES.CLINICS)} className="tertiary">
            {t('button.clinics')}
          </SolidButton>
          <SolidButton onClick={() => push(ROUTES.COMPANIES)}>{t('button.companies')}</SolidButton>
        </div>
      </section>
      <footer className="new-home-container__footer">©{new Date().getFullYear()} - HealthPhy</footer>
    </div>
  );
};

export default NewHome;

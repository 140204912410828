import React from 'react';
import { useTranslation } from 'react-i18next';

import noExercises from 'assets/images/no-plans.svg';

import './empty-state.scss';

const NoExercisesFound = () => {
  const { t } = useTranslation();

  return (
    <div className="library__no-exercises">
      <p>{t('emptyState.description')}</p>
      <img src={noExercises} alt="no exercises found" />
    </div>
  );
};

export default NoExercisesFound;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { BiDumbbell } from 'react-icons/bi';
import { RiHeartPulseLine } from 'react-icons/ri';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useEvieProgramContext } from 'components/eviePrograms/EvieProgramProvider';
import { ACTIONS } from 'components/eviePrograms/service';

import SolidButton from 'components/SolidButton';
import ItemsHeader from '../ItemsHeader';
import ExerciseSessionCard from '../ExerciseSessionCard/ExerciseSessionCard';

const EvieAddSessionExercises = ({ programSession, sessionNumber }) => {
  const { id, sessionExercises, sessionSqueezes } = programSession;
  const { t } = useTranslation();
  const { dispatch } = useEvieProgramContext();

  const exercisesHeader = {
    label: t('eviePrograms.itemsHeader.exercises.label'),
    description: t('eviePrograms.itemsHeader.exercises.description'),
    icon: <BiDumbbell />,
  };

  const squeezesHeader = {
    label: t('eviePrograms.itemsHeader.tasks.label'),
    description: t('eviePrograms.itemsHeader.tasks.description'),
    icon: <RiHeartPulseLine />,
  };

  const handleAddExerciseSession = () => {
    dispatch({
      type: ACTIONS.ADD_EXERCISE_SESSION,
      payload: {
        id,
        exerciseSession: { id: uuid(), isNew: true, exercises: [] },
      },
    });
  };

  const handleAddSqueezeSession = () => {
    dispatch({
      type: ACTIONS.ADD_SQUEEZE_SESSION,
      payload: {
        id,
        squeezeSession: { id: uuid(), isNew: true, squeezes: [] },
      },
    });
  };

  const onDragEnd = ({ result, items, squeeze }) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }
    if (type === 'list') {
      const currentDraggable = items.find((item) => item.id === draggableId);
      const newOrderedItems = [...items];
      newOrderedItems.splice(source.index, 1);
      newOrderedItems.splice(destination.index, 0, currentDraggable);
      if (squeeze) {
        dispatch({
          type: ACTIONS.DRAG_AND_DROP_SQUEEZE_SESSION,
          payload: { sessionId: id, newTaskExercises: newOrderedItems },
        });
      } else {
        dispatch({
          type: ACTIONS.DRAG_AND_DROP_EXERCISE_SESSION,
          payload: { sessionId: id, newSessionExercises: newOrderedItems },
        });
      }
    }
  };

  return (
    <>
      <ItemsHeader {...exercisesHeader} />
      <section className="evie-program__session-exercises">
        <DragDropContext onDragEnd={(result) => onDragEnd({ result, items: sessionExercises, squeeze: false })}>
          <Droppable droppableId="ex-block-id" type="list" direction="horizontal">
            {(provided) => (
              <ul className="evie-program__session-exercises-list" ref={provided.innerRef} {...provided.droppableProps}>
                {sessionExercises.map((exerciseSession, exSessionIndex) => {
                  const { id: exerciseSessionId } = exerciseSession;
                  return (
                    <ExerciseSessionCard
                      key={exerciseSessionId}
                      item={exerciseSession}
                      sessionId={id}
                      sessionNumber={sessionNumber}
                      items={sessionExercises}
                      index={exSessionIndex}
                    />
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <div className="evie-program__add-block">
          <SolidButton className="evie-add" onClick={handleAddExerciseSession}>
            {t('eviePrograms.session.addExBlockBtn')}
            <AiOutlinePlusSquare />
          </SolidButton>
        </div>
      </section>
      <ItemsHeader {...squeezesHeader} />
      <section className="evie-program__session-exercises">
        <DragDropContext onDragEnd={(result) => onDragEnd({ result, items: sessionSqueezes, squeeze: true })}>
          <Droppable droppableId="task-block-id" type="list" direction="horizontal">
            {(provided) => (
              <ul className="evie-program__session-exercises-list" ref={provided.innerRef} {...provided.droppableProps}>
                {sessionSqueezes.map((squeeze, squeezeIndex) => {
                  const { id: squeezeId } = squeeze;
                  return (
                    <ExerciseSessionCard
                      key={squeezeId}
                      item={squeeze}
                      sessionId={id}
                      sessionNumber={sessionNumber}
                      items={sessionSqueezes}
                      index={squeezeIndex}
                      isSqueeze
                    />
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <div className="evie-program__add-block">
          <SolidButton className="evie-add" onClick={handleAddSqueezeSession}>
            {t('eviePrograms.session.addTaskBlockBtn')}
            <AiOutlinePlusSquare />
          </SolidButton>
        </div>
      </section>
    </>
  );
};

EvieAddSessionExercises.propTypes = {
  programSession: PropTypes.shape({
    id: PropTypes.string,
    sessionExercises: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    sessionSqueezes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  }).isRequired,
  sessionNumber: PropTypes.number.isRequired,
};

export default EvieAddSessionExercises;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { BsFillChatDotsFill } from 'react-icons/bs';

import { useConversationsContext } from 'components/conversations/ConversationsProvider';
import showNotification from 'utils/showNotification';
import { ROUTES } from 'utils/constants';

import ConversationModal from '../ConversationsModal';

const ConversationNotifications = () => {
  const {
    location: { pathname },
  } = useHistory();
  const { unreadConversationsCount, isOpenModal, handleModal } = useConversationsContext();
  const getUnreadConversationsCount = Object.values(unreadConversationsCount).reduce(
    (acc, curr) => (curr > 1 ? acc + 1 : acc + curr),
    0,
  );

  const handleOpenModal = () => {
    if (pathname === ROUTES.CHAT) {
      showNotification({ type: 'warning', messages: ['You are already in the chat'] });
    } else {
      handleModal();
    }
  };

  return (
    <>
      <div className="web-notifications chat-notifications">
        <BsFillChatDotsFill className="web-notifications__bell" onClick={handleOpenModal} />
        {!!getUnreadConversationsCount && (
          <div className="chat-notifications__count">{getUnreadConversationsCount}</div>
        )}
      </div>
      <ConversationModal isOpen={isOpenModal} onCloseModal={handleModal} />
    </>
  );
};

export default ConversationNotifications;

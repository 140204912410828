import React from 'react';

import Modal from 'components/Modal';
import Icon from 'components/Icon';
import FollowUpInfo from '../FollowUpInfo';

import { modalProps } from './helpers';

const FollowUpSurveyViewModal = (props) => {
  const { modalIsOpen = false, onCloseModal = () => {}, data = {} } = { ...props };

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={onCloseModal} modalProps={modalProps} closeable={false}>
      <div className="patient__modal">
        <div className="close-icon-container">
          <Icon name="close" className="close-icon" onClick={onCloseModal} />
        </div>
        <FollowUpInfo data={data} />
      </div>
    </Modal>
  );
};

export default FollowUpSurveyViewModal;

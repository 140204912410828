import React from 'react';
import PropTypes from 'prop-types';

const ItemsHeader = ({ label, description, icon }) => (
  <header className="evie-program__items-header">
    {icon}
    <h3 className="evie-program__items-header_label">{label}</h3>
    <div className="evie-program__items-header_divider" />
    <p className="evie-program__items-header_description">{description}</p>
  </header>
);

ItemsHeader.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

export default ItemsHeader;

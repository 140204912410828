import React, { useState } from 'react';
import { FaEdit, FaUniversity } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { firstLetterCase } from 'utils/string';
import PaymentIcon from 'react-payment-icons';

import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Formik, Field, Form } from 'formik';
import { isEmpty, pathOr } from 'ramda';
import { MultiSelect, NumberInput } from 'components/FormComponents';
import { useMutation } from '@apollo/react-hooks';
import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';
import { UPDATE_PAYMENT_METHOD } from 'apollo/mutations';

import './payment-method.scss';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: '700px',
    padding: 30,
  },
};

const PaymentMethod = ({ cardLast4, type, cardBrand, setLoading, refetch  }) => {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const handleCardSubmit = async (event) => {
    event.preventDefault();
    setLoading && setLoading(true);
    if (!stripe || !elements) {
      return;
    }

    elements.submit();

    const result = await stripe.createPaymentMethod({
      elements,
    });

    if (result.error) {
      console.log(result.error.message);
      setLoading && setLoading(false);
    } else {
      if (!!result.paymentMethod.id) {
        const response = await updatePaymentMethod({
          variables: {
            paymentMethodId: result.paymentMethod.id,
          },
        });

        if (response?.data?.setPaymentMethod?.success) {
          toggleModal();
          refetch && refetch();
        }
        setLoading && setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal modalIsOpen={modal} closeModal={toggleModal} closeable={false} modalProps={modalProps}>
        <p className="settings__left_profile">{t('settings.payment.method')}</p>
        <div>
          <form onSubmit={handleCardSubmit}>
            <div className='payment__method__modal__body'>
              <PaymentElement />
            </div>
            <br />
            <div className='payment_config__form__buttons'>
              <SolidButton type='submit'>{t('button.save')}</SolidButton>
              <SolidButton className="secondary" onClick={toggleModal}>
                {t('button.cancel')}
              </SolidButton>
            </div>
          </form>
        </div>
      </Modal>

      <div className="payment__method__container">
        <div className="payment__method__title__container">
          <h3 className="payment__method__title">{t('settings.payment.method')}</h3>
          {cardLast4 && <div className="payment__method__edit" onClick={toggleModal}>
            <span>{t('button.edit')}</span>
            <FaEdit />
          </div>}
        </div>
        <div className={'payment__method__content'}>
          {!!cardLast4 ?
          <>
            {type === 'card'?
              <PaymentIcon id={cardBrand} className='payment__method__payment_icon'/>:
              <div className='payment__method__bank_icon__container'><FaUniversity className='payment__method__bank_icon'/></div>
            }
            <div className="payment__method__content__card_data">
              <p className="brand_name">{type === 'card'?firstLetterCase(cardBrand || ''):t('settings.payment.bank')}</p>
              <p className="card_number">{type === 'card'? '＊＊＊＊ ＊＊＊＊ ＊＊＊＊':`${cardBrand} ＊＊＊＊＊＊＊＊＊`}{}<span>{cardLast4}</span></p>
            </div>
          </> :
          <>
            <div className="payment__method__content__card_data" onClick={toggleModal}>
              <p className="add_payment_method">{`+ ${t('button.add')} ${t('settings.payment.method')}`}</p>
            </div>
          </>}
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;

import React from 'react';
import VideoPlayer from '../VideoPlayer';

import './selectedExercise.scss';

const SelectedExercise = ({ selectedExercise, selectedPart }) => {
  const { tips, video, instructions, levels = [], title: selectedTitle = '' } = { ...selectedExercise };

  return (
  	<section className={'selected-ecercise-content'}>
    	<VideoPlayer source={video} style={{ width: '100%', height: '45%', alignSelf: 'center' }}/>
  		<header className={'header-content'}>
  		 <p className={'header-content__title'}>{selectedTitle}</p>
  		 <div className={'header-content__category'}>
  		 	<p className={'one-row'}>Categoría</p>
  		 	<p className={'one-row'}>Dificultad</p>
  		 	<p className={'second-row'}>{selectedPart}</p>
		 		{ levels && (
		 			<div className={'levels-container'}>
		 				{levels.map((level, index) => (
		 					<p 
		 						key={index}
		 						className={'level'}
		 					>
		 						{level}
		 					</p>
		 				))}
		 			</div>
		 		)}
  		 </div>
  		</header>
  		<section className={'section-content'}>
    		<div className={'instructions'}>
    			<p className={'title'}>Instrucciones</p>
    			<ol>
    			  {`${instructions}`
    			    .split('- ')
    			    .filter((el) => !!el)
    			    .map((item, index) => (
    			      <li className={'value'} key={index}>
    			        {item}
    			      </li>
    			    ))}
    			</ol>	
    		</div>
  		  {tips && (
  		    <div className={'advice'}>
  		      <p className={'title'}>Consejos</p>
  		      <ol>
  		        {`${tips}`
  		          .split('- ')
  		          .filter((el) => !!el)
  		          .map((item, index) => (
  		            <li className={'value'} key={index}>
  		              {item}
  		            </li>
  		          ))}
  		      </ol>
  		    </div>
  		  )}
  		</section>
  	</section>
  );
}  

export default SelectedExercise;
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useEvieProgramContext } from 'components/eviePrograms/EvieProgramProvider';
import { EVIE_PROGRAM_STEPS, ACTIONS } from 'components/eviePrograms/service';

import EvieLevel from '../EvieLevel/EvieLevel';
import EvieLevelInAdditionalContent from '../EvieLevelInAdditionalContent';

const EvieLevelsList = () => {
  const {
    dispatch,
    state: { step, levels },
  } = useEvieProgramContext();
  const isInLevels = step === EVIE_PROGRAM_STEPS.LEVELS;

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }
    if (type === 'list') {
      const currentDraggable = levels.find((item) => item.id === draggableId);
      const newOrderedLevels = [...levels];
      newOrderedLevels.splice(source.index, 1);
      newOrderedLevels.splice(destination.index, 0, currentDraggable);
      dispatch({
        type: ACTIONS.DRAG_AND_DROP_LEVEL,
        payload: { newLevels: newOrderedLevels },
      });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="level-id" type="list">
        {(provided) => (
          <ol className="evie-program__levels-list" ref={provided.innerRef} {...provided.droppableProps}>
            {levels.map((level, index) => {
              if (isInLevels) {
                return <EvieLevel key={level.id} index={index} level={level} />;
              }
              return <EvieLevelInAdditionalContent key={level.id} index={index} level={level} />;
            })}
            {provided.placeholder}
          </ol>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default EvieLevelsList;

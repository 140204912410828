import React from 'react';

import noSurveyId from 'assets/images/no-plans.svg';

import './no-url-found.scss';

const NoUrlFound = ({ message }) => (
  <section className="no-url-found">
    <div className="no-url-found__content">
      <div className="no-url-found__content__image">
        <img src={noSurveyId} alt="url incorrecto" />
      </div>
      <p>{message}</p>
    </div>
  </section>
);

export default NoUrlFound;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';

import { companyPaymentOnboardingForm } from 'utils/validationSchema';
import SolidButton from 'components/SolidButton';
import { MultiSelect, NumberInput } from 'components/FormComponents'

const CompanyPayment = (props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    partner = {},
    onContinue = () => {},
    toggleOpenModal = () => {},
    onBack = () => {},
    editProfile = false,
    currency = 'usd'
  } = { ...props };

  const initialValues = {
    paymentMode: t('settings.payment.ondemand'),
    cadence: t('settings.payment.bi_yearly'),
    allowanceAmount: currency?.toUpperCase() === 'COP' ? 400000 : 120
  };


  return (
    <Formik initialValues={initialValues} onSubmit={onContinue} validationSchema={companyPaymentOnboardingForm}>
      {({ setFieldValue, isSubmitting, isValid }) => (
        <Form className="signup__profile">
          <div className="right">
            <div className="patient__title" style={{ justifyContent: 'flex-start' }}>
              <span className="title">{t('settings.payment.title')}</span>
            </div>
            <Field
                type='text'
                label={t('settings.payment.type')}
                name={'paymentMode'}
                component={MultiSelect}
                options={[t('settings.payment.ondemand'),t('settings.payment.reimbursement')]}
                disabled={true}
              />
              <Field
                type='text'
                label={t('settings.payment.cadence')}
                name='cadence'
                component={MultiSelect}
                options={[t('settings.payment.yearly'),t('settings.payment.bi_yearly'),t('settings.payment.quarterly')]}
              />
            <Field type="text" label={`${t('settings.payment.allowance')} (${currency?.toUpperCase()})`} name="allowanceAmount" component={NumberInput} />
            <div className="button-bar">
              <SolidButton type="button" className="secondary" onClick={onBack}>
                {t('button.cancel')}
              </SolidButton>
              <SolidButton
                type="submit"
                className={!isValid || isSubmitting ? 'disabled' : ''}
                disabled={!isValid || isSubmitting}
              >
                {t('button.save')}
              </SolidButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyPayment;

import React from 'react';
import { isEmpty } from 'ramda';
import { GET_BOOKINGS } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';
import SolidButton from 'components/SolidButton';

const Item = ({ label, value, className = '' }) => (
  <div className={'settings__right__info'}>
    <p className={'label'}>{label}</p>
    <p className={'value'}>{value}</p>   
  </div>
);

const PlanInfo = ({ partnerInfo }) => {
  const { data: patientsData } = useAuthQuery(GET_BOOKINGS);
  const { clinicDashboard: clinicPatientsData = {} } = { ...patientsData };
  const { patientStatus = {} } = { ...clinicPatientsData };
  const { profile = {} } = { ...partnerInfo };
  const { hasCalls = false } = { ...profile };
  const { current_active = 0, current_available = 0 } = { ...patientStatus };

  return (
    <section className={'settings'}>
      <div className={'settings__left'}>
        <p className={'settings__left_profile'}>Plan</p>
        <p className={'settings__left_description'}>
          En está sección podrás editar la información tu plan, características activas y número de pacientes disponibles.
        </p>
      </div>
      <div className={'settings__right'}>
        <Item label={'Llamadas'} value={hasCalls ? 'Habilitado' : !isEmpty(profile) && 'Deshabilitado'} />
        <Item label={'Pacientes'} value={`${current_active}/${current_available}`} />
        <div className={'settings__right__edit'}>
          <SolidButton onClick={() => window.open('/billing')}>Editar</SolidButton>
        </div>
      </div>
    </section>
  );
}

export default PlanInfo;
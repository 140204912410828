import React from 'react';

const InviteCard = ({ card, index, tab, onClick }) => {

  const { image, title = '' } = { ...card }

  return (
    <div onClick={onClick} className={`invite-card ${index === tab ? 'active-card' : ''}`}>
      <div className={'invite-card__image'}>
        <img src={image} alt={title}/>
      </div>
      <span>{title}</span>
    </div>
  );
};

export default InviteCard;
import React from 'react';
import noPatients from 'assets/images/no-patients.svg';

import './empty-state.scss';

const NoPatientsFound = ({ isModal }) => (
  <div className={`no-patients-found ${isModal ? 'isModal' : ''}`}>
    <div className="content">
      <p className="title">No se han encontrado resultados</p>
      <p className="subtitle">
        Asegúrate de que el criterio esté escrito correctamente o intenta ajustar el filtro para encontrar lo que estas
        buscando.
      </p>
    </div>
    <img src={noPatients} alt="no results" className="image" />
  </div>
);

export default NoPatientsFound;

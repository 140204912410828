import React, { useState } from 'react';

import Button from 'components/SolidButton';

import { useToggle } from 'utils/hooks/useToggle';

const GroupTitle = (props) => {
  const { title = '', groupId = '', updateGroupTitle = () => {} } = { ...props };
  const [edit, setEdit] = useToggle();
  const [newTitle, setNewTitle] = useState(title);

  const handleOnChange = ({ target: { value } }) => {
    setNewTitle(value);
  };

  const handleEdit = () => {
    updateGroupTitle(newTitle, groupId);
    setEdit();
  };

  return (
    <div className="group-title">
      {edit ? (
        <div className="group-title-edit">
          <input className="form__input group-title-input" value={newTitle} onChange={handleOnChange} />
          <Button className={`slim ${!newTitle ? 'disabled' : ''}`} disabled={!newTitle} onClick={handleEdit}>
            Actualizar
          </Button>
        </div>
      ) : (
        <h2 aria-hidden="true" onClick={() => setEdit()}>
          {title}
        </h2>
      )}
    </div>
  );
};

export default GroupTitle;

import React, { useContext, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  GET_BUILDER_PROGRAM,
  EXERCISES,
  READINGS_WITH_TYPE_NAME,
  AUDIOS_WITH_TYPE_NAME,
  SHORT_VIDEOS_WITH_TYPE_NAME,
  GET_SQUEEZES,
} from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';
import { queryParams } from 'utils/string';

const EvieProgramServiceContext = createContext();

const EvieProgramServiceProvider = ({ children }) => {
  const {
    location: { search },
  } = useHistory();
  const { id: programId } = queryParams(search);

  const {
    loading = false,
    data: programData = {},
    refetch = () => {},
  } = programId
    ? useAuthQuery(GET_BUILDER_PROGRAM, {
        variables: { programId },
      })
    : {};
  const { program = {} } = { ...programData };
  const { data: dataExercises } = useAuthQuery(EXERCISES);
  const { exercises = [] } = { ...dataExercises };
  const { data } = useAuthQuery(READINGS_WITH_TYPE_NAME);
  const { getClinicReadings = [] } = { ...data };
  const filteredByBlockContent = getClinicReadings.filter(({ blockContent }) => blockContent);
  const { data: audioData } = useAuthQuery(AUDIOS_WITH_TYPE_NAME);
  const { audios = [] } = { ...audioData };
  const { data: shortVideosData } = useAuthQuery(SHORT_VIDEOS_WITH_TYPE_NAME);
  const { shortVideos = [] } = { ...shortVideosData };
  const { data: dataSqueezes } = useAuthQuery(GET_SQUEEZES);
  const { squeezes = [] } = { ...dataSqueezes };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <EvieProgramServiceContext.Provider
      value={{
        isFecthingProgram: loading,
        programId,
        program,
        exercises,
        readings: filteredByBlockContent,
        audios,
        shortVideos,
        squeezes,
      }}
    >
      {children}
    </EvieProgramServiceContext.Provider>
  );
};

EvieProgramServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useEvieProgramServiceContext() {
  const context = useContext(EvieProgramServiceContext);
  if (!context) {
    throw new Error('Expected an EvieProgramServiceContext somewhere in the react tree to set context value');
  }
  return context;
}

export { useEvieProgramServiceContext, EvieProgramServiceProvider };

import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { getIn } from 'formik';

import './form.scss';

const TextAreaDescription = ({
  field,
  form,
  label,
  labelClassName,
  className,
  containerClass,
  isFSblocked,
  isRequired,
  type,
  ...props
}) => {
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error;
  const invalidClass = hasError ? 'is-invalid' : '';
  const fsClass = isFSblocked ? 'fs-block' : '';

  return (
    <div className={`form__input-container ${containerClass}`}>
      <div className={'textarea'}>
        {label && (
          <label htmlFor={name} className={`form__label ${labelClassName}`}>
            {label} {isRequired && <span className="text-pink">*</span>}
          </label>
        )}
        <textarea
          className={`form__input position ${className} ${fsClass} ${invalidClass}`}
          id={name}
          type={type}
          {...field}
          {...props}
        />
        {hasError && <div className="form__error textarea__error">{error}</div>}
      </div>
    </div>
  );
};

TextAreaDescription.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isFSblocked: PropTypes.bool,
  isRequired: PropTypes.bool,
};

TextAreaDescription.defaultProps = {
  label: '',
  className: '',
  labelClassName: '',
  isFSblocked: false,
  isRequired: false,
  type: 'text',
};

export default TextAreaDescription;

import React, { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { isEmpty } from 'ramda';
import SolidButton from '../../SolidButton';
import LogoBlue from '../../../assets/images/logo-blue.svg';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../utils/styles';

const LogoDropzone = ({ partnerInfo, files, handleAcceptedFiles }) => {

  const { profile = {} } = { ...partnerInfo };
  const { avatar = {} } = { ...profile };
  const { original: avatarUrl = '' } = { ...avatar };

  const {
    getRootProps, 
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/*',
    onDrop: acceptedFiles => handleAcceptedFiles(acceptedFiles),
  });

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <section className={'edit-zone__picker__dropzone'}>
      <aside className={'drop-prev-card'}>
        <div className={'drop-prev-card__drop'}>
          <p className={'drop-prev-card__drop_title'}>SUBIR LOGO</p>
          <div {...getRootProps({style})}>
            <input {...getInputProps()} />
            <p style={{textAlign: 'center'}}>Arrastre y suelte un archivo aquí, o haga clic para seleccionar</p>
            <SolidButton 
              onClick={open}
              className={'min-width-100'}
            >
              Añadir logo
            </SolidButton>
          </div>  
        </div>
        <div className={'drop-prev-card__preview'}>
          <p className={'drop-prev-card__preview_title'}>DEMO DE PANTALLA</p>
          <aside className={'drop-prev-card__preview__thumbs'}>
            <div className={'thumb'}>
              {(files || []).map((file) => (
                <div key={file.name} className={'thumb__image'}>
                  <img src={file.preview} alt={'logo preview'}/>
                </div>
              ))}
              {isEmpty(files) && (
                <div className={'thumb__image'}>
                  <img src={avatarUrl || LogoBlue} alt={'logo preview'}/>
                </div>
              )}
              <div className={'thumb__shadow'}>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </aside> 
        </div>
      </aside>
    </section>
  );
}

export default LogoDropzone;
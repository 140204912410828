import React, { useEffect, useState, useLayoutEffect, useMemo } from 'react';
import { isEmpty } from 'ramda';
import { FaThList } from 'react-icons/fa';
import { CgMenuGridR } from 'react-icons/cg';
import Loader from 'react-loader-spinner';

import { EXERCISES } from 'apollo/queries';
import { useAuthQuery, useFormInput } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { useShowComponent } from 'utils/hooks/useShowComponent';
import { useGetCategories } from 'utils/hooks/useGetCategories';
import { useFilterSegments } from 'utils/hooks/useFilterSegments';
import { ROLES } from 'utils/constants';
import { LEFT_MENU } from 'utils/leftMenu';
import { getUserRole } from 'utils/getUserRole';

import Header from 'components/Header';
import CategoriesFilter from 'components/CategoriesFilter';
import LibraryCarousel from 'components/LibraryCarousel';
import LibraryList from 'components/LibraryList';
import ModalExercise from 'components/ModalExercise';
import SelectedExercise from 'components/SelectedExercise';
import SelectList from 'components/SelectList';
import CategoriesButton from 'components/CategoriesButton';
import SearchFilter from 'components/SearchFilter';
import { NoExercisesFound } from 'components/EmptyState';

import './library.scss';

const Library = (props) => {
  useRedirectByRole([ROLES.CLINIC, ROLES.SPECIALIST]);
  const { userRole } = getUserRole();

  const [selected, setSelected] = useState({});
  const [filterPart, setFilterPart] = useState([]);
  const [exercisesArray, setExercisesArray] = useState([]);
  const [modalKey, setModalKey] = useState(0);
  const [libraryView, setLibraryView] = useState(false);
  const { loading, data, refetch } = useAuthQuery(EXERCISES);
  const { exercises = [] } = { ...data };
  const { location = {} } = { ...props };
  const { key = '' } = { ...location };

  const categories = useGetCategories({ exercises });
  const { memoBodyParts = [] } = { ...categories };

  const filterStates = useFilterSegments();
  const { filterParts, filterEquipments, filterLevels } = { ...filterStates };
  const { componentIsVisible: categoriesIsOpen, toggleComponent: toggleCategories } = useShowComponent();

  const searchExercises = useFormInput('');
  const { value = '' } = { ...searchExercises };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  useEffect(() => {
    if (isEmpty(exercisesArray) && !isEmpty(exercises)) {
      setExercisesArray(exercises);
    }
    if (isEmpty(filterPart) && !isEmpty(exercises)) {
      setFilterPart([memoBodyParts[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercises]);

  useLayoutEffect(() => {
    if (!isEmpty(filterEquipments) || !isEmpty(value) || !isEmpty(filterLevels)) {
      setFilterPart([memoParts[0]]);
    }
    if (!isEmpty(filterParts)) {
      setFilterPart([filterParts[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercisesArray, filterEquipments, filterParts, filterLevels, value]);

  useEffect(() => {
    const lowerCaseValue = value.toLowerCase();
    let filterArray = [];
    if (isEmpty(value)) {
      filterArray = [...exercises];
    }

    filterArray = exercises.filter(({ title, bodyParts }) => {
      const bodyPartsString = bodyParts.join(' - ').toLowerCase();
      return `${title}`.toLowerCase().includes(lowerCaseValue) || bodyPartsString.includes(lowerCaseValue);
    });

    if (!isEmpty(filterParts)) {
      filterArray = filterArray.filter((ex) => {
        const { bodyParts: exbpts = [] } = { ...ex };
        return exbpts.map((e) => filterParts.indexOf(e) !== -1).reduce((a, b) => a || b, false);
      });
    }
    if (!isEmpty(filterEquipments)) {
      filterArray = filterArray.filter((ex) => {
        const { equipments: equip = [] } = { ...ex };
        return equip.map((e) => filterEquipments.indexOf(e) !== -1).reduce((a, b) => a || b, false);
      });
    }
    if (!isEmpty(filterLevels)) {
      filterArray = filterArray.filter((ex) => {
        const { levels: levs = [] } = { ...ex };
        return levs.map((e) => filterLevels.indexOf(e) !== -1).reduce((a, b) => a || b, false);
      });
    }
    setExercisesArray(filterArray);
    setSelected({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, filterParts, filterEquipments, filterLevels]);

  useEffect(() => {
    const onShowVideo = () => {
      const modalVideo = document.querySelectorAll('.info-title')[0];
      if (modalVideo) {
        modalVideo.scrollIntoView({ behavior: 'smooth' });
        modalVideo.focus();
      }
    };
    onShowVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const memoParts = useMemo(() => {
    let parts = (exercisesArray || []).reduce((a, b) => {
      const { bodyParts = [] } = { ...b };
      return [...a, ...bodyParts];
    }, []);
    return (parts = [...new Set(parts)].sort());
  }, [exercisesArray]);

  const { part: selectedPart = '', exercise: selectedExercise = {} } = { ...selected };
  const { id: selectedId = '' } = { ...selectedExercise };

  const onTag = (tag) => setFilterPart([tag]);

  const onSelect = (isSelected, { part, exercise }) => {
    setModalKey(modalKey + 1);
    setSelected(isSelected ? {} : { part, exercise });
  };

  const switchView = () => {
    setSelected({});
    setLibraryView(!libraryView);
  };

  const handleSelect = () => setSelected({});

  return (
    <>
      <div className="d-flex hvh-100">
        {LEFT_MENU[userRole]}
        <div className="dashboard__main flex-fill">
          <Header title="Biblioteca" />
          <div className="library__filters">
            <SearchFilter searchItems={searchExercises} placeholder="Buscar un ejercicio" widthModifier="library" />
            <div className={`menu-grid-icon ${!libraryView && 'menu-list-icon'}`} onClick={switchView}>
              {!libraryView ? <FaThList /> : <CgMenuGridR />}
            </div>
            <CategoriesButton showCategories={categoriesIsOpen} toggleCategories={toggleCategories} />
          </div>
          <CategoriesFilter categories={categories} filterStates={filterStates} showCategories={categoriesIsOpen} />
          {!loading && isEmpty(exercisesArray) && <NoExercisesFound />}
          {!isEmpty(exercisesArray) && !libraryView && (
            <div className="library__content">
              {memoParts
                .filter((pts) => isEmpty(filterParts) || filterParts.indexOf(pts) !== -1)
                .map((part, index) => {
                  const partExercises = (exercisesArray || []).filter(
                    ({ bodyParts }) => (bodyParts || []).indexOf(part) !== -1,
                  );
                  return (
                    <div key={index} className="library__row">
                      <LibraryCarousel
                        part={part}
                        selectedPart={selectedPart}
                        selectedId={selectedId}
                        partExercises={partExercises}
                        onSelect={onSelect}
                      />
                      {selectedPart === part && !!selectedId && (
                        <ModalExercise selectedExercise={selectedExercise} key={modalKey} handleSelect={handleSelect} />
                      )}
                    </div>
                  );
                })}
            </div>
          )}
          {!isEmpty(exercisesArray) && libraryView && (
            <div className="library__list">
              {isEmpty(selected) && (
                <div className="library__list__tabs">
                  {memoParts
                    .filter((pts) => isEmpty(filterParts) || filterParts.indexOf(pts) !== -1)
                    .map((el, index) => (
                      <p
                        key={`${el}${index}`}
                        className={`tab ${[...filterPart].indexOf(el) !== -1 && 'selected-tab'}`}
                        onClick={() => onTag(el)}
                      >
                        {el}
                      </p>
                    ))}
                </div>
              )}
              {isEmpty(selected) && (
                <div className="library__list__exercises">
                  {memoParts
                    .filter((pts) => isEmpty(filterPart) || filterPart.indexOf(pts) !== -1)
                    .map((part, index) => {
                      const partExercises = (exercisesArray || []).filter(
                        ({ bodyParts }) => (bodyParts || []).indexOf(part) !== -1,
                      );
                      return (
                        <LibraryList
                          key={index}
                          part={part}
                          selectedPart={selectedPart}
                          selectedId={selectedId}
                          partExercises={partExercises}
                          onSelect={onSelect}
                        />
                      );
                    })}
                </div>
              )}
              {!!selectedId && (
                <div className="selected-exercise-container">
                  <SelectedExercise key={modalKey} selectedExercise={selectedExercise} selectedPart={selectedPart} />
                  <SelectList
                    exercisesArray={exercisesArray}
                    memoParts={memoParts}
                    filterParts={filterParts}
                    filterPart={filterPart}
                    onTag={onTag}
                    onSelect={onSelect}
                    handleSelect={handleSelect}
                    selectedPart={selectedPart}
                    selectedId={selectedId}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={loading} />
      </div>
    </>
  );
};

export default Library;

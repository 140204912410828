import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import EmployeeInviteTabs from './EmployeeInviteTabs';
import CopyLink from './CopyLink';

import './employee-invite-modal.scss';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: 540,
    padding: '50px 25px',
    overflow: 'hidden',
  },
};

const EmployeeInviteModal = ({ modalIsOpen, toggleModal }) => {
  const { t } = useTranslation();
  const tabs = [t('tabs.shareLink'), t('tabs.specialInvitation')];

  const [inviteTab, setInviteTab] = useState(tabs[0]);

  const handleChangeTab = (tab) => setInviteTab(tab);

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={toggleModal} modalProps={modalProps} closeable={false}>
      <h3 className="add-employee-title">{t('employees.invite.title')}</h3>
      <EmployeeInviteTabs tabs={tabs} inviteTab={inviteTab} handleChangeTab={handleChangeTab} />
      {inviteTab === tabs[0] ? <CopyLink /> : null}
    </Modal>
  );
};

EmployeeInviteModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default EmployeeInviteModal;

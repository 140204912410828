import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import PaymentStep from 'components/Billing/PaymentStep';

import success from 'assets/images/payment-success.svg';
import './payment-success.scss';

const PaymentSummary = (props) => {
  const { onNext = () => {} } = { ...props };
  const { t } = useTranslation();

  return (
    <div className="billing__main flex-fill">
      <PaymentStep step={3} />
      <div className="billing__content">
        <div className="billing__content__layout">
          <div className="billing__content__success">
            <div className="billing__content__success__container">
              <h2 className="success-title">{t('billing.success.title')}</h2>
              <p className="success-description">
                {t('billing.success.message')} <b>info@healthphy.de</b>
              </p>
              <img src={success} className="cards-img" alt="success" style={{ maxWidth: '50%' }} />
            </div>
            <Button type="button" category="rounded" className="button__confirm" onClick={onNext}>
              {t('billing.success.button')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { propOr } from 'ramda';
import Loader from 'react-loader-spinner';

import { getUserRole } from 'utils/getUserRole';
import { useAuthQuery } from 'utils/hooks';
import { FETCH_BY_ROLE, GET_PARTNER_BY_ROLE } from 'utils/constants';
import { LEFT_MENU } from 'utils/leftMenu';

import Header from 'components/Header';
import ProfileInfo from 'components/Settings/ProfileInfo';
import ChangeLanguage from 'components/Settings/ChangeLanguage';
import PlanInfo from 'components/Settings/PlanInfo';
import WhiteLabel from 'components/Settings/WhiteLabel';
import TeamMembers from 'components/Settings/TeamMembers';
import PaymentConfig from 'components/Settings/PaymentConfig';

import './settings.scss';

const Settings = () => {
  const { t } = useTranslation();
  const { userRole, isClinic, isCompany } = getUserRole();
  const { loading, data, refetch } = useAuthQuery(FETCH_BY_ROLE[userRole]);
  const partner = propOr({}, GET_PARTNER_BY_ROLE[userRole])(data);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="d-flex hvh-100">
      {LEFT_MENU[userRole]}
      <div className="dashboard__main flex-fill">
        <Header shadow title={t('leftMenuHeader.settings')} />
        <ProfileInfo partnerInfo={partner} />
        {isCompany && <PaymentConfig partnerInfo={partner} refetch={refetch} setProcessing={setProcessing} />}
        <a name="payment" />
        {(isCompany || isClinic) && <ChangeLanguage />}
        {!isCompany && <TeamMembers partnerInfo={partner} refetch={refetch} loading={loading} />}
        {isClinic && (
          <>
            <WhiteLabel partnerInfo={partner} refetch={refetch} />
            <PlanInfo partnerInfo={partner} />
          </>
        )}
        <br />
        <br />
      </div>
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || processing}
      />
    </div>
  );
};

export default Settings;

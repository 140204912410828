import React from 'react';
import { BsDownload } from 'react-icons/bs';

import { capitalize } from 'utils/string';
import { useDownloadPdf } from 'utils/hooks';
import { PERSONAL_INFORMATION } from 'utils/steps';
import { useAppState } from 'components/StoreProvider';

import { NoPatientData } from 'components/EmptyState';
import Icon from 'components/Icon';

const mapValue = (value, type) => {
  switch (type) {
    case 'multiple-selection':
      return value.join ? value.join(', ') : value;
    case 'verification':
      return value ? 'Verdadero' : 'Falso';
    default:
      return value || '---';
  }
};

const buildCustomData = ({
  objective,
  exerciseLevel,
  functionality,
  duration,
  medicalHistory,
  painLevel,
  painLocalization,
}) => [
  { value: objective, label: 'Objetivos del tratamiento' },
  { value: exerciseLevel, label: 'Nivel de los ejercicios' },
  { value: functionality, label: 'Actividades que no puede realizar' },
  { value: duration, label: 'Actividades que no puede realizar' },
  { value: medicalHistory, label: 'Historial médico' },
  { value: painLevel, label: 'Nivel del dolor' },
  { value: painLocalization, label: 'Localización del dolor del paciente' },
];

const Assessment = ({ patient, assessment, changeRoute }) => {
  const { id = '', name = '', email = '' } = { ...patient };
  const { content: customData = [], answerFiles = [], diagnostic, observations } = { ...assessment };
  const content = customData.length > 0 || answerFiles.length > 0 ? customData : buildCustomData(assessment);

  const { setStoreData } = useAppState();

  const location = {
    pathname: '/editar-paciente',
    state: {
      id,
      email,
    },
  };

  const onCreatePatient = () => {
    setStoreData('initialState');
    setStoreData('step', PERSONAL_INFORMATION);
    changeRoute(location);
  };

  const { componentRef, handlePrint } = useDownloadPdf(name || email);

  return (
    <div className="w-100">
      <div className="patient__title">
        <div>
          <Icon name="assessment" className="icon" />
          <p className="title">Valoración</p>
        </div>
        <div className="patient__title__download-button">
          <BsDownload onClick={handlePrint} />
        </div>
      </div>
      {!name ? (
        <NoPatientData tab="assessment" onCreateData={onCreatePatient} />
      ) : (
        <div className="patient__tab_content assessment" ref={componentRef}>
          <div className="assessment-grid">
            <div className="item">
              <p className="item-label">Diagnóstico CIE 10</p>
              <p className="item-value">{diagnostic ? capitalize(diagnostic) : '---'}</p>
            </div>
            {content.map((field, index) => {
              const { label = '', value = '', type = 'text' } = { ...field };
              return (
                <div className="item" key={index}>
                  {!['block'].includes(type) && <p className="item-label">{label}</p>}
                  <p className="item-value">{mapValue(value, type)}</p>
                </div>
              );
            })}
            {answerFiles.map((answer) => {
              const { id: fileId = '', label = '', file = {} } = { ...answer };
              const { original: fileUrl = '' } = { ...file };
              return (
                <div key={fileId} className="item">
                  <p className="item-label">{label}</p>
                  <a className="item-value--link" href={fileUrl} target="_blank" rel="noopener noreferrer">
                    Link archivo
                  </a>
                </div>
              );
            })}
            {!!observations && (
              <div className="item">
                <p className="item-label">Observaciones</p>
                <p className="item-value bigtext">{observations}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Assessment;

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaRegImage } from 'react-icons/fa';
import { AiOutlinePlusSquare } from 'react-icons/ai';

import { formatBackgroundImage } from 'components/readings/service/utils';

import SolidButton from 'components/SolidButton';

import './image-input.scss';

const ImageInput = ({ setFieldValue, formatedImage, name, label }) => {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState('');
  const fileInput = useRef();

  const handleFileInputClick = () => {
    if (fileInput) {
      fileInput.current.click();
    }
  };

  return (
    <div className="image-input">
      <div className="image-input__bg" style={{ backgroundImage: `url(${imageUrl || formatedImage})` }}>
        {!imageUrl && !formatedImage && <FaRegImage className="image-input__bg_icon" />}
      </div>
      <input
        type="file"
        ref={fileInput}
        style={{ display: 'none' }}
        onChange={(event) => {
          const upload = event.currentTarget.files[0];
          setFieldValue(name, upload);
          if (upload) {
            const imgUrl = formatBackgroundImage(upload);
            setImageUrl(imgUrl);
          }
        }}
      />
      <div className="image-input__add">
        <span className="image-input__add_label">{label}</span>
        <SolidButton className="evie-add" onClick={handleFileInputClick}>
          {t('button.add')}
          <AiOutlinePlusSquare />
        </SolidButton>
      </div>
    </div>
  );
};

ImageInput.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  formatedImage: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ImageInput;

import { ROLES } from 'utils/constants';

export const getUserType = (role) => {
  const patientType = 'patient';
  const employeeType = 'employee';
  switch (role) {
    case ROLES.CLINIC:
      return patientType;
    case ROLES.SUPER_CLINIC:
      return employeeType;
    default:
      return patientType;
  }
};

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import { GET_READING_TEMPLATES } from 'apollo/queries';
import { useAuthQuery, useFormInput } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROUTES, ROLES } from 'utils/constants';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SearchFilter from 'components/SearchFilter';
import SolidButton from 'components/SolidButton';
import TemplatesList from 'components/templates/TemplatesList/TemplatesList';
import NoProductsFound from 'components/Forms/NoFormsFound';
import { NoMembers } from 'components/EmptyState';

import noTemplates from 'assets/images/no-data.svg';

const Templates = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const { push } = useHistory();

  const { loading, data, refetch } = useAuthQuery(GET_READING_TEMPLATES);
  const { readingTemplates: allReadingTemplates = [] } = { ...data };

  const [readingTemplates, setReadingTemplates] = useState([]);
  const searchReadingTemplates = useFormInput('');
  const { value = '' } = { ...searchReadingTemplates };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    let temporal = allReadingTemplates;
    if (value) {
      temporal = allReadingTemplates.filter((readingTemplate) => {
        const { title } = { ...readingTemplate };
        return `${title}`.toLowerCase().includes(value.toLowerCase());
      });
    }
    setReadingTemplates(temporal);
  }, [allReadingTemplates, value]);

  const handleChangeRoute = () => {
    push(ROUTES.CREATE_TEMPLATE);
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenu />
        <div className="dashboard__main">
          <Header title={t('leftMenuHeader.templates')} shadow />
          <div className="templates__content dashboard__content--patients">
            {!loading && isEmpty(allReadingTemplates) && (
              <NoMembers
                title="¡Aún no tienes plantillas de lecturas configuradas!"
                subtitle="Inicia configurando una plantilla para la valoración de tus pacientes."
                button="Crear Palantilla"
                handleClick={handleChangeRoute}
                image={noTemplates}
              />
            )}
            {!loading && !isEmpty(allReadingTemplates) && (
              <div className="forms">
                <div className="forms__search">
                  <SearchFilter searchItems={searchReadingTemplates} placeholder="Buscar una Plantilla" />
                  <SolidButton onClick={handleChangeRoute} className="slim">
                    Crear Plantilla
                  </SolidButton>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '20%' }}>Imagen</th>
                      <th style={{ width: '30%' }}>Título</th>
                      <th style={{ width: '30%' }}>Descripción</th>
                      <th style={{ width: '10%' }}>Cantidad</th>
                      <th style={{ width: '10%' }}> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <TemplatesList templates={readingTemplates} />
                    {isEmpty(readingTemplates) && <NoProductsFound />}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </section>
      <Loader className="spinner" type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
    </>
  );
};

export default Templates;

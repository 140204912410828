import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { isEmpty, pathOr } from 'ramda';
import Loader from 'react-loader-spinner';
import { getTimeZones } from '@vvo/tzdb';

import { CREATE_BOOKING_REQUEST } from 'apollo/mutations';
import { bookingRequest } from 'utils/validationSchema';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';

import { DateInput, MultiSelect, TextAreaInput } from 'components/FormComponents';
import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';

import './create-booking-request.scss';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: 414,
  },
};

const initialValues = {
  date: '',
  hour: '',
  timeZone: '',
  message: '',
};

function generateHours() {
  const hoursArray = [];
  const startHour = 0;
  const endHour = 23;

  for (let hour = startHour; hour <= endHour; hour += 1) {
    for (let minute = 0; minute < 60; minute += 15) {
      const hourValue = `0${hour}`.slice(-2);
      const formattedHour = `0${hour % 12 === 0 ? 12 : hour % 12}`.slice(-2);
      const period = hour < 12 ? 'AM' : 'PM';
      const formattedMinute = `0${minute}`.slice(-2);
      const value = `${hourValue}:${formattedMinute}`;
      const time = `${formattedHour}:${formattedMinute} ${period}`;
      hoursArray.push({ value, label: time });
    }
  }

  return hoursArray;
}
const hours = generateHours();

const CreateBookingRequest = ({ open, toggleModal, patientId, refetch }) => {
  const { t } = useTranslation();
  const timeZones = getTimeZones();
  const formatTimeZones = timeZones.map((timeZone) => ({
    label: timeZone.currentTimeFormat,
    value: timeZone.currentTimeFormat,
  }));

  const [createBookingRequest] = useMutation(CREATE_BOOKING_REQUEST);

  const [spinner, setSpinner] = useToggle();

  const onCreateBookingRequest = async (values) => {
    const { date, hour, timeZone, message } = values;
    const dateTime = `${date} ${hour} ${timeZone.slice(0, 6)}`;
    setSpinner();

    const response = await createBookingRequest({
      variables: {
        patientId,
        startAt: dateTime,
        message,
      },
    });

    const errors = pathOr([], ['data', 'createBookingRequest', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [t('notification.success.booking')] : errors,
    });

    if (emptyErrors) {
      toggleModal();
      refetch();
    }
  };

  return (
    <>
      <Modal modalProps={modalProps} modalIsOpen={open} closeModal={toggleModal} closeable={false}>
        <h3 className="booking-title">{t('patient.personalInfo.booking.title')}</h3>
        <p className="booking-description">{t('patient.personalInfo.booking.description')}</p>
        <Formik
          initialValues={initialValues}
          onSubmit={onCreateBookingRequest}
          validationSchema={bookingRequest}
          enableReinitialize
        >
          {({ isSubmitting, isValid }) => {
            const isDisabledButton = [!isValid, isSubmitting].includes(true);
            return (
              <Form className="booking-form">
                <Field label={t('formLabel.date')} name="date" validation="none" component={DateInput} />
                <Field type="text" label={t('formLabel.hour')} name="hour" component={MultiSelect} options={hours} />
                <Field
                  type="text"
                  label={t('formLabel.timeZone')}
                  name="timeZone"
                  component={MultiSelect}
                  options={formatTimeZones}
                />
                <Field
                  type="text"
                  label={t('formLabel.message')}
                  name="message"
                  minRows={4}
                  component={TextAreaInput}
                />
                <div className="booking-form__btns">
                  <SolidButton className="secondary" onClick={toggleModal}>
                    {t('button.cancel')}
                  </SolidButton>
                  <SolidButton type="submit" className="primary" disabled={isDisabledButton}>
                    {t('button.create')}
                  </SolidButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={spinner} />
    </>
  );
};

CreateBookingRequest.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default CreateBookingRequest;

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'ramda';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useEvieProgramServiceContext } from 'components/eviePrograms/EvieProgramServiceProvider';
import { useEvieProgramContext } from 'components/eviePrograms/EvieProgramProvider';
import { ACTIONS } from 'components/eviePrograms/service';

import SolidButton from 'components/SolidButton';
import EvieProgramSession from '../EvieProgramSession/EvieProgramSession';
import ItemList from '../ItemList/ItemList';
import SelectedItemList from '../SelectedItemList';

export const handleGetItem = (arrayOfItems, searchId) => arrayOfItems.filter((item) => item.id === searchId);

const EvieSelectedLevel = () => {
  const { t } = useTranslation();
  const { exercises, squeezes } = useEvieProgramServiceContext();
  const { dispatch, state } = useEvieProgramContext();
  const { selectedLevel = {}, selectedExerciseSession = {} } = state;
  const { number, programSessions = [] } = selectedLevel;
  const { level, session, sessionId, exerciseSessionId, squeeze } = selectedExerciseSession;
  const [currentSession = {}] = handleGetItem(programSessions, sessionId);
  const { sessionExercises = [], sessionSqueezes = [] } = currentSession;
  const [currentExSession = {}] = handleGetItem(sessionExercises, exerciseSessionId);
  const { exercises: selectedExercises = [] } = currentExSession;
  const [currentSqSession = {}] = handleGetItem(sessionSqueezes, exerciseSessionId);
  const { squeezes: selectedSqueezes = [] } = currentSqSession;

  const items = squeeze ? squeezes : exercises;
  const selectedContent = squeeze ? selectedSqueezes : selectedExercises;
  const sessionItemsPropery = squeeze ? 'sessionSqueezes' : 'sessionExercises';
  const itemsProperty = squeeze ? 'squeezes' : 'exercises';

  const handleUnSelectLevel = () => {
    dispatch({ type: ACTIONS.SELECT_LEVEL, payload: {} });
  };

  const handleAddProgramSession = () => {
    dispatch({
      type: ACTIONS.ADD_SESSION,
      payload: { id: uuid(), isNew: true, sessionExercises: [], sessionSqueezes: [] },
    });
  };

  const handleAddProgramSessions = () => {
    dispatch({ type: ACTIONS.ADD_PROGRAM_SESSIONS, payload: selectedLevel });
    handleUnSelectLevel();
  };

  const handleAddItem = (selectedItem) => {
    dispatch({
      type: ACTIONS.ADD_ITEM,
      payload: {
        selectedItem: { ...selectedItem, temporalId: uuid() },
        sessionItems: sessionItemsPropery,
        items: itemsProperty,
      },
    });
  };

  const handleRemoveItem = (exId) => {
    dispatch({
      type: ACTIONS.REMOVE_ITEM,
      payload: {
        id: exId,
        sessionItems: sessionItemsPropery,
        items: itemsProperty,
      },
    });
  };

  const handleUnSelectExerciseSession = () => {
    dispatch({
      type: ACTIONS.SELECT_EXERCISE_SESSION,
      payload: {},
    });
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }
    if (type === 'list') {
      const currentDraggable = selectedContent.find((bBlock) => bBlock.temporalId === draggableId);
      const newOrderedItems = [...selectedContent];
      newOrderedItems.splice(source.index, 1);
      newOrderedItems.splice(destination.index, 0, currentDraggable);
      dispatch({
        type: ACTIONS.DRAG_AND_DROP_ITEM,
        payload: {
          newOrderedItems,
          sessionItems: sessionItemsPropery,
          items: itemsProperty,
        },
      });
    }
  };

  const onDragEndSession = (sessionResult) => {
    const { destination, source, draggableId, type } = sessionResult;
    if (!destination) {
      return;
    }
    if (type === 'list') {
      const currentDraggable = programSessions.find((pSession) => pSession.id === draggableId);
      const newOrderedSessions = [...programSessions];
      newOrderedSessions.splice(source.index, 1);
      newOrderedSessions.splice(destination.index, 0, currentDraggable);
      dispatch({ type: ACTIONS.DRAG_AND_DROP_SESSION, payload: { newSessions: newOrderedSessions } });
    }
  };

  return (
    <>
      {isEmpty(selectedExerciseSession) ? (
        <>
          <header className="evie-program__level-header">
            <div className="evie-program__level-header_description">
              <h3 className="evie-program__levels-list_label-level mb-0">
                {t('eviePrograms.levels.labelLevel')} {number}
              </h3>
              <p>{t('eviePrograms.level.title')}</p>
            </div>
            <SolidButton className="evie-add" onClick={handleAddProgramSession}>
              {t('eviePrograms.levels.addSessionBtn')}
              <AiOutlinePlusSquare />
            </SolidButton>
          </header>
          <DragDropContext onDragEnd={onDragEndSession}>
            <Droppable droppableId="session-id" type="list">
              {(provided) => (
                <ol className="evie-program__levels-list" ref={provided.innerRef} {...provided.droppableProps}>
                  {programSessions.map((programSession, index) => (
                    <EvieProgramSession key={programSession.id} index={index} programSession={programSession} />
                  ))}
                  {provided.placeholder}
                </ol>
              )}
            </Droppable>
          </DragDropContext>
          <section className="evie-program__btns">
            <SolidButton className="evie-secondary mr-4" onClick={handleUnSelectLevel}>
              {t('button.back')}
            </SolidButton>
            <SolidButton className="evie-primary" onClick={handleAddProgramSessions}>
              {t('button.save')}
            </SolidButton>
          </section>
        </>
      ) : (
        <div>
          <header className="evie-program__levels-list_level">
            <h3 className="evie-program__levels-list_label-level label-session">
              <Trans values={{ levelPosition: level, sessionPosition: session }}>
                eviePrograms.level.exerciseSession
              </Trans>
            </h3>
          </header>
          <h3 className="evie-program__levels-list_label-add">
            {squeeze ? t('eviePrograms.session.addSqueeze') : t('eviePrograms.session.addExercise')}
          </h3>
          <section className="evie-program__add-content">
            <ItemList
              items={items}
              addContent={handleAddItem}
              selectedContent={selectedContent}
              isExercises={!squeeze}
            />
            <SelectedItemList selectedContent={selectedContent} removeItem={handleRemoveItem} dragEnd={onDragEnd} />
          </section>
          <section className="evie-program__btns">
            <SolidButton className="evie-primary" onClick={handleUnSelectExerciseSession}>
              {t('button.continue')}
            </SolidButton>
          </section>
        </div>
      )}
    </>
  );
};

export default EvieSelectedLevel;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import SolidButton from 'components/SolidButton';
import { useCreateTemplateContext } from 'components/createTemplate/CreateTemplateProvider';

import noTemplates from 'assets/images/login-employers.png';

import 'react-lazy-load-image-component/src/effects/blur.css';

const ReadingCard = ({ reading, isOwn }) => {
  const { t } = useTranslation();
  const { id, title, backgroundImage } = { ...reading };
  const { thumb } = { ...backgroundImage };

  const {
    handleAddReading,
    handleRemoveReading,
    template: { readings = [] },
  } = useCreateTemplateContext();

  const handleAddRemoveReading = () => {
    if (isOwn) {
      handleRemoveReading(id);
    } else {
      handleAddReading(reading);
    }
  };

  const isSelected = !![...readings].find(({ id: currentId }) => currentId === id) && !isOwn;

  return (
    <div className={isSelected ? 'mask-reading-card' : ''}>
      <section className={`card-container__card ${isSelected ? 'selected' : ''}`}>
        <LazyLoadImage
          src={thumb || noTemplates}
          alt="reading card"
          className="card-container__card--image"
          placeholderSrc={noTemplates}
          effect="blur"
        />
        <div className="card-container__card__content">
          <h3>{title}</h3>
        </div>
        <SolidButton className={`slim ${isOwn ? 'remove' : 'add'}`} onClick={handleAddRemoveReading}>
          {isOwn ? t('button.remove') : t('button.add')}
        </SolidButton>
      </section>
      {isSelected && <AiOutlineCheckCircle />}
    </div>
  );
};

ReadingCard.propTypes = {
  isOwn: PropTypes.bool.isRequired,
  reading: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    backgroundImage: PropTypes.shape({
      original: PropTypes.string,
    }),
  }).isRequired,
};

export default ReadingCard;

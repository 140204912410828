import React, { useState, useRef } from 'react';
import { Formik, Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { createActivePause } from 'utils/validationSchema';
import { useCreateActivePauseContext } from 'components/createActivePause/CreateActivePauseProvider';

import { TextInput, TextAreaInput, ColorInput, MultiSelect } from 'components/FormComponents';
import SolidButton from 'components/SolidButton';
import VideoList from '../VideoList';

const DetailForm = () => {
  const { t } = useTranslation();
  const { activePauseState, bodyParts, exerciseIds, activePauseId, createUpdActivePause } =
    useCreateActivePauseContext();
  const { title, description, tagColor, backgroundImage, bodyParts: parts } = activePauseState;
  const initialValues = { title, description, tagColor, backgroundImage, bodyParts: parts };
  const isEmptyExerciseIds = exerciseIds.length === 0;

  const [imageUrl, setImageUrl] = useState('');
  const fileInput = useRef();
  const reader = new FileReader();

  reader.onloadend = () => {
    setImageUrl(reader.result);
  };

  const handleFileInputClick = () => {
    if (fileInput) {
      fileInput.current.click();
    }
  };

  return (
    <div className="active-pause__details">
      <Formik
        initialValues={initialValues}
        onSubmit={createUpdActivePause}
        validationSchema={createActivePause}
        enableReinitialize
      >
        {({ setFieldValue, isSubmitting, isValid }) => {
          const isDisabledButton = [!isValid, isSubmitting, isEmptyExerciseIds].includes(true);
          return (
            <Form>
              <section className="form-header">
                <h3 className="form-header_title">
                  {activePauseId ? t('leftMenuHeader.editActivePause') : t('leftMenuHeader.createActivePause')}
                </h3>
                <SolidButton
                  disabled={isDisabledButton}
                  className={`slim min-width-100 ${isDisabledButton ? 'disabled' : ''}`}
                  type="submit"
                >
                  {activePauseId ? t('button.update') : t('button.create')}
                </SolidButton>
              </section>
              <section className="form-content">
                <div className="form-content__file">
                  <div
                    className="form-content__file_img"
                    style={{ backgroundImage: `url(${imageUrl || backgroundImage})` }}
                  />
                  <input
                    type="file"
                    className="file-upload"
                    ref={fileInput}
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      const upload = event.currentTarget.files[0];
                      setFieldValue('backgroundImage', upload);
                      if (upload) {
                        reader.readAsDataURL(upload);
                      }
                    }}
                  />
                  <button type="button" className="form-content__file_btn" onClick={handleFileInputClick}>
                    {t('button.addImage')}
                  </button>
                </div>
                <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
                <div className="form-content__description">
                  <Field
                    type="text"
                    label={t('formLabel.description')}
                    name="description"
                    minRows={4}
                    component={TextAreaInput}
                  />
                </div>
                <div className="form-content__selectors">
                  <Field type="color" label={t('formLabel.color')} name="tagColor" component={ColorInput} />
                  <div className="form-content__selectors_parts">
                    <Field
                      type="text"
                      label={t('formLabel.bodyParts')}
                      name="bodyParts"
                      component={MultiSelect}
                      options={bodyParts}
                      isMulti
                    />
                  </div>
                </div>
              </section>
            </Form>
          );
        }}
      </Formik>
      <VideoList />
    </div>
  );
};

export default DetailForm;

import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { formatDate } from 'react-day-picker/moment';
import { getIn } from 'formik';

import DatePicker from '../DatePicker';

import 'react-day-picker/lib/style.css';

import './day-picker-input.scss';



const DateInput = (props) => {
  const { field, form, label, isRequired, labelClassName, className } = props;
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  return (
    <div className="form__input-container">
      {label && (
        <label htmlFor={name} className={`form__label ${labelClassName}`}>
          {label}
          {' '}
          {isRequired && <span className="text-pink">*</span>}
        </label>
      )}
      <DatePicker
        data={{
          ...props,
          onChange: (value) => {
            let val = value !== undefined ? formatDate(value, 'YYYY-MM-DD') : '';
            val = val === 'Invalid date' ? '' : val;
            form.setFieldValue(name, val);
          },
          touched: touch,
          valid: error,
          value: field.value,
          className,
        }}
      />
      {touch && error && <div className="form__error">{error}</div>}
    </div>
  );
};

DateInput.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
};

DateInput.defaultProps = {
  label: '',
  className: '',
  labelClassName: '',
  isRequired: false,
};

export default DateInput;

import { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import { v4 as uuid } from 'uuid';

import { inputTypes } from 'components/CreateForm/constants';

const normalizeData = (values) => {
  const { content: queryGroups = [], title: queryTitle, typeForm: queryTypeForm, surveyType, id } = { ...values };
  return {
    title: queryTitle,
    typeForm: queryTypeForm,
    surveyType,
    id,
    ...(queryGroups.some((group) => !group.groupTitle) && {
      groups: [
        {
          id: uuid(),
          groupTitle: 'single',
          questions: queryGroups.map((group) => {
            const { type: typeId } = { ...group };
            const type = inputTypes.find(({ type: inputTypeId }) => typeId === inputTypeId);
            return {
              ...group,
              type,
            };
          }),
        },
      ],
    }),
    ...(queryGroups.some((group) => group.groupTitle) && {
      groups: queryGroups.map((group) => ({
        ...group,
        questions: group.questions.map((question) => {
          const { type: typeId } = { ...question };
          const type = inputTypes.find(({ type: inputTypeId }) => typeId === inputTypeId);
          return {
            ...question,
            type,
          };
        }),
      })),
    }),
  };
};

export const useFormState = ({
  formData = {},
  setModal = () => {},
  selected = {},
  selectedGroup = '',
  groupTitle = '',
  setSelected = () => {},
  setSelectedGroup = () => {},
  setGroupTitle = () => {},
}) => {
  const [form, setForm] = useState({});

  useEffect(() => {
    if (!isEmpty(formData) && isEmpty(form)) {
      setForm(normalizeData(formData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleNameTypeChange = ({ title: formName, typeForm: typeFormName, surveyType }) => {
    setForm((prevForm) => ({
      ...prevForm,
      title: formName,
      typeForm: typeFormName,
      ...(typeFormName === 'survey' && {
        surveyType: surveyType || 'simple',
      }),
      ...(surveyType !== 'grouped' &&
        isEmpty(form) && {
          groups: [{ groupTitle: 'single', id: uuid(), questions: [] }],
        }),
    }));
    setModal('');
  };

  const handleSaveField = (field) => {
    if (selected.groupId) {
      setForm((prevForm) => ({
        ...prevForm,
        groups: prevForm.groups.map((group) => {
          if (group.id === selected.groupId) {
            return {
              ...group,
              questions: group.questions.map((question) => {
                if (question.id === field.id) {
                  return {
                    ...question,
                    ...field,
                  };
                }
                return question;
              }),
            };
          }
          return group;
        }),
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        groups: prevForm.groups.map((group) => {
          if (group.id === selectedGroup) {
            return {
              ...group,
              questions: [...group.questions, { ...field, id: uuid() }],
            };
          }
          return group;
        }),
      }));
    }
    setSelected();
    setSelectedGroup('');
  };

  const onRemoveInput = ({ groupId, questionId }) => {
    setForm((prevForm) => ({
      ...prevForm,
      groups: prevForm.groups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            questions: group.questions.filter((question) => question.id !== questionId),
          };
        }
        return group;
      }),
    }));
  };

  const handleClone = ({ clonedQuestion, index }) => {
    setForm((prevForm) => ({
      ...prevForm,
      groups: prevForm.groups.map((group) => {
        if (group.id === clonedQuestion.groupId) {
          const newQuestions = [...group.questions];
          newQuestions.splice(index + 1, 0, { ...clonedQuestion, id: uuid() });
          return {
            ...group,
            questions: newQuestions,
          };
        }
        return group;
      }),
    }));
  };

  const handleAddGroup = () => {
    if (groupTitle) {
      setForm((prevForm) => ({
        ...prevForm,
        groups: [...(prevForm.groups || []), { id: uuid(), groupTitle, questions: [] }],
      }));
      setGroupTitle('');
    }
  };

  const handleUpdateGroupTitle = (newGroupTitle, groupId) => {
    setForm((prevForm) => ({
      ...prevForm,
      groups: prevForm.groups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            groupTitle: newGroupTitle,
          };
        }
        return group;
      }),
    }));
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }
    if (type === 'list') {
      setForm((prevData) => {
        const currentDraggable = prevData.groups.find((list) => list.id === draggableId);
        const newOrderedGroup = [...prevData.groups];
        newOrderedGroup.splice(source.index, 1);
        newOrderedGroup.splice(destination.index, 0, currentDraggable);
        return { ...prevData, groups: newOrderedGroup };
      });
      return;
    }
    const sourceGroup = form.groups.find((list) => list.id === source.droppableId);
    const destinationGroup = form.groups.find((list) => list.id === destination.droppableId);
    const dragginCard = sourceGroup.questions.find((question) => question.id === draggableId);
    if (source.droppableId === destination.droppableId) {
      sourceGroup.questions.splice(source.index, 1);
      destinationGroup.questions.splice(destination.index, 0, dragginCard);
      setForm((prevData) => ({
        ...prevData,
        groups: prevData.groups.map((group) => {
          if (group.id === sourceGroup.id) {
            return destinationGroup;
          }
          return group;
        }),
      }));
    } else {
      sourceGroup.questions.splice(source.index, 1);
      destinationGroup.questions.splice(destination.index, 0, dragginCard);
      setForm((prevData) => ({
        ...prevData,
        groups: prevData.groups.map((group) => {
          if (group.id === sourceGroup.id) {
            return sourceGroup;
          }
          if (group.id === destinationGroup.id) {
            return destinationGroup;
          }
          return group;
        }),
      }));
    }
  };

  return {
    form,
    handleNameTypeChange,
    handleSaveField,
    onRemoveInput,
    handleClone,
    handleAddGroup,
    handleUpdateGroupTitle,
    onDragEnd,
    normalizeData,
  };
};

import { GET_EMPLOYEES } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetEmployees = () => {
  const { loading, data: dataEmployees } = useAuthQuery(GET_EMPLOYEES);
  const { employees: allEmployees = [] } = { ...dataEmployees };

  return { loading, allEmployees };
};

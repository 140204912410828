import React, { useEffect, useState, useRef } from 'react';
import { isEmpty } from 'ramda';
import moment from 'moment';
import { Formik, Field, Form } from 'formik';
import { Doughnut, Bar } from 'react-chartjs-2';
import { CSVLink } from 'react-csv';
import { useReactToPrint } from 'react-to-print';
import randomColor from 'randomcolor';
import Loader from 'react-loader-spinner';

import { useAuthQuery } from 'utils/hooks';
import { useGetCompanies } from 'components/createNotification/service/useGetCompanies';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import { getUserRole } from 'utils/isClinic';
import { GET_SURVEYS_STATS } from 'apollo/queries';
import noPlans from 'assets/images/no-plans.svg';

import LeftMenu from 'components/LeftMenu';
import SolidButton from 'components/SolidButton';
import Header from 'components/Header';
import { MultiSelect } from 'components/FormComponents';

import './reports.scss';

const Reports = () => {
  useRedirectByRole([ROLES.CLINIC, ROLES.SUPER_CLINIC]);
  const userRole = getUserRole();
  const isSuperClinic = userRole === ROLES.SUPER_CLINIC;
  const { loading, data, refetch } = useAuthQuery(GET_SURVEYS_STATS);
  const { clinicDashboard = {} } = { ...data };
  const { surveysStats: surveys = [] } = { ...clinicDashboard };

  const { formatedCompanies = [] } = isSuperClinic ? useGetCompanies() : {};
  const [surveyId, setSurveyId] = useState('');
  const [survey, setSurvey] = useState({});
  const [period, setPeriod] = useState('all');
  const [normalizeUi, setNormalizeUi] = useState({ value: false, resolve: () => undefined });

  useEffect(() => {
    normalizeUi.resolve();
  }, [normalizeUi]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (surveyId) {
      setSurvey((surveys || []).find(({ id }) => id === surveyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    ...(!!survey && {
      documentTitle: survey.title,
    }),
    onBeforeGetContent: () =>
      new Promise((resolve) => {
        setNormalizeUi((prevState) => ({ ...prevState, value: true, resolve }));
      }),
    onAfterPrint: () =>
      new Promise((resolve) => {
        setNormalizeUi((prevState) => ({ ...prevState, value: false, resolve }));
      }),
  });

  const getCSVData = () => {
    const { questions = [] } = { ...survey };
    const headers = ['Email', 'DNI', 'Nombre', 'Genero', 'Fecha', ...(questions || []).map(({ label }) => label)];
    const rows = [];
    const rowIds = [];
    questions.forEach((q) => {
      const { answers = [], label } = { ...q };
      answers
        .filter(({ date }) => {
          if (period === 'all') {
            return true;
          }
          return moment(date).isSameOrAfter(moment(new Date()).subtract(Number(period), 'days'));
        })
        .forEach((a) => {
          const { patient_email, date, patient_name, patient_gender, patient_id, value } = { ...a };
          const rowId = `${patient_email}_${date}`;
          const rowIndex = rowIds.indexOf(rowId);
          if (rowIndex === -1) {
            rowIds.push(rowId);
            rows.push({
              patient_email,
              date,
              patient_id,
              patient_gender,
              patient_name,
              [label]: value,
            });
          } else {
            rows[rowIndex] = { ...rows[rowIndex], [label]: value };
          }
        });
    });
    const csv = rows.map((r) =>
      headers.map((h) => {
        switch (h) {
          case 'Email':
            return r.patient_email;
          case 'DNI':
            return r.patient_id || '';
          case 'Nombre':
            return r.patient_name || '';
          case 'Genero':
            return r.patient_gender || '';
          case 'Fecha':
            return r.date;
          default:
            return r[h];
        }
      }),
    );
    return [headers, ...csv];
  };

  const handleSelectCompany = ({ target: { value } }) => {
    setSurveyId('');
    setSurvey({});
    refetch({ companyId: value });
  };

  return (
    <div className="d-flex">
      <LeftMenu />
      <div className="dashboard__main">
        <Header title="Reportes" shadow />
        <div className="dashboard__content--patients">
          <div className="patients__container survey__charts">
            <Formik initialValues={{ period, survey: surveyId }}>
              {() => (
                <Form className="reports-form">
                  {isSuperClinic && (
                    <Field
                      type="text"
                      label="Companies"
                      name="companies"
                      component={MultiSelect}
                      options={formatedCompanies}
                      placeholder="Seleccione una empresa"
                      onChange={handleSelectCompany}
                    />
                  )}
                  <Field
                    type="text"
                    label="Encuesta"
                    name="survey"
                    component={MultiSelect}
                    options={(surveys || []).map(({ id: value, title: label }) => ({ value, label }))}
                    placeholder="Seleccione la encuesta"
                    onChange={(evt) => setSurveyId(evt.target.value)}
                  />
                  <Field
                    type="text"
                    label="Periodo"
                    name="period"
                    component={MultiSelect}
                    options={[
                      { label: 'Todos los resultados', value: 'all' },
                      { label: 'Últimos 7 días', value: '7' },
                      { label: 'Últimos 14 días', value: '14' },
                      { label: 'Últimos 30 días', value: '30' },
                      { label: 'Últimos 90 días', value: '90' },
                    ]}
                    value={period}
                    placeholder="Seleccione la encuesta"
                    onChange={(evt) => setPeriod(evt.target.value)}
                  />
                  {!isEmpty(survey) && (
                    <div className="reports-form__download-buttons">
                      <CSVLink
                        data={getCSVData()}
                        filename="survey-report.csv"
                        target="_blank"
                        className="form_button slim extra-width mr-2"
                      >
                        Generar CSV
                      </CSVLink>
                      <SolidButton onClick={handlePrint} className="form_button slim extra-width">
                        Generar PDF
                      </SolidButton>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
            {!isEmpty(survey) && !isEmpty(survey.questions || []) && !loading && (
              <div className="chart_container" ref={componentRef}>
                <div
                  style={{
                    display: `${normalizeUi.value ? 'grid' : 'flex'}`,
                    ...(!normalizeUi.value && {
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }),
                    ...(normalizeUi.value && {
                      gridTemplateColumns: 'auto auto auto',
                      gridGap: '20px',
                    }),
                  }}
                >
                  {(survey.questions || []).map((question, index) => {
                    const { type, label = '', answers = [] } = { ...question };
                    if (type === 'multiple-selection' || type === 'verification') {
                      const dataSet = answers
                        .filter(({ date }) => {
                          if (period === 'all') {
                            return true;
                          }
                          return moment(date).isSameOrAfter(moment(new Date()).subtract(Number(period), 'days'));
                        })
                        .map(({ value }) => value);
                      const pieLabels = Array.from(new Set(dataSet)).sort();
                      const rgbColors = pieLabels.map(() => randomColor({ format: 'rgbArray', luminosity: 'dark' }));
                      const pieData = pieLabels.map((el) => dataSet.filter((value) => value === el).length);
                      const totalVotes = pieData.reduce((acc, curr) => acc + curr, 0);
                      const votesPercentage = pieData.map((votes) => Math.round((votes * 100) / totalVotes));
                      const labelsWithPercentage = pieLabels.map(
                        (label, index) => `${label} (${votesPercentage[index]}%)`,
                      );
                      const limitedCharacters = labelsWithPercentage.some((label) => label.length > 33);
                      const labelsLength = labelsWithPercentage.length > 4;
                      const legendPosition = labelsLength || !limitedCharacters ? 'left' : 'top';
                      const chartData = {
                        labels: labelsWithPercentage,
                        datasets: [
                          {
                            data: pieData,
                            backgroundColor: rgbColors.map(([r, g, b]) => `rgba(${r}, ${g}, ${b}, 0.2)`),
                          },
                        ],
                      };
                      return (
                        <div
                          key={`${index}-${question}`}
                          className={`chart_card chart_card--${!normalizeUi.value && 'printable'}`}
                        >
                          <p className="chart_title">{label}</p>
                          <Doughnut
                            data={chartData}
                            options={{
                              cutoutPercentage: 80,
                              legend: {
                                position: legendPosition,
                                labels: {
                                  boxWidth: 15,
                                  fontSize: 11,
                                },
                              },
                            }}
                          />
                        </div>
                      );
                    }
                    const dataSet = answers
                      .filter(({ date }) => {
                        if (period === 'all') {
                          return true;
                        }
                        return moment(date).isSameOrAfter(moment(new Date()).subtract(Number(period), 'days'));
                      })
                      .map(({ value }) => value);
                    const barLabels = Array.from(new Set(dataSet)).sort((a, b) => a - b);
                    const rgbColors = barLabels.map(() => randomColor({ format: 'rgbArray', luminosity: 'dark' }));
                    const barData = barLabels.map((el) => dataSet.filter((value) => value === el).length);
                    const chartData = {
                      labels: barLabels,
                      legend: null,
                      datasets: [
                        {
                          label: 'Pacientes',
                          data: barData,
                          backgroundColor: rgbColors.map(([r, g, b]) => `rgba(${r}, ${g}, ${b}, 0.2)`),
                        },
                      ],
                    };
                    return (
                      <div
                        key={`${index}-${question}`}
                        className={`chart_card chart_card--${!normalizeUi.value && 'printable'}`}
                      >
                        <p className="chart_title">{label}</p>
                        <Bar
                          data={chartData}
                          options={{
                            legend: false,
                            scales: {
                              yAxes: [
                                {
                                  ticks: {
                                    beginAtZero: true,
                                    setpSize: 1,
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <br />
              </div>
            )}
            {isEmpty(survey) && (
              <div className="patients__no-results">
                <div className="content">
                  <p className="title">Selecciona una encuesta</p>
                  <p className="subtitle">
                    Debes seleccionar una encuesta para generar los gráficos correspondientes de las preguntas
                    configuradas
                  </p>
                </div>
                <img src={noPlans} alt="no results" className="image" />
              </div>
            )}
            {!isEmpty(survey) && isEmpty(survey.questions || []) && (
              <div className="patients__no-results">
                <div className="content">
                  <p className="title">No se han encontrado resultados</p>
                  <p className="subtitle">La encuesta seleccionada no tiene preguntas configuradas para reportes</p>
                </div>
                <img src={noPlans} alt="no results" className="image" />
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={loading} />
    </div>
  );
};

export default Reports;

import React from 'react';

import { useConversationsContext } from 'components/conversations/ConversationsProvider';

import ConversationsList from '../ConversationsList';
import SelectedConversation from '../SelectedConversation';

import './conversations.scss';

const Conversations = () => {
  const { conversationKey } = useConversationsContext();

  return (
    <section className="conversations">
      <ConversationsList />
      <SelectedConversation key={conversationKey} />
    </section>
  );
};

export default Conversations;

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { propOr, isEmpty } from 'ramda';
import { AiOutlineCloseCircle, AiOutlineUnorderedList } from 'react-icons/ai';
import { FaSearch } from 'react-icons/fa';
import Select from 'react-select';
import Loader from 'react-loader-spinner';

import { customStyles } from 'utils/styles';
import { useAuthQuery } from 'utils/hooks';
import { GET_PATIENTS_TO_SEND_SURVEY } from 'apollo/queries';

import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';
import { NoPatientsFound } from 'components/EmptyState';

import maleAvatar from 'assets/images/patient-male.svg';
import femaleAvatar from 'assets/images/patient-female.svg';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: 790,
    maxHeight: 660,
    padding: '0',
    overflow: 'hidden',
  },
};

const selectOptions = [
  { label: 'Seleccionar todos', value: 'selectAll' },
  { label: 'Deseleccionar todos', value: 'unselectAll' },
];

const STATUS = {
  ALL: 'ALL',
  SELECTED: 'SELECTED',
};

const PatientsListModal = ({ modalIsOpen, onCloseModal, onBulkCreateSurveyInvitation }) => {
  const { loading, data, refetch } = useAuthQuery(GET_PATIENTS_TO_SEND_SURVEY);
  const allPatients = propOr([], 'patients')(data);

  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [searchPatients, setSearchPatients] = useState('');
  const [status, setStatus] = useState(STATUS.ALL);

  const memoPatients = useMemo(() => {
    const filteredPatients = (allPatients || [])
      .filter(({ status: patientStatus, name }) => {
        const isActive = patientStatus === 'active';
        const isName = !!name;
        return isActive && isName;
      })
      .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
    return filteredPatients;
  }, [allPatients]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isEmpty(patients)) setPatients(memoPatients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoPatients]);

  useEffect(() => {
    let temporal = memoPatients;
    if (searchPatients) {
      temporal = memoPatients.filter((item) => {
        const { name, cardId, email, covenant } = { ...item };
        const { name: covenantName = '' } = { ...covenant };
        const patient = `${name}-${cardId}-${email}-${covenantName}`.toLowerCase();
        return patient.indexOf(`${searchPatients}`.toLowerCase()) !== -1;
      });
    }
    setPatients(temporal);
    setStatus(STATUS.ALL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPatients]);

  useEffect(() => {
    if (status === STATUS.SELECTED) {
      setPatients((prevPatients) => prevPatients.filter(({ id }) => selectedPatients.includes(id)));
    } else {
      setPatients(memoPatients);
      setSearchPatients('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleSelectPatient = (patientId) => {
    setSelectedPatients((prevSelected) => {
      const isSelected = prevSelected.find((item) => item === patientId);
      if (isSelected) {
        return prevSelected.filter((item) => item !== patientId);
      }
      return [...prevSelected, patientId];
    });
  };

  const handleSelectAll = ({ value }) => {
    setStatus(STATUS.ALL);
    if (value === 'selectAll') {
      setSelectedPatients((prevSelected) => {
        const remaining = memoPatients.map(({ id }) => id).filter((item) => !prevSelected.includes(item));
        return [...prevSelected, ...remaining];
      });
    } else {
      setSelectedPatients([]);
    }
  };

  const handleChangeStatus = () => {
    if (status === STATUS.ALL) {
      setStatus(STATUS.SELECTED);
    } else {
      setStatus(STATUS.ALL);
    }
  };

  const handleResetState = () => {
    setSelectedPatients([]);
    setStatus(STATUS.ALL);
    setSearchPatients('');
  };

  const isMinLength = memoPatients.length < 6;

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={onCloseModal} modalProps={modalProps} closeable={false}>
      <AiOutlineCloseCircle onClick={onCloseModal} className="patients-list-modal__close" />
      <div className="patients-list-modal">
        <div className="patients-list-modal__title">
          <h3>Enviar encuesta masiva</h3>
          <p>Selecciona los pacientes a los que les enviarás la encuesta seleccionada.</p>
        </div>
        <div className="forms__search patients-list-modal__search">
          {!isMinLength && (
            <>
              <div className="search">
                <input
                  onChange={(ev) => setSearchPatients(ev.target.value)}
                  value={searchPatients}
                  placeholder="Buscar un paciente"
                />
                <FaSearch />
              </div>
              <button onClick={handleChangeStatus} type="button" className="filter">
                <AiOutlineUnorderedList size={24} /> {status === STATUS.ALL ? 'Seleccionados' : 'Todos'}
              </button>
            </>
          )}
          <div className="select">
            <Select options={selectOptions} styles={customStyles} onChange={handleSelectAll} placeholder="Opciones" />
          </div>
        </div>
        {!isEmpty(patients) && (
          <div className={`patients-list-modal__list ${isMinLength && 'minlength'}`}>
            {(patients || []).map((patient) => {
              const { name = '', gender = '', cardId = '', email = '', id = '' } = { ...patient };
              const isSelected = [...selectedPatients].indexOf(id) !== -1;
              return (
                <div key={`${id}-${cardId}`} className="patient">
                  <div className="patient__info">
                    <div className="patient__info__avatar">
                      <img src={gender === 'Hombre' ? maleAvatar : femaleAvatar} alt="patient avatar" />
                    </div>
                    <div className="patient__info__name">
                      <p>{name}</p>
                      <p>{cardId}</p>
                      <p>{email}</p>
                    </div>
                  </div>
                  <div>
                    <SolidButton
                      onClick={() => handleSelectPatient(id)}
                      className={`${isSelected ? 'remove' : 'add'} min-width-100 slim`}
                    >
                      {isSelected ? 'Eliminar' : 'Añadir'}
                    </SolidButton>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {isEmpty(patients) && !isMinLength && <NoPatientsFound isModal />}
        <div className="patients-list-modal__submit">
          <SolidButton
            onClick={() => onBulkCreateSurveyInvitation(selectedPatients, handleResetState)}
            disabled={isEmpty(selectedPatients)}
            className={`${isEmpty(selectedPatients) && 'disabled'}`}
          >
            Enviar
          </SolidButton>
        </div>
      </div>
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={loading} />
    </Modal>
  );
};

PatientsListModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onBulkCreateSurveyInvitation: PropTypes.func.isRequired,
};

export default PatientsListModal;

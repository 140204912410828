import React, { useState } from 'react';
import SolidButton from '../../SolidButton';
import WhiteLabelModal from '../WhiteLabelModal';
import './whiteLabel.scss';

const WhiteLabel = ({ partnerInfo, refetch }) => {

  const [whiteLabelModalIsOpen, setWhiteLabelModalIsOpen] = useState(false);

  const toggleWhiteLabelModal = () => {
    if (whiteLabelModalIsOpen) {
      setWhiteLabelModalIsOpen(false);
    } else {
      setWhiteLabelModalIsOpen(true);
    }
  };

  return (
    <section className={'settings'}>
      <div className={'settings__left'}>
        <p className={'settings__left_profile'}>Personaliza tu marca</p>
        <p className={'settings__left_description'}>
          Aquí puedes personalizar el diseño de la app móvil, editar los colores y subir tu logo para que la plataforma vaya bajo la imagen corporativa de tu clínica o consultorio.
        </p>
      </div>
      <div className={'settings__right settings__right--whitelabel'}>
        <p className={'team__member_name'}>
          Empieza a editar tu marca:
        </p>
        <SolidButton 
          onClick={toggleWhiteLabelModal}
          className={'whiteLabel-edit-button'}
        >
          Editar
        </SolidButton>
      </div>
      <WhiteLabelModal
        partnerInfo={partnerInfo}
        refetch={refetch}
        whiteLabelModalIsOpen={whiteLabelModalIsOpen}
        toggleWhiteLabelModal={toggleWhiteLabelModal}
      />
    </section>
  );
}

export default WhiteLabel;
import React from 'react';
import './plan-view.scss';

const PlanView = ({ plan = {}, onExercise = () => {} }) => {
  const { weeks = [] } = { ...plan };
  return (
    <div className={'weeks-container'}>
      {weeks.map((week, index) => {
        const { title = `Semana ${index + 1}`, days: exercises = [] } = { ...week };
        let days = exercises.reduce((rv, x) => {
          const { exercises = [] } = { ...x };
          exercises.map(el => (rv[x.weekNumber] = rv[x.weekNumber] || []).push(el));
          return rv;
        }, {});

        const daysList = Object.keys(days);
        const totalExercises = exercises.map(({ exercises: exs = [] }) => exs.length).reduce((a, b) => a + b, 0);
        return (
          <div className={'card'} key={index}>
            <div className={'card-title-container'}>
              <p className={'card-title plan-view-title-card'}>{title}</p>
            </div>
            <div className={'card-summary-container'}>
              <p className={'card-summary-item'}>
                <span>{totalExercises}</span>Ejercicios
              </p>
              <p className={'card-summary-item'}>
                <span>{daysList.length}</span>Días
              </p>
            </div>
            <div className='card-days-container'>
              {daysList.map(dayNumber => (
                <div className={'card-day'} key={`day-${dayNumber}`}>
                  <div className={'day-title'}>
                    <p>Día {dayNumber}</p>
                  </div>
                  <div className={'day-data'}>
                    {(days[dayNumber] || []).map(el => {
                      const { id, title, image } = { ...el };
                      return (
                        <div className={'card-exercise'} key={id} onClick={() => onExercise(el)}>
                          <img src={image} className={'image'} alt={'exercise'} />
                          <div className={'info'}>
                            <p>{title}</p>
                            <span>{`Dia ${dayNumber}`}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PlanView;

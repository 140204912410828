import { useState } from 'react';

export const useGetMessages = () => {
  const [messages, setMessages] = useState([]);

  const loadMessagesFor = (thisConversation) => {
    thisConversation
      .getMessages()
      .then((messagePaginator) => {
        setMessages((prev) => [...prev, ...messagePaginator.items]);
      })
      .catch((err) => {
        console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
      });
  };

  return { messages, setMessages, loadMessagesFor };
};

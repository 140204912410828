import React from 'react';
import { useTranslation } from 'react-i18next';
import { VscPreview } from 'react-icons/vsc';
import { BsGrid } from 'react-icons/bs';
import { FiLayers } from 'react-icons/fi';
import { AiOutlineCheck } from 'react-icons/ai';

import { EVIE_PROGRAM_STEPS } from 'components/eviePrograms/service';
import { useEvieProgramContext } from 'components/eviePrograms/EvieProgramProvider';

const EvieSteps = () => {
  const { t } = useTranslation();
  const {
    state: { step },
  } = useEvieProgramContext();

  const isDescription = step === EVIE_PROGRAM_STEPS.DESCRIPTION;
  const isCompleted = step === EVIE_PROGRAM_STEPS.COMPLETED;
  const isLevelsCompleted = [EVIE_PROGRAM_STEPS.ADDITIONAL_CONTENT, EVIE_PROGRAM_STEPS.COMPLETED].includes(step);

  return (
    <header className="evie-program__steps">
      <div className="evie-program__step current-step">
        <div className={`evie-program__intstep ${!isDescription ? 'bg' : ''}`}>
          {!isDescription ? (
            <div className="evie-program__step_check">
              <AiOutlineCheck />
            </div>
          ) : (
            <VscPreview className="evie-program__step_icon current-icon" />
          )}
          <h3 className="evie-program__step_label current-label">{t('eviePrograms.steps.description')}</h3>
        </div>
      </div>
      <div className={`evie-program__step ${!isDescription ? 'current-step' : ''}`}>
        <div className={`evie-program__intstep ${isLevelsCompleted ? 'bg' : ''}`}>
          {isLevelsCompleted ? (
            <div className="evie-program__step_check">
              <AiOutlineCheck />
            </div>
          ) : (
            <BsGrid className={`evie-program__step_icon ${!isDescription ? 'current-icon' : ''}`} />
          )}
          <h3 className={`evie-program__step_label ${!isDescription ? 'current-label' : ''}`}>
            {t('eviePrograms.steps.levels')}
          </h3>
        </div>
      </div>
      <div className={`evie-program__step ${isLevelsCompleted ? 'current-step' : ''}`}>
        <div className={`evie-program__intstep ${isCompleted ? 'bg' : ''}`}>
          {isCompleted ? (
            <div className="evie-program__step_check">
              <AiOutlineCheck />
            </div>
          ) : (
            <FiLayers className={`evie-program__step_icon ${isLevelsCompleted ? 'current-icon' : ''}`} />
          )}
          <h3 className={`evie-program__step_label ${isLevelsCompleted ? 'current-label' : ''}`}>
            {t('eviePrograms.steps.additionalContent')}
          </h3>
        </div>
      </div>
    </header>
  );
};

export default EvieSteps;

import React, { useState } from 'react';
import { isEmpty } from 'ramda';

import { useConversationsContext } from 'components/conversations/ConversationsProvider';

import SolidButton from 'components/SolidButton';

const SendMessageForm = () => {
  const { selectedConversation } = useConversationsContext();
  const [newMessage, setNewMessage] = useState('');

  const onMessageChanged = ({ target: { value } }) => {
    setNewMessage(value);
  };

  const sendMessage = (event) => {
    event.preventDefault();
    selectedConversation.sendMessage(newMessage, {
      recipient: selectedConversation.attributes.employee_id,
      clinic: true,
      clinicName: selectedConversation.attributes.clinic_name,
    });
    setNewMessage('');
  };

  const isDisabledButton = isEmpty(newMessage) || isEmpty(selectedConversation);

  return (
    <form className="conversation-form" onSubmit={sendMessage}>
      <textarea
        placeholder="type a message"
        value={newMessage}
        onChange={onMessageChanged}
        className="conversation-form__area"
      />
      <SolidButton type="submit" className={`slim ${isDisabledButton ? 'disabled' : ''}`} disabled={isDisabledButton}>
        Send
      </SolidButton>
    </form>
  );
};

export default SendMessageForm;

import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { isEmpty, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { CREATE_AVAILABILITY_SLOTS } from 'apollo/mutations';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';
import { ROUTES } from 'utils/constants';

export const useCreateAvailability = (allAvailabilities, selectedTimezone) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const [createAvailability] = useMutation(CREATE_AVAILABILITY_SLOTS);
  const [spinner, setSpinner] = useToggle();

  const onCreateAvailability = async () => {
    setSpinner();
    const formatAvailabilities = allAvailabilities
      .filter((item) => !isEmpty(item.intervals))
      .map((i) => ({ id: i.id, intervals: i.intervals, day: i.weekDay }));
    const response = await createAvailability({
      variables: {
        intervals: formatAvailabilities,
        timezoneOffset: selectedTimezone.value.timezoneOffset,
        timezoneValue: selectedTimezone.value.timezoneValue,
      },
    });

    const errors = pathOr([], ['data', 'createAvailability', 'errors'])(response);
    const emptyErrors = isEmpty(errors);
    setSpinner();
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [t('notification.success.availability')] : errors,
    });
    if (emptyErrors) {
      push(ROUTES.CALENDAR);
    }
  };

  return { onCreateAvailability, spinner };
};

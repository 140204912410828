import React from 'react';
import { Formik, Field, Form } from 'formik';

import Modal from '../../../Modal';
import { TextAreaInput } from '../../../FormComponents';
import Button from '../../../Button';

import { rejectForm } from '../../../../utils/validationSchema';

const modalProps = {
  content: {
    width: '45vw',
    maxWidth: '680px',
    outline: 'none',
    padding: '30px !important',
  },
};

const RejectModal = ({ modalIsOpen, onCloseModal, onSubmit }) => {
  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={onCloseModal} modalProps={modalProps} closeable={false}>
      <div className={'patient__modal call__modal form'}>
        <p className={'title'}>Sugiere a tu paciente unas horas disponibles para reagendar la llamada</p>
        <Formik
          initialValues={{
            message: '',
          }}
          validationSchema={rejectForm}
          onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={'w-100'}>
              <Field
                type='text'
                label='Mensaje'
                name='message'
                className={'call-message-field'}
                minRows={20}
                component={TextAreaInput}
              />
              <div className='text-center button-container'>
                <Button type='button' onClick={onCloseModal} className='mr-3' category='secondary-rounded'>
                  Cancelar
                </Button>
                <Button type='submit' category={'rounded'}>
                  Enviar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default RejectModal;

import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { CREATE_UPDATE_PROGRAM_BUILDER } from 'apollo/mutations';
import { ROUTES } from 'utils/constants';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';

const formatTypes = {
  Reading: 'reading',
  Audio: 'audio',
  ShortVideo: 'short_video',
};

const formatExerciseSessions = (exercise, indexExercise) => {
  const position = indexExercise + 1;
  const { isNew, id, title = '', laps = 0, rest = 0, exercises = [] } = exercise;
  const formatExercises = exercises.map((ex, indexEx) => ({
    exerciseId: ex.id,
    position: indexEx + 1,
  }));
  return {
    ...(!isNew && {
      id,
    }),
    title,
    laps,
    rest,
    position,
    exercises: formatExercises,
  };
};
const formatSqueezeSessions = (squeeze, indexSqueeze) => {
  const position = indexSqueeze + 1;
  const { isNew, id, title = '', squeezes = [] } = squeeze;
  const formatSqueezes = squeezes.map((sq, indexSq) => ({
    squeezeId: sq.id,
    position: indexSq + 1,
  }));
  return {
    ...(!isNew && {
      id,
    }),
    title,
    position,
    squeezes: formatSqueezes,
  };
};

const formatSessions = (programSession, indexProgramSession, levelNumber) => {
  const programSessionNumber = indexProgramSession + 1;
  const { isNew, id, sessionExercises, sessionSqueezes } = programSession;
  const exercises = sessionExercises.map(formatExerciseSessions);
  const squeezes = sessionSqueezes.map(formatSqueezeSessions);
  return {
    ...(!isNew && {
      id,
    }),
    number: programSessionNumber,
    name: `Session ${programSessionNumber}, level ${levelNumber}`,
    sessionExercises: exercises,
    sessionSqueezes: squeezes,
  };
};

const formatAdditionalContent = (content, indexContent) => {
  const position = indexContent + 1;
  const { id, __typename } = content;
  return { id, type: formatTypes[__typename], position };
};

const formatLevels = (level, index) => {
  const levelNumber = index + 1;
  const { isNew, id, name = '', goal = '', programSessions = [], additionalContent = [] } = level;
  const sessions = programSessions.map((session, indexSession) => formatSessions(session, indexSession, levelNumber));
  const aditionalContent = additionalContent.map(formatAdditionalContent);
  return {
    ...(!isNew && {
      id,
    }),
    number: levelNumber,
    name,
    goal,
    programSessions: sessions,
    aditionalContent,
  };
};

export const useCreateUpdateProgramBuilder = (programState, programId) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { detail = {}, levels = [] } = programState;
  const { title, description, backgroundImage } = detail;

  const [createUpdateProgramBuilder] = useMutation(CREATE_UPDATE_PROGRAM_BUILDER);
  const [spinner, setSpinner] = useToggle();

  const handleCreateUpdProgramBuilder = async () => {
    setSpinner();
    const programLevels = levels.map(formatLevels);

    const response = await createUpdateProgramBuilder({
      variables: {
        ...(!!programId && {
          programId,
        }),
        ...(typeof backgroundImage === 'object' && {
          backgroundImage,
        }),
        title,
        description,
        programLevels,
      },
    });

    const errors = pathOr([], ['data', 'createUpdateProgramBuilder', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors
        ? [programId ? t('notification.program.update') : t('notification.program.create')]
        : errors,
    });

    if (emptyErrors) {
      push(ROUTES.EVIE_PROGRAMS);
    }
  };
  return { createUpdProgramBuilder: handleCreateUpdProgramBuilder, spinner };
};

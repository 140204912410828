import React, { useState } from 'react';
import Button from '../../Button';
import healtphyLogo from '../../../assets/images/logo-blue.svg';
import licenseData from '../../../assets/license.json';

const Terms = ({ onContinue }) => {
  const [checked, setChecked] = useState(false);
  return (
    <div className={'signup__terms'}>
      <div className={'logo-container'}>
        <img src={healtphyLogo} alt='logo' className='logo' />
      </div>
      <p className={'title'}>Contrato de licencia de uso sobre la app y plataforma HealthPhy</p>
      <div className={'container'}>
        {licenseData.map(({ title, content, items }, index) => (
          <div key={`${index}`}>
            {title && <p className={'term-title'}>{title}</p>}
            {!!content &&
              content.map((cnt, i) => (
                <p className={'term-content'} key={i}>
                  {cnt}
                </p>
              ))}
            {items && (
              <ul className={'term-list'}>
                {items.map((itm, i) => (
                  <li key={i}>
                    <p className={'term-content'}>{itm}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
      <div className={'license-footer'}>
        <div className={'checkbox'}>
          <input type={'checkbox'} checked={checked} onChange={(event) => setChecked(event.target.checked)} />
          Entiendo y suscribo el presente contrato de licencia de uso de la app HealthPhy
        </div>

        <Button
          type={'button'}
          category={'rounded'}
          className={checked ? '' : 'disabled'}
          onClick={checked ? onContinue : () => {}}>
          Aceptar
        </Button>
      </div>
    </div>
  );
};

export default Terms;

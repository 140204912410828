import { useEffect } from 'react';
import { GET_AUDIOS } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetAudios = () => {
  const { loading, data, refetch } = useAuthQuery(GET_AUDIOS);
  const { audios: allAudio = [] } = { ...data };

  useEffect(() => {
    refetch();
  }, []);
  return { allAudio, loading };
};

import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import Loader from 'react-loader-spinner';
import { path, isEmpty, equals, pathOr } from 'ramda';
import { FaChevronLeft } from 'react-icons/fa';
import { IoMdEye } from 'react-icons/io';

import { useMutation } from '@apollo/react-hooks';
import { GET_FORM } from 'apollo/queries';
import { CREATE_UPDATE_FORM } from 'apollo/mutations';
import { useAuthQuery } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { useFormState } from 'utils/hooks/useFormState';
import { useToggle } from 'utils/hooks/useToggle';
import { queryParams } from 'utils/string';
import showNotification from 'utils/showNotification';
import { ROUTES, ROLES } from 'utils/constants';
import { inputTypes } from 'components/CreateForm/constants';
import { getUserRole } from 'utils/getUserRole';
import { LEFT_MENU } from 'utils/leftMenu';

import GroupsList from 'components/CreateForm/GroupsList';
import Button from 'components/SolidButton';
import FieldForm from 'components/CreateForm/FieldForm';
import InputCard from 'components/CreateForm/InputCard';
import AlertModal from 'components/AlertModal';
import NameModal from 'components/CreateForm/NameModal';
import Header from 'components/Header';
import PreviewModal from 'components/CreateForm/PreviewModal';

import noData from 'assets/images/no-data.svg';

import './create-form.scss';

const modalTypes = {
  BACK: 'back',
  NAME: 'name',
  REMOVE: 'remove',
};

const CreateForm = (props) => {
  useRedirectByRole([ROLES.CLINIC, ROLES.SUPER_CLINIC, ROLES.SPECIALIST]);
  const { userRole } = getUserRole();
  const changeRoute = path(['history', 'push'])(props);
  const search = path(['history', 'location', 'search'])(props);
  const params = { ...queryParams(search) };
  const { id: formId = '' } = { ...params };
  const {
    loading = false,
    data = {},
    refetch = () => {},
  } = formId
    ? useAuthQuery(GET_FORM, {
        variables: { formId },
      })
    : {};
  const { getClinicForm: formData = {} } = { ...data };

  const [showSpinner, setSpinner] = useState(false);
  const [modal, setModal] = useState('');
  const [selected, setSelected] = useState();
  const [selectedGroup, setSelectedGroup] = useState('');
  const [groupTitle, setGroupTitle] = useState('');
  const [preview, setPreview] = useToggle();
  const {
    form,
    handleNameTypeChange,
    handleSaveField,
    onRemoveInput,
    handleClone,
    handleAddGroup,
    handleUpdateGroupTitle,
    onDragEnd,
    normalizeData,
  } = useFormState({
    formData,
    setModal,
    selected,
    selectedGroup,
    groupTitle,
    setSelected,
    setSelectedGroup,
    setGroupTitle,
  });

  const { groups = [], title, typeForm, surveyType: survey } = { ...form };
  const [createUpdateForm] = useMutation(CREATE_UPDATE_FORM);

  const onCreateUpdateForm = async () => {
    if (!(isEmpty(groups) || !title || !typeForm)) {
      const { id, groups: content, title: titleForm, typeForm: type, surveyType } = { ...form };
      setSpinner(true);
      try {
        const response = await createUpdateForm({
          variables: {
            formId: id,
            content: content.map((group) => ({
              ...group,
              questions: group.questions.map((question) => {
                const { type: typeObj = {} } = { ...question };
                const { type: typeQuestion } = { ...typeObj };
                return {
                  ...question,
                  type: typeQuestion,
                  groupId: group.id,
                };
              }),
            })),
            title: titleForm,
            typeForm: type,
            ...(type === 'survey' && {
              surveyType,
            }),
          },
        });
        const errors = pathOr([], ['data', 'createUpdateClinicForm', 'errors'])(response);
        const emptyErrors = isEmpty(errors);
        showNotification({
          type: emptyErrors ? 'success' : 'error',
          messages: emptyErrors ? [`Se ha ${formId ? 'actualizado' : 'creado'} el formulario exitosamente.`] : errors,
        });

        if (emptyErrors) {
          if (!formId) {
            changeRoute(ROUTES.FORMS);
          } else {
            refetch();
          }
        }
      } catch (error) {
        showNotification({
          type: 'error',
          messages: ['Error inesperado guardando la información, intente nuevamente'],
        });
      } finally {
        setSpinner(false);
      }
    }
  };

  useEffect(() => {
    if (!formId && !title) {
      setModal(modalTypes.NAME);
    }
  }, [title, formId]);

  useEffect(() => {
    if (survey !== 'grouped' && !isEmpty(groups) && isEmpty(selectedGroup)) {
      setSelectedGroup(groups[0].id);
    }
  }, [survey, groups, selectedGroup]);

  const handleInput = (input) => setSelected(input);

  const onCloseModal = () => (title ? setModal('') : changeRoute(ROUTES.FORMS));

  const onBack = () => {
    if (equals(form, normalizeData(formData))) {
      changeRoute(ROUTES.FORMS);
    } else {
      setModal(modalTypes.BACK);
    }
  };

  const handleCancel = () => {
    setSelected();
    setSelectedGroup('');
  };

  const handleGroupTitle = ({ target: { value } }) => {
    setGroupTitle(value);
  };

  const handleSelectGroup = (groupId) => {
    setSelectedGroup(groupId);
  };

  const isEditorDisabled = survey === 'grouped' && !form.groups;
  const isCreateFormDisabled = isEmpty(groups) || groups.some((group) => isEmpty(group.questions)) || !title;

  const modalData = {
    open: modal === modalTypes.BACK,
    onCancelAction: onCloseModal,
    onConfirmAction: () => changeRoute(ROUTES.FORMS),
    title: '¿Seguro quieres continuar?',
    description: 'Perderás los cambios que no han sido guardados una vez des en Continuar',
    labelConfirm: 'Continuar',
  };

  return (
    <>
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || showSpinner}
      />
      <div className="d-flex">
        {LEFT_MENU[userRole]}
        <div className="dashboard__main">
          <Header title={`${formId ? 'Detalle del' : 'Crear nuevo'} formulario`} shadow />
          <div className="dashboard__content__forms">
            <div className="dashboard__content__workspace">
              {!!selected && (
                <div aria-hidden="true" className="dashboard__content__overlay_area" onClick={() => setSelected()} />
              )}
              <div className="fields_area">
                {!!title && (
                  <div className="title-bar">
                    <div aria-hidden="true" className="title-bar__back-button" onClick={onBack}>
                      <FaChevronLeft />
                    </div>
                    <h2 aria-hidden="true" className="title-bar__title" onClick={() => setModal(modalTypes.NAME)}>
                      {title}
                    </h2>
                    <IoMdEye className="title-bar__preview" onClick={setPreview} />
                    <Button
                      className={`${isCreateFormDisabled || !title ? 'disabled' : ''}`}
                      onClick={onCreateUpdateForm}
                    >
                      Guardar
                    </Button>
                  </div>
                )}
                {!isEmpty(groups) ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="grouped-survey" type="list">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {groups.map((group, index) => (
                            <GroupsList
                              key={group.id}
                              group={group}
                              index={index}
                              onRemoveInput={onRemoveInput}
                              handleInput={handleInput}
                              handleClone={handleClone}
                              updateGroupTitle={handleUpdateGroupTitle}
                              surveyType={survey}
                            />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <div className="empty_state">
                    <img src={noData} alt="empty form" className="image" />
                    <h2>Tu formulario de evaluación empieza aquí</h2>
                    <p>
                      Para empezar a construir tu formulario de valoración, selecciona las preguntas en la barra derecha
                      y modificalas
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className={`dashboard__content__components ${selected ? 'edit_mode' : ''}`}>
              {!selected && (
                <>
                  {form.surveyType === 'grouped' && (
                    <>
                      <h2 className="title">Editor de grupos</h2>
                      <p className="description">Elige un nombre para el siguiente grupo de preguntas</p>
                      <div className="create-group-container">
                        <input className="form__input" value={groupTitle} onChange={handleGroupTitle} />
                        <Button className="slim" onClick={handleAddGroup}>
                          Añadir
                        </Button>
                      </div>
                    </>
                  )}
                  <div className={isEditorDisabled ? 'disabled-editor' : ''}>
                    <h2 className="title">Editor de preguntas</h2>
                    <p className="description">
                      Seleccione los tipos de pregunta en el botón agregar para ingresarlos al formulario y modificar su
                      contenido
                    </p>
                    <ul className="component_list">
                      {inputTypes.map((typeObj) => (
                        <InputCard
                          key={uuid()}
                          {...typeObj}
                          surveyType={survey}
                          typeForm={typeForm}
                          onAddInput={() => handleInput({ type: typeObj })}
                        />
                      ))}
                    </ul>
                  </div>
                </>
              )}
              {!!selected && (
                <FieldForm
                  data={selected}
                  groupsList={groups}
                  surveyType={survey}
                  group={selectedGroup}
                  selectGroup={handleSelectGroup}
                  onCancel={handleCancel}
                  onSave={handleSaveField}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <AlertModal {...modalData} />
      <NameModal
        open={modal === modalTypes.NAME}
        onCloseModal={onCloseModal}
        editMode={!!title}
        form={form}
        handleNameTypeChange={handleNameTypeChange}
      />
      {!isEmpty(form) && form.groups && preview && (
        <PreviewModal modalIsOpen={preview} closeModal={setPreview} form={form} />
      )}
    </>
  );
};

export default CreateForm;

import { getAuthInfo } from 'libs/authenticationService';
import { ROLES } from 'utils/constants';

export const getUserRole = () => {
  const { user = null } = getAuthInfo();
  const { userType = null } = { ...user };

  const isClinic = userType === ROLES.CLINIC;
  const isSuperClinic = userType === ROLES.SUPER_CLINIC;
  const isCompany = userType === ROLES.COMPANY;
  const isSpecialist = userType === ROLES.SPECIALIST;
  return { userRole: userType, isClinic, isSuperClinic, isCompany, isSpecialist };
};

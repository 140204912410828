import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { loginForm } from 'utils/validationSchema';

import { TextInput, PasswordInput } from 'components/FormComponents';
import SolidButton from 'components/SolidButton';

const LoginForm = ({ onLogin, toggleForgotPassword, role }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={onLogin}
      validationSchema={loginForm}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Field
            type="email"
            label={t('formLabel.email')}
            name="email"
            component={TextInput}
            placeholder="example@email.com"
            labelClassName="login-label"
          />
          <div className="forgot-password">
            <Field
              type="text"
              label={t('formLabel.password')}
              name="password"
              component={PasswordInput}
              labelClassName="login-label"
            />
            <button type="button" onClick={toggleForgotPassword}>
              {t('login.forgot')}
            </button>
          </div>
          <div className="login-button">
            <SolidButton type="submit" className={`mb-4 ${role === 'clinic' ? 'tertiary' : ''}`}>
              {t('button.signin')}
            </SolidButton>
            {/* <p className="login__note">
                ¿Aún no tienes una cuenta? <Link to="/registro" className="login__note--lighted">Registrate</Link>
              </p> */}
          </div>
        </Form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
  toggleForgotPassword: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
};

export default LoginForm;

import React, { useContext, createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { v4 as uuid } from 'uuid';

import { queryParams } from 'utils/string';
import { readingBuilderReducer } from './service/readingBuilderReducer';
import { useCreateUpdateReadingBuilder } from './service/useCreateUpdateReadingBuilder';
import { useGetReadingBuilder } from './service/useGetReadingBuilder';
import { ACTIONS, BLOCK_TYPE } from './service/constants';

const CreateReadingContext = createContext();

const initialState = {
  blockType: BLOCK_TYPE.IDLE,
  selectedBlock: {},
  title: '',
  backgroundImage: '',
  thumbnailImage: '',
  content: [],
};

const CreateReadingProvider = ({ children }) => {
  const {
    goBack,
    location: { search },
  } = useHistory();
  const { id: readingId, block_content: blockContent } = queryParams(search);

  const [state, dispatch] = useReducer(readingBuilderReducer, initialState);
  const { blockType, selectedBlock, content } = state;

  const { loading } = useGetReadingBuilder(readingId, blockContent, dispatch);
  const { spinner, createUpdateReadingBuilder } = useCreateUpdateReadingBuilder(state, readingId);

  const handleEndEditing = () => {
    dispatch({ type: ACTIONS.SET_BLOCK_TYPE, payload: BLOCK_TYPE.IDLE });
    dispatch({ type: ACTIONS.HANDLE_SELECT_BLOCK, payload: {} });
  };

  const handleSaveBlock = (values) => {
    if (isEmpty(selectedBlock)) {
      const formatBlock = { ...values, id: uuid(), type: blockType };
      dispatch({ type: ACTIONS.ADD_BLOCK, payload: formatBlock });
    } else {
      const editBlock = content.map((block) => {
        const { id } = block;
        if (selectedBlock.id === id) {
          return { ...selectedBlock, ...values };
        }
        return block;
      });
      dispatch({ type: ACTIONS.EDIT_BLOCK, payload: editBlock });
    }
    handleEndEditing();
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }
    if (type === 'list') {
      const currentDraggable = content.find((bBlock) => bBlock.id === draggableId);
      const newOrderedBlocks = [...content];
      newOrderedBlocks.splice(source.index, 1);
      newOrderedBlocks.splice(destination.index, 0, currentDraggable);
      dispatch({ type: ACTIONS.DRAG_AND_DROP_BLOCK, payload: newOrderedBlocks });
    }
  };

  return (
    <CreateReadingContext.Provider
      value={{
        goBack,
        dispatch,
        endEditing: handleEndEditing,
        saveBlock: handleSaveBlock,
        dragEnd: onDragEnd,
        createUpdateReadingBuilder,
        loading: spinner || loading,
        readingId,
        blockContent,
        builderState: state,
      }}
    >
      {children}
    </CreateReadingContext.Provider>
  );
};

CreateReadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useCreateReadingContext() {
  const context = useContext(CreateReadingContext);
  if (!context) {
    throw new Error('Expected a CreateReadingContext somewhere in the react tree to set context value');
  }
  return context;
}

export { CreateReadingProvider, useCreateReadingContext };

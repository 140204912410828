import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { VscPreview } from 'react-icons/vsc';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import { formatBackgroundImage } from 'components/readings/service/utils';
import { evieDetail } from 'utils/validationSchema';
import { useEvieProgramContext } from 'components/eviePrograms/EvieProgramProvider';
import { ACTIONS, EVIE_PROGRAM_STEPS } from 'components/eviePrograms/service';

import SolidButton from 'components/SolidButton';
import { TextInput, ImageInput } from 'components/FormComponents';

const EvieDetail = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const {
    state: { detail = {} },
    dispatch,
  } = useEvieProgramContext();
  const { title, backgroundImage, description } = detail;
  const initialValues = { title };
  const formatedBackImage = formatBackgroundImage(backgroundImage);

  const blocksFromHtml = htmlToDraft(description);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));

  useEffect(() => {
    if (description) {
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [description]);

  const onEditorStateChange = (editState) => {
    setEditorState(editState);
  };

  const handleAddDetail = (values) => {
    dispatch({
      type: ACTIONS.ADD_DETAIL,
      payload: { ...values, description: draftToHtml(convertToRaw(editorState.getCurrentContent())) },
    });
    dispatch({ type: ACTIONS.SET_STEP, payload: EVIE_PROGRAM_STEPS.LEVELS });
  };

  return (
    <div className="evie-program__detail">
      <section className="evie-program__title">
        <h3 className="evie-program__title_label">{t('eviePrograms.detail.title')}</h3>
        <div className="evie-program__title_icon">
          <VscPreview />
        </div>
      </section>
      <Formik initialValues={initialValues} onSubmit={handleAddDetail} validationSchema={evieDetail} enableReinitialize>
        {({ setFieldValue, isSubmitting, isValid }) => {
          const isDisabledButton = [!isValid, isSubmitting].includes(true);
          return (
            <Form>
              <section className="evie-program__form">
                <div>
                  <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
                </div>
                <ImageInput
                  setFieldValue={setFieldValue}
                  formatedImage={formatedBackImage}
                  name="backgroundImage"
                  label={t('formLabel.backgroundImage')}
                />
              </section>
              <section className="evie-program__editor">
                <h4 className="evie-program__editor_label">{t('eviePrograms.detail.editorLabel')}</h4>
                <Editor
                  wrapperClassName="evie-program__editor_container"
                  toolbarClassName="evie-program__editor_toolbar"
                  editorClassName="evie-program__editor_content"
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  placeholder="..."
                  toolbar={{
                    options: ['inline', 'fontSize'],
                    inline: { options: ['bold', 'italic'] },
                  }}
                />
              </section>
              <section className="evie-program__btns mt-4">
                <SolidButton className="evie-secondary mr-4" onClick={goBack}>
                  {t('button.back')}
                </SolidButton>
                <SolidButton
                  className={`evie-primary ${isDisabledButton ? 'disabled' : ''}`}
                  disabled={isDisabledButton}
                  type="submit"
                >
                  {t('button.next')}
                </SolidButton>
              </section>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EvieDetail;

import React from 'react';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';
import { v4 as uuid } from 'uuid';

import { useCreateReadingContext } from '../CreateReadingProvider';
import { ACTIONS, BLOCK_TYPE } from '../service/constants';

const BlockCard = ({ blockType }) => {
  const { title, icon: Icon, type } = blockType;
  const { dispatch } = useCreateReadingContext();

  const handleSetBlock = () => {
    if (type === BLOCK_TYPE.DIVIDER) {
      dispatch({ type: ACTIONS.ADD_BLOCK, payload: { id: uuid(), type } });
    } else {
      dispatch({ type: ACTIONS.SET_BLOCK_TYPE, payload: type });
    }
  };

  return (
    <li>
      <button
        type="button"
        className={`blockOptions__list_item ${type === BLOCK_TYPE.V_CONTAINER ? 'v-container' : ''}`}
        onClick={handleSetBlock}
      >
        {Icon && <Icon className="input_icon" />}
        <span>
          <strong>{title}</strong>
        </span>
        <FaPlus />
      </button>
    </li>
  );
};

BlockCard.propTypes = {
  blockType: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.func,
    type: PropTypes.string,
  }).isRequired,
};

export default BlockCard;

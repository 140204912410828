import React from 'react';

const EmployeeInviteTabs = ({ tabs, inviteTab, handleChangeTab }) => {
  return (
    <section className={'invite-tabs'}>
      {(tabs || []).map((tab, index) => (
        <div 
          key={`${index}-${tab}`} 
          className={`invite-tabs__tab ${tab === inviteTab && 'selected'}`}
          onClick={() =>handleChangeTab(tab)}
        >
          <h4 className={`${tab === inviteTab && 'selected'}`}>{tab}</h4>
        </div> 
      ))}
    </section>
  );
}

export default EmployeeInviteTabs;
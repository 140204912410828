import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ToastContainer } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Routes from '../Routes';
import StoreProvider from '../StoreProvider';
import VideoCall from '../VideoCall';
import IconsLibrary from '../Icon/IconsLibrary';
import client from '../../apollo/client';

import 'bootstrap/scss/bootstrap.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'video.js/dist/video-js.css';
import '../../assets/fonts/SF-Pro-Text-Regular.otf';
import '../../assets/fonts/SF-Pro-Text-Semibold.otf';
import '../../assets/fonts/SF-Pro-Text-Bold.otf';
import '../../assets/fonts/SF-Pro-Display-Regular.otf';
import '../../assets/fonts/SF-Pro-Display-Bold.otf';
import './app.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const App = () => (
  <Elements stripe={stripePromise}>
    <StoreProvider>
      <ApolloProvider client={client}>
        <ToastContainer autoClose={10000} />
        <Routes />
        <IconsLibrary />
        <VideoCall />
      </ApolloProvider>
    </StoreProvider>
  </Elements>
);

export default App;

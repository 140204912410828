import { useMemo } from 'react';

export const useGetCategories = ({ exercises = [] }) => {
  const memoCategories = useMemo(() => {
    const bodyParts = exercises.reduce((a, b) => {
      const { bodyParts: parts = [] } = b;
      return [...a, ...parts];
    }, []);

    const equipments = exercises.reduce((a, b) => {
      const { equipments: equip = [] } = b;
      return [...a, ...equip];
    }, []);

    const levels = exercises.reduce((a, b) => {
      const { levels: lev = [] } = b;
      return [...a, ...lev];
    }, []);

    return {
      memoBodyParts: [...new Set(bodyParts)].sort(),
      memoEquipments: [...new Set(equipments)].sort(),
      memoLevels: [...new Set(levels)],
    };
  }, [exercises]);

  return memoCategories;
};

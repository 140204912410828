import LeftMenu from 'components/LeftMenu';
import LeftMenuEmployers from 'components/LeftMenuEmployers';
import SpecialistLeftMenu from 'components/SpecialistLeftMenu/SpecialistLeftMenu';

import { ROLES } from './constants';

export const LEFT_MENU = {
  [ROLES.CLINIC]: <LeftMenu />,
  [ROLES.SUPER_CLINIC]: <LeftMenu />,
  [ROLES.COMPANY]: <LeftMenuEmployers />,
  [ROLES.SPECIALIST]: <SpecialistLeftMenu />,
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormatBlockTypes } from '../service/useFormatBlockTypes';

import BlockCard from '../BlockCard';

const BlockList = () => {
  const { t } = useTranslation();
  const { blockTypes } = useFormatBlockTypes();

  return (
    <>
      <h3 className="builder__blockOptions_label">{t('readings.editor.label')}</h3>
      <p className="builder__blockOptions_description">{t('readings.editor.description')}</p>
      <ul className="blockOptions__list">
        {blockTypes.map((blockType) => {
          const { title } = blockType;
          return <BlockCard key={title} blockType={blockType} />;
        })}
      </ul>
    </>
  );
};

export default BlockList;

import React from 'react';

import Icon from 'components/Icon';

const OrdersList = ({ orders, selectOrder }) => (
  <>
    {(orders || []).map((order) => {
      const { id = '', name = '', status = '', createdAt, employee = {} } = { ...order };
      const { name: employeeName = '', email = '' } = { ...employee };
      const IS_UNFULLFILLED = status === 'unfulfilled';
      const date = new Date(createdAt);

      return (
        <tr key={id}>
          <td style={{ width: '35%' }}>{name}</td>
          <td style={{ width: '15%' }} className="status-reward">
            <span className={IS_UNFULLFILLED ? 'unactive' : ''}>{status}</span>
          </td>
          <td style={{ width: '20%' }} className="order-employee">
            <span>{employeeName}</span>
            <span>{email}</span>
          </td>
          <td style={{ width: '20%' }}>{date.toLocaleString()}</td>
          <td style={{ width: '10%' }}>
            <Icon name="edit" className="reward-edit" onClick={() => selectOrder(order)} />
          </td>
        </tr>
      );
    })}
  </>
);

export default OrdersList;

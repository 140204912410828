import React from 'react';
import { FaPlus } from 'react-icons/fa';

const InputCard = (props) => {
  const { title, description, icon: Icon, onAddInput = () => {}, surveyType, type, typeForm } = { ...props };
  const groupedType = 'grouped';
  const followupType = 'followup';
  const pointedType = 'pointed-multiple-selection';
  const cie10Type = 'cie10';
  if (groupedType !== surveyType && pointedType === type) return null;
  if (followupType !== typeForm && cie10Type === type) return null;
  return (
    <li onClick={onAddInput} aria-hidden="true">
      {Icon && <Icon className="input_icon" />}
      <div className="input_content">
        <p className="input_title">{title}</p>
        <p className="input_description">{description}</p>
      </div>
      <FaPlus className="add_icon" />
    </li>
  );
};

export default InputCard;

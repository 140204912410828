import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const BulletListPreview = ({ block }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { style, items } = block;

  return (
    <ul className={`readingPreview__info_list ${style ? 'bold-block' : ''}`}>
      {items.map((item) => (
        <li key={item.es}>{item[language]}</li>
      ))}
    </ul>
  );
};

BulletListPreview.propTypes = {
  block: PropTypes.shape({
    style: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        es: PropTypes.string,
        en: PropTypes.string,
      }),
    ),
  }).isRequired,
};

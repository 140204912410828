import React, { useState, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { isEmpty, pathOr, equals } from 'ramda';
import Loader from 'react-loader-spinner';

import Modal from 'components/Modal';

import { useAuthQuery } from 'utils/hooks';
import { useToggle } from 'utils/hooks/useToggle';
import showNotification from 'utils/showNotification';
import { GET_FORMS } from 'apollo/queries';
import { CREATE_SURVEY_INVITE } from 'apollo/mutations';

import noForms from 'assets/images/no-results.svg';

import { NoSurveys } from './NoSurveys';
import { SelectSurvey } from './SelectSurvey';
import { SelectedSurvey } from './SelectedSurvey';
import { ActionButtons } from './ActionButtons';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: '700px',
    maxHeight: '95vh',
    overflowY: 'hidden',
  },
};

const SurveysModal = (props) => {
  const { modalIsOpen = false, onCloseModal = () => {}, patientId = '', refetchPatient = () => {} } = { ...props };
  const { loading, data, refetch } = useAuthQuery(GET_FORMS, {
    variables: {
      type: 'survey',
    },
  });
  const { getClinicForms: surveys = [] } = { ...data };

  const [selectedSurvey, setSelectedSurvey] = useState({});
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [spinner, setSpinner] = useToggle();
  const [showSurvey, setShowSurvey] = useToggle();
  const [createSurveyInvite] = useMutation(CREATE_SURVEY_INVITE);

  const memoIds = useMemo(() => {
    if (!isEmpty(selectedSurvey)) {
      const questionIds = selectedSurvey.content.reduce((acc, curr) => {
        const { questions = [] } = { ...curr };
        return [...acc, ...questions];
      }, []);
      return questionIds.map(({ id }) => id);
    }
    return [];
  }, [selectedSurvey]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAll = ({ value }) => {
    if (value === 'selectAll') {
      setSelectedQuestions((prevSelected) => {
        const remaining = memoIds.filter((id) => !prevSelected.includes(id));
        return [...prevSelected, ...remaining];
      });
    } else {
      setSelectedQuestions([]);
    }
  };

  useEffect(() => {
    setSelectedQuestions([]);
    handleSelectAll({ value: 'selectAll' });
  }, [selectedSurvey]);

  const onSendSurvey = async () => {
    setSpinner(true);
    const { id: formId = '' } = { ...selectedSurvey };
    const response = await createSurveyInvite({
      variables: {
        patientId,
        formId,
        ...(!equals(memoIds, selectedQuestions) && {
          questions: selectedQuestions,
        }),
      },
    });
    const errors = pathOr([], ['data', 'createSurveyInvite', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? ['Se ha enviado exitosamente la encuesta.'] : errors,
    });

    if (emptyErrors) {
      refetchPatient();
      onCloseModal();
      setSelectedSurvey({});
      setSelectedQuestions([]);
      if (showSurvey) {
        setShowSurvey();
      }
    }
  };

  const handleSelectSurvey = (surveyId) => {
    const findSurvey = surveys.find(({ id }) => id === surveyId);
    setSelectedSurvey(findSurvey);
  };

  const handleSelectQuestion = (questionId) => {
    setSelectedQuestions((prevSelected) => {
      const isSelected = prevSelected.find((id) => id === questionId);
      if (isSelected) {
        return prevSelected.filter((id) => id !== questionId);
      }
      return [...prevSelected, questionId];
    });
  };

  const handleCloseModal = () => {
    onCloseModal();
    setSelectedSurvey({});
    setSelectedQuestions([]);
    if (showSurvey) {
      setShowSurvey();
    }
  };

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={onCloseModal} modalProps={modalProps} closeable={false}>
      {isEmpty(surveys) && <NoSurveys onCloseModal={onCloseModal} />}
      {!isEmpty(surveys) && (
        <div>
          <SelectSurvey
            surveys={surveys}
            showSurvey={showSurvey}
            handleSelectSurvey={handleSelectSurvey}
            handleSelectAll={handleSelectAll}
          />
          {showSurvey ? (
            <SelectedSurvey
              selectedSurvey={selectedSurvey}
              handleSelectQuestion={handleSelectQuestion}
              selectedQuestions={selectedQuestions}
            />
          ) : (
            <div className="no-form-selected no-form-selected--survey">
              <img src={noForms} alt="no form selected" />
            </div>
          )}
          <ActionButtons
            onCloseModal={handleCloseModal}
            selectedSurvey={selectedSurvey}
            handleSendSurvey={onSendSurvey}
            handleShowSurvey={setShowSurvey}
            showSurvey={showSurvey}
            selectedQuestions={selectedQuestions}
          />
        </div>
      )}
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || spinner}
      />
    </Modal>
  );
};

export default SurveysModal;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CgTrash } from 'react-icons/cg';
import { BsArrowRight } from 'react-icons/bs';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiDuplicate } from 'react-icons/bi';
import { v4 as uuid } from 'uuid';
import { Draggable } from 'react-beautiful-dnd';

import { useToggle } from 'utils/hooks/useToggle';
import { ACTIONS } from 'components/eviePrograms/service';
import { useEvieProgramContext } from 'components/eviePrograms/EvieProgramProvider';

import EvieWarning from '../EvieWarning/EvieWarning';
import EvieBlockInfo from '../EvieBlockInfo/EvieBlockInfo';

const ExerciseSessionCard = ({ item, sessionId, sessionNumber, items, index, isSqueeze }) => {
  const { id, title, laps, rest } = item;
  const { t } = useTranslation();
  const {
    dispatch,
    state: {
      selectedLevel: { number },
    },
  } = useEvieProgramContext();

  const [exerciseWarning, setExerciseWarning] = useToggle();
  const [blockInfo, setBlockInfo] = useToggle();

  const handleRemoveSessionItem = () => {
    setExerciseWarning();
    if (isSqueeze) {
      dispatch({ type: ACTIONS.REMOVE_SQUEEZE_SESSION, payload: { sessionId, itemId: id } });
    } else {
      dispatch({ type: ACTIONS.REMOVE_EXERCISE_SESSION, payload: { sessionId, itemId: id } });
    }
  };

  const handleSelectExerciseSession = () => {
    dispatch({
      type: ACTIONS.SELECT_EXERCISE_SESSION,
      payload: { sessionId, exerciseSessionId: id, level: number, session: sessionNumber, squeeze: isSqueeze },
    });
  };

  const handleAddBlockInfo = (values) => {
    setBlockInfo();
    if (isSqueeze) {
      dispatch({ type: ACTIONS.ADD_SQUEEZE_SESSION_INFO, payload: { sessionId, itemId: id, values } });
    } else {
      dispatch({ type: ACTIONS.ADD_EXERCISE_SESSION_INFO, payload: { sessionId, itemId: id, values } });
    }
  };

  const handleCloneExerciseSession = () => {
    const newItems = [...items];
    newItems.splice(index + 1, 0, { ...item, id: uuid(), isNew: true });
    if (isSqueeze) {
      dispatch({ type: ACTIONS.CLONE_SQUEEZE_SESSION, payload: { sessionId, newItems } });
    } else {
      dispatch({ type: ACTIONS.CLONE_EXERCISE_SESSION, payload: { sessionId, newItems } });
    }
  };

  const waringData = {
    open: exerciseWarning,
    onCancelAction: setExerciseWarning,
    onConfirmAction: handleRemoveSessionItem,
    title: t('eviePrograms.warning.excerciseTitle'),
  };

  const blockInfoData = {
    blockData: { title, laps, rest },
    open: blockInfo,
    onCancelAction: setBlockInfo,
    onSubmitBlockInfo: handleAddBlockInfo,
    squeeze: isSqueeze,
  };

  return (
    <>
      <Draggable draggableId={id} index={index}>
        {(provided) => (
          <li
            className="exercise-session-card"
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <div className="exercise-session-card__bg" />
            <div className="exercise-session-card__bg-mask" />
            <h3 className="exercise-session-card__bg_title">{title}</h3>
            <div className="exercise-session-card__actions">
              <div>
                <CgTrash className="exercise-session-card__actions_icon" onClick={setExerciseWarning} />
                <AiOutlineEdit className="exercise-session-card__actions_icon" onClick={setBlockInfo} />
                <BiDuplicate className="exercise-session-card__actions_icon" onClick={handleCloneExerciseSession} />
              </div>
              <button
                type="button"
                className="exercise-session-card__actions_button"
                onClick={handleSelectExerciseSession}
              >
                {t('button.edit')} <BsArrowRight className="exercise-session-card__actions_icon" />
              </button>
            </div>
          </li>
        )}
      </Draggable>
      {exerciseWarning && <EvieWarning {...waringData} />}
      {blockInfo && <EvieBlockInfo {...blockInfoData} />}
    </>
  );
};

ExerciseSessionCard.defaultProps = {
  isSqueeze: false,
};

ExerciseSessionCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    laps: PropTypes.number,
    rest: PropTypes.number,
  }).isRequired,
  sessionId: PropTypes.string.isRequired,
  sessionNumber: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  index: PropTypes.number.isRequired,
  isSqueeze: PropTypes.bool,
};

export default ExerciseSessionCard;

import React from 'react';
import { object, string } from 'yup';
import { Formik, Field, Form } from 'formik';
import { TextInput } from 'components/FormComponents';
import { requiredMessage, urlMessage } from 'utils/validationSchema';

const validationSchema = object().shape({
  label: string().trim().required(requiredMessage),
  gif: string().trim().url(urlMessage),
});

const BaseForm = (props) => {
  const { onUpdateField, data, footer } = { ...props };
  const { type: typeObj = {} } = { ...data };
  const { type = '' } = { ...typeObj };
  return (
    <Formik initialValues={data} onSubmit={onUpdateField} validationSchema={validationSchema}>
      {({ setFieldValue }) => (
        <Form className="form">
          <Field type="text" label="Enunciado de la pregunta" name="label" component={TextInput} />
          {!['cie10'].includes(type) && <Field type="text" label="Gif url" name="gif" component={TextInput} />}
          <Field
            name="required"
            render={({ field }) => (
              <div className="form__input-container assessment__checkbox__input">
                <input
                  {...field}
                  type="checkbox"
                  checked={field.value}
                  onChange={() => {
                    setFieldValue('required', !field.value);
                  }}
                />
                <label className="form__label">Obligatorio</label>
              </div>
            )}
          />
          {!['date', 'file', 'cie10'].includes(type) && (
            <Field
              name="chart"
              render={({ field }) => (
                <div className="form__input-container assessment__checkbox__input">
                  <input
                    {...field}
                    type="checkbox"
                    checked={field.value}
                    onChange={() => {
                      setFieldValue('chart', !field.value);
                    }}
                  />
                  <label className="form__label">Incluir gráfica</label>
                </div>
              )}
            />
          )}
          {footer}
        </Form>
      )}
    </Formik>
  );
};

export default BaseForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import Loader from 'react-loader-spinner';
import { isEmpty, pathOr } from 'ramda';

import { sendEmailForm } from 'utils/validationSchema';
import showNotification from 'utils/showNotification';
import { SEND_EMAIL_TO_PATIENTS } from 'apollo/mutations';

import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';
import { TextAreaInput, TextInput, FileInput } from 'components/FormComponents';

import './send-message-modal.scss';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: '610px',
  },
};

const initialValues = {
  subject: '',
  message: '',
  file: '',
};

const SendMessageModal = ({ modalIsOpen, onCloseModal, selectedPatients, unselectPatients }) => {
  const [spinner, setSpinner] = useState(false);
  const [sendEmailToPatients] = useMutation(SEND_EMAIL_TO_PATIENTS);

  const handleSendEmail = async (values) => {
    const { subject = '', message = '', file = '' } = { ...values };

    setSpinner(true);
    const response = await sendEmailToPatients({
      variables: {
        patientIds: selectedPatients,
        subject,
        message,
        ...(!isEmpty(file) && {
          file: file[0],
        }),
      },
    });

    const errors = pathOr([], ['data', 'sendEmailToPatients', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);
    showNotification({
      type: isEmpty(errors) ? 'success' : 'error',
      messages: isEmpty(errors) ? [`Se ha enviado el mensaje exitosamente`] : errors,
    });
    if (emptyErrors) {
      unselectPatients();
      onCloseModal();
    }
  };

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={onCloseModal} modalProps={modalProps} closeable={false}>
      <h3 className="send--title">Mensaje personalizado</h3>
      <p className="send--subtitle">
        Personaliza un mensaje para tus pacientes con información relevante o documentos que quieras compartir, este les
        llegará vía email.
      </p>
      <Formik initialValues={initialValues} validationSchema={sendEmailForm} onSubmit={handleSendEmail}>
        {({ isSubmitting, isValid }) => (
          <Form>
            <Field type="text" label="Asunto" name="subject" component={TextInput} />
            <Field type="text" label="Mensaje" name="message" minRows={40} component={TextAreaInput} />
            <div className="send--file">
              <Field label="Adjuntar archivo" name="file" component={FileInput} />
            </div>
            <div className="send--buttons">
              <SolidButton onClick={onCloseModal} className="secondary mr-4">
                Cancelar
              </SolidButton>
              <SolidButton
                className={`${!isValid || isSubmitting ? 'disabled' : ''}`}
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Enviar
              </SolidButton>
            </div>
          </Form>
        )}
      </Formik>
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={spinner} />
    </Modal>
  );
};

SendMessageModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  selectedPatients: PropTypes.arrayOf(PropTypes.string).isRequired,
  unselectPatients: PropTypes.func.isRequired,
};

export default SendMessageModal;

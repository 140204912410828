import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';

import SolidButton from 'components/SolidButton';

const Item = ({ label, value }) => (
  <div className="settings__right__info">
    <p className="label">{label}</p>
    <p className="value">{value}</p>
  </div>
);

const ProfileInfo = ({ partnerInfo }) => {
  const { email, name, profile } = { ...partnerInfo };
  const { country } = { ...profile };
  const { t } = useTranslation();
  const { push } = useHistory();
  const { isCompany, isClinic } = getUserRole();

  return (
    <section className="settings settings--profile">
      <div className="settings__left">
        <p className="settings__left_profile">{t('settings.profile.title')}</p>
        <p className="settings__left_description">
          {isCompany ? t('settings.profile.companyDescription') : t('settings.profile.clinicDescription')}
        </p>
      </div>
      <div className="settings__right">
        <Item label={t('formLabel.name')} value={name} />
        <Item label={t('formLabel.email')} value={email} />
        {(isCompany || isClinic) && <Item label={t('formLabel.country')} value={country} />}
        <div className="settings__right__edit">
          <SolidButton onClick={() => push(ROUTES.EDIT_PROFILE)}>{t('button.edit')}</SolidButton>
        </div>
      </div>
    </section>
  );
};

ProfileInfo.propTypes = {
  partnerInfo: PropTypes.shape({
    profile: PropTypes.shape({
      country: PropTypes.string,
    }),
    email: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

Item.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default ProfileInfo;

import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';

import { profileSuperClinicForm } from 'utils/validationSchema';

import UserAvatar from 'components/UserAvatar';
import SolidButton from 'components/SolidButton';
import Icon from 'components/Icon';
import { TextInput, TextAreaInput } from 'components/FormComponents';

const SuperClinicProfile = (props) => {
  const { t } = useTranslation();
  const {
    partner = {},
    onContinue = () => {},
    toggleOpenModal = () => {},
    onBack = () => {},
    editProfile = false,
  } = { ...props };
  const { email = '', name = '', profile = {} } = { ...partner };
  const { description = '', avatar: queryAvatar = {} } = { ...profile };
  const { original: avatarUrl = '' } = { ...queryAvatar };
  const initialValues = {
    email,
    name,
    description,
  };

  const [imageUrl, setImageUrl] = useState('');
  const fileInput = useRef();
  const reader = new FileReader();

  reader.onloadend = () => {
    setImageUrl(reader.result);
  };

  const handleFileInputClick = () => {
    if (fileInput) {
      fileInput.current.click();
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onContinue} validationSchema={profileSuperClinicForm}>
      {({ setFieldValue, isSubmitting, isValid }) => (
        <Form className="signup__profile">
          <div className="left">
            <UserAvatar size="huge" avatarUrl={imageUrl || avatarUrl} className="navigation__avatar user-avatar">
              <div className="camera-button" aria-hidden="true" onClick={handleFileInputClick}>
                <Icon name="camera" />
              </div>
              <input
                type="file"
                className="file-upload"
                ref={fileInput}
                style={{ display: 'none' }}
                onChange={(event) => {
                  const upload = event.currentTarget.files[0];
                  setFieldValue('file', upload);
                  if (upload) {
                    reader.readAsDataURL(upload);
                  }
                }}
              />
            </UserAvatar>
            <Field
              type="text"
              label={t('formLabel.description')}
              className="description-field"
              name="description"
              minRows={4}
              component={TextAreaInput}
            />
            {editProfile && (
              <div className="change-password change-password-company">
                <div>
                  <p className="item-label">{t('formLabel.password')}</p>
                  <p className="item-value password">{'\u2022'.repeat(10)}</p>
                </div>
                <div className="change-password__button">
                  <SolidButton type="button" onClick={toggleOpenModal}>
                    {t('button.change')}
                  </SolidButton>
                </div>
              </div>
            )}
          </div>
          <div className="right">
            <div className="patient__title" style={{ justifyContent: 'flex-start' }}>
              <Icon name="profile" className="icon" />
              <span className="title">{t('editProfile.title')}</span>
            </div>
            <div className="form-row">
              <Field
                type="text"
                label={t('formLabel.name')}
                name="name"
                component={TextInput}
                readOnly
                className="read-only-input"
              />
              <Field
                type="email"
                label={t('formLabel.email')}
                name="email"
                component={TextInput}
                readOnly
                className="read-only-input"
              />
            </div>
            <div className="button-bar">
              <SolidButton type="button" className="secondary ml-auto mr-2" onClick={onBack}>
                {t('button.cancel')}
              </SolidButton>
              <SolidButton
                type="submit"
                className={!isValid || isSubmitting ? 'disabled' : ''}
                disabled={!isValid || isSubmitting}
              >
                {t('button.save')}
              </SolidButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SuperClinicProfile;

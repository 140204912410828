import { useEffect, useState } from 'react';
import { propOr } from 'ramda';
import { getTimeZones } from '@vvo/tzdb';

import { useAuthQuery } from 'utils/hooks';
import { FETCH_AVAILABILITIES_BY_ROLE, GET_PARTNER_BY_ROLE } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';

export const useGetAvailabilities = () => {
  const { userRole } = getUserRole();
  const currentTimezoneValue = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data, refetch, loading } = useAuthQuery(FETCH_AVAILABILITIES_BY_ROLE[userRole]);
  const partner = propOr({}, GET_PARTNER_BY_ROLE[userRole])(data);
  const { availabilities = [], profile = {} } = { ...partner };
  const { timezoneValue } = { ...profile };

  const timeZones = getTimeZones();
  const formatTimeZones = timeZones.map((timeZone) => ({
    label: timeZone.currentTimeFormat,
    value: {
      timezoneOffset: timeZone.currentTimeOffsetInMinutes / 60,
      timezoneValue: timeZone.name,
    },
  }));

  const [selectedTimezone, setSelectedTimezone] = useState({});

  useEffect(() => {
    const getDeafultTimezone = formatTimeZones.find(
      (item) => item.value.timezoneValue === (timezoneValue || currentTimezoneValue),
    );
    setSelectedTimezone(getDeafultTimezone);
  }, [timezoneValue, currentTimezoneValue]);

  const handleSelectTimezone = (value) => {
    setSelectedTimezone(value);
  };

  useEffect(() => {
    refetch();
  }, []);

  return { availabilities, loading, selectedTimezone, allTimeZones: formatTimeZones, handleSelectTimezone };
};

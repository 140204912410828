import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';

import { passwordForm } from 'utils/validationSchema';
import { useToggle } from 'utils/hooks/useToggle';

import Modal from 'components/Modal';
import Icon from 'components/Icon';
import SolidButton from 'components/SolidButton';
import Button from 'components/Button';
import { TextInput } from 'components/FormComponents';

import './password-modal.scss';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: '840px',
  },
};

const CheckItem = ({ label, value }) => (
  <div className="feedback-item">
    <Icon name="checked" className={`icon ${value && 'checked'}`} />
    <p className={`label ${value && 'checked'}`}>{label}</p>
  </div>
);

const PasswordModal = (props) => {
  const { t } = useTranslation();
  const { open, onCloseModal, onSave } = { ...props };
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [showPassword, setShowPassword] = useToggle();

  const onChangePassword = (values) => {
    onSave(values, onCloseModal);
  };

  const onInputChange = (event, onChange, setState) => {
    const { value } = event.target;
    setState(value);
    onChange(event);
  };

  const minLength = password.length >= 8;
  const wMatch = !!password && password === confirm;
  const wUpper = /(.*[A-Z])/g.test(password);
  const wLower = /(.*[a-z])/g.test(password);
  const wNumber = /(.*[0-9])/g.test(password);
  const wSpecial = /(.*[#?!@$%^&*+-_])/g.test(password);
  const valid = minLength && wMatch && wUpper && wLower && wSpecial && wNumber;
  return (
    <Modal modalIsOpen={open} closeModal={onCloseModal} modalProps={modalProps} closeable={false}>
      <Formik initialValues={{ password: '', confirm: '' }} onSubmit={onChangePassword} validationSchema={passwordForm}>
        {({ handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <div className="password__modal">
              <div className="close-icon-container">
                <Icon name="close" className="close-icon" onClick={onCloseModal} />
              </div>
              <div className="password__modal__title">
                <Icon name="password" className="icon" />
                <p className="title">{t('changePassword.title')}</p>
              </div>
              <div className="password__modal__content">
                <div className="form">
                  <Field
                    type={showPassword ? 'text' : 'password'}
                    label={t('changePassword.label.new')}
                    name="password"
                    component={TextInput}
                    onChange={(e) => onInputChange(e, handleChange, setPassword)}
                  />
                  <Field
                    type={showPassword ? 'text' : 'password'}
                    label={t('changePassword.label.confirm')}
                    name="confirm"
                    component={TextInput}
                    onChange={(e) => onInputChange(e, handleChange, setConfirm)}
                  />
                  <div className="button-bar">
                    <SolidButton disabled={!valid} type="submit" className={`${!valid && 'disabled'}`}>
                      {t('button.update')}
                    </SolidButton>
                    <Button
                      type="button"
                      category="secondary-rounded"
                      className="password-button"
                      onClick={setShowPassword}
                    >
                      <div className="icon-container">
                        <Icon name="eye" />
                      </div>
                      {t('changePassword.show')}
                    </Button>
                  </div>
                </div>
                <div className="feedback">
                  <CheckItem label={t('changePassword.validation.match')} value={wMatch} />
                  <CheckItem label={t('changePassword.validation.uppercase')} value={wUpper} />
                  <CheckItem label={t('changePassword.validation.lowercase')} value={wLower} />
                  <CheckItem label={t('changePassword.validation.number')} value={wNumber} />
                  <CheckItem label={t('changePassword.validation.character')} value={wSpecial} />
                  <CheckItem label={t('changePassword.validation.length')} value={minLength} />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PasswordModal;

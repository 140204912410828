import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import moment from 'moment';

import Icon from 'components/Icon';

import noData from 'assets/images/no-data.svg';

export const EmployeeActivityLog = ({ userData }) => {
  const { programs, activities, lastCompleted, bookings, painLevel, difficultyLevel, satisfactionLevel, openLogs } =
    userData;
  const sortByStatus = programs.sort((a, b) => {
    if (a.status > b.status) {
      return 1;
    }
    if (a.status < b.status) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      {isEmpty(activities) ? (
        <div className="patients__no-results">
          <div className="content empty-state">
            <p className="title">El paciente aún no tiene registro de actividad</p>
            <p className="subtitle">
              Vigila el progreso de tu paciente en su casa, aquí encontrarás su registro de la actividad diaria.
            </p>
          </div>
          <img src={noData} alt="no follow ups" className="image" />
        </div>
      ) : (
        <div className="activity__content">
          <div className="activity__content_left-side">
            <div className="patient__title">
              <div>
                <Icon name="activity" className="icon" />
                <p className="title">Actividad del paciente</p>
              </div>
              <button type="button" onClick={openLogs} className="activity__content_logsbtn">
                Ver registros
              </button>
            </div>
            <h4 className="current-day">
              <strong>Programas:</strong>
            </h4>
            <ul className="patient-plans">
              {sortByStatus.map((employeeProgram) => {
                const { program, currentLevelNumber: level, currentSessionNumber: session, status } = employeeProgram;
                const { id = '', title = '' } = program;
                const isActive = status === 'active';
                return (
                  <li key={id} className="patient-plans__title">
                    <span>
                      {title} - sesión {session}, nivel {level}
                    </span>{' '}
                    {isActive ? <strong> | {status}</strong> : null}
                  </li>
                );
              })}
            </ul>
            <p className="last-session">
              <b>Última sesión finalizada:</b> {moment(lastCompleted).format('DD/MM/YYYY - hh:mm A')}
            </p>
            <p className="last-session">
              <b>Número de teleconsultas:</b> {bookings}
            </p>
            <div className="activity__content_metrics">
              <div className="metrics-item">
                <div className="metric-item-icon last-login" />
                <div className="metric-item-data">
                  <p className="metric-item-value">
                    {painLevel || '0.0'}
                    <span>/ 10</span>
                  </p>
                  <p className="metric-item-title">Nivel de dolor actual</p>
                </div>
              </div>
              <div className="metrics-item">
                <div className="metric-item-icon plan-state" />
                <div className="metric-item-data">
                  <p className="metric-item-value">
                    {difficultyLevel}
                    <span>/ 5</span>
                  </p>
                  <p className="metric-item-title">Nivel promedio de dolor en las sesiones</p>
                </div>
              </div>
              <div className="metrics-item">
                <div className="metric-item-icon pain-level" />
                <div className="metric-item-data">
                  <p className="metric-item-value">
                    {satisfactionLevel}
                    <span>/ 5</span>
                  </p>
                  <p className="metric-item-title">Nivel promedio de satisfacción</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

EmployeeActivityLog.propTypes = {
  userData: PropTypes.shape({
    programs: PropTypes.arrayOf(PropTypes.shape({})),
    activities: PropTypes.shape({}),
    lastCompleted: PropTypes.string,
    bookings: PropTypes.number,
    painLevel: PropTypes.string,
    difficultyLevel: PropTypes.string,
    satisfactionLevel: PropTypes.string,
    openLogs: PropTypes.bool,
  }).isRequired,
};

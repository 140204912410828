import React from 'react';
import { isEmpty } from 'ramda';
import moment from 'moment';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import { capitalize } from '../../../utils/string';
import { Clock } from 'react-feather';
import maleAvatar from '../../../assets/images/patient-male.svg';
import femaleAvatar from '../../../assets/images/patient-female.svg';
import 'moment/locale/es';

const getAvatar = (gender) => (gender === 'Hombre' ? maleAvatar : femaleAvatar);

const Booking = ({ bookingState, changeRoute, showClock, showEmptyState }) => {

  return !isEmpty(bookingState) ? (
    <div className={'bookings__panel'}>
      <div className={'panel-body'}>
        {bookingState.map((booking) => {
          const { id = '', patient = {}, startAt } = { ...booking };
          const { id: patientId, name = '', email = '', gender } = { ...patient };
          const mDate = moment(startAt);
          return (
            <div className={'incomming-pending-item item'} key={id}>
              <img src={getAvatar(gender)} alt={'patient avatar'} className={'avatar'} />
              <div className={'info'}>
                <p className={'name'}>{name || email}</p>
                {showClock ? (
                  <>
                    <p className={'time'}>
                      <span className={'icon'}>
                        <Clock size={16} color={'#8093FC'} />
                      </span>
                      {mDate.format('h:mma')}
                    </p>
                    <p className={'date'}>{`${capitalize(mDate.format('dddd'))}, ${capitalize(mDate.format('MMMM DD'))}`}</p>
                  </>
                ) : (
                  <p className={'label'}>{`Solicitud ${!!name ? 'de' : 'primera'} llamada`}</p>
                )}
              </div>
              <div className={'actions'}>
                <Button
                  category='secondary'
                  onClick={() => changeRoute(`/pacientes/${patientId}?tab=4`)}
                  className={'seemore'}>
                  Ver más
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className={'bookings-empty'}>
      <Icon name={'phone'}/>
      { showEmptyState ? (
        <h2>No tienes llamadas agendadas</h2>
      ) : (
        <h2>No tienes solicitudes pendientes</h2>
      )}
    </div>
  )   
};

export default Booking;


import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { addDays } from 'date-fns';

import './date-picker.scss';

const currentYear = new Date().getFullYear() + 1;
const currentMonth = new Date().getMonth();
const fromMonth = new Date(currentYear - 100, 0);
const toMonth = new Date(currentYear, currentMonth);
const toNextMonth = new Date(currentYear, currentMonth + 1);
const FORMAT = 'MM/DD/YYYY';

export function YearMonthForm({ date, localeUtils, onChange, fsClass }) {
  const months = localeUtils.getMonths();
  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div className={`${fsClass} DayPicker-Caption`}>
      <select
        name="month"
        onChange={handleChange}
        value={date.getMonth()}
        className="date-picker__select"
      >
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select
        name="year"
        onChange={handleChange}
        value={date.getFullYear()}
        className="date-picker__select"
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      month:
        props.data.validation === 'age'
          ? new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate())
          : props.data.initialDate,
    };
  }

  handleYearMonthChange = (month) => {
    this.setState({ month });
  };

  disabledDaysAfterValidation = (data) => {
    switch (data) {
      case 'age':
        return new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate());
      case 'next-month':
        return new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
      case 'none':
      case 'future':
        return '';
      case 'future45':
        return addDays(new Date(), 44);
      default:
        return new Date();
    }
  };

  disabledDaysBeforeValidation = (data) => {
    switch (data) {
      case 'future':
        return new Date();
      case 'future45':
        return new Date();
      default:
        return '';
    }
  };

  toMonthValidation = (data) => {
    switch (data) {
      case 'next-month':
        return toNextMonth;
      case 'none':
      case 'future':
        return '';
      default:
        return toMonth;
    }
  };

  render() {
    const { data } = this.props;
    const fsClass = data.isFSblocked ? 'fs-block' : '';
    const invalidClass = data.touched && data.valid ? 'is-invalid' : '';

    return (
      <div className={`YearNavigation ${fsClass} ${data.errorClass || ''} ${invalidClass}`}>
        <DayPickerInput
          inputProps={{
            id: data.id,
            name: data.name,
            className: `form__input ${data.classes} ${data.className || ''} ${fsClass} ${data.errorClass || ''} ${data.inputClass
              || ''} ${data.touched && data.valid ? 'is-invalid' : ''}`,
            autoComplete: 'off',
            disabled: data.disabled,
          }}
          classNames={{
            container: 'DayPickerInput',
            overlayWrapper: 'DayPickerInput-OverlayWrapper',
            overlay: 'DayPickerInput-Overlay',
          }}
          format={data.isSummaryPage ? 'YYYY-MM-DD' : FORMAT}
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder="(MM/DD/YYYY)"
          value={
            data.value && data.value !== 'Invalid date'
              ? formatDate(data.value, data.isSummaryPage ? 'YYYY-MM-DD' : FORMAT)
              : ''
          }
          onDayChange={
            data.isSummaryPage
              ? (value) => {
                data.onChange(value);
                data.onBlur(value);
              }
              : data.onChange
          }
          dayPickerProps={{
            disabledDays: {
              after: this.disabledDaysAfterValidation(data.validation),
              before: this.disabledDaysBeforeValidation(data.validation),
            },
            month: this.state.month,
            fromMonth,
            toMonth: this.toMonthValidation(data.validation),
            captionElement: (
              <YearMonthForm onChange={this.handleYearMonthChange} fsClass={fsClass} />
            ),
          }}
        />
      </div>
    );
  }
}

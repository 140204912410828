import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import SolidButton from 'components/SolidButton';
import { TextInput } from 'components/FormComponents';

import { newUserForm } from 'utils/validationSchema';

export const initialValues = {
  name: '',
  email: '',
};

const NewUserForm = ({ handleSubmitNewUser }) => (
  <div className="new-user__content__form">
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitNewUser}
      validationSchema={newUserForm}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <Form onSubmit={handleSubmit}>
          <Field label="Nombre" name="name" component={TextInput} />
          <Field label="Correo electrónico" name="email" component={TextInput} />
          <SolidButton
            className={`new-user__content__form_button ${!isValid || isSubmitting ? 'disabled' : ''}`}
            disabled={!isValid || isSubmitting}
            type="submit"
          >
            Enviar
          </SolidButton>
        </Form>
      )}
    </Formik>
  </div>
);

NewUserForm.defaultProps = {
  handleSubmitNewUser: () => {},
};

NewUserForm.propTypes = {
  handleSubmitNewUser: PropTypes.func,
};

export default NewUserForm;

import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import NumberFormat from 'react-number-format';
import { getIn } from 'formik';

import './form.scss';

const NumberInput = ({
  field,
  form,
  label,
  labelClassName,
  className,
  isRequired,
  isFSblocked,
  ...rest
}) => {
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error;
  const invalidClass = hasError ? 'is-invalid' : '';
  const fsClass = isFSblocked ? 'fs-block' : '';

  return (
    <div className="form__input-container">
      {label && (
        <label htmlFor={name} className={`form__label ${labelClassName}`}>
          {label}
          {' '}
          {isRequired && <span className="text-pink">*</span>}
        </label>
      )}
      <NumberFormat
        id={name}
        className={`form__input ${className} ${fsClass} ${invalidClass}`}
        type="text"
        onValueChange={(e) => form.setFieldValue(name, e.value)}
        onBlur={field.onBlur}
        value={field.value}
        {...rest}
      />
      {hasError && <div className="form__error">{error}</div>}
    </div>
  );
};

NumberInput.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  isRequired: PropTypes.bool,
  isFSblocked: PropTypes.bool,
};

NumberInput.defaultProps = {
  className: '',
  label: '',
  labelClassName: '',
  isRequired: false,
  isFSblocked: false,
};

export default NumberInput;

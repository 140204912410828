import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import Conversations from 'components/conversations/Conversations';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: '1280px',
    overflow: 'hidden',
    padding: '0',
  },
};

const ConversationsModal = ({ isOpen, onCloseModal }) => (
  <Modal modalIsOpen={isOpen} closeModal={onCloseModal} closeable={false} modalProps={modalProps}>
    {isOpen && <Conversations />}
  </Modal>
);

ConversationsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ConversationsModal;

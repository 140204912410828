import React from 'react';
import PropTypes from 'prop-types';

import './solid-button.scss';

const Button = ({ children, type, className, onClick, disabled }) => (
  <button disabled={disabled} type={type} className={`form_button ${className}`} onClick={onClick}>
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  className: '',
  onClick: () => {},
  disabled: false,
};

export default Button;

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

import { capitalize } from 'utils/string';

import Icon from 'components/Icon';

import noData from 'assets/images/no-data.svg';

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 10,
          stepSize: 1,
        },
      },
    ],
  },
};

export const PatientActivityLog = ({ userData }) => {
  const {
    plans,
    activities,
    painLevels,
    openLogs,
    finishedDay,
    bookings,
    painLevel,
    difficultyLevel,
    satisfactionLevel,
  } = userData;
  const chartData = {
    labels: (painLevels || []).map(({ date }) => capitalize(moment(new Date(date)).format('MMM DD'))),
    datasets: [
      {
        label: 'Nivel de dolor',
        data: (painLevels || []).map(({ level }) => level),
        borderColor: ['rgba(70, 194, 173, 0.2)'],
        backgroundColor: ['rgba(70, 194, 173, 0.2)'],
        pointBackgroundColor: ['rgba(70, 194, 173, 0.2)'],
        pointBorderColor: ['rgba(70, 194, 173, 0.2)'],
      },
    ],
  };

  return (
    <>
      {(isEmpty(activities) || isEmpty(painLevels)) && (
        <div className="patients__no-results">
          <div className="content empty-state">
            <p className="title">El paciente aún no tiene registro de actividad</p>
            <p className="subtitle">
              Vigila el progreso de tu paciente en su casa, aquí encontrarás su registro de la actividad diaria.
            </p>
          </div>
          <img src={noData} alt="no follow ups" className="image" />
        </div>
      )}
      {!isEmpty(activities) && !isEmpty(painLevels) && (
        <div className="activity__content">
          <div className="activity__content_left-side">
            <div className="patient__title">
              <div>
                <Icon name="activity" className="icon" />
                <p className="title">Actividad del paciente</p>
              </div>
              <button type="button" onClick={openLogs} className="activity__content_logsbtn">
                Ver registros
              </button>
            </div>
            <h4 className="current-day">
              <strong>Planes actuales:</strong>
            </h4>
            <ul className="patient-plans">
              {plans.map((patientPlan) => {
                const { id, currentDay = {}, plan = {} } = patientPlan;
                const { weekNumber, weekTitle } = currentDay;
                const { title } = plan;
                return (
                  <li key={id} className="patient-plans__title">
                    <span>
                      {title} - día {weekNumber} {weekTitle}
                    </span>
                  </li>
                );
              })}
            </ul>
            <p className="last-session">
              <b>Última sesión finalizada:</b> {moment(finishedDay).format('DD/MM/YYYY - hh:mm A')}
            </p>
            <p className="last-session">
              <b>Número de teleconsultas:</b> {bookings}
            </p>
            <div className="activity__content_metrics">
              <div className="metrics-item">
                <div className="metric-item-icon last-login" />
                <div className="metric-item-data">
                  <p className="metric-item-value">
                    {painLevel || '0.0'}
                    <span>/ 10</span>
                  </p>
                  <p className="metric-item-title">Nivel de dolor actual</p>
                </div>
              </div>
              <div className="metrics-item">
                <div className="metric-item-icon plan-state" />
                <div className="metric-item-data">
                  <p className="metric-item-value">
                    {difficultyLevel}
                    <span>/ 5</span>
                  </p>
                  <p className="metric-item-title">Nivel promedio de dolor en las sesiones</p>
                </div>
              </div>
              <div className="metrics-item">
                <div className="metric-item-icon pain-level" />
                <div className="metric-item-data">
                  <p className="metric-item-value">
                    {satisfactionLevel}
                    <span>/ 5</span>
                  </p>
                  <p className="metric-item-title">Nivel promedio de satisfacción</p>
                </div>
              </div>
            </div>
            <div className="activity__content_chart">
              <Line data={chartData} options={options} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

PatientActivityLog.propTypes = {
  userData: PropTypes.shape({
    plans: PropTypes.arrayOf(PropTypes.shape({})),
    activities: PropTypes.arrayOf(PropTypes.shape({})),
    painLevels: PropTypes.arrayOf(PropTypes.shape({})),
    openLogs: PropTypes.func,
    finishedDay: PropTypes.string,
    bookings: PropTypes.number,
    painLevel: PropTypes.string,
    difficultyLevel: PropTypes.string,
    satisfactionLevel: PropTypes.string,
  }).isRequired,
};

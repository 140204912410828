import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';

import { ROUTES } from 'utils/constants';

const ShortVideoRow = ({ shortVideo }) => {
  const { push } = useHistory();
  const { id, title, description } = shortVideo;

  const onShortVideoDetail = () => {
    push(`${ROUTES.SHORT_VIDEO}?id=${id}`);
  };

  return (
    <tr>
      <td style={{ width: '30%' }}>{title}</td>
      <td style={{ width: '60%' }}>
        <p className="content-column-table">{description}</p>
      </td>
      <td style={{ width: '10%' }} className="actions-col">
        <button type="button" onClick={onShortVideoDetail} className="form-detail">
          <IoIosArrowDown />
        </button>
      </td>
    </tr>
  );
};

ShortVideoRow.propTypes = {
  shortVideo: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default ShortVideoRow;

import React from 'react';
import PropTypes from 'prop-types';

const ReimbursementMode = ({ table }) => <>{table}</>;

ReimbursementMode.propTypes = {
  table: PropTypes.node.isRequired,
};

export default ReimbursementMode;

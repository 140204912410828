export const translationEn = {
  leftMenuHeader: {
    newPatient: 'New Patient',
    onboarding: 'Onboarding',
    home: 'Home',
    patients: 'Patients',
    individuals: 'Individuals',
    plans: 'Plans',
    forms: 'Forms',
    library: 'Library',
    calendar: 'Calendar',
    availability: 'Availability',
    reports: 'Reports',
    covenants: 'Covenants',
    events: 'Events',
    templates: 'Templates',
    template: 'Create new Template',
    editTemplate: 'Template Detail',
    readings: 'Readings',
    reading: 'Create new Reading',
    editReading: 'Reading Detail',
    settings: 'Settings',
    profile: 'Edit profile',
    program: 'Create new Program',
    editProgram: 'Program Detail',
    programs: 'Programs',
    company: 'Create new Company',
    editCompany: 'Company Detail',
    companiesList: 'Companies',
    notification: 'Create Notification',
    chat: 'Chat',
    activePauses: 'Active Breaks',
    createActivePause: 'Create Active Break',
    editActivePause: 'Update Active Break',
    audioList: 'Audios',
    audioDetail: 'Audio Detail',
    createAudio: 'Create new Audio',
    shortVideos: 'Short videos',
    shortVideoDetail: 'Short video detail',
    createShortVideo: 'Create new short video',
    eviePrograms: 'Evie programs',
    evieProgramDetail: 'Evie program detail',
    createEvieProgram: 'Create new Evie program',
    squeezes: 'Squeezes',
    squeeze: 'Squeeze detail',
    createSqueeze: 'Create new squeeze',
    squeezeDetail: 'Squeeze detail',
    companies: {
      invite: 'Invite an employee',
      employees: 'Employees',
      rewards: 'Rewards',
      orders: 'Orders',
      invoices: 'Invoices',
      teamCount: {
        actual: 'Your current team is',
        of: 'out of',
        button: 'Upgrade your plan',
      },
    },
  },
  navigation: {
    profile: 'My Profile',
    subscriptions: 'My Subscriptions',
    terms: 'Terms & Conditions',
    policy: 'Privacy Policy',
    logout: 'Log out',
  },
  settings: {
    payment: {
      title: 'Benefits Program',
      description: 'Configure payment information for your employees benefits',
      allowance: 'Allowance',
      cadence: 'Cadence',
      type: 'Payment Type',
      method: 'Payment Method',
      creditCard: 'Credit Card',
      reimbursement: 'Reimbursement',
      ondemand: 'On Demand',
      subscription: 'Subscription',
      bank: 'Bank Account',
      changeType: '* To change payment type you must contact the administrator',
      yearly: 'Yearly',
      bi_yearly: 'Biyearly',
      quarterly: 'Quarterly',
    },
    profile: {
      title: 'Profile',
      companyDescription:
        'In this section you can edit the personal information of your profile, adding the basic data and logo that your employees will see in the mobile app.',
      clinicDescription:
        'In this section you can edit the personal information of your profile, adding the basic data and logo that your patients will see in the mobile app.',
    },
    language: {
      label: 'Set your language:',
      title: 'Language',
      description: 'Set your default language for the platform here.',
      placeholder: 'Select language',
    },
    team: {
      title: 'HR Team',
      description: 'Here you can add HR members to receive admin notifications from the platform.',
      addBtn: 'Add team member',
      addModal: {
        addTitle: 'Add member to the team',
        updTitle: 'Update team member',
      },
      emptyState: {
        title: 'You don´t have any team member added!',
      },
    },
  },
  button: {
    delete: 'Delete',
    cancel: 'Cancel',
    exit: 'Exit',
    create: 'Create',
    update: 'Update',
    remove: 'Remove',
    add: 'Add',
    back: 'Back',
    buy: 'Buy',
    next: 'Next',
    edit: 'Edit',
    invite: 'Invite',
    change: 'Change',
    save: 'Save',
    send: 'Send',
    signin: 'Sign In',
    signout: 'Sign Out',
    retrieve: 'Retrieve',
    clinics: 'Clinics',
    companies: 'Companies',
    apply: 'Apply',
    setup: 'Set Up',
    continue: 'Continue',
    duplicate: 'Duplicate',
    addImage: 'Add image',
    categories: 'Categories',
    upload: 'Upload',
  },
  nofound: {
    title: 'No results have been found',
    description:
      'Make sure the criteria is spelled correctly or try adjusting the filter to find what you are looking for.',
  },
  searchLabel: {
    employees: 'Search for an employee',
    rewards: 'Search for a product',
    orders: 'Search for an order',
    program: 'Search for a program',
    companies: 'Search for a company',
    activePauses: 'Search for an active break',
    invoices: 'Search Invoice',
    squeeze: 'Search Squeeze',
  },
  filterLabel: {
    companies: 'Filter by company',
  },
  tabs: {
    all: 'All',
    active: 'Active',
    inactive: 'Inactive',
    shareLink: 'Share link',
    specialInvitation: 'Special invitation',
    unfulfilled: 'Unfulfilled',
    fulfilled: 'Fulfilled',
  },
  formLabel: {
    title: 'Title',
    description: 'Description',
    name: 'Name',
    lastName: 'Last name',
    email: 'Email',
    workEmail: 'Work email',
    contactNumber: 'Contact number',
    address: 'Address',
    promoCode: 'Promo code',
    image: 'Image',
    status: 'Status',
    price: 'Price in points',
    quantity: 'Quantity',
    country: 'Country',
    password: 'Password',
    confirmPassword: 'Confirm password',
    id: 'Identification',
    nit: 'NIT',
    phone: 'Phone',
    city: 'City',
    contact: 'Contact person',
    specialist: 'Number of specialists',
    employee: 'Number of employees',
    doctorName: 'Doctor Name',
    duration: 'Duration',
    plan: 'Plan',
    backgroundImage: 'Background Image',
    thumbnailImage: 'Thumbnail Image',
    posterImage: 'Poster Image',
    doctorImage: 'Doctor Image',
    survey: 'Survey',
    from: 'From',
    to: 'To',
    bodyParts: 'Body parts',
    color: 'Color',
    month: 'Month',
    year: 'Year',
    esHeadingLabel: 'Title content in Spanish',
    enHeadingLabel: 'Title content in English',
    esTextLabel: 'Content of the text block in Spanish',
    enTextLabel: 'Content of the text block in English',
    imageUrlLabel: 'Image or gif url',
    esOptionsLabel: 'Spanish Options',
    enOptionsLabel: 'English Options',
    bold: 'Bold',
    type: 'Type',
    goal: 'Goal',
    laps: 'Laps',
    rest: 'Rest (seconds)',
    date: 'Date',
    hour: 'Hour',
    timeZone: 'Time zone',
    message: 'Message',
    actions: 'Actions',
    action: 'Action',
    file: {
      text: 'Drag and drop a file here, or click to select',
      button: 'Add file',
    },
  },
  placeholder: {
    country: 'Select country',
    plan: 'Select plan',
    template: 'Select Template',
    day: 'Select day',
    survey: 'Select Survey',
    searchExercise: 'Exercise name',
    audio: 'Search for an audio',
    shortVideo: 'Search for a short video',
  },
  tableHeader: {
    title: 'Title',
    image: 'Image',
    doctor: 'Doctor',
    duration: 'Duration',
    name: 'Name',
    employees: 'Number of employees',
    content: 'Content',
  },
  deleteForm: {
    title: 'This action is not reversible. Please be certain',
    description: 'This form will be deleted, along with all of its configurations and questions.',
  },
  leaveCreatePatient: {
    description: 'You will not be able to edit this information once you click on Exit.',
  },
  notification: {
    deleteForm: 'The form has been successfully deleted.',
    order: 'Order has been successfully fulfilled',
    updateProfile: 'The profile has been successfully updated.',
    updatePassword: 'Password has been successfully updated.',
    passwordRecovery: 'In your email you will find the link to reset your password.',
    passwordRecovered: 'You have successfully recovered your password.',
    push: 'The PUSH notification has been successfully created.',
    coupon: 'Coupon has been applied successfully.',
    updateEmployeeStatus: 'The employee has been successfully updated.',
    program: {
      create: 'The program has been successfully created',
      update: 'The program has been successfully updated',
    },
    company: {
      create: 'Company has veen successfully created',
      update: 'Company has been successfully updated',
    },
    warning: {
      availability: 'There is already a space in this time frame',
      followupModal: 'Be sure to save the information before continuing, otherwise click cancel again.',
      selectedItem: 'The item has already been selected',
    },
    success: {
      availability: 'Availability has been successfully saved.',
      booking: 'The call has been successfully scheduled',
    },
    activePause: {
      create: 'The active break has been successfully created',
      update: 'The active break has been successfully updated',
    },
    createReading: {
      success: {
        create: 'The reading has been successfully created.',
        update: 'The reading has been successfully updated.',
      },
    },
  },
  changePassword: {
    title: 'Change password',
    show: 'Show password',
    label: {
      new: 'New password',
      confirm: 'Confirm new password',
    },
    validation: {
      match: 'Passwords must match',
      uppercase: 'At least one capital letter (A-Z)',
      lowercase: 'At least one lowercase letter (a-z)',
      number: 'At least one number (0-9)',
      character: 'At least one special character',
      length: 'At least 8 characters',
    },
  },
  createEmployee: {
    success:
      'We have sent all the information to create a HealthPhy user for companies in the email you provided us. If you have any questions, please contact us at info@healthphy.de',
  },
  editProfile: {
    title: 'My profile',
  },
  createTemplate: {
    readings: {
      title: {
        all: 'All Readings',
        own: 'Readings in this Template',
      },
    },
  },
  surveys: {
    welcome: {
      message:
        'Hello, you have been sent a survey from the HealthPhy platform, below you will find options to fill out as clearly as possible. This information is confidential, so when you are ready click on the button below.',
      button: 'Take questionnaire',
    },
    survey: {
      section: 'Section {{current}} of {{length}}',
      fileUpload: 'Upload a file',
    },
    completed: {
      thanksInit: 'You can now start your personalized exercises',
      thanks: 'Thank you very much for your answers',
      data: 'These are your login details',
      user: 'User name:',
      password: 'Password',
      success: 'The survey has been successfully completed and the answers will be analyzed by your professional.',
      reminder: 'Remember to change the password once you enter the app to validate your entry in the platform.',
      download: 'Download it now',
    },
  },
  billing: {
    clinicTitle: "You take care of providing your best service. We'll take care of your patients",
    clinicDescription:
      'At HealthPhy you pay for the number of active patients you have, making it easy to tailor the plan to your needs, with different payment options, according to your needs, without long-term contracts or complex licenses. You can cancel your subscription at any time with no additional costs or cancellation fees.',
    companyTitle: "You take care of providing your best service. We'll take care of your team.",
    companyDescription:
      'At HealthPhy you pay for the number of active employees you have, making it easy to tailor the plan to your needs, with different payment options, according to your needs, without long-term contracts or complex licenses. You can cancel your subscription at any time with no additional costs or cancellation fees.',
    clinicChoose: 'Escoge el número de pacientes:',
    companyChoose: 'Choose the number of active employees in your organization:',
    clinicPerks: {
      library: 'Biblioteca con cientos de ejercicios terapéuticos para crear el programa perfecto de rehabilitación.',
      plans: 'Planes de rehabilitación preconfigurados por nuestros especialistas',
      custom: 'Editor de planes personalizados de tratamiento según la necesidad de cada paciente.',
      remote: 'Atención remota por medio de Videollamadas encriptadas en la plataforma para mayor seguridad.',
      statistics: 'Estadísticas y monitoreo del proceso de recuperación.',
      tailor:
        '* Nos adaptamos a tu necesidad, si los videos de la plataforma no se adaptan a ti, ofrecemos grabación de vídeos a medida cuando adquieres un plan igual o superior a 6 meses.',
    },
    companyPerks: {
      surveys:
        'Surveys to identify and evaluate risk factors for musculoskeletal, vascular and psychosocial disorders.',
      plans: 'Preventive exercise plans and personalized active breaks plan.',
      programs:
        'Programs for management and education on common chronic diseases (hypertension, diabetes, obesity, COPD and/or musculoskeletal injuries).',
      appointments:
        'Access to the possibility of scheduling appointments with physiotherapists or psychologists in less than 48 hours.',
      support: 'Accompaniment for the implementation of digital wellness.',
      statistics: 'Initial report with diagnostic statistics of work-related illnesses for decision-making.',
    },
    planCard: {
      subscription: 'Subscription',
      name: {
        yearly: 'Yearly',
      },
      discount: 'Discount',
      save: 'Save',
    },
    users: {
      patient: 'Patient',
      employee: 'Employee',
      patients: 'Patients',
      employees: 'Employees',
    },
    timePeriod: {
      monthly: '(monthly)',
    },
    stepIndicator: {
      method: 'Payment method',
      detail: 'Payment details',
      confirm: 'Confirm payment',
    },
    paymentMethod: {
      useCards: 'Use your credit cards',
      choose: 'Choose payment method',
    },
    summary: {
      title: 'Payment details',
      selectedPlan: 'Selected plan',
      subtotal: 'Subtotal',
      discount: 'Discount',
      total: 'Total',
      coupon: 'Coupon',
    },
    paymentData: {
      billingInfo: 'Billing information',
      cardInfo: 'Card information',
      terms:
        'I understand and accept the <contract>end user license agreement</contract> and <privacy>privacy policy</privacy> for making this purchase.',
    },
    paymentSummary: {
      title: 'Confirm your information',
      standard: 'Standard subscription plan',
      creditCard: 'Credit card',
      button: 'Confirm and pay',
      totalPayment: 'Total payment',
      total: 'Total',
    },
    success: {
      title: 'Successful transaction',
      message:
        'Great! The payment for your plan has been successfully processed. Shortly you will receive the information of this transaction through your e-mail. If you have any questions, please do not hesitate to contact us at',
      button: 'Back to home',
      expires: 'Expires on',
    },
  },
  employees: {
    empty: {
      emptyTitle: "You don't have any employees registered in your team yet!",
      invitationLink: {
        share: 'Share the',
        link: 'invitation link',
        join: 'to get them to start joining.',
      },
    },
    tableHeader: {
      name: 'Name',
      status: 'Status',
      email: 'Email',
      activity: 'Activity',
      changeStatus: 'Active/Inactive',
    },
    invite: {
      title: 'Options for adding an employee',
      description:
        'Copy this link and share it with your team by email, intranet, slack, etc. Each team member with access to the link will get a Healthphy account to start making their work sessions more productive.',
      button: 'Copy link',
    },
    employeeSpend: {
      header: {
        employeLabel: 'Balance available in benefits',
        companyLabel: 'Amount of company allowance',
      },
      emptyInvoiceItems: {
        label: 'has no spent products yet',
      },
    },
  },
  rewards: {
    available: 'available',
    empty: {
      title: "You don't have any added rewards for your employees yet!",
      description: 'Start by creating products for your employees to claim as prizes.',
    },
    tableHeader: {
      name: 'Name',
      status: 'Status',
      stock: 'Stock',
      detail: 'Detail/Edit',
    },
    productDetail: {
      title: 'Product detail',
      name: 'Name',
      description: 'Description',
      image: 'Image',
      status: 'Status',
      price: 'Price in points',
      quantity: 'quantity',
    },
    addProduct: {
      addTitle: 'Add a new product',
      updateTitle: 'Update product',
      placeholderStatus: 'Select the status for the product',
      status: {
        active: 'Active',
        inactive: 'Inactive',
      },
    },
    button: {
      add: 'Add product',
      update: 'Update product',
    },
  },
  orders: {
    empty: {
      title: "You don't have any orders yet!",
      description: 'Stay tuned! you will see a list of created orders from your employees',
      button: 'Go to employees',
    },
    tableHeader: {
      name: 'Name',
      status: 'Status',
      employee: 'Employee',
      createdAt: 'Created at',
      detail: 'Detail/Edit',
    },
    orderDetail: {
      product: 'Product',
      quantity: 'Quantity',
      points: 'Points',
      employee: 'Employee',
      button: 'Fulfill',
    },
  },
  howToWin: {
    howto: 'How to earn points?',
    points:
      'Points are extra motivation to stay healthy. You can get them by doing your daily workouts or by enrolling to the different programs within the app.',
    whatcan: 'What can I use them for?',
    exchange:
      'You can exchange your points for many great products/items, you will find them in the "Redeem points" section at the top of the screen.',
  },
  redeemProduct: {
    title: 'Redeem Product',
    table: {
      product: 'Product',
      quantity: 'Qty.',
      points: 'Points',
    },
    resume: {
      title: 'Resume',
      currentPoints: 'Current points',
      total: 'Total',
      remainingPoints: 'Remaining points',
    },
    button: 'Redeem',
    loading: 'Loading',
    authError: 'You need to authenticate to perform this action',
    pointsError: "You don't have enough points for this item, you need ",
    more: 'more',
    successRedeem: 'Your product has been successfully redeem, enjoy it!',
    errorRedeem: 'Sorry, we are having issues with your request',
    quantityError: 'Sorry, we ran out of inventory',
  },
  createProgram: {
    step: {
      detail: 'Program Detail',
      readingTemplate: 'Reading Templates',
      audio: 'Add Audio',
    },
  },
  programs: {
    button: 'Create Program',
    duration: 'days',
  },
  companies: {
    createButton: 'Create Company',
  },
  trial: {
    ended: {
      title: 'Your trial period is over!',
      message:
        'Thank you for using and testing our platform, we hope you enjoyed the free version. To continue enjoying the services of our platform, upgrade your subscription by choosing any of our plans.',
      button: 'See plans',
    },
    period:
      'This account is in a trial period and has <span>{{trialDays}} days</span> to go, access one of our plans to upgrade your account.',
  },
  login: {
    welcome: 'Welcome to HealthPhy',
    forgot: 'Forgot your password?',
    recover: 'Password recovery',
    recoverMessage: 'Enter your email address and we will send you a link to recover access to your account.',
    access: 'Recover access to your account',
    empty: 'This link is invalid or has expired.',
  },
  tooltip: {
    selectAll: 'Select all',
    unselectAll: 'Unselect all',
  },
  forms: {
    previewTitle: 'This is how your patient will see the form',
  },
  introModal: {
    title: "Welcome to Healthphy, let's set up your account.",
    description:
      'Welcome to HealthPhy, to use the platform you need to configure your profile and login information, in the following screens we will help you to do it.',
  },
  changeStatusAlert: {
    title: 'Change Status',
    description: 'This action will change the status of <strong>{{name}}</strong>',
  },
  modalAlert: {
    updateEmployerPlan: {
      title: 'Update Plan',
      message: 'You have reached the allowed number of employees for your plan.',
    },
  },
  availability: {
    title: 'Edit time availability',
    description:
      'Configure the blocks of time per day that you have available to attend your patients on calls or to attend events.',
    weekDays: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
  },
  deletePlan: {
    title: 'This action is not reversible, are you sure?',
    description: 'This plan will be deleted, including all its content and settings.',
  },
  activePauses: {
    createButton: 'Create Active Break',
    emptyState: {
      title: 'You don`t have active breaks configured yet!',
      subtitle: 'Start by setting up your first active break for employee assessment.',
    },
  },
  createActivePause: {
    exercisesTitle: 'Search for an exercise',
    selectedExercisesTitle: 'Videos',
  },
  emptyState: {
    description:
      'Make sure the criteria is spelled correctly or try adjusting the filter to find what you are looking for.',
  },
  audioList: {
    createButton: 'Create Audio',
    emptyState: {
      title: 'You don`t have any audios configured yet!',
      description: 'Start by setting up an audio to complement your patient`s program.',
    },
    tableHeader: {
      title: 'Título',
      description: 'Descripción',
    },
  },
  invoices: {
    header: {
      amount: 'Amount due',
      dueDate: 'Due date',
      items: 'Items',
      status: 'Status',
      invoices: 'Invoices',
    },
    filters: {
      months: {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
    },
    tableHeader: {
      employee: 'Employee',
      program: 'Program',
      date: 'Date',
      amount: 'Amount',
      download: 'Download',
    },
    tableBody: {
      markAsPaidBtn: 'Mark as paid',
    },
  },
  readings: {
    editTitle: {
      label: 'Title and image for the reading',
    },
    blockTypes: {
      block: 'Block content',
      heading1: 'Heading 1',
      heading2: 'Heading 2',
      heading3: 'Heading 3',
      text: 'Text block',
      image: 'Image',
      bulletList: 'Bullet list',
      divider: 'Divider',
    },
    editor: {
      label: 'Block Editor',
      description: 'Select the type of block to add it to the reading and modify its content.',
      configLabel: 'Block configuration',
    },
    preview: {
      emptyState: 'The preview of your reading starts here.',
    },
  },
  shorVideos: {
    createBtn: 'Create short video',
    emptyState: {
      title: '¡You don´t have short videos configured yet!',
      description: 'Start by creating a short video to complement the program your employee will be taking.',
    },
  },
  eviePrograms: {
    createBtn: 'Create Evie program',
    emptyState: {
      title: 'You don´t have Evie programs configured yet!',
      description: 'Starts creating an Evie program for women´s empowerment',
    },
    steps: {
      description: 'Description',
      levels: 'Levels',
      additionalContent: 'Additional content',
    },
    detail: {
      title: 'Build your program',
      editorLabel: 'Add a program description',
    },
    levels: {
      title: 'Customize each level of your program, add as many as you need',
      addLevelBtn: 'Add Level',
      addSessionBtn: 'Add Session',
      labelLevel: 'Level',
    },
    level: {
      title:
        'Customize each session of the level, add exercises and Squeezes, define the time of their duration, you can also add sessions as needed.',
      labelSession: 'Sessionn',
      exerciseSession: 'Level {{levelPosition}} / Session {{sessionPosition}}',
      infoTitle: 'Update level information',
      blockTitle: 'Update the information of the exercise block',
    },
    session: {
      addExBlockBtn: 'Add block of exercises',
      addTaskBlockBtn: 'Add block of tasks',
      addExercise: 'Add exercises to the session',
      addSqueeze: 'Add squeezes to the session',
    },
    warning: {
      levelTitle: 'Are you sure you want to remove the level?',
      sessionTitle: 'Are you sure you want to remove the session?',
      excerciseTitle: 'Are you sure you want to remove the exercise block?',
    },
    additionalContent: {
      title: 'Customize each level of your program, add additional content as needed',
      titleLevel: 'Add additional content to the level',
      selectedItems: {
        label: 'Selection summary',
        count: '{{length}} exercises',
      },
    },
    itemsHeader: {
      readings: {
        label: 'Readings',
        description: 'Manage the readings of the level, add the ones you want according to the needs of the program',
      },
      audios: {
        label: 'Audios',
        description: 'Manage the audios of the level, add the ones you want according to the needs of the program',
      },
      shortVideos: {
        label: 'Short Videos',
        description:
          'Manage the short videos of the level, add as many as you want according to the needs of the program',
      },
      exercises: {
        label: 'Exercises',
        description: 'Manage the exercises of the level, add the blocks you want according to the needs of the program',
      },
      tasks: {
        label: 'Tasks',
        description: 'Manage the level Tasks, add the blocks you want according to the needs of the program',
      },
    },
  },
  patient: {
    personalInfo: {
      btns: {
        book: 'Book a call',
      },
      booking: {
        title: 'Book a call',
        description: 'Book a call and make a personalized follow-up to your patient`s exercise program',
      },
    },
  },
  squeezes: {
    createBtn: 'Create squeeze',
    actions: {
      release: 'Release',
      hold: 'Hold',
      squeeze: 'Squeeze',
      rest: 'Rest',
    },
    emptyState: {
      title: 'You don`t have squeezes configured yet!',
      description: 'Start by creating a squeeze to complement your patients` plans.',
    },
  },
};

import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import './modal.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    backgroundColor: '#fff',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

const ModalComponent = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  children,
  closeable = true,
  modalProps = {},
  closeOnOverlayClick,
  closeOnEsc,
}) => (
  <Modal
    shouldCloseOnOverlayClick={closeOnOverlayClick}
    shouldCloseOnEsc={closeOnEsc}
    isOpen={modalIsOpen}
    onAfterOpen={afterOpenModal}
    onRequestClose={closeModal}
    style={{ content: { ...customStyles.content, ...modalProps.content } }}
    contentLabel="Confirmación"
  >
    {closeable && (
      <div className="text-right">
        <button type="button" className="modal__close" onClick={closeModal}>
          x
        </button>
      </div>
    )}
    {children}
  </Modal>
);

ModalComponent.defaultProps = {
  afterOpenModal: () => {},
  closeOnOverlayClick: true,
  closeOnEsc: true,
};

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  afterOpenModal: PropTypes.func,
  closeOnOverlayClick: PropTypes.bool,
  closeOnEsc: PropTypes.bool,
};

export default ModalComponent;

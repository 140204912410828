import { GET_SUPER_CLINIC_PROFILE } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';

const SURVEY_TYPE = 'survey';

export const useGetSurveys = () => {
  const { data } = useAuthQuery(GET_SUPER_CLINIC_PROFILE);
  const { getSuperClinicInfo = {} } = { ...data };
  const { forms = [] } = { ...getSuperClinicInfo };
  const formatSurveyOptions = forms
    .filter(({ typeForm }) => typeForm === SURVEY_TYPE)
    .map(({ title: label, id: value }) => ({ label, value }));

  return {
    formatedOptions: formatSurveyOptions,
  };
};

import React from 'react';
import PropTypes from 'prop-types';

import 'react-toastify/dist/ReactToastify.css';
import './notifications.scss';

const notifications = {
  error: {
    defaultTitle: '¡Error!',
  },
  warning: {
    defaultTitle: 'Warning!',
  },
  success: {
    defaultTitle: 'Success!',
  },
  info: {
    defaultTitle: 'Information',
  },
};

const Notification = ({ type, messages, closeToast }) => (
  <>
    <h1 className="notifications__title">{type && notifications[type].defaultTitle}</h1>
    {messages.map((message) => (
      <p key={message} className="notifications__description">
        {message}
      </p>
    ))}
    <span className="notifications__close-btn" onClick={closeToast}>
      &times;
    </span>
  </>
);

Notification.defaultProps = {
  type: '',
  messages: [],
  closeToast: () => {},
};

Notification.propTypes = {
  type: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
  closeToast: PropTypes.func,
};

export default Notification;

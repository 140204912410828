import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IoIosArrowDown } from 'react-icons/io';
import { FaRegPlayCircle } from 'react-icons/fa';
import healtphyLogo from '../../assets/images/logo-white.svg';
import 'react-lazy-load-image-component/src/effects/blur.css'

const LibraryList = ({ part, selectedPart, selectedId, partExercises, onSelect }) => {
  return (
    <>
    	{partExercises.map((exercise) => {
    	  const { title = '', image = '', bodyParts = [], instructions, id = '' } = { ...exercise };
    	  const isSelected = selectedId === id && part === selectedPart;
    	  return (
    	    <div key={id} className={'exercise-container'}>
    	      <div className={'exercise-container__image'} onClick={() => onSelect(isSelected, {part, exercise})}>
    	        <LazyLoadImage
    	          effect={'blur'}
    	          alt={title}
    	          src={image}
    	          placeholderSrc={healtphyLogo}
    	        />
    	        <div className={'list-play-icon'}>
    	          <FaRegPlayCircle/>
    	        </div>
    	      </div>
    	      <div className={'exercise-container__info'}>
    	        <p className={'exercise-container__info-title'}>{title}</p>
    	        <ul className={`exercise-container__info-parts ${bodyParts.length > 8 && 'shrink-bdy-list'}`}>
    	          {bodyParts.map((bdPart, index) => (
    	            <li key={index}>{bdPart}</li>
    	          ))}
    	        </ul>
    	        <p className={'exercise-container__info-instructions'}>Instrucciones</p>
    	        <ol className={`${bodyParts.length > 8 && 'shrink-list'}`}>
    	          {`${instructions}`
    	            .split('- ')
    	            .filter((el) => !!el)
    	            .map((item, index) => (
    	              <li key={index}>
    	                {index + 1}{'. '}{item}
    	              </li>
    	            ))}
    	        </ol>
    	        <p 
    	        	className={'exercise-container__info-more'} 
    	        	onClick={() => onSelect(isSelected, {part, exercise})}
    	        >
    	        	Leer más{' '}<IoIosArrowDown/>
    	        </p>
    	      </div>
    	    </div>
    	  );
    	})}
    </>
  )
}

export default LibraryList;
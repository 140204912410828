import React from 'react';
import './filter-tabs.scss';

const FilterTabs = ({ filters, filterTab, handleChangeFilter }) => (
  <section className="filters">
    {(filters || []).map((item) => (
      <button
        type="button"
        key={item}
        className={`filters__filter ${item === filterTab ? 'filtered' : ''}`}
        onClick={() => handleChangeFilter(item)}
      >
        <span className={`${item === filterTab ? 'filtered' : ''}`}>{item}</span>
      </button>
    ))}
  </section>
);

export default FilterTabs;

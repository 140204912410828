import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { isEmpty, pathOr } from 'ramda';
import Loader from 'react-loader-spinner';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { addProductForm } from 'utils/validationSchema';
import showNotification from 'utils/showNotification';
import { productModalProps } from 'components/Rewards/productModalProps';
import { CREATE_UPDATE_COMPANY_PRODUCT } from 'apollo/mutations';

import { TextAreaInput, TextInput, NumberInput, FileInput, MultiSelect } from 'components/FormComponents';
import SolidButton from 'components/SolidButton';
import Modal from 'components/Modal';

import './add-product-modal.scss';

const emptyValues = {
  name: '',
  description: '',
  image: '',
  points: '',
  quantity: '',
  status: '',
};

const AddProductModal = ({ selectedProduct, modalIsOpen, toggleModal, handleRefetchProducts }) => {
  const { id: productId = '', avatar = {}, __typename = '', ...rest } = { ...selectedProduct };
  const { original = '' } = { ...avatar };
  const { t } = useTranslation();

  const statusOptions = [
    { label: t('rewards.addProduct.status.active'), value: 'active' },
    { label: t('rewards.addProduct.status.inactive'), value: 'unactive' },
  ];
  const isEmptyProduct = isEmpty(selectedProduct) && !productId && !__typename;
  const initialValues = isEmptyProduct ? emptyValues : { image: original, ...rest };

  const [spinner, setSpinner] = useState(false);
  const [createUpdateCompanyProduct] = useMutation(CREATE_UPDATE_COMPANY_PRODUCT);

  const handleAddProduct = async (values) => {
    const { image, points, quantity, ...restValues } = { ...values };
    const isFile = typeof image === 'object';
    setSpinner(true);
    const response = await createUpdateCompanyProduct({
      variables: {
        productInput: {
          points: parseInt(points, 10),
          quantity: parseInt(quantity, 10),
          ...restValues,
        },
        ...(!isEmptyProduct && {
          productId,
        }),
        ...(isFile && {
          image: image[0],
        }),
      },
    });

    const errors = pathOr([], ['data', 'createUpdateCompanyProduct', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);
    showNotification({
      type: isEmpty(errors) ? 'success' : 'error',
      messages: isEmpty(errors)
        ? [`Se ha ${isEmptyProduct ? 'añadido' : 'actualizado'} el producto exitosamente`]
        : errors,
    });
    if (emptyErrors) {
      toggleModal();
      handleRefetchProducts();
    }
  };

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={toggleModal} modalProps={productModalProps} closeable={false}>
      <AiOutlineCloseCircle onClick={toggleModal} className="add-product__close" />
      <header className="add-product__title">
        <h3>{isEmptyProduct ? t('rewards.addProduct.addTitle') : t('rewards.addProduct.updateTitle')}</h3>
      </header>
      <section className="add-product__form">
        <Formik
          initialValues={initialValues}
          onSubmit={handleAddProduct}
          validationSchema={addProductForm}
          enableReinitialize
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <Form onSubmit={handleSubmit}>
              <Field label={t('formLabel.name')} name="name" component={TextInput} />
              <div style={{ marginBottom: 20 }}>
                <Field label={t('formLabel.description')} name="description" component={TextAreaInput} minRows={20} />
              </div>
              <Field label={t('formLabel.image')} name="image" component={FileInput} />
              <Field
                type="text"
                label={t('formLabel.status')}
                name="status"
                component={MultiSelect}
                options={statusOptions}
                placeholder={t('rewards.addProduct.placeholderStatus')}
              />
              <Field label={t('formLabel.price')} name="points" component={NumberInput} />
              <Field label={t('formLabel.quantity')} name="quantity" component={NumberInput} />
              <SolidButton
                className={`add-product__button ${!isValid || isSubmitting ? 'disabled' : ''}`}
                disabled={!isValid || isSubmitting}
                type="submit"
              >
                {isEmptyProduct ? t('rewards.button.add') : t('rewards.button.update')}
              </SolidButton>
            </Form>
          )}
        </Formik>
      </section>
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={spinner} />
    </Modal>
  );
};

export default React.memo(AddProductModal);

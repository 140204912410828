import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaListUl } from 'react-icons/fa';

import Icon from 'components/Icon';

import './categories-button.scss';

const CategoriesButton = ({ showCategories, toggleCategories }) => {
  const { t } = useTranslation();
  return (
    <button type="button" className={`icon--list icon--${showCategories ? 'close' : ''}`} onClick={toggleCategories}>
      {showCategories ? <Icon name="close-categories" /> : <FaListUl />}
      <span>{t('button.categories')}</span>
    </button>
  );
};

CategoriesButton.propTypes = {
  showCategories: PropTypes.bool.isRequired,
  toggleCategories: PropTypes.func.isRequired,
};

export default CategoriesButton;

import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { CREATE_UPDATE_ACTIVE_BREAK } from 'apollo/mutations';
import { ROUTES } from 'utils/constants';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';

export const useCreateUpdActivePause = (exerciseIds, activePauseId) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const [createUpdateActiveBreak] = useMutation(CREATE_UPDATE_ACTIVE_BREAK);
  const [spinner, setSpinner] = useToggle();

  const formatedExerciseIds = exerciseIds.map((exercise, index) => ({
    exerciseId: exercise.id,
    exercisePosition: index + 1,
  }));

  const handleCreateUpdActivePause = async (values) => {
    const { title, description, tagColor, bodyParts, backgroundImage } = values;
    setSpinner();
    const response = await createUpdateActiveBreak({
      variables: {
        ...(!!activePauseId && {
          activeBreakId: activePauseId,
        }),
        ...(typeof backgroundImage === 'object' && {
          backgroundImage,
        }),
        title,
        description,
        tagColor,
        bodyParts,
        exerciseIds: formatedExerciseIds,
      },
    });

    const errors = pathOr([], ['data', 'createUpdateActiveBreak', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors
        ? [activePauseId ? t('notification.activePause.update') : t('notification.activePause.create')]
        : errors,
    });

    if (emptyErrors) {
      push(ROUTES.ACTIVE_PAUSES);
    }
  };

  return { createUpdActivePause: handleCreateUpdActivePause, spinner };
};

import React from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import FieldCard from './FieldCard';
import GroupTitle from './GroupTitle';

const GroupsList = ({ group, index, onRemoveInput, handleInput, handleClone, updateGroupTitle, surveyType }) => (
  <Draggable draggableId={group.id} index={index}>
    {(provided) => (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        {surveyType === 'grouped' && (
          <GroupTitle title={group.groupTitle} groupId={group.id} updateGroupTitle={updateGroupTitle} />
        )}
        <Droppable droppableId={group.id}>
          {(providedCards) => (
            <ul className="group-container" ref={providedCards.innerRef} {...providedCards.droppableProps}>
              {group.questions.map((question, questionIndex) => (
                <FieldCard
                  key={question.id}
                  index={questionIndex}
                  question={question}
                  groupId={group.id}
                  onRemoveInput={onRemoveInput}
                  handleInput={handleInput}
                  handleClone={handleClone}
                />
              ))}
              {providedCards.placeholder}
            </ul>
          )}
        </Droppable>
      </div>
    )}
  </Draggable>
);

GroupsList.defaultProps = {
  group: {},
  onRemoveInput: () => {},
  handleInput: () => {},
  handleClone: () => {},
  updateGroupTitle: () => {},
};

GroupsList.propTypes = {
  index: PropTypes.number.isRequired,
  group: PropTypes.shape({
    id: PropTypes.string,
    groupTitle: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.object),
  }),
  onRemoveInput: PropTypes.func,
  handleInput: PropTypes.func,
  handleClone: PropTypes.func,
  updateGroupTitle: PropTypes.func,
  surveyType: PropTypes.string.isRequired,
};

export default GroupsList;

import React, { useRef, useState } from 'react';
import healtphyLogo from '../../assets/images/logo-blue.svg';
import { useAppState } from '../StoreProvider';
import ReactTooltip from 'react-tooltip';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import { Rnd } from 'react-rnd';
import { Video, Mic, VideoOff, MicOff, Phone } from 'react-feather';
import { AiOutlineFullscreenExit, AiOutlineFullscreen } from 'react-icons/ai';
import { MdPublish } from 'react-icons/md';
import './videocall.scss';

const openTokApiKey = process.env.REACT_APP_OPENTOK;

const initialCallDimensions = { width: 380, height: 300, x: 0, y: 50 };

const VideoCall = () => {
  const [mic, setMic] = useState(true);
  const [video, setVideo] = useState(true);
  const [isPublishScreen, setIsPublishScreen] = useState(false);
  const [callDimensions, setCallDimensions] = useState(initialCallDimensions);
  const sessionRef = useRef();
  const publisherRef = useRef();
  const publisherScreenRef = useRef();
  const subscriberRef = useRef();
  const { setStoreData, openCall } = useAppState();
  const { isOpen, sessionId = '', token = '' } = { ...openCall };
  const iconProps = { size: 20, color: '#fff' };
  const isCenter = parseInt(callDimensions.width) > 720;
  const fullWIdth = window.innerWidth;

  const onDisconnect = () => {
    const values = { isOpen: false, sessionId: '', token: '' };
    if (!!sessionRef && !!sessionRef.current && !!sessionRef.current.sessionHelper) {
      sessionRef.current.sessionHelper.disconnect();
    }
    if (isPublishScreen) {
      toggleScreenShare();
    }
    setStoreData('openCall', values);
    setCallDimensions(initialCallDimensions);
    setMic(true);
    setVideo(true);
  };

  const toggleMic = () => {
    if (!!publisherRef) {
      const { state = {} } = { ...publisherRef.current };
      const { publisher = {} } = { ...state };
      if (publisher) {
        publisher.publishAudio(!mic);
        setMic(!mic);
      }
    }
  };

  const toggleVideo = () => {
    if (!!publisherRef) {
      const { state = {} } = { ...publisherRef.current };
      const { publisher = {} } = { ...state };
      if (publisher) {
        publisher.publishVideo(!video);
        setVideo(!video);
      }
    }
  };

  const toggleScreenShare = () => {
    setIsPublishScreen(prevState => !prevState);
  };

  const toggleSize = () => {
    if (parseInt(callDimensions.width) < fullWIdth) {
      setCallDimensions({ 
        ...callDimensions, 
        width: window.innerWidth, 
        height: window.innerHeight, 
        x: 0, 
        y: 0 
      });
      document.documentElement.scrollTop = 0;
    } else {
      setCallDimensions(initialCallDimensions);
    }
  };

  const publisherScreenEventHandlers = {
    accessDenied: () => {
      console.log("User denied access to media Screen source");
      onDisconnect();
    },
    streamCreated: () => {
      console.log("Publisher SCreen created");
      const { state = {} } = { ...publisherScreenRef.current };
      const { publisher = {} } = { ...state };
      if (publisher) {
        publisher.publishAudio(false);
      }
    },
    mediaStopped: (event) => {
      toggleScreenShare();
    },
    streamDestroyed: (event) => {
      console.log(`Publisher Screen destroyed because: ${event.reason}`);
    },
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Rnd
      style={{ 
        zIndex: '2147483647', 
        border: 'solid 1px #ddd', 
        background: '#f0f0f0',
      }}
      size={{ width: callDimensions.width, height: callDimensions.height }}
      position={{ x: callDimensions.x, y: callDimensions.y }}
      minWidth={380}
      minHeight={300}
      maxWidth={isPublishScreen ? 380 : window.innerWidth}
      maxHeight={isPublishScreen ? 300 : window.innerHeight}
      bounds={'window'}
      onDragStop={(e, d) => {
        setCallDimensions({ ...callDimensions, x: d.x, y: d.y });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        setCallDimensions({
          ...position,
          width: ref.style.width,
          height: ref.style.height,
        });
      }}>
      <div className={'videocall__container'}>
        {token && (
          <OTSession
            ref={sessionRef} 
            apiKey={openTokApiKey} 
            sessionId={sessionId} 
            token={token} 
          >
            {isPublishScreen && (
              <OTPublisher
                style={{ position: 'absolute', bottom: 5, left: 5 }}
                properties={{ videoSource: 'screen', width: 120, height: 80, showControls: false, fitMode: 'cover' }}
                eventHandlers={publisherScreenEventHandlers}
                ref={publisherScreenRef}
              />
            )}
            <OTPublisher
              style={{ position: 'absolute', bottom: 5, right: 5 }}
              properties={{ showControls: false }} 
              ref={publisherRef} 
            />
            <OTStreams>
              <OTSubscriber
                ref={subscriberRef}
                style={{
                  position: 'absolute',
                  width: callDimensions.width,
                  height: callDimensions.height,
                  ...(isPublishScreen && {
                    width: 180,
                    height: 130,
                    border: '2px solid #fff',
                    borderRadius: 8,
                    right: 5,
                    top: 30
                  })
                }}
                properties={{
                  showControls: false,
                }}
              />
            </OTStreams>
          </OTSession>
        )}
        <div className={'full-screen-button'} onClick={toggleSize}>
          { parseInt(callDimensions.width) < fullWIdth ? <AiOutlineFullscreen/> : <AiOutlineFullscreenExit/>}
        </div>
        <img className='videocall__logo' src={healtphyLogo} alt='logo' />
        <div className={`videocall__buttons videocall__buttons--${!isCenter && 'small'}`}>
          <div className={`control-button control-button--${!isCenter && 'small'}`} onClick={toggleVideo}>
            {video ? <VideoOff {...iconProps} /> : <Video {...iconProps} />}
          </div>
          <div className={`hang-button hang-button--${!isCenter && 'small'}`} onClick={onDisconnect}>
            <Phone size={28} color={'#ff'} className={'icon'} />
          </div>
          <div className={`control-button control-button--${!isCenter && 'small'}`} onClick={toggleMic}>
            {mic ? <MicOff {...iconProps} /> : <Mic {...iconProps} />}
          </div>
        </div>
        <div 
          onClick={toggleScreenShare}
          data-tip={!isPublishScreen ? 'Compartir pantalla' : 'Dejar de compartir'}
          data-for={'share'}
          className={'share-button'}
        >
          <ReactTooltip place={'right'} effect={'solid'} id={'share'} /> 
          <MdPublish/>
        </div>
      </div>
    </Rnd>
  );
};

export default VideoCall;

import { GET_COMPANIES } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetCompanies = () => {
  const { data: dataCompanies } = useAuthQuery(GET_COMPANIES);
  const { companies: allCompanies = [] } = { ...dataCompanies };
  const formatCompanyOptions = allCompanies.map(({ name: label, id: value }) => ({ value, label }));

  return { formatedCompanies: formatCompanyOptions };
};

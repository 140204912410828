import React, { useState, useEffect } from 'react';
import { propOr } from 'ramda';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Info } from 'react-feather';
import { useTranslation, Trans } from 'react-i18next';

import { useAuthQuery } from 'utils/hooks';
import { ROUTES, PAYMENT_MODE, FETCH_BY_ROLE, GET_PARTNER_BY_ROLE } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';

import TrialModal from 'components/Trial';

const TrialBanner = () => {
  const { t } = useTranslation();
  const { userRole, isClinic } = getUserRole();
  const { data, refetch, loading } = useAuthQuery(FETCH_BY_ROLE[userRole]);
  const partner = propOr({}, GET_PARTNER_BY_ROLE[userRole])(data);
  const {
    tcAcceptedAt = new Date(),
    trialDaysLeft: daysLeft = (isClinic ? 30 : 15) - (moment().diff(moment(tcAcceptedAt), 'days') + 1),
    trialActive = true,
    availabilities = [],
    profile = {},
  } = { ...partner };
  const { paymentMode } = { ...profile };
  const isSubscription = paymentMode === PAYMENT_MODE.SUBSCRIPTION;

  const [trial, setTrial] = useState({ trialDays: -1, availabilities: 0 });
  const [expiredTrial, setExpiredTrial] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isSubscription) {
      setTrial({ trialDays: trialActive ? daysLeft : -1, availabilities: availabilities.length });
      setExpiredTrial(trialActive && daysLeft <= 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysLeft, trialActive, isSubscription]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !isSubscription) return null;

  return (
    <>
      {trial.trialDays > 0 && (
        <div className="d-flex trial-banner">
          <Info size={18} color="#fff" />
          <p className="banner-content">
            <Trans values={{ trialDays: trial.trialDays }} components={{ span: <span /> }}>
              trial.period
            </Trans>
          </p>
          <button type="button" className="pay-button" onClick={() => history.replace(ROUTES.BILLING)}>
            {t('button.update')}
          </button>
        </div>
      )}
      {trial.availabilities === 0 && isClinic && (
        <div className="d-flex trial-banner availabilities-banner">
          <Info size={18} color="#000" />
          <div className="banner-content">
            Aún no has establecido la disponibilidad horaria para atender llamadas, tus pacientes no podran agendar
            hasta que lo realices.
          </div>
          <button type="button" className="pay-button" onClick={() => history.replace(ROUTES.CALENDAR)}>
            Configurar
          </button>
        </div>
      )}
      <TrialModal open={expiredTrial} />
    </>
  );
};

export default TrialBanner;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { propOr } from 'ramda';

import { getTodayDate } from 'utils/getTodayDate';
import { capitalize } from 'utils/string';
import { getUserRole } from 'utils/getUserRole';
import { useAuthQuery } from 'utils/hooks';
import { ROLES, FETCH_NOTI_AND_THUMB_BY_ROLE, GET_PARTNER_BY_ROLE, PAYMENT_MODE } from 'utils/constants';

import ConversationNotifications from 'components/conversations/ConversationNotifications';
import Notifications from '../Notifications';
import Navigation from '../Navigation';

import './header.scss';

const Header = ({ title, shadow }) => {
  const { userRole, isSuperClinic } = getUserRole();

  const { data, refetch, loading } = useAuthQuery(FETCH_NOTI_AND_THUMB_BY_ROLE[userRole]);
  const partner = propOr({}, GET_PARTNER_BY_ROLE[userRole])(data);
  const { termsConditions = false, profile = {}, notifications = [] } = { ...partner };
  const { avatar = {}, id = '', paymentMode } = { ...profile };
  const { thumb = '' } = { ...avatar };
  const isSubscription = paymentMode === PAYMENT_MODE.SUBSCRIPTION;

  const {
    i18n: { language },
  } = useTranslation();
  const [day, month, year = ''] = getTodayDate(language);

  return (
    <>
      <header className={`header ${shadow ? 'header-shadow' : ''}`}>
        <div className="header__title__container">
          <h1 className="header__title">{title}</h1>
          <p className="header__date">
            <span>{capitalize(day)}</span>,{month}
            {year ? `, ${year}` : ''}
          </p>
        </div>
        <div className="header__title__navigation">
          <div className="header__title__navigation__container">
            {[ROLES.CLINIC, ROLES.SUPER_CLINIC].includes(userRole) && (
              <Notifications notifications={notifications} refetch={refetch} />
            )}
            {isSuperClinic && <ConversationNotifications />}
            <Navigation
              thumb={thumb}
              id={id}
              termsConditions={termsConditions}
              isSubscription={isSubscription}
              loading={loading}
              refetch={refetch}
            />
          </div>
        </div>
      </header>
    </>
  );
};

Header.defaultProps = {
  shadow: false,
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
};

export default Header;

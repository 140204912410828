import React from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';
import { propOr } from 'ramda';
import { getIn } from 'formik';

const TextAreaInput = ({
  field,
  form,
  label,
  isRequired,
  minRows,
  maxRows,
  labelClassName = 'form__label',
  className,
  isFSblocked,
  ...props
}) => {
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error;
  const invalidClass = hasError ? 'is-invalid' : '';
  const fsClass = isFSblocked ? 'fs-block' : '';

  return (
    <>
      {label && (
        <label htmlFor={name} className={`form__label ${labelClassName}`}>
          {label} {isRequired && <span className='text-pink'>*</span>}
        </label>
      )}
      <Textarea
        className={`form-control ${className} ${fsClass} ${invalidClass}`}
        {...props}
        {...field}
        id={name}
        minRows={minRows}
        maxRows={maxRows}
      />
      {/*hasError && <div className='form__error--area'>{error}</div>*/}
    </>
  );
};

TextAreaInput.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  isRequired: PropTypes.bool,
  isFSblocked: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
};

TextAreaInput.defaultProps = {
  className: '',
  label: '',
  labelClassName: '',
  isRequired: false,
  isFSblocked: false,
  minRows: 1,
  maxRows: 3,
};

export default TextAreaInput;

import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { path, pathOr, propOr, compose, head, isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';

import { GET_PRODUCT_INFO } from 'apollo/queries';
import { CREATE_ORDER } from 'apollo/mutations';
import { queryParams } from 'utils/string';
import { useToggle } from 'utils/hooks/useToggle';
import showNotification from 'utils/showNotification';

import Button from 'components/Button';

import noReadings from 'assets/images/error.png';

import './redeem-product.scss';

const RedeemProduct = (props) => {
  const { t } = useTranslation();
  const [token, setToken] = useState(null);
  const [loadingToken, toggleLoadingToken] = useToggle(true);
  const [isSuccess, toggleIsSuccess] = useToggle(false);
  const [redeeming, toggleRedeeming] = useToggle(false);
  const search = path(['history', 'location', 'search'])(props);
  const params = { ...queryParams(search) };
  const { productId } = { ...params };
  const [getProductInfo, { loading: loadingData, error, data }] = useLazyQuery(GET_PRODUCT_INFO);
  const [createOrder] = useMutation(CREATE_ORDER);

  const errorMessage = compose(propOr('', 'message'), head, propOr([], 'graphQLErrors'))(error);
  const dataErrorArray = path(['getProductInfo', 'errors'])(data);
  const dataErrorPoints = dataErrorArray?.[0]?.includes('points') ? dataErrorArray?.[1] : '';

  const employeePoints = pathOr(0, ['getProductInfo', 'employee_points'])(data);
  const productPoints = pathOr(0, ['getProductInfo', 'product', 'points'])(data);
  const productName = pathOr('', ['getProductInfo', 'product', 'name'])(data);
  const productQuantity = pathOr('', ['getProductInfo', 'product', 'quantity'])(data);
  const productImage = pathOr('', ['getProductInfo', 'product', 'avatar', 'url'])(data);

  const handleMessage = ({ data: { token: newToken } }) => {
    if (newToken) {
      setToken(newToken);
      getProductInfo({
        variables: { productId },
        context: {
          headers: {
            authorization: `Bearer ${newToken}`,
            role: 'employee',
          },
        },
      });
      toggleLoadingToken();
    }
  };

  useEffect(() => {
    // Android support
    document.addEventListener('message', handleMessage);

    // iOS support
    window.addEventListener('message', handleMessage);

    return () => {
      document.removeEventListener('message', handleMessage);
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleRedeemProduct = async () => {
    toggleRedeeming();
    const response = await createOrder({
      variables: {
        productId,
        quantity: 1,
      },
      context: {
        headers: {
          authorization: `Bearer ${token}`,
          role: 'employee',
        },
      },
    });
    const errors = pathOr([], ['data', 'createOrder', 'errors'])(response);
    toggleRedeeming();
    showNotification({
      type: isEmpty(errors) ? 'success' : 'error',
      messages: isEmpty(errors) ? [t('redeemProduct.successRedeem')] : [t('redeemProduct.errorRedeem')],
    });
    if (isEmpty(errors)) toggleIsSuccess();
  };

  return (
    <div className="redeem-product">
      <div className="redeem-product__wrapper">
        <h2 className="redeem-product__title">{t('redeemProduct.title')}</h2>
        {loadingToken || loadingData ? (
          <p>{t('redeemProduct.loading')}...</p>
        ) : errorMessage || dataErrorPoints ? (
          <>
            <img className="redeem-product__error-img" src={noReadings} alt="error" />
            <p>
              {dataErrorPoints
                ? `${t('redeemProduct.pointsError')} ${Math.round(Number(dataErrorPoints))} ${t('redeemProduct.more')}`
                : t('redeemProduct.authError')}
              .
            </p>
          </>
        ) : isSuccess ? (
          <>
            <img className="redeem-product__image" src={productImage} alt={productName} />
            <p>{t('redeemProduct.successRedeem')}</p>
          </>
        ) : (
          <>
            <div className="redeem-product__card">
              <table className="redeem-product__table">
                <thead>
                  <tr>
                    <th></th>
                    <th>{t('redeemProduct.table.product')}</th>
                    <th>{t('redeemProduct.table.quantity')}</th>
                    <th>{t('redeemProduct.table.points')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img className="redeem-product__image" src={productImage} alt={productName} />
                    </td>
                    <td>
                      <div>
                        <span className="redeem-product__product-name">{productName}</span>
                        <span className="redeem-product__product-value">
                          {productPoints} {t('redeemProduct.table.points')}
                        </span>
                      </div>
                    </td>
                    <td>1</td>
                    <td>{productPoints}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="redeem-product__card">
              <h3 className="redeem-product__subtitle">{t('redeemProduct.resume.title')}</h3>
              <div className="redeem-product__resume-item">
                <b>{t('redeemProduct.resume.currentPoints')}</b>
                <span>{Math.round(Number(employeePoints))}</span>
              </div>
              <div className="redeem-product__resume-item">
                <b>{t('redeemProduct.resume.total')}</b>
                <span>{Math.round(Number(productPoints))}</span>
              </div>
              <div className="redeem-product__resume-item">
                <b>{t('redeemProduct.resume.remainingPoints')}</b>
                <span>{Math.round(Number(employeePoints) - Number(productPoints))}</span>
              </div>
            </div>
            <Button
              className="redeem-product__button"
              onClick={handleRedeemProduct}
              disabled={redeeming || !productQuantity}
            >
              {redeeming ? t('redeemProduct.loading') : t('redeemProduct.button')}
            </Button>
            {!productQuantity && <p className="redeem-product__error">{t('redeemProduct.quantityError')}.</p>}
          </>
        )}
      </div>
    </div>
  );
};

export default RedeemProduct;

import React from 'react';
import PropTypes from 'prop-types';

import './button.scss';

const Button = ({ children, type, className, category, onClick, disabled }) => (
  <button type={type} className={`button button--${category} ${className}`} onClick={onClick} disabled={disabled}>
    {children}
  </button>
);

Button.defaultProps = {
  type: 'button',
  className: '',
  category: 'primary',
  disabled: false,
  onClick: () => {},
};

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  category: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Button;

import React from 'react';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import './TeamMembersList.scss';

const TeamMembersList = ({ memoSortedMembers, teamMembers, getTeamMember }) => {

  const isLength = memoSortedMembers.length < 5;

  return (
    <div className={`team team--${isLength && 'small'}`}>
      {(teamMembers || []).map((member) => {
        const { id = '', name = '', email = '' } = { ...member };
        return (
          <div key={id} className={'team__member'}>
            <div>
              <p className={'team__member_name'}>{name}</p>
              <p className={'team__member_email'}>{email}</p>  
            </div>
            <div className={'team__member__icons'}>
              <FaRegTrashAlt onClick={() => getTeamMember(member, 'delete')} className={'team__member__icons_icon'}/>
              <FaRegEdit onClick={() => getTeamMember(member, 'update')} className={'team__member__icons_icon'}/>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TeamMembersList;
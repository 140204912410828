import React, { useState, useEffect } from 'react';
import Preview from '../Preview';
import LogoDropzone from '../LogoDropzone';
import Modal from '../../Modal';
import SolidButton from '../../SolidButton';
import { isEmpty, pathOr } from 'ramda';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { TwitterPicker } from 'react-color';
import Loader from 'react-loader-spinner';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_CLINIC_WHITE_LABEL } from '../../../apollo/mutations';
import showNotification from '../../../utils/showNotification';

const modalProps = {
  content: {
    width: '95%',
    padding: '0',
    maxWidth: '1520px',
    maxHeight: '98vh',
  },
};

const initialColors = {
  primary: '#495FD7',
  secondary: '#8093FC',
  terciary: '#46C2AD',
};

const primaryColors = ['#495FD7', '#FF6900', '#FCB900', '#7BDCB5', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF'];
const secondaryColors = ['#8093FC', '#FF6900', '#FCB900', '#7BDCB5', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF'];
const terciaryColors = ['#46C2AD', '#FF6900', '#FCB900', '#7BDCB5', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF'];

const WhiteLabelModal = ({ partnerInfo, refetch, whiteLabelModalIsOpen, toggleWhiteLabelModal }) => {

  const { profile = {} } = { ...partnerInfo };
  const { colors: colorsFromDataBase = {} } = { ...profile };

  const [colors, setColors] = useState(initialColors);
  const [files, setFiles] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const [updateClinicWhiteLabel] = useMutation(UPDATE_CLINIC_WHITE_LABEL);

  useEffect(() => {
    if (!isEmpty(colorsFromDataBase) && whiteLabelModalIsOpen) {
      setColors((prev) => ({ ...prev, ...colorsFromDataBase }));
    }
    if (!whiteLabelModalIsOpen) {
      setFiles([]);
    }
  }, [colorsFromDataBase, whiteLabelModalIsOpen]);

  const onUpdateClinicWhiteLabel = async (colors, files) => {
    setSpinner(true);
    const [ avatar ] = [ ...files ];
    const response = await updateClinicWhiteLabel({
      variables: {
        colors,
        ...(!isEmpty(files) && {
          avatar,
        }), 
      },
    });
    const errors = pathOr([], ['data', 'updateClinicWhiteLabel', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha personalizado tu marca exitosamente.`] : errors,
    });

    if (emptyErrors) {
      refetch();
      setFiles([]);
      toggleWhiteLabelModal();
    }
  };

  const handleChangeColor = (color, category) => {
    setColors((prev) => ({ ...prev, [category]: color }));
  };

  const handleAcceptedFiles = (acceptedFiles) => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  };

  return (
    <Modal modalIsOpen={whiteLabelModalIsOpen} closeModal={toggleWhiteLabelModal} closeable={false} modalProps={modalProps}>
      <div className={'close-white-label-modal'}>
        <AiOutlineCloseCircle onClick={toggleWhiteLabelModal}/>
      </div>
      <section className={'edit-zone'}>
        <section className={'edit-zone__preview'}>
          <Preview colors={colors}/>
        </section>
        <section className={'edit-zone__picker'}>
          <section className={'edit-zone__picker__title'}>
            <p>Personaliza tu marca</p>
            <p>Configura tus colores y tu logo para mantener la identidad de marca en el diseño de la app móvil.</p>
          </section>
          <section className={'edit-zone__picker__colors'}>
            <div style={{marginBottom: 20}}>
              <TwitterPicker
                triangle={'top-right'} 
                colors={primaryColors} 
                color={colors.primary} 
                onChange={color => handleChangeColor(color.hex, 'primary')}
              /> 
            </div>
            <div style={{marginBottom: 20}}>
              <TwitterPicker 
                triangle={'top-right'} 
                colors={secondaryColors} 
                color={colors.secondary} 
                onChange={color => handleChangeColor(color.hex, 'secondary')}
              />
            </div>
            <div style={{marginBottom: 20}}>
              <TwitterPicker 
                triangle={'top-right'} 
                colors={terciaryColors} 
                color={colors.terciary} 
                onChange={color => handleChangeColor(color.hex, 'terciary')}
              />
            </div>  
          </section>
          <LogoDropzone partnerInfo={partnerInfo} files={files} handleAcceptedFiles={handleAcceptedFiles}/>
          <SolidButton
            onClick={() => onUpdateClinicWhiteLabel(colors, files)}
            className={'whitelabel-update'}
          >
            Actualizar
          </SolidButton>
        </section>
      </section>
      <Loader type={'ThreeDots'} color={'#495fd7'} height={100} width={100} className={'spinner'} visible={spinner} />
    </Modal>
  );
}

export default WhiteLabelModal;
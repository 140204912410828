import React from 'react';
import moment from 'moment';
import { capitalize } from '../../utils/string';
import EventCard from './EventCard';

moment.locale = 'es';
const DayView = (props) => {
  const { current = moment(), events = [], changeRoute } = { ...props };
  const currentLabel = capitalize(current.format('MMMM DD, YYYY'));

  const generateHours = () => {
    const items = [];
    new Array(24).fill().forEach((acc, index) => {
      items.push(moment({ hour: index }).format('h:mm A'));
    });
    return items;
  };

  const hours = generateHours();
  return (
    <div style={{ display: 'flex', minHeight: '85%' }}>
      <div className={'day-view'}>
        <div className={'day-view-title'}>
          <span>
            <b>{currentLabel}</b>
          </span>
        </div>
        <div className={'day-view-body'}>
          <>
            {hours.map((hour, index) => {
              return (
                <React.Fragment key={index}>
                  <span className={'day-view-item hour'}>{hour}</span>
                  <span key={index} className={`day-view-item`}>
                    {events
                      .filter(({ startAt, endAt, type }) => {
                        const eventI = moment(`${moment(current).format('DD/MM/YYYY')} ${hour}`, 'DD/MM/YYYY h:mm A');
                        const isEnd =
                          type === 'event'
                            ? eventI.isBefore(moment(endAt || startAt).startOf('hour'))
                            : eventI.isSameOrBefore(moment(endAt || startAt).startOf('hour'));
                        return (
                          moment(startAt).format('YYYYMMDD') === moment(current).format('YYYYMMDD') &&
                          eventI.isSameOrAfter(moment(startAt).startOf('hour')) &&
                          isEnd
                        );
                      })
                      .map((event) => (
                        <EventCard event={event} changeRoute={changeRoute} />
                      ))}
                  </span>
                </React.Fragment>
              );
            })}
          </>
        </div>
      </div>
    </div>
  );
};
export default DayView;

import React, { useContext, createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';

import { queryParams } from 'utils/string';
import {
  createActivePauseReducer,
  useGetExercises,
  useGetBodyParts,
  useCreateUpdActivePause,
  useGetActivePause,
  ACTIONS,
} from './service';

const initialState = {
  title: '',
  description: '',
  tagColor: '#46c2ad',
  backgroundImage: '',
  bodyParts: [],
  exerciseIds: [],
};

const CreateActivePauseContext = createContext();

const CreateActivePauseProvider = ({ children }) => {
  const {
    location: { search },
  } = useHistory();
  const { id: activePauseId } = queryParams(search);
  const { loading, exercises } = useGetExercises();
  const { bodyParts } = useGetBodyParts();
  const { isLoading: loadingActivePause, activePause } = useGetActivePause(activePauseId);

  const { title, description, tagColor, backgroundImage = {}, bodyParts: activePauseParts, day = {} } = activePause;
  const { thumb } = backgroundImage;
  const { dayExercises = [] } = day;
  const formatedExercises = dayExercises.map((dayExercise) => {
    const {
      exercise: { id, title: exTitle, image },
    } = dayExercise;
    return { id, title: exTitle, image };
  });

  const updatedActivePauseState = {
    title,
    description,
    tagColor,
    backgroundImage: thumb,
    bodyParts: activePauseParts,
    exerciseIds: formatedExercises,
  };

  const [state, dispatch] = useReducer(createActivePauseReducer, initialState);
  const { exerciseIds } = state;

  useEffect(() => {
    if (!isEmpty(activePause)) {
      dispatch({ type: ACTIONS.SET_ACTIVE_PAUSE, payload: updatedActivePauseState });
    }
  }, [activePause]);

  const { createUpdActivePause, spinner } = useCreateUpdActivePause(exerciseIds, activePauseId);

  const isLoading = loading || loadingActivePause || spinner;

  return (
    <CreateActivePauseContext.Provider
      value={{
        activePauseId,
        isLoading,
        exercises,
        bodyParts,
        exerciseIds,
        activePauseState: state,
        dispatch,
        createUpdActivePause,
      }}
    >
      {children}
    </CreateActivePauseContext.Provider>
  );
};

CreateActivePauseProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useCreateActivePauseContext() {
  const context = useContext(CreateActivePauseContext);
  if (!context) {
    throw new Error('Expected an CreateActivePauseContext somewhere in the react tree to set context value');
  }
  return context;
}

export { CreateActivePauseProvider, useCreateActivePauseContext };

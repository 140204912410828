import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty, pathOr } from 'ramda';

import { CREATE_UPDATE_READING_TEMPLATE } from 'apollo/mutations';
import { ROUTES } from 'utils/constants';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';

export const useCreateUpdateReadingTemplate = (template) => {
  const { push } = useHistory();
  const [createReadingTemplate] = useMutation(CREATE_UPDATE_READING_TEMPLATE);
  const [spinner, setSpinner] = useToggle();

  const handleCreateEditTemplate = async (values) => {
    const { id: templateId, readings = [] } = { ...template };
    const getReadingIds = readings.map(({ id }) => id);
    setSpinner();
    const response = await createReadingTemplate({
      variables: {
        ...values,
        readingIds: getReadingIds,
        ...(!!templateId && {
          templateId,
        }),
      },
    });

    const errors = pathOr([], ['data', 'createReadingTemplate', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors
        ? [`Se ha ${templateId ? 'actualizado' : 'creado'} la plantilla de lecturas exitosamente.`]
        : errors,
    });

    if (emptyErrors) {
      push(ROUTES.TEMPLATES);
    }
  };

  return { createEditTemplate: handleCreateEditTemplate, spinner };
};

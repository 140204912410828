import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty, equals, isNil } from 'ramda';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { IoMdArrowBack } from 'react-icons/io';
import { Fade } from 'react-awesome-reveal';

import { queryParams } from 'utils/string';
import { ROUTES } from 'utils/constants';
import evieLogo from 'assets/images/evie-logo-qs.png';

import FormControlSurveys from 'components/Surveys/FormControlSurveys';
import Button from 'components/SolidButton';

const Groups = ({ survey, handleSubmit }) => {
  const { content: groups = [] } = { ...survey };
  const { t } = useTranslation();
  const {
    push,
    goBack,
    location: { search },
  } = useHistory();
  const { survey_id: surveyId, group, step } = queryParams(search);
  const changeType = (value) => Number(value);
  const groupNumber = changeType(group);
  const stepNumber = changeType(step);

  const [queryGroups, setQueryGroups] = useState(groups);
  const [currentStep, setCurrentStep] = useState(0);

  const totalSteps = groups.reduce((acc, curr) => acc + curr.questions.length, 0);
  const percentageCompleted = (currentStep * 100) / totalSteps;

  const currentQuestion = groups
    .find((_, gIndex) => gIndex + 1 === groupNumber)
    .questions.find((_, qIndex) => qIndex + 1 === stepNumber);
  const isInfoScreen = currentQuestion.type === 'text-image';

  useEffect(() => {
    const isLastQuestion = groups[groups.length - 1].questions.length === stepNumber;
    if (groupNumber === queryGroups.length && isLastQuestion && !equals(groups, queryGroups)) {
      handleSubmit(queryGroups);
    }
  }, [group, queryGroups]);

  const initialFormState = (id) => {
    const findGroupToValidate = queryGroups.find(({ id: validateId }) => validateId === id);
    const initial = {};
    findGroupToValidate.questions.forEach((question) => {
      const { id: questionId = '', type = 'text', label = '', value = '', multi } = { ...question };

      switch (type) {
        case 'verification':
          initial[questionId] = false || value;
          break;
        case 'block':
          initial[questionId] = label;
          break;
        case 'multiple-selection':
          initial[questionId] = multi ? value.split(', ').filter(Boolean) || '' : '' || value;
          break;
        case 'pointed-multiple-selection':
          initial[questionId] = '' || value.label;
          break;
        default:
          initial[questionId] = '' || value;
      }
    });
    if (findGroupToValidate.files) {
      findGroupToValidate.files.forEach(({ file, questionId }) => {
        initial[questionId] = file;
      });
    }
    return initial;
  };

  const getValue = ({ id: valueId, options, values }) => {
    if (Array.isArray(values[valueId])) {
      return values[valueId].join(', ');
    }
    if (['object'].includes(typeof options[0])) {
      return options.find(({ label }) => label === values[valueId]) || '';
    }
    return isNil(values[valueId]) ? '' : values[valueId];
  };

  const handleAddGroup = (values, groupId) => {
    const currentGroup = groups.find(({ id: queryGroupId }) => queryGroupId === groupId);
    setQueryGroups((prevQueryGroups) =>
      prevQueryGroups.map((currGroup) => {
        if (currGroup.id === groupId) {
          return {
            ...currGroup,
            questions: currGroup.questions
              .filter(({ type }) => type !== 'file')
              .map((question) => ({
                ...question,
                value: getValue({ id: question.id, options: question.options || [], values }),
              })),
            files: currentGroup.questions
              .filter(({ type, id }) => type === 'file' && !isEmpty(values[id]) && values[id] !== undefined)
              .map(({ id: fileId, label }) => ({
                groupId: currGroup.id,
                label,
                file: values[fileId],
              })),
          };
        }
        return currGroup;
      }),
    );
    setCurrentStep((prev) => prev + 1);
    push(`${ROUTES.SURVEYS}?survey_id=${surveyId}&group=${groupNumber + 1}&step=1`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleBack = ({ groupNumber: currGroup, stepNumber: currStep, prevGroup }) => {
    const goPrevious = currStep === 1;
    const newGroup = goPrevious ? currGroup - 1 : currGroup;
    const newStep = goPrevious ? prevGroup.questions.length : currStep - 1;

    setCurrentStep((prev) => prev - 1);
    goBack(`${ROUTES.SURVEYS}?survey_id=${surveyId}&group=${newGroup}&step=${newStep}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const questionhandler = ({ goNextGroup, submitForm }) => {
    if (goNextGroup) {
      submitForm();
    } else {
      setCurrentStep((prev) => prev + 1);
      push(`${ROUTES.SURVEYS}?survey_id=${surveyId}&group=${group}&step=${stepNumber + 1}`);
    }
  };

  return (
    <section className={`sections ${isInfoScreen ? 'info-screen' : ''}`}>
      {(stepNumber > 1 || groupNumber > 1) && (
        <IoMdArrowBack
          className={`evie-secondary sections__goback ${isInfoScreen ? 'info-screen' : ''}`}
          onClick={() =>
            handleBack({
              groupNumber,
              stepNumber,
              prevGroup: groups[groupNumber - 2],
            })
          }
        />
      )}
      {!isInfoScreen && (
        <>
          <div className="sections__logo">
            <img src={evieLogo} alt="evie logo" />
          </div>
          <div className="sections__progress">
            <div className="sections__completed" style={{ width: `${percentageCompleted}%` }} />
          </div>
        </>
      )}
      {groups.map((groupInfo, groupIndex) => {
        const { id = '', questions = [] } = { ...groupInfo };
        const initialValues = initialFormState(id);

        if (groupIndex + 1 !== groupNumber) return null;

        return (
          <div key={id} className="sections__form">
            <Formik
              validationSchema={null}
              initialValues={initialValues}
              onSubmit={(values) => handleAddGroup(values, id)}
            >
              {(formData) => (
                <Form>
                  {questions.map((question, index) => {
                    const {
                      type = 'text',
                      label = '',
                      title = '',
                      content = '',
                      gif = '',
                      id: questionId = '',
                      required = false,
                      includeNA = false,
                      multi = false,
                      options = [],
                      min = 0,
                      max = 10,
                    } = {
                      ...question,
                    };
                    const submitButtonMessage = groupIndex === groups.length - 1 && index === questions.length - 1;
                    const isRatio = type === 'multiple-selection' && !multi;

                    if (index + 1 !== stepNumber) return null;
                    return (
                      <Fade key={questionId}>
                        <div>
                          <div className="form-input">
                            <FormControlSurveys
                              formikProps={formData}
                              control={type}
                              name={questionId}
                              label={label || title}
                              content={content}
                              gif={gif}
                              options={options}
                              isMulti={multi}
                              isRequired={required}
                              includeNA={includeNA}
                              min={Number(min)}
                              max={Number(max)}
                              handleNext={() =>
                                questionhandler({
                                  goNextGroup: index + 1 === questions.length,
                                  submitForm: formData.submitForm,
                                })
                              }
                            />
                          </div>
                          <div className="handle-sections">
                            {!isRatio && (
                              <Button
                                className="evie-survey"
                                onClick={() =>
                                  questionhandler({
                                    goNextGroup: index + 1 === questions.length,
                                    submitForm: formData.submitForm,
                                  })
                                }
                                type="button"
                                disabled={formData.isSubmitting}
                              >
                                {submitButtonMessage ? t('button.send') : t('button.next')}
                              </Button>
                            )}
                          </div>
                        </div>
                      </Fade>
                    );
                  })}
                </Form>
              )}
            </Formik>
          </div>
        );
      })}
    </section>
  );
};

Groups.defaultProps = {
  survey: {},
  handleSubmit: () => {},
};

Groups.propTypes = {
  survey: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  }),
  handleSubmit: PropTypes.func,
};

export default Groups;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { path, isEmpty, pathOr } from 'ramda';
import { v4 as uuid } from 'uuid';
import { useMutation } from '@apollo/react-hooks';
import Loader from 'react-loader-spinner';

import Welcome from 'components/Surveys/Welcome';
import Groups from 'components/Surveys/Groups';
import CompletedSurvey from 'components/Surveys/CompletedSurvey';
import NoUrlFound from 'components/NoUrlFound';

import { PATIENT_SURVEY } from 'apollo/queries';
import { UPDATE_PATIENT_SURVEY } from 'apollo/mutations';
import { queryParams } from 'utils/string';
import { ROUTES } from 'utils/constants';
import { useAuthQuery } from 'utils/hooks';
import showNotification from 'utils/showNotification';

import './surveys.scss';

export const SURVEY_STEPS = {
  INTRO: 'intro',
  SURVEY: 'survey',
  COMPLETED: 'completed',
};

const Surveys = (props) => {
  const { push } = useHistory();
  const search = path(['history', 'location', 'search'])(props);
  const params = { ...queryParams(search) };
  const { survey_id: surveyId = '', client = '' } = { ...params };

  const {
    loading,
    data = {},
    error,
  } = useAuthQuery(PATIENT_SURVEY, {
    variables: { surveyId },
  });
  const { patientSurvey = {} } = { ...data };
  const { form = {}, patient = {}, typeSurvey = '' } = { ...patientSurvey };
  const { title: surveyTitle = '' } = { ...form };

  const isInitial = typeSurvey === 'initial';

  const [step, setStep] = useState(client === 'evie' ? SURVEY_STEPS.SURVEY : SURVEY_STEPS.INTRO);
  const [showSpinner, setSpinner] = useState(false);
  const [updatePatientSurvey] = useMutation(UPDATE_PATIENT_SURVEY);

  useEffect(() => {
    push(`${ROUTES.SURVEYS}?survey_id=${surveyId}&group=1&step=1`);
  }, []);

  const handleSubmit = async (groups) => {
    setSpinner(true);
    const formValues = groups.map((group) => {
      const { id, groupTitle, questions } = { ...group };
      return { id, groupTitle, questions };
    });

    const allFiles = groups
      .reduce((acc, curr) => {
        const { files = [] } = { ...curr };
        return [...acc, ...files];
      }, [])
      .map((currentFile) => ({ ...currentFile, file: currentFile.file[0] }));

    const response = await updatePatientSurvey({
      variables: {
        surveyId,
        content: formValues,
        ...(!isEmpty(allFiles) && {
          files: allFiles,
        }),
      },
    });

    const errors = pathOr([], ['data', 'updatePatientSurvey', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? ['Se ha completado exitosamente la encuesta.'] : errors,
    });

    if (emptyErrors) {
      setStep(SURVEY_STEPS.COMPLETED);
      if (isInitial) {
        push(`${ROUTES.SURVEYS}?survey_id=${surveyId}&success=${uuid()}`);
      }
    }
  };

  if (!surveyId || error) return <NoUrlFound message="Token inválido" />;

  return (
    <>
      {step === SURVEY_STEPS.INTRO && (
        <Welcome handleSetStep={() => setStep(SURVEY_STEPS.SURVEY)} title={surveyTitle} />
      )}
      {step === SURVEY_STEPS.SURVEY && form.id && <Groups survey={form} handleSubmit={handleSubmit} />}
      {step === SURVEY_STEPS.COMPLETED && <CompletedSurvey patient={patient} isInitial={isInitial} />}
      <Loader
        type="ThreeDots"
        color="#9b2c51"
        height={100}
        width={100}
        className="spinner"
        visible={loading || showSpinner}
      />
    </>
  );
};

export default Surveys;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { IoIosArrowDown } from 'react-icons/io';

import { GET_ACTIVE_BREAKS } from 'apollo/queries';
import { useAuthQuery, useFormInput } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROUTES, ROLES } from 'utils/constants';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SearchFilter from 'components/SearchFilter';
import SolidButton from 'components/SolidButton';
// import NoActivePausesFound from 'components/Forms/NoFormsFound';
import { NoMembers } from 'components/EmptyState';

import noTemplates from 'assets/images/no-data.svg';

const ActivePauses = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const { push } = useHistory();

  const { loading, data, refetch } = useAuthQuery(GET_ACTIVE_BREAKS);
  const { activeBreaks: allActivePauses = [] } = { ...data };

  const [activePauses, setActivePauses] = useState([]);

  const searchActivePause = useFormInput('');
  const { value = '' } = { ...searchActivePause };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    let temporal = allActivePauses;
    if (value) {
      temporal = allActivePauses.filter((activePause) => {
        const { title } = activePause;
        return `${title}`.toLowerCase().includes(value.toLowerCase());
      });
    }
    setActivePauses(temporal);
  }, [allActivePauses, value]);

  const handleChangeRoute = () => {
    push(ROUTES.CREATE_ACTIVE_PAUSE);
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenu />
        <div className="dashboard__main">
          <Header title={t('leftMenuHeader.activePauses')} shadow />
          <div className="dashboard__content--patients">
            {!loading && isEmpty(allActivePauses) && (
              <NoMembers
                title={t('activePauses.emptyState.title')}
                subtitle={t('activePauses.emptyState.subtitle')}
                button={t('activePauses.createButton')}
                handleClick={handleChangeRoute}
                image={noTemplates}
              />
            )}
            {!loading && !isEmpty(allActivePauses) && (
              <div className="forms">
                <div className="forms__search">
                  <SearchFilter searchItems={searchActivePause} placeholder={t('searchLabel.activePauses')} />
                  <SolidButton onClick={handleChangeRoute} className="slim">
                    {t('activePauses.createButton')}
                  </SolidButton>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '90%' }}>{t('tableHeader.title')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(activePauses || []).map((activePause) => {
                      const { id, title } = activePause;
                      return (
                        <tr key={id}>
                          <td style={{ width: '90%' }}>{title}</td>
                          <td className="actions-col" style={{ width: '10%' }}>
                            <button
                              type="button"
                              onClick={() => push(`${ROUTES.ACTIVE_PAUSE}?id=${id}`)}
                              className="form-detail"
                            >
                              <IoIosArrowDown />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </section>
      <Loader className="spinner" type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
    </>
  );
};

export default ActivePauses;

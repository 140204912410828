import { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { REDIRECT_BY_ROLE } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';

export const useRedirectByRole = (expectedRoles = []) => {
  const { replace } = useHistory();
  const { userRole } = getUserRole();

  useLayoutEffect(() => {
    if (!expectedRoles.includes(userRole)) {
      replace(REDIRECT_BY_ROLE[userRole]);
    }
  }, []);
};

import { useEffect } from 'react';
import { isEmpty } from 'ramda';

import { GET_CLINIC_READING } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';
import { ACTIONS, BLOCK_CONTENT } from './constants';
import { formatRetrievedData } from './utils';

export const useGetReadingBuilder = (readingId, blockContent, dispatch) => {
  const isReadingBuilder = BLOCK_CONTENT.TRUE === blockContent;
  const {
    loading = false,
    data = {},
    refetch = () => {},
  } = readingId && isReadingBuilder
    ? useAuthQuery(GET_CLINIC_READING, {
        variables: { readingId },
      })
    : {};
  const { getClinicReading: readingData = {} } = { ...data };
  const { title = '', backgroundImage = {}, thumbnailImage = {}, contentJson = [] } = { ...readingData };
  const { thumb } = { ...backgroundImage };
  const { thumb: thumbImg } = { ...thumbnailImage };
  const formatedData = formatRetrievedData(contentJson);

  const retrievedData = { title, backgroundImage: thumb, thumbnailImage: thumbImg, content: formatedData };

  useEffect(() => {
    if (!isEmpty(readingData)) {
      dispatch({ type: ACTIONS.SET_READING_DATA, payload: retrievedData });
    }
  }, [readingData]);

  useEffect(() => {
    refetch();
  }, []);

  return {
    loading,
    readingData,
  };
};

import { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { propOr } from 'ramda';

import { FETCH_TEAM_MEMBERS_BY_ROLE, GET_PARTNER_BY_ROLE } from 'utils/constants';

export const useAuthQuery = (query, options = {}) => {
  const { replace = () => {} } = useHistory();
  const queryObj = useQuery(query, options);
  const { error = '' } = { ...queryObj };
  if (`${error}`.indexOf('authenticate to perform this action') !== -1) {
    localStorage.clear();
    replace('/');
  }
  return queryObj;
};

export const useTeamMembersList = (userRole = 'Clinic') => {
  const { data: clinicData, refetch: teamMembersRefetch } = useAuthQuery(FETCH_TEAM_MEMBERS_BY_ROLE[userRole]);
  const partner = propOr({}, GET_PARTNER_BY_ROLE[userRole])(clinicData);
  const { teamMembers = [] } = { ...partner };
  const teamMembersOptions = (teamMembers || [])
    .map(({ name: label, id: value }) => ({ label, value }))
    .sort((a, b) => {
      const toLowerA = a.label.toLowerCase();
      const toLowerB = b.label.toLowerCase();
      return toLowerA > toLowerB ? 1 : toLowerA < toLowerB ? -1 : 0;
    });
  return {
    teamMembersOptions,
    teamMembersRefetch,
  };
};

export const usePolling = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      const updateData = setInterval(tick, delay);
      return () => {
        clearInterval(updateData);
      };
    }
  });
};

export const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export const useFilterTabs = (filters) => {
  const [filterTab, setFilterTab] = useState(filters[0]);
  const handleChangeFilter = (filter) => {
    setFilterTab(filter);
  };
  return { handleChangeFilter, filterTab };
};

export const useDownloadPdf = (documentTitle = undefined) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    ...(!!documentTitle && {
      documentTitle,
    }),
  });
  return { componentRef, handlePrint };
};

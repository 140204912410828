import { object, string, boolean, array, date, ref } from 'yup';

export const requiredMessage = 'Required';
export const emailMessage = 'Invalid Email';
export const urlMessage = 'Invalid Url';
export const termsMessage = 'You must accept the terms and conditions to continue';
export const nequiMessage = 'Número de cuenta Nequi inválido';
export const matchMessage = 'Las contraseñas deben coincidir';
export const validateMessage =
  'La contraseña debe tener al menos: 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial';
export const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

// const cvvMessage = 'Código inválido';

export const patientFollowupForm = object().shape({
  painLevel: string().trim().required(requiredMessage),
  pillsLevel: string().trim().required(requiredMessage),
  urinaryDisfunction: string().trim().required(requiredMessage),
  functionality: string().trim().required(requiredMessage),
  sleepQuality: string().trim().required(requiredMessage),
});

export const MedicalAssessmentForm = object().shape({
  diagnostic: array().of(string().trim().required(requiredMessage)),
  objective: string().trim().required(requiredMessage),
  painLocalization: string().trim().required(requiredMessage),
  exerciseLevel: string().trim().required(requiredMessage),
  duration: string().trim().required(requiredMessage),
  functionality: string().trim().required(requiredMessage),
  painLevel: string().trim().required(requiredMessage),
  medicalHistory: string().trim().required(requiredMessage),
});

export const personalInformationForm = object().shape({
  email: string().trim().email(emailMessage).required(requiredMessage),
  name: string().trim().required(requiredMessage),
  cardId: string().trim().required(requiredMessage),
  gender: string().trim().required(requiredMessage),
  birthday: string().trim().required(requiredMessage),
  formId: string().trim().required(requiredMessage),
});

export const updatePatientPersonalInfo = object().shape({
  name: string().trim().required(requiredMessage),
  // cardId: string().trim().required(requiredMessage).nullable(),
  // birthday: string().trim().required(requiredMessage).nullable(),
  email: string().trim().email(emailMessage).required(requiredMessage),
  gender: string().trim().required(requiredMessage),
  phone: string().trim().required(requiredMessage).nullable(),
});

export const signUpForm = object().shape({
  email: string().trim().email(emailMessage).required(requiredMessage),
  name: string().trim().required(requiredMessage),
});

export const newUserForm = object().shape({
  name: string().trim().required(requiredMessage),
  // age: string().trim().required(requiredMessage),
  email: string().trim().email(emailMessage).required(requiredMessage),
  // cardId: string().trim().required(requiredMessage),
});

export const loginForm = object().shape({
  email: string().trim().email(emailMessage).required(requiredMessage),
  password: string().trim().required(requiredMessage),
});

export const sendEmail = object().shape({
  email: string().trim().email(emailMessage).required(requiredMessage),
});

export const passwordRecovery = object().shape({
  password: string()
    .trim()
    .required(requiredMessage)
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,40}$/, validateMessage),
  confirmPassword: string()
    .trim()
    .oneOf([ref('password'), ''], matchMessage)
    .required(requiredMessage),
});

export const customPlanForm = object().shape({
  title: string().trim().required(requiredMessage),
  description: string().trim().required(requiredMessage),
});

export const customPlanTemplateForm = object().shape({
  title: string().trim().required(requiredMessage),
  description: string().trim().required(requiredMessage),
  bodyParts: string().trim().required(requiredMessage),
});

export const profileClinicsForm = object().shape({
  email: string().trim().email(emailMessage).required(requiredMessage),
  address: string().trim().required(requiredMessage),
  description: string().trim().required(requiredMessage),
  nit: string().trim().required(requiredMessage),
  phone: string().trim().required(requiredMessage),
  doctorsNumber: string().trim().required(requiredMessage),
  countryName: string().trim().required(requiredMessage),
});

export const profileCompaniesForm = object().shape({
  email: string().trim().email(emailMessage).required(requiredMessage),
  address: string().trim().required(requiredMessage),
  description: string().trim().required(requiredMessage),
  nit: string().trim().required(requiredMessage),
  phone: string().trim().required(requiredMessage),
  numberOfEmployees: string().trim().required(requiredMessage),
  contactPerson: string().trim().required(requiredMessage),
  countryName: string().trim().required(requiredMessage),
});

export const profileSuperClinicForm = object().shape({
  description: string().trim().required(requiredMessage),
});

export const passwordForm = object().shape({
  password: string().trim().required(requiredMessage),
  confirm: string().trim().required(requiredMessage),
});

export const addTeamMember = object().shape({
  name: string().trim().required(requiredMessage),
  email: string().trim().email(emailMessage).required(requiredMessage),
});

export const rejectForm = object().shape({
  message: string().trim().required(requiredMessage),
});

const personalData = {
  firstName: string().trim().required(requiredMessage),
  lastName: string().trim().required(requiredMessage),
  email: string().trim().email(emailMessage).required(requiredMessage),
  phone: string().trim().required(requiredMessage),
  terms: boolean().oneOf([true], termsMessage),
  address: string().trim().required(requiredMessage),
};

export const readingDetail = object().shape({
  title: string().trim().required(requiredMessage),
});

export const CreditCardForm = object().shape({
  ...personalData,
  /* cardNumber: string().trim().required(requiredMessage),
  cardHolder: string().trim().required(requiredMessage),
  cvv: string()
    .trim()
    .required(requiredMessage)
    .min(3, cvvMessage)
    .max(4, cvvMessage)
    .matches(/^[0-9]*$/, cvvMessage),
  expirationMonth: string().trim().required(requiredMessage),
  expirationYear: string().trim().required(requiredMessage),
  dues: string().trim().required(requiredMessage), */
});

export const NequiForm = object().shape({
  ...personalData,
  accountNumber: string().trim().required(requiredMessage).length(14, nequiMessage),
});

export const covenantForm = object().shape({
  name: string().trim().required(requiredMessage),
  description: string().trim().required(requiredMessage),
  label: string().trim().required(requiredMessage),
});

export const eventForm = object().shape({
  title: string().trim().required(requiredMessage),
  description: string().trim().required(requiredMessage),
  startAt: date().required(requiredMessage),
  covenantId: string().trim().required(requiredMessage),
});

export const titleTypeForm = object().shape({
  title: string().trim().required(requiredMessage),
  typeForm: string().trim().required(requiredMessage),
});

export const availibilityForm = object().shape({
  weekday: string().trim().required(requiredMessage),
  from: string().trim().required(requiredMessage),
  to: string().trim().required(requiredMessage),
});

export const addProductForm = object().shape({
  name: string().trim().required(requiredMessage),
  description: string().trim().required(requiredMessage),
  image: string().trim().required(requiredMessage),
  points: string().trim().required(requiredMessage),
  quantity: string().trim().required(requiredMessage),
  status: string().trim().required(requiredMessage),
});

export const sendEmailForm = object().shape({
  subject: string().trim().required(requiredMessage),
  message: string().trim().required(requiredMessage),
});

export const programDetail = object().shape({
  title: string().trim().required(requiredMessage),
  doctorName: string().trim().required(requiredMessage),
  duration: string().trim().required(requiredMessage),
  planId: string().trim().required(requiredMessage),
});

export const createCompany = object().shape({
  name: string().trim().required(requiredMessage),
  email: string().trim().email(emailMessage).required(requiredMessage),
  planId: string().trim().required(requiredMessage),
  surveyId: string().trim().required(requiredMessage),
});

export const createActivePause = object().shape({
  title: string().trim().required(requiredMessage),
  description: string().trim().required(requiredMessage),
  // tagColor: string().trim().required(requiredMessage),
  bodyParts: string().trim().required(requiredMessage),
});

export const createAudio = object().shape({
  title: string().trim().required(requiredMessage),
  content: string().trim().required(requiredMessage),
});

export const createShortVideo = object().shape({
  title: string().trim().required(requiredMessage),
  description: string().trim().required(requiredMessage),
});

export const headingForm = object().shape({
  es: string().trim().required(requiredMessage),
  en: string().trim().required(requiredMessage),
});

export const imageUrl = object().shape({
  url: string().trim().required(requiredMessage).matches(urlPattern, urlMessage),
});

export const bulletList = object().shape({
  es: array().of(string().trim().required(requiredMessage)),
  en: array().of(string().trim().required(requiredMessage)),
});

export const evieDetail = object().shape({
  title: string().trim().required(requiredMessage),
});

export const evieLevelInfo = object().shape({
  name: string().trim().required(requiredMessage),
  goal: string().trim().required(requiredMessage),
});

export const evieExerciseInfo = object().shape({
  title: string().trim().required(requiredMessage),
  laps: string().trim().required(requiredMessage),
  rest: string().trim().required(requiredMessage),
});

export const evieSqueezeInfo = object().shape({
  title: string().trim().required(requiredMessage),
});

export const bookingRequest = object().shape({
  date: string().trim().required(requiredMessage),
  hour: string().trim().required(requiredMessage),
  timeZone: string().trim().required(requiredMessage),
  message: string().trim().required(requiredMessage),
});

const typeSelector = (type) => {
  switch (type) {
    case 'date':
      return date();
    case 'verification':
      return boolean();
    default:
      return string().trim();
  }
};

export const validationSchemaBuilder = (fields = []) => {
  const schema = {};
  fields.forEach((field) => {
    const { id = '', required = false, type = 'text' } = { ...field };
    let typeSchema = typeSelector(type);
    if (required && type !== 'verification') {
      typeSchema = typeSchema.required(requiredMessage);
    }
    schema[id] = typeSchema;
  });
  return object().shape(schema);
};

export const companyPaymentForm = object().shape({
  allowanceAmount: string().trim().required(requiredMessage),
  cadence: string().trim().required(requiredMessage),
});

export const companyPaymentOnboardingForm = object().shape({
  paymentMode: string().trim().required(requiredMessage),
  allowanceAmount: string().trim().required(requiredMessage),
  cadence: string().trim().required(requiredMessage),
});

export const createSqueeze = object().shape({
  title: string().trim().required(requiredMessage),
  actions: string().trim().required(requiredMessage),
});

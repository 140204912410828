import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';

import './payment-step.scss';

const PaymentStep = (props) => {
  const { step = 0 } = { ...props };
  const { t } = useTranslation();

  return (
    <div className="billing__header">
      <div className="pay-step-list">
        <div className="pay-step-container">
          <div className={`pay-step ${step <= 0 ? 'pay-step-current' : step > 0 && 'pay-step-checked'}`}>
            {step > 0 && <Icon name="checked" className="checked-icon" />}
          </div>
          <p className={`pay-step-name ${step <= 0 ? 'pay-step-current-text' : step > 0 && 'pay-step-checked-text'}`}>
            {t('billing.stepIndicator.method')}
          </p>
        </div>
        <span className={`pay-step-line ${step > 0 && 'pay-step-checked-line'}`} />
        <div className="pay-step-container">
          <div className={`pay-step ${step === 1 ? 'pay-step-current' : step > 1 && 'pay-step-checked'}`}>
            {step > 1 && <Icon name="checked" className="checked-icon" />}
          </div>
          <p className={`pay-step-name ${step === 1 ? 'pay-step-current-text' : step > 1 && 'pay-step-checked-text'}`}>
            {t('billing.stepIndicator.detail')}
          </p>
        </div>
        <span className={`pay-step-line ${step > 1 && 'pay-step-checked-line'}`} />
        <div className="pay-step-container">
          <div className={`pay-step ${step > 2 ? 'pay-step-checked' : step > 1 && 'pay-step-current'}`}>
            {step > 2 && <Icon name="checked" className="checked-icon" />}
          </div>
          <p className={`pay-step-name ${step === 2 ? 'pay-step-current-text' : step > 2 && 'pay-step-checked-text'}`}>
            {t('billing.stepIndicator.confirm')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentStep;

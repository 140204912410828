import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useConversationsContext } from 'components/conversations/ConversationsProvider';
import { getFullDate, getTimestamp } from '../utils';

const MessageBubble = ({ message }) => {
  const { selectedConversation, lng, ownerEmail } = useConversationsContext();
  const messageRef = useRef();

  const employeeName = selectedConversation.attributes?.employee_name;
  const owner = message.author === ownerEmail;
  const guest = employeeName || message.author;
  const { timestamp } = message.state;

  const fullDate = getFullDate({
    date: timestamp,
    lng,
    options: { weekday: 'short', month: 'short', day: 'numeric' },
  });

  useEffect(() => {
    if (message) {
      messageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [message]);

  return (
    <li ref={messageRef} className={`messages__message ${owner ? 'outgoing' : 'incoming'}`}>
      <span>{owner ? 'Me' : guest}</span>
      <span>{message.body}</span>
      <span>
        {fullDate} - {getTimestamp(timestamp)}
      </span>
    </li>
  );
};

MessageBubble.propTypes = {
  message: PropTypes.shape({
    state: PropTypes.shape({
      timestamp: PropTypes.instanceOf(Date),
    }),
    author: PropTypes.string,
    body: PropTypes.string,
  }).isRequired,
};

export default MessageBubble;

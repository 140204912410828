import React, { useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { isEmpty } from 'ramda';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { TextInput, NumberInput, DateInput, CovenantSelect, MultiSelect } from '../../../components/FormComponents';
import Button from '../../../components/SolidButton';
import { GET_FORMS } from '../../../apollo/queries';
import { useAuthQuery } from '../../../utils/hooks';
import { useTeamMembersList } from '../../../utils/hooks';
import { MEDICAL_ASSESMENT } from '../../../utils/steps';
import { personalInformationForm } from '../../../utils/validationSchema';
import { StoreConsumer } from '../../StoreProvider';
import './personal-information.scss';

const PersonalInformation = (props) => {
  const { onSetStep, fromPatients } = props;
  const { state: stateFromPatients } = { ...fromPatients };
  const { email = '', } = { ...stateFromPatients };

  const { loading, data, refetch } = useAuthQuery(GET_FORMS, {
    variables: {
      type: 'assesment',
    }
  });

  const { teamMembersOptions = [], teamMembersRefetch = () => {} } = useTeamMembersList();

  useEffect(() => {
    refetch();
    teamMembersRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (setStoreData) => (values) => {
    setStoreData('patient', values);
    onSetStep(MEDICAL_ASSESMENT);
  };
  const { goBack = () => {} } = useHistory();
  const { getClinicForms: forms = [] } = { ...data };
  const formOptions = (forms || []).map(({ title: label, id: value }) => ({ label, value }));

  return (
    <StoreConsumer>
      {({ patient, setStoreData }) => (
        <div className={'personal-info-form'}>
          <h2 className={'section-title'}>Información Personal</h2>
          <p className={'section-description'} style={{ marginBottom: '40px' }}>
            Ingresa la información para identificar a tu paciente. Verifica que toda la información sea correcta y única. (En el
            correo ingresado será enviado los datos de acceso a la app móvil)
          </p>
          <Formik initialValues={!!email ? {...patient, email} : patient} onSubmit={onSubmit(setStoreData)} validationSchema={personalInformationForm}>
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} className='w-100'>
                <div className={'form-row'}>
                  <Field 
                    type='email' 
                    label='Correo Electrónico' 
                    name='email' 
                    component={TextInput} 
                    disabled={!!email}
                    className={`${!!email ? 'read-only-input' : ''}`}
                  />
                  <Field label='Documento de Identidad' name='cardId' component={TextInput} />
                </div>
                <div className={'form-row'}>
                  <Field type='text' label='Nombre' name='name' component={TextInput} />
                  <Field label='Fecha de nacimiento' name='birthday' component={DateInput} />
                </div>
                <div className={'form-row'}>
                  <Field label='Teléfono' name='phone' component={NumberInput} />
                  <div className={'form-row__gender'}>
                    <span className='form__label d-block mb-2'>Género</span>
                    <div className='form-row'>
                      <div className='col-auto'>
                        <div className='form-check form-check-inline align-items-baseline'>
                          <label htmlFor='male' className='form__radio'>
                            <Field id='male' name='gender' type='radio' value='Hombre' /> Hombre
                          </label>
                        </div>
                      </div>
                      <div className='col-auto'>
                        <div className='form-check form-check-inline align-items-baseline'>
                          <label htmlFor='female' className='form__radio'>
                            <Field id='female' name='gender' type='radio' value='Mujer' /> Mujer
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Field
                  type='text'
                  label='Convenio'
                  name='covenantId'
                  component={CovenantSelect}
                  placeholder='Seleccione un convenio (si aplica)'
                />
                <Field
                  type='text'
                  label='Valoración'
                  name='formId'
                  component={MultiSelect}
                  options={formOptions}
                  placeholder='Seleccione el formulario para la valoración'
                />
                {!isEmpty(teamMembersOptions) && (
                  <Field
                    type={'text'}
                    label={'Profesional'}
                    name={'teamMemberId'}
                    component={MultiSelect}
                    options={teamMembersOptions}
                    placeholder={'Seleccione el Profesional para el acompañamiento'}
                  />
                )}
                <div className='button-container'>
                  <Button type='button' className={'secondary'} onClick={goBack}>
                    Cancelar
                  </Button>
                  <Button type='submit'>Siguiente</Button>
                </div>
              </Form>
            )}
          </Formik>
          <Loader type={'ThreeDots'} color={'#495fd7'} height={100} width={100} className={'spinner'} visible={loading} />
        </div>
      )}
    </StoreConsumer>
  );
};

export default PersonalInformation;

export const capitalize = (text) =>
  `${text}`
    .trim()
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase());

export const titleize = (text) => {
  return `${text}`.trim().split(' ').map(capitalize).join(' ');
};

export const normalize = (text) => `${text}`.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const queryParams = (url) => {
  let hashes = url.slice(url.indexOf('?') + 1).split('&');
  return hashes.reduce((params, hash) => {
    let [key, val] = hash.split('=');
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
};

export const currencyFormat = (amount, decimalCount = 2, decimal = ',', thousands = '.') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
};

export const firstLetterCase = (text) =>
  `${text.toLowerCase().slice(0, 1).toUpperCase()}${text.toLowerCase().slice(1)}`;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { useFormInput } from 'utils/hooks';
import { useToggle } from 'utils/hooks/useToggle';
import { useGetCategories } from 'utils/hooks/useGetCategories';
import { useFilterSegments } from 'utils/hooks/useFilterSegments';
import { ACTIONS } from 'components/createActivePause/service';
import { useCreateActivePauseContext } from 'components/createActivePause/CreateActivePauseProvider';

import SearchFilter from 'components/SearchFilter';
import CategoriesButton from 'components/CategoriesButton';
import CategoriesFilter from 'components/CategoriesFilter';
import ExerciseModal from 'components/CreatePatient/ExerciseModal';
import { NoExercisesFound } from 'components/EmptyState';
import ExerciseCard from '../ExerciseCard';

const ExercisesList = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { loading, exercises, exerciseIds, dispatch } = useCreateActivePauseContext();
  const searchExercises = useFormInput('');
  const { value = '' } = { ...searchExercises };
  const categories = useGetCategories({ exercises });
  const filterStates = useFilterSegments();
  const { filterParts, filterEquipments, filterLevels } = filterStates;

  const [allExercises, setAllExercises] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState({});
  const [isExerciseModal, setIsExerciseModal] = useToggle();
  const [isCategoriesOpen, setIsCategoriesOpen] = useToggle();

  const { id: selectedId, image: selectedImg, title: selectedTitle } = selectedExercise;

  const handleFilterElements = (allEx, category) =>
    allEx.filter((ex) => {
      const { bodyParts: exbpts = [] } = ex;
      return exbpts.map((e) => category.indexOf(e) !== -1).reduce((a, b) => a || b, false);
    });

  useEffect(() => {
    if (isEmpty(allExercises) && !isEmpty(exercises)) {
      setAllExercises(exercises);
    }
  }, [exercises]);

  useEffect(() => {
    let temporal = exercises;
    const lowerCaseValue = value.toLowerCase();
    if (value) {
      temporal = exercises.filter(({ title, bodyParts }) => {
        const bodyPartsString = bodyParts.join(' - ').toLowerCase();
        return `${title}`.toLowerCase().includes(lowerCaseValue) || bodyPartsString.includes(lowerCaseValue);
      });
    }
    if (!isEmpty(filterParts)) {
      temporal = handleFilterElements(temporal, filterParts);
    }
    if (!isEmpty(filterEquipments)) {
      temporal = handleFilterElements(temporal, filterEquipments);
    }
    if (!isEmpty(filterLevels)) {
      temporal = handleFilterElements(temporal, filterLevels);
    }
    setAllExercises(temporal);
  }, [value, filterParts, filterEquipments, filterLevels]);

  const handleDisplayExercise = (exercise) => {
    setIsExerciseModal();
    setSelectedExercise(exercise);
  };

  const handleAddExercise = ({ id, image, title }) => {
    dispatch({ type: ACTIONS.ADD_EXERCISE, payload: { id, image, title } });
  };

  return (
    <>
      <section className="active-pause__exercises">
        <h2 className="active-pause__exercises_title">{t('createActivePause.exercisesTitle')}</h2>
        <div className="active-pause__exercises_filters">
          <SearchFilter searchItems={searchExercises} placeholder={t('placeholder.searchExercise')} />
          <CategoriesButton showCategories={isCategoriesOpen} toggleCategories={setIsCategoriesOpen} />
        </div>
        <AiOutlineCloseCircle className="active-pause__exercises_close" onClick={goBack} />
        <CategoriesFilter
          className="create-active-break-view"
          showCategories={isCategoriesOpen}
          categories={categories}
          filterStates={filterStates}
        />
        <ul className="exercises-list">
          {allExercises.map((exercise) => (
            <ExerciseCard
              key={exercise.id}
              exercise={exercise}
              exerciseIds={exerciseIds}
              handleAddExercise={handleAddExercise}
              handleDisplayExercise={handleDisplayExercise}
            />
          ))}
          {!loading && value && isEmpty(allExercises) && <NoExercisesFound />}
        </ul>
      </section>
      {isExerciseModal && (
        <ExerciseModal
          open={isExerciseModal}
          onCloseModal={setIsExerciseModal}
          exercise={selectedExercise}
          onAdd={() => handleAddExercise({ id: selectedId, image: selectedImg, title: selectedTitle })}
        />
      )}
    </>
  );
};

export default ExercisesList;

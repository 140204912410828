import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';
import Thumb from 'components/Thumb';

import './order-detail.scss';

const OrderDetail = ({ order, modalIsOpen, toggleModal, handleFulfillOrder }) => {
  const { name = '', status = '', employee = {}, lineItem = {} } = { ...order };
  const { name: employeeName = '' } = { ...employee };
  const { title = '', quantity = '', points = '', product = {} } = { ...lineItem };
  const { avatar = {} } = { ...product };
  const { original = '' } = { ...avatar };
  const { t } = useTranslation();

  const fullfilled = status === 'fulfilled';

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={toggleModal} closeable={false}>
      <section className="order-detail">
        <h3 className="modal__title title_left">{name}</h3>
        <div className="order-detail__content">
          <Thumb source={original} alt={title} />
          <div className="order-detail__column">
            <span>{t('orders.orderDetail.product')}</span>
            <span>{title}</span>
          </div>
          <div className="order-detail__column">
            <span>{t('orders.orderDetail.quantity')}</span>
            <span>{quantity}</span>
          </div>
          <div className="order-detail__column">
            <span>{t('orders.orderDetail.points')}</span>
            <span>{points}</span>
          </div>
          <div className="order-detail__column">
            <span>{t('orders.orderDetail.employee')}</span>
            <span>{employeeName}</span>
          </div>
        </div>
        <div className="order-detail__footer">
          <SolidButton className={fullfilled ? 'disabled' : ''} onClick={handleFulfillOrder}>
            {t('orders.orderDetail.button')}
          </SolidButton>
        </div>
      </section>
    </Modal>
  );
};

export default OrderDetail;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { IoMdUnlock } from 'react-icons/io';

import Powered from 'components/Surveys/Powered';
import DownloadButton from 'components/Surveys/DownloadButton';
import completedSurvey from 'assets/images/completed-survey.png';

const CompletedSurvey = ({ patient, isInitial }) => {
  const { t } = useTranslation();
  const { email = '' } = { ...patient };
  return (
    <section className="sections-completed">
      <div className="welcome-survey__title">
        <h2>{isInitial ? t('surveys.completed.thanksInit') : t('surveys.completed.thanks')}</h2>
      </div>
      <div className="sections-completed__credentials">
        <div className="sections-completed__credentials_image">
          <img src={completedSurvey} alt="encuesta terminada" />
        </div>
        <div className="sections-completed__credentials_data">
          <div className="login">
            {isInitial ? (
              <>
                <p>{t('surveys.completed.data')}</p>
                <p>
                  <span className="user">{t('surveys.completed.user')}</span>
                  <span>{email}</span>
                </p>
                <p>
                  <span className="user">{t('surveys.completed.password')}</span>
                  <span>nuevo_paciente_2020</span>
                </p>
              </>
            ) : (
              <p>{t('surveys.completed.success')}</p>
            )}
          </div>
          {isInitial && (
            <div className="reminder">
              <div className="reminder__unlock">
                <IoMdUnlock />
              </div>
              <p>{t('surveys.completed.reminder')}</p>
            </div>
          )}
        </div>
      </div>
      <div className="welcome-survey__footer">
        {isInitial && (
          <div className="buttons">
            <p>{t('surveys.completed.download')}</p>
            <DownloadButton store="google" />
            <DownloadButton store="apple" />
          </div>
        )}
        <div className="powered-container">
          <Powered />
        </div>
      </div>
    </section>
  );
};

CompletedSurvey.propTypes = {
  patient: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  isInitial: PropTypes.bool.isRequired,
};

export default CompletedSurvey;

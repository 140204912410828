import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';
import Select from 'react-select';

import { CREATE_PLAN, EDIT_PLAN } from 'utils/steps';
import { ROUTES } from 'utils/constants';
import { customStyles } from 'utils/styles';
import { useFilterTabs } from 'utils/hooks';
import { useFilterByProperty } from 'utils/hooks/useFilterByProperty';
import { rowsPerPage, typeOfPlans, handleFilterPlans } from 'components/Patients/Patient/SuperClinicPlans/utils';
import { useAppState } from 'components/StoreProvider';

import Icon from 'components/Icon';
import SolidButton from 'components/SolidButton';
import { NoPatientData, NoPatientsFound as NoPlansFound } from 'components/EmptyState';
import PlanCard from 'components/Plan/PlanCard';
import Pagination from 'components/Pagination';

const SuperClinicPlans = ({ patient, refetchPatient }) => {
  const { id: patientId, allPatientPlans: plans = [] } = { ...patient };
  const { setStoreData } = useAppState();

  const [patientsPlan, setPatientsPlan] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  const { handleChangeFilter, filterTab: planFilter } = useFilterTabs(typeOfPlans);
  const unactivePlans = useFilterByProperty({ data: plans, property: 'status', value: 'unactive' });
  const completedPlans = useFilterByProperty({ data: plans, property: 'completed', value: true });
  const rehabilitationPlans = useFilterByProperty({ data: plans, property: 'planType', value: 'rehabilitation' });
  const activePausePlans = useFilterByProperty({ data: plans, property: 'planType', value: 'active_pause' });

  const isNewResults = (results) => {
    const rIds = (results || []).map(({ id }) => id).join(',');
    const fIds = (filtered || []).map(({ id }) => id).join(',');
    return rIds !== fIds;
  };

  useEffect(() => {
    const temporal = handleFilterPlans(planFilter, {
      completedPlans,
      unactivePlans,
      rehabilitationPlans,
      activePausePlans,
      plans,
      filterList: typeOfPlans,
    });
    const totalPages = temporal.length > 0 ? Math.ceil(temporal.length / rowsPerPage) : 0;
    if (page !== 1) setPage(1);
    if (pages !== totalPages) setPages(totalPages);
    if (isNewResults(temporal)) setFiltered(temporal);
  }, [plans, planFilter]);

  useEffect(() => {
    const init = page * rowsPerPage - rowsPerPage;
    const end = init + rowsPerPage;
    setPatientsPlan(filtered.slice(init, end));
  }, [filtered, page]);

  const { push } = useHistory();
  const onCreatePlan = () => {
    setStoreData('patient', { ...patient, id: patientId });
    setStoreData('step', CREATE_PLAN);
    setStoreData('customPlan', { title: '', description: '' });
    setStoreData('weeks', []);
    push(ROUTES.EDIT_PATIENT);
  };

  const onEditPlan = (patientPlan) => () => {
    const { id: planId, plan, currentDay = {} } = { ...patientPlan };
    const { id: currentDayId } = { ...currentDay };
    const { weeks = [], bodyParts = [] } = { ...plan };
    const cws = weeks.map((w) => {
      const { days = [] } = { ...w };
      return days.map(({ id }) => id).indexOf(currentDayId);
    });
    const weekIndex = cws.find((c) => c !== -1);
    const currentDayIndex = cws.indexOf(weekIndex);
    const parsedWeeks = weeks.map((week, index) => {
      const { title = `Semana ${index + 1}`, days: weekDays = [] } = { ...week };
      const days = weekDays
        .map((day) => {
          const { weekNumber, exercises = [] } = { ...day };
          return exercises.map((exercise) => ({ ...exercise, weekNumber }));
        })
        .reduce((a, b) => [...a, ...b], []);
      return { ...week, title, days, disabled: index <= currentDayIndex };
    });
    setStoreData('patient', { ...patient, id: patientId });
    setStoreData('customPlan', { ...plan, planId, bodyParts: (bodyParts || []).map(({ title }) => title) });
    setStoreData('weeks', parsedWeeks);
    setStoreData('step', EDIT_PLAN);
    push(ROUTES.EDIT_PATIENT);
  };

  const handleChangePage = (currentPage) => {
    setPage(currentPage);
  };

  return (
    <div className="w-100">
      <div className="patient__title">
        <div className="patient__title_text">
          <Icon name="plans" className="icon" />
          <p className="title">Planes asignados</p>
        </div>
        {!isEmpty(plans) && (
          <Select
            className="patient__title_select"
            value={planFilter}
            options={typeOfPlans}
            styles={customStyles}
            onChange={handleChangeFilter}
          />
        )}
      </div>
      <div className="patient__tab_content plans">
        {isEmpty(plans) ? (
          <NoPatientData tab="plans" onCreateData={onCreatePlan} />
        ) : (
          <>
            <table>
              <tbody>
                {patientsPlan.map((item) => {
                  const { id, plan, status } = { ...item };
                  return (
                    <tr key={id}>
                      <td>
                        <PlanCard
                          plan={plan}
                          status={status}
                          onClick={onEditPlan(item)}
                          planId={id}
                          refetch={refetchPatient}
                          isInpatient
                        />
                      </td>
                    </tr>
                  );
                })}
                {isEmpty(patientsPlan) && (
                  <tr>
                    <td>
                      <NoPlansFound isModal />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="edit-plan-container">
              <Pagination currentPage={page} totalPages={pages} onPageChange={handleChangePage} />
              <SolidButton onClick={onCreatePlan}>Crear plan</SolidButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

SuperClinicPlans.defaultProps = {
  refetchPatient: () => {},
};

SuperClinicPlans.propTypes = {
  patient: PropTypes.shape({
    patientId: PropTypes.string,
    plans: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  refetchPatient: PropTypes.func,
};

export default SuperClinicPlans;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import { CREATE_PROGRAM_STEPS } from 'components/createProgram/service/programConstants';
import { CreateProgramProvider, useCreateProgramContext } from 'components/createProgram/CreateProgramProvider';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import CreateProgramStep from 'components/createProgram/CreateProgramStep';
import ProgramDetail from 'components/createProgram/ProgramDetail';
import ReadingTemplates from 'components/createProgram/ReadingTemplates';
import AddAudio from 'components/createProgram/AddAudio';

import './create-program.scss';

const CreateProgramWrapper = () => (
  <CreateProgramProvider>
    <CreateProgram />
  </CreateProgramProvider>
);

const CreateProgram = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const {
    searchId,
    isLoading,
    createProgramState: { step },
  } = useCreateProgramContext();

  return (
    <section className="d-flex">
      <LeftMenu />
      <div className="dashboard__main">
        <Header title={searchId ? t('leftMenuHeader.editProgram') : t('leftMenuHeader.program')} shadow />
        <div className="program">
          <CreateProgramStep />
          <div className="program__steps">
            {step === CREATE_PROGRAM_STEPS.PROGRAM_DETAIL && <ProgramDetail />}
            {step === CREATE_PROGRAM_STEPS.READING_TEMPLATES && <ReadingTemplates />}
            {step === CREATE_PROGRAM_STEPS.AUDIO && <AddAudio />}
          </div>
        </div>
      </div>
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={isLoading} />
    </section>
  );
};

export default CreateProgramWrapper;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import { GET_COMPANY_ORDERS } from 'apollo/queries';
import { useAuthQuery, useFilterTabs, useFormInput } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { useFilterByProperty } from 'utils/hooks/useFilterByProperty';
import { handleFilterTabs } from 'utils/handleFilterTabs';
import { useToggle } from 'utils/hooks/useToggle';
import { ROLES, ROUTES } from 'utils/constants';
import { useFullfillOrder } from 'components/orders/service/useFullfillOrder';

import LeftMenuEmployers from 'components/LeftMenuEmployers';
import Header from 'components/Header';
import OrdersList from 'components/orders/OrdersList';
import OrderDetail from 'components/orders/OrderDetail';
import Pagination from 'components/Pagination';
import SearchFilter from 'components/SearchFilter';
import FilterTabs from 'components/Employees/FilterTabs';
import NoOrdersFound from 'components/Forms/NoFormsFound';
import { NoMembers } from 'components/EmptyState';

import noProducts from 'assets/images/no-products.svg';

const rowsPerPage = 8;

const Orders = () => {
  useRedirectByRole([ROLES.COMPANY]);
  const { t } = useTranslation();
  const { push } = useHistory();
  const filters = [t('tabs.fulfilled'), t('tabs.unfulfilled'), t('tabs.all')];
  const { loading, data, refetch: refetchOrders } = useAuthQuery(GET_COMPANY_ORDERS);
  const { companyOrders: allCompanyOrders = [] } = { ...data };

  const [orders, setOrders] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [orderKey, setOrderKey] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState({});
  const { id: orderId = '' } = { ...selectedOrder };

  const [openOrder, setOpenOrder] = useToggle();
  const searchOrders = useFormInput('');
  const { value = '' } = { ...searchOrders };
  const { handleChangeFilter, filterTab } = useFilterTabs(filters);
  const fulfilledOrders = useFilterByProperty({ data: allCompanyOrders, property: 'status', value: 'fulfilled' });
  const unfulfilledOrders = useFilterByProperty({ data: allCompanyOrders, property: 'status', value: 'unfulfilled' });
  const { handleFulfillOrder, spinner } = useFullfillOrder({ orderId, refetchOrders, setOpenOrder, setOrderKey });

  useEffect(() => {
    refetchOrders();
  }, []);

  const isNewResults = (results) => {
    const rIds = (results || []).map(({ id, name }) => `${id}${name}`).join(',');
    const fIds = (filtered || []).map(({ id, name }) => `${id}${name}`).join(',');
    return rIds !== fIds;
  };

  useEffect(() => {
    let temporal = handleFilterTabs(filterTab, {
      activeItems: fulfilledOrders,
      unactiveItems: unfulfilledOrders,
      allItems: allCompanyOrders,
      filterItems: filters,
    });
    if (value) {
      temporal = handleFilterTabs(filterTab, {
        activeItems: fulfilledOrders,
        unactiveItems: unfulfilledOrders,
        allItems: allCompanyOrders,
        filterItems: filters,
      }).filter((order) => {
        const {
          name = '',
          employee: { name: employeeName = '' },
        } = { ...order };
        const employeeFound = `${name}-${employeeName}`.toLowerCase();
        return employeeFound.indexOf(`${value}`.toLowerCase()) !== -1;
      });
    }
    const totalPages = temporal.length > 0 ? Math.ceil(temporal.length / rowsPerPage) : 0;
    if (page !== 1) setPage(1);
    if (pages !== totalPages) setPages(totalPages);
    if (isNewResults(temporal) || orderKey) setFiltered(temporal);
  }, [allCompanyOrders, filterTab, value, orderKey]);

  useEffect(() => {
    const init = page * rowsPerPage - rowsPerPage;
    const end = init + rowsPerPage;
    setOrders(filtered.slice(init, end));
  }, [filtered, page]);

  useEffect(() => {
    if (!openOrder) {
      setSelectedOrder({});
    }
  }, [openOrder]);

  const handlePageChange = (currentPage) => {
    setPage(currentPage);
  };

  const handleSelectOrder = (order) => {
    setSelectedOrder(order);
    setOpenOrder();
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenuEmployers />
        <div className="dashboard__main">
          <Header title={t('leftMenuHeader.companies.orders')} shadow />
          <section className="rewards">
            {!loading && isEmpty(allCompanyOrders) && (
              <NoMembers
                title={t('orders.empty.title')}
                subtitle={t('orders.empty.description')}
                button={t('orders.empty.button')}
                handleClick={() => push(ROUTES.EMPLOYEES)}
                image={noProducts}
              />
            )}
            {!loading && !isEmpty(allCompanyOrders) && (
              <>
                <div className="rewards__header mb-2">
                  <FilterTabs filters={filters} filterTab={filterTab} handleChangeFilter={handleChangeFilter} />
                  <SearchFilter searchItems={searchOrders} placeholder={t('searchLabel.orders')} />
                </div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '35%' }}>{t('orders.tableHeader.name')}</th>
                      <th style={{ width: '15%' }}>{t('orders.tableHeader.status')}</th>
                      <th style={{ width: '20%' }}>{t('orders.tableHeader.employee')}</th>
                      <th style={{ width: '20%' }}>{t('orders.tableHeader.createdAt')}</th>
                      <th style={{ width: '10%' }}>{t('orders.tableHeader.detail')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <OrdersList orders={orders} selectOrder={handleSelectOrder} />
                    {isEmpty(orders) && <NoOrdersFound />}
                  </tbody>
                </table>
                <Pagination currentPage={page} totalPages={pages} onPageChange={handlePageChange} />
              </>
            )}
          </section>
        </div>
      </section>
      <OrderDetail
        order={selectedOrder}
        modalIsOpen={openOrder}
        toggleModal={setOpenOrder}
        handleFulfillOrder={handleFulfillOrder}
      />
      <Loader
        className="spinner"
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        visible={loading || spinner}
      />
    </>
  );
};

export default Orders;

import React, { useEffect, useState } from 'react';
import { path, propOr, isEmpty } from 'ramda';
import Loader from 'react-loader-spinner';

import { GET_PLANS } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SolidButton from 'components/SolidButton';
import PlanCard from 'components/Plan/PlanCard';
import Pagination from 'components/Pagination';
import { StoreConsumer } from 'components/StoreProvider';

import searchIcon from 'assets/images/search.svg';
import noPlans from 'assets/images/no-plans.svg';

import './plans.scss';

const rowsPerPage = 3;
const tabs = ['Mis plantillas', 'HealthPhy'];

const Plans = (props) => {
  useRedirectByRole([ROLES.CLINIC, ROLES.SUPER_CLINIC]);
  const changeRoute = path(['history', 'push'])(props);
  const { loading, data, refetch } = useAuthQuery(GET_PLANS);
  const allPlans = propOr([], 'planTemplates')(data);
  const userPlans = allPlans.filter(({ sharedTemplate }) => !sharedTemplate);
  const adminPlans = allPlans.filter(({ sharedTemplate }) => sharedTemplate);

  const [plans, setPlans] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    refetch();
  }, []);

  const getPlans = () => {
    if (tab === 0) {
      return userPlans;
    }
    return adminPlans;
  };
  const isNewResults = (results) => {
    const rIds = (results || []).map(({ id }) => id).join(',');
    const fIds = (filtered || []).map(({ id }) => id).join(',');
    return rIds !== fIds;
  };
  useEffect(() => {
    let temporal = getPlans();
    if (search) {
      temporal = getPlans().filter((item) => {
        const { title, description, bodyParts } = { ...item };
        const plan = `${title}-${description}-${(bodyParts || []).join('-')}`.toLowerCase();
        return plan.indexOf(`${search}`.toLowerCase()) !== -1;
      });
    }
    const totalPages = temporal.length > 0 ? Math.ceil(temporal.length / rowsPerPage) : 0;
    if (page !== 1) setPage(1);
    if (pages !== totalPages) setPages(totalPages);
    if (isNewResults(temporal)) setFiltered(temporal);
  }, [allPlans, tab, search]);

  useEffect(() => {
    const init = page * rowsPerPage - rowsPerPage;
    const end = init + rowsPerPage;
    setPlans(filtered.slice(init, end));
  }, [filtered, page]);

  const onCreatePlan = (setStoreData) => () => {
    setStoreData('weeks', []);
    setStoreData('customPlan', { title: '', description: '' });
    changeRoute('/crear-plan');
  };

  const handlePageChange = (currentPage) => {
    setPage(currentPage);
  };

  return (
    <StoreConsumer>
      {({ setStoreData }) => (
        <div className="d-flex">
          <LeftMenu />
          <div className="dashboard__main">
            <Header title="Planes personalizados" changeRoute={changeRoute} />
            <div className="dashboard__content--plans">
              {loading && (
                <div className="loading-container">
                  <Loader type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
                </div>
              )}
              {!loading && isEmpty(allPlans) && (
                <div className="plans__empty-state">
                  <div className="content">
                    <p className="title">¡Aún no tienes planes personalizados!</p>
                    <p className="subtitle">
                      Inicia creando los planes personalizados que usarás para el tratamiento de tus pacientes.
                    </p>
                    <SolidButton className="mx-2 text-center" onClick={onCreatePlan(setStoreData)}>
                      Agregar
                    </SolidButton>
                  </div>
                  <img src={noPlans} alt="no plans" className="image" />
                </div>
              )}
              {!loading && !isEmpty(allPlans) && (
                <div className="plans__container">
                  <div className="search-box-container">
                    <div className="search-box">
                      <input
                        onChange={(ev) => setSearch(ev.target.value)}
                        value={search}
                        placeholder="Buscar un plan"
                      />
                      <img src={searchIcon} className="icon" alt="search icon" />
                    </div>
                    {tabs.map((item, index) => (
                      <button
                        type="button"
                        key={item}
                        className={`tab-plans ${index === 0 ? 'border-right' : 'border-left'} ${
                          index === tab ? 'active' : ''
                        }`}
                        onClick={() => setTab(index)}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                  <table>
                    <tbody>
                      {plans.map((plan) => {
                        const { id } = { ...plan };
                        return (
                          <tr key={id} onClick={() => changeRoute(`/plan?id=${id}`)}>
                            <td>
                              <PlanCard plan={plan} planId={id} refetch={refetch} isEditable />
                            </td>
                          </tr>
                        );
                      })}
                      {isEmpty(plans) && (
                        <tr className="empty-state">
                          <td>
                            <div className="patients__no-results">
                              <div className="content">
                                <p className="title">No se han encontrado resultados</p>
                                <p className="subtitle">
                                  Asegúrate de que el criterio esté escrito correctamente o intenta ajustar el filtro
                                  para encontrar lo que estas buscando.
                                </p>
                                <SolidButton className="mx-2 text-center" onClick={onCreatePlan(setStoreData)}>
                                  Crear plan
                                </SolidButton>
                              </div>
                              <img src={noPlans} alt="no results" className="image" />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="pages-button">
                    <Pagination currentPage={page} totalPages={pages} onPageChange={handlePageChange} />
                    {!isEmpty(plans) && <SolidButton onClick={onCreatePlan(setStoreData)}>Crear plan</SolidButton>}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </StoreConsumer>
  );
};

export default Plans;

import { ROLES } from 'utils/constants';

const getChat = (userRole = ROLES.CLINIC) => {
  if (userRole === ROLES.CLINIC) {
    window.Beacon('init', '74bf5082-bcfb-4548-bae3-c3b2e4df5c3e');
  }
};
const destroyChat = () => {
  window.Beacon('destroy');
};

export { getChat, destroyChat };

import React from 'react';
import PropTypes from 'prop-types';

const InfoScreen = ({ label, content, gif }) => (
  <section className="info-screen">
    <div className="question__gif">
      <img src={gif} alt="this is a Gif" />
    </div>
    <h2 className="info-screen__title">{label}</h2>
    <p className="info-screen__content">{content}</p>
  </section>
);

InfoScreen.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  gif: PropTypes.string.isRequired,
};

export default InfoScreen;

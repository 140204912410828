import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { CgTrash } from 'react-icons/cg';
import { BiDuplicate } from 'react-icons/bi';
import { v4 as uuid } from 'uuid';
import { Draggable } from 'react-beautiful-dnd';

import { useToggle } from 'utils/hooks/useToggle';
import { useEvieProgramContext } from 'components/eviePrograms/EvieProgramProvider';
import { ACTIONS } from 'components/eviePrograms/service';

import EvieAddSessionExercises from '../EvieAddSessionExercises/EvieAddSessionExercises';
import EvieWarning from '../EvieWarning/EvieWarning';

const EvieProgramSession = ({ index, programSession }) => {
  const { t } = useTranslation();
  const { dispatch, state } = useEvieProgramContext();
  const {
    selectedLevel: { programSessions },
  } = state;
  const { id } = programSession;
  const sessionNumber = index + 1;

  const [isAddingSessionExercises, setIsAddingSessionExercises] = useToggle();
  const [sessionWarning, setSessionWarning] = useToggle();

  const handleRemoveSession = () => {
    dispatch({ type: ACTIONS.REMOVE_SESSION, payload: id });
  };

  const handleCloneSession = () => {
    const newSessions = [...programSessions];
    newSessions.splice(sessionNumber, 0, {
      ...programSession,
      isNew: true,
      id: uuid(),
      sessionExercises: programSession.sessionExercises.map((sessionEx) => ({ ...sessionEx, isNew: true })),
    });
    dispatch({ type: ACTIONS.CLONE_SESSION, payload: { newSessions } });
  };

  const waringData = {
    open: sessionWarning,
    onCancelAction: setSessionWarning,
    onConfirmAction: handleRemoveSession,
    title: t('eviePrograms.warning.sessionTitle'),
  };

  return (
    <>
      <Draggable draggableId={id} index={index} isDragDisabled={isAddingSessionExercises}>
        {(provided) => (
          <li
            className="evie-program__levels-list_level"
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <span className="evie-program__levels-list_label-level label-session">
              {t('eviePrograms.level.labelSession')} {sessionNumber}
            </span>
            <div className="evie-program__handlers">
              <CgTrash onClick={setSessionWarning} />
              <BiDuplicate onClick={handleCloneSession} />
              <button type="button" className="evie-program__handlers_selector" onClick={setIsAddingSessionExercises}>
                {isAddingSessionExercises ? <FaChevronDown /> : <FaChevronRight />}
              </button>
            </div>
          </li>
        )}
      </Draggable>
      {isAddingSessionExercises && (
        <EvieAddSessionExercises programSession={programSession} sessionNumber={sessionNumber} />
      )}
      {waringData && <EvieWarning {...waringData} />}
    </>
  );
};

EvieProgramSession.propTypes = {
  index: PropTypes.number.isRequired,
  programSession: PropTypes.shape({ id: PropTypes.string, sessionExercises: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
};

export default EvieProgramSession;

import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useCreateReadingContext } from '../CreateReadingProvider';

import BuilderBlockCard from '../BuilderBlockCard';

const BuilderBlocks = () => {
  const { dragEnd, builderState } = useCreateReadingContext();
  const { content } = builderState;
  return (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId="builder-blocks-id" type="list">
        {(provided) => (
          <ul className="builder-blocks" ref={provided.innerRef} {...provided.droppableProps}>
            {content.map((builderBlock, builderBlockIndex) => {
              const { id } = builderBlock;
              return <BuilderBlockCard key={id} builderBlock={builderBlock} index={builderBlockIndex} />;
            })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default BuilderBlocks;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { isEmpty, pathOr } from 'ramda';
import Loader from 'react-loader-spinner';

import { GET_COMPANY_PROFILE } from 'apollo/queries';
import { UPDATE_PATIENT_STATUS } from 'apollo/mutations';
import { useAuthQuery, useFormInput, useFilterTabs } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { useFilterByProperty } from 'utils/hooks/useFilterByProperty';
import { handleFilterTabs } from 'utils/handleFilterTabs';
import { useToggle } from 'utils/hooks/useToggle';
import { ROLES, PAYMENT_MODE } from 'utils/constants';
import showNotification from 'utils/showNotification';

import { useAppState } from 'components/StoreProvider';
import LeftMenuEmployers from 'components/LeftMenuEmployers';
import Header from 'components/Header';
import SearchFilter from 'components/SearchFilter';
import EmployeesList from 'components/Employees/EmployeesList';
import FilterTabs from 'components/Employees/FilterTabs';
import EmployeeSpend from 'components/Employees/EmployeeSpend';
import Pagination from 'components/Pagination';
import NoEmployeesFound from 'components/Forms/NoFormsFound';
import { NoMembers } from 'components/EmptyState';
import ChangeStatusAlert from 'components/Patients/Patient/ChangeStatusAlert';

import noEmployees from 'assets/images/no-patients.svg';

import './employees.scss';

const rowsPerPage = 10;

const Employees = () => {
  useRedirectByRole([ROLES.COMPANY]);
  const { t } = useTranslation();
  const filters = [t('tabs.active'), t('tabs.inactive'), t('tabs.all')];
  const { loading, data, refetch } = useAuthQuery(GET_COMPANY_PROFILE);
  const { getCompanyInfo = {} } = { ...data };
  const { employees: allEmployees = [], invitationLink = '', profile = {} } = { ...getCompanyInfo };
  const { paymentMode, currency } = { ...profile };
  const isNoSubscription = paymentMode !== PAYMENT_MODE.SUBSCRIPTION;
  const [updatePatientStatus] = useMutation(UPDATE_PATIENT_STATUS);
  const [employees, setEmployees] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState({});

  const [employeeSpend, setEmployeeSpend] = useToggle();
  const [isAlert, setIsAlert] = useToggle();
  const { toggleInviteCreateMember = () => {} } = useAppState();
  const searchEmployees = useFormInput('');
  const { value = '' } = { ...searchEmployees };
  const { handleChangeFilter, filterTab } = useFilterTabs(filters);
  const activeEmployees = useFilterByProperty({ data: allEmployees, property: 'status', value: 'active' });
  const unactiveEmployees = useFilterByProperty({ data: allEmployees, property: 'status', value: 'unactive' });

  useEffect(() => {
    refetch();
  }, []);

  const isNewResults = (results) => {
    const rIds = (results || []).map(({ id, name, gender }) => `${id}${name}${gender}`).join(',');
    const fIds = (filtered || []).map(({ id, name, gender }) => `${id}${name}${gender}`).join(',');
    return rIds !== fIds;
  };

  useEffect(() => {
    let temporal = handleFilterTabs(filterTab, {
      activeItems: activeEmployees,
      unactiveItems: unactiveEmployees,
      allItems: allEmployees,
      filterItems: filters,
    });
    if (value) {
      temporal = handleFilterTabs(filterTab, {
        activeItems: activeEmployees,
        unactiveItems: unactiveEmployees,
        allItems: allEmployees,
        filterItems: filters,
      }).filter((employee) => {
        const { name = '', email = '', gender = '' } = { ...employee };
        const employeeFound = `${name}-${email}-${gender}`.toLowerCase();
        return employeeFound.indexOf(`${value}`.toLowerCase()) !== -1;
      });
    }
    const totalPages = temporal.length > 0 ? Math.ceil(temporal.length / rowsPerPage) : 0;
    if (page !== 1) setPage(1);
    if (pages !== totalPages) setPages(totalPages);
    if (isNewResults(temporal)) setFiltered(temporal);
  }, [allEmployees, filterTab, value]);

  useEffect(() => {
    const init = page * rowsPerPage - rowsPerPage;
    const end = init + rowsPerPage;
    setEmployees(filtered.slice(init, end));
  }, [filtered, page]);

  const onUpdateStatus = async () => {
    const response = await updatePatientStatus({
      variables: {
        patientId: selectedEmployee.id,
      },
    });

    const errors = pathOr([], ['data', 'updatePatientStatus', 'errors'])(response);
    const emptyErrors = isEmpty(errors);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [t('notification.updateEmployeeStatus')] : errors,
    });
    if (emptyErrors) {
      refetch();
      setIsAlert();
      setSelectedEmployee({});
    }
  };

  const handlePageChange = (currentPage) => {
    setPage(currentPage);
  };

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
    setIsAlert();
  };

  const handleEmployeeSpend = (employee) => {
    setSelectedEmployee(employee);
    setEmployeeSpend();
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenuEmployers />
        <div className="dashboard__main">
          <Header title={t('leftMenuHeader.companies.employees')} shadow />
          <section className="employees">
            {loading && (
              <div className="loading-container">
                <Loader type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
              </div>
            )}
            {!loading && isEmpty(allEmployees) && (
              <NoMembers
                title={t('employees.empty.emptyTitle')}
                button={t('button.invite')}
                link={invitationLink}
                handleClick={toggleInviteCreateMember}
                image={noEmployees}
              />
            )}
            {!loading && !isEmpty(allEmployees) && (
              <div className="employees__content">
                <FilterTabs filters={filters} filterTab={filterTab} handleChangeFilter={handleChangeFilter} />
                <SearchFilter searchItems={searchEmployees} placeholder={t('searchLabel.employees')} />
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '10%' }}> </th>
                      <th style={{ width: '30%' }}>{t('employees.tableHeader.name')}</th>
                      <th style={{ width: '15%' }}>{t('employees.tableHeader.status')}</th>
                      <th style={{ width: '25%' }}>{t('employees.tableHeader.email')}</th>
                      <th style={{ width: '15%' }}>{t('employees.tableHeader.changeStatus')}</th>
                      <th style={{ width: '5%' }}> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <EmployeesList
                      employees={employees}
                      toggleAlert={handleSelectEmployee}
                      toggleEmloyeeSpend={handleEmployeeSpend}
                      isNoSubscription={isNoSubscription}
                    />
                    {isEmpty(employees) && <NoEmployeesFound />}
                  </tbody>
                </table>
                <Pagination currentPage={page} totalPages={pages} onPageChange={handlePageChange} />
              </div>
            )}
          </section>
        </div>
      </section>
      {employeeSpend && (
        <EmployeeSpend
          modalIsOpen={employeeSpend}
          toggleModal={setEmployeeSpend}
          selectedEmployee={selectedEmployee}
          currency={currency}
        />
      )}
      {isAlert && (
        <ChangeStatusAlert
          isAlert={isAlert}
          name={selectedEmployee.name}
          updateStatus={onUpdateStatus}
          toggleAlert={setIsAlert}
        />
      )}
    </>
  );
};

export default Employees;

import React, { useContext, createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';

import { queryParams } from 'utils/string';
import { CREATE_PROGRAM_STEPS, ACTIONS } from 'components/createProgram/service/programConstants';
import { createProgramReducer } from 'components/createProgram/service/createProgramReducer';
import { useGetProgram } from 'components/createProgram/service/useGetProgram';
import { useGetReadingTemplates } from 'components/createProgram/service/useGetReadingTemplates';
import { useCreateUpdateProgram } from 'components/createProgram/service/useCreateUpdateProgram';

const initialState = {
  step: CREATE_PROGRAM_STEPS.PROGRAM_DETAIL,
  backgroundImage: '',
  doctorImage: '',
  title: '',
  doctorName: '',
  duration: '',
  planId: '',
  description: '',
  templateId: '',
  readingDays: [],
};

const CreateProgramContext = createContext();

const CreateProgramProvider = ({ children }) => {
  const {
    location: { search },
  } = useHistory();
  const { id: searchId } = queryParams(search);

  const { readingTemplates } = useGetReadingTemplates();
  const { isLoading, program } = useGetProgram(searchId);
  const {
    backgroundImage = {},
    doctorImage = {},
    title = '',
    doctorName = '',
    duration = '',
    planTemplate = {},
    description = '',
    readingTemplate = {},
    readingDays = [],
  } = { ...program };

  const { thumb: backThumb = '' } = { ...backgroundImage };
  const { thumb: docThumb = '' } = { ...doctorImage };
  const { id: planId = '' } = { ...planTemplate };
  const { id: templateId = '' } = { ...readingTemplate };
  const programInitState = {
    backgroundImage: backThumb,
    doctorImage: docThumb,
    title,
    doctorName,
    duration,
    planId,
    description,
    templateId,
    readingDays,
  };

  const [state, dispatch] = useReducer(createProgramReducer, initialState);

  useEffect(() => {
    if (!isEmpty(program)) {
      dispatch({ type: ACTIONS.GET_PROGRAM, payload: programInitState });
    }
  }, [program]);

  const { createEditProgram, spinner } = useCreateUpdateProgram(searchId, state);

  return (
    <CreateProgramContext.Provider
      value={{
        searchId,
        isLoading: isLoading || spinner,
        createProgramState: state,
        readingTemplates,
        dispatch,
        handleCreateEditProgram: createEditProgram,
      }}
    >
      {children}
    </CreateProgramContext.Provider>
  );
};

CreateProgramProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useCreateProgramContext() {
  const context = useContext(CreateProgramContext);
  if (!context) {
    throw new Error('Expected an CreateProgramContext somewhere in the react tree to set context value');
  }
  return context;
}

export { useCreateProgramContext, CreateProgramProvider };

export const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 200,
  padding: 20,
  borderWidth: 1,
  borderRadius: 12,
  borderColor: '#EA7DAE',
  borderStyle: 'dashed',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

export const activeStyle = {
  borderColor: '#2196f3',
};

export const acceptStyle = {
  borderColor: '#2196f3',
};

export const rejectStyle = {
  borderColor: '#ff1744',
};

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: '300',
    fontSize: '14px',
    color: state.isSelected ? '#8093FC' : '#606060',
    backgroundColor: '#fff',
    ':hover': {
      color: '#fff',
      backgroundColor: '#8093FC',
      cursor: 'pointer',
    },
  }),
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#606060',
    fontWeight: '300',
    fontSize: '14px',
  }),
};

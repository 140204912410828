import { v4 as uuid } from 'uuid';

export const weekDays = [
  { id: uuid(), intervals: [], weekDay: 'monday' },
  { id: uuid(), intervals: [], weekDay: 'tuesday' },
  { id: uuid(), intervals: [], weekDay: 'wednesday' },
  { id: uuid(), intervals: [], weekDay: 'thursday' },
  { id: uuid(), intervals: [], weekDay: 'friday' },
  { id: uuid(), intervals: [], weekDay: 'saturday' },
  { id: uuid(), intervals: [], weekDay: 'sunday' },
];

export const handleSringToNumber = (string) => parseInt(string, 10);

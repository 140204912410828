import decode from 'jwt-decode';
// import { path } from 'ramda';
// import { http } from 'libs';

// Token string name
const TOKEN_NAME = 'token';
const USER_NAME = 'user';

export const getAuthInfo = () => {
  // Retrieves the user token from sessionStorage
  const token =  localStorage.getItem(TOKEN_NAME);
  const user = JSON.parse(localStorage.getItem(USER_NAME));

  return { token, user };
};

export const loggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const { token } = getAuthInfo(); // Getting token from sessionstorage
  return !!token;
};

export const setAuthInfo = ({ token, user }) => {
  // Saves user token to sessionStorage
  localStorage.setItem(TOKEN_NAME, token);
  localStorage.setItem(USER_NAME, JSON.stringify(user));
};

export const logout = () => {
  // Clear user token from sessionStorage
  localStorage.removeItem(TOKEN_NAME);
  localStorage.removeItem(USER_NAME);
};

export const getProfile = () => {
  // Using jwt-decode to decode the token
  return decode(getAuthInfo());
};

// const checkStatus = (response) => {
//   // raises an error in case response status is not a success
//   if (response.status >= 200 && response.status < 300) {
//     // Success status lies between 200 to 300
//     return response;
//   }
//   const error = new Error(response.statusText);
//   error.response = response;
//   throw error;
// };

// const refreshTokenHandler = (response) => {
//   const refreshedToken = path(['data', 'refreshedToken'])(response);
//   if (refreshedToken) {
//     debugger;
//     setAuthInfo(refreshedToken);
//   }
//   return response;
// };

// const unauthorizedHandler = ({ response }) => {
//   if (response) {
//     if (response.status === 401 || response.status === 403) {
//       logout();
//       window.location.replace('/');
//     }
//     const error = new Error(response.statusText);
//     error.response = response;
//     throw error;
//   }
// };

// export const apiCall = (method, url, options) => {
//   // performs api calls sending the required authentication headers
//   const headers = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//   };

//   // Setting Authorization header if logged in
//   // Authorization: xxxxxxx.xxxxxxxx.xxxxxx
//   if (loggedIn()) {
//     headers.Authorization = getAuthInfo();
//   }

//   return http[method](url, {
//     headers,
//     ...options,
//   })
//     .then(checkStatus)
//     .then(refreshTokenHandler)
//     .catch(unauthorizedHandler);
// };

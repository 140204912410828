import React from 'react';
import { useHistory } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';

import { ROUTES } from 'utils/constants';

const CompaniesList = ({ companies }) => {
  const { push } = useHistory();

  return (
    <>
      {(companies || []).map((company) => {
        const { id, name, employees } = { ...company };
        return (
          <tr key={id}>
            <td style={{ width: '45%' }}>{name}</td>
            <td style={{ width: '45%' }}>{employees.length}</td>
            <td className="actions-col" style={{ width: '10%' }}>
              <button type="button" onClick={() => push(`${ROUTES.COMPANY}?id=${id}`)} className="form-detail">
                <IoIosArrowDown />
              </button>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default CompaniesList;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaRegImage } from 'react-icons/fa';
import { IoIosRemoveCircleOutline } from 'react-icons/io';

const SelectedItemList = ({ selectedContent, removeItem, dragEnd }) => {
  const { t } = useTranslation();

  return (
    <section className="evie-program__selected-item-list">
      <h3 className="evie-program__selected-item-list_title">
        <span className="evie-program__selected-item-list_title-label">
          {t('eviePrograms.additionalContent.selectedItems.label')}
        </span>
        <span className="evie-program__selected-item-list_title-count">
          <Trans values={{ length: selectedContent.length }}>eviePrograms.additionalContent.selectedItems.count</Trans>
        </span>
      </h3>
      <DragDropContext onDragEnd={dragEnd}>
        <Droppable droppableId="selected-item-id" type="list">
          {(provided) => (
            <ul className="evie-program__selected-list" ref={provided.innerRef} {...provided.droppableProps}>
              {selectedContent.map((item, itemIndex) => {
                const { temporalId, title, image, thumbnailImage = {}, backgroundImage = {} } = item;
                const { thumb } = thumbnailImage;
                const { thumb: thumbBg } = backgroundImage;
                return (
                  <Draggable key={temporalId} draggableId={temporalId} index={itemIndex}>
                    {(providedItem) => (
                      <li
                        className="evie-program__selected-list_item"
                        ref={providedItem.innerRef}
                        {...providedItem.dragHandleProps}
                        {...providedItem.draggableProps}
                      >
                        <div
                          className="image-input__bg evie-program__selected-list_item-img"
                          style={{ backgroundImage: `url(${thumb || thumbBg || image})` }}
                        >
                          {!thumb && !thumbBg && !image && <FaRegImage className="image-input__bg_icon" />}
                        </div>
                        <h3 className="evie-program__item-list_item-title">{title}</h3>
                        <IoIosRemoveCircleOutline
                          className="evie-program__selected-list_item-icon"
                          onClick={() => removeItem(temporalId)}
                        />
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
};

SelectedItemList.propTypes = {
  selectedContent: PropTypes.arrayOf(
    PropTypes.shape({
      temporalId: PropTypes.string,
      title: PropTypes.string,
      image: PropTypes.string,
      thumbnailImage: PropTypes.shape({
        thumb: PropTypes.string,
      }),
      backgroundImage: PropTypes.shape({
        thumbBg: PropTypes.string,
      }),
    }),
  ).isRequired,
  removeItem: PropTypes.func.isRequired,
  dragEnd: PropTypes.func.isRequired,
};

export default SelectedItemList;

import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, pathOr } from 'ramda';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Formik, Field, Form } from 'formik';

import showNotification from 'utils/showNotification';
import { CREATE_PLAN, CREATE_WEEK, EDIT_PLAN } from 'utils/steps';
import { customPlanForm } from 'utils/validationSchema';
import { mapWeeksToUpdate } from 'utils/plan';
import { CREATE_CUSTOM_PLAN, UPDATE_CUSTOM_PLAN } from 'apollo/mutations';
import { useAuthQuery } from 'utils/hooks';
import { ROUTES } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';
import { BODY_PARTS } from 'apollo/queries';

import { TextInput, TextAreaDescription, MultiSelect } from 'components/FormComponents';
import Button from 'components/SolidButton';
import Icon from 'components/Icon';
import { StoreConsumer } from 'components/StoreProvider';

import noWeeks from 'assets/images/no-weeks.svg';

import './create-custom-plan.scss';

const planTypes = [
  { label: 'Pausas Activas', value: 'active_pause' },
  { label: 'Rehabilitación', value: 'rehabilitation' },
];

const CreateCustomPlan = ({ onSetStep, patientId, changeRoute }) => {
  const { isSuperClinic } = getUserRole();
  const [createCustomPlan] = useMutation(CREATE_CUSTOM_PLAN);
  const [updateCustomPlan] = useMutation(UPDATE_CUSTOM_PLAN);
  const { data } = useAuthQuery(BODY_PARTS);
  const { bodyParts = [] } = { ...data };

  const memoBodyParts = useMemo(() => {
    const normalizeData = (bodyParts || []).map((bodyPart) => {
      const { title = '' } = { ...bodyPart };
      return title;
    });
    return normalizeData;
  }, [bodyParts]);

  const [showSpinner, setSpinner] = useState(false);
  const [size, setZise] = useState(1);
  const { replace = () => {} } = useHistory();

  const onRenderAddPanel = () => (
    <button className="side-add-panel" type="button" onClick={() => onSetStep(CREATE_WEEK)}>
      <p className="text">Agregar semana</p>
      <Icon name="add" className="icon" />
    </button>
  );
  const scrollToRef = (ref) => {
    const panel = document.getElementById('weeks');
    !!ref.current && panel && panel.scrollTo(ref.current.offsetLeft, 0);
  };
  const weekRef = useRef(null);

  useEffect(() => {
    scrollToRef(weekRef);
  }, []);

  const onDuplicateWeek = (weeks, week, setStoreData) => {
    const { days = [] } = { ...week };
    const newWeek = {
      days,
      title: `Semana ${weeks.length + 1}`,
    };
    setStoreData('weeks', [...weeks, newWeek]);
    scrollToRef(weekRef);
  };

  const onDeleteWeek = (weeks, weekIndex, setStoreData) => {
    const newWeeks = weeks
      .filter((el, index) => el && weekIndex !== index)
      .map((el, index) => ({
        ...el,
        title: `Semana ${index + 1}`,
      }));
    setStoreData('weeks', newWeeks);
    scrollToRef(weekRef);
  };

  const onEditWeek = (week, index, setStoreData) => {
    setStoreData('week', { week, index });
    onSetStep(CREATE_WEEK);
  };

  const onRenderWeeks = (weeks, setStoreData) =>
    weeks.map((week, index) => {
      const { title, days: exercises = [], disabled = false } = { ...week };
      const days = exercises.reduce((rv, x) => {
        (rv[x.weekNumber] = rv[x.weekNumber] || []).push(x);
        return rv;
      }, {});
      const daysList = Object.keys(days);
      return (
        <div className="card" key={title} ref={index === weeks.length - 1 ? weekRef : null}>
          <div className="card-title-container">
            <p className="card-title">{title}</p>
            <div className="head-buttons">
              <Icon name="copy" className="icon" onClick={() => onDuplicateWeek(weeks, week, setStoreData)} />
              {!disabled && <Icon name="edit" className="icon" onClick={() => onEditWeek(week, index, setStoreData)} />}
              {!disabled && (
                <Icon name="trash" className="icon" onClick={() => onDeleteWeek(weeks, index, setStoreData)} />
              )}
            </div>
          </div>
          <div className="card-summary-container">
            <p className="card-summary-item">
              <span>{exercises.length}</span>Ejercicios
            </p>
            <p className="card-summary-item">
              <span>{daysList.length}</span>Días
            </p>
          </div>
          <div className="card-days-container">
            {daysList.map((dayNumber) => (
              <div className="card-day" key={`day-${dayNumber}`}>
                <div className="day-title">
                  <p>Día {dayNumber}</p>
                </div>
                <div className="day-data">
                  {(days[dayNumber] || []).map(({ id, title, image }, index) => (
                    <div className="card-exercise" key={`${id}-${index}`}>
                      <img src={image} className="image" alt="exercise" />
                      <div className="info">
                        <p>{title}</p>
                        <span>{`Dia ${dayNumber}`}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    });

  const onSavePlan = (values, setStoreData, weeks, isEdit) => async () => {
    setSpinner(true);
    const weeksToSend = weeks.map((week) => {
      const { title, days = [], id: weekId } = { ...week };
      return {
        title,
        weekId,
        days: days.map((day) => {
          const { weekNumber, id, order, rules } = { ...day };
          return {
            weekNumber,
            exerciseId: id,
            exercisePosition: order,
            rules,
          };
        }),
      };
    });

    const { title, description, id: planId, bodyParts: bodyPartsToSend, planType } = { ...values };
    const saveMutation = isEdit ? updateCustomPlan : createCustomPlan;
    const response = await saveMutation({
      variables: {
        ...(isEdit &&
          !!planId && {
            planId,
          }),
        patientId,
        plan: {
          title,
          description,
          bodyParts: (bodyPartsToSend || []).join(', '),
          isTemplate: false,
        },
        weeks: mapWeeksToUpdate(weeksToSend, values),
        ...(planType && {
          planType,
        }),
      },
    });

    const errors = pathOr([], ['data', isEdit ? 'updatePlan' : 'createCustomPlan', 'errors'])(response);
    const emptyErrors = isEmpty(errors);
    setSpinner(false);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha ${isEdit ? 'actualizado' : 'creado'} el plan exitosamente.`] : errors,
    });

    if (emptyErrors) {
      setStoreData('initialState');
      changeRoute(`${ROUTES.PATIENTS}/${patientId}`);
    }
  };

  const onGoBack = (setStoreData, step) => () => {
    setStoreData('weeks', []);
    setStoreData('customPlan', { title: '', description: '' });
    if (step === EDIT_PLAN) {
      setStoreData('step', '');
      replace(`${ROUTES.PATIENTS}/${patientId}`);
    } else {
      onSetStep(CREATE_PLAN);
    }
  };

  const onInputChange = (event, onChange, customPlan, setStoreData) => {
    const { value } = event.target;
    const key = event.target.name;
    setStoreData('customPlan', { ...customPlan, [key]: value });
    onChange(event);
  };

  return (
    <StoreConsumer>
      {({ weeks, customPlan, step, setStoreData }) => (
        <div className="custom-plan__main-container content__container_plan">
          <Formik
            initialValues={customPlan}
            onSubmit={(values) => onSavePlan(values, setStoreData, weeks, step === EDIT_PLAN)()}
            validationSchema={customPlanForm}
          >
            {({ handleSubmit, handleChange }) => (
              <Form onSubmit={handleSubmit} className="plans-view-create-plan-form">
                <div className="plans-view-create-plan-form__fields">
                  <Field
                    type="text"
                    label="Título"
                    name="title"
                    component={TextInput}
                    onChange={(e) => onInputChange(e, handleChange, customPlan, setStoreData)}
                  />
                  <div className="plans-view-create-plan-form__description">
                    <Field
                      className={size === 10 ? 'shadow' : ''}
                      rows={size}
                      type="text"
                      label="Descripción"
                      name="description"
                      component={TextAreaDescription}
                      onChange={(e) => onInputChange(e, handleChange, customPlan, setStoreData)}
                      onClick={() => setZise(10)}
                    />
                    <Icon
                      name="close"
                      className="plans-view-create-plan-form__description_icon"
                      onClick={() => setZise(1)}
                    />
                  </div>
                  <Field
                    type="text"
                    label="Partes del cuerpo relacionadas"
                    name="bodyParts"
                    component={MultiSelect}
                    options={memoBodyParts}
                    isMulti
                    onChange={(e) => onInputChange(e, handleChange, customPlan, setStoreData)}
                    placeholder="Seleccionar"
                  />
                  {isSuperClinic && (
                    <Field
                      type="text"
                      label="Tipo del plan"
                      name="planType"
                      component={MultiSelect}
                      options={planTypes}
                      onChange={(e) => onInputChange(e, handleChange, customPlan, setStoreData)}
                      placeholder="Seleccionar"
                    />
                  )}
                </div>
                <div className="plans-view-create-plan-form__buttons">
                  <Button type="button" className="secondary mr-3" onClick={onGoBack(setStoreData, step)}>
                    {step === EDIT_PLAN ? 'Cancelar' : 'Atrás'}
                  </Button>
                  <Button
                    type={isEmpty(weeks) ? 'button' : 'submit'}
                    category="rounded"
                    className={isEmpty(weeks) ? 'disabled' : ''}
                  >
                    {step === EDIT_PLAN ? 'Guardar' : 'Crear'} Plan
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          {isEmpty(weeks) ? (
            <div className="empty-state">
              <div className="content">
                <img src={noWeeks} alt="no weeks" className="image" />
                <p className="title">Aún no tienes semanas creadas</p>
                <p className="subtitle">
                  Empieza <span>agregando</span> tu primera semana para crear el plan de tu paciente
                </p>
              </div>
              {onRenderAddPanel()}
            </div>
          ) : (
            <>
              <div className="plan-container">
                <div className="weeks-container" id="weeks">
                  {onRenderWeeks(weeks, setStoreData)}
                </div>
                {onRenderAddPanel()}
              </div>
              <Loader
                type="ThreeDots"
                color="#495fd7"
                height={100}
                width={100}
                className="spinner"
                visible={showSpinner}
              />
            </>
          )}
        </div>
      )}
    </StoreConsumer>
  );
};

CreateCustomPlan.propTypes = {
  changeRoute: PropTypes.func.isRequired,
  onSetStep: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
};

export default CreateCustomPlan;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { productModalProps } from 'components/Rewards/productModalProps';

import Modal from 'components/Modal';

import medal from 'assets/images/medal.png';

import './product-detail-modal.scss';

const ProductDetailModal = ({ selectedProduct, modalIsOpen, toggleModal }) => {
  const { name = '', description = '', avatar = {}, status = '', points = 0, quantity = 0 } = { ...selectedProduct };
  const { original = '' } = { ...avatar };
  const { t } = useTranslation();

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={toggleModal} modalProps={productModalProps} closeable={false}>
      <AiOutlineCloseCircle onClick={toggleModal} className="add-product__close" />
      <header className="add-product__title">
        <h3>{t('rewards.productDetail.title')}</h3>
      </header>
      <section className="add-product__form product-detail">
        <section className="product-detail__content">
          <h4 className="product-detail__content--item">{t('rewards.productDetail.name')}</h4>
          <p className="product-detail__content--description">{name}</p>
        </section>
        <section className="product-detail__content">
          <h4 className="product-detail__content--item">{t('rewards.productDetail.description')}</h4>
          <p className="product-detail__content--description">{description}</p>
        </section>
        <section className="product-detail__content">
          <h4 className="product-detail__content--item">{t('rewards.productDetail.image')}</h4>
          <img className="product-detail__content--image" src={original} alt={name} />
        </section>
        <section className="product-detail__content">
          <h4 className="product-detail__content--item">{t('rewards.productDetail.status')}</h4>
          <p className={`product-detail__content--description ${status}`}>{status}</p>
        </section>
        <section className="product-detail__content">
          <h4 className="product-detail__content--item">
            {t('rewards.productDetail.price')}
            <img src={medal} alt="medal" />
          </h4>
          <p className="product-detail__content--description">{points}</p>
        </section>
        <section className="product-detail__content">
          <h4 className="product-detail__content--item">{t('rewards.productDetail.quantity')}</h4>
          <p className="product-detail__content--description">{quantity}</p>
        </section>
      </section>
    </Modal>
  );
};

export default React.memo(ProductDetailModal);

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import ReadingCard from 'components/createTemplate/ReadingCard';
import { useCreateTemplateContext } from 'components/createTemplate/CreateTemplateProvider';

import noReadings from 'assets/images/no-data.svg';

const ReadingsList = ({ title, own }) => {
  const {
    template: { readings = [] },
    allReadings = [],
  } = useCreateTemplateContext();

  const getReadings = own ? readings : allReadings;

  return (
    <section className="readings-list-container__list">
      <h3 className="readings-list-container__list--title">{title}</h3>
      <div className="card-container">
        {isEmpty(getReadings) ? (
          <div className="card-container__image">
            <img src={noReadings} alt="No readings available" />
          </div>
        ) : (
          getReadings.map((reading) => <ReadingCard key={reading.id} reading={reading} isOwn={own} />)
        )}
      </div>
    </section>
  );
};

ReadingsList.defaultProps = {
  own: false,
};

ReadingsList.propTypes = {
  title: PropTypes.string.isRequired,
  own: PropTypes.bool,
};

export default ReadingsList;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import {
  FaUsersCog,
  FaHome,
  FaChalkboard,
  FaCalendarAlt,
  FaClipboardList,
  FaHandsHelping,
  FaPhotoVideo,
  FaUserPlus,
  FaUserInjured,
  FaColumns,
  FaChartPie,
  FaBookReader,
  FaNewspaper,
  FaWalking,
  FaVideo,
  FaUserAstronaut
} from 'react-icons/fa';
import { GoCircuitBoard } from 'react-icons/go';
import { IoMdBusiness, IoIosNotifications } from 'react-icons/io';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { AiFillAudio, AiFillBuild } from 'react-icons/ai';
import { GiPelvisBone } from 'react-icons/gi';

import AlertModal from 'components/AlertModal';
import PatientInviteModal from 'components/LeftMenu/PatientInviteModal';
import { useAppState } from 'components/StoreProvider';

import { VALIDATE_USER, GET_BOOKINGS } from 'apollo/queries';
import { ROUTES, ROLES } from 'utils/constants';
import { useAuthQuery } from 'utils/hooks';
import { getUserRole } from 'utils/isClinic';
import { useToggle } from 'utils/hooks/useToggle';

import healtphyLogo from 'assets/images/logo-white.svg';

import './left-menu.scss';

const eventPermissions = ['fisioterapiamodofisio@gmail.com'];

const LeftMenu = () => {
  const {
    push: changeRoute,
    location: { pathname },
  } = useHistory();
  const userRole = getUserRole();

  const { data } = userRole === ROLES.CLINIC ? useAuthQuery(VALIDATE_USER) : {};
  const { data: patientsData } = useAuthQuery(GET_BOOKINGS);
  const { getClinicInfo: clinic = {} } = { ...data };
  const { clinicDashboard: clinicPatientsData = {} } = { ...patientsData };
  const { patientStatus = {} } = { ...clinicPatientsData };
  const { current_active: currentActive = 0, current_available: currentAvailable = 0 } = { ...patientStatus };
  const { email = '', enableEvents = false } = { ...clinic };
  const [modalIsOpen, setIsModalOpen] = useToggle();
  const [pathTo, setPath] = useState('');
  const [expanded, setExpanded] = useToggle();
  const { t } = useTranslation();

  const { patient, step, setStoreData, inviteCreateMember, toggleInviteCreateMember } = useAppState();

  const onChangeRoute =
    ({ route, currentStep, currentPatient }) =>
    () => {
      setPath(route);
      if (currentPatient && currentPatient.name && currentStep) {
        setIsModalOpen();
      }
      changeRoute(route);
    };

  const onConfirm = (setData) => () => {
    changeRoute(pathTo);
    setIsModalOpen();
    setData('initialState');
  };

  const iconStyle = { width: '24px', height: '24px', marginTop: expanded ? '-4px' : '0px' };

  const handleHideRoute = (role) => {
    if (role === ROLES.SUPER_CLINIC) {
      return { display: 'none' };
    }
    return null;
  };

  return (
    <>
      <div className="toggle-menu" style={{ width: expanded ? '280px' : '64px' }}>
        {!expanded && <ReactTooltip place="right" effect="solid" id="menu" />}
        <SideNav onToggle={(toggle) => setExpanded(toggle)}>
          <NavItem className="left-menu__logo__item">
            <div
              className="left-menu__logo__container"
              style={{ width: expanded ? '80px' : '54px', height: expanded ? '80px' : '54px' }}
            >
              <img
                src={healtphyLogo}
                alt="logo"
                className="left-menu__logo"
                style={{ width: expanded ? '80px' : '44px' }}
              />
            </div>
          </NavItem>
          <SideNav.Toggle />
          <SideNav.Nav defaultSelected={ROUTES.DASHBOARD} selected={pathname}>
            {currentActive < currentAvailable && (
              <NavItem
                style={handleHideRoute(userRole)}
                data-tip={t('leftMenuHeader.newPatient')}
                data-for="menu"
                onClick={toggleInviteCreateMember}
              >
                <NavIcon>
                  <FaUserPlus style={iconStyle} />
                </NavIcon>
                <NavText>{t('leftMenuHeader.newPatient')}</NavText>
              </NavItem>
            )}
            <NavItem
              style={handleHideRoute(userRole)}
              data-tip={t('leftMenuHeader.onboarding')}
              data-for="menu"
              onClick={onChangeRoute({ route: ROUTES.ONBOARDING, step, patient })}
              active={pathname === ROUTES.ONBOARDING}
            >
              <NavIcon>
                <FaUsersCog style={iconStyle} />
              </NavIcon>
              <NavText>{t('leftMenuHeader.onboarding')}</NavText>
            </NavItem>
            <NavItem
              style={handleHideRoute(userRole)}
              data-tip={t('leftMenuHeader.home')}
              data-for="menu"
              onClick={onChangeRoute({ route: ROUTES.DASHBOARD, step, patient })}
              active={pathname === ROUTES.DASHBOARD}
            >
              <NavIcon>
                <FaHome style={iconStyle} />
              </NavIcon>
              <NavText>{t('leftMenuHeader.home')}</NavText>
            </NavItem>
            <NavItem
              data-tip={t('leftMenuHeader.patients')}
              data-for="menu"
              onClick={onChangeRoute({ route: ROUTES.PATIENTS, step, patient })}
              active={
                pathname === ROUTES.PATIENTS ||
                pathname.startsWith(ROUTES.PATIENT) ||
                pathname === ROUTES.CREATE_PATIENT
              }
            >
              <NavIcon>
                <FaUserInjured style={iconStyle} />
              </NavIcon>
              <NavText>{t('leftMenuHeader.patients')}</NavText>
            </NavItem>
            <NavItem
              data-tip={t('leftMenuHeader.individuals')}
              data-for="menu"
              onClick={onChangeRoute({ route: ROUTES.INDIVIDUALS, step, patient })}
              active={
                pathname === ROUTES.INDIVIDUALS ||
                pathname.startsWith(ROUTES.PATIENT) 
              }
            >
              <NavIcon>
                <FaUserAstronaut style={iconStyle} />
              </NavIcon>
              <NavText>{t('leftMenuHeader.individuals')}</NavText>
            </NavItem>
      
            <NavItem
              data-tip={t('leftMenuHeader.plans')}
              data-for="menu"
              onClick={onChangeRoute({ route: ROUTES.PLANS, step, patient })}
              active={[ROUTES.PLANS, ROUTES.PLAN, ROUTES.CREATE_PLAN].includes(pathname)}
            >
              <NavIcon>
                <FaClipboardList style={iconStyle} />
              </NavIcon>
              <NavText>{t('leftMenuHeader.plans')}</NavText>
            </NavItem>
            <NavItem
              data-tip={t('leftMenuHeader.forms')}
              data-for="menu"
              onClick={onChangeRoute({ route: ROUTES.FORMS, step, patient })}
              active={pathname === ROUTES.FORMS || pathname.startsWith(ROUTES.FORMS) || pathname === ROUTES.CREATE_FORM}
            >
              <NavIcon>
                <FaColumns style={iconStyle} />
              </NavIcon>
              <NavText>{t('leftMenuHeader.forms')}</NavText>
            </NavItem>
            {userRole === ROLES.SUPER_CLINIC && (
              <>
                <NavItem
                  data-tip={t('leftMenuHeader.templates')}
                  data-for="menu"
                  onClick={onChangeRoute({ route: ROUTES.TEMPLATES, step, patient })}
                  active={pathname === ROUTES.TEMPLATES}
                >
                  <NavIcon>
                    <FaBookReader style={iconStyle} />
                  </NavIcon>
                  <NavText>{t('leftMenuHeader.templates')}</NavText>
                </NavItem>
                <NavItem
                  data-tip={t('leftMenuHeader.readings')}
                  data-for="menu"
                  onClick={onChangeRoute({ route: ROUTES.READINGS, step, patient })}
                  active={pathname === ROUTES.READINGS}
                >
                  <NavIcon>
                    <FaNewspaper style={iconStyle} />
                  </NavIcon>
                  <NavText>{t('leftMenuHeader.readings')}</NavText>
                </NavItem>
                <NavItem
                  data-tip={t('leftMenuHeader.programs')}
                  data-for="menu"
                  onClick={onChangeRoute({ route: ROUTES.PROGRAMS, step, patient })}
                  active={pathname === ROUTES.PROGRAMS}
                >
                  <NavIcon>
                    <GoCircuitBoard style={iconStyle} />
                  </NavIcon>
                  <NavText>{t('leftMenuHeader.programs')}</NavText>
                </NavItem>
                <NavItem
                  data-tip={t('leftMenuHeader.eviePrograms')}
                  data-for="menu"
                  onClick={onChangeRoute({ route: ROUTES.EVIE_PROGRAMS, step, patient })}
                  active={[ROUTES.EVIE_PROGRAMS, ROUTES.EVIE_PROGRAM, ROUTES.CREATE_EVIE_PROGRAM].includes(pathname)}
                >
                  <NavIcon>
                    <AiFillBuild style={iconStyle} />
                  </NavIcon>
                  <NavText>{t('leftMenuHeader.eviePrograms')}</NavText>
                </NavItem>
                <NavItem
                  data-tip={t('leftMenuHeader.companiesList')}
                  data-for="menu"
                  onClick={onChangeRoute({ route: ROUTES.COMPANIES_LIST, step, patient })}
                  active={pathname === ROUTES.COMPANIES_LIST}
                >
                  <NavIcon>
                    <IoMdBusiness style={iconStyle} />
                  </NavIcon>
                  <NavText>{t('leftMenuHeader.companiesList')}</NavText>
                </NavItem>
                <NavItem
                  data-tip={t('leftMenuHeader.notification')}
                  data-for="menu"
                  onClick={onChangeRoute({ route: ROUTES.CREATE_NOTIFICATION, step, patient })}
                  active={pathname === ROUTES.CREATE_NOTIFICATION}
                >
                  <NavIcon>
                    <IoIosNotifications style={iconStyle} />
                  </NavIcon>
                  <NavText>{t('leftMenuHeader.notification')}</NavText>
                </NavItem>
                <NavItem
                  data-tip={t('leftMenuHeader.chat')}
                  data-for="menu"
                  onClick={onChangeRoute({ route: ROUTES.CHAT, step, patient })}
                  active={pathname === ROUTES.CHAT}
                >
                  <NavIcon>
                    <BsFillChatDotsFill style={iconStyle} />
                  </NavIcon>
                  <NavText>{t('leftMenuHeader.chat')}</NavText>
                </NavItem>
                <NavItem
                  data-tip={t('leftMenuHeader.activePauses')}
                  data-for="menu"
                  onClick={onChangeRoute({ route: ROUTES.ACTIVE_PAUSES, step, patient })}
                  active={[ROUTES.ACTIVE_PAUSES, ROUTES.ACTIVE_PAUSE, ROUTES.CREATE_ACTIVE_PAUSE].includes(pathname)}
                >
                  <NavIcon>
                    <FaWalking style={iconStyle} />
                  </NavIcon>
                  <NavText>{t('leftMenuHeader.activePauses')}</NavText>
                </NavItem>
                <NavItem
                  data-tip={t('leftMenuHeader.audioList')}
                  data-for="menu"
                  onClick={onChangeRoute({ route: ROUTES.AUDIO_LIST, step, patient })}
                  active={[ROUTES.AUDIO_LIST, ROUTES.AUDIO, ROUTES.CREATE_AUDIO].includes(pathname)}
                >
                  <NavIcon>
                    <AiFillAudio style={iconStyle} />
                  </NavIcon>
                  <NavText>{t('leftMenuHeader.audioList')}</NavText>
                </NavItem>
                <NavItem
                  data-tip={t('leftMenuHeader.shortVideos')}
                  data-for="menu"
                  onClick={onChangeRoute({ route: ROUTES.SHORT_VIDEOS, step, patient })}
                  active={[ROUTES.SHORT_VIDEOS, ROUTES.SHORT_VIDEO, ROUTES.CREATE_SHORT_VIDEO].includes(pathname)}
                >
                  <NavIcon>
                    <FaVideo style={iconStyle} />
                  </NavIcon>
                  <NavText>{t('leftMenuHeader.shortVideos')}</NavText>
                </NavItem>
                <NavItem
                  data-tip={t('leftMenuHeader.squeezes')}
                  data-for="menu"
                  onClick={onChangeRoute({ route: ROUTES.SQUEEZES, step, patient })}
                  active={[ROUTES.SQUEEZES, ROUTES.SQUEEZE, ROUTES.CREATE_SQUEEZE].includes(pathname)}
                >
                  <NavIcon>
                    <GiPelvisBone style={iconStyle} />
                  </NavIcon>
                  <NavText>{t('leftMenuHeader.squeezes')}</NavText>
                </NavItem>
              </>
            )}
            <NavItem
              style={handleHideRoute(userRole)}
              data-tip={t('leftMenuHeader.library')}
              data-for="menu"
              onClick={onChangeRoute({ route: ROUTES.LIBRARY, step, patient })}
              active={pathname === ROUTES.LIBRARY || pathname.startsWith(ROUTES.LIBRARY)}
            >
              <NavIcon>
                <FaPhotoVideo style={iconStyle} />
              </NavIcon>
              <NavText>{t('leftMenuHeader.library')}</NavText>
            </NavItem>
            <NavItem
              data-tip={t('leftMenuHeader.calendar')}
              data-for="menu"
              onClick={onChangeRoute({ route: ROUTES.CALENDAR, step, patient })}
              active={pathname === ROUTES.CALENDAR || pathname.startsWith(ROUTES.CALENDAR)}
            >
              <NavIcon>
                <FaCalendarAlt style={iconStyle} />
              </NavIcon>
              <NavText>{t('leftMenuHeader.calendar')}</NavText>
            </NavItem>
            <NavItem
              data-tip={t('leftMenuHeader.reports')}
              data-for="menu"
              onClick={onChangeRoute({ route: ROUTES.REPORTS, step, patient })}
              active={pathname === ROUTES.REPORTS}
            >
              <NavIcon>
                <FaChartPie style={iconStyle} />
              </NavIcon>
              <NavText>{t('leftMenuHeader.reports')}</NavText>
            </NavItem>
            <NavItem
              style={handleHideRoute(userRole)}
              data-tip={t('leftMenuHeader.covenants')}
              data-for="menu"
              onClick={onChangeRoute({ route: ROUTES.COVENANTS, step, patient })}
              active={pathname === ROUTES.COVENANTS}
            >
              <NavIcon>
                <FaHandsHelping style={iconStyle} />
              </NavIcon>
              <NavText>{t('leftMenuHeader.covenants')}</NavText>
            </NavItem>
            {(process.env.REACT_APP_ENV !== 'production' || eventPermissions.indexOf(email) !== -1 || enableEvents) && (
              <NavItem
                style={handleHideRoute(userRole)}
                data-tip={t('leftMenuHeader.events')}
                data-for="menu"
                onClick={onChangeRoute({ route: ROUTES.EVENTS, step, patient })}
                active={pathname === ROUTES.EVENTS}
              >
                <NavIcon>
                  <FaChalkboard style={iconStyle} />
                </NavIcon>
                <NavText>{t('leftMenuHeader.events')}</NavText>
              </NavItem>
            )}
          </SideNav.Nav>
        </SideNav>
      </div>
      <AlertModal
        open={modalIsOpen}
        onCancelAction={setIsModalOpen}
        onConfirmAction={onConfirm(setStoreData)}
        title={t('deleteForm.title')}
        description={t('leaveCreatePatient.description')}
        labelConfirm={t('button.exit')}
      />
      <PatientInviteModal modalIsOpen={inviteCreateMember} toggleModal={toggleInviteCreateMember} />
    </>
  );
};

export default LeftMenu;

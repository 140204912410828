import React, { useState } from 'react';
import BasicButton from '../../Button';
import Button from '../../SolidButton';
import customPlan from '../../../assets/images/custom-plan.png';
import existPlan from '../../../assets/images/exist-plan.png';
import { SEGMENTS, CREATE_CUSTOM_PLAN } from '../../../utils/steps';
import './create-plan.scss';

const CreatePlan = ({ onSetStep }) => {
  const [planType, setPlanType] = useState(SEGMENTS);
  return (
    <div className={'create-plan-form'}>
      <h2 className={'section-title'}>Crear el plan del paciente</h2>
      {planType === CREATE_CUSTOM_PLAN && (
        <p className={'section-description'}>
          <span>Plan personalizado:</span> Crea un plan para tu paciente desde 0, con la ayuda de nuestra algoritmo de sugerencias
          de ejercicios podrás agilizar y personalizar al mismo tiempo cada día de rehabilitación.{' '}
        </p>
      )}
      {planType === SEGMENTS && (
        <p className={'section-description'}>
          <span>Planes existentes:</span> Asigna una de nuestras plantillas de planes de rehabilitación(basadas en protocolos)
          para agilizar el proceso o asigna una de tus propias plantillas creadas desde tu sección de "Mis planes".
        </p>
      )}
      <div className={'image-container'}>
        <img src={planType === SEGMENTS ? existPlan : customPlan} alt={planType} />
      </div>
      <div className='d-flex flex-column types-selection'>
        <BasicButton
          category='left-tab'
          className={`w-100 ${planType === SEGMENTS && 'active'}`}
          onClick={() => setPlanType(SEGMENTS)}>
          Planes existentes
        </BasicButton>
        <BasicButton
          category='right-tab'
          className={`w-100 ${planType === CREATE_CUSTOM_PLAN && 'active'}`}
          onClick={() => setPlanType(CREATE_CUSTOM_PLAN)}>
          Plan personalizado
        </BasicButton>
      </div>
      <div className='button-container' style={{ justifyContent: 'flex-end' }}>
        <Button type='submit' onClick={() => onSetStep(planType)}>
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default CreatePlan;

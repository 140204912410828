import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form, FieldArray } from 'formik';
import { useHistory } from 'react-router-dom';
import { FaRegPlusSquare, FaRegTrashAlt } from 'react-icons/fa';
import Lottie from 'react-lottie';
import Loader from 'react-loader-spinner';

import { useCreateUpdateSqueeze, useGetSqueeze } from 'components/squeeze/service';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import { createSqueeze } from 'utils/validationSchema';
import { queryParams } from 'utils/string';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SolidButton from 'components/SolidButton';
import { TextInput, AudioInput, FileInput, MultiSelect } from 'components/FormComponents';

import './create-squeeze.scss';

const CreateSqueeze = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const {
    goBack,
    location: { search },
  } = useHistory();
  const { id: squeezeId = '' } = queryParams(search);

  const { createUpdateSqueeze, spinner } = useCreateUpdateSqueeze(squeezeId);
  const { squeeze, loading } = useGetSqueeze(squeezeId);
  const { title = '', content = [], audio = '', audioUs = '', jsonParse = '' } = squeeze;
  const initialValues = { title, actions: content, audio, audioUs, jsonParse };

  const [jsonUrl, setJsonUrl] = useState('');
  console.log('JSON: ', jsonUrl);

  const actionList = [
    {value: 'squeeze_0', label: `${t('squeezes.actions.squeeze')} 0%`},
    {value: 'squeeze_5', label: `${t('squeezes.actions.squeeze')} 5%`},
    {value: 'squeeze_50', label: `${t('squeezes.actions.squeeze')} 50%`},
    {value: 'squeeze_95', label: `${t('squeezes.actions.squeeze')} 95%`},
    {value: 'squeeze_100', label: `${t('squeezes.actions.squeeze')} 100%`},
    {value: 'release_0', label: `${t('squeezes.actions.release')} 0%`},
    {value: 'release_5', label: `${t('squeezes.actions.release')} 5%`},
    {value: 'release_50', label: `${t('squeezes.actions.release')} 50%`},
    {value: 'release_95', label: `${t('squeezes.actions.release')} 95%`},
    {value: 'release_100', label: `${t('squeezes.actions.release')} 100%`},
    {value: 'hold', label: t('squeezes.actions.hold')},
    {value: 'rest', label: t('squeezes.actions.rest')}
  ];

  useEffect(() => {
    if (jsonParse) {
      setJsonUrl(jsonParse);
    }
  }, [jsonParse]);

  const reader = new FileReader();
  reader.onloadend = () => {
    const base64String = reader.result;
    if (base64String) {
      const base64Data = base64String.split(',')[1];
      const decodedData = atob(base64Data);
      const jsonData = JSON.parse(decodedData);
      setJsonUrl(jsonData);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: jsonParse,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenu />
        <div className="dashboard__main">
          <Header title={squeezeId ? t('leftMenuHeader.squeezeDetail') : t('leftMenuHeader.createSqueeze')} shadow />
          <Formik
            initialValues={initialValues}
            onSubmit={createUpdateSqueeze}
            validationSchema={createSqueeze}
            enableReinitialize
          >
            {({ isSubmitting, isValid, values }) => {
              const { actions, jsonParse: file } = values;
              const isDisabledButton = [!isValid, isSubmitting].includes(true);
              if (typeof file === 'object' && !jsonUrl) {
                if (reader.readyState === FileReader.LOADING) {
                  reader.abort();
                }
                reader.readAsDataURL(file[0]);
              }
              return (
                <Form className="create-squeeze">
                  <div className="create-squeeze__btns">
                    <SolidButton className="secondary mr-2" onClick={goBack}>
                      {t('button.back')}
                    </SolidButton>
                    <SolidButton
                      className={isDisabledButton ? 'disabled' : ''}
                      disabled={isDisabledButton}
                      type="submit"
                    >
                      {squeezeId ? t('button.update') : t('button.create')}
                    </SolidButton>
                  </div>
                  <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
                  <div style={{flexDirection: 'row', display:'flex', flexGrow: 1}}>
                    <div style={{flexGrow: 1}}><FieldArray
                    name="actions"
                    render={(arrayHelpers) => (
                      <>
                        <div className="create-squeeze__actions-title">
                          <h3 className="create-squeeze__actions-title_label">{t('formLabel.actions')}</h3>
                          <FaRegPlusSquare onClick={() => arrayHelpers.push('')} />
                        </div>
                        <ul className="create-squeeze__actions-list">
                          {actions.map((_, index) => (
                            <li key={index} className="create-squeeze__actions-list_item">
                              <Field
                                type="text"
                                label={t('formLabel.action')}
                                name={`actions[${index}]action`}
                                component={MultiSelect}
                                options={actionList}
                                placeholder="Seleccionar"
                              />
                              <Field
                                type="text"
                                label={t('formLabel.duration')}
                                name={`actions[${index}]duration`}
                                component={TextInput}
                              />
                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <FaRegTrashAlt onClick={() => arrayHelpers.remove(index)} />
                              </div>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  /></div>

                    <div style={{flexDirection:'column', display: 'flex', alignItems: 'flex-end'}}>
                      <div style={{flexGrow: 1, width: 'calc(100% - 5px)', marginBottom: '10px'}}>
                        <Field labelBtn={t('button.upload')} name="audio" component={AudioInput} />
                      </div>
                      <div style={{flexGrow: 1, width: 'calc(100% - 5px)', marginBottom: '10px'}}>
                        <Field labelBtn={`${t('button.upload')} Us`} name="audioUs" component={AudioInput} />
                      </div>
                      <di className={'create-squeeze_file_container'}>
                        <Field name="jsonParse" label="JSON" component={FileInput} />
                      </di>

                      <div className="create-squeeze__border">
                        <div className="create-squeeze__background">
                          <div className="create-squeeze__container">
                            {!loading && jsonParse ? (
                              <Lottie options={defaultOptions} />
                            ) : null}
                          </div>
                        </div>
                      </div>
                  </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || spinner}
      />
    </>
  );
};

export default CreateSqueeze;

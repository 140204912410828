import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty, pathOr } from 'ramda';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';

import { CREATE_UPDATE_READING } from 'apollo/mutations';
import { ROUTES } from 'utils/constants';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';

export const useCreateUpdateReading = ({ editorState = {}, readingId = '' }) => {
  const { push } = useHistory();
  const [createUpdateReading] = useMutation(CREATE_UPDATE_READING);
  const [spinner, setSpinner] = useToggle();

  const onCreateUpdateReading = async (values) => {
    setSpinner();
    const formatedContent = await draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const response = await createUpdateReading({
      variables: {
        ...values,
        content: formatedContent,
        ...(!!readingId && {
          readingId,
        }),
      },
    });

    const errors = pathOr([], ['data', 'createUpdateReading', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha ${readingId ? 'actualizado' : 'creado'} la lectura exitosamente.`] : errors,
    });

    if (emptyErrors) {
      push(ROUTES.READINGS);
    }
  };

  return {
    spinner,
    onCreateUpdateReading,
  };
};

import React from 'react';
import PropTypes from 'prop-types';

import './authentication.scss';

import healtphyLogo from 'assets/images/logo-blue.svg';

const Authentication = ({ title, children, role }) => (
  <div className="authentication">
    <div className="authentication__container">
      {role !== 'super_clinic' && <div className={`authentication__image ${role}`} />}
      <div className="authentication__form">
        <img className="authentication__logo" src={healtphyLogo} alt="logo" />
        <h2 className="authentication__form_title">{title}</h2>
        {children}
      </div>
      <footer className="authentication__form_footer">©{new Date().getFullYear()} - HealthPhy</footer>
    </div>
  </div>
);

Authentication.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  role: PropTypes.string.isRequired,
};

export default Authentication;

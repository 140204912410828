import React from 'react';
import { useAppState } from 'components/StoreProvider';
import { CREATE_STEPS } from 'utils/steps';
import Icon from 'components/Icon';

import './step-indicator.scss';

export default (props) => {
  const { currentTab = 0 } = useAppState();
  const { step = '' } = { ...props };
  const stepNbr = CREATE_STEPS.indexOf(step);

  return (
    <div className={'step-list'}>
      <div className={'step-container'}>
        <div className={`step ${stepNbr <= 0 ? 'step-current' : stepNbr > 0 && 'step-checked'}`}>
          {stepNbr > 0 && <Icon name='checked' className='checked-icon' />}
        </div>
        <p className={`step-name ${stepNbr <= 0 ? 'step-current-text' : stepNbr > 0 && 'step-checked-text'}`}>
          Información personal
          <span>Datos generales del paciente</span>
        </p>
      </div>
      <span className={`step-line ${stepNbr > 0 ? 'step-checked' : ''}`}></span>
      <div className={'step-container'}>
        <div className={`step ${stepNbr === 1 ? 'step-current' : stepNbr > 1 && 'step-checked'}`}>
          {stepNbr > 1 && <Icon name='checked' className='checked-icon' />}
        </div>
        <p className={`step-name ${stepNbr === 1 ? 'step-current-text' : stepNbr > 1 && 'step-checked-text'}`}>
          Valoración<span>Historial médico del paciente</span>
        </p>
      </div>
      {currentTab !== 2 && (
        <>
          <span className={`step-line ${stepNbr > 1 ? 'step-checked' : ''}`}></span>
          <div className={'step-container'}>
            <div className={`step ${stepNbr > 1 ? 'step-current' : ''}`}></div>
            <p className={`step-name ${stepNbr > 1 ? 'step-current-text' : ''}`}>
              Crear Plan<span>Terapia de rehabilitación</span>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

import React from 'react';
import { Formik, Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { useCreateReadingContext } from 'components/readings/CreateReadingProvider';
import { imageUrl } from 'utils/validationSchema';

import { TextInput } from 'components/FormComponents';
import SolidButton from 'components/SolidButton';
import { FormTitle } from './FormTitle';

export const ImageUrlForm = () => {
  const { t } = useTranslation();
  const { endEditing, saveBlock, builderState } = useCreateReadingContext();
  const { selectedBlock = {} } = builderState;
  const { url = '' } = selectedBlock;
  const initialValues = { url };

  return (
    <>
      <FormTitle />
      <Formik initialValues={initialValues} onSubmit={saveBlock} validationSchema={imageUrl}>
        {() => (
          <Form>
            <Field type="text" label={t('formLabel.imageUrlLabel')} name="url" component={TextInput} />
            <div className="d-flex mt-4 modal__buttons right_buttons">
              <SolidButton className="secondary mr-2" onClick={endEditing}>
                {t('button.cancel')}
              </SolidButton>
              <SolidButton type="submit" className="">
                {t('button.save')}
              </SolidButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { isEmpty } from 'ramda';

import { useCreateProgramContext } from 'components/createProgram/CreateProgramProvider';
import { ACTIONS, CREATE_PROGRAM_STEPS } from 'components/createProgram/service/programConstants';
import { customStyles } from 'utils/styles';

import SolidButton from 'components/SolidButton';
import SelectedReadingList from '../SelectedReadingList';

const ReadingTemplates = () => {
  const { t } = useTranslation();

  const { createProgramState, readingTemplates, dispatch } = useCreateProgramContext();
  const { templateId = '', readingDays = [] } = { ...createProgramState };

  const formatTemplateOptions = readingTemplates.map(({ id: value, title: label }) => ({ value, label }));

  const getSelectedTemplate = formatTemplateOptions.find(({ value }) => value === templateId);

  const [tempId, setTempId] = useState(templateId);
  const [readDays, setReadDays] = useState(readingDays);

  const addReadingDays = (readingId, selectedDay) => {
    setReadDays((prevState) => {
      const findDay = prevState.find(({ day }) => day === selectedDay);
      const updateReadings = prevState.map((currentDay) => {
        const isSavedReading = currentDay.ids.includes(readingId);
        if (isSavedReading) {
          return { ...currentDay, ids: currentDay.ids.filter((readId) => readId !== readingId) };
        }
        return currentDay;
      });
      if (findDay) {
        return updateReadings.map((currDay) =>
          currDay.day === selectedDay ? { ...currDay, ids: [...currDay.ids, readingId] } : currDay,
        );
      }
      return [...updateReadings, { day: selectedDay, ids: [...[], readingId] }];
    });
  };

  const onBack = () => {
    dispatch({ type: ACTIONS.SET_STEP, payload: CREATE_PROGRAM_STEPS.PROGRAM_DETAIL });
    if (!isEmpty(tempId)) {
      dispatch({ type: ACTIONS.ADD_TEMPLATE, payload: { templateId: tempId, readingDays: readDays } });
    }
  };

  const onNext = () => {
    dispatch({ type: ACTIONS.ADD_TEMPLATE, payload: { templateId: tempId, readingDays: readDays } });
    dispatch({ type: ACTIONS.SET_STEP, payload: CREATE_PROGRAM_STEPS.AUDIO });
  };

  const handleChangeReadingTemplate = ({ value }) => {
    setReadDays([]);
    setTempId(value);
  };

  return (
    <section className="program__templates">
      <div className="program__templates__select">
        <Select
          defaultValue={getSelectedTemplate}
          placeholder={t('placeholder.template')}
          options={formatTemplateOptions}
          styles={customStyles}
          onChange={handleChangeReadingTemplate}
        />
      </div>
      {!isEmpty(tempId) && (
        <>
          <SelectedReadingList
            templates={readingTemplates}
            readDays={readDays}
            templateId={tempId}
            addReadingDays={addReadingDays}
          />
          <div className="program__templates__buttons">
            <SolidButton className="secondary mr-2" onClick={onBack}>
              {t('button.back')}
            </SolidButton>
            <SolidButton className={isEmpty(readDays) ? 'disabled' : ''} onClick={onNext} disabled={isEmpty(readDays)}>
              {t('button.next')}
            </SolidButton>
          </div>
        </>
      )}
    </section>
  );
};

export default ReadingTemplates;

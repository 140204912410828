import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import Loader from 'react-loader-spinner';

import { GET_PRODUCTS } from 'apollo/queries';
import { useFilterTabs, useAuthQuery, useFormInput } from 'utils/hooks';
import { useToggle } from 'utils/hooks/useToggle';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { handleFilterTabs } from 'utils/handleFilterTabs';
import { useFilterByProperty } from 'utils/hooks/useFilterByProperty';
import { ROLES } from 'utils/constants';

import LeftMenuEmployers from 'components/LeftMenuEmployers';
import Header from 'components/Header';
import SearchFilter from 'components/SearchFilter';
import SolidButton from 'components/SolidButton';
import FilterTabs from 'components/Employees/FilterTabs';
import AddProductModal from 'components/Rewards/AddProductModal';
import ProductDetailModal from 'components/Rewards/ProductDetailModal';
import RewardsList from 'components/Rewards/RewardsList';
import Pagination from 'components/Pagination';
import NoProductsFound from 'components/Forms/NoFormsFound';
import { NoMembers } from 'components/EmptyState';

import noProducts from 'assets/images/no-products.svg';
import './rewards.scss';

const rowsPerPage = 10;

const Rewards = () => {
  useRedirectByRole([ROLES.COMPANY]);
  const { t } = useTranslation();
  const filters = [t('tabs.active'), t('tabs.inactive'), t('tabs.all')];
  const { loading, data, refetch } = useAuthQuery(GET_PRODUCTS);
  const { products: allProducts = [] } = { ...data };

  const [products, setProducts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [addProduct, setAddProduct] = useToggle();
  const [productDetail, setProductDetail] = useToggle();

  const searchProducts = useFormInput('');
  const { value = '' } = { ...searchProducts };
  const { handleChangeFilter, filterTab } = useFilterTabs(filters);
  const activeProducts = useFilterByProperty({ data: allProducts, property: 'status', value: 'active' });
  const unactiveProducts = useFilterByProperty({ data: allProducts, property: 'status', value: 'unactive' });

  useEffect(() => {
    refetch();
  }, []);

  const isNewResults = (results) => {
    const rIds = (results || []).map(({ id, name, description }) => `${id}${name}${description}`).join(',');
    const fIds = (filtered || []).map(({ id, name, description }) => `${id}${name}${description}`).join(',');
    return rIds !== fIds;
  };

  useEffect(() => {
    let temporal = handleFilterTabs(filterTab, {
      activeItems: activeProducts,
      unactiveItems: unactiveProducts,
      allItems: allProducts,
      filterItems: filters,
    });
    if (value) {
      temporal = handleFilterTabs(filterTab, {
        activeItems: activeProducts,
        unactiveItems: unactiveProducts,
        allItems: allProducts,
        filterItems: filters,
      }).filter((product) => {
        const { name = '' } = { ...product };
        const employeeFound = `${name}`.toLowerCase();
        return employeeFound.indexOf(`${value}`.toLowerCase()) !== -1;
      });
    }
    const totalPages = temporal.length > 0 ? Math.ceil(temporal.length / rowsPerPage) : 0;
    if (page !== 1) setPage(1);
    if (pages !== totalPages) setPages(totalPages);
    if (isNewResults(temporal)) setFiltered(temporal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProducts, filterTab, value]);

  useEffect(() => {
    const init = page * rowsPerPage - rowsPerPage;
    const end = init + rowsPerPage;
    setProducts(filtered.slice(init, end));
  }, [filtered, page]);

  useEffect(() => {
    if (!addProduct && !productDetail) {
      setSelectedProduct({});
    }
  }, [addProduct, productDetail]);

  const memoizedHandleRefetchProducts = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleSelectProduct = (product, callback) => {
    setSelectedProduct(product);
    callback();
  };

  const handlePageChange = (currentPage) => {
    setPage(currentPage);
  };

  return (
    <section className="d-flex">
      <LeftMenuEmployers />
      <div className="dashboard__main">
        <Header title={t('leftMenuHeader.companies.rewards')} shadow />
        <section className="rewards">
          {loading && (
            <div className="loading-container">
              <Loader type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
            </div>
          )}
          {!loading && isEmpty(allProducts) && (
            <NoMembers
              title={t('rewards.empty.title')}
              subtitle={t('rewards.empty.description')}
              button={t('button.add')}
              handleClick={setAddProduct}
              image={noProducts}
            />
          )}
          {!loading && !isEmpty(allProducts) && (
            <>
              <div className="rewards__header">
                <SearchFilter searchItems={searchProducts} placeholder={t('searchLabel.rewards')} />
                <SolidButton onClick={setAddProduct} className="slim">
                  {t('rewards.button.add')}
                </SolidButton>
              </div>
              <FilterTabs filters={filters} filterTab={filterTab} handleChangeFilter={handleChangeFilter} />
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '10%' }}> </th>
                    <th style={{ width: '30%' }}>{t('rewards.tableHeader.name')}</th>
                    <th style={{ width: '20%' }}>{t('rewards.tableHeader.status')}</th>
                    <th style={{ width: '25%' }}>{t('rewards.tableHeader.stock')}</th>
                    <th style={{ width: '15%' }}>{t('rewards.tableHeader.detail')}</th>
                  </tr>
                </thead>
                <tbody>
                  <RewardsList
                    rewards={products}
                    handleSelectProduct={handleSelectProduct}
                    showProductDetailModal={setProductDetail}
                    showAddProductModal={setAddProduct}
                  />
                  {isEmpty(products) && <NoProductsFound />}
                </tbody>
              </table>
              <Pagination currentPage={page} totalPages={pages} onPageChange={handlePageChange} />
            </>
          )}
        </section>
      </div>
      <AddProductModal
        selectedProduct={selectedProduct}
        modalIsOpen={addProduct}
        toggleModal={setAddProduct}
        handleRefetchProducts={memoizedHandleRefetchProducts}
      />
      <ProductDetailModal
        selectedProduct={selectedProduct}
        modalIsOpen={productDetail}
        toggleModal={setProductDetail}
      />
    </section>
  );
};

export default Rewards;

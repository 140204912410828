import React, { useEffect, useState } from 'react';
import { CirclePicker } from 'react-color';
import { propOr, isEmpty, pathOr } from 'ramda';
import { useMutation } from '@apollo/react-hooks';
import { useAuthQuery } from '../../utils/hooks';
import { useRedirectByRole } from '../../utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import Loader from 'react-loader-spinner';
import LeftMenu from '../../components/LeftMenu';
import Header from '../../components/Header';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { Formik, Field, Form } from 'formik';
import { TextAreaInput, TextInput } from '../../components/FormComponents';
import showNotification from '../../utils/showNotification';
import { GET_COVENANTS } from '../../apollo/queries';
import { CREATE_COVENANT, UPDATE_COVENANT } from '../../apollo/mutations';
import searchIcon from '../../assets/images/search.svg';
import noPatients from '../../assets/images/no-patients.svg';
import { covenantForm } from '../../utils/validationSchema';
import './covenants.scss';

const rowsPerPage = 10;
const modalProps = {
  content: {
    background: '#FCFCFC',
    width: '40vw',
    maxWidth: '640px',
    outline: 'none',
    padding: '0px !important',
  },
};

const Covenants = (props) => {
  useRedirectByRole([ROLES.CLINIC]);
  const { loading, data, refetch } = useAuthQuery(GET_COVENANTS);
  const allCovenants = propOr([], 'covenants')(data);
  const [covenants, setCovenants] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [selectedColor, setSelectedColor] = useState();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState();
  const [updateCovenant] = useMutation(UPDATE_COVENANT);
  const [createCovenant] = useMutation(CREATE_COVENANT);

  const newCovenant = { name: '', description: '' };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let temporal = allCovenants;
    if (!!search) {
      temporal = allCovenants.filter((item) => {
        const { name, label, id, description, active } = { ...item };
        const covenant = `${id}-${name}-${label}-${description}-${active}`.toLowerCase();
        return covenant.indexOf(`${search}`.toLowerCase()) !== -1;
      });
    }
    const totalPages = temporal.length > 0 ? Math.ceil(temporal.length / rowsPerPage) : 0;
    if (page !== 1) setPage(1);
    if (pages !== totalPages) setPages(totalPages);
    if (isNewResults(temporal)) setFiltered(temporal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCovenants, search]);

  useEffect(() => {
    const init = page * rowsPerPage - rowsPerPage;
    const end = init + rowsPerPage;
    setCovenants(filtered.slice(init, end));
  }, [filtered, page]);

  const onCloseModal = () => {
    setOpenModal(false);
    setSelected(undefined);
  };

  const onOpenModal = (item) => {
    const { id: covenantId, label: hex = '#f44336' } = { ...item };
    setOpenModal(true);
    setSelected(item);
    if (!!covenantId) {
      setSelectedColor({ hex });
    }
  };

  const onSelectColor = (color, setFieldValue) => {
    setSelectedColor(color);
    setFieldValue('label', color.hex, true);
  };

  const saveCovenantForm = async (values) => {
    const { id: covenantId, active } = { ...selected };
    const data = {
      ...selected,
      ...values,
      active: !!covenantId ? active : true,
      covenantId,
    };
    const mutation = !!covenantId ? updateCovenant : createCovenant;
    const response = await mutation({
      variables: { ...data },
    });

    const errors = pathOr(
      [],
      ['data', !!covenantId ? 'updateClinicCovenant' : 'createClinicCovenant', 'errors'],
    )(response);
    const emptyErrors = isEmpty(errors);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha ${!!covenantId ? 'actualizado' : 'creado'} el convenio exitosamente.`] : errors,
    });

    if (emptyErrors) {
      onCloseModal();
      refetch();
    }
  };

  const updateCovenantStatus = async (item, active) => {
    const { id: covenantId } = { ...item };
    const response = await updateCovenant({
      variables: { ...item, id: undefined, covenantId, active },
    });

    const errors = pathOr([], ['data', 'updateClinicCovenant', 'errors'])(response);
    const emptyErrors = isEmpty(errors);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha actualizado el estado del convenio exitosamente.`] : errors,
    });

    if (emptyErrors) {
      refetch();
    }
  };

  const isNewResults = (results) => {
    const rIds = (results || []).map(({ id, name, label, active }) => `${id}${name}${label}${active}`).join(',');
    const fIds = (filtered || []).map(({ id, name, label, active }) => `${id}${name}${label}${active}`).join(',');
    return rIds !== fIds;
  };

  return (
    <div className="d-flex">
      <LeftMenu history={props.history} />
      <div className="dashboard__main">
        <Header title="Convenios" />
        <div className="dashboard__content--patients">
          {loading && (
            <div className={'loading-container'}>
              <Loader type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
            </div>
          )}
          {!loading && isEmpty(allCovenants) && (
            <div className="patients__empty-state">
              <div className={'content'}>
                <p className={'title'}>¡Aún no tienes convenios registrados!</p>
                <p className={'subtitle'}>Inicia creando un convenio para tu consultorio o clínica.</p>
                <div className="mx-2 text-center" onClick={() => onOpenModal({ ...newCovenant })}>
                  <Button category="rounded">Crear Convenio</Button>
                </div>
              </div>
              <img src={noPatients} alt={'no patients'} className={'image'} />
            </div>
          )}
          {!loading && !isEmpty(allCovenants) && (
            <div className="patients__container">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className={'search-box covenants'}>
                  <input
                    onChange={(ev) => setSearch(ev.target.value)}
                    value={search}
                    placeholder={'Buscar un convenio'}
                  />
                  <img src={searchIcon} className={'icon'} alt={'search icon'} />
                </div>
                <div className="mx-2 text-center" onClick={() => onOpenModal({ ...newCovenant })}>
                  <Button category="rounded">Crear Convenio</Button>
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '5%' }}></th>
                    <th style={{ width: '25%' }}>Nombre</th>
                    <th style={{ width: '50%' }}>Descripción</th>
                    <th style={{ width: '10%' }}>Status</th>
                    <th style={{ width: '10%' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {covenants.map((covenant) => {
                    const { id, name, description, label, active } = { ...covenant };
                    return (
                      <tr key={id}>
                        <td style={{ width: '5%' }}>
                          <div
                            style={{
                              width: '32px',
                              height: '32px',
                              borderRadius: '48px',
                              margin: '10px',
                              marginLeft: '30px',
                              backgroundColor: label,
                            }}
                          />
                        </td>
                        <td style={{ width: '25%' }}>{name}</td>
                        <td style={{ width: '50%' }}>
                          <p className={'covenant-description'}>{description}</p>
                        </td>
                        <td style={{ width: '10%', display: 'flex !important' }}>
                          <Button
                            category="status"
                            className={`status ${active ? 'active' : 'inactive'}`}
                            onClick={() => updateCovenantStatus(covenant, !active)}
                          >
                            {active ? 'Activo' : 'Inactivo'}
                          </Button>
                        </td>
                        <td className={'actions-col'} style={{ width: '10%' }}>
                          <Button category="primary" onClick={() => onOpenModal(covenant)}>
                            Ver detalle
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                  {isEmpty(covenants) && (
                    <tr className={'empty-state'}>
                      <td>
                        <div className={'patients__no-results'}>
                          <div className={'content'}>
                            <p className={'title'}>No se han encontrado resultados</p>
                            <p className={'subtitle'}>
                              Asegúrate de que el criterio esté escrito correctamente o intenta ajustar el filtro para
                              encontrar lo que estas buscando.
                            </p>
                          </div>
                          <img src={noPatients} alt={'no results'} className={'image'} />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {pages > 1 && (
                <div className={'pagination'}>
                  <div className={'page-item arrow'} onClick={page > 1 ? () => setPage(page - 1) : undefined}>
                    <Icon name={'previous'} className={'arrow-icon'} />
                  </div>
                  {[...new Array(pages)].map((item, index) => {
                    const pg = index + 1;
                    return (
                      <div key={index} className={`page-item ${pg === page && 'active'}`} onClick={() => setPage(pg)}>
                        {pg}
                      </div>
                    );
                  })}
                  <div className={'page-item arrow'} onClick={page < pages ? () => setPage(page + 1) : undefined}>
                    <Icon name={'next'} className={'arrow-icon'} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal modalIsOpen={openModal} closeModal={onCloseModal} modalProps={modalProps} closeable={false}>
        <div>
          <h3 className="modal__title">{selected && selected.id ? 'Actualizar' : 'Crear'} Convenio</h3>
          <Formik initialValues={{ ...selected }} validationSchema={covenantForm} onSubmit={saveCovenantForm}>
            {({ handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit} className={'w-100'}>
                <Field type="text" label="Nombre" name="name" component={TextInput} />
                <span className={'span-covenant-description'}>
                  <Field
                    type="text"
                    label="Descripción"
                    name="description"
                    minRows={20}
                    component={TextAreaInput}
                    className={'observations-field'}
                  />
                </span>
                <div style={{ padding: '30px 0px' }}>
                  <CirclePicker
                    width={'100%'}
                    color={selectedColor}
                    onChange={(color) => onSelectColor(color, setFieldValue)}
                  />
                </div>
                <div className="d-flex justify-content-center mt-4 modal_buttons">
                  <Button category="secondary-rounded" className="mr-3" onClick={onCloseModal}>
                    Cancelar
                  </Button>
                  <Button category="rounded" onClick={() => {}}>
                    Guardar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default Covenants;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { IoIosArrowDown } from 'react-icons/io';

import { GET_BUILDER_PROGRAMS } from 'apollo/queries';
import { useAuthQuery, useFormInput } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROUTES, ROLES } from 'utils/constants';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SearchFilter from 'components/SearchFilter';
import SolidButton from 'components/SolidButton';
import Thumb from 'components/Thumb';
import NoProgramsFound from 'components/Forms/NoFormsFound';
import { NoMembers } from 'components/EmptyState';

import noTemplates from 'assets/images/no-data.svg';

const EviePrograms = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const { push } = useHistory();

  const { loading, data, refetch } = useAuthQuery(GET_BUILDER_PROGRAMS);
  const { builderPrograms: allPrograms = [] } = { ...data };
  const [builderPrograms, setBuilderPrograms] = useState([]);

  const searchPrograms = useFormInput('');
  const { value = '' } = { ...searchPrograms };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    let temporal = allPrograms;
    if (value) {
      temporal = allPrograms.filter((program) => {
        const { title, description } = program;
        return `${title}-${description}`.toLowerCase().includes(value.toLowerCase());
      });
    }
    setBuilderPrograms(temporal);
  }, [allPrograms, value]);

  const handleChangeRoute = () => {
    push(ROUTES.CREATE_EVIE_PROGRAM);
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenu />
        <div className="dashboard__main">
          <Header title={t('leftMenuHeader.eviePrograms')} shadow />
          <div className="dashboard__content--patients">
            {!loading && isEmpty(allPrograms) && (
              <NoMembers
                title={t('eviePrograms.emptyState.title')}
                subtitle={t('eviePrograms.emptyState.description')}
                button={t('eviePrograms.createBtn')}
                handleClick={handleChangeRoute}
                image={noTemplates}
              />
            )}
            {!loading && !isEmpty(allPrograms) && (
              <div className="forms">
                <div className="forms__search">
                  <SearchFilter searchItems={searchPrograms} placeholder={t('searchLabel.program')} />
                  <SolidButton onClick={handleChangeRoute} className="slim">
                    {t('eviePrograms.createBtn')}
                  </SolidButton>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '20%' }}>{t('tableHeader.image')}</th>
                      <th style={{ width: '60%' }}>{t('tableHeader.title')}</th>
                      <th style={{ width: '10%' }}>{t('tableHeader.duration')}</th>
                      <th style={{ width: '10%' }}> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(builderPrograms || []).map((program) => {
                      const {
                        id,
                        title,
                        backgroundImage: { thumb },
                        programLevels = [],
                      } = program;
                      return (
                        <tr key={id} className="">
                          <td style={{ width: '20%' }}>
                            <Thumb source={thumb} alt={title} />
                          </td>
                          <td style={{ width: '60%' }}>{title}</td>
                          <td style={{ width: '10%' }}>
                            {programLevels.length} {t('eviePrograms.steps.levels')}
                          </td>
                          <td className="actions-col" style={{ width: '10%' }}>
                            <button
                              type="button"
                              onClick={() => push(`${ROUTES.EVIE_PROGRAM}?id=${id}`)}
                              className="form-detail"
                            >
                              <IoIosArrowDown />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                    {isEmpty(builderPrograms) && <NoProgramsFound />}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </section>
      <Loader className="spinner" type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
    </>
  );
};

export default EviePrograms;

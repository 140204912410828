import React from 'react';
import { GET_BOOKINGS } from '../../apollo/queries';
import { useAuthQuery } from '../../utils/hooks';
import { Doughnut } from 'react-chartjs-2';

import './patientStatus.scss';

const PatientStatus = (props) => {
  const { data } = useAuthQuery(GET_BOOKINGS);
  const { clinicDashboard: status = {} } = { ...data };
  const { patientStatus = {} } = { ...status };
  const { current_active = 0, current_available = 0 } = { ...patientStatus };

  const dataChart = {
    labels: ['Pagados', 'Activos'],
    datasets: [
      {
        data: [current_available, current_active],
        backgroundColor: ['#8093FC', '#FFBB6A'],
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    cutoutPercentage: 80,
    maintainAspectRatio: false,
  };

  return (
    <section className={'chart-status'}>
      <div className={'chart-status__description'}>
        <p>Pacientes</p>
        <div className={'active'}>
          <p className={'active_count'}>{current_active}</p>
          <p className={'description'}>Pacientes activos</p>
        </div>
        <div className={'available'}>
          <p className={'available_count'}>{current_available}</p>
          <p className={'description'}>Pacientes pagados</p>
        </div>  
      </div>
      <div className={'chart-status__doughnut'}>
        <p>
          <span>{current_active}</span><br/>
          Pacientes
        </p>
        <Doughnut data={dataChart} options={options} />
      </div>
    </section>
  );
}

export default PatientStatus;
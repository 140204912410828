import React from 'react';
import healtphyLogo from '../../assets/images/logo-blue.svg';
import privacyData from '../../assets/privacy.json';

const Privacy = () => {
  return (
    <div className={'signup__terms'}>
      <div className={'logo-container'}>
        <img src={healtphyLogo} alt='logo' className='logo' />
      </div>
      <p className={'title'}>MANUAL DE POLÍTICAS Y PROCEDIMIENTOS PARA PROTECCIÓN DE LOS DATOS PERSONALES </p>
      <p className={'term-title'}>HEALTHPHY S.A.S. EN ADELANTE "HEALTHPHY"</p>
      <div className={'container-xl'}>
        <p className={'term-title'}>{'I. MARCO LEGAL'}</p>
        <p className={'term-content'}>
          Constitución Política de Colombia, artículo 15\nLey 1581 de 2012 Decreto Reglamentario 1377 de 2013 Sentencias de la
          Corte Constitucional C - 1011 de 2008, y C -748 del 2011 Resolución 1995 de 1999 del Ministerio de Salud. Resolución
          2646 de 2008 del Ministerio de la protección Social.
        </p>
        {privacyData.map(({ title, content, items }, index) => (
          <div key={`${index}`}>
            {title && <p className={'term-title'}>{title}</p>}
            {!!content &&
              content.map((cnt, i) => (
                <p className={'term-content'} key={i}>
                  {cnt}
                </p>
              ))}
            {items && (
              <ul className={'term-list'}>
                {items.map((itm, i) => (
                  <li key={i}>
                    <p className={'term-content'}>{itm}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Privacy;

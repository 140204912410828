import React from 'react';
import Carousel from 'react-grid-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FaRegPlayCircle } from 'react-icons/fa';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import healtphyLogo from '../../assets/images/logo-white.svg';
import 'react-lazy-load-image-component/src/effects/blur.css';

const responsiveCarousel = [
  {
    breakpoint: 768,
    cols: 3,
    gap: 5,
  },
  {
    breakpoint: 1024,
    cols: 4,
    gap: 5,
  },
  {
    breakpoint: 1440,
    cols: 5,
    gap: 10,
  },
  {
    breakpoint: 1920,
    cols: 6,
    gap: 5,
  },
  {
    breakpoint: 2560,
    cols: 8,
    gap: 5,
  }
]

const ArrowRight = ({minExercises}) => (
  <div className={`arrow-right ${minExercises && 'arrow-bottom-position'}`}>
    <MdKeyboardArrowRight/>
  </div>
);

const ArrowLeft = ({minExercises}) => (
  <div className={`arrow-left ${minExercises && 'arrow-bottom-position'}`}>
    <MdKeyboardArrowLeft/>
  </div>
);

const LibraryCarousel = ({ part, selectedPart, selectedId, partExercises, onSelect }) => {
  const minExercises = partExercises.length <= 8;
  return (
    <>
    	<p className={'library__row__title'}>{part}</p>
    	<Carousel
    	  responsiveLayout={responsiveCarousel}
    	  cols={10}
    	  rows={minExercises ? 1 : 2}
    	  gap={10}
    	  arrowLeft={<ArrowLeft minExercises={minExercises}/>}
    	  arrowRight={<ArrowRight minExercises={minExercises}/>}
    	>
    	  {partExercises.map((exercise) => {
    	    const { title = '', image = '', id = '' } = { ...exercise };
    	    const isSelected = selectedId === id && part === selectedPart;
    	    return (
    	      <Carousel.Item key={id}>
    	        <div
    	          onClick={() => onSelect(isSelected, {part, exercise})}
    	          className={`library__row__carousel__item ${isSelected ? 'selected' : selectedId && 'unselected'}`}
    	        >
                <LazyLoadImage
                  effect={'blur'}
                  alt={title}
                  src={image}
                  placeholderSrc={healtphyLogo}
                />
    	          <p>{title}</p>
    	          <div className={'play-icon'}>
    	            <FaRegPlayCircle/>
    	          </div>
    	        </div>
    	      </Carousel.Item>
    	    );
    	  })}
    	</Carousel>
    </>
  );
}

export default LibraryCarousel;
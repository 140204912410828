import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { isNil } from 'ramda';
import moment from 'moment';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { capitalize, titleize } from 'utils/string';
import { getUserRole } from 'utils/getUserRole';
import { getUserType } from 'components/Patients/Patient/ActivityLog/utils';

import Modal from 'components/Modal';

const modalProps = {
  content: {
    width: '100vw',
    maxWidth: '640px',
    overflow: 'hidden',
  },
};

const LogsModal = ({ isLogsOpen, onCloseModal, isFemale, activityLogs, activityLogsByDay }) => {
  const { userRole } = getUserRole();
  const userType = getUserType(userRole);

  return (
    <Modal modalIsOpen={isLogsOpen} closeModal={onCloseModal} closeable={false} modalProps={modalProps}>
      <div className="activity__content_right-side">
        <div className="activity__content_logstitle">
          <h3>Registros</h3>
          <AiOutlineCloseCircle onClick={onCloseModal} />
        </div>
        {isFemale ? (
          <ul>
            {activityLogsByDay.map(([day, activities]) => (
              <li key={day}>
                <div>
                  <strong style={{ color: '#65dfb6' }}>Día: {day}</strong>
                </div>
                {activities
                  .filter(({ recipient_type: type }) => type === 'PatientProgram')
                  .map((activity) => {
                    const { id = '', /* key = '' */ parameters = {}, created_at: createdAt = new Date() } = activity;
                    const { session_duration: duration, level, session, activity_type: type } = parameters;
                    return (
                      <li key={id}>
                        <p className="comment">
                          Realizó ejercicios de la <span className="highlight-color">sesión {session}</span> del nivel{' '}
                          <span className="highlight-color">{capitalize(level)}</span> satisfactoriamente
                          {!isNil(duration) && (
                            <>
                              <br />
                              <span className="inner-data">
                                Duración de la sesión: <span className="highlight-color">{duration || 0} mins</span>
                              </span>
                              {!isNil(type) && (
                                <>
                                  <br />
                                  <span className="inner-data">
                                    Tipo de actividad: <span className="highlight-color">{type}</span>
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </p>
                        <p className="date">{titleize(moment(createdAt).format('DD MMMM YYYY, HH:mm'))}</p>
                      </li>
                    );
                  })}
              </li>
            ))}
          </ul>
        ) : (
          <ul>
            {activityLogs.map((log) => {
              const { id = '', key = '', parameters = {}, createdAt = new Date(), recipient = {} } = log;
              const {
                current_week: logWeek,
                session_duration: sessionDuration,
                week_number: dayWeek,
                satisfaction_level: satisfaction,
                difficulty_level: pain,
                comments,
              } = parameters;
              const { currentPlan = {} } = recipient;
              return (
                <li key={id}>
                  {key === `${userType}.update_current_day` && (
                    <p className="comment">
                      Completó los ejercicios <span className="highlight-color">día {dayWeek}</span> de{' '}
                      <span className="highlight-color">{capitalize(logWeek)}</span> satisfactoriamente
                      {!isNil(sessionDuration) && (
                        <>
                          <br />
                          <span className="inner-data">
                            Duración de la sesión: <span className="highlight-color">{sessionDuration} mins</span>
                          </span>
                          {!isNil(currentPlan) && (
                            <>
                              <br />
                              <span className="inner-data">
                                Plan: <span className="highlight-color">{currentPlan.title}</span>
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </p>
                  )}
                  {key === `${userType}.create_feedback` && (
                    <p className="comment">
                      Envió feedback de su sesión:
                      <br />
                      <span className="inner-data">
                        Nivel de satisfacción: <span className="highlight-color">{satisfaction}</span>
                      </span>
                      <br />
                      <span className="inner-data">
                        Dolor durante la sesión: <span className="highlight-color">{pain}</span>
                      </span>
                      {!isNil(currentPlan) && (
                        <>
                          <br />
                          <span className="inner-data">
                            Plan: <span className="highlight-color">{currentPlan.title}</span>
                          </span>
                        </>
                      )}
                      {!!comments && (
                        <span className="comment-button" data-tip={comments || 'Sin comentarios'}>
                          Ver Comentarios
                        </span>
                      )}
                      <ReactTooltip multiline place="right" className="custom-width" />
                    </p>
                  )}
                  <p className="date">{titleize(moment(createdAt).format('DD MMMM YYYY, HH:mm'))}</p>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </Modal>
  );
};

LogsModal.propTypes = {
  isLogsOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  isFemale: PropTypes.bool.isRequired,
  activityLogs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activityLogsByDay: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
};

export default LogsModal;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { Formik, Field, Form } from 'formik';
import Select from 'react-select';
import { isEmpty } from 'ramda';
import { BiCheck } from 'react-icons/bi';
import ReactTooltip from 'react-tooltip';

import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { useToggle } from 'utils/hooks/useToggle';
import { useFormInput } from 'utils/hooks';
import { ROLES } from 'utils/constants';
import { customPlanForm as createNotification } from 'utils/validationSchema';
import { customStyles } from 'utils/styles';
import { useGetCompanies } from 'components/createNotification/service/useGetCompanies';
import { useGetEmployees } from 'components/createNotification/service/useGetEmployees';
import { useCreateNotification } from 'components/createNotification/service/useCreateNotification';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SolidButton from 'components/SolidButton';
import SearchFilter from 'components/SearchFilter';
import EmployeeList from 'components/createNotification/EmployeeList';
import { NoPatientsFound as NoEmployeesFound } from 'components/EmptyState';
import { TextInput, TextAreaInput } from 'components/FormComponents';

import './create-notification.scss';

const initialValues = {
  title: '',
  description: '',
};

const CreateNotification = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();

  const { loading, allEmployees } = useGetEmployees();
  const { formatedCompanies } = useGetCompanies();
  const searchEmployees = useFormInput('');
  const { value = '' } = { ...searchEmployees };

  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectAll, setSelectAll] = useToggle();

  const { spinner, handleCreateNotification } = useCreateNotification(selectedEmployees, setSelectedEmployees);

  useEffect(() => {
    if (!isEmpty(allEmployees)) {
      setEmployees(allEmployees);
    }
  }, [allEmployees]);

  const filterUsers = (item) => {
    const { name, email } = { ...item };
    const employee = `${name}-${email}`.toLowerCase();
    return employee.indexOf(`${value}`.toLowerCase()) !== -1;
  };

  useEffect(() => {
    let temporal = allEmployees;
    if (value) {
      temporal = allEmployees.filter(filterUsers);
    }
    if (selectedCompany) {
      temporal = allEmployees.filter(filterUsers).filter(({ company: { id: compId } }) => compId === selectedCompany);
    }
    setEmployees(temporal);
  }, [allEmployees, value, selectedCompany]);

  const handleSelectAllEmployees = (isSelectAll) => {
    if (isSelectAll) {
      setSelectedEmployees((prevSelected) => {
        const remaining = allEmployees.map(({ id }) => id).filter((employeeId) => !prevSelected.includes(employeeId));
        return [...prevSelected, ...remaining];
      });
    } else {
      setSelectedEmployees([]);
    }
  };

  useEffect(() => {
    handleSelectAllEmployees(selectAll);
  }, [selectAll]);

  const handleSelectEmployee = (employeeId) => {
    setSelectedEmployees((prevSelected) => {
      const isSelected = prevSelected.find((item) => item === employeeId);
      if (isSelected) {
        return prevSelected.filter((item) => item !== employeeId);
      }
      return [...prevSelected, employeeId];
    });
  };

  const handleChangeCompany = (currSelectedCompany = {}) => {
    const { value: companyId = '' } = { ...currSelectedCompany };
    setSelectedCompany(companyId);
  };

  return (
    <section className="d-flex">
      <LeftMenu />
      <div className="dashboard__main">
        <Header title={t('leftMenuHeader.notification')} shadow />
        <div className="create-notification">
          <ReactTooltip place="left" effect="solid" id="select" />
          <div className="create-notification__fields">
            <Formik
              initialValues={initialValues}
              onSubmit={handleCreateNotification}
              validationSchema={createNotification}
              enableReinitialize
            >
              {({ isSubmitting, isValid }) => {
                const isDisabledButton = [!isValid, isSubmitting, isEmpty(selectedEmployees)].includes(true);
                return (
                  <Form>
                    <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
                    <Field
                      type="text"
                      label={t('formLabel.description')}
                      name="description"
                      minRows={4}
                      component={TextAreaInput}
                    />

                    <SolidButton
                      className={`mt-4 ml-auto ${isDisabledButton ? 'disabled' : ''}`}
                      disabled={isDisabledButton}
                      type="submit"
                    >
                      {t('button.create')}
                    </SolidButton>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="create-notification__list">
            <div className="create-notification__list__filters">
              <SearchFilter searchItems={searchEmployees} placeholder={t('searchLabel.employees')} />
              <Select
                options={formatedCompanies}
                onChange={handleChangeCompany}
                placeholder={t('filterLabel.companies')}
                styles={customStyles}
                isClearable
              />
              <button
                data-tip={selectAll ? t('tooltip.unselectAll') : t('tooltip.selectAll')}
                data-for="select"
                type="button"
                onClick={setSelectAll}
                className={`create-notification__list__filters_select ${
                  selectAll ? 'create-notification__list__filters_select--selected' : ''
                }`}
              >
                {selectAll && <BiCheck />}
              </button>
            </div>
            {!isEmpty(employees) && (
              <EmployeeList
                employees={employees}
                selectedEmployees={selectedEmployees}
                handleSelectEmployee={handleSelectEmployee}
              />
            )}
            {isEmpty(employees) && <NoEmployeesFound isModal />}
          </div>
        </div>
      </div>
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || spinner}
      />
    </section>
  );
};

export default CreateNotification;

import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty, pathOr } from 'ramda';

import { CREATE_UPDATE_AUDIO } from 'apollo/mutations';
import { ROUTES } from 'utils/constants';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';

export const useCreateUpdateAudio = (audioId) => {
  const { push } = useHistory();
  const [createUpdateAudio] = useMutation(CREATE_UPDATE_AUDIO);
  const [spinner, setSpinner] = useToggle();

  const handleCreateUpdateAudio = async (values) => {
    const { title, content, file, thumbnailImage, fileUs } = values;
    setSpinner();
    const response = await createUpdateAudio({
      variables: {
        title,
        content,
        ...(typeof file === 'object' && {
          file,
        }),
        ...(typeof thumbnailImage === 'object' && {
          thumbnailImage,
        }),
        ...(!!audioId && {
          audioId,
        }),
        ...(typeof fileUs === 'object' && {
          fileUs,
        }),
      },
    });

    const errors = pathOr([], ['data', 'createUpdateAudio', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha ${audioId ? 'actualizado' : 'creado'} el audio exitosamente.`] : errors,
    });

    if (emptyErrors) {
      push(ROUTES.AUDIO_LIST);
    }
  };

  return { createUpdateAudio: handleCreateUpdateAudio, spinner };
};

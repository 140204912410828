import React from 'react';
import Loader from 'react-loader-spinner';
import { propOr } from 'ramda';

import { capitalize } from 'utils/string';
import { GET_PLAN } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';

import Modal from 'components/Modal';
import Icon from 'components/Icon';
import PlanView from 'components/Plan/PlanView';

import './plan-view-modal.scss';

const modalProps = {
  content: {
    width: '100%',
    maxWidth: '1200px',
    padding: '0px !important',
  },
};

const PlanViewModal = (props) => {
  const { open, onCloseModal, planId } = { ...props };
  const { loading = false, data = {} } = planId
    ? useAuthQuery(GET_PLAN, {
        variables: { planId },
      })
    : {};
  const plan = propOr({}, 'plan')(data);
  const { title = '' } = { ...plan };

  return (
    <Modal modalIsOpen={open} closeModal={onCloseModal} modalProps={modalProps} closeable={false}>
      <div className="plan-view__modal">
        <div className="close-icon-container">
          <Icon name="close" className="close-icon" onClick={onCloseModal} />
        </div>
        {!loading && <p className="plan-view__modal__title">{capitalize(title)}</p>}
        <div className="plan-view__modal__content">{!loading && <PlanView plan={plan} />}</div>
        <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={loading} />
      </div>
    </Modal>
  );
};

export default PlanViewModal;

import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { MultiSelect } from '../../components/FormComponents';
import { isEmpty, pathOr, groupBy } from 'ramda';
import Modal from '../../components/Modal';
import { useMutation } from '@apollo/react-hooks';
import Button from '../../components/SolidButton';
import { FaTrash } from 'react-icons/fa';
import { availibilityForm } from '../../utils/validationSchema';
import showNotification from '../../utils/showNotification';
import Loader from 'react-loader-spinner';
import { CREATE_AVAILABILITY_SLOTS } from '../../apollo/mutations';
import moment from 'moment';

moment.locale = 'es';

const modalProps = {
  content: {
    width: '50vw',
    maxWidth: '740px',
    outline: 'none',
    padding: '0px !important',
  },
};

const generateHours = () => {
  const items = [];
  new Array(24).fill().forEach((acc, index) => {
    items.push(moment({ hour: index }).format('h:mm A'));
    items.push(moment({ hour: index, minutes: 30 }).format('h:mm A'));
  });
  return items.map((el) => ({ value: el, label: el }));
};

const dayOptions = [
  { value: 'monday', label: 'Lunes' },
  { value: 'tuesday', label: 'Martes' },
  { value: 'wednesday', label: 'Miércoles' },
  { value: 'thursday', label: 'Jueves' },
  { value: 'friday', label: 'Viernes' },
  { value: 'saturday', label: 'Sábado' },
  { value: 'sunday', label: 'Domingo' },
];

const mapAvailibilities = (availabilities) => {
  const flatObj = (availabilities || []).map((availability) => {
    const { weekDay: weekday, intervals = [] } = { ...availability };
    return intervals.map(({ start_at: startAt, end_at: endAt }) => {
      return {
        weekday,
        from: moment(startAt, 'HH:mm').format('h:mm A'),
        to: moment(endAt, 'HH:mm').format('h:mm A'),
      };
    });
  });
  return flatObj.reduce((a, b) => [...a, ...b], []);
};

const AvailabilityModal = (props) => {
  const { open, onCloseModal, availabilities = [] } = { ...props };
  const [availability, setAvailability] = useState([]);
  const [createAvailability] = useMutation(CREATE_AVAILABILITY_SLOTS);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setAvailability(mapAvailibilities(availabilities));
  }, [availabilities]);
  const saveAvailability = async () => {
    if (!isEmpty(availability)) {
      const groupedAvailability = groupBy(({ weekday = '' }) => weekday, availability);
      const intervals = Object.keys(groupedAvailability).map((day) => {
        const slots = groupedAvailability[day];
        return {
          day,
          intervals: slots.map((slot) => {
            const { from, to } = { ...slot };
            return {
              start_at: moment(from, 'h:mm A').format('HH:mm'),
              end_at: moment(to, 'h:mm A').format('HH:mm'),
            };
          }),
        };
      });

      setShowLoader(true);
      const response = await createAvailability({
        variables: {
          intervals,
        },
      });

      const errors = pathOr([], ['data', 'createAvailability', 'errors'])(response);
      const emptyErrors = isEmpty(errors);
      setShowLoader(false);
      showNotification({
        type: emptyErrors ? 'success' : 'error',
        messages: emptyErrors ? ['Se ha guardado la disponibilidad exitosamente.'] : errors,
      });
      if (emptyErrors) {
        onCloseModal();
      }
    }
  };
  return (
    <Modal modalIsOpen={open} closeModal={onCloseModal} modalProps={modalProps} closeable={false}>
      <div>
        <h3 className='modal__title title_left'>Editar disponibilidad horaria</h3>
        <p className='modal__subtitle subtitle_left no_max_width'>
          Configura los bloques de horario por día que tienes disponibles para atender a tus pacientes en llamadas o atender
          eventos.
        </p>

        <br />
        <div className={'availability-form'}>
          <Formik
            initialValues={{ weekday: '', from: '', to: '' }}
            onSubmit={(values) => {
              setAvailability([...availability, values]);
            }}
            validationSchema={availibilityForm}>
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className={'form-row'}>
                  <Field
                    type='text'
                    label={'Día'}
                    isRequired={true}
                    name={'weekday'}
                    component={MultiSelect}
                    options={dayOptions}
                    isMulti={false}
                    placeholder='Seleccione...'
                  />
                  <Field
                    type='text'
                    label={'Desde'}
                    isRequired={true}
                    name={'from'}
                    component={MultiSelect}
                    options={generateHours()}
                    isMulti={false}
                    placeholder='Seleccione...'
                  />
                  <Field
                    type='text'
                    label={'Hasta'}
                    isRequired={true}
                    name={'to'}
                    component={MultiSelect}
                    options={generateHours()}
                    isMulti={false}
                    placeholder='Seleccione...'
                  />
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Button type={'submit'} className={'secondary slim'}>
                      Agregar
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <br />
          {availability.map((item, index) => {
            return (
              <div className={'availability-row'} key={index}>
                <span className={'day'}>{(dayOptions.find(({ value }) => value === item.weekday) || {}).label}</span>
                <span>{item.from}</span>
                <span>{item.to}</span>
                <span>
                  <div
                    className={'action'}
                    onClick={() => {
                      setAvailability(availability.filter((a, i) => i !== index));
                    }}>
                    <FaTrash />
                  </div>
                </span>
              </div>
            );
          })}
          <br />
          <div
            className='d-flex justify-content-center mt-4 modal__buttons'
            style={{ borderTop: '1px solid #c5c6c5', paddingTop: '20px' }}>
            {!showLoader ? (
              <>
                <Button className={'secondary'} onClick={onCloseModal}>
                  Cancelar
                </Button>
                <div style={{ marginRight: '10px' }}></div>
                <Button
                  className={isEmpty(availability) ? 'disabled' : ''}
                  onClick={isEmpty(availability) ? null : saveAvailability}>
                  Guardar
                </Button>
              </>
            ) : (
              <Loader type={'ThreeDots'} color={'#495fd7'} height={100} width={100} className={'spinner'} visible={showLoader} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AvailabilityModal;

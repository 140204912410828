import React from 'react';
import { getAuthInfo } from '../../libs/authenticationService';

import './greeting.scss'

export default () => {
  const hours = new Date().getHours();
  const hiKey = hours > 18 || hours < 4 ? 'Buenas noches' : hours > 11 ? 'Buenas tardes' : 'Buenos días';
  const { user } = getAuthInfo();
  const { name = '' } = { ...user };

  return (
    <div className={'greeting'}>
      <p><span>{hiKey},</span> {name}</p>
    </div>
  )
};
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import Loader from 'react-loader-spinner';
import { prop, isEmpty, pathOr } from 'ramda';

import { BODY_PARTS } from 'apollo/queries';
import { CREATE_PATIENT_PLAN } from 'apollo/mutations';
import { CREATE_PLAN } from 'utils/steps';
import { useAuthQuery } from 'utils/hooks';
import showNotification from 'utils/showNotification';
import { ROUTES } from 'utils/constants';

import Button from 'components/SolidButton';
import PlanViewModal from 'components/CreatePatient/PlanViewModal';
import PlanList from 'components/CreatePatient/PlanList';
import { StoreConsumer } from 'components/StoreProvider';

import './segments.scss';

const Segments = ({ changeRoute, onSetStep }) => {
  const [createPatientPlan] = useMutation(CREATE_PATIENT_PLAN);
  const { loading, data } = useAuthQuery(BODY_PARTS);

  const [bodyPartId, setBodyPartId] = useState('');
  const [planId, setPlanId] = useState('');
  const [showedPlan, setShowedPlan] = useState('');
  const [showSpinner, setSpinner] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const bodyParts = prop('bodyParts')(data);

  const onGoBack = () => onSetStep(CREATE_PLAN);
  const onToggleModal = () => setModalOpen(!modalOpen);
  const showPlan = (id) => () => {
    onToggleModal();
    setShowedPlan(id);
  };

  useEffect(() => {
    if (!!bodyParts && bodyParts.length > 0) {
      const [first = {}] = [...bodyParts];
      const { id = '' } = { ...first };
      if (id) {
        setBodyPartId(id);
      }
    }
  }, [bodyParts]);

  const onRenderBodyParts = () =>
    (bodyParts || []).map(({ title, image, id }) => (
      <button
        type="button"
        key={id}
        className={`segments__item ${bodyPartId === id ? 'is-active' : ''}`}
        onClick={() => setBodyPartId(id)}
      >
        <img src={image} alt={title} className="segments__image" />
        <h3 className="segments__title">{title}</h3>
      </button>
    ));

  const onCreatePlan =
    ({ patient, setStoreData }) =>
    async () => {
      if (planId) {
        setSpinner(true);
        const response = await createPatientPlan({
          variables: {
            patientId: patient.id,
            planId,
          },
        });

        const errors = pathOr([], ['data', 'createPatientProfile', 'errors'])(response);
        const emptyErrors = isEmpty(errors);

        setSpinner(false);
        showNotification({
          type: emptyErrors ? 'success' : 'error',
          messages: emptyErrors ? ['Se ha creado el plan del paciente exitosamente.'] : errors,
        });

        if (emptyErrors) {
          setStoreData('initialState');
          changeRoute(`${ROUTES.PATIENTS}/${patient.id}`);
        }
      }
    };

  return (
    <StoreConsumer>
      {({ patient, setStoreData }) => (
        <div className="plan-container">
          <div
            className="content__side-wizard"
            style={{ flexDirection: 'column', justifyContent: 'flex-start', paddingTop: '60px' }}
          >
            <p className="left-title">Zonas</p>
            <div className="body-part-list">{onRenderBodyParts()}</div>
          </div>
          <div className="content__container" style={{ marginBottom: '40px' }}>
            <div className="d-flex w-100">
              {loading ? (
                <Loader
                  type="ThreeDots"
                  color="#495fd7"
                  height={100}
                  width={100}
                  className="spinner"
                  visible={loading}
                />
              ) : (
                <>
                  <div className="segments__container">
                    <h2 className="section-title">Planes Existentes</h2>
                    <p className="section-description">
                      Selecciona y asigna un plan para tu paciente de las plantillas existentes.
                    </p>
                    {bodyPartId && (
                      <PlanList showPlan={showPlan} planId={planId} setPlanId={setPlanId} bodyPartId={bodyPartId} />
                    )}
                  </div>
                  <Loader
                    type="ThreeDots"
                    color="#495fd7"
                    height={100}
                    width={100}
                    className="spinner"
                    visible={showSpinner}
                  />
                </>
              )}
            </div>
            <div className="button-container">
              <Button type="button" onClick={onGoBack} className="secondary">
                Atrás
              </Button>
              <Button type="button" category="rounded" onClick={onCreatePlan({ patient, setStoreData })}>
                Crear plan
              </Button>
            </div>
          </div>
          <PlanViewModal open={modalOpen} onCloseModal={onToggleModal} planId={showedPlan} />
        </div>
      )}
    </StoreConsumer>
  );
};

Segments.propTypes = {
  changeRoute: PropTypes.func.isRequired,
  onSetStep: PropTypes.func.isRequired,
};

export default Segments;

import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { CREATE_UPDATE_READING } from 'apollo/mutations';
import { ROUTES } from 'utils/constants';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';
import { formatDataToSend } from './utils';

export const useCreateUpdateReadingBuilder = (builderState = {}, readingId = '') => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [createUpdateReading] = useMutation(CREATE_UPDATE_READING);
  const [spinner, setSpinner] = useToggle();

  const { backgroundImage, thumbnailImage, title, content } = builderState;
  const formatedData = formatDataToSend(content);

  const onCreateUpdateReadingBuilder = async () => {
    setSpinner();
    const response = await createUpdateReading({
      variables: {
        blockContent: true,
        ...(typeof backgroundImage === 'object' && {
          backgroundImage,
        }),
        ...(typeof thumbnailImage === 'object' && {
          thumbnailImage,
        }),
        title,
        contentJson: formatedData,
        ...(!!readingId && {
          readingId,
        }),
      },
    });

    const errors = pathOr([], ['data', 'createUpdateReading', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();
    const successmessage = readingId
      ? t('notification.createReading.success.update')
      : t('notification.createReading.success.create');
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [successmessage] : errors,
    });

    if (emptyErrors) {
      push(ROUTES.READINGS);
    }
  };

  return {
    spinner,
    createUpdateReadingBuilder: onCreateUpdateReadingBuilder,
  };
};

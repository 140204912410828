import React from 'react';
import { useTranslation } from 'react-i18next';

import noPatients from 'assets/images/no-patients.svg';

import './no-forms-found.scss';

const NoFormsFound = () => {
  const { t } = useTranslation();

  return (
    <tr className="no-data-found">
      <td style={{ width: '100%' }}>
        <div className="content">
          <div className="content__text">
            <p className="content__text_title">{t('nofound.title')}</p>
            <p className="content__text_subtitle">{t('nofound.description')}</p>
          </div>
          <div className="content__image">
            <img src={noPatients} alt="no results" />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default NoFormsFound;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { BiCheck } from 'react-icons/bi';
import { AiOutlinePlus, AiOutlineMinusCircle } from 'react-icons/ai';

import { useCreateAvailabilityContext } from '../CreateAvailabilityProvider';
import { handleSringToNumber } from '../utils';

const AvailabilityList = () => {
  const { t } = useTranslation();
  const { allAvailabilities, handleSelectDay, handleRemoveInterval } = useCreateAvailabilityContext();

  const handleSortIntervals = (a, b) => handleSringToNumber(a.end_at) - handleSringToNumber(b.end_at);

  return (
    <>
      {allAvailabilities.map(({ id, weekDay, intervals }) => {
        const isActive = !isEmpty(intervals);
        const sortedIntervals = intervals.sort(handleSortIntervals);
        return (
          <section key={id} className="availability__weekdays">
            <div className="availability__weekdays_check">
              <div className={`custom-checkbox ${isActive ? 'selected' : ''}`}>{isActive && <BiCheck />}</div>
              <p className={isActive ? 'selected' : ''}>{t(`availability.weekDays.${weekDay}`)}</p>
            </div>
            <div className="availability__weekdays_intervals">
              {isActive &&
                sortedIntervals.map(({ start_at: startAt, end_at: endAt, id: intervalId }) => (
                  <div key={intervalId} className="interval">
                    <span>
                      <time dateTime={startAt}>{startAt}</time> - <time dateTime={endAt}>{endAt}</time>
                    </span>
                    <AiOutlineMinusCircle onClick={() => handleRemoveInterval(intervalId)} />
                  </div>
                ))}
            </div>
            <button type="button" className="availability__weekdays_add" onClick={() => handleSelectDay(id)}>
              <AiOutlinePlus />
            </button>
          </section>
        );
      })}
    </>
  );
};

export default AvailabilityList;

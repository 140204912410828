import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Modal from 'components/Modal';
import Icon from 'components/Icon';
import Button from 'components/Button';
import InviteCard from './InviteCard';

import { useAppState } from 'components/StoreProvider';

import { ROUTES } from 'utils/constants';
import { PERSONAL_INFORMATION, BULK_INVITE } from 'utils/steps';

import createPatient from 'assets/images/create-patient.png';
import invitePatient from 'assets/images/invite-patient.png';

import './patient-invite-modal.scss';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: 700,
    maxHeight: '95vh',
  },
};

const cards = [
  {
    image: createPatient,
    title: 'Evaluación + plan',
  },
  {
    image: invitePatient,
    title: 'Invitar paciente',
  },
  {
    image: createPatient,
    title: 'Iniciar evaluación',
  },
];

const getDescriptionText = (currentTab) => {
  switch (currentTab) {
    case 0: {
      return 'Aquí podrás evaluar por primera vez a tus pacientes y asignarles un plan de rehabilitación personalizado.';
    }
    case 1: {
      return 'Aquí podrás invitar uno o mas pacientes, y así, a través de videollamada elaborar un diagnóstico completo.';
    }
    case 2: {
      return 'Aquí podrás crear y evaluar por primera vez a tus pacientes.'; 
    }
    default: {
      return null;
    }
  }  
};

const PatientInviteModal = ({ modalIsOpen, toggleModal }) => {

  const { setStoreData = () => {}, toggleInviteCreateMember = () => {}, } = useAppState();
  const history = useHistory();
  const { pathname } = useLocation();

  const [tab, setTab] = useState(0);

  const onCreatePatient = ({ setStoreData, toggleInviteCreateMember, tab }) => () => {
    toggleInviteCreateMember();
    setStoreData('initialState');
    switch (tab) {
      case 0:
      case 2:
        setStoreData('step', PERSONAL_INFORMATION);
        setStoreData('currentTab', tab);
        break;
      case 1:
        setStoreData('step', BULK_INVITE);
        break;
      default:
        setStoreData('step', PERSONAL_INFORMATION);
    }
    if (pathname !== ROUTES.CREATE_PATIENT) {
      history.push(ROUTES.CREATE_PATIENT);
    }
  };

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={toggleModal} closeable={false} modalProps={modalProps}>
      <div className={'modal-invite-container'}>
        <Icon name='close' className={'modal-invite-container__icon'} onClick={toggleModal} />
        <h3 className='modal__title'>Opciones de creación de paciente</h3>
        <p className='help-text'>{getDescriptionText(tab)}</p>
        <div className={'modal-invite-container__card'}>
          {cards.map((card, index) => {
            return (
              <InviteCard 
                key={index} 
                card={card} 
                index={index} 
                tab={tab}
                onClick={() => setTab(index)}
              />
            );
          })}
        </div>
        <Button category='rounded' className={'confirmation-button'} onClick={onCreatePatient({ setStoreData, toggleInviteCreateMember, tab })}>
          Confirmar selección
        </Button>
      </div>
    </Modal>
  );
}

export default PatientInviteModal;

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import { VscPreview } from 'react-icons/vsc';

import { ROUTES } from 'utils/constants';
import { useToggle } from 'utils/hooks/useToggle';

import Thumb from 'components/Thumb';
import ReadingPreview from '../ReadingPreview';

const ReadingRow = ({ reading }) => {
  const { push } = useHistory();
  const {
    id,
    title,
    backgroundImage: { thumb },
    blockContent,
    contentJson,
  } = { ...reading };
  const previewData = {
    title,
    backgroundImage: thumb,
    content: contentJson,
  };

  const [isReadingPreview, setIsReadingPreview] = useToggle();

  const onCreateReading = () => {
    push(`${ROUTES.READING}?id=${id}&block_content=${blockContent}`);
  };

  return (
    <tr>
      <td style={{ width: '30%' }}>
        <Thumb source={thumb} alt={title} />
      </td>
      <td style={{ width: '50%' }}>{title}</td>
      <td style={{ width: '20%' }}>
        {blockContent && (
          <button type="button" onClick={setIsReadingPreview} className="form-detail">
            <VscPreview />
          </button>
        )}
        <button type="button" onClick={onCreateReading} className="form-detail">
          <IoIosArrowDown />
        </button>
      </td>
      {isReadingPreview && (
        <ReadingPreview modalIsOpen={isReadingPreview} toggleModal={setIsReadingPreview} previewData={previewData} />
      )}
    </tr>
  );
};

ReadingRow.propTypes = {
  reading: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    backgroundImage: PropTypes.shape({
      thumb: PropTypes.string,
    }),
    blockContent: PropTypes.bool,
    contentJson: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default ReadingRow;

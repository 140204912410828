import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import DateView from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './FormComponents.scss';

const DateInput = ({ label, gif, name, isRequired, ...rest }) => (
  <div className="question">
    <div className="question__validation">
      <label htmlFor={name}>{label}</label>
    </div>
    {!!gif && (
      <div className="question__gif">
        <img src={gif} alt="this is a Gif" />
      </div>
    )}
    <Field name={name}>
      {({ form, field }) => {
        const { setFieldValue } = { ...form };
        const { value } = { ...field };
        return (
          <DateView
            {...field}
            {...rest}
            id={name}
            dateFormat="yyyy/MM/dd"
            selected={value}
            onChange={(val) => setFieldValue(name, val)}
            showPopperArrow={false}
            showMonthDropdown
            showYearDropdown
            className="input"
            placeholderText="YYYY/MM/DD"
          />
        );
      }}
    </Field>
  </div>
);

DateInput.defaultProps = {
  gif: '',
};

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  gif: PropTypes.string,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
};

export default DateInput;

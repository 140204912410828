import React from 'react';
import { BsArrowLeftShort, BsCheckBox, BsClock, BsArrowRepeat, BsPerson } from 'react-icons/bs';
import { FiCalendar } from 'react-icons/fi';
import { MdChatBubbleOutline } from 'react-icons/md';
import tatiSit from '../../../assets/images/tati-sit.png';
import tatiHand from '../../../assets/images/tati-hand.png';

const Preview = ({ colors }) => {
  return (
    <aside className={'edit-zone__preview__app'}>
      <div className={'edit-zone__preview__app_header-icons'}>
        <BsArrowLeftShort size={26}/>
        <BsCheckBox/>
      </div>
      <p className={'edit-zone__preview__app_title'}>Ejercicios para hoy</p>
      <p style={{color: colors.secondary}} className={'edit-zone__preview__app_subtitle'}>Dia 5 de semana 3</p>
      <div className={'edit-zone__preview__app__indicators'}>
        <p style={{background: colors.terciary}}>
          <BsClock size={16}/>
          {' '}
          30 Minutos
        </p>
        <p style={{background: colors.terciary}}>
        <BsArrowRepeat size={18}/>
          {' '}
          4 Ejercicios
        </p>
      </div>
      <hr/>
      <div className={'edit-zone__preview__app__required'}>
        <p className={'edit-zone__preview__app__required_title'}>Elementos requeridos:</p>
        <div className={'edit-zone__preview__app__required__items'}>
          <p style={{background: colors.secondary}}>Colchoneta</p>
          <p style={{background: colors.secondary}}>Silla</p>
        </div>
      </div>
      <hr/>
      <div className={'edit-zone__preview__app__exercise'}>
        <div className={'edit-zone__preview__app__exercise__image'}>
          <img src={tatiSit} alt={'example imgage'}/>
        </div>
        <div className={'edit-zone__preview__app__exercise__content'}>
          <p>Estiramiento de aductores en mariposa</p>
          <p>4 series / 10 repeticiones</p>
        </div>
      </div>
      <hr/>
      <div className={'edit-zone__preview__app__exercise'}>
        <div className={'edit-zone__preview__app__exercise__image'}>
          <img src={tatiHand} alt={'example imgage'}/>
        </div>
        <div className={'edit-zone__preview__app__exercise__content'}>
          <p>Estabilización lumbopélvica - Bird Dog</p>
          <p>4 series / 10 repeticiones</p>
        </div>
      </div>
      <hr/>
      <p style={{background: colors.primary}} className={'edit-zone__preview__app_mimic-button'}>Empezar ejercicios</p>
      <div className={'edit-zone__preview__app__nav'}>
        <div className={'edit-zone__preview__app__nav__icon'}>
          <FiCalendar size={20} color={colors.primary}/>
          <p style={{color: colors.primary}}>Hoy</p>
        </div>
        <div className={'edit-zone__preview__app__nav__icon'}>
          <BsPerson size={20} color={'#606060'}/>
          <p>Configuración</p> 
        </div>
        <div className={'edit-zone__preview__app__nav__icon'}>
          <MdChatBubbleOutline size={20} color={'#606060'}/>
          <p>Contacto</p>
        </div>
      </div>
    </aside>
  );
}

export default Preview;
import React from 'react';
import { path, propOr } from 'ramda';

import { GET_BOOKINGS } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';
import {
  BULK_INVITE,
  PERSONAL_INFORMATION,
  MEDICAL_ASSESMENT,
  CREATE_PLAN,
  SEGMENTS,
  CREATE_CUSTOM_PLAN,
  CREATE_WEEK,
  EDIT_PLAN,
} from 'utils/steps';
import { LEFT_MENU } from 'utils/leftMenu';

import Header from 'components/Header';
import BulkInvite from 'components/CreatePatient/BulkInvite';
import PersonalInformation from 'components/CreatePatient/PersonalInformation';
import MedicalAssessment from 'components/CreatePatient/MedicalAssessment';
import CreatePlan from 'components/CreatePatient/CreatePlan';
import CreateCustomPlan from 'components/CreatePatient/CreateCustomPlan';
import CreateWeek from 'components/CreatePatient/CreateWeek';
import Segments from 'components/CreatePatient/Segments';
import StepIndicator from 'components/CreatePatient/StepIndicator';
import { StoreConsumer } from 'components/StoreProvider';

import './create-patient.scss';

const CreatePatient = (props) => {
  useRedirectByRole([ROLES.CLINIC, ROLES.SUPER_CLINIC, ROLES.SPECIALIST]);
  const { userRole, isSuperClinic } = getUserRole();
  const changeRoute = path(['history', 'push'])(props);
  const location = propOr({}, 'location')(props);
  const { data: patientsData } = useAuthQuery(GET_BOOKINGS);
  const { clinicDashboard: clinicPatientsData = {} } = { ...patientsData };
  const { patientStatus = {} } = { ...clinicPatientsData };
  const { current_active: currActive = 0, current_available: currAvailable = 0 } = { ...patientStatus };

  const onSetStep = (setStoreData) => (newStep) => setStoreData('step', newStep);

  const onHeaderTitle = (step) => {
    switch (step) {
      case CREATE_PLAN:
      case CREATE_CUSTOM_PLAN:
      case CREATE_WEEK:
      case SEGMENTS:
        return 'Crear plan';
      case EDIT_PLAN:
        return 'Editar plan';
      case BULK_INVITE:
        return 'Invitar pacientes';
      default:
        return 'Agregar nuevo paciente';
    }
  };

  const onRenderStep =
    ({ setStoreData, step, patient, week }) =>
    () => {
      switch (step) {
        case MEDICAL_ASSESMENT:
          return <MedicalAssessment onSetStep={onSetStep(setStoreData)} fromPatients={location} />;
        case CREATE_PLAN:
          return <CreatePlan onSetStep={onSetStep(setStoreData)} />;
        case CREATE_CUSTOM_PLAN:
        case EDIT_PLAN:
          return (
            <CreateCustomPlan onSetStep={onSetStep(setStoreData)} patientId={patient.id} changeRoute={changeRoute} />
          );
        case CREATE_WEEK:
          return <CreateWeek onSetStep={onSetStep(setStoreData)} patientId={patient.id} week={week} />;
        case SEGMENTS:
          return <Segments onSetStep={onSetStep(setStoreData)} changeRoute={changeRoute} />;
        case BULK_INVITE:
          return <BulkInvite changeRoute={changeRoute} onSetStep={onSetStep(setStoreData)} />;
        case PERSONAL_INFORMATION:
        default:
          return <PersonalInformation onSetStep={onSetStep(setStoreData)} fromPatients={location} />;
      }
    };

  if (currActive > currAvailable && !isSuperClinic) return null;

  return (
    <StoreConsumer>
      {({ setStoreData, step, patient, week, customPlan }) => {
        const isPatientCreation =
          step !== BULK_INVITE &&
          step !== CREATE_CUSTOM_PLAN &&
          step !== SEGMENTS &&
          step !== CREATE_WEEK &&
          step !== EDIT_PLAN;
        const { planId } = { ...customPlan };
        return (
          <div className="d-flex hvh-100">
            {LEFT_MENU[userRole]}
            <div className="dashboard__main">
              <Header title={onHeaderTitle(planId ? EDIT_PLAN : step)} shadow />
              <div className="dashboard__content__create_patient">
                <div className="dashboard__content__wizard">
                  {isPatientCreation && (
                    <div className="content__side-wizard">
                      <StepIndicator step={step} />
                    </div>
                  )}
                  <div className="dashboard__content__wizard__content">
                    <div className={`${isPatientCreation ? 'content__container' : 'content__fullwidth'}`}>
                      {onRenderStep({ setStoreData, step, patient, week })()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </StoreConsumer>
  );
};

export default CreatePatient;

import React from 'react';
import PropTypes from 'prop-types';
import { TiUserDelete } from 'react-icons/ti';
import { BiInfoCircle } from 'react-icons/bi';

import maleAvatar from 'assets/images/patient-male.svg';
import femaleAvatar from 'assets/images/patient-female.svg';

const EmployeesList = ({ employees, toggleAlert, toggleEmloyeeSpend, isNoSubscription }) => (
  <>
    {(employees || []).map((employee) => {
      const { id = '', name = '', email = '', status = '', gender = '' } = { ...employee };
      const IS_UNACTIVE = status === 'unactive';
      return (
        <tr key={id}>
          <td style={{ width: '10%' }}>
            <img src={gender === 'Hombre' ? maleAvatar : femaleAvatar} alt="employee avatar" />
          </td>
          <td style={{ width: '30%' }}>{name}</td>
          <td style={{ width: '15%' }}>
            <span className={IS_UNACTIVE ? 'unactive' : ''}>{status}</span>
          </td>
          <td style={{ width: '25%' }}>{email}</td>
          <td style={{ width: '15%' }}>
            <TiUserDelete className="employee-edit" onClick={() => toggleAlert(employee)} />
          </td>
          {isNoSubscription && (
            <td style={{ width: '5%' }}>
              <BiInfoCircle className="employee-info" onClick={() => toggleEmloyeeSpend(employee)} />
            </td>
          )}
        </tr>
      );
    })}
  </>
);

EmployeesList.propTypes = {
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      status: PropTypes.string,
      gender: PropTypes.string,
    }),
  ).isRequired,
  toggleAlert: PropTypes.func.isRequired,
  toggleEmloyeeSpend: PropTypes.func.isRequired,
  isNoSubscription: PropTypes.bool.isRequired,
};

export default EmployeesList;

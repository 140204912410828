import React, {useState, useEffect} from 'react';
import Loader from 'react-loader-spinner';
import { loadStripe } from '@stripe/stripe-js';
import {useStripe, useElements, Elements, PaymentElement} from '@stripe/react-stripe-js';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { currencyFormat as formatter } from 'utils/string';
import { CHECK_COUPON_WEB } from 'apollo/queries';
import { CREATE_EVIE_PAYMENT_METHOD } from 'apollo/mutations';
import logo from 'assets/images/evie-logo.svg';
import check from 'assets/images/evie-check.svg';
import banner from 'assets/images/evie-banner.svg'
import paymentSuccess from 'assets/images/evie-payment-success.svg';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './evie-checkout.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const prices = {
  usd: 150,
  eur: 140,
  cop: 650000
}

const sources = {
  production: '2hfNuIEbadLQURluvsv8fQ',
  development: 'xYP8Zt4ImFYuTkj7U2Rj9A',
};

const products = {
  production: {
    usd: 'prod_P478Plwgwr4z6Y',
    eur: 'prod_P479HtCCCeKgd3',
    cop: 'prod_P478mrp0GuF7Ch'
  },
  development: {
    usd: 'prod_P46yLzBvYz2Y9f',
    eur: 'prod_P46dACVIinYzxS',
    cop: 'prod_P478rGad4MTufJ'
  }
}

const source =  sources[process.env.REACT_APP_ENV] || sources.development;
const envProducts = products[process.env.REACT_APP_ENV] || products.development;

const installments = [2,3,4,5,6];

const EvieCheckout = ({currency, onChangeCurrency}) => {
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [coupon, setCoupon] = useState();
  const [paymentData, setPaymentData] = useState({ installments:'1' });
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [createEviePaymentMethod] = useMutation(CREATE_EVIE_PAYMENT_METHOD);
  const [checkCoupon, { data: couponData = {} }] = useLazyQuery(CHECK_COUPON_WEB);
  const [discount, setDiscount] = useState(0);
  const [success, setSuccess] = useState(false);
  const [language,setLanguage] = useState('en');
  const [country, setCountry] = useState('ee');
  const [couponError, setCouponError] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const price = prices[currency]||0;
  const total = price - discount;
  const productId = envProducts[currency];

  const countries = {
    US: 'usd',
    CO:'cop',
    AT:'eur',
    BE:'eur',
    BG:'eur',
    HR:'eur',
    CY:'eur',
    CZ:'eur',
    DK:'eur',
    EE:'eur',
    FI:'eur',
    FR:'eur',
    DE:'eur',
    GR:'eur',
    HU:'eur',
    IE:'eur',
    IT:'eur',
    LV:'eur',
    LT:'eur',
    LU:'eur',
    MT:'eur',
    NL:'eur',
    PL:'eur',
    PT:'eur',
    RO:'eur',
    SK:'eur',
    SI:'eur',
    ES:'eur',
    SE:'eur',
    GB:'eur',
  }

  const currencyFormat = (amount)=> {
    const decimalCount = currency === 'cop'? 0: 2;
    const decimal = currency === 'cop'? ',': '.';
    const thousands = currency === 'cop'? '.': ',';
    const symbol = currency === 'eur' ? '€' : '$'
    return `${symbol}${formatter(amount, decimalCount, decimal, thousands)}`;
  }

  const confirmEmail = () => {
    setPaymentData({...paymentData, email })
  }

  const confirmName = () => {
    setPaymentData({...paymentData, name })
  }

  const confirmPhone = () => {
    setPaymentData({...paymentData, phone })
  }

  const validateCoupon = async() => {
    setCouponError(false);
    if (paymentData.couponCode) {
      setPaymentData({...paymentData, couponCode: undefined });
      setDiscount(0);
    } else {
      await checkCoupon({ variables: { couponId: `${coupon}` } });
    }
  }

  const onChangeInstallments= (e)=> {
    setPaymentData({...paymentData, installments: e.target.value })
  }

  const handlePayment = async () => {

    if(loading || !email || !phone || !name || !stripe || !elements || !checked || !paymentData || !paymentData?.name || !paymentData?.phone || !paymentData?.email || success) {
      return;
    }
    
    setLoading(true);
    try {

      if (!stripe || !elements) {
        setLoading(false);
        return;
      }

      const {error: submitError} = await elements.submit();
      if (submitError) {
        console.log(submitError);
        setLoading(false);
        return;
      }

      const result = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name: paymentData.name,
            email: paymentData.email,
            phone: paymentData.phone
          },
        },
      });

      if (result?.error) {
        alert(result?.error.message)
      } else {
        if (!!result.paymentMethod.id) {
          const response = await createEviePaymentMethod({
            variables: {
              name: paymentData.name,
              email: paymentData.email,
              phone: paymentData.phone,
              coupon: paymentData.couponCode || null,
              currency,
              installments: paymentData.installments,
              paymentMethodId: result.paymentMethod.id,
              productId,
              source,
              language
            },
          });
  
          if (!!response?.data?.createEvieExternalPayment?.success) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setSuccess(true);
          } else {
            alert(response?.data?.createEvieExternalPayment?.errors[0])
          }
        }
      }
    }catch(err) {
      console.log(err)
      alert("Unexpected error creating payment for your plan, try again")
    }finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = 'Checkout - Evie Premium';
    const link = document.querySelector('link[rel="icon"]');
    link.setAttribute('href', 'evie-favicon.png');

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onGeoSuccess, onGeoError);
    } else {
      console.log("Geolocation not supported");
      onChangeCurrency('eur');
    }
  }, []);

  function onGeoSuccess(position) {
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;

    fetch(`http://api.openweathermap.org/geo/1.0/reverse?lat=${lat}&lon=${lon}&limit=1&appid=8ad91b1af19a2bdfb7ae0e9589f54d57`).then((data)=>{
     return data.json()
    }).then((data)=>{
      const result = (data ||[])[0];
      const { country: location = 'EE' } = {...result};
      onChangeCurrency(countries[location]||'eur');
      setCountry(`${location}`.toLowerCase())
      if(location === 'ES' || location === 'CO') {
        setLanguage('es');
      }
    }).catch((error)=>{
      console.log(error);
      onChangeCurrency('eur');
    })

  }

  function onGeoError() {
    console.log("Unable to retrieve your location");
    onChangeCurrency('eur');
  }

  useEffect(()=>{
    document.body.style.overflow = success?'hidden': 'unset';
  },[success])

  useEffect(()=>{
    if((couponData?.checkStripeCouponWeb?.errors?.length || 0) === 0 && couponData?.checkStripeCouponWeb?.coupon?.valid) {
      setCoupon(couponData?.checkStripeCouponWeb?.coupon?.id);
      setDiscount(couponData?.checkStripeCouponWeb?.coupon?.amount_off ? couponData?.checkStripeCouponWeb?.coupon?.amount_off : price*(couponData?.checkStripeCouponWeb?.coupon?.percent_off/100));
      setPaymentData({...paymentData, couponCode: couponData?.checkStripeCouponWeb?.coupon?.id});
    } else if (coupon) {
      setCoupon('')
      setCouponError(true);
    }
  }, [couponData?.checkStripeCouponWeb?.coupon?.id, couponData?.checkStripeCouponWeb?.errors?.join("-")])

  return (
    <>
     <Loader
        type="ThreeDots"
        color="#9B2C51"
        height={100}
        width={100}
        className="spinner"
        visible={loading}
      />
      {success && <div className={'success-modal-overlay'}>
        <div className={'success-modal-container'}>
        <img className={'success-modal-image'} src={paymentSuccess} alt="success" />
          <p className={'succes-modal-title'}>Your Payment is Successfull</p>
          <p className={'succes-modal-message'}>Thank you for your payment. An automated email will be sent to your registered email with your Evie access credentials.</p>
          <button className={'evie-button small'} onClick={()=>{window.location.replace('https://www.hievie.com')}} >
            {'Go to Home'}
          </button>
        </div>
      </div>}
    <div className={'evie-main-container'}>
      <div className={'evie-checkout-container'}>
        <div className={'evie-card evie-main-card'}>
          <img src={logo} className={'evie-logo'}/>
          <div className={'evie-main-form'}>

            <h2 className={'evie-section-title'}>Customer</h2>
            <p className={'evie-label'}>Email:</p>
            <input type={'email'} className={'evie-input email-input'} value={email} onChange={(e)=>setEmail(e.target.value)} onBlur={confirmEmail} autoComplete="on"/>

            <p className={'evie-label'}>Name:</p>
            <input type={'text'} name="name" id="name" className={'evie-input email-input'} value={name} onChange={(e)=>setName(e.target.value)} onBlur={confirmName} autoComplete="on"/>

            <p className={'evie-label'}>Phone:</p>
            <PhoneInput
              country={country}
              value={phone}
              onChange={(value)=>{setPhone(value)}}
              onBlur={()=>{confirmPhone()}}
              containerStyle={{width: '100%'}}
              inputStyle={{width: '100%', border: '1px solid #818181', paddingTop: '0.5rem',  paddingBottom: '0.5rem'}}
              inputClass={'evie-input'}
            />
            <br/>
            <br/>

            <h2 className={'evie-section-title'}>Payment</h2>
            <div className={`evie-payment-data-container`}>

            <p className={'evie-label'}>Currency:</p>
            <select className={'evie-input evie-payment-type'} value={currency} onChange={(e)=>onChangeCurrency(e.target.value)}>
              <option value={'usd'}>United States Dollar (USD)</option>
              <option value={'eur'}>Euro (EUR)</option>
              <option value={'cop'}>Peso Colombiano (COP)</option>
            </select>

            <p className={'evie-label'}>Payment Type:</p>
            <select className={'evie-input evie-payment-type'} value={paymentData?.installments} onChange={(e)=>onChangeInstallments(e)}>
              <option value={'1'}>Pay Now ({currencyFormat(total)})</option>
              {installments.map((i)=>{
                return (<option value={`${i}`} key={`${i}`}>{i} Payments x ({currencyFormat(total/i)}/mo)</option>)
              })}
            </select>

            
            <p className={'evie-label'}>Coupon Code:</p>
            <div className={'evie-email-confirmed'}>
                {paymentData?.couponCode ? <h3>{paymentData?.couponCode}</h3> :
                <input type={'text'} className={'evie-input inline'} value={coupon} onChange={(e)=>setCoupon(e.target.value)}/>}
                <button className={'evie-button small outline'} onClick={validateCoupon} disabled={!paymentData?.couponCode && !coupon}>
                {paymentData?.couponCode ? 'Clear' : 'Apply'}
                </button>
              </div>
            </div>
            {couponError && <p className={'evie-promo-code-error'}>Oops! Looks like that promo code isn't quite right. Please check it and try again!</p>}
            <br/>
            <p className={'evie-label'}>Payment Method:</p>
            <br/>
            <PaymentElement id="payment-element" />
          </div>
        </div>
        <div className={'evie-card evie-right-card'}>
          <p className={'evie-program-name'}>Evie Premium Program</p>
          <img src={banner} className={'evie-banner'} />
          <div className={'evie-list-item'}>
            <img src={check} className={'evie-check'} />
            <p className={'evie-paragraph'}>Initial 45-minute virtual assessment.</p>
          </div>
          <div className={'evie-list-item'}>
            <img src={check} className={'evie-check'} />
            <p className={'evie-paragraph'}>7 private 15-minute follow-up sessions.</p>
          </div>
          <div className={'evie-list-item'}>
            <img src={check} className={'evie-check'} />
            <p className={'evie-paragraph'}>7 private 15-minute follow-up sessions.</p>
          </div>
          <div className={'evie-list-item'}>
            <img src={check} className={'evie-check'} />
            <p className={'evie-paragraph'}>Exercise routines on the app ranging from 10 to 20 minutes.</p>
          </div>
          <div className={'evie-list-item'}>
            <img src={check} className={'evie-check'} />
            <p className={'evie-paragraph'}>24/7 private chat with a physiotherapist.</p>
          </div>
          <div className={'evie-list-item'}>
            <img src={check} className={'evie-check'} />
            <p className={'evie-paragraph'}>Guided Kegel exercises and coordination by experts.</p>
          </div>
          <div className={'evie-list-item'}>
            <img src={check} className={'evie-check'} />
            <p className={'evie-paragraph'}>Interactive and educational content: videos, audios, articles, and practical exercises.</p>
          </div>
          <div className={'evie-list-item'}>
            <img src={check} className={'evie-check'} />
            <p className={'evie-paragraph'}> Access to the app for 6 months.</p>
          </div>
          <hr/>
            <div className={'evie-price-item'}>
              <p className={'evie-paragraph name'}>Subtotal</p>
              <p className={'evie-paragraph value'}>{currencyFormat(price)}</p>
            </div>
            {paymentData?.couponCode && !!discount && <div className={'evie-price-item'}>
              <p className={'evie-paragraph name'}>Discount <span className='discount-code'>({paymentData?.couponCode})</span></p>
              <p className={'evie-paragraph value'}>-{currencyFormat(discount)}</p>
            </div>}
          <hr/>
            <div className={'evie-price-item total'}>
              <p className={'evie-paragraph name'}>Total</p>
              <p className={'evie-paragraph value'}><strong>{currencyFormat(total)}</strong></p>
            </div>
          <p className={'evie-light-paragraph'}>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p>
          <div className={'evie-check-item'}>
            <input type="checkbox" defaultChecked={checked} onChange={() => setChecked((state) => !state)} className={'evie-checkbox'}/>
            <p className={'evie-paragraph'}>I have read and agree to the website <a href="https://www.iubenda.com/terms-and-conditions/83039977" target={'_blank'}>terms and conditions</a> and <a href="https://www.iubenda.com/privacy-policy/83039977" target={'_blank'}>privacy policy</a>. By checking this box you verify that you are <strong>over 18 years of age.</strong></p>
          </div>
        <button disabled={loading || !email || !phone || !name || !stripe || !elements || !checked || !paymentData || !paymentData?.name || !paymentData?.phone || !paymentData?.email || success } id="submit" className={'evie-button'} type='button' onClick={handlePayment}>Place Order</button>
        </div>
      </div>
    </div>
    </>

 

  );
};


const EvieCheckoutWrapper = ()=> {
  const [currency, setCurrency] = useState('eur');
  return (
  <Elements stripe={stripePromise} options={{ paymentMethodCreation: 'manual', mode: 'setup', currency }}>
    <EvieCheckout currency={currency} onChangeCurrency={setCurrency}/>
  </Elements>)
}

export default EvieCheckoutWrapper;

import React from 'react';
import { Formik, Field, Form } from 'formik';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';

import { addTeamMember } from 'utils/validationSchema';

import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';
import { TextInput } from 'components/FormComponents';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: '390px',
  },
};

const AddTeamMemberModal = ({ addUpdateMember, teamMember, modalIsOpen, onCloseModal }) => {
  const initialValues = { name: '', email: '' };
  const { name = '', email = '' } = teamMember;
  const { t } = useTranslation();

  const onAddTeamMember = (values) => addUpdateMember(values);

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={onCloseModal} closeable={false} modalProps={modalProps}>
      <p className="add-team-member-modal-title">
        {isEmpty(teamMember) ? t('settings.team.addModal.addTitle') : t('settings.team.addModal.updTitle')}
      </p>
      <Formik
        initialValues={isEmpty(teamMember) ? initialValues : { name, email }}
        onSubmit={onAddTeamMember}
        validationSchema={addTeamMember}
        enableReinitialize
      >
        {({ handleSubmit, isValid, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Field label={t('formLabel.name')} name="name" component={TextInput} />
            <Field label={t('formLabel.email')} name="email" component={TextInput} />
            <div className="add-team-member-modal-buttons">
              <SolidButton onClick={onCloseModal} type="button" className="secondary min-width-100 mr-2">
                {t('button.cancel')}
              </SolidButton>
              <SolidButton type="submit" className={`min-width-100 ${(!isValid || isSubmitting) && 'disabled'}`}>
                {isEmpty(teamMember) ? t('button.add') : t('button.update')}
              </SolidButton>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddTeamMemberModal;

import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { VALIDATE_USER } from '../../apollo/queries';
import { path, isEmpty } from 'ramda';
import Loader from 'react-loader-spinner';
import { useRedirectByRole } from '../../utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import LeftMenu from '../../components/LeftMenu';
import Header from '../../components/Header';
import Greeting from '../../components/Greeting';
import CreateClinicData from '../../components/CreateClinicData';
import orgAccount from '../../assets/images/org-account.svg';

import './onboarding.scss';

const Onboarding = (props) => {
  useRedirectByRole([ROLES.CLINIC]);
  const changeRoute = path(['history', 'push'])(props);
  const { data: clinicData, loading, refetch } = useQuery(VALIDATE_USER);
  const { getClinicInfo: clinic = {} } = { ...clinicData };
  const { availabilities = [], patients = [], plans = [], forms = [] } = { ...clinic };
  const clinicOptions = [
    {
      data: availabilities,
      step: 'calendar',
    },
    {
      data: patients,
      step: 'patients',
    },
    {
      data: plans,
      step: 'plans',
    },
    {
      data: forms,
      step: 'forms',
    },
  ];

  const memoData = useMemo(() => {
    let normalizeData = (clinicOptions || []).map((option) => {
      const { data = [] } = { ...option };
      return isEmpty(data) ? 0 : 1;
    });
    return normalizeData.reduce((acc, curr) => acc + curr, 0);
  }, [clinicOptions]);

  const completed = (memoData * 100) / clinicOptions.length;

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={'d-flex'}>
      <LeftMenu history={props.history} />
      <div className={'dashboard__main'}>
        <Header title={'Inducción'} shadow />
        {loading && (
          <div className={'loading-container'}>
            <Loader type={'ThreeDots'} color={'#495fd7'} height={100} width={100} visible={loading} />
          </div>
        )}
        {!loading && (
          <>
            <Greeting />
            <div className={'onboarding'}>
              <div className={'onboarding__bar'}>
                <p className={'onboarding__bar_completed'}>
                  <strong>!Completa tus tareas! </strong>
                  llevas el {completed}% completado.
                </p>
                <progress value={completed} max={100}>
                  Completado
                </progress>
                <p className={'onboarding__bar_link'}>
                  Si deseas aprender más de como darle un buen uso a la plataforma en{' '}
                  <a
                    className={'step-by-step'}
                    href={'https://healthphy.helpscoutdocs.com/'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    este enlance.
                  </a>{' '}
                  encontrarás información extra.
                </p>
                <div className={'onboarding__image'}>
                  <img src={orgAccount} alt={'organiza tu cuenta'} />
                </div>
              </div>
              <div className={'onboarding__steps'}>
                <p className={'onboarding__steps_title'}>
                  Al finalizar esta inducción estarás listo para iniciar correctamente en la plataforma y darle a tus
                  pacientes la mejor atención. Si quieres saber más ingresa a{' '}
                  <a
                    className={'step-by-step'}
                    href={'https://healthphy.helpscoutdocs.com/'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    este enlance.
                  </a>
                </p>
                {(clinicOptions || []).map((option, index) => {
                  const { step = '', data = [] } = { ...option };
                  return (
                    <React.Fragment key={`${index}-${step}`}>
                      {isEmpty(data) && <CreateClinicData step={step} changeRoute={changeRoute} data={data} />}
                    </React.Fragment>
                  );
                })}
                {memoData > 0 && (
                  <div className={'completed-title'}>
                    <span></span>
                    <p>Realizados</p>
                    <span></span>
                  </div>
                )}
                {(clinicOptions || []).map((option, index) => {
                  const { step = '', data = [] } = { ...option };
                  return (
                    <React.Fragment key={`${index}-${step}`}>
                      {!isEmpty(data) && <CreateClinicData step={step} data={data} />}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Onboarding;

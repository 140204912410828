import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const HeadingPreview = ({ block }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { type, style, content } = block;
  return <h3 className={`readingPreview__info_title ${type} ${style ? 'bold-block' : ''}`}>{content[language]}</h3>;
};

HeadingPreview.defaultProps = {
  block: {},
};

HeadingPreview.propTypes = {
  block: PropTypes.shape({
    style: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
    }),
  }),
};

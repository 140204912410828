import { useMutation } from '@apollo/react-hooks';
import { UPDATE_USER_LANGUAGE } from 'apollo/mutations';
import i18next from 'i18next';
import { isEmpty, pathOr } from 'ramda';

export const useSetLanguage = () => {
  const [updateUserLanguage] = useMutation(UPDATE_USER_LANGUAGE);
  const setLanguage = async (language) => {
    const response = await updateUserLanguage({
      variables: { language },
    });

    const errors = pathOr([], ['data', 'updateUserLanguage', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    if (emptyErrors) {
      i18next.changeLanguage(language);
    }
  };

  return { setLanguage };
};

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import { propOr } from 'ramda';

import { FETCH_LANGUAGE_BY_ROLE, GET_PARTNER_BY_ROLE } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';
import { useAuthQuery } from 'utils/hooks';
import { loggedIn } from 'libs/authenticationService';

import { translationEs } from 'utils/i18next/es';
import { translationEn } from 'utils/i18next/en';

const SetupLanguage = () => {
  const { userRole } = getUserRole();
  const isLoggedIn = loggedIn();

  const { data } = isLoggedIn ? useAuthQuery(FETCH_LANGUAGE_BY_ROLE[userRole]) : {};
  const partner = propOr({}, GET_PARTNER_BY_ROLE[userRole])(data);
  const { profile = {} } = { ...partner };
  const { language: lng } = { ...profile };

  i18next
    .use(detector)
    .use(initReactI18next)
    .init({
      resources: {
        es: { translation: translationEs },
        en: { translation: translationEn },
      },
      ...(!!lng && {
        lng,
      }),
      fallbackLng: 'es',
      interpolation: { escapeValue: false },
    });

  return null;
};

export default SetupLanguage;

import { useEffect } from 'react';

import { useAuthQuery } from 'utils/hooks';
import { GET_COMPANY_INVOICES } from 'apollo/queries';

export const useGetCompanyInvoices = () => {
  const { loading, data, refetch } = useAuthQuery(GET_COMPANY_INVOICES);
  const { companyInvoices = {} } = { ...data };

  useEffect(() => {
    refetch();
  }, []);

  return { loading, refetch, companyInvoices };
};

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import { GET_COMPANIES } from 'apollo/queries';
import { useAuthQuery, useFormInput } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROUTES, ROLES } from 'utils/constants';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SearchFilter from 'components/SearchFilter';
import SolidButton from 'components/SolidButton';
import CompaniesList from 'components/companies/CompaniesList';
import NoCompaniesFound from 'components/Forms/NoFormsFound';
import { NoMembers } from 'components/EmptyState';

import noTemplates from 'assets/images/no-data.svg';

const Companies = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const { push } = useHistory();

  const { loading, data, refetch } = useAuthQuery(GET_COMPANIES);
  const { companies: allCompanies = [] } = { ...data };

  const searchCompanies = useFormInput('');
  const { value = '' } = { ...searchCompanies };

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    let temporal = allCompanies;
    if (value) {
      temporal = allCompanies.filter((program) => {
        const { name } = { ...program };
        return `${name}`.toLowerCase().includes(value.toLowerCase());
      });
    }
    setCompanies(temporal);
  }, [allCompanies, value]);

  const handleChangeRoute = () => {
    push(ROUTES.CREATE_COMPANY);
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenu />
        <div className="dashboard__main">
          <Header title={t('leftMenuHeader.companiesList')} shadow />
          <div className="dashboard__content--patients">
            {!loading && isEmpty(allCompanies) && (
              <NoMembers
                title="¡Aún no tienes empresas configuradas!"
                subtitle="Inicia configurando una empresa para la valoración de tus pacientes."
                button="Crear Empresa"
                handleClick={handleChangeRoute}
                image={noTemplates}
              />
            )}
            {!loading && !isEmpty(allCompanies) && (
              <div className="forms">
                <div className="forms__search">
                  <SearchFilter searchItems={searchCompanies} placeholder={t('searchLabel.companies')} />
                  <SolidButton onClick={handleChangeRoute} className="slim">
                    {t('companies.createButton')}
                  </SolidButton>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '45%' }}>{t('tableHeader.name')}</th>
                      <th style={{ width: '45%' }}>{t('tableHeader.employees')}</th>
                      <td style={{ width: '10%' }}> </td>
                    </tr>
                  </thead>
                  <tbody>
                    <CompaniesList companies={companies} />
                    {isEmpty(companies) && <NoCompaniesFound />}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </section>
      <Loader className="spinner" type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
    </>
  );
};

export default Companies;

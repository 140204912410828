import React from 'react';
import videojs from 'video.js';

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    const { source } = { ...this.props };
    this.player = videojs(
      this.videoNode,
      {
        autoplay: false,
        controls: true,
        width: '100%',
        height: '100%',
        sources: [
          {
            src: source,
            type: `${source}`.endsWith('.mp4') ? 'video/mp4' : 'application/x-mpegURL',
          },
        ],
      },
      () => {}
    );
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    const { style = null } = { ...this.props };
    return (
      <div style={!!style ? style : { width: '100%', height: '25vh' }}>
        <div data-vjs-player style={{ width: '100%', height: '100%' }}>
          <video ref={(node) => (this.videoNode = node)} className={'video-js'} onContextMenu={(e) => e.preventDefault()}></video>
        </div>
      </div>
    );
  }
}

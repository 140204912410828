import React from 'react';
import unselectedPatient from '../../../assets/images/custom-plan.png';

const UnselectedPatient = (props) => {
  return (
    <section className={'unselected-patient'}>
    	<p>¡Aún no tienes un paciente seleccionado!</p>
    	<p>
    		Selecciona un paciente de la lista y podrás seguir la información asociada a su perfil, crear o modificar su plan de ejercicios.
    	</p>
    	<div className={'unselected-patient__image'}>
    		<img src={unselectedPatient} alt={'no patient selected'}/>
    	</div>
    </section>
  )
}

export default UnselectedPatient;
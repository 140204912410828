export const BULK_INVITE = 'Invitación masiva'; 
export const PERSONAL_INFORMATION = 'Información personal';
export const MEDICAL_ASSESMENT = 'Valoración';
export const CREATE_PLAN = 'Crear plan';
export const CREATE_CUSTOM_PLAN = 'Plan personalizado';
export const EDIT_PLAN = 'Editar plan';
export const CREATE_WEEK = 'Crear semana';
export const SEGMENTS = 'Segmentos';

export const CREATE_STEPS = [
  PERSONAL_INFORMATION,
  MEDICAL_ASSESMENT,
  CREATE_PLAN,
  CREATE_CUSTOM_PLAN,
  CREATE_WEEK,
  SEGMENTS,
  EDIT_PLAN,
  BULK_INVITE,
];

export const BookingStatus = {
  AWAITING: 'awaiting_clinic',
  CONFIRMED: 'confirmed',
  REJECTED: 'rejected',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import './completedDays.scss';

const CompletedDays = ({ completed }) => {
  const { completedDaysToday = {} } = { ...completed };
  const { total = '', with_feedback = '' } = { ...completedDaysToday };

  let withoutFeedback = 100 - with_feedback;
  const arr = [with_feedback, withoutFeedback];

  const data = {
    labels: ['% con feedback', '% sin feedback'],
    datasets: [
      {
        data: arr,
        backgroundColor: ['#46C2AD', '#BDBDBD'],
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    cutoutPercentage: 80,
    maintainAspectRatio: false,
  };

  return (
    <div className={'completed-days'}>
      <p className={'title'}>Sesiones completados</p>
      <p className={'description'}>Total sesiones completadas hoy: {total}</p>
      <p className={'feedback'}>Porcentaje de sesiones con feedback</p>
      <div className={'doughnut'}>
        <p className={`${(with_feedback > 99 && 'completed') || (with_feedback < 10 && 'no-completed')}`}>{with_feedback}%</p>
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
};

export default CompletedDays;

import { useState, useEffect } from 'react';
import { isEmpty } from 'ramda';

import { GET_AUDIO } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetAudio = (audioId = '') => {
  const {
    loading = false,
    data = {},
    refetch = () => {},
  } = audioId
    ? useAuthQuery(GET_AUDIO, {
        variables: { audioId },
      })
    : {};
  const { audio = {} } = { ...data };

  const [currentAudio, setCurrentAudio] = useState({});

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!isEmpty(audio) && isEmpty(currentAudio)) {
      setCurrentAudio(audio);
    }
  }, [audio, currentAudio]);

  return { loading, audio: currentAudio };
};

import React, { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import { useAuthQuery } from '../../../utils/hooks';
import { PLAN_BY_BODY_PART } from '../../../apollo/queries';
import { capitalize } from '../../../utils/string';
import Icon from '../../Icon';
import searchIcon from '../../../assets/images/search.svg';
import noPlans from '../../../assets/images/no-plans.svg';

const EMAIL_HEALTHPHY = 'info@healthphy.de';

const PlanList = ({ bodyPartId, setPlanId, showPlan, planId }) => {
  const { loading, error, data, refetch } = useAuthQuery(PLAN_BY_BODY_PART, { variables: { bodyPartId } });
  useEffect(() => {
    setPlanId('');
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyPartId]);

  const { planByBodyPart: plans = [] } = { ...data };

  const [query, setQuery] = useState('');

  const filteredPlans = plans.filter((plan) => {
    const { title } = { ...plan };
    return `${title}`.toLowerCase().includes(query.toLowerCase());
  });

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <>
      <div className={'search-box'}>
        <input
          type={'text'}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          value={query}
          placeholder={'Buscar un ejercicio'}
        />
        <img src={searchIcon} className={'icon'} alt={'search icon'} />
      </div>
      <div className={'segments__plan'}>
        {filteredPlans.map((plan) => {
          const { id, title, image, owner } = { ...plan };
          return (
            <div className={`segments__plan-item ${id === planId ? 'is-active' : ''}`} key={id} onClick={() => setPlanId(id)}>
              {owner !== EMAIL_HEALTHPHY ? (
                <Icon name='badge' className='left-menu__icon mr-3 plan-badge' />
              ) : (
                <div className='left-menu__icon mr-3'></div>
              )}
              <img src={image} className={'item-image'} alt={title} />
              <p className={'item-title'}>{capitalize(title)}</p>
              <div className='detail-button' onClick={showPlan(id)}>
                Ver
              </div>
            </div>
          );
        })}

        {isEmpty(filteredPlans) && (
          <div className={'no-results'}>
            <div className={'content'}>
              <p className={'title'}>No se han encontrado resultados</p>
              <p className={'subtitle'}>
                Asegúrate de que el criterio esté escrito correctamente o intenta ajustar el filtro para encontrar lo que estas
                buscando.
              </p>
            </div>
            <img src={noPlans} alt={'no results'} className={'image'} />
          </div>
        )}
      </div>
    </>
  );
};

export default PlanList;

import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty, pathOr } from 'ramda';

import { CREATE_UPDATE_SQUEEZE } from 'apollo/mutations';
import { ROUTES } from 'utils/constants';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';

export const useCreateUpdateSqueeze = (squeezeId) => {
  const { push } = useHistory();
  const [createUpdateSqueeze] = useMutation(CREATE_UPDATE_SQUEEZE);
  const [spinner, setSpinner] = useToggle();

  const handleCreateUpdateSqueeze = async (values) => {
    const { title, actions: content, jsonParse: file, audio, audioUs } = values;
    setSpinner();
    const response = await createUpdateSqueeze({
      variables: {
        title,
        content,
        ...(typeof audio === 'object' && {
          audio,
        }),
        ...(typeof audioUs === 'object' && {
          audioUs,
        }),
        ...(!!squeezeId && {
          squeezeId,
        }),
        ...(typeof file[0] === 'object' && {
          file: file[0],
        }),
      },
    });

    const errors = pathOr([], ['data', 'createUpdateSqueeze', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha ${squeezeId ? 'actualizado' : 'creado'} el squeeze exitosamente.`] : errors,
    });

    if (emptyErrors) {
      push(ROUTES.SQUEEZES);
    }
  };

  return { createUpdateSqueeze: handleCreateUpdateSqueeze, spinner };
};

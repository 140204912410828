import React from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { FaRegPlusSquare, FaRegTrashAlt } from 'react-icons/fa';

import { useCreateReadingContext } from 'components/readings/CreateReadingProvider';
import { bulletList } from 'utils/validationSchema';

import { TextInput } from 'components/FormComponents';
import SolidButton from 'components/SolidButton';
import { FormTitle } from './FormTitle';

export const BulletListForm = () => {
  const { t } = useTranslation();
  const { endEditing, saveBlock, builderState } = useCreateReadingContext();
  const { selectedBlock = {} } = builderState;
  const { en = [''], es = [''], bold = false } = selectedBlock;
  const initialValues = { es, en, bold };

  return (
    <>
      <FormTitle />
      <Formik initialValues={initialValues} onSubmit={saveBlock} validationSchema={bulletList}>
        {({ setFieldValue, values }) => {
          const { es: esOptions = [''], en: enOptions = [''] } = { ...values };
          return (
            <Form>
              <Field
                name="bold"
                render={({ field }) => (
                  <div>
                    <input
                      id="strong"
                      {...field}
                      type="checkbox"
                      checked={field.value}
                      onChange={() => {
                        setFieldValue('bold', !field.value);
                      }}
                    />
                    <label htmlFor="strong" className="builder__blockOptions_checkbox-label">
                      {t('formLabel.bold')}
                    </label>
                  </div>
                )}
              />
              <FieldArray
                name="es"
                render={(arrayHelpers) => (
                  <div className="bulletList">
                    <div className="bulletList__content content-title">
                      <h3 className="bulletList__content_title">{t('formLabel.esOptionsLabel')}</h3>
                      <FaRegPlusSquare
                        className="bulletList__content_plus-icon"
                        onClick={() => arrayHelpers.push('')}
                      />
                    </div>

                    {esOptions.map((_, index) => (
                      <div key={index} className="bulletList__content">
                        <Field type="text" name={`es[${index}]`} component={TextInput} />
                        {index > 0 && (
                          <FaRegTrashAlt
                            className="bulletList__content_trash-icon"
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              />
              <FieldArray
                name="en"
                render={(arrayHelpers) => (
                  <div className="bulletList">
                    <div className="bulletList__content content-title">
                      <h3 className="bulletList__content_title">{t('formLabel.enOptionsLabel')}</h3>
                      <FaRegPlusSquare
                        className="bulletList__content_plus-icon"
                        onClick={() => arrayHelpers.push('')}
                      />
                    </div>

                    {enOptions.map((_, index) => (
                      <div key={index} className="bulletList__content">
                        <Field type="text" name={`en[${index}]`} component={TextInput} />
                        {index > 0 && (
                          <FaRegTrashAlt
                            className="bulletList__content_trash-icon"
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              />
              <div className="d-flex mt-4 modal__buttons right_buttons">
                <SolidButton className="secondary mr-2" onClick={endEditing}>
                  {t('button.cancel')}
                </SolidButton>
                <SolidButton type="submit" className="">
                  {t('button.save')}
                </SolidButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

import React, { useEffect } from 'react';
import './evie-checkout.scss';
import FacebookPixel from 'components/FacebookPixel';
import { useLocation } from 'react-router-dom';
import paymentSuccess from '../../assets/images/evie-payment-success.svg';

const translations = {
  es: {
    paymentSuccessTitle: 'Subscripción exitosa',
    paymentSuccessMsg: 'Gracias por tu subscripción. Se enviará un correo electrónico automatizado a tu dirección de correo registrada con tus credenciales de acceso a Evie.',
    paymentSuccessButton: 'Ir al Inicio',
    title: "Evie - Pago Exitoso",
  }, en: {
    paymentSuccessTitle: 'Your Subscription is Successfully',
    paymentSuccessMsg: 'Thank you for your subscription. An automated email will be sent to your registered email with your Evie access credentials.',
    paymentSuccessButton: 'Go to Home',
    title: "Evie - Checkout Success",
  }
}
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const EvieCheckoutSuccess = () => {
  const params = useQuery();
  const lang = params.get("lang") || 'es';
  const t = translations[lang] || translations.es;

  useEffect(() => {
    document.title = t.title;
    const link = document.querySelector('link[rel="icon"]');
    link.setAttribute('href', 'evie-favicon.png');
  }, []);

  return (
    <>
      <FacebookPixel pixelId={"570488783823891"}/>
      <div className={'success-modal-overlay'}>
        <div className={'success-modal-container'}>
          <img className={'success-modal-image'} src={paymentSuccess} alt="success" />
          <p className={'succes-modal-title'}>{t.paymentSuccessTitle}</p>
          <p className={'succes-modal-message'}>{t.paymentSuccessMsg}</p>
          <button className={'evie-button small'} onClick={() => {
            window.location.replace('https://www.hievie.com')
          }}>
            {t.paymentSuccessButton}
          </button>
        </div>
      </div>
    </>
  )
}

export default EvieCheckoutSuccess;

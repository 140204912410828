import React, { useState } from 'react';
import Booking from './Booking';

import './bookings.scss'

const badgeCount = (arr) => {
  const length = [...arr].length;
  return length > 9 ? '9+' : `${length}`;
};

export default (props) => {
  const { bookings = {}, changeRoute = () => {} } = { ...props };
  const { awaitingResponseBookings: pending = [], upcommingBookings: incomming = [] } = { ...bookings };

  const [tab, setTab] = useState(0);

  const tabs = ['Llamadas', 'Solicitudes'];

  return (
    <div className={'bookings-container'}>
      <div className={'panel'}>
      {tabs.map((item, index) => {
        return (
          <div key={index} className={`panel-head panel-head--${index} ${index === tab && 'active'}`} onClick={() => setTab(index)}>
            <p className={`panel-title ${index === tab && 'panel-title-active'}`}>{item}</p>
            <p className={`panel-badge ${index === tab && 'panel-badge-active'}`}>{index === 0 ? badgeCount(incomming) : badgeCount(pending)}</p> 
          </div>
        )
      })}
      </div>
      <div className={'bookings'}>
        {tab === 0 && <Booking bookingState={incomming} changeRoute={changeRoute} showClock showEmptyState/>}
        {tab === 1 && <Booking bookingState={pending} changeRoute={changeRoute}/>}
      </div>
    </div>
  );
};

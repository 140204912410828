import React from 'react';
import Slider from 'rc-slider';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import Button from 'components/Button';
import { PerksList } from 'components/Billing/PaymentPlan/PerksList';

import { ROLES } from 'utils/constants';
import { currencyFormat } from 'utils/string';
import paymentInit from 'assets/images/payment-init.svg';
import { getPricing, getValues, getPercentage } from './payment-plan-utils';

import './payment-plan.scss';
import 'rc-slider/assets/index.css';

const { Handle } = Slider;

const PaymentPlan = (props) => {
  const { patients, setPatients, onSelectPlan, currency, userRole } = { ...props };
  const isClinic = userRole === ROLES.CLINIC;
  const partnerPrice = getPricing({ userRole, currency });
  const { t } = useTranslation();

  const PlanItem = (itemProps) => {
    const { name = '', discount = 0, suggested = false, period = 3 } = { ...itemProps };
    const { totalPrice, savings } = getValues({ patients, partnerPrice, period, discount, isClinic });

    return (
      <div className="option__container">
        {suggested ? (
          <div className="suggested__tag">
            <p>Recomendado</p>
          </div>
        ) : (
          <div className="spacer" />
        )}
        <li className={`option__item ${suggested ? 'suggested' : ''}`}>
          <div className="plan__name">
            <p>
              {isClinic && t('billing.planCard.subscription')} {name}
            </p>
          </div>
          <div className="plan__info">
            <ul className="details__list">
              <li className="details__list__item">
                <Icon name="checked" className="icon" />
                <p>
                  <b>
                    {t('billing.planCard.discount')} {isClinic ? discount : getPercentage(patients)}%
                  </b>
                </p>
              </li>
              <li className="details__list__item">
                <Icon name="checked" className="icon" />
                <p>
                  {t('billing.planCard.save')}{' '}
                  <b>
                    {currencyFormat(savings)} {currency}
                  </b>
                </p>
              </li>
            </ul>
          </div>
          <div className="plan__price">
            <p>
              {currencyFormat(totalPrice)} {currency}
            </p>
            <Button
              type="button"
              category={suggested ? 'rounded' : 'secondary-rounded'}
              className="mr-3"
              onClick={() => onSelectPlan({ ...itemProps, totalPrice, partnerPrice, currency })}
            >
              {t('button.buy')}
            </Button>
          </div>
        </li>
      </div>
    );
  };
  const handle = (handleProps) => <Handle {...handleProps} />;

  return (
    <div className="billing__main flex-fill">
      <div className="billing__header">
        <p className="title">{isClinic ? t('billing.clinicTitle') : t('billing.companyTitle')}</p>
      </div>
      <div className="billing__content">
        <div className="billing__content__intro">
          <p>{isClinic ? t('billing.clinicDescription') : t('billing.companyDescription')}</p>
          <img src={paymentInit} alt="start payment" className="image" />
        </div>
        <div className="billing__content__plans">
          <div className="billing__content__plans__body">
            <div className="billing__content__features">
              <div className="patients__slider">
                <p className="label">
                  {isClinic ? t('billing.clinicChoose') : t('billing.companyChoose')} <strong>{patients}</strong>
                </p>
                <Slider
                  min={1}
                  max={isClinic ? 100 : 1000}
                  defaultValue={patients}
                  handle={handle}
                  onChange={(value) => setPatients(value)}
                />
                <p className="unit_price">
                  {currencyFormat(partnerPrice)} {currency} /{' '}
                  {isClinic ? t('billing.users.patient') : t('billing.users.employee')}{' '}
                  {t('billing.timePeriod.monthly')}
                </p>
                {isClinic && (
                  <p className="big_group">
                    * ¿Tienes más de 50 pacientes? Contáctanos y crearemos la mejor solución que se adapte al tamaño,
                    presupuesto y necesidad de tu centro.
                  </p>
                )}
              </div>
              <PerksList isClinic={isClinic} />
            </div>
            <ul className="billing__content__options">
              {isClinic ? (
                <>
                  <PlanItem name="Anual" discount={40} period={12} />
                  <PlanItem name="Semestral" discount={20} suggested period={6} />
                  <PlanItem name="Trimestral" discount={10} period={3} />
                  <PlanItem name="Mensual" discount={0} period={1} />
                </>
              ) : (
                <PlanItem name={t('billing.planCard.name.yearly')} period={12} />
              )}
            </ul>
          </div>
          <div className="billing__content__plans__footer">
            {/* <div>
              <Icon name='lock' className={'icon'} />
              <p>Garantía de devolución de dinero si no obtiene lo resultados que busca.</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPlan;

import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { isEmpty, pathOr } from 'ramda';
import { TiUserDelete } from 'react-icons/ti';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UNACTIVE_PATIENT_PLAN, DELETE_CLINIC_PLAN } from 'apollo/mutations';
import showNotification from 'utils/showNotification';
import { capitalize } from 'utils/string';
import { ROUTES } from 'utils/constants';
import { useToggle } from 'utils/hooks/useToggle';
import { getUserRole } from 'utils/getUserRole';

import AlertModal from 'components/AlertModal';
import SolidButton from 'components/SolidButton';

import './plan-card.scss';

const PlanCard = ({ plan = {}, onClick, status, planId, refetch, isInpatient, isEditable }) => {
  const {
    title,
    description,
    image,
    bodyParts = [],
    numberOfDays = 0,
    numberOfExercises = 0,
    numberOfWeeks = 0,
    sharedTemplate,
  } = {
    ...plan,
  };

  const [part = {}] = [...(bodyParts || [])];
  const { image: partImg = '' } = { ...part };
  const isUnactive = status === 'unactive';
  const { isSuperClinic } = getUserRole();
  const { push } = useHistory();
  const { t } = useTranslation();

  const [alertModal, setAlertModal] = useToggle();
  const [unactivePatientPlan] = useMutation(UNACTIVE_PATIENT_PLAN);
  const [deletePlanTemplate] = useMutation(DELETE_CLINIC_PLAN);

  const handleUnactivePlan = async (event) => {
    event.stopPropagation();
    const response = await unactivePatientPlan({
      variables: {
        patientPlanId: planId,
      },
    });
    const errors = pathOr([], ['data', 'unactivePatientPlan', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? ['The plan has been successfully unactivated'] : errors,
    });
    if (emptyErrors) {
      refetch();
    }
  };

  const handleDeletePlan = async (event) => {
    event.stopPropagation();
    const response = await deletePlanTemplate({
      variables: {
        planId,
      },
    });
    const errors = pathOr([], ['data', 'deletePlanTemplate', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? ['The plan has been successfully deleted'] : errors,
    });
    if (emptyErrors) {
      refetch();
      setAlertModal();
    }
  };

  const handleEditPlan = () => {
    if (isUnactive) return;
    onClick();
  };

  const handleOpenAlertModal = (event) => {
    event.stopPropagation();
    setAlertModal();
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    setAlertModal();
  };

  const handleDuplicatePlan = (event) => {
    event.stopPropagation();
    push(`${ROUTES.CREATE_PLAN}?planId=${planId}`);
  };

  const modalData = {
    open: alertModal,
    onCancelAction: handleCancel,
    onConfirmAction: handleDeletePlan,
    title: t('deletePlan.title'),
    description: t('deletePlan.description'),
    labelConfirm: t('button.delete'),
    category: 'remove',
  };

  return (
    <>
      <div className={`${isUnactive ? 'unactive-plan-card' : ''} plan-card`} onClick={handleEditPlan}>
        <ReactTooltip place="top" effect="solid" id="unactive-plan" />
        <div className="plan-image-container">
          <img src={partImg || image} alt="" className="plan-image" />
        </div>
        <div className="plan-info">
          {isInpatient && !isUnactive && (
            <TiUserDelete
              className="unactive-plan-icon"
              onClick={handleUnactivePlan}
              data-tip="Unactive Plan"
              data-for="unactive-plan"
            />
          )}
          <p className="plan-title">{capitalize(title)}</p>
          <p className="plan-description">{description}</p>
          <div className="plan-tags">
            {bodyParts.map((el) => (
              <span key={el.id}>{el.title}</span>
            ))}
          </div>
          {isEditable && (isSuperClinic || !sharedTemplate) && (
            <div className="plan-info__actions">
              <SolidButton className="slim secondary mr-2 min-width-100 " onClick={handleOpenAlertModal}>
                {t('button.delete')}
              </SolidButton>
              <SolidButton className="slim min-width-100 " onClick={handleDuplicatePlan}>
                {t('button.duplicate')}
              </SolidButton>
            </div>
          )}
        </div>
        <div className="plan-stats">
          <div className="plan-stat-item">
            <p className="plan-stat-value">{numberOfExercises}</p>
            <p className="plan-stat-label">Ejercicios</p>
          </div>
          <div className="plan-stat-item">
            <p className="plan-stat-value">{numberOfWeeks}</p>
            <p className="plan-stat-label">Semanas</p>
          </div>
          <div className="plan-stat-item">
            <p className="plan-stat-value">{numberOfDays}</p>
            <p className="plan-stat-label">Días</p>
          </div>
        </div>
      </div>
      <AlertModal {...modalData} />
    </>
  );
};

PlanCard.defaultProps = {
  onClick: () => {},
  refetch: () => {},
  status: 'active',
  planId: '',
  isInpatient: false,
  isEditable: false,
};

PlanCard.propTypes = {
  plan: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  refetch: PropTypes.func,
  status: PropTypes.string,
  planId: PropTypes.string,
  isInpatient: PropTypes.bool,
  isEditable: PropTypes.bool,
};

export default PlanCard;

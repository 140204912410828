import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import Summary from 'components/Billing/Summary';
import PaymentStep from 'components/Billing/PaymentStep';

import creditCards from 'assets/images/credit-cards.svg';
// import nequi from 'assets/images/nequi.svg';
import stripe from 'assets/images/stripe-2.svg';

import './payment-method.scss';

const PaymentMethod = (props) => {
  const { plan, onBack, method = 0, setMethod = () => {}, onNext = () => {} } = { ...props };
  // const { currency = 'USD' } = { ...plan };
  const { t } = useTranslation();

  return (
    <div className="billing__main flex-fill">
      <PaymentStep step={0} />
      <div className="billing__content">
        <div className="billing__content__title">
          <div className="back">
            <Icon name="back" className="icon" onClick={onBack} />
          </div>
          <p className="title">{t('billing.paymentMethod.choose')}</p>
        </div>
        <div className="billing__content__layout">
          <div className="billing__content__methods">
            <div className={`method__card ${method === 0 ? 'selected' : ''}`} onClick={() => setMethod(0)}>
              <div className="method__card__body">
                <img src={creditCards} alt="credit cards" style={{ width: '80%' }} />
              </div>
              <p className={`method__card__footer ${method === 0 ? 'selected' : ''}`}>
                {t('billing.paymentMethod.useCards')}
              </p>
            </div>
            {/* <div
              className={`method__card ${method === 1 && 'selected'} ${(currency === 'USD' || true) && 'disabled'}`}
              onClick={currency !== 'USD' ? () => setMethod(1) : null}>
              <div className={'method__card__body'}>
                <img src={nequi} alt='nequi' style={{ width: '65%' }} />
              </div>
              <p className={`method__card__footer ${method === 1 && 'selected'}`}>Usa tu cuenta Nequi</p>
            </div> */}
          </div>
          <Summary plan={plan} useWompi={false} onNext={onNext} />
        </div>
        <div className="billing__content__pay__footer">
          <img src={stripe} alt="stripe certified" />
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;

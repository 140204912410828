import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import Icon from 'components/Icon';
import FollowUpInfo from '../FollowUpInfo';

const modalProps = {
  content: {
    overflowY: 'auto',
    width: '95%',
    maxWidth: 768,
    maxHeight: '95vh',
    padding: 40,
  },
};

const FullHistoryModal = ({ open, onCancelAction, followUps }) => (
  <Modal modalProps={modalProps} modalIsOpen={open} closeModal={onCancelAction} closeable={false}>
    <div className="patient__modal">
      <div className="close-icon-container">
        <Icon name="close" className="close-icon" onClick={onCancelAction} />
      </div>
      <ul className="patient__modal_followups">
        {followUps.map((followUp) => (
          <li key={followUp.id}>
            <FollowUpInfo data={followUp} />
          </li>
        ))}
      </ul>
    </div>
  </Modal>
);

FullHistoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancelAction: PropTypes.func.isRequired,
  followUps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FullHistoryModal;

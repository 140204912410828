import { useEffect } from 'react';
import { GET_CLINIC_READINGS } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetReadings = () => {
  const { loading, data, refetch } = useAuthQuery(GET_CLINIC_READINGS);
  const { getClinicReadings: allReadings = [] } = { ...data };

  useEffect(() => {
    refetch();
  }, []);

  return { loading, allReadings };
};

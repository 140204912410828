import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import ExercisesList from 'components/createActivePause/ExercisesList';
import DetailForm from 'components/createActivePause/DetailForm';
import {
  CreateActivePauseProvider,
  useCreateActivePauseContext,
} from 'components/createActivePause/CreateActivePauseProvider';

import './create-active-pause.scss';

const CreateActivePauseWrapper = () => (
  <CreateActivePauseProvider>
    <CreateActivePause />
  </CreateActivePauseProvider>
);

const CreateActivePause = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();

  const { isLoading, activePauseId } = useCreateActivePauseContext();

  return (
    <section className="d-flex">
      <LeftMenu />
      <div className="dashboard__main">
        <Header
          title={activePauseId ? t('leftMenuHeader.editActivePause') : t('leftMenuHeader.createActivePause')}
          shadow
        />
        <div className="active-pause">
          <ExercisesList />
          <DetailForm />
        </div>
      </div>
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={isLoading} />
    </section>
  );
};

export default CreateActivePauseWrapper;

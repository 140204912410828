import React, { useEffect } from 'react';
import { path } from 'ramda';
import moment from 'moment';
import 'moment/locale/es';
import LeftMenu from '../../components/LeftMenu';
import Header from '../../components/Header';
import Bookings from '../../components/Bookings';
import CompletedDays from '../../components/CompletedDays';
import SatisfactionLevel from '../../components/SatisfactionLevel';
import PatientStatus from '../../components/PatientStatus';
// import Button from '../../components/SolidButton';
import Loader from 'react-loader-spinner';
import { GET_BOOKINGS } from '../../apollo/queries';
import { useAuthQuery } from '../../utils/hooks';
import { useRedirectByRole } from '../../utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import { FaExclamationCircle } from 'react-icons/fa';
import './dashboard.scss';

moment.locale = 'es';
const Dashboard = (props) => {
  useRedirectByRole([ROLES.CLINIC]);
  const changeRoute = path(['history', 'push'])(props);
  const { loading, data, refetch } = useAuthQuery(GET_BOOKINGS);
  const { clinicDashboard: bookings = {} } = { ...data };
  const { patientStatus = {} } = { ...bookings };
  const { current_active = 0, current_available = 0 } = { ...patientStatus };
  const { location = {} } = { ...props };
  const { key = '' } = { ...location };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return (
    <>
      <div className="d-flex hvh-100" style={{ height: '100%' }}>
        <LeftMenu history={props.history} />
        <div className="dashboard__main flex-fill" style={{ maxHeight: '100vh', overflow: 'scroll' }}>
          <Header title="Inicio" shadow={true} />
          {current_active >= current_available && (
            <>
              <br />
              <div className={'patients-banner'}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
                  <FaExclamationCircle />
                  <p style={{ marginBottom: '0px', marginLeft: '10px' }}>
                    Llegaste al límite de pacientes activos, no puedes crear mas pacientes, actualiza tu plan o
                    contactanos.
                  </p>
                </div>
                {/*<Button className={'secondary'} onClick={() => changeRoute('/billing')}>
                  Actualiza tu plan
                </Button>*/}
              </div>
            </>
          )}
          <br />
          <div className={'data-container'}>
            <Bookings bookings={bookings} changeRoute={changeRoute} />
            <CompletedDays completed={bookings} />
            <PatientStatus />
            <SatisfactionLevel satisfactionLevel={bookings} />
          </div>
        </div>
        <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={loading} />
      </div>
    </>
  );
};

export default Dashboard;

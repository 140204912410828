import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { pathOr, isEmpty, propOr } from 'ramda';
import { useMutation } from '@apollo/react-hooks';
import Loader from 'react-loader-spinner';
import { ROUTES } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useAuthQuery } from 'utils/hooks';
import showNotification from 'utils/showNotification';
import { useSignUpSteps } from 'utils/hooks/useSignUpSteps';
import { useSetLanguage } from 'utils/hooks/useSetLanguage';
import { getUserRole } from 'utils/getUserRole';
import { CREATE_UPDATE_CLINIC_PROFILE, UPDATE_PASSWORD, ACCEPT_TERMS, UPDATE_SPEND_LIMIT, UPDATE_PAYMENT_MODE } from 'apollo/mutations';
import { FETCH_BY_ROLE, GET_PARTNER_BY_ROLE } from 'utils/constants';

import Modal from 'components/Modal';
import PasswordModal from 'components/Profile/PasswordModal';
import Terms from './Terms';
import License from './License';
import Intro from './Intro';
import ClinicProfile from './ClinicProfile';
import CompanyProfile from './CompanyProfile';
import CompanyPayment from './CompanyPayment';

import './signup-modal.scss';

const modalProps = {
  content: {
    width: '95vw',
    maxWidth: '940px',
    maxHeight: '98vh',
    padding: '0',
  },
};

const SignUpPartnerModal = ({ open, onCloseModal }) => {
  const { setLanguage } = useSetLanguage();
  const { userRole, isClinic, isCompany } = getUserRole();

  const { data } = useAuthQuery(FETCH_BY_ROLE[userRole]);
  const partner = propOr({}, GET_PARTNER_BY_ROLE[userRole])(data);
  const { termsConditions = false, profile = {} } = { ...partner };
  const { id = '' } = { ...profile };

  const { step, handleStep, STEPS } = useSignUpSteps();
  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [currency, setCurrency] = useState();
  const { push } = useHistory();
  const [acceptTerms] = useMutation(ACCEPT_TERMS);
  const [createUpdateCompanyProfile] = useMutation(CREATE_UPDATE_CLINIC_PROFILE);
  const [updatePaymentMode] = useMutation(UPDATE_PAYMENT_MODE);
  const [updateSpendLimit] = useMutation(UPDATE_SPEND_LIMIT);
  const [updatePassword] = useMutation(UPDATE_PASSWORD);
  const { t } = useTranslation();

  useEffect(() => {
    if (termsConditions || !isClinic) {
      handleStep(STEPS.INTRO);
    }
  }, [termsConditions, isClinic]);

  const togglePassword = () => {
    setPasswordModalIsOpen((prevState) => !prevState);
  };

  const onSaveProfile = async (values) => {
    setSpinner(true);
    const { file: avatar, countryName: country, doctorsNumber, ...rest } = { ...values };
    const response = await createUpdateCompanyProfile({
      variables: {
        profile: {
          ...rest,
          email: undefined,
          name: undefined,
          country,
          ...(doctorsNumber && {
            doctorsNumber: Number(doctorsNumber),
          }),
        },
        avatar,
      },
    });
    const errors = pathOr([], ['data', 'createUpdateClinicProfile', 'errors'])(response);
    const emptyErrors = isEmpty(errors);
    console.log()
    setSpinner(false);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha actualizado el perfil exitosamente.`] : errors,
    });
    if (emptyErrors) {
      setLanguage(i18next.resolvedLanguage);
      if (isCompany) {
        setCurrency(response?.data?.createUpdateClinicProfile?.clinic?.profile?.currency);
        handleStep(STEPS.COMPANY_PAYMENT);
      } else {
        onCloseModal();
        togglePassword();
      }
    }
  };

  const onSavePayment = async (values) => {

    setSpinner(true);
    const dataPaymentMode =
    values.paymentMode === t("settings.payment.ondemand")
      ? "ondemand"
      : "reimbursement";
    const dataCadence = data.cadence === t('settings.payment.yearly')? 'yearly': data.cadence === t('settings.payment.quarterly')? 'quarterly':'bi_yearly';
    await updatePaymentMode({
      variables: {
        paymentMode: dataPaymentMode,
      },
    });
    await updateSpendLimit({
      variables: {
        allowanceAmount: Number(values.allowanceAmount),
        cadence: dataCadence,
      },
    });
    setSpinner(false);
    onCloseModal();
    setTimeout(()=>{
      push(`${ROUTES.CONFIGURATION}#payment`)
    },500)
  }

  const onAcceptTerms = async () => {
    setSpinner(true);
    const response = await acceptTerms({
      variables: {},
    });
    const errors = pathOr([], ['data', 'acceptTermsAndConditions', 'errors'])(response);
    const emptyErrors = isEmpty(errors);
    setSpinner(false);
    if (isClinic) {
      showNotification({
        type: emptyErrors ? 'success' : 'error',
        messages: emptyErrors ? [`Se han aceptado los términos & condiciones exitosamente.`] : errors,
      });
    }

    if (emptyErrors) {
      if (id) {
        onCloseModal();
      } else if (isClinic) {
        handleStep(STEPS.INTRO);
      }
    }
  };

  const onSavePassword = async (values, callback) => {
    const { password } = { ...values };
    setSpinner(true);
    const response = await updatePassword({
      variables: {
        password,
      },
    });
    const errors = pathOr([], ['data', 'updateUserPassword', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha actualizado la contraseña exitosamente.`] : errors,
    });

    if (emptyErrors) {
      callback();
    }
  };

  const handleTermsFromCompanies = () => {
    if (!isClinic && !termsConditions) onAcceptTerms();
    handleStep(STEPS.PROFILE);
  };

  return (
    <>
      <Modal modalIsOpen={open} modalProps={modalProps} closeable={false} closeModal={() => {}}>
        <>
          <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={spinner} />
          {step === STEPS.LICENSE && isClinic && <License onContinue={() => handleStep(STEPS.TERMS)} />}
          {step === STEPS.TERMS && isClinic && <Terms onContinue={onAcceptTerms} />}
          {step === STEPS.INTRO && <Intro onContinue={handleTermsFromCompanies} />}
          {step === STEPS.PROFILE && (
            <>
              {isClinic && (
                <ClinicProfile partner={partner} onContinue={onSaveProfile} onBack={() => handleStep(STEPS.INTRO)} />
              )}
              {isCompany && (
                <CompanyProfile partner={partner} onContinue={onSaveProfile} onBack={() => handleStep(STEPS.INTRO)} />
              )}
            </>
          )}
          {step === STEPS.COMPANY_PAYMENT && isCompany && (
            <CompanyPayment partner={partner} onContinue={onSavePayment} onBack={onCloseModal} currency={currency} />
          )}
        </>
      </Modal>
      <PasswordModal open={passwordModalIsOpen} onCloseModal={togglePassword} onSave={onSavePassword} />
    </>
  );
};

SignUpPartnerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default SignUpPartnerModal;

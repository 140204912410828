import React from 'react';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { propOr } from 'ramda';
import { getIn } from 'formik';
import { capitalize } from '../../utils/string';
import myData from '../../assets/data.json';

import './react-autosuggest.scss';

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestionValue(suggestion) {
  return `${suggestion.code} ${suggestion.description}`;
}

function getSuggestions(value) {
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp(`\\b${escapedValue}`, 'i');

  return myData['CIE-10'].filter((description) => regex.test(getSuggestionValue(description)));
}

function renderSuggestion(suggestion, { query }) {
  const suggestionText = capitalize(suggestion.description);
  const matches = AutosuggestHighlightMatch(suggestionText, query);
  const parts = AutosuggestHighlightParse(suggestionText, matches);

  return (
    <span className="suggestion-content">
      <span>
        {
          parts.map((part, index) => {
            const className = part.highlight ? 'font-weight-bold' : null;
            return (
              <span className={className} key={index}>{part.text}</span>
            );
          })
        }
      </span>
    </span>
  );
}

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    const { field: { value } } = props;

    this.state = {
      value,
      suggestions: [],
    };
  }

  onChange = (_, { newValue }) => {
    this.setState({
      value: newValue,
    });
    const { form, field } = this.props;
    if (!newValue) form.setFieldValue(field.name, '');
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { form, field } = this.props;
    form.setFieldValue(field.name, `${suggestion.description} - ${suggestion.code}`);
  }

  shouldRenderSuggestions(value) {
    return value.trim().length > 2;
  }
  

  render() {
    const { value, suggestions } = this.state;
    const {
      placeholder,
      className,
      type,
      label,
      field: { name },
      labelClassName,
      isRequired,
      disabled,
      form,
    } = this.props;

    const touched = propOr({}, 'touched')(form);
    const errors = propOr({}, 'errors')(form);
    const error = getIn(errors, name);
    const hasError = getIn(touched, name) && error;
    const invalidClass = hasError ? 'is-invalid' : '';

    const inputProps = {
      placeholder,
      className,
      value,
      type,
      id: name,
      name,
      disabled,
      onChange: this.onChange,
    };
  
    const theme = {
      container: `react-autosuggest__container ${invalidClass}`,
      containerOpen: 'react-autosuggest__container--open',
      input: 'form__input',
      inputOpen: 'react-autosuggest__input--open',
      inputFocused: 'react-autosuggest__input--focused',
      suggestionsContainer: 'react-autosuggest__suggestions-container',
      suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
      suggestionsList: 'react-autosuggest__suggestions-list',
      suggestion: 'react-autosuggest__suggestion',
      suggestionFirst: 'react-autosuggest__suggestion--first',
      suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
      sectionContainer: 'react-autosuggest__section-container',
      sectionContainerFirst: 'react-autosuggest__section-container--first',
      sectionTitle: 'react-autosuggest__section-title',
    };


    return (
      <div className="form__input-container">
        {label && (
          <label htmlFor={name} className={`form__label ${labelClassName}`}>
            {label}
            {' '}
            {isRequired && <span className="text-pink">*</span>}
          </label>
        )}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          theme={theme}
        />
        {hasError && <span className='form__error form__error--select'>{error}</span>}
      </div>
    );
  }
}


export default SearchInput;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SolidButton from 'components/SolidButton';
import Powered from 'components/Surveys/Powered';

import doctors from 'assets/images/doctors.svg';

const Welcome = ({ handleSetStep, title }) => {
  const { t } = useTranslation();
  return (
    <section className="welcome-survey">
      <div className="welcome-survey__image">
        <img className="image" src={doctors} alt="doctors" />
      </div>
      <div className="welcome-survey__title">
        <h2>{title}</h2>
        <p>{t('surveys.welcome.message')}</p>
      </div>
      <div className="welcome-survey__footer">
        <SolidButton onClick={handleSetStep} className="survey-button">
          {t('surveys.welcome.button')}
        </SolidButton>
        <Powered />
      </div>
    </section>
  );
};

Welcome.propTypes = {
  handleSetStep: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Welcome;

import React from 'react';
import PropTypes from 'prop-types';

const OndemandMode = ({ table }) => <>{table}</>;

OndemandMode.propTypes = {
  table: PropTypes.node.isRequired,
};

export default OndemandMode;

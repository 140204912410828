import React from 'react';
import { useTranslation } from 'react-i18next';
import { path, pathOr, isEmpty } from 'ramda';
import { Formik, Field, Form } from 'formik';
import Loader from 'react-loader-spinner';
import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';

import { useMutation } from '@apollo/react-hooks';
import { EMPLOYEE_SIGNUP } from 'apollo/mutations';
import { useToggle } from 'utils/hooks/useToggle';
import { useGetCountries } from 'utils/hooks/useGetCountries';
import showNotification from 'utils/showNotification';
import { signUpForm } from 'utils/validationSchema';

import SolidButton from 'components/SolidButton';
import { TextInput, MultiSelect, NumberInput } from 'components/FormComponents';

import logoHealthphy from 'assets/images/logo-blue.svg';

import './new-employee.scss';

const initialValues = {
  name: '',
  country: '',
  phone: '',
  email: '',
};
const languages = {
  es: 'es',
  en: 'en',
};

const NewEmployee = (props) => {
  const { resolvedLanguage } = i18next.use(detector);
  const isNotSpanish = resolvedLanguage !== languages.es;
  const { dialingCodeList } = useGetCountries();
  const { t } = useTranslation();
  const companyToken = path(['match', 'params', 'companyToken'])(props);

  const [idSent, setIdSent] = useToggle();
  const [showSpinner, setSpinner] = useToggle();
  const [employeeSignup] = useMutation(EMPLOYEE_SIGNUP);

  const handleSubmitNewEmployee = async ({ name, email, country, phone }) => {
    setSpinner();
    const response = await employeeSignup({
      variables: {
        name,
        email: email.toLowerCase(),
        companyToken,
        language: isNotSpanish ? languages.en : resolvedLanguage,
        ...(phone && {
          phone: `${country} - ${phone}`,
        }),
      },
    });

    const errors = pathOr([], ['data', 'employeeSignup', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();
    if (!emptyErrors) showNotification({ type: 'error', messages: errors });

    if (emptyErrors) setIdSent();
  };

  if (!companyToken) return null;

  return (
    <div className="new-employee-container">
      <div className="new-employee">
        <header className="new-employee__logo">
          <img src={logoHealthphy} alt="HealthPhy Logo" />
        </header>
        {!idSent && (
          <section className="new-employee__form">
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmitNewEmployee}
              validationSchema={signUpForm}
              enableReinitialize
            >
              {({ handleSubmit, isSubmitting, isValid }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <Field label={t('formLabel.name')} name="name" component={TextInput} />
                  </div>
                  <div className="new-employee__form_phone">
                    <Field
                      label={t('formLabel.phone')}
                      type="text"
                      name="country"
                      component={MultiSelect}
                      options={dialingCodeList}
                    />
                    <div className="new-employee__form_phone--number">
                      <Field type="text" name="phone" component={NumberInput} />
                    </div>
                  </div>
                  <Field label={t('formLabel.workEmail')} name="email" component={TextInput} />
                  <SolidButton
                    className={`new-employee__form__button ${(!isValid || isSubmitting) && 'disabled'}`}
                    disabled={!isValid || isSubmitting}
                    type="submit"
                  >
                    {t('button.send')}
                  </SolidButton>
                </Form>
              )}
            </Formik>
          </section>
        )}
        {idSent && (
          <div className={`new-employee__form__succes ${idSent ? 'sent' : ''}`}>
            <p>{t('createEmployee.success')}</p>
          </div>
        )}
      </div>
      <footer className="new-employee__footer">©{new Date().getFullYear()} - HealthPhy</footer>
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={showSpinner} />
    </div>
  );
};

export default NewEmployee;

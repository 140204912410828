import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateReadingContext } from '../CreateReadingProvider';
import { useFormatBlockTypes } from '../service/useFormatBlockTypes';

export const FormTitle = () => {
  const { t } = useTranslation();
  const { builderState } = useCreateReadingContext();
  const { blockType } = { ...builderState };
  const { blockTypes } = useFormatBlockTypes();

  const getBlockLabel = blockTypes.find((bType) => bType.type === blockType);

  return (
    <>
      <h3 className="builder__blockOptions_form-title">{t('readings.editor.configLabel')}</h3>
      <p className="builder__blockOptions_form-type">
        <strong>{t('formLabel.type')}: </strong>
        {getBlockLabel.title}
      </p>
    </>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { getUserRole } from 'utils/isClinic';
import { capitalize, currencyFormat } from 'utils/string';
import { ROLES } from 'utils/constants';
import { getPercentage } from 'components/Billing/PaymentPlan/payment-plan-utils';
import { getGranTotal } from 'components/Billing/utils';

import Icon from 'components/Icon';
import Button from 'components/Button';
import PaymentStep from 'components/Billing/PaymentStep';

import stripe from 'assets/images/stripe-2.svg';
import visa from 'assets/images/visa.svg';
import mastercard from 'assets/images/mastercard.svg';
import american from 'assets/images/american.svg';
import unknown from 'assets/images/unknown.svg';
import nequi from 'assets/images/nequi.svg';
import './payment-summary.scss';

const PaymentSummary = (props) => {
  const {
    plan,
    onBack,
    billingData = {},
    onNext = () => {},
    method = 0,
    onPlan = () => {},
    onMethod = () => {},
  } = { ...props };
  const { name = '', patients = 0, discount = 0, totalPrice = 0, currency = 'USD', couponDiscount } = { ...plan };
  const { paymentMethod = {}, accountNumber = '' } = { ...billingData };
  const { card = {} } = { ...paymentMethod };
  const { exp_year = 2020, exp_month = 4, last4 = '', brand = 'visa' } = { ...card };
  const { totalPriceWithCoupon } = getGranTotal(totalPrice, couponDiscount);

  const { t } = useTranslation();
  const userRole = getUserRole();
  const isClinic = userRole === ROLES.CLINIC;

  const creditCardNumber = () => `${capitalize(brand) || 'N/A'} •••${last4}`;

  const creditCardExpiration = () => capitalize(moment(`${exp_year}-${exp_month}-15`).format('MMMM YYYY'));

  const creditCardImage = () => {
    if (brand === 'visa') {
      return visa;
    }
    if (brand === 'amex') {
      return american;
    }
    if (brand === 'mastercard') {
      return mastercard;
    }
    return unknown;
  };
  return (
    <div className="billing__main flex-fill">
      <PaymentStep step={2} />
      <div className="billing__content">
        <div className="billing__content__title">
          <div className="back">
            <Icon name="back" className="icon" onClick={onBack} />
          </div>
          <p className="title">{t('billing.paymentSummary.title')}</p>
        </div>
        <div className="billing__content__layout">
          <div className="billing__content__confirm">
            <div className="billing__content__confirm__container">
              <div className="summary__card">
                <div className="summary__card__top__container">
                  <div>
                    <p className="title">{t('billing.summary.selectedPlan')}</p>
                    <p className="text">{name}</p>
                  </div>
                  <Button type="button" category="secondary-rounded" onClick={onPlan}>
                    {t('button.edit')}
                  </Button>
                </div>
                <div className="summary__card__footer__container">
                  <div>
                    <p className="text">
                      {patients} {isClinic ? t('billing.users.patients') : t('billing.users.employees')}
                    </p>
                    <p className="text">{capitalize(`${t('billing.paymentSummary.standard')} - ${name}`)}</p>
                  </div>
                </div>
              </div>
              <div className="summary__card">
                <div className="summary__card__top__container">
                  <div>
                    <p className="title">{t('billing.stepIndicator.method')}</p>
                    <p className="text">{method === 0 ? t('billing.paymentSummary.creditCard') : 'Nequi'}</p>
                  </div>
                  <Button type="button" category="secondary-rounded" onClick={onMethod}>
                    {t('button.edit')}
                  </Button>
                </div>
                <div className="summary__card__footer__container">
                  <div>
                    <p className="text">{method === 1 ? 'Cuenta Nequi #' : creditCardNumber()}</p>
                    <p className="text">
                      {method === 1 ? accountNumber : `${t('billing.success.expires')} ${creditCardExpiration()}`}
                    </p>
                  </div>
                  <img src={method === 1 ? nequi : creditCardImage()} className="cards-img" alt="credit cards" />
                </div>
              </div>
              <div className="summary__card">
                <div className="summary__card__top__container">
                  <div>
                    <p className="title">{t('billing.paymentSummary.totalPayment')}</p>
                    <p className="text">{`${name} (${isClinic ? discount : getPercentage(patients)}% Dcto)`}</p>
                    {couponDiscount && (
                      <p className="text">
                        {t('formLabel.promoCode')} ({couponDiscount}% Dcto)
                      </p>
                    )}
                  </div>
                </div>
                <div className="summary__card__footer__container price">
                  <div>
                    <p className="text">{t('billing.paymentSummary.total')}</p>
                  </div>
                  <p className="value total__price">
                    <span>{currencyFormat(totalPriceWithCoupon)}</span> {currency}
                  </p>
                </div>
              </div>
            </div>
            <Button type="button" category="rounded" className="button__confirm" onClick={onNext}>
              {t('billing.paymentSummary.button')}
            </Button>
          </div>
        </div>
        <div className="billing__content__confirm__footer">
          <img src={stripe} alt="stripe certified" />
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;

import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import ReactTooltip from 'react-tooltip';
import { FaUsers, FaUserPlus, FaMedal, FaTruck, FaFileInvoice } from 'react-icons/fa';

import { useAuthQuery } from 'utils/hooks';
import { ROUTES, PAYMENT_MODE } from 'utils/constants';
import { useToggle } from 'utils/hooks/useToggle';
import { GET_COMPANY_PROFILE } from 'apollo/queries';
import { useAppState } from 'components/StoreProvider';

import EmployeeInviteModal from 'components/Employees/EmployeeInviteModal';
import TeamCount from 'components/LeftMenuEmployers/TeamCount';
import AlertModal from 'components/AlertModal';

import healtphyLogo from 'assets/images/logo-white.svg';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

const LeftMenuEmployers = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { data } = useAuthQuery(GET_COMPANY_PROFILE);
  const { getCompanyInfo: company = {} } = { ...data };
  const { activeEmployees = {}, trialActive, profile } = { ...company };
  const { current_active: active, current_available: available } = { ...activeEmployees };
  const { paymentMode } = { ...profile };
  const isSubscription = paymentMode === PAYMENT_MODE.SUBSCRIPTION;

  const [expanded, setExpanded] = useState(false);
  const [planUpdateAlert, setPlanUpdateAlert] = useToggle();
  const { inviteCreateMember = false, toggleInviteCreateMember = () => {} } = useAppState();

  const addIconStyle = { width: 24, height: 24, marginLeft: expanded ? 0 : 5 };
  const iconStyle = { ...addIconStyle, marginTop: expanded ? '-4px' : 0, marginLeft: 0 };

  const handleCreateEmployee = () => {
    const canCreateEmployee = active < available;
    if (canCreateEmployee) {
      toggleInviteCreateMember();
    } else {
      setPlanUpdateAlert();
    }
  };

  const modalData = {
    open: planUpdateAlert,
    onCancelAction: setPlanUpdateAlert,
    onConfirmAction: () => history.push(ROUTES.BILLING),
    title: t('modalAlert.updateEmployerPlan.title'),
    description: t('modalAlert.updateEmployerPlan.message'),
    labelConfirm: t('button.update'),
  };

  return (
    <div style={{ width: expanded ? 280 : 64, position: 'relative' }}>
      {!expanded && <ReactTooltip place="right" effect="solid" id="menu" />}
      <SideNav onToggle={(toggle) => setExpanded(toggle)} expanded={expanded}>
        <NavItem className="left-menu__logo__item">
          <div
            className="left-menu__logo__container"
            style={{ width: expanded ? '80px' : '54px', height: expanded ? '80px' : '54px' }}
          >
            <img
              src={healtphyLogo}
              alt="healtphy Logo"
              className="left-menu__logo"
              style={{ width: expanded ? '80px' : '44px' }}
            />
          </div>
        </NavItem>
        <NavItem
          onClick={isSubscription ? handleCreateEmployee : toggleInviteCreateMember}
          data-tip={t('leftMenuHeader.companies.invite')}
          data-for="menu"
        >
          <div className={`left-menu__add ${expanded ? 'left-menu__expanded' : ''}`}>
            <FaUserPlus style={addIconStyle} />
            <span className={expanded ? 'left-menu__add_text' : ''}>{t('leftMenuHeader.companies.invite')}</span>
          </div>
        </NavItem>
        <SideNav.Toggle />
        <SideNav.Nav defaultSelected={ROUTES.EMPLOYEES} selected={location.pathname}>
          <NavItem
            className={expanded ? '' : 'left-menu__logo__first'}
            active={location.pathname === ROUTES.EMPLOYEES}
            data-tip={t('leftMenuHeader.companies.employees')}
            data-for="menu"
            onClick={() => history.push(ROUTES.EMPLOYEES)}
          >
            <NavIcon>
              <FaUsers style={iconStyle} />
            </NavIcon>
            <NavText>{t('leftMenuHeader.companies.employees')}</NavText>
          </NavItem>
          <NavItem
            active={location.pathname === ROUTES.REWARDS}
            data-tip={t('leftMenuHeader.companies.rewards')}
            data-for="menu"
            onClick={() => history.push(ROUTES.REWARDS)}
          >
            <NavIcon>
              <FaMedal style={iconStyle} />
            </NavIcon>
            <NavText>{t('leftMenuHeader.companies.rewards')}</NavText>
          </NavItem>
          <NavItem
            active={location.pathname === ROUTES.ORDERS}
            data-tip={t('leftMenuHeader.companies.orders')}
            data-for="menu"
            onClick={() => history.push(ROUTES.ORDERS)}
          >
            <NavIcon>
              <FaTruck style={iconStyle} />
            </NavIcon>
            <NavText>{t('leftMenuHeader.companies.orders')}</NavText>
          </NavItem>
          {!isSubscription && (
            <NavItem
              active={location.pathname === ROUTES.INVOICES}
              data-tip={t('leftMenuHeader.companies.invoices')}
              data-for="menu"
              onClick={() => history.push(ROUTES.INVOICES)}
            >
              <NavIcon>
                <FaFileInvoice style={iconStyle} />
              </NavIcon>
              <NavText>{t('leftMenuHeader.companies.invoices')}</NavText>
            </NavItem>
          )}
        </SideNav.Nav>
        {isSubscription && (
          <TeamCount expanded={expanded} activeEmployees={active} availableEmployees={available} trial={trialActive} />
        )}
      </SideNav>
      <EmployeeInviteModal modalIsOpen={inviteCreateMember} toggleModal={toggleInviteCreateMember} />
      <AlertModal {...modalData} />
    </div>
  );
};

export default React.memo(LeftMenuEmployers);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Formik, Field, Form } from 'formik';
import { isEmpty, pathOr } from 'ramda';
import { NumberInput, TextInput, MultiSelect } from 'components/FormComponents';
import { useMutation } from '@apollo/react-hooks';
import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';
import PaymentMethod from 'components/PaymentMethod';
import { UPDATE_PAYMENT_MODE, UPDATE_SPEND_LIMIT, UPDATE_PAYMENT_METHOD } from 'apollo/mutations';
import { companyPaymentForm } from 'utils/validationSchema';
import './payment-config.scss';

const Item = ({ label, value }) => (
  <div className="settings__right__info">
    <p className="label">{label}</p>
    <p className="value">{value}</p>
  </div>
);

const modalProps = {
  content: {
    width: '95%',
    maxWidth: '700px',
    padding: 30,
  },
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentConfig = ({ partnerInfo, refetch, setProcessing }) => {
  const { t } = useTranslation();

  const { profile, companySpendLimit = {}, cardBrand, cardLast4, paymentMethodType } = { ...partnerInfo };
  const { currency, paymentMode } = { ...profile };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [updateSpendLimit] = useMutation(UPDATE_SPEND_LIMIT);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const currencyFormatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    useGrouping: true,
  });

  const onSaveData = async (data) => {
    setProcessing(true);
    const dataCadence =
      data.cadence === t('settings.payment.yearly')
        ? 'yearly'
        : data.cadence === t('settings.payment.quarterly')
        ? 'quarterly'
        : 'bi_yearly';
    const response = await updateSpendLimit({
      variables: {
        allowanceAmount: Number(data.allowanceAmount),
        cadence: dataCadence,
      },
    });

    const errors = pathOr([], ['data', 'createCompanySpendLimit', 'errors'])(response);

    if (isEmpty(errors)) {
      refetch();
      toggleModal();
    }
    setProcessing(false);
  };

  return (
    <>
      <section className="settings settings--profile">
        <div className="settings__left">
          <p className="settings__left_profile">{t('settings.payment.title')}</p>
          <p className="settings__left_description">{t('settings.payment.description')}</p>
        </div>
        <div className="settings__right">
          <Item
            label={t('settings.payment.type')}
            value={!!paymentMode ? t(`settings.payment.${paymentMode}`) : '---'}
          />
          <Item
            label={t('settings.payment.allowance')}
            value={!!companySpendLimit ? currencyFormatter.format(companySpendLimit?.allowanceAmount) : '---'}
          />
          <Item
            label={t('settings.payment.cadence')}
            value={!!companySpendLimit?.cadence ? t(`settings.payment.${companySpendLimit?.cadence}`) : '---'}
          />
          <div className="settings__right__edit">
            <SolidButton onClick={toggleModal}>{t('button.edit')}</SolidButton>
          </div>
          <br />
          <br />
          {!!currency && (
            <Elements stripe={stripePromise} options={{ paymentMethodCreation: 'manual', mode: 'setup', currency }}>
              <PaymentMethod
                cardLast4={cardLast4}
                cardBrand={cardBrand}
                type={paymentMethodType}
                refetch={refetch}
                setLoading={setProcessing}
              />
            </Elements>
          )}
        </div>
      </section>
      <Modal modalIsOpen={modalIsOpen} closeModal={toggleModal} closeable={false} modalProps={modalProps}>
        <Formik
          initialValues={{
            paymentMode: t(`settings.payment.${paymentMode}`),
            allowanceAmount: companySpendLimit?.allowanceAmount,
            cadence: companySpendLimit?.cadence ? t(`settings.payment.${companySpendLimit?.cadence}`) : undefined,
          }}
          onSubmit={onSaveData}
          validationSchema={companyPaymentForm}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="payment_config__form">
              <p className="settings__left_profile">{t('settings.payment.title')}</p>
              <br />
              <Field
                type="text"
                label={t('settings.payment.type')}
                containerClass="payment_config__type_input_container"
                name="paymentType"
                component={TextInput}
                disabled={true}
                value={!!paymentMode ? t(`settings.payment.${paymentMode}`) : '---'}
              />
              <p className="payment_config__tooltip">{t('settings.payment.changeType')}</p>
              <Field
                type="text"
                label={t('settings.payment.cadence')}
                name="cadence"
                component={MultiSelect}
                options={[
                  t('settings.payment.yearly'),
                  t('settings.payment.bi_yearly'),
                  t('settings.payment.quarterly'),
                ]}
              />
              <Field
                type="text"
                label={`${t('settings.payment.allowance')} (${currency?.toUpperCase()})`}
                name="allowanceAmount"
                component={NumberInput}
              />
              <div className="payment_config__form__buttons">
                <SolidButton type="submit">{t('button.save')}</SolidButton>
                <SolidButton className="secondary" onClick={toggleModal}>
                  {t('button.cancel')}
                </SolidButton>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

PaymentConfig.propTypes = {
  partnerInfo: PropTypes.object,
  refetch: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default PaymentConfig;

import React from 'react';
import { object, string } from 'yup';
import { Formik, Field, Form } from 'formik';
import { TextInput, TextAreaInput } from 'components/FormComponents';
import { requiredMessage, urlMessage } from 'utils/validationSchema';

const validationSchema = object().shape({
  title: string().trim().required(requiredMessage),
  content: string().trim().required(requiredMessage),
  gif: string().trim().url(urlMessage),
});

const TextImageForm = (props) => {
  const { onUpdateField, data, footer } = { ...props };

  return (
    <Formik initialValues={data} onSubmit={onUpdateField} validationSchema={validationSchema}>
      {() => (
        <Form className="form">
          <Field type="text" label="Image url" name="gif" component={TextInput} />
          <Field type="text" label="Título" name="title" component={TextInput} />
          <Field type="text" label="Contenido" name="content" minRows={10} component={TextAreaInput} />
          {footer}
        </Form>
      )}
    </Formik>
  );
};

export default TextImageForm;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';
import { CardElement } from '@stripe/react-stripe-js';
import { Formik, Field, Form } from 'formik';
import { useLazyQuery } from '@apollo/react-hooks';
import { isEmpty } from 'ramda';

import { CreditCardForm, NequiForm } from 'utils/validationSchema';
import showNotification from 'utils/showNotification';
import { useGetCountries } from 'utils/hooks/useGetCountries';
import { GET_COUPON } from 'apollo/queries';

import Icon from 'components/Icon';
import Summary from 'components/Billing/Summary';
import PaymentStep from 'components/Billing/PaymentStep';
import { TextInput, MultiSelect, MaskedInput, NumberInput } from 'components/FormComponents';
import SolidButton from 'components/SolidButton';

import creditCards from 'assets/images/form-cards.svg';
import nequi from 'assets/images/nequi.svg';

import './payment-data.scss';

const baseValues = {
  country: '',
  phone: '',
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  terms: false,
};
const date = moment();
const validators = [CreditCardForm, NequiForm];
const initialValues = [
  {
    ...baseValues,
    cardNumber: '',
    cvv: '',
    cardHolder: '',
    dues: '1',
    expirationMonth: date.format('MM'),
    expirationYear: date.format('YYYY'),
  },
  { ...baseValues, accountNumber: '' },
];

// export const duesOptions = [...new Array(12).fill('0').map((el, index) => `${index + 1}`), '18', '24', '36', '72'];
// export const yearsOptions = new Array(8).fill(moment().format('YYYY')).map((el, index) => `${Number(el) + index}`);
// export const monthsOptions = new Array(12).fill('0').map((el, index) => `${index + 1 < 10 ? '0' : ''}${index + 1}`);

const PaymentData = (props) => {
  const { plan, onBack, method = 0, onNext = () => {}, billingData = {}, handleAddCoupon = () => {} } = { ...props };
  const { t } = useTranslation();
  const { dialingCodeList } = useGetCountries();

  const [couponInput, setCouponInput] = useState('');

  const [getCoupon, { data: couponData = {} }] = useLazyQuery(GET_COUPON);
  const { checkStripeCoupon = {} } = { ...couponData };
  const { coupon = {}, errors: couponErrors } = { ...checkStripeCoupon };
  const { percent_off: percentOff = null, id } = { ...coupon };

  const handleChangeCoupon = ({ target: { value } }) => {
    setCouponInput(value);
  };

  const onApplyCoupon = async () => {
    await getCoupon({ variables: { couponId: couponInput } });
    setCouponInput('');
  };

  useEffect(() => {
    if (couponErrors) {
      const emptyErrors = isEmpty(couponErrors);
      showNotification({
        type: emptyErrors ? 'success' : 'error',
        messages: emptyErrors ? [t('notification.coupon')] : couponErrors,
      });
    }
  }, [couponErrors]);

  useEffect(() => {
    if (percentOff) {
      handleAddCoupon(percentOff, id);
    }
  }, [percentOff]);

  return (
    <div className="billing__main flex-fill">
      <PaymentStep step={1} />
      <div className="billing__content">
        <div className="billing__content__title">
          <div className="back">
            <Icon name="back" className="icon" onClick={onBack} />
          </div>
          <p className="title">{t('billing.summary.title')}</p>
        </div>
        <Formik
          initialValues={{ ...initialValues[method], ...billingData }}
          onSubmit={onNext}
          validationSchema={validators[method]}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="billing__content__layout">
              <div className="billing__content__form">
                <div className="billing__content__form__title">
                  <Icon name="billing" className="icon" />
                  <p className="title">{t('billing.paymentData.billingInfo')}</p>
                </div>
                <div className="billing__content__form__row">
                  <Field type="text" label={t('formLabel.name')} name="firstName" component={TextInput} />
                  <Field type="text" label={t('formLabel.lastName')} name="lastName" component={TextInput} />
                </div>
                <div className="billing__content__form__row">
                  <Field type="text" label={t('formLabel.email')} name="email" component={TextInput} />
                  <div className="billing__phone__container">
                    <label className="form__label">{t('formLabel.contactNumber')}</label>
                    <div className="billing__phone">
                      <Field
                        type="text"
                        name="country"
                        component={MultiSelect}
                        options={dialingCodeList}
                        className="contry__code"
                      />
                      <Field type="text" name="phone" component={NumberInput} />
                    </div>
                  </div>
                </div>
                <div className="billing__content__form__row field_70">
                  <Field type="text" label={t('formLabel.address')} name="address" component={TextInput} />
                </div>
                <div className="billing__content__form__title second">
                  <Icon name="card" className="icon" />
                  <p className="title">{method === 0 ? t('billing.paymentData.cardInfo') : 'Paga con Nequi'}</p>
                  <img
                    src={method === 0 ? creditCards : nequi}
                    className="cards-img"
                    alt="credit cards"
                    style={{ maxWidth: '40%' }}
                  />
                </div>
                {method === 0 && (
                  <div className="form__input">
                    <CardElement
                      options={{
                        style: {
                          base: {
                            color: '#86888f',
                            fontSize: '15px',
                            fontWeight: 'normal',
                          },
                        },
                      }}
                    />
                  </div>
                  /* <>
                    <div className={'billing__content__form__row'}>
                      <Field
                        type='text'
                        label='Número de tarjeta'
                        name='cardNumber'
                        component={MaskedInput}
                        size={20}
                        mask={'1111  1111  1111  1111'}
                        placeholderChar={' '}
                      />
                      <Field type='text' label='Nombre en la tarjeta' name='cardHolder' component={TextInput} />
                    </div>
                    <div className={'billing__content__form__row cols_4'}>
                      <Field
                        type='text'
                        label='Mes Expiración'
                        name='expirationMonth'
                        component={MultiSelect}
                        options={monthsOptions}
                      />
                      <Field
                        type='text'
                        label='Año Expiración'
                        name='expirationYear'
                        component={MultiSelect}
                        options={yearsOptions}
                      />
                      <Field
                        type='text'
                        label='CVV'
                        name='cvv'
                        component={MaskedInput}
                        size={4}
                        mask={'1111'}
                        placeholderChar={' '}
                      />
                      <Field type='text' label='Cuotas' name='dues' component={MultiSelect} options={duesOptions} />
                    </div>
                  </CardElement> */
                )}
                {method === 1 && (
                  <div className="billing__content__form__row field_70">
                    <Field
                      type="text"
                      label="Número celular asociado a cuenta Nequi"
                      name="accountNumber"
                      component={MaskedInput}
                      size={20}
                      mask="(111) 111-1111"
                      placeholderChar={' '}
                    />
                  </div>
                )}
                <br />
                <div className="billing__promo">
                  <div className="billing__promo__field">
                    <label htmlFor="coupon" className="form__label">
                      {t('formLabel.promoCode')}
                    </label>
                    <input id="coupon" value={couponInput} className="form__input" onChange={handleChangeCoupon} />
                  </div>
                  <SolidButton
                    className={`slim ml-2 ${isEmpty(couponInput) ? 'disabled' : ''}`}
                    onClick={onApplyCoupon}
                    disabled={isEmpty(couponInput)}
                  >
                    {t('button.apply')}
                  </SolidButton>
                </div>
                <div className="billing__content__form__row field_100">
                  <Field
                    name="terms"
                    render={({ field, form: { touched, errors } }) => (
                      <div className="form__input-container checkbox__input">
                        <input {...field} type="checkbox" checked={field.value} />
                        <label className="form__label">
                          <Trans
                            components={{
                              contract: <Link to="/licencia" target="_blank" rel="noopener noreferrer" />,
                              privacy: <Link to="/privacidad" target="_blank" rel="noopener noreferrer" />,
                            }}
                          >
                            billing.paymentData.terms
                          </Trans>
                        </label>
                        {touched.terms && errors.terms && (
                          <div className="form__error billing__terms">{errors.terms}</div>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
              <Summary plan={plan} onNext={null} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PaymentData;

import React from 'react';
import moment from 'moment';
import { capitalize } from '../../utils/string';

moment.locale = 'es';
const MonthView = (props) => {
  const { current = moment(), onSelectDay = () => {}, events = [] } = { ...props };
  const currentLabel = capitalize(current.format('MMMM YYYY'));

  const generateMonth = () => {
    const newDays = [];
    const start = moment(current).startOf('month');
    const startWD = start.weekday();
    const end = moment(current).endOf('month');
    const endWD = end.weekday();
    if (startWD > 0) {
      for (let i = startWD; i > 0; i--) {
        newDays.push({ value: '' });
      }
    }
    for (let j = 1; j <= end.date(); j++) {
      newDays.push({
        value: `${j}`,
        events: events.filter(({ startAt }) => moment(startAt).format('YYYYMMD') === `${moment(current).format('YYYYMM')}${j}`)
          .length,
      });
    }
    if (endWD < 6) {
      for (let k = 6; k > endWD; k--) {
        newDays.push({ value: '' });
      }
    }
    return newDays;
  };

  const isToday = ({ value: day }) => {
    return `${moment(current).format('YYYYMM')}${day}` === moment().format('YYYYMMDD');
  };

  return (
    <div style={{ display: 'flex', height: '85%' }}>
      <div className={'month-view'}>
        <div className={'month-view-title'}>
          <span>
            <b>{currentLabel}</b>
          </span>
        </div>
        <div className={'month-view-body'}>
          <span className={'month-view-item title'}>LUN</span>
          <span className={'month-view-item title'}>MAR</span>
          <span className={'month-view-item title'}>MIE</span>
          <span className={'month-view-item title'}>JUE</span>
          <span className={'month-view-item title'}>VIE</span>
          <span className={'month-view-item title'}>SAB</span>
          <span className={'month-view-item title'}>DOM</span>
          {generateMonth().map((item, index) => (
            <span key={index} className={`month-view-item ${!item.value && 'past'} ${isToday(item) && 'today'}`}>
              {item.value}
              {!!item.events && (
                <div className={'events-count'} onClick={() => onSelectDay(`${moment(current).format('YYYYMM')}${item.value}`)}>
                  <div />
                  {item.events} evento{item.events > 1 ? 's' : ''}
                </div>
              )}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
export default MonthView;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';

import { CREATE_PLAN, EDIT_PLAN } from 'utils/steps';
import { useAppState } from 'components/StoreProvider';

import Icon from 'components/Icon';
import SolidButton from 'components/SolidButton';
import { NoPatientData } from 'components/EmptyState';
import PlanCard from 'components/Plan/PlanCard';
import Modal from 'components/Modal';
import Pagination from 'components/Pagination';

const rowsPerPage = 2;

const Plans = ({ patient = {} }) => {
  const { id: patientId, patientPlans: plans = [] } = { ...patient };
  const { setStoreData } = useAppState();

  const [modalIsOpen, setIsModalOpen] = useState(false);

  const [patientsPlan, setPatientsPlan] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  const isNewResults = (results) => {
    const rIds = (results || []).map(({ id }) => id).join(',');
    const fIds = (filtered || []).map(({ id }) => id).join(',');
    return rIds !== fIds;
  };

  useEffect(() => {
    const temporal = plans;
    const totalPages = temporal.length > 0 ? Math.ceil(temporal.length / rowsPerPage) : 0;
    if (page !== 1) setPage(1);
    if (pages !== totalPages) setPages(totalPages);
    if (isNewResults(temporal)) setFiltered(temporal.sort((a, b) => (a.status > b.status ? 1 : -1)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  useEffect(() => {
    const init = page * rowsPerPage - rowsPerPage;
    const end = init + rowsPerPage;
    setPatientsPlan(filtered.slice(init, end));
  }, [filtered, page]);

  const onCloseModal = () => setIsModalOpen(false);
  const { push } = useHistory();
  const onCreatePlan = () => {
    setStoreData('patient', { ...patient, id: patientId });
    setStoreData('step', CREATE_PLAN);
    setStoreData('customPlan', { title: '', description: '' });
    setStoreData('weeks', []);
    push('/editar-paciente');
  };

  const onEditPlan = (patientPlan) => () => {
    const { id: planId, plan, currentDay = {} } = { ...patientPlan };
    const { id: currentDayId } = { ...currentDay };
    const { weeks = [] } = { ...plan };
    const cws = weeks.map((w) => {
      const { days = [] } = { ...w };
      return days.map(({ id }) => id).indexOf(currentDayId);
    });
    const weekIndex = cws.find((c) => c !== -1);
    const currentDayIndex = cws.indexOf(weekIndex);
    const parsedWeeks = weeks.map((week, index) => {
      const { title = `Semana ${index + 1}`, days: weekDays = [] } = { ...week };
      const days = weekDays
        .map((day) => {
          const { weekNumber, exercises = [] } = { ...day };
          return exercises.map((exercise) => ({ ...exercise, weekNumber }));
        })
        .reduce((a, b) => [...a, ...b], []);
      return { ...week, title, days, disabled: index <= currentDayIndex };
    });
    setStoreData('patient', { ...patient, id: patientId });
    setStoreData('customPlan', { ...plan, planId });
    setStoreData('weeks', parsedWeeks);
    setStoreData('step', EDIT_PLAN);
    push('/editar-paciente');
  };

  const handleChangePage = (currentPage) => {
    setPage(currentPage);
  };

  return (
    <div className="w-100">
      <div className="patient__title">
        <div>
          <Icon name="plans" className="icon" />
          <p className="title">Planes asignados</p>
        </div>
      </div>
      <div className="patient__tab_content plans">
        {isEmpty(plans) ? (
          <NoPatientData tab="plans" onCreateData={onCreatePlan} />
        ) : (
          <>
            <table>
              <tbody>
                {patientsPlan.map((item) => {
                  const { id, plan, status } = { ...item };
                  return (
                    <tr key={id}>
                      <td>
                        <PlanCard plan={plan} status={status} onClick={onEditPlan(item)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="edit-plan-container">
              <Pagination currentPage={page} totalPages={pages} onPageChange={handleChangePage} />
              <SolidButton onClick={() => setIsModalOpen(true)}>Crear plan</SolidButton>
            </div>
          </>
        )}
      </div>
      <Modal modalIsOpen={modalIsOpen} closeModal={onCloseModal} closeable={false}>
        <div>
          <h3 className="modal__title">¿Seguro quieres continuar?</h3>
          <p className="modal__subtitle">
            Al crear el nuevo plan, este quedará como el plan activo del paciente y todos los demás serán desactivados
            automáticamente
          </p>
          <div className="d-flex justify-content-center mt-4 modal_buttons">
            <SolidButton className="secondary mr-3" onClick={onCloseModal}>
              Cancelar
            </SolidButton>
            <SolidButton onClick={onCreatePlan}>Continuar</SolidButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

Plans.propTypes = {
  patient: PropTypes.shape({
    patientId: PropTypes.string,
    plans: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default Plans;

export const getFullDate = ({ date, lng, options }) => date.toLocaleDateString(lng, options);

export const getTimestamp = (timestamp) => timestamp.toLocaleTimeString();

export const getNameAndInitials = (conversation) => {
  const employeeName = conversation?.attributes?.employee_name;
  const initials = employeeName ? employeeName.slice(0, 2) : conversation.friendlyName.slice(0, 2);
  const name = employeeName || conversation.friendlyName || conversation.sid;

  return { name, initials };
};

export const sortConversations = (a, b) => {
  const aLastMessageDate = a?.lastMessage?.dateCreated;
  const bLastMessageDate = b?.lastMessage?.dateCreated;
  if (aLastMessageDate) {
    return bLastMessageDate - aLastMessageDate;
  }
  return b.dateUpdated - a.dateUpdated;
};

import React from 'react';
import { Formik, Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { useCreateReadingContext } from 'components/readings/CreateReadingProvider';
import { headingForm } from 'utils/validationSchema';

import { TextAreaInput } from 'components/FormComponents';
import SolidButton from 'components/SolidButton';
import { FormTitle } from './FormTitle';

export const TextForm = () => {
  const { t } = useTranslation();
  const { endEditing, saveBlock, builderState } = useCreateReadingContext();
  const { selectedBlock = {} } = builderState;
  const { en = '', es = '', bold = false } = selectedBlock;
  const initialValues = { es, en, bold };

  return (
    <>
      <FormTitle />
      <Formik initialValues={initialValues} onSubmit={saveBlock} validationSchema={headingForm}>
        {({ setFieldValue }) => (
          <Form>
            <Field
              type="text"
              className="builder__blockOptions_text-input"
              label={t('formLabel.esTextLabel')}
              name="es"
              minRows={10}
              component={TextAreaInput}
            />
            <Field
              type="text"
              className="builder__blockOptions_text-input"
              label={t('formLabel.enTextLabel')}
              name="en"
              minRows={10}
              component={TextAreaInput}
            />
            <Field
              name="bold"
              render={({ field }) => (
                <div>
                  <input
                    id="strong"
                    {...field}
                    type="checkbox"
                    checked={field.value}
                    onChange={() => {
                      setFieldValue('bold', !field.value);
                    }}
                  />
                  <label htmlFor="strong" className="builder__blockOptions_checkbox-label">
                    {t('formLabel.bold')}
                  </label>
                </div>
              )}
            />
            <div className="d-flex mt-4 modal__buttons right_buttons">
              <SolidButton className="secondary mr-2" onClick={endEditing}>
                {t('button.cancel')}
              </SolidButton>
              <SolidButton type="submit" className="">
                {t('button.save')}
              </SolidButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

import React from 'react';

import { BLOCK_TYPE } from '../service/constants';
import { useCreateReadingContext } from '../CreateReadingProvider';

import { HeadingForm, TextForm, ImageUrlForm, BulletListForm } from '../BuilderFormComponents';

const BlockModifier = () => {
  const { builderState } = useCreateReadingContext();
  const { blockType } = { ...builderState };

  switch (blockType) {
    case BLOCK_TYPE.HEADING_1:
    case BLOCK_TYPE.HEADING_2:
    case BLOCK_TYPE.HEADING_3:
      return <HeadingForm />;
    case BLOCK_TYPE.TEXT:
      return <TextForm />;
    case BLOCK_TYPE.IMAGE:
      return <ImageUrlForm />;
    case BLOCK_TYPE.BULLET_LIST:
      return <BulletListForm />;
    default:
      return <div>Unknown value: {blockType}</div>;
  }
};

export default BlockModifier;

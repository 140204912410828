import React from 'react';
import PropTypes from 'prop-types';

import SolidButton from 'components/SolidButton';

import noData from 'assets/images/no-data.svg';

const getTitle = (tab) => {
  switch (tab) {
    case 'assessment': {
      return 'Aún no has creado un perfil para este paciente!';
    }
    case 'plans': {
      return 'El paciente no tiene planes asociados';
    }
    case 'followup': {
      return 'El paciente aún no tiene datos de seguimiento';
    }
    case 'calls':
      return 'El paciente aún no tiene solicitudes de llamada';
    case 'surveys':
      return 'Por el momento no hay ninguna encuesta relacionada a este paciente.';
    default: {
      return null;
    }
  }
};
const getSubtitle = (tab) => {
  switch (tab) {
    case 'assessment': {
      return 'Inicia creando el perfil de tu paciente y configurando su plan de ejercicios.';
    }
    case 'plans': {
      return 'Asocia uno de los planes existentes o personalizados para iniciar el tratamiento de tu paciente.';
    }
    case 'followup': {
      return 'Lleva control del progreso de tu paciente en su terapia, inicia registrando un seguimiento.';
    }
    case 'calls':
      return 'Realiza el seguimiento del proceso de tu paciente en casa, aquí podrás ver cuando solicite acompañamiento.';
    case 'surveys':
      return 'Empieza enviando una invitación para encuesta.';
    default: {
      return null;
    }
  }
};
const getButtonText = (tab) => {
  switch (tab) {
    case 'assessment': {
      return 'Crear';
    }
    case 'plans': {
      return 'Crear plan';
    }
    case 'followup':
    case 'surveys': {
      return 'Agregar';
    }
    default: {
      return null;
    }
  }
};

const NoPatientData = ({ onCreateData, tab, calls }) => (
  <div className="members-empty-state patient-empty-state">
    <div className="content">
      <p className="title">{getTitle(tab)}</p>
      <p className="subtitle">{getSubtitle(tab)}</p>
      {!calls && <SolidButton onClick={onCreateData}>{getButtonText(tab)}</SolidButton>}
    </div>
    <img src={noData} alt="no data" className="image" />
  </div>
);

NoPatientData.defaultProps = {
  onCreateData: () => {},
  calls: false,
};

NoPatientData.propTypes = {
  tab: PropTypes.string.isRequired,
  onCreateData: PropTypes.func,
  calls: PropTypes.bool,
};

export default NoPatientData;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BsExclamationCircle } from 'react-icons/bs';

import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: 500,
    padding: '40px 20px',
  },
};

const EvieWarning = ({ open, onCancelAction, onConfirmAction, title }) => {
  const { t } = useTranslation();

  return (
    <Modal modalProps={modalProps} modalIsOpen={open} closeModal={onCancelAction} closeable={false}>
      <div className="evie-warning">
        <div className="evie-warning__icon">
          <BsExclamationCircle />
        </div>
        <h3 className="evie-warning__title">{title}</h3>
        <div className="evie-warning__handlers">
          <SolidButton className="evie-secondary mr-2" onClick={onCancelAction}>
            {t('button.cancel')}
          </SolidButton>
          <SolidButton onClick={onConfirmAction} className="evie-primary">
            {t('button.delete')}
          </SolidButton>
        </div>
      </div>
    </Modal>
  );
};

EvieWarning.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancelAction: PropTypes.func.isRequired,
  onConfirmAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default EvieWarning;

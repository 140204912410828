import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { IoIosArrowDown, IoMdEye } from 'react-icons/io';

import { useToggle } from 'utils/hooks/useToggle';
import { useFormInput } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROUTES, ROLES } from 'utils/constants';
import { GET_JSON_PARSE } from 'apollo/queries';

import SqueezePreview from 'components/squeeze/SqueezePreview';
import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SearchFilter from 'components/SearchFilter';
import SolidButton from 'components/SolidButton';
import { NoMembers } from 'components/EmptyState';

import { useGetSqueezes } from 'components/squeeze/service';

import noTemplates from 'assets/images/no-data.svg';

const Squeezes = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const { push } = useHistory();

  const { allSqueeze, loading } = useGetSqueezes();
  const [getJsonParse, { loading: loadingJsonParse, data: jsonData = {} }] = useLazyQuery(GET_JSON_PARSE);
  const { squeeze = {} } = { ...jsonData };

  const searchSqueezes = useFormInput('');
  const { value = '' } = { ...searchSqueezes };

  const [squeezes, setSqueezes] = useState([]);
  const [isSqueeze, setIsSqueeze] = useToggle();

  useEffect(() => {
    let temporal = allSqueeze;
    if (value) {
      temporal = allSqueeze.filter((sq) => {
        const { title } = sq;
        return `${title}`.toLowerCase().includes(value.toLowerCase());
      });
    }
    setSqueezes(temporal);
  }, [loading, value]);

  const handleChangeRoute = () => {
    push(ROUTES.CREATE_SQUEEZE);
  };
  const handleGetJsonParse = (squeezeId) => {
    setIsSqueeze();
    getJsonParse({ variables: { squeezeId } });
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenu />
        <div className="dashboard__main">
          <Header title={t('leftMenuHeader.squeezes')} shadow />
          <div className="dashboard__content--patients">
            {!loading && isEmpty(allSqueeze) && (
              <NoMembers
                title={t('squeezes.emptyState.title')}
                subtitle={t('squeezes.emptyState.description')}
                button={t('squeezes.createBtn')}
                handleClick={handleChangeRoute}
                image={noTemplates}
              />
            )}
            {!loading && !isEmpty(allSqueeze) && (
              <div className="forms">
                <div className="forms__search">
                  <SearchFilter searchItems={searchSqueezes} placeholder={t('searchLabel.squeeze')} />
                  <SolidButton onClick={handleChangeRoute} className="slim">
                    {t('squeezes.createBtn')}
                  </SolidButton>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '40%' }}>{t('tableHeader.title')}</th>
                      <th style={{ width: '40%' }}>{t('tableHeader.content')}</th>
                      <th style={{ width: '20%' }}> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(squeezes || []).map((sq) => {
                      const { id, title, content } = sq;
                      return (
                        <tr key={id}>
                          <td style={{ width: '40%' }}>{title}</td>
                          <td style={{ width: '40%' }}>
                            <p className="content-column-table">{content.length} actions</p>
                          </td>
                          <td className="actions-col" style={{ width: '20%' }}>
                            <button type="button" className="form-detail" onClick={() => handleGetJsonParse(id)}>
                              <IoMdEye />
                            </button>
                            <button
                              type="button"
                              onClick={() => push(`${ROUTES.SQUEEZE}?id=${id}`)}
                              className="form-detail"
                            >
                              <IoIosArrowDown />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </section>
      {isSqueeze && !loadingJsonParse && (
        <SqueezePreview modalIsOpen={isSqueeze} toggleModal={setIsSqueeze} previewData={squeeze} />
      )}
      <Loader
        className="spinner"
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        visible={loading || loadingJsonParse}
      />
    </>
  );
};

export default Squeezes;

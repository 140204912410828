import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { sendEmail } from 'utils/validationSchema';

import { TextInput } from 'components/FormComponents';
import SolidButton from 'components/SolidButton';

const RequestPasswordForm = ({ onSendEmail, toggleForgotPassword }) => {
  const { t } = useTranslation();

  return (
    <Formik initialValues={{ email: '' }} onSubmit={onSendEmail} validationSchema={sendEmail} enableReinitialize>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <p className="send-email-message">{t('login.recoverMessage')}</p>
          <Field
            type="email"
            name="email"
            label={t('formLabel.email')}
            component={TextInput}
            placeholder="example@email.com"
            labelClassName="login-label"
          />
          <div className="send-email-buttons">
            <SolidButton className="secondary mr-3" onClick={toggleForgotPassword}>
              {t('button.cancel')}
            </SolidButton>
            <SolidButton type="submit">{t('button.send')}</SolidButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

RequestPasswordForm.propTypes = {
  onSendEmail: PropTypes.func.isRequired,
  toggleForgotPassword: PropTypes.func.isRequired,
};

export default RequestPasswordForm;

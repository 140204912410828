import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { CREATE_UPDATE_PROGRAM } from 'apollo/mutations';
import { ROUTES } from 'utils/constants';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';

export const useCreateUpdateProgram = (programId, programData) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { backgroundImage, doctorImage, title, description, duration, doctorName, planId, templateId, readingDays } = {
    ...programData,
  };

  const [createUpdateProgram] = useMutation(CREATE_UPDATE_PROGRAM);
  const [spinner, setSpinner] = useToggle();

  const handleCreateEditProgram = async () => {
    setSpinner();
    const response = await createUpdateProgram({
      variables: {
        ...(!!programId && {
          programId,
        }),
        ...(typeof backgroundImage === 'object' && {
          backgroundImage,
        }),
        ...(typeof doctorImage === 'object' && {
          doctorImage,
        }),
        programInput: {
          title,
          description,
          duration: Number(duration),
          doctorName,
          planId,
          templateId,
          readingDays,
        },
      },
    });

    const errors = pathOr([], ['data', 'createUpdateProgram', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors
        ? [programId ? t('notification.program.update') : t('notification.program.create')]
        : errors,
    });

    if (emptyErrors) {
      push(ROUTES.PROGRAMS);
    }
  };

  return { createEditProgram: handleCreateEditProgram, spinner };
};

import React from 'react';
import Select from 'react-select';

import { useCreateAvailabilityContext } from 'components/availability/CreateAvailabilityProvider';
import { customStyles } from 'utils/styles';

const Timezone = () => {
  const { allTimeZones, selectedTimezone, handleSelectTimezone } = useCreateAvailabilityContext();
  return (
    <div className="availability__timezone">
      <Select value={selectedTimezone} options={allTimeZones} styles={customStyles} onChange={handleSelectTimezone} />
    </div>
  );
};

export default Timezone;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isEmpty, pathOr } from 'ramda';
import { BiCheck } from 'react-icons/bi';
import { IoIosArrowDown, IoMdEye } from 'react-icons/io';
import { BsTrash } from 'react-icons/bs';

import { useMutation } from '@apollo/react-hooks';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';
import { DELETE_CLINIC_FORM } from 'apollo/mutations';

import AlertModal from 'components/AlertModal';

moment.locale = 'es';
const FormsList = ({
  forms,
  selectedForm,
  handleSelectForm,
  handleSelectFormPreview,
  changeRoute,
  translateType,
  refetchForms,
}) => {
  const { t } = useTranslation();
  const [selectId, setSelectId] = useState('');
  const [alertModal, setAlertModal] = useToggle();
  const [deleteClinicForm] = useMutation(DELETE_CLINIC_FORM);

  const handleOpenAlertModal = (selectedId) => {
    setAlertModal();
    setSelectId(selectedId);
  };

  const onDeleteForm = async () => {
    const response = await deleteClinicForm({ variables: { formId: selectId } });

    const errors = pathOr([], ['data', 'deleteClinicForm', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [t('notification.deleteForm')] : errors,
    });

    if (emptyErrors) {
      refetchForms();
      setSelectId('');
      setAlertModal();
    }
  };

  const modalData = {
    open: alertModal,
    onCancelAction: setAlertModal,
    onConfirmAction: onDeleteForm,
    title: t('deleteForm.title'),
    description: t('deleteForm.description'),
    labelConfirm: t('button.delete'),
    category: 'remove',
  };

  return (
    <>
      {(forms || []).map((form) => {
        const { id, title, createdAt, typeForm } = { ...form };
        const isSurveyType = typeForm === 'survey';
        const isSelectedForm = !isEmpty(selectedForm) && id === selectedForm;
        return (
          <tr key={id} className={`${isSelectedForm && 'selected-form'}`}>
            <td style={{ width: '5%' }}>
              {isSurveyType && (
                <button type="button" onClick={() => handleSelectForm(isSelectedForm, id)} className="select-form">
                  {isSelectedForm && <BiCheck />}
                </button>
              )}
            </td>
            <td style={{ width: '50%' }}>{title}</td>
            <td style={{ width: '10%' }}>{translateType(typeForm)}</td>
            <td style={{ width: '25%' }}>{moment(createdAt).format('DD/MM/YYYY - hh:mm A')}</td>
            <td className="actions-col" style={{ width: '10%' }}>
              <button type="button" className="form-detail" onClick={() => handleSelectFormPreview(form)}>
                <IoMdEye />
              </button>
              <button type="button" onClick={() => changeRoute(`/formulario?id=${id}`)} className="form-detail">
                <IoIosArrowDown />
              </button>
              <button
                type="button"
                onClick={() => handleOpenAlertModal(id)}
                className="form-detail form-detail--delete"
              >
                <BsTrash />
              </button>
            </td>
          </tr>
        );
      })}
      <AlertModal {...modalData} />
    </>
  );
};

export default FormsList;

import { BLOCK_TYPE } from './constants';

export const formatDataToSend = (content = []) => {
  const handleFormatContent = (block) => {
    const { bold, en, es, id, type, url } = block;
    let items;
    if (type === BLOCK_TYPE.BULLET_LIST) {
      items =
        en.length > es.length
          ? en.map((item, index) => ({ en: item, es: es[index] || '' }))
          : es.map((item, index) => ({ es: item, en: en[index] || '' }));
    }
    switch (type) {
      case BLOCK_TYPE.HEADING_1:
      case BLOCK_TYPE.HEADING_2:
      case BLOCK_TYPE.HEADING_3:
      case BLOCK_TYPE.TEXT:
        return { id, type, content: { en, es }, ...(bold && { style: 'bold' }) };
      case BLOCK_TYPE.IMAGE:
        return { id, type, url };
      case BLOCK_TYPE.BULLET_LIST:
        return { id, type, items, ...(bold && { style: 'bold' }) };
      case BLOCK_TYPE.DIVIDER:
        return { id, type };
      default:
        return { type };
    }
  };
  return content.map(handleFormatContent);
};

export const formatRetrievedData = (contentJson = []) => {
  const handleFormatContentJson = (block) => {
    const { id, type, style, content = {}, url, items } = block;
    const { en, es } = content;
    const enItems = [];
    const esItems = [];
    if (type === BLOCK_TYPE.BULLET_LIST) {
      items.forEach((item) => {
        enItems.push(item.en);
        esItems.push(item.es);
      });
    }
    switch (type) {
      case BLOCK_TYPE.HEADING_1:
      case BLOCK_TYPE.HEADING_2:
      case BLOCK_TYPE.HEADING_3:
      case BLOCK_TYPE.TEXT:
        return { id, type, en, es, ...(style && { bold: true }) };
      case BLOCK_TYPE.IMAGE:
        return { id, type, url };
      case BLOCK_TYPE.BULLET_LIST:
        return { id, type, en: enItems, es: esItems, ...(style && { bold: true }) };
      case BLOCK_TYPE.DIVIDER:
        return { id, type };
      default:
        return { type };
    }
  };
  return contentJson.map(handleFormatContentJson);
};

export const formatBackgroundImage = (image) => (typeof image === 'object' ? URL.createObjectURL(image) : image);

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';

import { ROUTES } from 'utils/constants';

import Thumb from 'components/Thumb';

const TemplatesList = ({ templates }) => {
  const { push } = useHistory();

  return (
    <>
      {(templates || []).map((template) => {
        const {
          id,
          title,
          readings,
          description,
          backgroundImage: { thumb },
        } = { ...template };
        return (
          <tr key={id} className="">
            <td style={{ width: '20%' }}>
              <Thumb source={thumb} alt={title} />
            </td>
            <td style={{ width: '30%' }}>{title}</td>
            <td style={{ width: '30%' }}>{description}</td>
            <td style={{ width: '10%' }}>{readings.length}</td>
            <td className="actions-col" style={{ width: '10%' }}>
              <button type="button" onClick={() => push(`${ROUTES.TEMPLATE}?id=${id}`)} className="form-detail">
                <IoIosArrowDown />
              </button>
            </td>
          </tr>
        );
      })}
    </>
  );
};

TemplatesList.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      readings: PropTypes.arrayOf(PropTypes.shape({})),
      backgroundImage: PropTypes.shape({
        thumb: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default TemplatesList;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';

import { ACTIONS } from 'components/createActivePause/service';
import { useCreateActivePauseContext } from 'components/createActivePause/CreateActivePauseProvider';

const VideoList = () => {
  const { t } = useTranslation();
  const { exerciseIds, dispatch } = useCreateActivePauseContext();
  const videoQuantity = exerciseIds.length;

  const handleRemoveExercise = (exerciseId) => {
    dispatch({ type: ACTIONS.REMOVE_EXERCISE, payload: { id: exerciseId } });
  };

  return (
    <section className="videos">
      <h3 className="videos__title">
        {t('createActivePause.selectedExercisesTitle')} ({videoQuantity})
      </h3>
      <ul className="videos__list">
        {exerciseIds.map((exercise) => {
          const { id, image, title } = exercise;
          return (
            <li key={id} className="videos__list_card">
              <img src={image} alt={title} className="videos__list_img" />
              <h3 className="videos__list_title">{title}</h3>
              <AiOutlineClose className="videos__list_close" onClick={() => handleRemoveExercise(id)} />
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default VideoList;

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import './FormComponents.scss';

const TextArea = ({ label, gif, name, isRequired, ...rest }) => (
  <div className="question">
    <div className="question__validation">
      <label htmlFor={name}>{label}</label>
    </div>
    {!!gif && (
      <div className="question__gif">
        <img src={gif} alt="this is a Gif" />
      </div>
    )}
    <Field {...rest} rows={5} as="textarea" id={name} name={name} className="input-area" />
  </div>
);

TextArea.defaultProps = {
  gif: '',
};

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  gif: PropTypes.string,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
};

export default TextArea;

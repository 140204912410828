import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { CREATE_UPDATE_COMPANY } from 'apollo/mutations';
import { ROUTES } from 'utils/constants';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';

export const useCreateUpdateCompany = (companyId = '') => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [createUpdateCompany] = useMutation(CREATE_UPDATE_COMPANY);
  const [spinner, setSpinner] = useToggle();

  const onCreateUpdateCompany = async (values) => {
    setSpinner();

    const response = await createUpdateCompany({
      variables: {
        ...values,
        ...(!!companyId && {
          companyId,
        }),
      },
    });

    const errors = pathOr([], ['data', 'createUpdateCompany', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors
        ? [companyId ? t('notification.company.update') : t('notification.company.create')]
        : errors,
    });

    if (emptyErrors) {
      push(ROUTES.COMPANIES_LIST);
    }
  };

  return {
    spinner,
    onCreateUpdateCompany,
  };
};

import React from 'react';

import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import Conversations from 'components/conversations/Conversations';

import './chat.scss';

const Chat = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);

  return (
    <section className="d-flex">
      <LeftMenu />
      <div className="dashboard__main">
        <Header title="Chat" shadow />
        <div className="chat">
          <Conversations />
        </div>
      </div>
    </section>
  );
};

export default Chat;

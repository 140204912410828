import React from 'react';
import { Field } from 'formik';
import { v4 as uuid } from 'uuid';

const RadioButtons = (props) => {
  const { label, gif, name, options, isRequired, handleNext, ...rest } = { ...props };

  const mapOptions = options.map((item) => {
    if (item.value) {
      return item;
    }
    return { value: item, label: item };
  });
  return (
    <div className="question">
      <div className="question__validation">
        <label htmlFor={name}>{label}</label>
      </div>
      {!!gif && (
        <div className="question__gif">
          <img src={gif} alt="this is a Gif" />
        </div>
      )}
      <Field id={name} name={name} {...rest}>
        {({ field }) =>
          mapOptions.map((option) => (
            <label key={uuid()} className={`radio radio--${field.value === option.label ? 'checked' : ''}`}>
              {option.label}
              <input
                type="radio"
                id={option.label}
                {...field}
                value={option.label}
                checked={field.value === option.label}
                onClick={handleNext}
              />
            </label>
          ))
        }
      </Field>
    </div>
  );
};

export default RadioButtons;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { FaCalendarAlt, FaPhotoVideo, FaUserInjured, FaColumns } from 'react-icons/fa';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import { useToggle } from 'utils/hooks/useToggle';
import { ROUTES } from 'utils/constants';

import healtphyLogo from 'assets/images/logo-white.svg';

const SpecialistLeftMenu = () => {
  const {
    push: changeRoute,
    location: { pathname },
  } = useHistory();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useToggle();

  const onChangeRoute = ({ route }) => {
    changeRoute(route);
  };

  const iconStyle = { width: '24px', height: '24px', marginTop: expanded ? '-4px' : '0px' };

  return (
    <div className="toggle-menu" style={{ width: expanded ? '280px' : '64px' }}>
      {!expanded && <ReactTooltip place="right" effect="solid" id="menu" />}
      <SideNav onToggle={(toggle) => setExpanded(toggle)}>
        <NavItem className="left-menu__logo__item">
          <div
            className="left-menu__logo__container"
            style={{ width: expanded ? '80px' : '54px', height: expanded ? '80px' : '54px' }}
          >
            <img
              src={healtphyLogo}
              alt="logo"
              className="left-menu__logo"
              style={{ width: expanded ? '80px' : '44px' }}
            />
          </div>
        </NavItem>
        <SideNav.Toggle />
        <SideNav.Nav defaultSelected={ROUTES.PATIENTS} selected={pathname}>
          <NavItem
            data-tip={t('leftMenuHeader.patients')}
            data-for="menu"
            onClick={() => onChangeRoute({ route: ROUTES.PATIENTS })}
            active={
              pathname === ROUTES.PATIENTS || pathname.startsWith(ROUTES.PATIENT) || pathname === ROUTES.CREATE_PATIENT
            }
          >
            <NavIcon>
              <FaUserInjured style={iconStyle} />
            </NavIcon>
            <NavText>{t('leftMenuHeader.patients')}</NavText>
          </NavItem>
          <NavItem
            data-tip={t('leftMenuHeader.library')}
            data-for="menu"
            onClick={() => onChangeRoute({ route: ROUTES.LIBRARY })}
            active={pathname === ROUTES.LIBRARY || pathname.startsWith(ROUTES.LIBRARY)}
          >
            <NavIcon>
              <FaPhotoVideo style={iconStyle} />
            </NavIcon>
            <NavText>{t('leftMenuHeader.library')}</NavText>
          </NavItem>
          <NavItem
            data-tip={t('leftMenuHeader.calendar')}
            data-for="menu"
            onClick={() => onChangeRoute({ route: ROUTES.CALENDAR })}
            active={pathname === ROUTES.CALENDAR || pathname.startsWith(ROUTES.CALENDAR)}
          >
            <NavIcon>
              <FaCalendarAlt style={iconStyle} />
            </NavIcon>
            <NavText>{t('leftMenuHeader.calendar')}</NavText>
          </NavItem>
          <NavItem
            data-tip={t('leftMenuHeader.forms')}
            data-for="menu"
            onClick={() => onChangeRoute({ route: ROUTES.FORMS })}
            active={pathname === ROUTES.FORMS || pathname.startsWith(ROUTES.FORMS) || pathname === ROUTES.CREATE_FORM}
          >
            <NavIcon>
              <FaColumns style={iconStyle} />
            </NavIcon>
            <NavText>{t('leftMenuHeader.forms')}</NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    </div>
  );
};

export default SpecialistLeftMenu;

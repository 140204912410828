import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

const StoreContext = React.createContext();

const initialState = {
  patient: {
    email: '',
    name: '',
    gender: 'Hombre',
    birthday: '',
    cardId: '',
    formId: '',
  },
  assessment: {
    diagnostic: [''],
    /* objective: '',
    painLocalization: '',
    exerciseLevel: '',
    duration: '',
    functionality: '',
    painLevel: '1',
    medicalHistory: '', */
    observations: '',
  },
  weeks: [],
  step: '',
  currentTab: 0,
  week: undefined,
  customPlan: { title: '', description: '', bodyParts: '', planType: '' },
  openCall: { isOpen: false, sessionId: '', token: '' },
};

const StoreProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const [inviteCreateMember, setInviteCreateMember] = useState(false);

  const { patient, assessment, step, currentTab, weeks, week, customPlan, openCall } = state;
  const isOpenCall = openCall.isOpen;

  const setStoreData = (name, data) => {
    if (name === 'initialState') {
      setState(isOpenCall ? state : initialState);
    } else {
      setState((currentState) => ({ ...currentState, [name]: data }));
    }
  };

  const toggleInviteCreateMember = () => setInviteCreateMember((prev) => !prev);

  return (
    <StoreContext.Provider
      value={{
        patient,
        assessment,
        step,
        currentTab,
        weeks,
        week,
        customPlan,
        openCall,
        setStoreData,
        inviteCreateMember,
        toggleInviteCreateMember,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

const StoreConsumer = StoreContext.Consumer;

const useAppState = () => {
  const ctxValue = useContext(StoreContext);
  if (ctxValue === undefined)
    throw new Error('Expected an StoreContextProvider somewhere in the react tree to set context value');
  return ctxValue;
};

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StoreProvider;
export { StoreConsumer, useAppState };

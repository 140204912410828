import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'utils/constants';
import { destroyChat } from 'utils/isChat';
import { isClinic } from 'utils/isClinic';
import { logout } from 'libs/authenticationService';

import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';

import trialExpired from 'assets/images/trial-expired.svg';

const modalProps = {
  content: {
    width: '80vw',
    maxWidth: '940px',
    height: '80vh',
    maxHeight: '640px',
    outline: 'none',
    padding: '10px !important',
  },
};

const TrialModal = ({ open }) => {
  const { t } = useTranslation();
  const { replace } = useHistory();
  const IS_CLINIC = isClinic();

  const onBilling = () => replace(ROUTES.BILLING);

  const onLogout = () => {
    if (IS_CLINIC) {
      destroyChat();
    }
    logout();
    replace(ROUTES.HOME);
    localStorage.clear();
  };

  return (
    <Modal modalIsOpen={open} closeModal={() => {}} modalProps={modalProps} closeable={false}>
      <div className="signup__intro">
        <div className="right">
          <img src={trialExpired} alt="intro" className="intro__image" />
        </div>
        <div className="left">
          <div>
            <p className="title">{t('trial.ended.title')}</p>
            <p className="message">{t('trial.ended.message')}</p>
            <div className="left__buttons">
              <SolidButton onClick={onBilling} className="mr-2">
                {t('trial.ended.button')}
              </SolidButton>
              <SolidButton onClick={onLogout} className="secondary">
                {t('button.signout')}
              </SolidButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TrialModal;

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { customStyles } from 'utils/styles';

import SearchFilter from 'components/SearchFilter';

const Filters = ({ searchInvoices, month, year, setMonth, setYear }) => {
  const { t } = useTranslation();

  const monthOptions = [...Array(12)].map((_, index) => {
    const getMonthValue = `0${index + 1}`.slice(-2);
    return { value: `0${index + 1}`.slice(-2), label: t(`invoices.filters.months.${getMonthValue}`) };
  });

  const yearOptions = [...Array(3)].map((_, index) => {
    const getCurrentYear = new Date().getFullYear();
    const yearValue = `${getCurrentYear - index}`;
    return { value: yearValue, label: yearValue };
  });

  return (
    <div className="invoices__filters">
      <label className="invoices__filters_label">
        {t('formLabel.month')}
        <Select value={month} options={monthOptions} styles={customStyles} onChange={setMonth} />
      </label>
      <label className="invoices__filters_label">
        {t('formLabel.year')}
        <Select value={year} options={yearOptions} styles={customStyles} onChange={setYear} />
      </label>
      <SearchFilter searchItems={searchInvoices} placeholder={t('searchLabel.invoices')} />
    </div>
  );
};

Filters.propTypes = {
  searchInvoices: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  month: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  year: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  setMonth: PropTypes.func.isRequired,
  setYear: PropTypes.func.isRequired,
};

export default Filters;

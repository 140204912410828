import { useEffect } from 'react';

import { GET_ACTIVE_BREAK } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetActivePause = (activeBreakId) => {
  const {
    loading = false,
    data = {},
    refetch = () => {},
  } = activeBreakId
    ? useAuthQuery(GET_ACTIVE_BREAK, {
        variables: { activeBreakId },
      })
    : {};
  const { activeBreak = {} } = data;

  useEffect(() => {
    refetch();
  }, []);

  return {
    isLoading: loading,
    activePause: activeBreak,
  };
};

import React from 'react';
import './followup-survey-to-print.scss';

const formatDate = (value) => {
  if (!value) return '---';
  let d = new Date(value),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};

const mapValue = (value, type) => {
  switch (type) {
    case 'verification':
      return !!value ? 'Verdadero' : 'Falso';
    case 'date':
      return formatDate(value);
    default:
      return !!value ? value : '---';
  }
};

const buildCustomData = ({ painLevel, pillsLevel, urinaryDisfunction, functionality, sleepQuality }) => {
  return [
    { value: functionality, type: 'multiple-selection', label: 'Funcionalidad: ¿Cuáles de estas actividades no es capaz de realizar?' },
    { value: pillsLevel, label: 'Uso de medicamentos para disminuir el dolor en la última semana' },
    { value: painLevel, label: '¿De uno a 10 cómo califica hoy el dolor?' },
    { value: sleepQuality, label: '¿Cómo es la calidad subjetiva del sueño en la última semana?' },
    { value: urinaryDisfunction, label: 'Nivel del dolor' },
  ];
};

class FollowupSurveyToPrint extends React.Component {
  render() {
    const { data } = this.props;
    const { content: followupSurveyForm = [], answerFiles = [], form = {}, observations, owner = '' } = { ...data };
    const { title: surveyTitle = '' } = { ...form };
    const content = !!followupSurveyForm && followupSurveyForm.length > 0 ? followupSurveyForm : buildCustomData(data);

    return (
      <div className={'download-pdf'}>
        <p className={'download-pdf__title'}>
          {!!surveyTitle ? surveyTitle : 'Detalles del seguimiento por semana'}
          {!!owner ? ` - ${owner}` : ''}
        </p>
        <div className={'download-pdf__info'}>
          {content.map((field, index) => {
            const { label = '', value = '', type = 'text' } = { ...field };
            return (
              <div className={'download-pdf__info__item'} key={index}>
                <p className='label'>{label}</p>
                {type === 'multiple-selection' ? (
                  <div className={'tags'}>
                    {`${value}`.split(', ').map((item, index) => {
                      const isItem = !!item;
                      return (
                        <span className={`${isItem ? 'tag' : 'value'}`} key={index}>
                          {isItem ? item : '---'}
                        </span>  
                      );
                    })}
                  </div>
                ) : (
                  <p className={'value'}>{mapValue(value, type)}</p>
                )}
              </div>
            );
          })}
          {answerFiles.map((answer, index) => {
            const { id: fileId = '', label = '', file = {} } = { ...answer };
            const { original: fileUrl = '' } = { ...file };
            return (
              <div key={`${index}-${fileId}`} className={'download-pdf__info__item'}>
                <p className={'label'}>{label}</p>
                <a 
                  className={'value-link'}
                  href={fileUrl}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  Link archivo
                </a>
              </div>
            );
          })}
          {observations && (
            <div className={'item'}>
              <p className={'label'}>Observaciones</p>
              <p className={'value bigtext'}>{observations}</p>
            </div>
          )}
        </div>
      </div> 
    );
  }
}

export default FollowupSurveyToPrint;

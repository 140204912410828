import { ACTIONS } from './constants';

export function evieProgramReducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_PROGRAM:
      return { ...state, ...action.payload };
    case ACTIONS.SET_STEP:
      return { ...state, step: action.payload };
    case ACTIONS.ADD_DETAIL:
      return { ...state, detail: { ...state.detail, ...action.payload } };
    case ACTIONS.ADD_LEVEL:
      return { ...state, levels: [...state.levels, action.payload] };
    case ACTIONS.SELECT_LEVEL:
      return { ...state, selectedLevel: action.payload };
    case ACTIONS.REMOVE_LEVEL:
      return { ...state, levels: state.levels.filter(({ id }) => id !== action.payload) };
    case ACTIONS.ADD_LEVEL_INFO:
      return {
        ...state,
        levels: state.levels.map((level) => {
          if (level.id === action.payload.id) {
            return { ...level, ...action.payload.values };
          }
          return level;
        }),
      };
    case ACTIONS.ADD_ADDITIONAL_CONTENT:
      return {
        ...state,
        levels: state.levels.map((level) => {
          if (level.id === action.payload.levelId) {
            return { ...level, additionalContent: [...level.additionalContent, action.payload.selectedItem] };
          }
          return level;
        }),
      };
    case ACTIONS.REMOVE_ADDITIONAL_CONTENT:
      return {
        ...state,
        levels: state.levels.map((level) => {
          if (level.id === action.payload.levelId) {
            return {
              ...level,
              additionalContent: level.additionalContent.filter(
                ({ temporalId }) => temporalId !== action.payload.itemId,
              ),
            };
          }
          return level;
        }),
      };
    case ACTIONS.DRAG_AND_DROP_ADDITIONAL_CONTENT:
      return {
        ...state,
        levels: state.levels.map((level) => {
          if (level.id === action.payload.levelId) {
            return {
              ...level,
              additionalContent: action.payload.newAdditionalContent,
            };
          }
          return level;
        }),
      };
    case ACTIONS.ADD_SESSION:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: [...state.selectedLevel.programSessions, action.payload],
        },
      };
    case ACTIONS.REMOVE_SESSION:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.filter(({ id }) => id !== action.payload),
        },
      };
    case ACTIONS.ADD_EXERCISE_SESSION:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === action.payload.id) {
              return { ...session, sessionExercises: [...session.sessionExercises, action.payload.exerciseSession] };
            }
            return session;
          }),
        },
      };
    case ACTIONS.REMOVE_EXERCISE_SESSION:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === action.payload.sessionId) {
              return {
                ...session,
                sessionExercises: session.sessionExercises.filter(({ id }) => id !== action.payload.itemId),
              };
            }
            return session;
          }),
        },
      };
    case ACTIONS.ADD_EXERCISE_SESSION_INFO:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === action.payload.sessionId) {
              return {
                ...session,
                sessionExercises: session.sessionExercises.map((exSession) => {
                  if (exSession.id === action.payload.itemId) {
                    return { ...exSession, ...action.payload.values };
                  }
                  return exSession;
                }),
              };
            }
            return session;
          }),
        },
      };
    case ACTIONS.ADD_PROGRAM_SESSIONS:
      return {
        ...state,
        levels: state.levels.map((level) => {
          if (level.id === action.payload.id) {
            return { ...level, ...action.payload };
          }
          return level;
        }),
      };
    case ACTIONS.SELECT_EXERCISE_SESSION:
      return {
        ...state,
        selectedExerciseSession: action.payload,
      };
    case ACTIONS.ADD_ITEM:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === state.selectedExerciseSession.sessionId) {
              return {
                ...session,
                [action.payload.sessionItems]: session[action.payload.sessionItems].map((sessionEx) => {
                  if (sessionEx.id === state.selectedExerciseSession.exerciseSessionId) {
                    return {
                      ...sessionEx,
                      [action.payload.items]: [...sessionEx[action.payload.items], action.payload.selectedItem],
                    };
                  }
                  return sessionEx;
                }),
              };
            }
            return session;
          }),
        },
      };
    case ACTIONS.REMOVE_ITEM:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === state.selectedExerciseSession.sessionId) {
              return {
                ...session,
                [action.payload.sessionItems]: session[action.payload.sessionItems].map((sessionEx) => {
                  if (sessionEx.id === state.selectedExerciseSession.exerciseSessionId) {
                    return {
                      ...sessionEx,
                      [action.payload.items]: sessionEx[action.payload.items].filter(
                        ({ temporalId }) => temporalId !== action.payload.id,
                      ),
                    };
                  }
                  return sessionEx;
                }),
              };
            }
            return session;
          }),
        },
      };
    case ACTIONS.DRAG_AND_DROP_ITEM:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === state.selectedExerciseSession.sessionId) {
              return {
                ...session,
                [action.payload.sessionItems]: session[action.payload.sessionItems].map((sessionEx) => {
                  if (sessionEx.id === state.selectedExerciseSession.exerciseSessionId) {
                    return {
                      ...sessionEx,
                      [action.payload.items]: action.payload.newOrderedItems,
                    };
                  }
                  return sessionEx;
                }),
              };
            }
            return session;
          }),
        },
      };
    case ACTIONS.CLONE_LEVEL:
      return { ...state, levels: action.payload.newLevels };
    case ACTIONS.CLONE_SESSION:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: action.payload.newSessions,
        },
      };
    case ACTIONS.CLONE_EXERCISE_SESSION:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === action.payload.sessionId) {
              return { ...session, sessionExercises: action.payload.newItems };
            }
            return session;
          }),
        },
      };
    case ACTIONS.DRAG_AND_DROP_EXERCISE_SESSION:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === action.payload.sessionId) {
              return { ...session, sessionExercises: action.payload.newSessionExercises };
            }
            return session;
          }),
        },
      };
    case ACTIONS.DRAG_AND_DROP_SESSION:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: action.payload.newSessions,
        },
      };
    case ACTIONS.DRAG_AND_DROP_LEVEL:
      return { ...state, levels: action.payload.newLevels };
    case ACTIONS.ADD_SQUEEZE_SESSION:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === action.payload.id) {
              return { ...session, sessionSqueezes: [...session.sessionSqueezes, action.payload.squeezeSession] };
            }
            return session;
          }),
        },
      };
    case ACTIONS.DRAG_AND_DROP_SQUEEZE_SESSION:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === action.payload.sessionId) {
              return { ...session, sessionSqueezes: action.payload.newTaskExercises };
            }
            return session;
          }),
        },
      };
    case ACTIONS.REMOVE_SQUEEZE_SESSION:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === action.payload.sessionId) {
              return {
                ...session,
                sessionSqueezes: session.sessionSqueezes.filter(({ id }) => id !== action.payload.itemId),
              };
            }
            return session;
          }),
        },
      };
    case ACTIONS.ADD_SQUEEZE_SESSION_INFO:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === action.payload.sessionId) {
              return {
                ...session,
                sessionSqueezes: session.sessionSqueezes.map((sqSession) => {
                  if (sqSession.id === action.payload.itemId) {
                    return { ...sqSession, ...action.payload.values };
                  }
                  return sqSession;
                }),
              };
            }
            return session;
          }),
        },
      };
    case ACTIONS.CLONE_SQUEEZE_SESSION:
      return {
        ...state,
        selectedLevel: {
          ...state.selectedLevel,
          programSessions: state.selectedLevel.programSessions.map((session) => {
            if (session.id === action.payload.sessionId) {
              return { ...session, sessionSqueezes: action.payload.newItems };
            }
            return session;
          }),
        },
      };
    default:
      throw new Error(`Unhandled action ${action.type}`);
  }
}

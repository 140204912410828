import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { isEmpty, pathOr } from 'ramda';

import { CREATE_UPDATE_SHORT_VIDEO } from 'apollo/mutations';
import { ROUTES } from 'utils/constants';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';

export const useCreateUpdShortVideo = (shortVideoId) => {
  const { push } = useHistory();
  const [createUpdateShortVideo] = useMutation(CREATE_UPDATE_SHORT_VIDEO);
  const [spinner, setSpinner] = useToggle();

  const handleCreateUpdShortVideo = async (values) => {
    const { title, description, file, fileUs, thumbnailImage, posterImage } = values;
    setSpinner();
    const response = await createUpdateShortVideo({
      variables: {
        title,
        description,
        ...(typeof file === 'object' && {
          file,
        }),
        ...(typeof fileUs === 'object' && {
          fileUs,
        }),
        ...(typeof thumbnailImage === 'object' && {
          thumbnailImage,
        }),
        ...(typeof posterImage === 'object' && {
          posterImage,
        }),
        ...(!!shortVideoId && {
          shortVideoId,
        }),
      },
    });

    const errors = pathOr([], ['data', 'createUpdateShortVideo', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha ${shortVideoId ? 'actualizado' : 'creado'} el video exitosamente.`] : errors,
    });

    if (emptyErrors) {
      push(ROUTES.SHORT_VIDEOS);
    }
  };

  return { createUpdShortVideo: handleCreateUpdShortVideo, spinner };
};

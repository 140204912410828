import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RiNewspaperLine } from 'react-icons/ri';
import { AiOutlineAudio, AiOutlineVideoCamera } from 'react-icons/ai';

import { useEvieProgramServiceContext } from 'components/eviePrograms/EvieProgramServiceProvider';

import ItemsHeader from '../ItemsHeader';
import ItemList from '../ItemList/ItemList';

const EvieAddContent = ({ index, addContent, additionalContent, selectedItems }) => {
  const { t } = useTranslation();
  const { readings, audios, shortVideos } = useEvieProgramServiceContext();

  const readingsHeader = {
    label: t('eviePrograms.itemsHeader.readings.label'),
    description: t('eviePrograms.itemsHeader.readings.description'),
    icon: <RiNewspaperLine />,
  };

  const audiosHeader = {
    label: t('eviePrograms.itemsHeader.audios.label'),
    description: t('eviePrograms.itemsHeader.audios.description'),
    icon: <AiOutlineAudio />,
  };

  const shortVideosHeader = {
    label: t('eviePrograms.itemsHeader.shortVideos.label'),
    description: t('eviePrograms.itemsHeader.shortVideos.description'),
    icon: <AiOutlineVideoCamera />,
  };

  return (
    <>
      <h3 className="evie-program__title-level">
        {t('eviePrograms.additionalContent.titleLevel')} {index + 1}
      </h3>
      <div className="evie-program__add-content">
        <section>
          <ItemsHeader {...readingsHeader} />
          <ItemList items={readings} addContent={addContent} selectedContent={additionalContent} />
          <ItemsHeader {...audiosHeader} />
          <ItemList items={audios} addContent={addContent} selectedContent={additionalContent} />
          <ItemsHeader {...shortVideosHeader} />
          <ItemList items={shortVideos} addContent={addContent} selectedContent={additionalContent} />
        </section>
        {selectedItems}
      </div>
    </>
  );
};

EvieAddContent.propTypes = {
  index: PropTypes.number.isRequired,
  addContent: PropTypes.func.isRequired,
  additionalContent: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedItems: PropTypes.element.isRequired,
};

export default EvieAddContent;

import { BODY_PARTS } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';

export const useGetBodyParts = () => {
  const { data } = useAuthQuery(BODY_PARTS);
  const { bodyParts = [] } = { ...data };

  const formatData = bodyParts.map(({ title = '' }) => title);

  return { bodyParts: formatData };
};

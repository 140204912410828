import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaChevronRight } from 'react-icons/fa';

const ExerciseCard = ({ exercise, exerciseIds, handleAddExercise, handleDisplayExercise }) => {
  const { t } = useTranslation();
  const { id, image, title } = exercise;
  const isSelected = [...exerciseIds.map(({ id: exeId }) => exeId)].includes(id);

  return (
    <li className="exercises-list__card">
      <img src={image} alt={title} className="exercises-list__card_image" />
      <button
        type="button"
        className={`card-mask ${isSelected ? 'card-mask--selected' : ''}`}
        onClick={() => handleAddExercise({ id, image, title })}
      >
        <span className="card-mask_label">{t('button.add')}</span>
      </button>
      <div className="exercise-title">
        <h3>{title}</h3>
        <FaChevronRight className="exercise-title_icon" onClick={() => handleDisplayExercise(exercise)} />
      </div>
    </li>
  );
};

ExerciseCard.propTypes = {
  exercise: PropTypes.shape({
    id: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  exerciseIds: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  handleAddExercise: PropTypes.func.isRequired,
  handleDisplayExercise: PropTypes.func.isRequired,
};

export default ExerciseCard;

import React, { useState } from 'react';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { useCreateAvailabilityContext } from 'components/availability/CreateAvailabilityProvider';
import { customStyles } from 'utils/styles';

import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: '514px',
    padding: 20,
  },
};

const intervalsOptions = [...Array(24)].map((_, index) => ({
  value: `0${index}:00`.slice(-5),
  label: `0${index}:00`.slice(-5),
}));

const AddIntervalsModal = () => {
  const { t } = useTranslation();
  const { isOpenAddIntervalsModal, setIsOpenAddIntervalsModal, handleAddInterval } = useCreateAvailabilityContext();
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const endOptions = intervalsOptions.filter(({ value }) => parseInt(value, 16) > parseInt(start, 16));
  const isDisabled = isEmpty(start) || isEmpty(end) || parseInt(end, 16) <= parseInt(start, 16);

  const handleAddStart = ({ value }) => {
    setStart(value);
  };
  const handleAddEnd = ({ value }) => {
    setEnd(value);
  };

  return (
    <Modal
      modalIsOpen={isOpenAddIntervalsModal}
      closeModal={setIsOpenAddIntervalsModal}
      closeable={false}
      modalProps={modalProps}
    >
      <section className="availability__addintervals">
        <label className="availability__addintervals_label">
          {t('formLabel.from')}
          <Select options={intervalsOptions} styles={customStyles} onChange={handleAddStart} />
        </label>
        <label className="availability__addintervals_label">
          {t('formLabel.to')}
          <Select options={endOptions} styles={customStyles} onChange={handleAddEnd} />
        </label>
        <div className="availability__addintervals_buttons">
          <SolidButton className="secondary mr-2" onClick={setIsOpenAddIntervalsModal}>
            {t('button.cancel')}
          </SolidButton>
          <SolidButton
            disabled={isDisabled}
            className={isDisabled ? 'disabled' : ''}
            onClick={() => handleAddInterval({ start_at: start, end_at: end })}
          >
            {t('button.add')}
          </SolidButton>
        </div>
      </section>
    </Modal>
  );
};

export default AddIntervalsModal;

import React from 'react';
import { X } from 'react-feather';
import VideoPlayer from '../VideoPlayer';
import './modalExercise.scss';

const ModalExercise = ({selectedExercise, handleSelect}) => {
  const { tips, video, reps, rest, sets, instructions, title: selectedTitle = '' } = { ...selectedExercise };
  return (
    <div className={'modal-exercise'}>
      <VideoPlayer source={video} style={{ width: '47%', height: '60%', alignSelf: 'center', marginLeft: 40 }}/>
      <div className={'modal-exercise__data'}>
        <div className={'info-row'}>
          <p className={'info-title'}>{selectedTitle}</p>
          <div className={'close-icon-container'}>
            <X name='close' className={'close-icon'} onClick={handleSelect}/>
          </div>
        </div>
        <div className={'info-row'}>
          <div className={'info-col'}>
            <p className={'label'}>Repeticiones</p>
            <p className={'value'}>{reps}</p>
          </div>
          <div className={'info-col'}>
            <p className={'label'}>Series</p>
            <p className={'value'}>{sets}</p>
          </div>
          <div className={'info-col'}>
            <p className={'label'}>Descanso</p>
            <p className={'value'}>{rest}</p>
          </div>
        </div>
        <div className={'info-row instructions'}>
          <p className={'label'}>Instrucciones</p>
          <ol>
            {`${instructions}`
              .split('- ')
              .filter((el) => !!el)
              .map((item, index) => (
                <li className={'value'} key={index}>
                  {item}
                </li>
              ))}
          </ol>
          {tips && (
            <>
              <p className={'label'}>Consejos</p>
              <ol>
                {`${tips}`
                  .split('- ')
                  .filter((el) => !!el)
                  .map((item, index) => (
                    <li className={'value'} key={index}>
                      {item}
                    </li>
                  ))}
              </ol>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalExercise;
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiShowAlt } from 'react-icons/bi';

import Icon from 'components/Icon';
import Thump from 'components/Thumb';

const RewardsList = ({ rewards, handleSelectProduct, showProductDetailModal, showAddProductModal }) => {
  const { t } = useTranslation();
  return (
    <>
      {(rewards || []).map((reward) => {
        const { id = '', name = '', status = '', quantity = '', avatar = {} } = { ...reward };
        const { original = '' } = { ...avatar };
        const IS_UNACTIVE = status === 'unactive';
        return (
          <tr key={id}>
            <td style={{ width: '10%' }}>
              <Thump source={original} alt={name} />
            </td>
            <td style={{ width: '30%' }}>{name}</td>
            <td style={{ width: '20%' }} className="status-reward">
              <span className={IS_UNACTIVE ? 'unactive' : ''}>{status}</span>
            </td>
            <td style={{ width: '25%' }} className="quantity-reward">
              <span>{quantity}</span>
              {t('rewards.available')}
            </td>
            <td style={{ width: '15%' }}>
              <BiShowAlt className="reward-show" onClick={() => handleSelectProduct(reward, showProductDetailModal)} />
              <Icon
                name="edit"
                className="reward-edit"
                onClick={() => handleSelectProduct(reward, showAddProductModal)}
              />
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default RewardsList;

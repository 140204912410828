import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const BlockTextPreview = ({ block }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { style, content } = block;
  return <p className={`readingPreview__info_content ${style ? 'bold-block' : ''}`}>{content[language]}</p>;
};

BlockTextPreview.propTypes = {
  block: PropTypes.shape({
    style: PropTypes.string,
    content: PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
    }),
  }).isRequired,
};

import React, { useEffect } from 'react';
import { isEmpty } from 'ramda';
import { v4 as uuid } from 'uuid';

import { useConversationsContext } from 'components/conversations/ConversationsProvider';
import { useGetMessages } from '../hooks';

import ConversationHeader from '../ConversationHeader';
import SendMessageForm from '../SendMessageForm';
import MessageBubble from '../MessageBubble';

const SelectedConversation = () => {
  const { selectedConversation } = useConversationsContext();
  const { messages, setMessages, loadMessagesFor } = useGetMessages();

  const messageAdded = (message) => {
    setMessages((prev) => [...prev, message]);
  };

  useEffect(() => {
    if (!isEmpty(selectedConversation)) {
      loadMessagesFor(selectedConversation);
      selectedConversation.on('messageAdded', (message) => messageAdded(message));
    }
  }, [selectedConversation]);

  return (
    <section className="conversations__messages">
      {!isEmpty(selectedConversation) && (
        <div className="conversations__messages_title">
          <ConversationHeader />
        </div>
      )}
      {isEmpty(selectedConversation) ? (
        <div className="conversations__messages_empty-list">Please select a conversation!</div>
      ) : (
        <div className="conversations__messages_list">
          <ul className="messages">
            {messages.map((message) => (
              <MessageBubble key={uuid()} message={message} />
            ))}
          </ul>
        </div>
      )}
      <div className="conversations__messages_input">
        <SendMessageForm />
      </div>
    </section>
  );
};

export default SelectedConversation;

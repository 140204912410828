import React from 'react';
import { propOr } from 'ramda';
import { getIn } from 'formik';

const RangeInput = ({ label, labelClassName, isRequired, includeNA, field, orientation, form, ...rest }) => {
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error;
  const { min = 0, max = 10 } = { ...rest };
  const length = max - min + 1;
  let range = [...new Array(length)].map((item, index) => index + min);
  if (includeNA && min > 0) {
    range = [0, ...range];
  }
  return (
    <div className='form__input-container'>
      {label && (
        <label htmlFor={field.name} className={`form__label ${labelClassName}`}>
          {isRequired ? `${label} *` : label}
        </label>
      )}

      <div className={'range-container'}>
        {(range || []).map((item) => {
          return (
            <div
              key={item}
              className={`range-item ${field.value === `${item}` && 'active'}`}
              onClick={() => {
                form.setFieldValue(field.name, `${item}`);
              }}>
              {item === 0 ? 'N/A' : item}
            </div>
          );
        })}
      </div>
      {hasError && <div style={{fontSize: 13}} className="form__error">{error}</div>}
    </div>
  );
};

export default RangeInput;

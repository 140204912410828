import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import Button from 'components/SolidButton';

const AlertModal = ({ open, onCancelAction, onConfirmAction, title, description, labelConfirm, category }) => {
  const { t } = useTranslation();
  return (
    <Modal modalIsOpen={open} closeModal={onCancelAction} closeable={false}>
      <div>
        <h3 className="modal__title">{title}</h3>
        <p className="modal__subtitle">{description}</p>
        <div className="d-flex justify-content-center">
          <Button className="secondary mr-2" onClick={onCancelAction}>
            {t('button.cancel')}
          </Button>
          <Button onClick={onConfirmAction} className={category}>
            {labelConfirm}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AlertModal.defaultProps = {
  category: '',
};

AlertModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancelAction: PropTypes.func.isRequired,
  onConfirmAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  labelConfirm: PropTypes.string.isRequired,
  category: PropTypes.string,
};

export default AlertModal;

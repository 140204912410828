import React, { useEffect } from 'react';
import { propOr } from 'ramda';
import { getIn } from 'formik';
import Select from 'react-select';
import { useAuthQuery } from '../../utils/hooks';
import { GET_COVENANTS } from '../../apollo/queries';

const dot = (color = '#fff') => ({
  alignItems: 'center',
  display: 'flex',
  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 20,
    width: 20,
    border: '1px solid',
    borderColor: color === '#fff' ? '#818181' : '#fff',
  },
});

export const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
  }),
  option: (provided, state) => {
    const { data } = { ...state };
    const { color } = { ...data };
    return {
      ...provided,
      backgroundColor: state.isSelected ? '#e8ebfa' : '#fff',
      color: '#818181',
      ':hover': {
        backgroundColor: '#e8ebfa',
        color: '#818181',
        cursor: 'pointer',
        marginLeft: '-2px',
      },
      ...dot(color),
    };
  },
  singleValue: (provided, state) => {
    const { data } = { ...state };
    const { color } = { ...data };
    return {
      ...provided,
      color: '#818181',
      fontWeight: '300',
      ...dot(color),
    };
  },
};

const CovenantSelect = (props) => {
  const { field, form, label, isRequired, labelClassName, className, placeholder, onChange: onChangeValue, disabled } = {
    ...props,
  };
  const { data, refetch } = useAuthQuery(GET_COVENANTS);
  const covenants = propOr([], 'covenants')(data);
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error;
  const invalidClass = hasError ? 'is-invalid' : '';

  const options = [
    { value: '', label: 'Ninguno', color: '#fff' },
    ...covenants
      .filter(({ active }) => active)
      .map((covenant) => {
        const { name: label, label: color, id: value } = { ...covenant };
        return {
          value,
          label,
          color,
        };
      }),
  ];
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (option) => {
    !!onChangeValue &&
      onChangeValue({
        target: {
          name,
          value: option.value,
        },
      });
    form.setFieldValue(name, option.value);
  };

  const getValue = () => {
    if (covenants) {
      return options.find((option) => option.value === field.value);
    } else {
      return '';
    }
  };

  return (
    <div className='form__input-container form__input-container-select'>
      {label && (
        <label htmlFor={name} className={`form__label ${labelClassName}`}>
          {label} {isRequired && <span className='text-pink'>*</span>}
        </label>
      )}
      <Select
        id={name}
        name={name}
        isMulti={false}
        className={`form__input-select ${className} ${invalidClass}`}
        options={options}
        value={getValue()}
        onChange={onChange}
        styles={customStyles}
        placeholder={placeholder}
        isDisabled={disabled}
      />
      {hasError && <span className='form__error form__error--select'>{error}</span>}
    </div>
  );
};

export default CovenantSelect;

import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { getAuthInfo } from '../libs/authenticationService';

const envs = {
  production: 'https://healthphy-api-staging.herokuapp.com/graphql',
  development: 'https://healthphy-api-dev.herokuapp.com/graphql',
};

const uploadLink = createUploadLink({
  uri: envs[process.env.REACT_APP_ENV] || envs.development, // Apollo Server is served from port 4000
  headers: {
    'keep-alive': 'true',
  },
});

const apolloCache = new InMemoryCache();

const requestHandler = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => {
          const { token, user } = getAuthInfo();
          const { userType: role = '' } = { ...user };
          const customHeaders = oper.getContext().hasOwnProperty('headers') ? oper.getContext().headers : {};
          oper.setContext({
            headers: {
              authorization: token ? `Bearer ${token}` : '',
              role,
              ...customHeaders,
            },
          });
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    }),
);

const errorHandler = onError((error) => {
  const { networkError, graphQLErrors } = error;
  if (networkError || graphQLErrors) {
    console.error(error);
  }
});

const link = ApolloLink.from([errorHandler, requestHandler, uploadLink].filter((x) => !!x));

export default new ApolloClient({
  link,
  cache: apolloCache,
});

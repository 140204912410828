import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { Formik, Field, Form } from 'formik';

import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import { queryParams } from 'utils/string';
import { createCompany } from 'utils/validationSchema';
import { useGetPlans } from 'utils/hooks/useGetPlans';
import { useGetSurveys } from 'components/createCompany/service/useGetSurveys';
import { useGetCompany } from 'components/createCompany/service/useGetCompany';
import { useCreateUpdateCompany } from 'components/createCompany/service/useCreateUpdateCompany';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SolidButton from 'components/SolidButton';
import { TextInput, MultiSelect } from 'components/FormComponents';

import './create-company.scss';

const CreateCompany = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const {
    goBack,
    location: { search },
  } = useHistory();
  const { id: companyId = '' } = queryParams(search);

  const { loading, companyData } = useGetCompany(companyId);
  const { name = '', email = '', configValues = {} } = { ...companyData };
  const { survey_id: surveyId = '', plan_id: planId = '' } = { ...configValues };
  const { formatedOptions: planOptions } = useGetPlans();
  const { formatedOptions: surveyOptions } = useGetSurveys();
  const { spinner, onCreateUpdateCompany } = useCreateUpdateCompany(companyId);

  const initialValues = {
    name,
    email,
    planId,
    surveyId,
  };

  return (
    <section className="d-flex">
      <LeftMenu />
      <div className="dashboard__main">
        <Header title={companyId ? t('leftMenuHeader.editCompany') : t('leftMenuHeader.company')} shadow />
        <Formik
          initialValues={initialValues}
          onSubmit={onCreateUpdateCompany}
          validationSchema={createCompany}
          enableReinitialize
        >
          {({ isSubmitting, isValid }) => {
            const isDisabledButton = [!isValid, isSubmitting].includes(true);
            return (
              <Form>
                <div className="create-company">
                  <Field type="text" label={t('formLabel.name')} name="name" component={TextInput} />
                  <Field type="text" label={t('formLabel.email')} name="email" component={TextInput} />
                  <Field
                    type="text"
                    label={t('formLabel.plan')}
                    name="planId"
                    component={MultiSelect}
                    options={planOptions}
                    placeholder={t('placeholder.plan')}
                  />
                  <Field
                    type="text"
                    label={t('formLabel.survey')}
                    name="surveyId"
                    component={MultiSelect}
                    options={surveyOptions}
                    placeholder={t('placeholder.survey')}
                  />
                </div>
                <div className="create-company__buttons">
                  <SolidButton className="secondary mr-2" onClick={goBack}>
                    {t('button.back')}
                  </SolidButton>
                  <SolidButton
                    className={`mr-4 ${isDisabledButton ? 'disabled' : ''}`}
                    disabled={isDisabledButton}
                    type="submit"
                  >
                    {companyId ? t('button.update') : t('button.create')}
                  </SolidButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || spinner}
      />
    </section>
  );
};

export default CreateCompany;

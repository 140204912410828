export const ACTIONS = {
  SET_READING_DATA: 'setReadingData',
  SET_TITLE: 'setTitle',
  SET_BLOCK_TYPE: 'setBlockType',
  ADD_BLOCK: 'addBlock',
  EDIT_BLOCK: 'editBlock',
  HANDLE_SELECT_BLOCK: 'handleSelectBlock',
  REMOVE_BLOCK: 'removeBlock',
  DRAG_AND_DROP_BLOCK: 'dragAndDropBlock',
};

export const BLOCK_TYPE = {
  IDLE: 'idle',
  V_CONTAINER: 'v_container',
  TEXT: 'text',
  IMAGE: 'image',
  HEADING_1: 'heading_1',
  HEADING_2: 'heading_2',
  HEADING_3: 'heading_3',
  BULLET_LIST: 'bullet_list',
  DIVIDER: 'divider',
};

export const BLOCK_CONTENT = {
  TRUE: 'true',
  FALSE: 'false',
};

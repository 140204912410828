import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from 'utils/formatCurrency';

const ReimbursementHeader = ({ invoice }) => {
  const { amountDue, currency, invoiceItems = [] } = { ...invoice };
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <section className="invoices__header reimbursement">
      <div className="invoices__header_info">
        <section className="amount">
          <div className="amount__label">
            <span className="amount__label_title">{t('invoices.header.amount')}</span>
            <strong className="amount__label_value">{formatCurrency({ language, currency, amount: amountDue })}</strong>
          </div>
        </section>
        <section className="status-count">
          <div className="status-count__label">
            <span className="amount__label_title">{t('invoices.header.items')}</span>
            <strong className="status-count__label_count">
              {invoiceItems.length} {t('invoices.header.invoices')}
            </strong>
          </div>
        </section>
      </div>
    </section>
  );
};

ReimbursementHeader.propTypes = {
  invoice: PropTypes.shape({
    amountDue: PropTypes.number,
    currency: PropTypes.string,
    invoiceItems: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default ReimbursementHeader;

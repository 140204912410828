import React from 'react';
import Select from 'react-select';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isEmpty } from 'ramda';
import Icon from '../Icon';
import { FaRegPlayCircle } from 'react-icons/fa';
import healtphyLogo from '../../assets/images/logo-white.svg';
import 'react-lazy-load-image-component/src/effects/blur.css'

import './selectList.scss';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: '300',
    fontSize: '16px',
    color: '#606060',
    backgroundColor: '#fff',
    ':hover': {
    	color: '#fff',
      backgroundColor: '#8093FC',
      cursor: 'pointer',
    },
  }),
  container: (provided, state) => ({
    ...provided,
    width: '50%',
  }),
};

const SelectList = ({ exercisesArray, memoParts, filterParts, filterPart, onTag, onSelect, handleSelect, selectedPart, selectedId }) => {
  const options = memoParts
  	.filter((pts) => isEmpty(filterParts) || filterParts.indexOf(pts) !== -1)
  	.map((part, index) => ({value: part.toLowerCase(), label: part}));
  return (
  	<section className={'list'}>
  		<div className={'select'}>
  		  <label htmlFor={filterPart[0]}>
  		    Más videos de
  		  </label>
  		  <Select 
  		  	id={filterPart[0]} 
  		  	options={options} 
  		  	placeholder={filterPart[0]}
      		styles={customStyles}
  		  	onChange={({label}) => onTag(label)}
  		  />
  		  <Icon 
  		  	name={'close-categories'} 
  		  	className={'close-selected-exercise'}
  		  	onClick={handleSelect}
  		  />
  		</div>
  		<div className={'list-container'}>
  			{memoParts
  		  	.filter((pts) => isEmpty(filterPart) || filterPart.indexOf(pts) !== -1)
  		  	.map((part, index) => {
  		    	const partExercises = (exercisesArray || []).filter(({ bodyParts }) => (bodyParts || []).indexOf(part) !== -1);
  		    	return (
  		      	<div key={index}>
  		      		{partExercises.map((exercise) => {
  		      	  	const { title = '', image = '', id = '', equipments = [] } = { ...exercise };
  		      	  	const isSelected = selectedId === id && part === selectedPart;
  		      	  	return (
  		      	    	<div key={id} className={`list-container__item ${isSelected ? 'selected' : selectedId && 'unselected'}`}>
											<div className={'list-container__item-image'} onClick={() => onSelect(isSelected, {part, exercise})}>
												<LazyLoadImage
												  effect={'blur'}
												  alt={title}
												  src={image}
												  placeholderSrc={healtphyLogo}
												/>
												<div className={'list-play-icon'}>
												  <FaRegPlayCircle/>
												</div>
											</div>
											<div className={'list-container__item-title'}>
												<p>{title}</p>
												{!isEmpty(equipments) && (
													<div className={'list-container__item-equipments'}>
													 	{(equipments || []).map((equi, index) => (
													 		<p 
													 			key={index}
													 			className={'equi-item'}
													 		>
													 			{equi}
													 		</p>
													 	))}
													</div> 
												)}	
											</div>     	      
  		      	    	</div>
  		      	  	);
  		      		})}
  		      	</div>
  		    	);
  		  	})
  			}	
  		</div>
  	</section>
  );
}

export default SelectList;
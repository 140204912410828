import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { isEmpty, pathOr } from 'ramda';

import { CREATE_PUSH_NOTIFICATION } from 'apollo/mutations';
import showNotification from 'utils/showNotification';
import { useToggle } from 'utils/hooks/useToggle';

export const useCreateNotification = (selectedEmployees, setSelectedEmployees) => {
  const { t } = useTranslation();
  const [createPushNotification] = useMutation(CREATE_PUSH_NOTIFICATION);
  const [spinner, setSpinner] = useToggle();

  const handleCreateNotification = async (values) => {
    setSpinner();
    const { title, description: message } = { ...values };

    const response = await createPushNotification({
      variables: {
        title,
        message,
        employeeIds: selectedEmployees,
      },
    });
    const errors = pathOr([], ['data', 'createPushNotification', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [t('notification.push')] : errors,
    });
    if (emptyErrors) {
      setSelectedEmployees([]);
    }
  };

  return { handleCreateNotification, spinner };
};

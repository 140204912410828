import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';

const ChangeStatusAlert = ({ isAlert, name, updateStatus, toggleAlert }) => {
  const { t } = useTranslation();
  return (
    <Modal modalIsOpen={isAlert} closeModal={toggleAlert} closeable={false}>
      <div>
        <h3 className="modal__title">{t('changeStatusAlert.title')}</h3>
        <p className="modal__subtitle">
          <Trans values={{ name }} components={{ strong: <strong /> }}>
            changeStatusAlert.description
          </Trans>
        </p>
        <div className="d-flex justify-content-center mt-4 modal_buttons">
          <SolidButton className="secondary mr-3" onClick={toggleAlert}>
            {t('button.cancel')}
          </SolidButton>
          <SolidButton onClick={updateStatus}>{t('button.continue')}</SolidButton>
        </div>
      </div>
    </Modal>
  );
};

ChangeStatusAlert.propTypes = {
  isAlert: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  updateStatus: PropTypes.func.isRequired,
  toggleAlert: PropTypes.func.isRequired,
};

export default ChangeStatusAlert;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaFilePdf } from 'react-icons/fa';
import { isEmpty } from 'ramda';

import { formatCurrency } from 'utils/formatCurrency';
import { getFullDate } from 'components/conversations/utils';

import NoInvoiceFound from 'components/Forms/NoFormsFound';

const ReimbursementTable = ({ invoiceItems, currency }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <table className="invoices__table">
      <thead className="invoices__table_head">
        <tr className="head-row">
          <th style={{ width: '25%' }}>{t('invoices.tableHeader.employee')}</th>
          <th style={{ width: '30%' }}>{t('invoices.tableHeader.program')}</th>
          <th style={{ width: '10%' }}>{t('invoices.tableHeader.date')}</th>
          <th style={{ width: '15%' }}>{t('invoices.tableHeader.amount')}</th>
          <th style={{ width: '10%' }} className="head-row__download">
            {t('invoices.tableHeader.download')}
          </th>
          <th style={{ width: '10%' }}> </th>
        </tr>
      </thead>
      <tbody className="invoices__table_body">
        {(invoiceItems || []).map((invoiceItem) => {
          const { id = '', recipient = '', description = '', createdAt = '', amount = '' } = { ...invoiceItem };
          const formatDate = getFullDate({ date: new Date(createdAt), lng: language, options: {} });
          return (
            <tr key={id} className="body-row">
              <td style={{ width: '25%' }}>{recipient}</td>
              <td style={{ width: '30%' }}>{description}</td>
              <td style={{ width: '10%' }}>{formatDate}</td>
              <td style={{ width: '15%' }} className="body-row__amount">
                {formatCurrency({ language, currency, amount })}
              </td>
              <td style={{ width: '10%' }} className="body-row__download">
                <FaFilePdf />
              </td>
              <td style={{ width: '10%' }} className="body-row__download">
                <button type="button" className="body-row__download_mark">
                  {t('invoices.tableBody.markAsPaidBtn')}
                </button>
              </td>
            </tr>
          );
        })}
        {isEmpty(invoiceItems) && <NoInvoiceFound />}
      </tbody>
    </table>
  );
};

ReimbursementTable.propTypes = {
  invoiceItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      recipient: PropTypes.string,
      description: PropTypes.string,
      createdAt: PropTypes.string,
      amount: PropTypes.string,
    }),
  ).isRequired,
  currency: PropTypes.string.isRequired,
};

export default ReimbursementTable;

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';

import { BLOCK_TYPE } from 'components/readings/service/constants';

import Modal from 'components/Modal';
import { HeadingPreview } from './HeadingPreview';
import { BlockTextPreview } from './BlockTextPreview';
import { ImagePreview } from './ImagePreview';
import { BulletListPreview } from './BulletListPreview';

import './reading-preview.scss';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: 500,
    height: 768,
    padding: 0,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
};

const ReadingPreview = ({ modalIsOpen, toggleModal, previewData }) => {
  const { t } = useTranslation();
  const { backgroundImage, title, content } = previewData;

  return (
    <Modal modalProps={modalProps} modalIsOpen={modalIsOpen} closeModal={toggleModal} closeable={false}>
      <section className="readingPreview">
        <AiOutlineClose className="readingPreview__close" onClick={toggleModal} />
        <div className="readingPreview__border">
          <div className="readingPreview__background">
            <div className="readingPreview__container">
              <section
                className="readingPreview__container_hero"
                style={{ backgroundImage: `url(${backgroundImage})` }}
              />
              <section className={`readingPreview__content ${isEmpty(title) ? 'empty-state' : ''}`}>
                {isEmpty(title) ? (
                  <p className="readingPreview__content_empty">{t('readings.preview.emptyState')}</p>
                ) : (
                  <>
                    <header className="readingPreview__header">
                      <h3 className="readingPreview__header_title">{title}</h3>
                      <div className="readingPreview__header_save-icon" />
                    </header>
                    <section className="readingPreview__info">
                      {content.map((block) => {
                        const { id, type } = block;
                        switch (type) {
                          case BLOCK_TYPE.HEADING_1:
                          case BLOCK_TYPE.HEADING_2:
                          case BLOCK_TYPE.HEADING_3:
                            return <HeadingPreview key={id} block={block} />;
                          case BLOCK_TYPE.TEXT:
                            return <BlockTextPreview key={id} block={block} />;
                          case BLOCK_TYPE.IMAGE:
                            return <ImagePreview key={id} block={block} />;
                          case BLOCK_TYPE.BULLET_LIST:
                            return <BulletListPreview key={id} block={block} />;
                          case BLOCK_TYPE.DIVIDER:
                            return <hr key={id} />;
                          default:
                            return <span key={id}>Unknown value: {type}</span>;
                        }
                      })}
                    </section>
                  </>
                )}
              </section>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};

ReadingPreview.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  previewData: PropTypes.shape({
    backgroundImage: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default ReadingPreview;

/* eslint-disable import/order */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Loader from 'react-loader-spinner';

import { useAuthQuery } from 'utils/hooks';
import { GET_PATIENT_STATS, GET_FEMALE_PROGRAM_STATS } from 'apollo/queries';
import { useToggle } from 'utils/hooks/useToggle';
import { getBookingCount } from '../utils';

import LogsModal from 'components/Patients/Patient/LogsModal';
import { PatientActivityLog } from './PatientActivityLog';
import { EmployeeActivityLog } from './EmployeeActivityLog';

moment.locale = 'es';

const ACTIVE_STATUS = 'active';

const parseDate = (stringDate = '') => {
  const [date, time, zone] = [...stringDate.split(' ')];
  return `${date}T${time}${zone}`;
};

const ActivityLog = ({ patient = {} }) => {
  const {
    id: patientId = '',
    patientPlans = [],
    femaleProgram = false,
    bookings = [],
    patientPrograms = [],
  } = { ...patient };

  const { loading, data, refetch } = useAuthQuery(femaleProgram ? GET_FEMALE_PROGRAM_STATS : GET_PATIENT_STATS, {
    variables: { patientId },
  });

  const [isLogsOpen, setIsLogsOpen] = useToggle();

  const { patientStats = {} } = { ...data };
  const {
    painLevels = [],
    averageDifficultyLevel = 'N/A',
    averageSatisfactionLevel = 'N/A',
    currentPainLevel = 'N/A',
    lastFinishedDay = '',
    activities = [],
    activitiesByDay = {},
  } = {
    ...patientStats,
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activityLogs = activities.map((log) => {
    const { parameters = {}, createdAt = new Date() } = { ...log };
    const { created_at: feedbackAt = null } = { ...parameters };
    const logAt = feedbackAt ? parseDate(feedbackAt) : undefined;
    return {
      ...log,
      createdAt: logAt || createdAt,
    };
  });

  activityLogs.sort(({ createdAt: dateA }, { createdAt: dateB }) => {
    if (moment(dateA).isBefore(dateB)) return 1;
    if (moment(dateB).isBefore(dateA)) return -1;
    return 0;
  });

  const activityLogsByDay = Object.entries(activitiesByDay);

  const filterActivePrograms = patientPrograms.filter((program) => program.status === ACTIVE_STATUS);
  const lastSessionCompletedAt = filterActivePrograms[0]?.lastSessionCompletedAt;
  const bookingsCount = getBookingCount(bookings);

  const patientData = {
    plans: patientPlans,
    activities,
    painLevels,
    openLogs: setIsLogsOpen,
    finishedDay: lastFinishedDay,
    bookings: bookingsCount,
    painLevel: currentPainLevel,
    difficultyLevel: averageDifficultyLevel,
    satisfactionLevel: averageSatisfactionLevel,
  };

  const employeeData = {
    programs: patientPrograms,
    activities: activitiesByDay,
    lastCompleted: lastSessionCompletedAt,
    bookings: bookingsCount,
    painLevel: currentPainLevel,
    difficultyLevel: averageDifficultyLevel,
    satisfactionLevel: averageSatisfactionLevel,
    openLogs: setIsLogsOpen,
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Loader type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
      </div>
    );
  }

  return (
    <div className="w-100">
      <div className="patient__tab_content follow-up">
        {femaleProgram ? (
          <EmployeeActivityLog userData={employeeData} />
        ) : (
          <PatientActivityLog userData={patientData} />
        )}
      </div>
      {isLogsOpen && (
        <LogsModal
          isLogsOpen={isLogsOpen}
          onCloseModal={setIsLogsOpen}
          isFemale={femaleProgram}
          activityLogs={activityLogs}
          activityLogsByDay={activityLogsByDay}
        />
      )}
    </div>
  );
};

ActivityLog.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default ActivityLog;

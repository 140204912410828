import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import { useConversationsContext } from 'components/conversations/ConversationsProvider';
import { getTimestamp, getFullDate, getNameAndInitials } from '../utils';
import { useGetMessages } from '../hooks';

const ConversationInfo = ({ conversation }) => {
  const { selectedConversation, handleSelectConversation, lng } = useConversationsContext();
  const { messages, loadMessagesFor } = useGetMessages();
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [upatedKey, setUpatedKey] = useState(0);
  const [lastMessage, setLastMessage] = useState({});

  const isSelected = selectedConversation.sid === conversation.sid;
  const hasUnreadMessages = unreadMessagesCount > 0;
  const conversationDate = conversation?.lastMessage?.dateCreated || conversation?.dateUpdated;

  const getUnreadMessages = () => {
    conversation.getUnreadMessagesCount().then((unreadMessages) => {
      setUnreadMessagesCount(unreadMessages);
    });
  };

  const setAllMessagesRead = () => {
    conversation.setAllMessagesRead().then((unreadMessages) => {
      setUnreadMessagesCount(unreadMessages);
    });
  };

  const conversationUpdated = (data) => {
    if (data.updateReasons.includes('lastMessage')) {
      setUpatedKey((prevKey) => prevKey + 1);
      getUnreadMessages();
      loadMessagesFor(data.conversation);
    }
  };

  useEffect(() => {
    getUnreadMessages();
  }, []);

  useEffect(() => {
    if (!isEmpty(conversation)) {
      loadMessagesFor(conversation);
      conversation.on('updated', (data) => conversationUpdated(data));
    }
  }, [conversation]);

  useEffect(() => {
    if (isSelected) {
      setAllMessagesRead();
    }
  }, [isSelected, upatedKey]);

  useEffect(() => {
    const getLastMessage = messages.find((message) => message.index === conversation.lastMessage.index);
    if (getLastMessage) {
      setLastMessage(getLastMessage);
    }
  }, [messages, conversation]);

  const dateUptaded = getFullDate({
    date: conversationDate,
    lng,
    options: { month: 'short', day: 'numeric', year: '2-digit' },
  });
  const timestamp = getTimestamp(conversationDate);

  const { name, initials } = getNameAndInitials(conversation);

  const onSelectConversation = () => {
    if (isSelected) {
      return;
    }
    handleSelectConversation(conversation);
  };

  return (
    <li
      className={`conversation-info ${isSelected ? 'selected-conversation' : ''}`}
      onClick={onSelectConversation}
      aria-hidden="true"
    >
      <div className="conversation-info__initials">
        <span>{initials}</span>
      </div>
      <div className="conversation-info__credentials">
        <h4>{name}</h4>
        <p>{lastMessage.body || '...'}</p>
      </div>
      {!isSelected && hasUnreadMessages ? (
        <div className="conversation-info__count">
          <span>{unreadMessagesCount}</span>
        </div>
      ) : (
        <div className="conversation-info__time">
          <span>
            {dateUptaded} - {timestamp}
          </span>
        </div>
      )}
    </li>
  );
};

ConversationInfo.propTypes = {
  conversation: PropTypes.shape({
    lastMessage: PropTypes.shape({
      dateCreated: PropTypes.instanceOf(Date),
      index: PropTypes.number,
    }),
    friendlyName: PropTypes.string,
    sid: PropTypes.string,
    on: PropTypes.func,
    getUnreadMessagesCount: PropTypes.func,
    setAllMessagesRead: PropTypes.func,
  }).isRequired,
};

export default ConversationInfo;

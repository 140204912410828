import { ACTIONS } from './constants';

export function readingBuilderReducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_READING_DATA:
    case ACTIONS.SET_TITLE:
      return { ...state, ...action.payload };
    case ACTIONS.SET_BLOCK_TYPE:
      return { ...state, blockType: action.payload };
    case ACTIONS.HANDLE_SELECT_BLOCK:
      return { ...state, selectedBlock: action.payload };
    case ACTIONS.ADD_BLOCK:
      return { ...state, content: [...state.content, action.payload] };
    case ACTIONS.EDIT_BLOCK:
    case ACTIONS.REMOVE_BLOCK:
    case ACTIONS.DRAG_AND_DROP_BLOCK:
      return { ...state, content: action.payload };

    default:
      throw new Error(`Unhandled action ${action.type}`);
  }
}

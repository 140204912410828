import React from 'react';
import PropTypes from 'prop-types';
import { IoMdUnlock } from 'react-icons/io';

import DownloadButton from 'components/Surveys/DownloadButton';

import completedSurvey from 'assets/images/completed-survey.png';

const NewUserCompleted = ({ credentials }) => {
  const { email = '', password = '' } = { ...credentials };

  return (
    <section className="new-user__content__completed">
      <div className="welcome-survey__title">
        <h2>Ya puedes iniciar tus ejercicios personalizados</h2>
      </div>
      <div className="sections-completed__credentials">
        <div className="sections-completed__credentials_image">
          <img src={completedSurvey} alt="encuesta terminada" />
        </div>
        <div className="sections-completed__credentials_data">
          <div className="login">
            <p>Estos son tus datos para ingresar</p>
            <p>
              <span className="user">Usuario:</span>
              <span>{email}</span>
            </p>
            <p>
              <span className="user">Contraseña:</span>
              <span>{password}</span>
            </p>
          </div>
          <div className="reminder">
            <div className="reminder__unlock">
              <IoMdUnlock />
            </div>
            <p>Recuerda cambiar la clave una vez ingreses a la app para validar tu entrada en la plataforma.</p>
          </div>
        </div>
      </div>
      <div className="welcome-survey__footer">
        <div className="buttons">
          <p>Descargarlo ahora</p>
          <DownloadButton store="google" />
          <DownloadButton store="apple" />
        </div>
      </div>
    </section>
  );
};

NewUserCompleted.defaultProps = {
  credentials: {},
};

NewUserCompleted.propTypes = {
  credentials: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
};

export default NewUserCompleted;

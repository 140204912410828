import React, { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Client as ConversationsClient } from '@twilio/conversations';
import { useTranslation } from 'react-i18next';
import addNotification from 'react-push-notification';

import { GET_TWILIO_TOKEN } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';
import { useToggle } from 'utils/hooks/useToggle';
import { ROUTES, ROLES } from 'utils/constants';
import { getUserRole } from 'utils/isClinic';

const CreateConversationsContext = createContext();

const STATUS = {
  connecting: 'Connecting to Twilio...',
  connected: 'You are connected.',
  disconnecting: 'Disconnecting from Twilio...',
  disconnected: 'Disconnected.',
  denied: 'Failed to connect.',
  fetching: 'Fetching credentials...',
};

const ConversationsProvider = ({ children }) => {
  const userRole = getUserRole();
  const isSuperClinic = userRole === ROLES.SUPER_CLINIC;
  const {
    i18n: { language },
  } = useTranslation();
  const {
    location: { pathname },
  } = useHistory();
  const { data = {}, refetch = () => {} } = isSuperClinic ? useAuthQuery(GET_TWILIO_TOKEN) : {};
  const { getSuperClinicInfo = {} } = { ...data };
  const { twilioToken = null, email = '' } = { ...getSuperClinicInfo };

  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState({});
  const [status, setStatus] = useState(null);
  const [conversationKey, setConversationKey] = useState(0);
  const [unreadConversationsCount, setUnreadConversationsCount] = useState({});
  const [twilioClient, setTwilioClient] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const [isOpenConversationsModal, setIsOpenConversationsModal] = useToggle();

  const initConversations = () => {
    const conversationsClient = new ConversationsClient(twilioToken);
    setStatus(STATUS.connecting);

    conversationsClient.on('connectionStateChanged', (state) => {
      setStatus(STATUS[state]);
    });

    conversationsClient.on('conversationJoined', (conversation) => {
      setConversations((prev) => [...prev, conversation]);
      conversation.getUnreadMessagesCount().then((unreadMessages) => {
        setUnreadConversationsCount((prev) => ({ ...prev, [conversation.sid]: unreadMessages ? 1 : 0 }));
      });
    });

    conversationsClient.on('conversationLeft', (thisConversation) => {
      setConversations((prev) => prev.filter((it) => it !== thisConversation));
    });
    setTwilioClient(conversationsClient);
  };

  useEffect(() => {
    if (twilioToken) {
      initConversations();
    }
  }, [twilioToken]);

  useEffect(() => {
    if (twilioClient) {
      twilioClient.on('conversationUpdated', (conversationData) => {
        const { conversation = {} } = conversationData;
        const { eventHistory } = conversation;
        const participantInfo = eventHistory.get('messageAdded');
        if (participantInfo) {
          const { author = '', body = '' } = participantInfo[0];
          addNotification({ title: author, message: body, native: true });
        }
        const isSelected = conversationData.conversation.sid === selectedConversation.sid;
        if (conversationData.updateReasons.includes('lastMessage')) {
          setUnreadConversationsCount((prev) => ({ ...prev, [conversationData.conversation.sid]: isSelected ? 0 : 1 }));
        }
      });
    }
  }, [twilioClient, selectedConversation]);

  useEffect(() => {
    if (selectedConversation) {
      setUnreadConversationsCount((prev) => ({ ...prev, [selectedConversation.sid]: 0 }));
    }
  }, [selectedConversation]);

  useEffect(() => {
    if ((pathname !== ROUTES.CHAT && !pathname.startsWith(ROUTES.PATIENTS)) || !isOpenConversationsModal) {
      setSelectedConversation({});
      setPatientId(null);
    }
  }, [pathname, isOpenConversationsModal]);

  useEffect(() => {
    if (patientId) {
      const findNewConversation = conversations.find(({ attributes }) => attributes.employee_id === patientId);
      if (findNewConversation) {
        setSelectedConversation(findNewConversation);
      }
    }
  }, [patientId]);

  useEffect(() => {
    if (status === STATUS.disconnected) {
      setConversations([]);
      setSelectedConversation({});
      refetch();
    }
  }, [status]);

  const handleSelectConversation = (selectedConv) => {
    setConversationKey((prevKey) => prevKey + 1);
    setSelectedConversation(selectedConv);
  };

  return (
    <CreateConversationsContext.Provider
      value={{
        conversations,
        selectedConversation,
        handleSelectConversation,
        status,
        conversationKey,
        lng: language,
        ownerEmail: email,
        unreadConversationsCount,
        isOpenModal: isOpenConversationsModal,
        handleModal: setIsOpenConversationsModal,
        handlePatientId: setPatientId,
      }}
    >
      {children}
    </CreateConversationsContext.Provider>
  );
};

ConversationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useConversationsContext() {
  const context = useContext(CreateConversationsContext);
  if (!context) {
    throw new Error('Expected an CreateConversationsContext somewhere in the react tree to set context value');
  }
  return context;
}

export { useConversationsContext, ConversationsProvider };

import React, { useState, useRef, useEffect, useMemo } from 'react';
import { isEmpty, pathOr } from 'ramda';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import Loader from 'react-loader-spinner';

import { BODY_PARTS, GET_PLAN } from 'apollo/queries';
import { CREATE_CUSTOM_PLAN, UPDATE_CUSTOM_PLAN } from 'apollo/mutations';
import { useAuthQuery } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { customPlanTemplateForm } from 'utils/validationSchema';
import { mapWeeksToUpdate } from 'utils/plan';
import showNotification from 'utils/showNotification';
import { ROLES, ROUTES } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';
import { queryParams } from 'utils/string';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import { TextInput, TextAreaDescription, MultiSelect } from 'components/FormComponents';
import SolidButton from 'components/SolidButton';
import Icon from 'components/Icon';
import CreateWeek from 'components/CreatePatient/CreateWeek';
import { StoreConsumer, useAppState } from 'components/StoreProvider';

import noWeeks from 'assets/images/no-weeks.svg';
import 'components/CreatePatient/CreateCustomPlan/create-custom-plan.scss';
import './create-plan.scss';

const planTypes = [
  { label: 'Pausas Activas', value: 'active_pause' },
  { label: 'Rehabilitación', value: 'rehabilitation' },
];

const CreatePlan = () => {
  useRedirectByRole([ROLES.CLINIC, ROLES.SUPER_CLINIC]);
  const { isSuperClinic } = getUserRole();
  const { push = () => {}, replace = () => {}, location = {} } = useHistory();
  const { pathname, search } = { ...location };
  const { planId: queryParamOfPlanId } = queryParams(search);
  const isEdit = pathname === ROUTES.EDIT_PLAN;
  const { setStoreData } = useAppState();

  const { loading = false, data: planData = {} } = queryParamOfPlanId
    ? useAuthQuery(GET_PLAN, {
        variables: { planId: queryParamOfPlanId },
      })
    : {};
  const { plan = {} } = { ...planData };
  const { weeks: weeksPlan = [], bodyParts: bodyPartsPlan = [] } = { ...plan };
  const { data } = useAuthQuery(BODY_PARTS);
  const { bodyParts = [] } = { ...data };
  const memoBodyParts = useMemo(() => {
    const normalizeData = (bodyParts || []).map((bodyPart) => {
      const { title = '' } = { ...bodyPart };
      return title;
    });
    return normalizeData;
  }, [bodyParts]);

  const [createCustomPlan] = useMutation(CREATE_CUSTOM_PLAN);
  const [updateCustomPlan] = useMutation(UPDATE_CUSTOM_PLAN);

  const [showWeek, setShowWeek] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [size, setZise] = useState(1);

  useEffect(() => {
    if (queryParamOfPlanId) {
      const parsedWeeks = weeksPlan.map((week, index) => {
        const { title: weekTitle = `Semana ${index + 1}`, days: weekDays = [] } = { ...week };
        const days = weekDays
          .map((day) => {
            const { weekNumber, exercises = [] } = { ...day };
            return exercises.map((ex) => ({ ...ex, weekNumber }));
          })
          .reduce((a, b) => [...a, ...b], []);
        return { ...week, title: weekTitle, days };
      });
      setStoreData('weeks', parsedWeeks);
      setStoreData('customPlan', {
        ...plan,
        bodyParts: (bodyPartsPlan || []).map(({ title: bodyPartLabel }) => bodyPartLabel),
      });
    }
  }, [queryParamOfPlanId, weeksPlan, bodyPartsPlan]);

  const weekRef = useRef(null);
  const scrollToRef = (ref) => {
    const panel = document.getElementById('weeks');
    !!ref.current && panel && panel.scrollTo(ref.current.offsetLeft, 0);
  };
  useEffect(() => {
    scrollToRef(weekRef);
  }, []);

  const onRenderAddPanel = () => (
    <div className="side-add-panel" onClick={() => setShowWeek(true)}>
      <p className="text">Agregar semana</p>
      <Icon name="add" className="icon" />
    </div>
  );

  const onDuplicateWeek = (weeks, week, setStoreData) => {
    const { days = [] } = { ...week };
    const newWeek = {
      days,
      title: `Semana ${weeks.length + 1}`,
    };
    setStoreData('weeks', [...weeks, newWeek]);
    scrollToRef(weekRef);
  };

  const onDeleteWeek = (weeks, weekIndex, setStoreData) => {
    const newWeeks = weeks
      .filter((el, index) => el && weekIndex !== index)
      .map((el, index) => ({
        ...el,
        title: `Semana ${index + 1}`,
      }));
    setStoreData('weeks', newWeeks);
    scrollToRef(weekRef);
  };

  const onEditWeek = (week, index, setStoreData) => {
    setStoreData('week', { week, index });
    setShowWeek(true);
  };

  const onSetStep = (step) => {
    setShowWeek(!step);
  };

  const onRenderWeeks = (weeks, setStoreData) =>
    weeks.map((week, index) => {
      const { title, days: exercises = [] } = { ...week };
      const days = exercises.reduce((rv, x) => {
        (rv[x.weekNumber] = rv[x.weekNumber] || []).push(x);
        return rv;
      }, {});
      const daysList = Object.keys(days);
      return (
        <div className="card" key={title} ref={index === weeks.length - 1 ? weekRef : null}>
          <div className="card-title-container">
            <p className="card-title">{title}</p>
            <div className="head-buttons">
              <Icon name="copy" className="icon" onClick={() => onDuplicateWeek(weeks, week, setStoreData)} />
              <Icon name="edit" className="icon" onClick={() => onEditWeek(week, index, setStoreData)} />
              <Icon name="trash" className="icon" onClick={() => onDeleteWeek(weeks, index, setStoreData)} />
            </div>
          </div>
          <div className="card-summary-container">
            <p className="card-summary-item">
              <span>{exercises.length}</span>Ejercicios
            </p>
            <p className="card-summary-item">
              <span>{daysList.length}</span>Días
            </p>
          </div>
          <div className="card-days-container">
            {daysList.map((dayNumber) => (
              <div className="card-day" key={`day-${dayNumber}`}>
                <div className="day-title">
                  <p>Día {dayNumber}</p>
                </div>
                <div className="day-data">
                  {(days[dayNumber] || []).map(({ id, title: dayTitle, image }) => (
                    <div className="card-exercise" key={id}>
                      <img src={image} className="image" alt="exercise" />
                      <div className="info">
                        <p>{dayTitle}</p>
                        <span>{`Dia ${dayNumber}`}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    });

  const onSavePlan = (values, setStoreData, weeks) => async () => {
    setSpinner(true);
    const weeksToSend = weeks.map((week) => {
      const { title, days = [], id: weekId } = { ...week };
      return {
        title,
        weekId,
        days: days.map((day) => {
          const { weekNumber, id, order, rules } = { ...day };
          return {
            weekNumber,
            exerciseId: id,
            exercisePosition: order,
            rules,
          };
        }),
      };
    });

    const { title, description, id: planId, bodyParts: bodyPartsToSend, planType } = { ...values };
    const saveMutation = isEdit ? updateCustomPlan : createCustomPlan;
    const target = isEdit ? `/plan?id=${planId}` : '/planes';
    const response = await saveMutation({
      variables: {
        ...(isEdit &&
          !!planId && {
            planId,
          }),
        plan: {
          title,
          description,
          bodyParts: (bodyPartsToSend || []).join(', '),
          isTemplate: true,
        },
        weeks: mapWeeksToUpdate(weeksToSend, values),
        ...(planType && {
          planType,
        }),
      },
    });
    const errors = pathOr([], ['data', isEdit ? 'updatePlan' : 'createCustomPlan', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha ${isEdit ? 'actualizado' : 'creado'} el plan exitosamente.`] : errors,
    });

    if (emptyErrors) {
      setStoreData('initialState');
      push(target);
    }
  };

  const onGoBack = (setStoreData, plan) => () => {
    const { id } = { ...plan };
    setStoreData('weeks', []);
    setStoreData('customPlan', { title: '', description: '' });
    setStoreData('step', '');
    replace(isEdit ? `/plan?id=${id}` : '/planes');
  };

  const onInputChange = (event, onChange, customPlan, setStoreData) => {
    const { value } = event.target;
    const key = event.target.name;
    setStoreData('customPlan', { ...customPlan, [key]: value });
    onChange(event);
  };

  return (
    <div className="d-flex hvh-100">
      <LeftMenu />
      <div className="dashboard__main flex-fill">
        <Header title={`${isEdit ? 'Editar' : 'Crear'} plan`} />
        <StoreConsumer>
          {({ weeks, customPlan, setStoreData, week }) =>
            showWeek ? (
              <CreateWeek onSetStep={onSetStep} week={week} />
            ) : (
              <div className="custom-plan__main-container content__container_plan">
                <Formik
                  enableReinitialize
                  initialValues={customPlan}
                  onSubmit={(values) => onSavePlan(values, setStoreData, weeks)()}
                  validationSchema={customPlanTemplateForm}
                >
                  {({ handleSubmit, handleChange }) => (
                    <Form onSubmit={handleSubmit} className="plans-view-create-plan-form">
                      <div className="plans-view-create-plan-form__fields">
                        <Field
                          type="text"
                          label="Título"
                          name="title"
                          component={TextInput}
                          onChange={(e) => onInputChange(e, handleChange, customPlan, setStoreData)}
                        />
                        <div className="plans-view-create-plan-form__description">
                          <Field
                            className={size === 10 ? 'shadow' : ''}
                            rows={size}
                            type="text"
                            label="Descripción"
                            name="description"
                            component={TextAreaDescription}
                            onChange={(e) => onInputChange(e, handleChange, customPlan, setStoreData)}
                            onClick={() => setZise(10)}
                          />
                          <Icon
                            name="close"
                            className="plans-view-create-plan-form__description_icon"
                            onClick={() => setZise(1)}
                          />
                        </div>
                        <Field
                          type="text"
                          label="Partes del cuerpo relacionadas"
                          name="bodyParts"
                          component={MultiSelect}
                          options={memoBodyParts}
                          isMulti
                          onChange={(e) => onInputChange(e, handleChange, customPlan, setStoreData)}
                          placeholder="Seleccionar"
                        />
                        {isSuperClinic && (
                          <Field
                            type="text"
                            label="Tipo del plan"
                            name="planType"
                            component={MultiSelect}
                            options={planTypes}
                            onChange={(e) => onInputChange(e, handleChange, customPlan, setStoreData)}
                            placeholder="Seleccionar"
                          />
                        )}
                      </div>
                      <div className="plans-view-create-plan-form__buttons">
                        <SolidButton
                          type="button"
                          className="mr-2 secondary"
                          onClick={onGoBack(setStoreData, customPlan)}
                        >
                          {isEdit ? 'Cancelar' : 'Atrás'}
                        </SolidButton>
                        <SolidButton
                          type={isEmpty(weeks) ? 'button' : 'submit'}
                          className={isEmpty(weeks) ? 'disabled' : ''}
                        >
                          {isEdit ? 'Guardar' : 'Crear'} Plan
                        </SolidButton>
                      </div>
                    </Form>
                  )}
                </Formik>

                {isEmpty(weeks) ? (
                  <div className="empty-state">
                    <div className="content">
                      <img src={noWeeks} alt="no weeks" className="image" />
                      <p className="title">Aún no tienes semanas creadas</p>
                      <p className="subtitle">
                        Empieza <span>agregando</span> tu primera semana para crear el plan de tu paciente
                      </p>
                    </div>
                    {onRenderAddPanel()}
                  </div>
                ) : (
                  <>
                    <div className="plan-container">
                      <div className="weeks-container" id="weeks">
                        {onRenderWeeks(weeks, setStoreData)}
                      </div>
                      {onRenderAddPanel()}
                    </div>
                    <Loader
                      type="ThreeDots"
                      color="#495fd7"
                      height={100}
                      width={100}
                      className="spinner"
                      visible={showSpinner || loading}
                    />
                  </>
                )}
              </div>
            )
          }
        </StoreConsumer>
      </div>
    </div>
  );
};

export default CreatePlan;

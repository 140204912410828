import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './categoriesFilter.scss';

const CategoriesFilter = ({ categories, filterStates, showCategories, className }) => {
  const { memoBodyParts = [], memoEquipments = [], memoLevels = [] } = { ...categories };

  const {
    filterParts = [],
    filterEquipments = [],
    filterLevels = [],
    handleSelectSegments = () => {},
  } = { ...filterStates };

  const setSelectedClassName = (array, element) => ([...(array || [])].indexOf(element) !== -1 ? 'selected' : '');

  return (
    <TransitionGroup component={null}>
      {showCategories && (
        <CSSTransition classNames="library__detail" timeout={300}>
          <div className={`categories ${className}`}>
            <div className="categories__items">
              <p className="categories__items--title">Segmento</p>
              <div className="categories__items_list">
                {memoBodyParts.map((el) => (
                  <button
                    type="button"
                    key={el}
                    className={`categories__items_list--item ${setSelectedClassName(filterParts, el)}`}
                    onClick={() => handleSelectSegments({ selectedSegment: el, category: 'bodyParts' })}
                  >
                    {el}
                  </button>
                ))}
              </div>
            </div>
            <div className="categories__items">
              <p className="categories__items--title">Materiales</p>
              <div className="categories__items_list">
                {memoEquipments.map((el) => (
                  <button
                    type="button"
                    key={el}
                    className={`categories__items_list--item ${setSelectedClassName(filterEquipments, el)}`}
                    onClick={() => handleSelectSegments({ selectedSegment: el, category: 'equipments' })}
                  >
                    {el}
                  </button>
                ))}
              </div>
            </div>
            <div className="categories__items">
              <p className="categories__items--title">Dificultad</p>
              <div className="categories__items_list levels">
                {memoLevels.map((el) => (
                  <button
                    type="button"
                    key={el}
                    className={`categories__items_list--item ${setSelectedClassName(filterLevels, el)}`}
                    onClick={() => handleSelectSegments({ selectedSegment: el, category: 'levels' })}
                  >
                    {el}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

export default CategoriesFilter;

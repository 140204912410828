import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form, FieldArray } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { isEmpty, pathOr } from 'ramda';
import Loader from 'react-loader-spinner';
import { RangeInput, SearchInput, MultiSelect, TextAreaInput, TextInput, DateInput, NumberInput, FileInput } from 'components/FormComponents';
import Button from 'components/SolidButton';
import Modal from 'components/Modal';
import Icon from 'components/Icon';
import { StoreConsumer, useAppState } from 'components/StoreProvider';

import { ROUTES } from 'utils/constants';
import { useAuthQuery } from 'utils/hooks';
import { CREATE_PLAN, PERSONAL_INFORMATION } from 'utils/steps';
import { validationSchemaBuilder } from 'utils/validationSchema';
import showNotification from 'utils/showNotification';

import { CREATE_PATIENT_PROFILE } from 'apollo/mutations';
import { GET_FORM } from 'apollo/queries';

export const initialFormState = (fields = [], values) => {
  const initial = {};
  fields.forEach((field) => {
    const { id = '', type = 'text', label = '' } = { ...field };
    switch (type) {
      case 'cie10':
        initial[id] = [''];
        break;
      case 'verification':
        initial[id] = false;
        break;
      case 'block':
        initial[id] = label;
        break;
      default:
        initial[id] = '';
    }
  });
  return { ...initial, ...values };
};

const MedicalAssessment = ({ onSetStep, fromPatients }) => {
  const history = useHistory();
  const [createPatientProfile] = useMutation(CREATE_PATIENT_PROFILE);
  const [assessmentData, setAssessmentData] = useState({});
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [showSpinner, setSpinner] = useState(false);

  const onCloseModal = () => setIsModalOpen(false);

  const { state: stateFromPatients } = { ...fromPatients };
  const { id: patientId = '', } = { ...stateFromPatients };

  const appState = useAppState();
  const { patient: fullPatient, assessment, setStoreData, currentTab = 0 } = { ...appState };
  const { formId = null } = { ...fullPatient };

  const { loading, data = {} } = useAuthQuery(GET_FORM, {
    variables: { formId },
  });

  const { getClinicForm: formData = {} } = { ...data };
  const { content: assessmentForm = [] } = { ...formData };

  const onSendData = ({ patient, setStoreData }) => async () => {
    onCloseModal();
    setSpinner(true);
    const formValues = assessmentForm
      .filter(({ type }) => type !== 'file')
      .map((formField) => {
        const { id } = { ...formField };
        return { ...formField, value: assessmentData[id] || '' };
      });
    const files = assessmentForm
      .filter(({ type, id }) => type === 'file' && !isEmpty(assessmentData[id]))
      .map((formField) => {
        const { id = '', label = '' } = { ...formField };
        return { label, file: assessmentData[id][0] };
      });
    const response = await createPatientProfile({
      variables: {
        ...(!!patientId && {
          patientId,
        }),
        patientProfile: patient,
        patientAssessment: {
          diagnostic: (assessmentData || {}).diagnostic,
          content: formValues,
        },
        ...(!isEmpty(files) && {
          files,
        }),
      },
    });

    const errors = pathOr([], ['data', 'createPatientProfile', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? ['Se ha creado el perfil del paciente exitosamente.'] : errors,
    });

    if (emptyErrors) {
      const patientId = pathOr([], ['data', 'createPatientProfile', 'patient', 'id'])(response);
      currentTab !== 2 ? onSetStep(CREATE_PLAN) : history.push(ROUTES.PATIENTS);
      setStoreData('patient', { ...patient, id: patientId });
    }
  };

  const joinValue = (value) => {
    return typeof value === 'string' ? value : value.join(', ');
  };

  const splitValue = (value) => {
    return typeof value === 'string' ? value.split(', ') : value;
  };

  const onSubmit = (setStoreData) => ({ diagnostic, ...data }) => {
    const stringDiagnostic = joinValue(diagnostic);
    const values = {
      ...data,
      formId: undefined,
      diagnostic: stringDiagnostic,
    };
    setAssessmentData(values);
    setStoreData('assessment', values);
    setIsModalOpen(true);
  };

  const validationSchema = validationSchemaBuilder(assessmentForm);
  const initialAssessment = initialFormState(assessmentForm, assessment);

  return (
    <StoreConsumer>
      {() => {
        const { diagnostic: diagnosticAssessment = [] } = { ...assessment };
        const assesDiagns = splitValue(diagnosticAssessment) || [];
        const patient = { ...fullPatient, formId: undefined };
        return (
          <div style={{ width: '80%' }}>
            <h2 className={'section-title'}>Valoración</h2>
            <p className={'section-description'}>
              Ingresa el diagnóstico y estado físico inicial del paciente con el fin de asignar el tratamiento más adecuado y
              estudiar el seguimiento, para comprobar sí existe mejoría según los objetivos terapéuticos
            </p>
            {!loading && (
              <Formik
                initialValues={{ ...initialAssessment, diagnostic: assesDiagns }}
                onSubmit={onSubmit(setStoreData)}
                validationSchema={validationSchema}>
                {({ handleSubmit, values, setFieldValue, errors }) => {
                  const { diagnostic: diagnosticValue = [] } = { ...values };
                  const diagnostic = splitValue(diagnosticValue) || [];
                  return (
                    <Form onSubmit={handleSubmit} className='w-100'>
                      <FieldArray
                        name='diagnostic'
                        render={(arrayHelpers) => (
                          <div>
                            <div className={'icon-container'}>
                              <Icon name='add' className={'add-icon'} onClick={() => arrayHelpers.push('')} />
                              {diagnostic.length > 1 && (
                                <Icon className={'remove-icon'} name='close' onClick={() => arrayHelpers.pop()} />
                              )}
                            </div>
                            {diagnostic.map((field, index) => (
                              <div key={index}>
                                <Field
                                  type='text'
                                  label='Diagnóstico CIE 10'
                                  name={`diagnostic[${index}]`}
                                  placeholder={'Código o diagnóstico CIE10'}
                                  component={SearchInput}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      />
                      {diagnostic.length > 1 && <div className={'horizontal-line'} />}
                      {(assessmentForm || []).map((formField) => {
                        const {
                          type = 'text',
                          label = '',
                          id = '',
                          required = false,
                          includeNA = false,
                          multi = false,
                          options = [],
                          min = 0,
                          max = 10,
                        } = {
                          ...formField,
                        };
                        switch (type) {
                          case 'multiple-selection':
                            return (
                              <Field
                                key={id}
                                type='text'
                                label={label}
                                isRequired={required}
                                name={id}
                                component={MultiSelect}
                                options={options}
                                isMulti={multi}
                                placeholder='Seleccione...'
                              />
                            );
                          case 'numeric':
                            return <Field isRequired={required} key={id} label={label} name={id} component={NumberInput} />;
                          case 'numeric-scale':
                            return (
                              <Field
                                key={id}
                                label={label}
                                name={id}
                                isRequired={required}
                                component={RangeInput}
                                orientation='horizontal'
                                includeNA={includeNA}
                                min={Number(min)}
                                max={Number(max)}
                              />
                            );
                          /*case 'cie10':
                            const { [id]: diagnosticValue = [] } = { ...values };
                            const diagnostic = splitValue(diagnosticValue) || [];
                            return (
                              <FieldArray
                                key={id}
                                name={id}
                                render={(arrayHelpers) => (
                                  <>
                                    {diagnostic.length > 1 && (
                                      <div className={'icon-container'}>
                                        <Icon className={'remove-icon'} name='close' onClick={() => arrayHelpers.pop()} />
                                      </div>
                                    )}
                                    <div className={'icon-container'}>
                                      <Icon name='add' className={'add-icon'} onClick={() => arrayHelpers.push('')} />
                                    </div>
                                    {diagnostic.map((field, index) => (
                                      <div key={index}>
                                        <Field
                                          type='text'
                                          label={label}
                                          isRequired={required}
                                          name={`${id}[${index}]`}
                                          placeholder={'Código o diagnóstico CIE10'}
                                          component={SearchInput}
                                        />
                                      </div>
                                    ))}
                                  </>
                                )}
                              />
                            );*/
                          case 'date':
                            return <Field key={id} label={label} name={id} component={DateInput} isRequired={required} />;
                          case 'file':
                            return (
                              <Field key={id} label={label} name={id} component={FileInput} isRequired={required} />
                            );
                          case 'verification':
                            return (
                              <Field
                                key={id}
                                name={id}
                                render={({ field }) => (
                                  <div className={'form__input-container assessment__checkbox__input'}>
                                    <input
                                      {...field}
                                      type='checkbox'
                                      checked={field.value}
                                      onChange={() => {
                                        setFieldValue(id, !field.value);
                                      }}
                                    />
                                    <label className={'form__label'}>{label}</label>
                                  </div>
                                )}
                              />
                            );
                          case 'block':
                            return (
                              <Field 
                                key={id} 
                                label={label} 
                                name={id} 
                                component={TextInput} 
                                className={'field-block'}
                              />
                            );  
                          default:
                            return (
                              <Field
                                key={id}
                                type={'text'}
                                label={label}
                                isRequired={required}
                                name={id}
                                minRows={multi ? 20 : null}
                                component={multi ? TextAreaInput : TextInput}
                                className={multi ? 'observations-field' : ''}
                              />
                            );
                        }
                      })}
                      {/*<Field
                        type='text'
                        label='Observaciones'
                        name='observations'
                        minRows={20}
                        component={TextAreaInput}
                        className={'observations-field'}
                      />*/}
                      <div className='button-container'>
                        <Button className={'mr-3 secondary'} onClick={() => onSetStep(PERSONAL_INFORMATION)}>
                          Atrás
                        </Button>
                        <Button type={'submit'}>Siguiente</Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )}
            <Modal modalIsOpen={modalIsOpen} closeModal={onCloseModal} closeable={false}>
              <div>
                <h3 className='modal__title'>¿Seguro quieres continuar?</h3>
                <p className='modal__subtitle'>No podrás volver a editar esta información una vez des en `Continuar`</p>
                <div className='d-flex justify-content-center mt-4 modal_buttons'>
                  <Button className='mr-3 secondary' onClick={onCloseModal}>
                    Cancelar
                  </Button>
                  <Button onClick={onSendData({ patient: { ...patient }, setStoreData })}>Continuar</Button>
                </div>
              </div>
            </Modal>
            <Loader
              type='ThreeDots'
              color='#495fd7'
              height={100}
              width={100}
              className='spinner'
              visible={showSpinner || loading}
            />
          </div>
        );
      }}
    </StoreConsumer>
  );
};

export default MedicalAssessment;

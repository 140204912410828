import React, { useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { queryParams } from 'utils/string';
import { useGetReadings, useGetReadingTemplate, useCreateUpdateReadingTemplate } from './service';

const CreateTemplateContext = createContext();

const CreateTemplateProvider = ({ children }) => {
  const {
    location: { search },
  } = useHistory();
  const { id: searchId } = queryParams(search);

  const { loading, template, addReading, removeReading } = useGetReadingTemplate(searchId);
  const { allReadings } = useGetReadings();
  const { createEditTemplate, spinner } = useCreateUpdateReadingTemplate(template);

  return (
    <CreateTemplateContext.Provider
      value={{
        searchId,
        isLoading: loading || spinner,
        template,
        allReadings,
        handleAddReading: addReading,
        handleRemoveReading: removeReading,
        handleCreateEditTemplate: createEditTemplate,
      }}
    >
      {children}
    </CreateTemplateContext.Provider>
  );
};

CreateTemplateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useCreateTemplateContext() {
  const context = useContext(CreateTemplateContext);
  if (!context) {
    throw new Error('Expected an CreateTemplateContext somewhere in the react tree to set context value');
  }
  return context;
}

export { useCreateTemplateContext, CreateTemplateProvider };

import React from 'react';
import { useTranslation } from 'react-i18next';

import medal from 'assets/images/medal.png';

import './how-to-win.scss';

const HowToWin = () => {
  const { t } = useTranslation();

  return (
    <section className="how-to-win">
      <div className="how-to-win__content">
        <div className="how-to-win__content__icons">
          <img src={medal} alt="medal" className="how-to-win__content__icons--left medal" />
          <img src={medal} alt="medal" className="how-to-win__content__icons--center medal" />
          <img src={medal} alt="medal" className="how-to-win__content__icons--right medal" />
        </div>
        <h2 className="how-to-win__content--title">{t('howToWin.howto')}</h2>
        <p className="how-to-win__content--description">{t('howToWin.points')}</p>
        <h2 className="how-to-win__content--title">{t('howToWin.whatcan')}</h2>
        <p className="how-to-win__content--description">{t('howToWin.exchange')}</p>
      </div>
    </section>
  );
};

export default HowToWin;

import { ACTIONS } from './programConstants';

export function createProgramReducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_STEP:
      return { ...state, step: action.payload };
    case ACTIONS.GET_PROGRAM:
      return { ...state, ...action.payload };
    case ACTIONS.ADD_DETAILS:
      return { ...state, ...action.payload };
    case ACTIONS.ADD_TEMPLATE:
      return { ...state, ...action.payload };
    default:
      throw new Error(`Unhandled action ${action.type}`);
  }
}

/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { path, propOr, isEmpty, pathOr } from 'ramda';
import Loader from 'react-loader-spinner';
import { useMutation } from '@apollo/react-hooks';

import { GET_FORMS } from 'apollo/queries';
import { BULK_CREATE_SURVEY_INVITATION } from 'apollo/mutations';
import { useAuthQuery, useFormInput } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { useToggle } from 'utils/hooks/useToggle';
import { ROUTES, ROLES } from 'utils/constants';
import showNotification from 'utils/showNotification';
import { getUserRole } from 'utils/getUserRole';
import { LEFT_MENU } from 'utils/leftMenu';

import FormsList from 'components/Forms/FormsList';
import NoFormsFound from 'components/Forms/NoFormsFound';
import PatientsListModal from 'components/Forms/PatientsListModal';
import Header from 'components/Header';
import Icon from 'components/Icon';
import SolidButton from 'components/SolidButton';
import SearchFilter from 'components/SearchFilter';
import Pagination from 'components/Pagination';
import { NoMembers } from 'components/EmptyState';
import PreviewModal from 'components/CreateForm/PreviewModal';

import noForms from 'assets/images/no-patients.svg';

import './forms.scss';

const rowsPerPage = 10;
const Forms = (props) => {
  useRedirectByRole([ROLES.CLINIC, ROLES.SUPER_CLINIC, ROLES.SPECIALIST]);
  const { userRole } = getUserRole();
  const { loading, data, refetch } = useAuthQuery(GET_FORMS);
  const allForms = propOr([], 'getClinicForms')(data);
  const changeRoute = path(['history', 'push'])(props);
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState('');
  const [filtered, setFiltered] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [selectedFormPreview, setSelectedFormPreview] = useState({});
  const [bulkCreateSurveyInvitation] = useMutation(BULK_CREATE_SURVEY_INVITATION);

  const [showPatientsListModal, setShowPatientsListModal] = useToggle();
  const searchForms = useFormInput('');
  const { value = '' } = { ...searchForms };
  const [isFormPreview, setIsFormPreview] = useToggle();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNewResults = (results) => {
    const rIds = (results || []).map(({ id, title, createdAt }) => `${id}${title}${createdAt}`).join(',');
    const fIds = (filtered || []).map(({ id, title, createdAt }) => `${id}${title}${createdAt}`).join(',');
    return rIds !== fIds;
  };

  const translateType = (typeForm) => {
    switch (typeForm) {
      case 'assesment': {
        return 'Valoración';
      }
      case 'followup': {
        return 'Seguimiento';
      }
      case 'survey': {
        return 'Encuesta';
      }
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    let temporal = allForms;
    if (value) {
      temporal = allForms.filter((form) => {
        const { title = '', typeForm = '' } = { ...form };
        const formFound = `${title}-${translateType(typeForm)}`.toLowerCase();
        return formFound.indexOf(`${value}`.toLowerCase()) !== -1;
      });
    }
    const totalPages = temporal.length > 0 ? Math.ceil(temporal.length / rowsPerPage) : 0;
    if (page !== 1) setPage(1);
    if (pages !== totalPages) setPages(totalPages);
    if (isNewResults(temporal)) setFiltered(temporal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allForms, value]);

  useEffect(() => {
    const init = page * rowsPerPage - rowsPerPage;
    const end = init + rowsPerPage;
    setForms(filtered.slice(init, end));
  }, [filtered, page]);

  const onBulkCreateSurveyInvitation = async (patientIds, callback) => {
    setSpinner(true);
    const response = await bulkCreateSurveyInvitation({
      variables: {
        patientIds,
        formId: selectedForm,
      },
    });
    const errors = pathOr([], ['data', 'bulkCreateSurveyInvitation', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner(false);

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? [`Se ha enviado la encuesta exitosamente.`] : errors,
    });

    if (emptyErrors) {
      refetch();
      callback();
      setShowPatientsListModal();
    }
  };

  const handleSelectForm = (isSelectedForm, currentSelectedForm) => {
    setSelectedForm(isSelectedForm ? '' : currentSelectedForm);
  };

  const handleCreateForm = () => {
    changeRoute(ROUTES.CREATE_FORM);
  };

  const handlePageChange = (currentPage) => {
    setPage(currentPage);
  };

  const handleSelectFormPreview = (currentForm) => {
    setSelectedFormPreview(currentForm);
    setIsFormPreview();
  };

  return (
    <div className="d-flex">
      {LEFT_MENU[userRole]}
      <div className="dashboard__main">
        <Header title="Formularios" shadow />
        <div className="dashboard__content--patients">
          {loading && (
            <div className="loading-container">
              <Loader type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading || spinner} />
            </div>
          )}
          {!loading && isEmpty(allForms) && (
            <NoMembers
              title="¡Aún no tienes formularios configurados!"
              subtitle="Inicia configurando un formulario para la valoración de tus pacientes."
              button="Crear Formulario"
              handleClick={handleCreateForm}
              image={noForms}
            />
          )}
          {!loading && !isEmpty(allForms) && (
            <div className="forms">
              <div className="forms__search">
                <SearchFilter searchItems={searchForms} placeholder="Buscar un formulario" />
                {!isEmpty(selectedForm) && (
                  <div className="send-icon" data-tip="Enviar encuesta" data-for="survey">
                    <Icon onClick={setShowPatientsListModal} name="send" className="icon" />
                    <ReactTooltip place="right" effect="solid" id="survey" />
                  </div>
                )}
                <SolidButton onClick={handleCreateForm} className="slim">
                  Crear Formulario
                </SolidButton>
              </div>
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '5%' }}> </th>
                    <th style={{ width: '50%' }}>Título</th>
                    <th style={{ width: '10%' }}>Tipo</th>
                    <th style={{ width: '25%' }}>Fecha/Hora</th>
                    <th style={{ width: '10%' }}> </th>
                  </tr>
                </thead>
                <tbody>
                  <FormsList
                    forms={forms}
                    handleSelectForm={handleSelectForm}
                    handleSelectFormPreview={handleSelectFormPreview}
                    selectedForm={selectedForm}
                    changeRoute={changeRoute}
                    translateType={translateType}
                    refetchForms={refetch}
                  />
                  {isEmpty(forms) && <NoFormsFound />}
                </tbody>
              </table>
              <Pagination currentPage={page} totalPages={pages} onPageChange={handlePageChange} />
            </div>
          )}
        </div>
      </div>
      {showPatientsListModal && (
        <PatientsListModal
          modalIsOpen={showPatientsListModal}
          onCloseModal={setShowPatientsListModal}
          onBulkCreateSurveyInvitation={onBulkCreateSurveyInvitation}
        />
      )}
      {!isEmpty(selectedFormPreview) && selectedFormPreview.content && (
        <PreviewModal modalIsOpen={isFormPreview} closeModal={setIsFormPreview} form={selectedFormPreview} />
      )}
    </div>
  );
};

export default Forms;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import { useGetShortVideos } from 'components/shortVideos/service';
import { useFormInput } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROUTES, ROLES } from 'utils/constants';

import ShortVideoRow from 'components/shortVideos/ShortVideoRow';
import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SearchFilter from 'components/SearchFilter';
import SolidButton from 'components/SolidButton';
import NoShortVideosFound from 'components/Forms/NoFormsFound';
import { NoMembers } from 'components/EmptyState';

import noAudios from 'assets/images/no-data.svg';

const ShortVideos = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const { push } = useHistory();

  const { loading, allShortVideos } = useGetShortVideos();

  const [shortVideos, setShortVideos] = useState([]);
  const searchAudio = useFormInput('');
  const { value = '' } = searchAudio;

  useEffect(() => {
    let temporal = allShortVideos;
    if (value) {
      temporal = allShortVideos.filter((audio) => {
        const { title } = audio;
        return `${title}`.toLowerCase().includes(value.toLowerCase());
      });
    }
    setShortVideos(temporal);
  }, [allShortVideos, value]);

  const handleChangeRoute = () => {
    push(ROUTES.CREATE_SHORT_VIDEO);
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenu />
        <div className="dashboard__main">
          <Header title={t('leftMenuHeader.shortVideos')} shadow />
          <div className="templates__content dashboard__content--patients">
            {!loading && isEmpty(allShortVideos) && (
              <NoMembers
                title={t('shorVideos.emptyState.title')}
                subtitle={t('shorVideos.emptyState.description')}
                button={t('shorVideos.createBtn')}
                handleClick={handleChangeRoute}
                image={noAudios}
              />
            )}
            {!loading && !isEmpty(allShortVideos) && (
              <div className="forms">
                <div className="forms__search">
                  <SearchFilter searchItems={searchAudio} placeholder={t('placeholder.shortVideo')} />
                  <SolidButton onClick={handleChangeRoute} className="slim">
                    {t('shorVideos.createBtn')}
                  </SolidButton>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '30%' }}>{t('audioList.tableHeader.title')}</th>
                      <th style={{ width: '60%' }}>{t('audioList.tableHeader.description')}</th>
                      <th style={{ width: '10%' }}> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(shortVideos || []).map((shortVideo) => {
                      const { id } = shortVideo;
                      return <ShortVideoRow key={id} shortVideo={shortVideo} />;
                    })}

                    {isEmpty(shortVideos) && <NoShortVideosFound />}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </section>
      <Loader className="spinner" type="ThreeDots" color="#495fd7" height={100} width={100} visible={false} />
    </>
  );
};

export default ShortVideos;

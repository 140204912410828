import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { isEmpty } from 'ramda';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { customStyles } from 'utils/styles';
import showNotification from 'utils/showNotification';
import { validationSchemaBuilder } from 'utils/validationSchema';
import { useToggle } from 'utils/hooks/useToggle';
import { useAuthQuery, useTeamMembersList } from 'utils/hooks';
import { GET_FORMS } from 'apollo/queries';

import {
  RangeInput,
  MultiSelect,
  TextAreaInput,
  TextInput,
  DateInput,
  NumberInput,
  FileInput,
  SearchInput,
} from 'components/FormComponents';
import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';
import Button from 'components/Button';

import noForms from 'assets/images/no-results.svg';
import noPatients from 'assets/images/no-patients.svg';

import './followUpModal.scss';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: '680px',
    maxHeight: '95vh',
  },
};

const FollowUpModal = ({ modalIsOpen, onCloseModal, onSubmit }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data, refetch } = useAuthQuery(GET_FORMS, {
    variables: {
      type: 'followup',
    },
  });
  const { getClinicForms: forms = [] } = { ...data };
  const { teamMembersOptions = [] } = useTeamMembersList();

  const [selectedForm, setSelectedForm] = useState('');
  const [isEnabledToClose, setIsEnabledToClose] = useToggle(true);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (selectedForm) {
      setIsEnabledToClose();
    }
  }, [selectedForm]);

  const initialFormState = (fields = []) => {
    const initial = {};
    fields.forEach((field) => {
      const { id = '', type = 'text', label = '' } = { ...field };
      switch (type) {
        case 'verification':
          initial[id] = false;
          break;
        case 'block':
          initial[id] = label;
          break;
        default:
          initial[id] = '';
      }
    });
    return initial;
  };

  const getFollowupForm = (form) => {
    if (form) {
      const { content = [], id: followupFormId = '' } = { ...form };
      const [{ groupTitle = '', questions = [] }] = [...content];
      return {
        followupForm: groupTitle ? questions : content,
        followupFormId,
      };
    }
    return { followupForm: [], followupFormId: '' };
  };

  const formOptions = (forms || []).map(({ title: label, id: value }) => ({ label, value }));
  const findForm = (forms || []).find(({ id }) => id === selectedForm);
  const { followupForm, followupFormId } = getFollowupForm(findForm);

  const initialFollowup = initialFormState(followupForm);
  const validationSchema = validationSchemaBuilder(followupForm);

  const onLeaveForm = () => {
    if (isEnabledToClose) {
      setSelectedForm('');
      onCloseModal();
    } else {
      showNotification({ type: 'warning', messages: [t('notification.warning.followupModal')] });
      setIsEnabledToClose();
    }
  };

  return (
    <Modal
      modalIsOpen={modalIsOpen}
      closeModal={onCloseModal}
      modalProps={modalProps}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      closeable={false}
    >
      {isEmpty(forms) && (
        <div className="forms-empty-state">
          <div className="forms-empty-state__content">
            <p className="title">¡Aún no tienes formularios configurados!</p>
            <p className="subtitle">Inicia configurando un formulario para el segumiento de tus pacientes.</p>
            <img src={noPatients} alt="no patients" className="forms-empty-state__content-image" />
          </div>
          <div className="followup-buttons">
            <Button type="button" onClick={onLeaveForm} className="mr-3" category="secondary-rounded">
              Cancelar
            </Button>
            <Button type="button" category="rounded" onClick={() => history.push('/crear-formulario')}>
              Continuar
            </Button>
          </div>
        </div>
      )}
      {!isEmpty(forms) && (
        <>
          <div className="select-forms-container">
            <div className="select-forms-container__header">
              <h3 className="title">Indicadores de mejoría para el seguimiento por semana</h3>
              <AiOutlineCloseCircle onClick={onLeaveForm} />
            </div>
            <label htmlFor="select-custom-form">Selecciona el tipo de formato de seguimiento que utilizarás</label>
            <Select
              id="select-custom-form"
              options={formOptions}
              styles={customStyles}
              onChange={({ value }) => setSelectedForm(value)}
            />
          </div>
          {isEmpty(selectedForm) && (
            <div className="no-form-selected">
              <img src={noForms} alt="no form selected" />
            </div>
          )}
          <div>
            {!isEmpty(selectedForm) && (
              <Formik
                initialValues={initialFollowup}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => onSubmit(values, followupForm, followupFormId)}
              >
                {(formikProps) => (
                  <Form>
                    {!isEmpty(teamMembersOptions) && (
                      <Field
                        type="text"
                        label="Profesional"
                        name="ownerId"
                        component={MultiSelect}
                        options={teamMembersOptions}
                        placeholder="Seleccione el Profesional para el acompañamiento"
                      />
                    )}
                    {forms
                      .filter(({ id }) => selectedForm.indexOf(id) !== -1)
                      .map((form) => {
                        const { followupForm: currentSelectedForm, followupFormId: currentSelectedFormId } =
                          getFollowupForm(form);
                        return (
                          <div key={currentSelectedFormId}>
                            {(currentSelectedForm || []).map((formField) => {
                              const {
                                type = 'text',
                                label = '',
                                id = '',
                                required = false,
                                includeNA = false,
                                multi = false,
                                options = [],
                                min = 0,
                                max = 10,
                              } = {
                                ...formField,
                              };
                              switch (type) {
                                case 'multiple-selection':
                                  return (
                                    <Field
                                      key={id}
                                      type="text"
                                      label={label}
                                      isRequired={required}
                                      name={id}
                                      component={MultiSelect}
                                      options={options}
                                      isMulti={multi}
                                      placeholder="Seleccione..."
                                    />
                                  );
                                case 'numeric':
                                  return (
                                    <Field
                                      isRequired={required}
                                      key={id}
                                      label={label}
                                      name={id}
                                      component={NumberInput}
                                    />
                                  );
                                case 'numeric-scale':
                                  return (
                                    <Field
                                      key={id}
                                      label={label}
                                      name={id}
                                      isRequired={required}
                                      component={RangeInput}
                                      orientation="horizontal"
                                      includeNA={includeNA}
                                      min={Number(min)}
                                      max={Number(max)}
                                    />
                                  );
                                case 'date':
                                  return (
                                    <Field
                                      key={id}
                                      label={label}
                                      name={id}
                                      component={DateInput}
                                      isRequired={required}
                                    />
                                  );
                                case 'file':
                                  return (
                                    <Field
                                      key={id}
                                      label={label}
                                      name={id}
                                      component={FileInput}
                                      isRequired={required}
                                    />
                                  );
                                case 'verification':
                                  return (
                                    <Field
                                      key={id}
                                      name={id}
                                      render={({ field }) => (
                                        <div className="form__input-container assessment__checkbox__input">
                                          <input
                                            {...field}
                                            type="checkbox"
                                            checked={field.value}
                                            onChange={() => {
                                              formikProps.setFieldValue(id, !field.value);
                                            }}
                                          />
                                          <label className="form__label">{label}</label>
                                        </div>
                                      )}
                                    />
                                  );
                                case 'block':
                                  return (
                                    <Field
                                      key={id}
                                      label={label}
                                      name={id}
                                      component={TextInput}
                                      className="field-block"
                                    />
                                  );
                                case 'cie10':
                                  return (
                                    <Field
                                      key={id}
                                      label={label}
                                      name={id}
                                      component={SearchInput}
                                      isRequired={required}
                                    />
                                  );
                                default:
                                  return (
                                    <Field
                                      key={id}
                                      type="text"
                                      label={label}
                                      isRequired={required}
                                      name={id}
                                      minRows={multi ? 20 : null}
                                      component={multi ? TextAreaInput : TextInput}
                                      className={multi ? 'observations-field' : ''}
                                    />
                                  );
                              }
                            })}
                          </div>
                        );
                      })}
                    <div className="followup-buttons">
                      <SolidButton type="button" onClick={onLeaveForm} className="mr-3 secondary">
                        Cancelar
                      </SolidButton>
                      {!isEmpty(selectedForm) && <SolidButton type="submit">Guardar</SolidButton>}
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </>
      )}
      <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={loading} />
    </Modal>
  );
};

FollowUpModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FollowUpModal;

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { propOr } from 'ramda';
import { getIn } from 'formik';

import SolidButton from 'components/SolidButton';

import { baseStyle, activeStyle, acceptStyle, rejectStyle } from 'utils/styles';

const baseStyleInput = {
  ...baseStyle,
  width: '50%',
  height: 100,
};

const FileInput = (props) => {
  const { t } = useTranslation();
  const { field, form, label, isRequired, labelClassName } = { ...props };
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error;

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (currAcceptedFiles) => {
      form.setFieldValue(name, currAcceptedFiles);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyleInput,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const files = (acceptedFiles || []).map((file) => (
    <li key={file.path}>
      {file.path} <AiOutlineCheckCircle color="#46c2ad" size={20} />
    </li>
  ));

  return (
    <div className="form__input-container mt-3">
      {label && (
        <label htmlFor={name} className={`form__label ${labelClassName}`}>
          {label} {isRequired && <span className="text-pink">*</span>}
        </label>
      )}
      <div className="form__file-container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p style={{ textAlign: 'center', marginBottom: 4 }}>{t('formLabel.file.text')}</p>
          <SolidButton onClick={open} className="min-width-100 slim">
            {t('formLabel.file.button')}
          </SolidButton>
        </div>
        <ul className="form__accepted-files">{files}</ul>
      </div>
      {hasError && <div className="form__error form__error_dropfiles mt-3">{error}</div>}
    </div>
  );
};

export default FileInput;

import { useState, useEffect } from 'react';
import { isEmpty } from 'ramda';

import { GET_SQUEEZE } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetSqueeze = (squeezeId = '') => {
  const {
    loading = false,
    data = {},
    refetch = () => {},
  } = squeezeId
    ? useAuthQuery(GET_SQUEEZE, {
        variables: { squeezeId },
      })
    : {};
  const { squeeze = {} } = { ...data };

  const [currentSqueeze, setCurrentAudio] = useState({});

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!isEmpty(squeeze) && isEmpty(currentSqueeze)) {
      setCurrentAudio(squeeze);
    }
  }, [squeeze]);

  return { loading, squeeze: currentSqueeze };
};

import React from 'react';
import Button from '../../Button';
import healtphyLogo from '../../../assets/images/logo-blue.svg';
import termsData from '../../../assets/terms.json';

const Terms = ({ onContinue }) => {
  return (
    <div className={'signup__terms'}>
      <div className={'logo-container'}>
        <img src={healtphyLogo} alt='logo' className='logo' />
      </div>
      <p className={'title'}>Términos y condiciones</p>
      <div className={'container'}>
        {termsData.map(({ title, content, items }, index) => (
          <div key={`${index}`}>
            {title && <p className={'term-title'}>{title}</p>}
            {!!content &&
              content.map((cnt, i) => (
                <p className={'term-content'} key={i}>
                  {cnt}
                </p>
              ))}
            {items && (
              <ul className={'term-list'}>
                {items.map((itm, i) => (
                  <li key={i}>
                    <p className={'term-content'}>{itm}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
      <div className={'footer'}>
        <Button type={'button'} category={'rounded'} onClick={onContinue}>
          Aceptar
        </Button>
      </div>
    </div>
  );
};

export default Terms;

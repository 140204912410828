import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { buildCustomData, noGroupedContent, groupedContent } from '../FollowUpSurveyViewModal/helpers';

const FollowUpInfo = ({ data }) => {
  const {
    createdAt = '',
    content: followupSurveyForm = [],
    answerFiles = [],
    form = {},
    observations,
    owner = '',
    groupPoints = [],
    totalPoints = 0,
  } = { ...data };
  const { title: surveyTitle = '' } = { ...form };
  const content = !!followupSurveyForm && followupSurveyForm.length > 0 ? followupSurveyForm : buildCustomData(data);
  const isGrouped = !!content[0].groupTitle;

  return (
    <div>
      <div className="patient__modal_title">
        <h2 className="patient__modal_label">
          {surveyTitle || 'Detalles del seguimiento por semana'}
          {owner ? ` - ${owner}` : ''}
        </h2>
        <span className="patient__modal_timestamp">{moment(createdAt).format('DD/MM/YYYY - hh:mma')}</span>
      </div>
      <div className="info">
        {isGrouped ? groupedContent({ content, groupPoints, totalPoints }) : noGroupedContent(content)}
        {!!totalPoints && <p className="group-title_points group-title_points--total">Puntos totales: {totalPoints}</p>}
        {!!answerFiles.length && <h3 className="group-title">Archivos</h3>}
        {answerFiles.map((answer) => {
          const { id: fileId = '', label = '', file = {} } = { ...answer };
          const { original: fileUrl = '' } = { ...file };
          return (
            <div key={fileId} className="item">
              <p className="label">{label}</p>
              <a className="value-link" href={fileUrl} target="_blank" rel="noopener noreferrer">
                Link archivo
              </a>
            </div>
          );
        })}
        {observations && (
          <div className="item">
            <p className="label">Observaciones</p>
            <p className="value bigtext">{observations}</p>
          </div>
        )}
      </div>
    </div>
  );
};

FollowUpInfo.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default FollowUpInfo;

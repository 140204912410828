import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';

export const PerksList = ({ isClinic }) => {
  const { t } = useTranslation();

  const clinicPerks = [
    t('billing.clinicPerks.library'),
    t('billing.clinicPerks.plans'),
    t('billing.clinicPerks.custom'),
    t('billing.clinicPerks.remote'),
    t('billing.clinicPerks.statistics'),
    t('billing.clinicPerks.tailor'),
  ];

  const companyPerks = [
    t('billing.companyPerks.surveys'),
    t('billing.companyPerks.plans'),
    t('billing.companyPerks.programs'),
    t('billing.companyPerks.appointments'),
    t('billing.companyPerks.support'),
    t('billing.companyPerks.statistics'),
  ];

  const getList = isClinic ? clinicPerks : companyPerks;
  return (
    <ul className="features__list">
      {(getList || []).map((perk) => (
        <li key={perk}>
          <Icon name="checked" className="icon" />
          <p>{perk}</p>
        </li>
      ))}
    </ul>
  );
};

import { useEffect } from 'react';

import { GET_SHORT_VIDEO } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetShortVideo = (shortVideoId = '') => {
  const {
    loading = false,
    data = {},
    refetch = () => {},
  } = shortVideoId
    ? useAuthQuery(GET_SHORT_VIDEO, {
        variables: { shortVideoId },
      })
    : {};
  const { shortVideo = {} } = { ...data };

  useEffect(() => {
    refetch();
  }, []);

  return { loading, shortVideo };
};

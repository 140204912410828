import { useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'ramda';

import { GET_READING_TEMPLATE } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetReadingTemplate = (templateId = '') => {
  const {
    loading = false,
    data = {},
    refetch = () => {},
  } = templateId
    ? useAuthQuery(GET_READING_TEMPLATE, {
        variables: { templateId },
      })
    : {};
  const { readingTemplate = {} } = { ...data };

  const [template, setTemplate] = useState({});

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!isEmpty(readingTemplate) && isEmpty(template)) {
      setTemplate(readingTemplate);
    }
  }, [readingTemplate, template]);

  const handleAddReading = useCallback((reading) => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      readings: [...(prevTemplate.readings || []), reading],
    }));
  }, []);

  const handleRemoveReading = useCallback((readingId) => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      readings: prevTemplate.readings.filter(({ id }) => id !== readingId),
    }));
  }, []);

  return {
    loading,
    template,
    addReading: handleAddReading,
    removeReading: handleRemoveReading,
  };
};

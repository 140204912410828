import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { GrClose } from 'react-icons/gr';

import { formatCurrency } from 'utils/formatCurrency';
import { getFullDate } from 'components/conversations/utils';

import Modal from 'components/Modal';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: '768px',
    padding: 24,
  },
};

const EmployeeSpend = ({ modalIsOpen, toggleModal, selectedEmployee, currency }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { name, currentSpendLimitAmount, companyAllowanceAmount, invoiceItems = [] } = { ...selectedEmployee };

  const formatEmployeeSpend = formatCurrency({ language, currency, amount: currentSpendLimitAmount });
  const formatCompanyAllowance = formatCurrency({ language, currency, amount: companyAllowanceAmount });
  return (
    <Modal modalProps={modalProps} modalIsOpen={modalIsOpen} closeModal={toggleModal} closeable={false}>
      <div className="employee-spend">
        <GrClose className="employee-spend__close" onClick={toggleModal} />
        <header className="employee-spend__header">
          <div className="employee-spend__header_side">
            <span className="employee-spend__header_amount">
              <strong>{formatEmployeeSpend}</strong>
            </span>
            <h2 className="employee-spend__header_label">{t('employees.employeeSpend.header.employeLabel')}</h2>
          </div>
          <div className="employee-spend__header_side right">
            <span className="employee-spend__header_amount company-limit">
              <strong>{formatCompanyAllowance}</strong>
            </span>
            <h2 className="employee-spend__header_label">{t('employees.employeeSpend.header.companyLabel')}</h2>
          </div>
        </header>
        <section className="employee-spend__table">
          {isEmpty(invoiceItems) ? (
            <p className="employee-spend__table_empty">
              <strong>{name}</strong> {t('employees.employeeSpend.emptyInvoiceItems.label')}
            </p>
          ) : (
            <table className="invoices__table">
              <thead className="invoices__table_head">
                <tr className="head-row">
                  <th style={{ width: '50%' }}>{t('invoices.tableHeader.program')}</th>
                  <th style={{ width: '25%' }}>{t('invoices.tableHeader.date')}</th>
                  <th style={{ width: '25%' }}>{t('invoices.tableHeader.amount')}</th>
                </tr>
              </thead>
              <tbody className="invoices__table_body">
                {(invoiceItems || []).map((invoiceItem) => {
                  const { id = '', description = '', createdAt = '', amount = '' } = { ...invoiceItem };
                  const formatDate = getFullDate({ date: new Date(createdAt), lng: language, options: {} });
                  return (
                    <tr key={id} className="body-row">
                      <td style={{ width: '50%' }}>{description}</td>
                      <td style={{ width: '25%' }}>{formatDate}</td>
                      <td style={{ width: '25%' }} className="body-row__amount">
                        {formatCurrency({ language, currency, amount })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </section>
      </div>
    </Modal>
  );
};

EmployeeSpend.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.shape({}).isRequired,
  currency: PropTypes.string.isRequired,
};

export default EmployeeSpend;

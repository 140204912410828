import React, { useState, useEffect } from 'react';
import { path, propOr, isEmpty } from 'ramda';
import Loader from 'react-loader-spinner';

import { GET_PLAN } from 'apollo/queries';
import { capitalize } from 'utils/string';
import { ROLES, ROUTES } from 'utils/constants';
import { useAuthQuery } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { getUserRole } from 'utils/getUserRole';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SolidButton from 'components/SolidButton';
import PlanView from 'components/Plan/PlanView';
import ExerciseModal from 'components/CreatePatient/ExerciseModal';
import { StoreConsumer } from 'components/StoreProvider';

import './plan.scss';

const Plan = (props) => {
  useRedirectByRole([ROLES.CLINIC, ROLES.SUPER_CLINIC]);
  const { isSuperClinic } = getUserRole();
  const [openModal, setOpenModal] = useState(false);
  const [exercise, setExercise] = useState({});
  const changeRoute = path(['history', 'push'])(props);
  const replaceRoute = path(['history', 'replace'])(props);
  const search = path(['history', 'location', 'search'])(props);
  const planId = search && search.split('=')[1];
  const { loading, data, refetch } = useAuthQuery(GET_PLAN, {
    variables: { planId },
  });

  useEffect(() => {
    refetch();
  }, []);

  const plan = propOr({}, 'plan')(data);

  const onEditPlan = (setStoreData) => () => {
    const { weeks = [], bodyParts = [] } = { ...plan };
    const parsedWeeks = weeks.map((week, index) => {
      const { title = `Semana ${index + 1}`, days: weekDays = [] } = { ...week };
      const days = weekDays
        .map((day) => {
          const { weekNumber, exercises = [] } = { ...day };
          return exercises.map((ex) => ({ ...ex, weekNumber }));
        })
        .reduce((a, b) => [...a, ...b], []);
      return { ...week, title, days };
    });
    setStoreData('weeks', parsedWeeks);
    setStoreData('customPlan', {
      ...plan,
      bodyParts: (bodyParts || []).map(({ title }) => title),
    });
    changeRoute(ROUTES.EDIT_PLAN);
  };
  const onGoBack = (setStoreData) => () => {
    setStoreData('weeks', []);
    setStoreData('customPlan', {});
    replaceRoute(ROUTES.PLANS);
  };
  const onOpenModal = (item) => {
    setExercise(item);
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setExercise({});
    setOpenModal(false);
  };
  return (
    <StoreConsumer>
      {({ setStoreData }) => {
        const { title = '', description = '', bodyParts = [], sharedTemplate } = { ...plan };
        return (
          <div className="d-flex hvh-100">
            <LeftMenu />
            <div className="dashboard__main flex-fill">
              <Header title="Detalle del plan" />
              <div className="plan__title">
                <div className="title-container">
                  <div
                    className={`title-container-left title-container-left--${isEmpty(bodyParts) && 'only-description'}`}
                  >
                    <p className="title">{capitalize(title)}</p>
                    <p className="description">{description}</p>
                  </div>
                  {!isEmpty(bodyParts) && (
                    <div className="title-container-right">
                      <p className="title-right">Partes del cuerpo relacionadas</p>
                      <div className="parts-list">
                        {bodyParts.map(({ title: bodyPartTitle }) => (
                          <span key={bodyPartTitle} className="parts-list-title">
                            {bodyPartTitle}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="buttons">
                  <SolidButton className="secondary mr-2" onClick={onGoBack(setStoreData)}>
                    Atrás
                  </SolidButton>
                  {(isSuperClinic || !sharedTemplate) && (
                    <SolidButton onClick={onEditPlan(setStoreData)}>Editar</SolidButton>
                  )}
                </div>
              </div>
              <div className="dashboard__content plan__view">
                <PlanView plan={plan} onExercise={onOpenModal} />
              </div>
            </div>
            <ExerciseModal open={openModal} onCloseModal={onCloseModal} exercise={exercise} />
            <Loader type="ThreeDots" color="#495fd7" height={100} width={100} className="spinner" visible={loading} />
          </div>
        );
      }}
    </StoreConsumer>
  );
};

export default Plan;

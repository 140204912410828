import React from 'react';
import logoGray from 'assets/images/logo-gray.svg';

const Powered = () => (
  <>
  	<p className={'powered'}>Powered by</p>
  	<img src={logoGray} alt={'logo Healthphy'}/>
  </>
);

export default Powered;

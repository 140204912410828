import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { getIn } from 'formik';

const DropDown = ({
  field,
  form,
  label,
  optionLabel,
  options,
  optionValue,
  optionDisplay,
  isRequired,
  labelClassName,
  className,
  isFSblocked,
  ...props
}) => {
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error;
  const invalidClass = hasError ? 'is-invalid' : '';
  const fsClass = isFSblocked ? 'fs-block' : '';

  return (
    <div className="form__input-container">
      {label && (
        <label htmlFor={name} className={`form__label ${labelClassName}`}>
          {label}
          {' '}
          {isRequired && <span className="text-pink">*</span>}
        </label>
      )}
      <select
        id={name}
        className={`form__input ${className} ${fsClass} ${invalidClass}`}
        {...props}
        {...field}
      >
        {optionLabel && <option value="">{optionLabel}</option>}
        {options.map((option) => {
          const value = !optionValue ? option : option[optionValue];
          const display = !optionDisplay ? option : option[optionDisplay];

          return (
            <option value={value} key={value}>
              {display}
            </option>
          );
        })}
      </select>
      {hasError && <div className="form__error">{error}</div>}
    </div>
  );
};

DropDown.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  isRequired: PropTypes.bool,
  isFSblocked: PropTypes.bool,
  optionLabel: PropTypes.string,
  options: PropTypes.array.isRequired,
};

DropDown.defaultProps = {
  className: '',
  label: '',
  labelClassName: '',
  isRequired: false,
  isFSblocked: false,
  optionLabel: '',
};

export default DropDown;

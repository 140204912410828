import { useState } from 'react';

const STEPS = {
  TERMS: 'TERMS_CONDITIONS',
  LICENSE: 'LICENSE',
  INTRO: 'INTRO',
  PROFILE: 'PROFILE_INFO',
  COMPANY_PAYMENT: 'COMPANY_PAYMENT'
};

export const useSignUpSteps = () => {
  const [step, setStep] = useState(STEPS.LICENSE);
  const handleStep = (step) => {
    setStep(step);
  };
  return { step, handleStep, STEPS };
};
import React, { useContext, createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'ramda';
import Loader from 'react-loader-spinner';

import { useToggle } from 'utils/hooks/useToggle';
import showNotification from 'utils/showNotification';
import { useCreateAvailability } from './service/useCreateAvailability';
import { useGetAvailabilities } from './service/useGetAvailabilities';

import { weekDays, handleSringToNumber } from './utils';

const CreateAvailabilityContext = createContext();

const CreateAvailabilityProvider = ({ children }) => {
  const { t } = useTranslation();
  const { availabilities, loading, allTimeZones, selectedTimezone, handleSelectTimezone } = useGetAvailabilities();

  const [allAvailabilities, setAllAvailabilities] = useState(() => weekDays);
  const [selectedDay, setSelectedDay] = useState('');
  const [isOpenAddIntervalsModal, setIsOpenAddIntervalsModal] = useToggle();
  const { onCreateAvailability, spinner } = useCreateAvailability(allAvailabilities, selectedTimezone);

  useEffect(() => {
    if (!isEmpty(availabilities)) {
      setAllAvailabilities((prevAvailabilities) =>
        prevAvailabilities.map((availability) => {
          const isDayActive = availabilities.find(
            (availabilityItem) => availabilityItem?.weekDay === availability.weekDay,
          );
          if (isDayActive) {
            return {
              ...availability,
              ...isDayActive,
              intervals: [...availability.intervals, ...isDayActive.intervals].map((interval) => ({
                ...interval,
                ...(!interval.id && {
                  id: uuid(),
                }),
              })),
            };
          }
          return availability;
        }),
      );
    }
  }, [availabilities]);

  const handleSelectDay = (selectedId) => {
    setIsOpenAddIntervalsModal();
    setSelectedDay(selectedId);
  };

  const handleAddInterval = (interval) => {
    const findSelectedDay = allAvailabilities.find((day) => day.id === selectedDay);
    const getIntervalValues = findSelectedDay.intervals.map((int) => [
      handleSringToNumber(int.start_at),
      handleSringToNumber(int.end_at),
    ]);
    const isIntervalSelected = getIntervalValues.some(
      ([start, end]) =>
        (handleSringToNumber(interval.start_at) >= start && handleSringToNumber(interval.start_at) <= end) ||
        (handleSringToNumber(interval.end_at) >= start && handleSringToNumber(interval.end_at) <= end),
    );
    if (isIntervalSelected) {
      showNotification({ type: 'warning', messages: [t('notification.warning.availability')] });
      return;
    }

    setAllAvailabilities((prevAvailabilities) =>
      prevAvailabilities.map((availability) => {
        if (availability.id === selectedDay) {
          return { ...availability, intervals: [...availability.intervals, { ...interval, id: uuid() }] };
        }
        return availability;
      }),
    );
    setSelectedDay('');
    setIsOpenAddIntervalsModal();
  };

  const handleRemoveInterval = (intervalId) => {
    setAllAvailabilities((prevAvailabilities) =>
      prevAvailabilities.map((availability) => ({
        ...availability,
        intervals: availability.intervals.filter((interval) => interval.id !== intervalId),
      })),
    );
  };

  const isButtonDisabled = allAvailabilities.every((availability) => isEmpty(availability.intervals));

  const values = {
    allAvailabilities,
    onCreateAvailability,
    handleSelectDay,
    handleRemoveInterval,
    handleAddInterval,
    isOpenAddIntervalsModal,
    setIsOpenAddIntervalsModal,
    selectedTimezone,
    allTimeZones,
    handleSelectTimezone,
    isButtonDisabled,
  };

  return (
    <CreateAvailabilityContext.Provider value={values}>
      {children}
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || spinner}
      />
    </CreateAvailabilityContext.Provider>
  );
};

CreateAvailabilityProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useCreateAvailabilityContext() {
  const context = useContext(CreateAvailabilityContext);
  if (!context) {
    throw new Error('Expected an CreateAvailabilityContext somewhere in the react tree to set context value');
  }
  return context;
}

export { useCreateAvailabilityContext, CreateAvailabilityProvider };

import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { BsFillBellFill, BsBell, BsDot, BsClock } from 'react-icons/bs';
import { isEmpty, pathOr } from 'ramda';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';

import showNotification from 'utils/showNotification';
import { MARK_AS_READ } from 'apollo/mutations';

import femaleAvatar from 'assets/images/patient-female.svg';

import './notifications.scss';

const Notifications = ({ notifications, refetch }) => {
  const history = useHistory();

  useEffect(() => {
    refetch();
  }, []);

  const sortedNotifications = useMemo(() => {
    const sorted = notifications.sort((a, b) => {
      const { createdAt: first = '' } = { ...a };
      const { createdAt: second = '' } = { ...b };
      return new Date(second).getTime() - new Date(first).getTime();
    });
    return sorted;
  }, [notifications]);

  const [markAsRead] = useMutation(MARK_AS_READ);

  const onMarkAsRead = async (notificationId, { type, params }) => {
    const { booking = {} } = { ...params };
    const { patient_id: patientId = '' } = { ...booking };

    const response = await markAsRead({
      variables: {
        notificationId,
      },
    });

    const errors = pathOr([], ['data', 'markAsRead', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    if (!emptyErrors) {
      showNotification({ type: 'error', messages: errors });
    }

    if (emptyErrors) {
      refetch();
      if (type === 'BookingNotification') {
        history.push(`/pacientes/${patientId}?tab=4`);
      }
    }
  };

  const buildNotification = (noti) => {
    const { type = '', id = '', createdAt = '', params = {} } = { ...noti };
    const { message = '' } = { ...params };

    switch (type) {
      case 'BookingNotification':
        return (
          <button
            type="button"
            key={id}
            onClick={() => onMarkAsRead(id, { type, params })}
            className="web-notifications__list__body__item"
          >
            <div className="web-notifications__list__body__item__avatar">
              <img src={femaleAvatar} alt="patient avatar" />
            </div>
            <div className="web-notifications__list__body__item__date">
              <p>{message}</p>
              <p>
                <span className="mr-2">
                  <BsClock color="#8093FC" size={18} />
                </span>
                {moment(createdAt).fromNow()}
              </p>
            </div>
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <div className="web-notifications">
      <div className="web-notifications__bell">
        <BsFillBellFill />
      </div>
      {!isEmpty(sortedNotifications) && (
        <div className="web-notifications__dot">
          <BsDot />
        </div>
      )}
      <div className="web-notifications__list">
        <div className="web-notifications__list__header">
          <p>Notificaciones</p>
        </div>
        <div className="web-notifications__list__body">
          {isEmpty(sortedNotifications) && (
            <div className="web-notifications__list__body__empty">
              <BsBell size={78} color="#8093FC" />
              <p>Actualmente no tienes notificaciones en tu bandeja</p>
            </div>
          )}
          {(sortedNotifications || []).map(buildNotification)}
        </div>
      </div>
    </div>
  );
};

Notifications.propTypes = {
  refetch: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      createdAt: PropTypes.string,
      params: PropTypes.shape({
        message: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default Notifications;

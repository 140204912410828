import { useEffect } from 'react';
import { GET_SQUEEZES } from 'apollo/queries';

import { useAuthQuery } from 'utils/hooks';

export const useGetSqueezes = () => {
  const { loading, data, refetch } = useAuthQuery(GET_SQUEEZES);
  const { squeezes: allSqueeze = [] } = { ...data };

  useEffect(() => {
    refetch();
  }, []);
  return { allSqueeze, loading };
};

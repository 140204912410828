export const CREATE_PROGRAM_STEPS = {
  PROGRAM_DETAIL: 'Program Detail',
  READING_TEMPLATES: 'Reading Templates',
  AUDIO: 'Audio',
  COMPLETED: 'Completed',
};

export const ACTIONS = {
  SET_STEP: 'setStep',
  ADD_DETAILS: 'addDetails',
  ADD_TEMPLATE: 'addTemplate',
  GET_PROGRAM: 'getProgram',
};

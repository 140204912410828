import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import noTemplates from 'assets/images/login-employers.png';

import 'react-lazy-load-image-component/src/effects/blur.css';

import './thumb.scss';

const Thumb = ({ source, alt }) => (
  <LazyLoadImage
    src={source || noTemplates}
    alt={alt}
    placeholderSrc={noTemplates}
    effect="blur"
    className="image-thumb"
  />
);

Thumb.propTypes = {
  source: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Thumb;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { isEmpty } from 'ramda';

import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES, PAYMENT_MODE } from 'utils/constants';
import { useFormInput } from 'utils/hooks';
import { useToggle } from 'utils/hooks/useToggle';
import { useGetCompanyInvoices } from 'components/invoices/service';

import LeftMenuEmployers from 'components/LeftMenuEmployers';
import Header from 'components/Header';
import Pagination from 'components/Pagination';
import OndemandMode from 'components/invoices/OndemandMode';
import ReimbursementMode from 'components/invoices/ReimbursementMode';
import OndemandTable from 'components/invoices/OndemandTable';
import ReimbursementTable from 'components/invoices/ReimbursementTable';
import OndemandHeader from 'components/invoices/OndemandHeader';
import ReimbursementHeader from 'components/invoices/ReimbursementHeader';
import Filters from 'components/invoices/Filters';

import './invoices.scss';

const rowsPerPage = 10;
const initialValues = { value: '', label: '' };

const Invoices = () => {
  useRedirectByRole([ROLES.COMPANY]);
  const { t } = useTranslation();

  const { loading, refetch, companyInvoices } = useGetCompanyInvoices();
  const { paymentMode, invoice = {} } = { ...companyInvoices };
  const { invoiceItems = [], currency, period = '' } = { ...invoice };
  const isOndemand = paymentMode === PAYMENT_MODE.ONDEMAND;

  const [invoices, setInvoices] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [month, setMonth] = useState(initialValues);
  const [year, setYear] = useState(initialValues);
  const [updatingPayment, setUpdatingPayment] = useToggle();
  const searchInvoices = useFormInput('');
  const { value = '' } = { ...searchInvoices };

  useEffect(() => {
    if (isEmpty(month.value)) {
      const monthValue = period.slice(0, 2);
      setMonth({ value: monthValue, label: t(`invoices.filters.months.${monthValue}`) });
    }
    if (isEmpty(year.value)) {
      const yearValue = period.slice(3);
      setYear({ value: yearValue, label: yearValue });
    }
  }, [period, month.value, year.value]);

  const isNewResults = (results) => {
    const rIds = (results || []).map(({ id, name, gender }) => `${id}${name}${gender}`).join(',');
    const fIds = (filtered || []).map(({ id, name, gender }) => `${id}${name}${gender}`).join(',');
    return rIds !== fIds;
  };

  useEffect(() => {
    let temporal = invoiceItems;
    if (value) {
      temporal = invoiceItems.filter((invoiceItem) => {
        const { recipient = '', description = '' } = { ...invoiceItem };
        const invoiceFound = `${recipient}-${description}`.toLowerCase();
        return invoiceFound.indexOf(`${value}`.toLowerCase()) !== -1;
      });
    }
    const totalPages = temporal.length > 0 ? Math.ceil(temporal.length / rowsPerPage) : 0;
    if (page !== 1) setPage(1);
    if (pages !== totalPages) setPages(totalPages);
    if (isNewResults(temporal)) setFiltered(temporal);
  }, [invoiceItems, value]);

  useEffect(() => {
    const init = page * rowsPerPage - rowsPerPage;
    const end = init + rowsPerPage;
    setInvoices(filtered.slice(init, end));
  }, [filtered, page]);

  const handlePageChange = (currentPage) => {
    setPage(currentPage);
  };

  const handleSetMonth = (objectMonth) => {
    setMonth(objectMonth);
    refetch({ period: `${objectMonth.value}/${year.value}` });
  };
  const handleSetYear = (objectYear) => {
    setYear(objectYear);
    refetch({ period: `${month.value}/${objectYear.value}` });
  };

  return (
    <>
      <div className="d-flex">
        <LeftMenuEmployers />
        <div className="dashboard__main">
          <Header title={t('leftMenuHeader.companies.invoices')} shadow />
          <section className="invoices">
            {isOndemand ? (
              <OndemandHeader invoice={invoice} updatingPayment={setUpdatingPayment} />
            ) : (
              <ReimbursementHeader invoice={invoice} />
            )}
            <Filters
              searchInvoices={searchInvoices}
              month={month}
              year={year}
              setMonth={handleSetMonth}
              setYear={handleSetYear}
            />
            {isOndemand ? (
              <OndemandMode table={<OndemandTable invoiceItems={invoices} currency={currency} />} />
            ) : (
              <ReimbursementMode table={<ReimbursementTable invoiceItems={invoices} currency={currency} />} />
            )}
            <Pagination currentPage={page} totalPages={pages} onPageChange={handlePageChange} />
          </section>
        </div>
      </div>
      <Loader
        className="spinner"
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        visible={loading || updatingPayment}
      />
    </>
  );
};

export default Invoices;

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { getIn } from 'formik';
import DateTime from 'react-datetime';

import 'react-datetime/css/react-datetime.css';
moment.locale = 'es';
const DateTimeInput = (props) => {
  const { field, form, label, isRequired, labelClassName, className, disabled } = props;
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  return (
    <div className='form__input-container'>
      {label && (
        <label htmlFor={name} className={`form__label ${labelClassName}`}>
          {label} {isRequired && <span className='text-pink'>*</span>}
        </label>
      )}
      <DateTime
        locale='es'
        timeFormat={'hh:mm A'}
        value={field.value}
        isValidDate={() => !error}
        className={className}
        inputProps={{ disabled }}
        onChange={(value) => {
          form.setFieldValue(name, value);
        }}
      />
      {touch && error && <div className='form__error'>{error}</div>}
    </div>
  );
};

DateTimeInput.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
};

DateTimeInput.defaultProps = {
  label: '',
  className: '',
  labelClassName: '',
  isRequired: false,
};

export default DateTimeInput;

import { getAuthInfo } from '../libs/authenticationService';

export const isClinic = () => {
  const fullUser = getAuthInfo();
  return !!fullUser && !!fullUser.user && !!fullUser.user.userType && fullUser.user.userType === 'Clinic';
};

export const getUserRole = () => {
  const { user = null } = getAuthInfo();
  const { userType = null } = { ...user };
  return userType;
};

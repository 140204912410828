import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateProgramContext } from 'components/createProgram/CreateProgramProvider';
import { ACTIONS, CREATE_PROGRAM_STEPS } from 'components/createProgram/service/programConstants';

import SolidButton from 'components/SolidButton';

const AddAudio = () => {
  const { t } = useTranslation();
  const { searchId, dispatch, handleCreateEditProgram } = useCreateProgramContext();

  const onBack = () => {
    dispatch({ type: ACTIONS.SET_STEP, payload: CREATE_PROGRAM_STEPS.READING_TEMPLATES });
  };

  const onCreateEditProgram = () => {
    dispatch({ type: ACTIONS.SET_STEP, payload: CREATE_PROGRAM_STEPS.COMPLETED });
    handleCreateEditProgram();
  };

  return (
    <div className="program__audio">
      <p className="form__label">Audio will be added soon...</p>
      <div className="program__audio__buttons">
        <SolidButton className="secondary mr-2" onClick={onBack}>
          {t('button.back')}
        </SolidButton>
        <SolidButton onClick={onCreateEditProgram}>{searchId ? t('button.update') : t('button.create')}</SolidButton>
      </div>
    </div>
  );
};

export default AddAudio;

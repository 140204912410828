import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineClose } from 'react-icons/ai';
import Lottie from 'react-lottie';

import Modal from 'components/Modal';

import './squeeze-preview.scss';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: 500,
    height: 768,
    padding: 0,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
};

const isPaused = false;

const SqueezePreview = ({ modalIsOpen, toggleModal, previewData }) => {
  const { jsonParse, content = [], title } = previewData;

  const interval = useRef(null);
  const [currentAction, setCurrentAction] = useState(0);
  const [seconds, setSeconds] = useState(null);

  const currentTime = Number(content[currentAction]?.duration);

  const countDown = () => {
    setSeconds((prev) => {
      if (prev === 0) {
        clearInterval(interval.current);
        return prev;
      }
      const timeLeft = prev - 1;
      return timeLeft;
    });
  };

  useEffect(() => {
    setSeconds(currentTime);
  }, [currentTime, currentAction]);

  useEffect(() => {
    if (isPaused) {
      if (interval.current) clearInterval(interval.current);
      return;
    }
    interval.current = setInterval(countDown, 1000);
    return () => clearInterval(interval.current);
  }, [isPaused]);

  useEffect(() => {
    if (seconds === 0) {
      setCurrentAction((prev) => {
        if (prev === content.length - 1) {
          return 0;
        }
        return prev + 1;
      });
    }
  }, [seconds]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: jsonParse,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Modal modalProps={modalProps} modalIsOpen={modalIsOpen} closeModal={toggleModal} closeable={false}>
      <section className="squeezepreview">
        <AiOutlineClose className="squeezepreview__close" onClick={toggleModal} />
        <div className="squeezepreview__border">
          <div className="squeezepreview__background">
            <h2 className="squeezepreview__background_title">{title}</h2>
            <div className="squeezepreview__animation">{jsonParse ? <Lottie options={defaultOptions} /> : null}</div>
            <div className="squeezepreview__counter">
              <p className="squeezepreview__counter_value">{seconds}</p>
              <div className="squeezepreview__actions">
                {content.map((currAction, index) => {
                  const actionLabel = currAction.action.split('_').join(' ');
                  const noRest = !['rest', 'hold'].includes(actionLabel);
                  if (index === currentAction) {
                    return (
                      <>
                        <p className="squeezepreview__actions_action">
                          {actionLabel}
                          {noRest ? '%' : null}
                        </p>
                        <p className="squeezepreview__actions_action">
                          {actionLabel}
                          {noRest ? '%' : null}
                        </p>
                        <p className="squeezepreview__actions_action">
                          {actionLabel}
                          {noRest ? '%' : null}
                        </p>
                      </>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};

SqueezePreview.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  previewData: PropTypes.shape({
    title: PropTypes.string,
    file: PropTypes.string,
  }).isRequired,
};

export default SqueezePreview;

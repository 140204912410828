import React from 'react';
import { useTranslation } from 'react-i18next';

import { ACTIONS, EVIE_PROGRAM_STEPS } from 'components/eviePrograms/service';
import { useEvieProgramServiceContext } from 'components/eviePrograms/EvieProgramServiceProvider';
import { useEvieProgramContext } from 'components/eviePrograms/EvieProgramProvider';

import SolidButton from 'components/SolidButton';
import EvieLevelsList from '../EvieLevelsList/EvieLevelsList';

const EvieAdditionalContent = () => {
  const { t } = useTranslation();
  const { programId } = useEvieProgramServiceContext();
  const { dispatch, createUpdProgramBuilder } = useEvieProgramContext();

  const handleBack = () => {
    dispatch({ type: ACTIONS.SET_STEP, payload: EVIE_PROGRAM_STEPS.LEVELS });
  };
  const handleSave = () => {
    dispatch({ type: ACTIONS.SET_STEP, payload: EVIE_PROGRAM_STEPS.COMPLETED });
    createUpdProgramBuilder();
  };

  return (
    <div className="evie-program__additional">
      <section className="evie-program__add-level">
        <h3 className="evie-program__add-level_title">{t('eviePrograms.additionalContent.title')}</h3>
      </section>
      <EvieLevelsList />
      <section className="evie-program__btns">
        <SolidButton className="evie-secondary mr-4" onClick={handleBack}>
          {t('button.back')}
        </SolidButton>
        <SolidButton className="evie-primary" onClick={handleSave}>
          {programId ? t('button.update') : t('button.create')}
        </SolidButton>
      </section>
    </div>
  );
};

export default EvieAdditionalContent;

import React from 'react';
import Select from 'react-select';

import { customStyles } from 'utils/styles';

const selectOptions = [
  { label: 'Seleccionar todas', value: 'selectAll' },
  { label: 'Deseleccionar todas', value: 'unselectAll' },
];

const SelectSurvey = (props) => {
  const { surveys = [], showSurvey = false, handleSelectSurvey = () => {}, handleSelectAll = () => {} } = { ...props };
  const surveysOptions = (surveys || []).map(({ title: label, id: value }) => ({ label, value }));

  return (
    <div className="select-survey-container">
      <label htmlFor="snd-survey" className="send-survey">
        Escoge la encuesta que deseas enviar:
      </label>
      <Select
        id="snd-survey"
        options={surveysOptions}
        styles={customStyles}
        onChange={({ value }) => handleSelectSurvey(value)}
        placeholder="Seleccione una encuesta"
      />
      {showSurvey && (
        <Select
          className="select-all"
          options={selectOptions}
          styles={customStyles}
          onChange={handleSelectAll}
          placeholder="Seleccionar preguntas"
        />
      )}
    </div>
  );
};

export { SelectSurvey };

import { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { loggedIn } from 'libs/authenticationService';
import { getUserRole } from 'utils/isClinic';
import { REDIRECT_BY_ROLE } from 'utils/constants';

export const useRedirectByStatus = () => {
  const { replace } = useHistory();
  const isLoggedIn = loggedIn();

  useLayoutEffect(() => {
    if (isLoggedIn) {
      replace(REDIRECT_BY_ROLE[getUserRole()]);
    }
  }, [isLoggedIn]);
};

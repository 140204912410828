import { useTranslation } from 'react-i18next';
import { FaChevronCircleDown, FaFont, FaImage, FaAlignJustify, FaBoxes } from 'react-icons/fa';
import { BiVerticalCenter } from 'react-icons/bi';

import { BLOCK_TYPE } from './constants';

export const useFormatBlockTypes = () => {
  const { t } = useTranslation();

  const blockTypes = [
    {
      type: BLOCK_TYPE.V_CONTAINER,
      title: t('readings.blockTypes.block'),
      icon: FaBoxes,
    },
    {
      type: BLOCK_TYPE.HEADING_1,
      title: t('readings.blockTypes.heading1'),
      icon: FaFont,
    },
    {
      type: BLOCK_TYPE.HEADING_2,
      title: t('readings.blockTypes.heading2'),
      icon: FaFont,
    },
    {
      type: BLOCK_TYPE.HEADING_3,
      title: t('readings.blockTypes.heading3'),
      icon: FaFont,
    },
    {
      type: BLOCK_TYPE.TEXT,
      title: t('readings.blockTypes.text'),
      icon: FaAlignJustify,
    },
    {
      type: BLOCK_TYPE.IMAGE,
      title: t('readings.blockTypes.image'),
      icon: FaImage,
    },
    {
      type: BLOCK_TYPE.BULLET_LIST,
      title: t('readings.blockTypes.bulletList'),
      icon: FaChevronCircleDown,
    },
    {
      type: BLOCK_TYPE.DIVIDER,
      title: t('readings.blockTypes.divider'),
      icon: BiVerticalCenter,
    },
  ];
  return { blockTypes };
};

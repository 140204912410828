import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { FaFileAudio } from 'react-icons/fa';

import SolidButton from 'components/SolidButton';

export const formatUploadedFile = (file) => (typeof file === 'object' ? URL.createObjectURL(file) : file);

const AudioInput = ({ field, form, labelBtn }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const fileInput = useRef();
  const [audioUrl, setAudioUrl] = useState(value);

  useEffect(() => {
    if (typeof value === 'string') {
      setAudioUrl(value);
    }
  }, [value]);

  const handleAudioInputClick = () => {
    if (fileInput) {
      fileInput.current.click();
    }
  };

  return (
    <div className="create-audio__player">
      <SolidButton type="button" className="slim" onClick={handleAudioInputClick}>
        <FaFileAudio />
        {labelBtn}
      </SolidButton>
      <input
        type="file"
        accept="audio/*"
        className="file-upload"
        ref={fileInput}
        style={{ display: 'none' }}
        onChange={(event) => {
          const upload = event.currentTarget.files[0];
          setFieldValue(name, upload);
          if (upload) {
            const audio = formatUploadedFile(upload);
            setAudioUrl(audio);
          }
        }}
      />
      <audio className="aud" controls src={audioUrl}>
        <track kind="captions" />
        Your browser does not support the
        <code>audio</code> element.
      </audio>
    </div>
  );
};

AudioInput.propTypes = {
  field: PropTypes.shape({ name: PropTypes.string, value: PropTypes.string }).isRequired,
  form: PropTypes.shape({ setFieldValue: PropTypes.func }).isRequired,
  labelBtn: PropTypes.string.isRequired,
};

export default AudioInput;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'utils/constants';

import SolidButton from 'components/SolidButton';

const TeamCount = ({ expanded, activeEmployees, availableEmployees, trial }) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  return (
    <section className={`left-menu__actual ${expanded ? '' : 'left-menu__actual--hide'}`}>
      <p>
        {t('leftMenuHeader.companies.teamCount.actual')}
        <br />
        <span className="number-of-employees">
          {activeEmployees} {t('leftMenuHeader.companies.teamCount.of')} {availableEmployees}
        </span>
      </p>
      {trial && (
        <SolidButton className="tertiary" onClick={() => push(ROUTES.BILLING)}>
          {t('leftMenuHeader.companies.teamCount.button')}
        </SolidButton>
      )}
    </section>
  );
};

TeamCount.propTypes = {
  expanded: PropTypes.bool.isRequired,
  activeEmployees: PropTypes.number.isRequired,
  availableEmployees: PropTypes.number.isRequired,
  trial: PropTypes.bool.isRequired,
};

export default TeamCount;

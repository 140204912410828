import React from 'react';
import Select from 'react-select';

import { useTranslation } from 'react-i18next';

import { customStyles } from 'utils/styles';
import { useSetLanguage } from 'utils/hooks/useSetLanguage';

const setLanguageOptions = [
  { label: 'Español', value: 'es' },
  { label: 'English', value: 'en' },
];

const ChangeLanguage = () => {
  const { setLanguage } = useSetLanguage();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const handleChangeLanguage = ({ value }) => {
    setLanguage(value);
  };
  return (
    <section className="settings">
      <div className="settings__left">
        <p className="settings__left_profile">{t('settings.language.title')}</p>
        <p className="settings__left_description">{t('settings.language.description')}</p>
      </div>
      <div className="settings__right settings__right--whitelabel">
        <label htmlFor="change-language" className="team__member_name">
          {t('settings.language.label')}
        </label>
        <div className="settings__right_language">
          <Select
            id="change-language"
            defaultValue={setLanguageOptions.find(({ value }) => value === language)}
            options={setLanguageOptions}
            styles={customStyles}
            onChange={handleChangeLanguage}
            placeholder={t('settings.language.placeholder')}
          />
        </div>
      </div>
    </section>
  );
};

export default ChangeLanguage;

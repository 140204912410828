import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { RiSurveyLine } from 'react-icons/ri';
import { BsDownload } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';

import SurveysModal from 'components/Patients/Patient/SurveysModal';
import FollowUpSurveyViewModal from 'components/Patients/Patient/FollowUpSurveyViewModal';
import FollowupSurveyToPrint from 'components/Patients/Patient/FollowupSurveyToPrint';
import SolidButton from 'components/SolidButton';
import { NoPatientData } from 'components/EmptyState';

import { useDownloadPdf } from 'utils/hooks';

const Surveys = ({ patient = {}, refetch }) => {
  const { id: patientId = '', patientSurveys = [] } = { ...patient };
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [surveyModalIsOpen, setIsSurveyModalOpen] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState({});

  const onOpenModal = () => setIsModalOpen(true);
  const onCloseModal = () => setIsModalOpen(false);
  const onCloseSurveyModal = () => setIsSurveyModalOpen(false);
  const onOpenViewSurveyModal = (data) => () => {
    setSelectedSurvey(data);
    setIsSurveyModalOpen(true);
  };

  const onPrint = (callback, data) => () => {
    setSelectedSurvey(data);
    callback();
  };

  const { componentRef, handlePrint } = useDownloadPdf();

  return (
    <div className="w-100">
      <div className="patient__title">
        <div>
          <RiSurveyLine color="#495fd7" size="32px" style={{ marginRight: '16px' }} />
          <p className="title">Encuestas</p>
        </div>
      </div>
      <div className="patient__tab_content follow-up">
        {isEmpty(patientSurveys) ? (
          <NoPatientData tab="surveys" onCreateData={onOpenModal} />
        ) : (
          <>
            <div className="patient__logs">
              {patientSurveys.map((survey) => {
                const { id: surveyId = '', status = '', form = {} } = { ...survey };
                const { title: surveyTitle = '' } = { ...form };
                const showStatus = status === 'invited' ? 'Encuesta enviada' : 'Encuesta completada';
                return (
                  <div className={`item item--${status}`} key={surveyId}>
                    <div className="info">
                      <p className="title">{surveyTitle}</p>
                      <p className="date">{showStatus}</p>
                    </div>
                    <div className="events">
                      <div className="event-detail">
                        <IoIosArrowDown onClick={status === 'invited' ? undefined : onOpenViewSurveyModal(survey)} />
                      </div>
                      <div className="event-detail">
                        <BsDownload onClick={status === 'invited' ? undefined : onPrint(handlePrint, survey)} />
                      </div>
                    </div>
                    <div style={{ display: 'none' }}>
                      <FollowupSurveyToPrint data={selectedSurvey} ref={componentRef} />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="add-followup-button">
              <SolidButton onClick={() => setIsModalOpen(true)}>Agregar</SolidButton>
            </div>
          </>
        )}
      </div>
      <SurveysModal
        modalIsOpen={modalIsOpen}
        onCloseModal={onCloseModal}
        patientId={patientId}
        refetchPatient={refetch}
      />
      <FollowUpSurveyViewModal
        modalIsOpen={surveyModalIsOpen}
        onCloseModal={onCloseSurveyModal}
        data={selectedSurvey}
      />
    </div>
  );
};

Surveys.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string,
    patientSurveys: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default Surveys;

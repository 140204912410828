import React from "react";
import appStore from "assets/images/app-store-button.png";
import googlePlay from "assets/images/google-play-button.png";

const DownloadButton = ({ store }) => {
  const getButtonInfo = (store) => {
    const isGoogleStore = store === "google";
    return {
      link: isGoogleStore
        ? "https://play.google.com/store/apps/details?id=com.healthphy&hl=es_CO"
        : "https://apps.apple.com/us/app/healthphy/id1499438992?ls=1",
      logo: isGoogleStore ? googlePlay : appStore,
      alt: isGoogleStore
        ? "descargar la app en google play"
        : "descargar la app en app store",
    };
  };

  const { link, logo, alt } = getButtonInfo(store);

  return (
    <a href={link} target={"_blank"} rel={"noopener noreferrer"}>
      <img src={logo} alt={alt} />
    </a>
  );
};

export default DownloadButton;

import React, { useState, useEffect, useRef } from 'react';
import { Formik, Field, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { FaFileVideo } from 'react-icons/fa';

import { useCreateUpdShortVideo, useGetShortVideo } from 'components/shortVideos/service';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import { queryParams } from 'utils/string';
import { createShortVideo } from 'utils/validationSchema';
import { formatBackgroundImage } from 'components/readings/service/utils';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SolidButton from 'components/SolidButton';
import { TextInput, TextAreaInput, ImageInput } from 'components/FormComponents';

import './create-short-video.scss';

const CreateShortVideo = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const {
    goBack,
    location: { search },
  } = useHistory();
  const { id: videoId = '' } = queryParams(search);

  const { createUpdShortVideo, spinner } = useCreateUpdShortVideo(videoId);
  const { loading, shortVideo } = useGetShortVideo(videoId);
  const { title, description, file, fileUs, thumbnailImage = {}, posterImage = {} } = shortVideo;
  const { thumb } = thumbnailImage;
  const { thumb: thumbPosterImage } = posterImage;
  const initialValues = { title, description, file, fileUs, thumbnailImage: thumb, posterImage: thumbPosterImage };
  const formatedThumbImage = formatBackgroundImage(thumb);
  const formatedThumbPosterImage = formatBackgroundImage(thumbPosterImage);

  const [videoUrl, setVideoUrl] = useState('');
  const [videoUsUrl, setVideoUsUrl] = useState('');

  useEffect(() => {
    if (file) {
      setVideoUrl(file);
    }
  }, [file]);

  useEffect(() => {
    if (fileUs) {
      setVideoUsUrl(fileUs);
    }
  }, [fileUs]);

  const fileInput = useRef();
  const fileUsInput = useRef();

  const handleFileInputClick = () => {
    if (fileInput) {
      fileInput.current.click();
    }
  };

  const handleFileUsInputClick = () => {
    if (fileUsInput) {
      fileUsInput.current.click();
    }
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenu />
        <div className="dashboard__main">
          <Header
            title={videoId ? t('leftMenuHeader.shortVideoDetail') : t('leftMenuHeader.createShortVideo')}
            shadow
          />
          <Formik
            initialValues={initialValues}
            onSubmit={createUpdShortVideo}
            validationSchema={createShortVideo}
            enableReinitialize
          >
            {({ setFieldValue, isSubmitting, isValid }) => {
              const isDisabledButton = [!isValid, isSubmitting, !videoUrl, !videoUsUrl].includes(true);
              return (
                <Form>
                  <section className="create-audio">
                    <div className="create-audio__fields">
                      <ImageInput
                        setFieldValue={setFieldValue}
                        formatedImage={formatedThumbImage}
                        name="thumbnailImage"
                        label={t('formLabel.thumbnailImage')}
                      />
                      <ImageInput
                        setFieldValue={setFieldValue}
                        formatedImage={formatedThumbPosterImage}
                        name="posterImage"
                        label={t('formLabel.posterImage')}
                      />
                      <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
                      <Field
                        type="text"
                        label={t('formLabel.description')}
                        name="description"
                        minRows={4}
                        component={TextAreaInput}
                      />
                    </div>
                    <div className="create-audio__player">
                      <SolidButton type="button" className="slim" onClick={handleFileInputClick}>
                        <FaFileVideo />
                        {t('button.upload')}
                      </SolidButton>
                      <input
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        className="file-upload"
                        ref={fileInput}
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          const upload = event.currentTarget.files[0];
                          setFieldValue('file', upload);
                          if (upload) {
                            const fileUrl = URL.createObjectURL(upload);
                            setVideoUrl(fileUrl);
                          }
                        }}
                      />
                      {videoUrl && (
                        <video className="create-short-video__player" controls>
                          <source src={videoUrl} type="video/mp4" />
                          <track kind="captions" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>

                    <div className="create-audio__player">
                      <SolidButton type="button" className="slim" onClick={handleFileUsInputClick}>
                        <FaFileVideo />
                        {t('button.upload')} US
                      </SolidButton>
                      <input
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        className="file-upload"
                        ref={fileUsInput}
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          const upload = event.currentTarget.files[0];
                          setFieldValue('fileUs', upload);
                          if (upload) {
                            const fileUsUrl = URL.createObjectURL(upload);
                            setVideoUsUrl(fileUsUrl);
                          }
                        }}
                      />
                      {videoUsUrl && (
                        <video className="create-short-video__player" controls>
                          <source src={videoUsUrl} type="video/mp4" />
                          <track kind="captions" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                  </section>
                  <div className="create-audio__buttons">
                    <SolidButton className="secondary" onClick={goBack}>
                      {t('button.back')}
                    </SolidButton>
                    <SolidButton
                      className={`ml-2 ${isDisabledButton ? 'disabled' : ''}`}
                      disabled={isDisabledButton}
                      type="submit"
                    >
                      {videoId ? t('button.update') : t('button.create')}
                    </SolidButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || spinner}
      />
    </>
  );
};

export default CreateShortVideo;

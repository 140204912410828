import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SolidButton from 'components/SolidButton';
import introDoctor from 'assets/images/intro-doctor.svg';

const Intro = ({ onContinue }) => {
  const { t } = useTranslation();

  return (
    <div className="signup__intro">
      <div className="left">
        <div>
          <p className="title">{t('introModal.title')}</p>
          <p className="message">{t('introModal.description')}</p>
          <SolidButton onClick={onContinue}>{t('button.setup')}</SolidButton>
        </div>
      </div>
      <div className="right">
        <img src={introDoctor} alt="intro" className="intro__image" />
      </div>
    </div>
  );
};

Intro.propTypes = {
  onContinue: PropTypes.func.isRequired,
};

export default Intro;

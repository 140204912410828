import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import { EVIE_PROGRAM_STEPS } from 'components/eviePrograms/service';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import EvieSteps from 'components/eviePrograms/EvieSteps';
import EvieDetail from 'components/eviePrograms/EvieDetail';
import EvieLevels from 'components/eviePrograms/EvieLevels';
import EvieAdditionalContent from 'components/eviePrograms/EvieAdditionalContent';
import { EvieProgramProvider, useEvieProgramContext } from 'components/eviePrograms/EvieProgramProvider';
import {
  EvieProgramServiceProvider,
  useEvieProgramServiceContext,
} from 'components/eviePrograms/EvieProgramServiceProvider';

import './create-evie-program.scss';

const EvieProgramWrapper = () => (
  <EvieProgramServiceProvider>
    <EvieProgramProvider>
      <CreateEvieProgram />
    </EvieProgramProvider>
  </EvieProgramServiceProvider>
);

const CreateEvieProgram = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();
  const { isFecthingProgram, programId } = useEvieProgramServiceContext();
  const {
    isSendingProgram,
    state: { step },
  } = useEvieProgramContext();

  const isInAdditionalContent = [EVIE_PROGRAM_STEPS.ADDITIONAL_CONTENT, EVIE_PROGRAM_STEPS.COMPLETED].includes(step);

  return (
    <section className="d-flex">
      <LeftMenu />
      <div className="dashboard__main">
        <Header
          title={programId ? t('leftMenuHeader.evieProgramDetail') : t('leftMenuHeader.createEvieProgram')}
          shadow
        />
        <div className="evie-program">
          <EvieSteps />
          <section className={`evie-program__sections ${isInAdditionalContent ? 'final-section' : ''}`}>
            {step === EVIE_PROGRAM_STEPS.DESCRIPTION && <EvieDetail />}
            {step === EVIE_PROGRAM_STEPS.LEVELS && <EvieLevels />}
            {step === EVIE_PROGRAM_STEPS.ADDITIONAL_CONTENT && <EvieAdditionalContent />}
          </section>
        </div>
      </div>
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={isFecthingProgram || isSendingProgram}
      />
    </section>
  );
};

export default EvieProgramWrapper;
